import React from "react";

import { Typography } from "@material-tailwind/react";

import { ReactComponent as EmptyIcon } from "../../assets/icons/emptyIconn.svg";

const NotFound = () => {
  return (
    <>
      <div className="flex justify-center">
        <EmptyIcon className="ml-[30px]" />
      </div>
      <Typography
        variant="h2"
        className="p-4 text-center text-xl font-semibold text-neutral-500"
      >
        Data tidak ditemukan
      </Typography>

      <Typography className="text-center text-neutral-500">
        Data yang Anda cari tidak ditemukan. Coba kata kunci lain
      </Typography>
    </>
  );
};

export default NotFound;
