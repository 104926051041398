import React, { useEffect, useState } from "react";
import { Card, Typography } from "@material-tailwind/react";
import { FaChevronLeft } from "react-icons/fa";
import { Button } from "../../atoms/Button";
import forgotImg from "../../img/Forgot password.png";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ChangePassword } from "../features/authSlice";
import EmailInput from "./EmailInput";

const FormForgotPassword = ({ email, setEmail, onBack }) => {
  const navigate = useNavigate();
  const isSubmitDisabled = !email;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useDispatch();
  const { changeSuccess, isLoading } = useSelector((state) => state.auth);

  const handleChangePassword = async () => {
    if (!isSubmitting) {
      setIsSubmitting(true);
      dispatch(ChangePassword({ email }));
      localStorage.setItem("emailSender", JSON.stringify(email));
    }
  };

  useEffect(() => {
    if (changeSuccess) {
      navigate("/email-terkirim");
    }
  }, [changeSuccess, navigate]);

  // const handleSubmit = async () => {
  //   if (!isSubmitting) {
  //     await api.post("v1/auth/requestForgotPassword", {
  //       email: email
  //     })
  //     .then((response) => {
  //       localStorage.setItem("emailSender", JSON.stringify(email))
  //       return response.data
  //     })
  //     setIsSubmitting(true);
  //     navigate("/emailSent");
  //   }
  // };

  return (
    <div className="flex justify-center items-center md:min-h-[700px] min-h-[480px]">
      <Card className="w-[484px] max-w-[484px] md:min-w-sm shadow-none md:shadow-md md:border-t-2 md:border-gray-100 rounded-xl md:p-4xl p-xs">
        <div className="w-[150px] h-[150px] pl-[5.86px] pr-[6.14px] pt-[16.99px] pb-2.5 justify-center items-center inline-flex">
          <img src={forgotImg} alt="forgotPasswordImg" />
        </div>
        <Typography
          type="h2"
          className="text-xl font-bold text-neutral-900 mb-xs"
        >
          Lupa Kata Sandi
        </Typography>
        <Typography
          type="small"
          className="text-base font-normal text-slate-600 leading-normal pr-3xl mb-4xl"
        >
          Masukkan email terdaftar Anda untuk menerima tautan atur ulang kata
          sandi.
        </Typography>

        <div className="mb-2xl">
          <EmailInput email={email} setEmail={setEmail} />
        </div>

        {/* Tombol Masuk */}
        <Button
          label="Lanjutkan"
          size="medium"
          type={`${
            isSubmitDisabled || isLoading ? "btnPrimaryDisabled" : "btnPrimary"
          }`}
          onClick={handleChangePassword}
          componentLeft={
            isSubmitting && (
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                className="animate-spin fill-neutral-400"
              >
                <path
                  d="M10.72,19.9a8,8,0,0,1-6.5-9.79A7.77,7.77,0,0,1,10.4,4.16a8,8,0,0,1,9.49,6.52A1.54,1.54,0,0,0,21.38,12h.13a1.37,1.37,0,0,0,1.38-1.54,11,11,0,1,0-12.7,12.39A1.54,1.54,0,0,0,12,21.34h0A1.47,1.47,0,0,0,10.72,19.9Z"
                  className="spinner_6kVp"
                />
              </svg>
            )
          }
          disabled={isSubmitDisabled || isSubmitting}
        />
        <Button
          size="medium"
          className="mt-4xl"
          type="btnLinkBlack"
          componentLeft={
            <FaChevronLeft className="h-3 w-3 text-slate-600 hover:text-neutral-800 mt-[7px]" />
          }
          label="Kembali ke halaman masuk"
          onClick={onBack}
        />
      </Card>
    </div>
  );
};

export default FormForgotPassword;
