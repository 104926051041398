import Lottie from "lottie-react"
import loadingAnimation from '../assets/loading_animation.json'
import spinnerWhite from '../assets/spinner.json'

const LoadingAnimationRounded = ({
  width,
  height,
  white
}) => {
  return <Lottie 
    loop={true}
    animationData={white ? spinnerWhite : loadingAnimation}
    width={width}
    height={height}
  />
}

export default LoadingAnimationRounded