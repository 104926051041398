import {
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";
import PropTypes from "prop-types";
import { Button } from "./Button";
import { IoClose } from "react-icons/io5";

const ConfirmDialog = ({ isOpen, onClose, onCancel, onConfirm, title, content, btnLabel, className, btnType, disabled, btnLabelCancel, classNameContent, componentLeftBtn, colorDsiabled, classNameHeader }) => {
  return (
    <Dialog size="md" className="p-1" open={isOpen} toggler={() => onClose()}>
      <DialogHeader toggler={() => onClose()} className={`mt-2 text-xl flex justify-between ${classNameHeader}`}>
        <div className="text-text-primary">
        {title}
        </div>
        
        <div className="text-right">
          <button
            className="top-0 right-0 p-1 rounded-full shadow-none bg-white"
            onClick={() => onClose()}
          >
            <IoClose className="h-7 w-7 bg-white text-text-quarterary hover:text-text-primary" />
          </button>
        </div>
        </DialogHeader>
      <DialogBody className={`mt-[-30px] text-text-quarterary text-lg ${classNameContent}`}>{content}</DialogBody>
      <DialogFooter className="border-none mt-[-15px] mb-4">
        <Button
          label={btnLabelCancel ? btnLabelCancel : "Batal"}
          size="medium"
          type={"btnSecondary"}
          className={`${className} shadow-none mr-md`}
          onClick={onCancel ? () => onCancel() : () => onClose()}
        />
        <Button
          label={btnLabel}
          size="medium"
          type={btnType}
          onClick={() => onConfirm()}
          disabled={disabled}
          componentLeft={componentLeftBtn}
        />
      </DialogFooter>
    </Dialog>
  );
};

ConfirmDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  title: PropTypes.string,
  content: PropTypes.string,
};

export default ConfirmDialog;
