import React, { useEffect, useState } from "react";

import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronDownIcon,
} from "@heroicons/react/24/outline";
import {
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
  Tooltip,
  Typography,
} from "@material-tailwind/react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";

import { ReactComponent as MutasiIcon } from "../../assets/icons/sidebarIcon/ClipboardDownload.svg";
import { ReactComponent as HarvestIcon } from "../../assets/icons/sidebarIcon/Harvest.svg";
import { ReactComponent as HomeIcon } from "../../assets/icons/sidebarIcon/home.svg";
import { ReactComponent as IconKandang } from "../../assets/icons/sidebarIcon/KandangIcon.svg";
import { ReactComponent as MasterIcon } from "../../assets/icons/sidebarIcon/MasterIcon.svg";
import { ReactComponent as PeriodIcon } from "../../assets/icons/sidebarIcon/Period.svg";
import { ReactComponent as IconSetting } from "../../assets/icons/sidebarIcon/Setting.svg";
import { ReactComponent as StokIcon } from "../../assets/icons/sidebarIcon/StokIcon.svg";
import bg from "../../img/chicken-sidebar.svg";
import justLogo from "../../img/just-logo.svg";
import logo from "../../img/Logo-2.svg";
import deleteIrrelevantCookies from "../../shared/conditionalDeleteCookies";
import { ROUTES_PATH } from "../../shared/routes";
import { getMe } from "../features/authSlice";
import { setIsSidebarOpen } from "../features/menuSlice";

// Import fungsi

const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { user, isError } = useSelector((state) => state.auth);
  const isSidebarOpen = useSelector((state) => state.menu.isSidebarOpen);
  const [menuActive, setMenuActive] = useState("");
  const [subMenuActive, setSubMenuActive] = useState("");

  const [openSubmenus, setOpenSubmenus] = useState({});
  useEffect(() => {
    dispatch(getMe());
  }, [dispatch]);

  useEffect(() => {
    if (isError) {
      navigate("/");
    }
  }, [isError, navigate]);

  const toggleSidebar = () => {
    dispatch(setIsSidebarOpen(!isSidebarOpen));
  };

  const toggleSubmenu = (menuId) => {
    setOpenSubmenus((prev) => ({
      ...prev,
      [menuId]: !prev[menuId],
    }));
  };

  const filteredMenu = (menu) => {
    return menu.filter((item) => {
      if (!item.submenuFiel) {
        return user?.permissions?.includes(item.permission);
      } else {
        return item.submenu.some((subItem) =>
          user?.permissions?.includes(subItem.permission),
        );
      }
    });
  };

  const dataMenu = filteredMenu([
    {
      id: 1,
      label: "Dashboard",
      icon: <HomeIcon color="white" className="h-6 w-6" />,
      link: "/dashboard",
      activeMenu: "dashboard",
      submenuFiel: false,
      submenu: [],
      permission: "Lihat daftar_Dashboard",
    },

    {
      id: 2,
      label: "Kandang",
      icon: <IconKandang color="white" className="h-6 w-6" />,
      link: "/kandang",
      activeMenu: "kandang",
      submenuFiel: false,
      submenu: [],
      permission: "Lihat daftar_Kandang",
    },
    {
      id: 3,
      label: "Stok",
      icon: <StokIcon color="white" className="h-6 w-6" />,
      activeMenu: "stok",
      submenuFiel: true,
      permission: "",
      submenu: [
        {
          id: 4,
          label: "Livebird",
          icon: null,
          link: "/stok/livebird",
          activeSubMenu: "livebird",
          permission: "Lihat daftar_Stok_Livebird",
        },
        // {
        //   id: 5,
        //   label: "Pakan",
        //   icon: null,
        //   link: "/stok/pakan",
        //   activeSubMenu: "pakan",
        //   permission: "Lihat daftar_Stok_Pakan",
        // },
        // {
        //   id: 6,
        //   label: "OVK",
        //   icon: null,
        //   link: "/stok/ovk",
        //   activeSubMenu: "ovk",
        //   permission: "Lihat daftar_Stok_OVK",
        // }
      ],
    },
    {
      id: 7,
      label: "Periode Budidaya",
      icon: <PeriodIcon color="white" className="h-6 w-6" />,
      activeMenu: "periode-budidaya",
      submenuFiel: true,
      permission: "",
      submenu: [
        user?.level === "UNIT" && {
          id: 71,
          label: "Master Periode",
          icon: null,
          link: ROUTES_PATH.masterPeriode,
          activeSubMenu: "master-periode",
          permission: "Lihat daftar_Periode Budidaya_Master Periode",
        },
        {
          id: 84,
          label: "Master Periode",
          icon: null,
          link: ROUTES_PATH.masterPeriodeRegion,
          activeSubMenu: "region-master-periode",
          permission: "Lihat detail_Periode Budidaya_Master Periode Region",
        },
        {
          id: 8,
          label: "Periode Berjalan",
          icon: null,
          link: "/periode-budidaya/periode-berjalan",
          activeSubMenu: "periode-berjalan",
          permission: "Lihat daftar_Periode Budidaya_Periode Berjalan",
        },
        {
          id: 9,
          label: "Monitoring Growing",
          icon: null,
          link: "/periode-budidaya/monitoring-growing/berjalan",
          activeSubMenu: "monitoring-growing",
          permission: "Lihat daftar_Periode Budidaya_Monitoring Growing",
        },
        {
          id: 10,
          label: "Periode Selesai",
          icon: null,
          link: "/periode-budidaya/periode-selesai",
          activeSubMenu: "periode-selesai",
          permission: "Lihat daftar_Periode Budidaya_Periode Selesai",
        },
      ],
    },
    {
      id: 11,
      label: "Mutasi",
      icon: <MutasiIcon color="white" className="h-6 w-6" />,
      activeMenu: "mutasi",
      link: "/mutasi",
      submenuFiel: false,
      submenu: [],
      permission: "Lihat daftar_Mutasi",
    },
    {
      id: 12,
      label: "Panen",
      icon: <HarvestIcon color="white" className="h-6 w-6" />,
      activeMenu: "panen",
      link: "/panen",
      submenuFiel: false,
      submenu: [],
      permission: "Lihat daftar_Panen",
    },
    {
      id: 13,
      label: "Master",
      icon: <MasterIcon color="white" className="h-6 w-6" />,
      activeMenu: "master",
      link: "/master",
      submenuFiel: true,
      permission: "",
      submenu: [
        {
          id: 14,
          label: "OVK",
          icon: null,
          activeSubMenu: "master-ovk",
          link: "/master/ovk",
          permission: "Lihat daftar_Master_OVK",
        },
        {
          id: 15,
          label: "DOC",
          icon: null,
          activeSubMenu: "master-doc",
          link: "/master/doc",
          permission: "Lihat daftar_Master_DOC",
        },
        {
          id: 16,
          label: "Region",
          icon: null,
          activeSubMenu: "master-region",
          link: "/master/region",
          permission: "Lihat daftar_Master_DOC",
        },
        {
          id: 17,
          label: "Pakan",
          icon: null,
          activeSubMenu: "master-pakan",
          link: "/master/pakan",
          permission: "Lihat daftar_Master_Pakan",
        },
        {
          id: 18,
          label: "Unit",
          icon: null,
          activeSubMenu: "master-unit",
          link: "/master/unit",
          permission: "Lihat daftar_Master_Unit",
        },
        {
          id: 19,
          label: "Bakul",
          icon: null,
          activeSubMenu: "master-bakul",
          link: "/master/bakul",
          permission: "Lihat daftar_Master_Bakul",
        },
      ],
    },
    {
      id: 20,
      header: user?.role === "Super Admin" ? "Manajemen" : "Manajemen User",
      label:
        user?.role === "Super Admin"
          ? "Pengaturan Pengguna"
          : "Pengaturan Mitra",
      icon: <IconSetting color="white" className="h-6 w-6" />,
      activeMenu: "manajemen",
      submenuFiel: true,
      permission: "",
      submenu: [
        {
          id: 21,
          label: "Role Akses",
          icon: null,
          link: "/pengaturan-pengguna/role-akses",
          activeSubMenu: "role-akses",
          permission: "Lihat daftar_Pengaturan Pengguna_Role Akses",
        },
        {
          id: 22,
          label: "Karyawan",
          icon: null,
          link: "/pengaturan-pengguna/user",
          activeSubMenu: "pengguna",
          permission: "Lihat daftar_Pengaturan Pengguna_Pengguna",
        },

        {
          id: 23,
          label: user?.role === "Super Admin" ? "Peternak" : "Peternak",
          icon: null,
          link: "/pengaturan-pengguna/farmer",
          activeSubMenu: "penggunaPeternak",
          permission: "Lihat daftar_Pengaturan Pengguna_Peternak",
        },
      ],
    },
  ]);
  const isActiveMenu = (menu) => location.pathname.startsWith(menu.link);

  const isActiveSubMenu = (submenuLink) =>
    location.pathname.startsWith(submenuLink);

  useEffect(() => {
    const path = location.pathname.split("/");
    setMenuActive(path[1]);
    setSubMenuActive(path[2]);
  }, [location]);

  const handleMenuClick = (menu, submenu = null) => {
    deleteIrrelevantCookies(menu, submenu, menuActive, subMenuActive);

    setMenuActive(menu);
    setSubMenuActive(submenu || "");
  };

  return (
    <aside
      className={`left-0 top-0 z-0 flex min-h-screen w-full max-w-[20rem] flex-col overflow-y-hidden bg-[#0C111D] text-white duration-300 ease-linear lg:static lg:translate-x-0 ${
        isSidebarOpen === true || isSidebarOpen === "true"
          ? "w-[300px]"
          : "w-[72px] max-w-[72px]"
      } transition-all`}
      style={{
        backgroundImage: `url(${bg})`,
        backgroundSize: "163.94px",
        backgroundPosition: "left bottom",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div
        className={`${
          isSidebarOpen === true || isSidebarOpen === "true"
            ? "flex items-center justify-between"
            : "text-center"
        } p-lg`}
      >
        <img
          src={
            isSidebarOpen === true || isSidebarOpen === "true" ? logo : justLogo
          }
          alt="BroilerX Logo"
          className="mb-2"
        />
        <button onClick={toggleSidebar} className="text-white">
          {isSidebarOpen === true || isSidebarOpen === "true" ? (
            <ChevronDoubleLeftIcon className="h-5 w-5" />
          ) : (
            <ChevronDoubleRightIcon className="h-5 w-5" />
          )}
        </button>
      </div>

      <div className="no-scrollbar flex flex-col overflow-y-auto px-lg">
        <div className="py-lg">
          {dataMenu.map((menu) =>
            !menu.submenuFiel ? (
              <NavLink
                to={menu.link}
                key={menu.id}
                onClick={() => handleMenuClick(menu.activeMenu)}
                selected={isActiveMenu(menu)}
                className={`my-md flex h-10 items-center gap-4 rounded-lg px-lg hover:bg-neutral-600 ${
                  isActiveMenu(menu)
                    ? "border-r-4 border-primary-400 bg-neutral-600"
                    : ""
                }`}
              >
                <div>
                  {isSidebarOpen === true || isSidebarOpen === "true" ? (
                    menu.icon
                  ) : (
                    <Tooltip
                      content={menu.label}
                      placement="right"
                      className="bg-neutral-50 text-text-quarterary"
                    >
                      {menu.icon}
                    </Tooltip>
                  )}
                </div>
                {(isSidebarOpen === true || isSidebarOpen === "true") && (
                  <Typography className="text-white">{menu.label}</Typography>
                )}
              </NavLink>
            ) : (
              <div>
                <div
                  onClick={() => toggleSubmenu(menu.id)}
                  className={`flex h-10 cursor-pointer items-center justify-between rounded-lg p-lg hover:bg-neutral-600`}
                >
                  {isSidebarOpen === false || isSidebarOpen === "false" ? (
                    <div>
                      <Menu placement="right-start" allowHover>
                        <MenuHandler>{menu.icon}</MenuHandler>
                        <MenuList>
                          {menu.submenu.map(
                            (item) =>
                              user?.permissions?.includes(item.permission) && (
                                <Link
                                  key={item.id}
                                  onClick={() =>
                                    handleMenuClick(
                                      menu.activeMenu,
                                      item.activeSubMenu,
                                    )
                                  }
                                  to={item.link}
                                  className="flex"
                                >
                                  <MenuItem>{item.label}</MenuItem>
                                </Link>
                              ),
                          )}
                        </MenuList>
                      </Menu>
                    </div>
                  ) : (
                    <div className="flex items-center gap-4">
                      {menu.icon}
                      {(isSidebarOpen === true || isSidebarOpen === "true") && (
                        <Typography className="text-white">
                          {menu.label}
                        </Typography>
                      )}
                    </div>
                  )}

                  {(isSidebarOpen === true || isSidebarOpen === "true") && (
                    <span
                      className={`transform transition-transform duration-300 ${
                        openSubmenus[menu.id] ? "rotate-180" : ""
                      }`}
                    >
                      <ChevronDownIcon
                        color="white"
                        strokeWidth={2.5}
                        className={`mx-auto h-4 w-4 transition-transform`}
                      />
                    </span>
                  )}
                </div>
                <div
                  className={`my-md overflow-hidden transition-all duration-300 ease-in-out ${
                    openSubmenus[menu.id] ? "max-h-[1000px]" : "max-h-0"
                  }`}
                >
                  <div>
                    {(isSidebarOpen === true || isSidebarOpen === "true") &&
                      menu.submenu.map(
                        (submenu) =>
                          user?.permissions?.includes(submenu.permission) && (
                            <NavLink
                              to={submenu.link}
                              key={submenu.id}
                              selected={isActiveSubMenu(submenu.link)}
                              onClick={() =>
                                handleMenuClick(
                                  menu.activeMenu,
                                  submenu.activeSubMenu,
                                )
                              }
                              className={`my-md flex h-10 items-center rounded-lg p-lg pl-5xl font-normal text-white hover:bg-neutral-600 ${
                                isActiveSubMenu(submenu.link)
                                  ? "border-r-4 border-primary-400 bg-neutral-600"
                                  : ""
                              }`}
                            >
                              <Typography className="px-lg text-white">
                                {submenu.label}
                              </Typography>
                            </NavLink>
                          ),
                      )}
                  </div>
                </div>
              </div>
            ),
          )}
        </div>
      </div>
    </aside>
  );
};

export default Sidebar;
