/* eslint-disable no-unused-vars */
import {
  Card,
  CardBody,
  CardHeader,
  Typography,
} from "@material-tailwind/react";
import { useEffect, useState } from "react";
import { Button } from "../../../atoms/Button";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../../api/configApi";
import { ReactComponent as FilterCheck } from "../../../assets/icons/filter-check.svg";
import AlertNotification from "../../../atoms/AlertNotification";
import { FaCheckCircle } from "react-icons/fa";
import LoadingAnimation from "../../../atoms/LoadingAnimation";

import * as Sentry from "@sentry/react";

const RecomendationAddHouse = () => {
  const { id } = useParams();
  const [error, setError] = useState("");
  const alert = JSON.parse(localStorage.getItem("response success add farmer"));
  const [notif, setNotif] = useState(alert);
  const [loading, setLoading] = useState(false);
  const [cageName, setCageName] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchDetailCage = async () => {
      setLoading(true);
      try {
        const response = await api.get(`/v1/houses/${id}`);
        setCageName(response?.data?.name);
        return response;
      } catch (error) {
        setError(error);
        Sentry.captureException(error);
      } finally {
        setLoading(false);
      }
    };

    fetchDetailCage();
  }, [id]);

  useEffect(() => {
    setTimeout(() => {
      setNotif(false);
      localStorage.clear("response success add farmer");
    }, 4000);
  }, [notif]);

  const handleAddLater = () => {
    navigate("/pengaturan-pengguna/farmer");
  };

  const handleAddNow = () => {
    navigate(`/kandang`);
    localStorage.setItem("settingTypeDetailCage", "akses");
  };

  return (
    <div className="flex h-auto xl:overflow-hidden overflow-auto">
      <div className="flex flex-col flex-1 overflow-hidden p-4">
        <Card
          className={`w-full h-auto border border-border-primary shadow-none`}
        >
          <CardHeader
            floated={false}
            shadow={false}
            className="p-0 m-0 border-b rounded-b-none"
          >
            <div className="flex justify-betwen p-6"></div>
          </CardHeader>

          {loading ? (
            <div className="flex justify-center items-center h-screen">
              <LoadingAnimation />
            </div>
          ) : (
            <CardBody className="hidden:overflow-scroll py-2xl px-3xl m-0 h-[704px]">
              <div className="w-full flex justify-center">
                <FilterCheck className="mt-[103px] mb-[39px]" />
              </div>
              <div className="w-full flex justify-center mb-sm">
                <Typography className="text-xl font-semibold p-2 text-text-primary">
                  Peternak berhasil di tambahkan
                </Typography>
              </div>
              <div className="w-full flex justify-center mb-6xl">
                <Typography className="text-md font-normal px-2 text-text-primary max-w-[600px] text-center">
                  Peternak baru telah berhasil ditambahkan. Silakan tambahkan
                  kandang untuk memulai periode.
                </Typography>
              </div>
              <div className="w-full flex justify-center">
                <Button
                  label="Tambahkan nanti"
                  size="medium"
                  type="btnSecondary"
                  className="mr-md"
                  onClick={() => handleAddLater()}
                />
                <Button
                  label="Ke menu kandang"
                  size="medium"
                  type="btnPrimary"
                  onClick={() => handleAddNow()}
                />
              </div>
            </CardBody>
          )}
        </Card>
      </div>

      {notif && (
        <AlertNotification
          icon={<FaCheckCircle />}
          color="green"
          message="Peternak berhasil ditambahkan"
        />
      )}
    </div>
  );
};

export default RecomendationAddHouse;
