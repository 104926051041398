/* eslint-disable no-unused-expressions */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  ChevronUpDownIcon,
  MagnifyingGlassIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/outline";

import { FiFilter } from "react-icons/fi";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Typography,
  Chip,
  Popover,
  PopoverContent,
  PopoverHandler,
} from "@material-tailwind/react";
import { Button } from "../../../../atoms/Button";
import { ReactComponent as Plus } from "../../../../assets/icons/plus.svg";
import { useEffect, useState } from "react";
import Pagination from "../../../../atoms/Pagination";
import { FaCheckCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import api from "../../../../api/configApi";
import ConfirmDialog from "../../../../atoms/ConfirmDialog";
import AlertNotification from "../../../../atoms/AlertNotification";
import Input from "../../../../atoms/Input";
import { ReactComponent as EmptyIcon } from "../../../../assets/icons/emptyIconn.svg";
import LoadingAnimation from "../../../../atoms/LoadingAnimation";
import { useRef } from "react";
import AlertModal from "../../../../atoms/AlertModal";
import DropdownWithSearchInput from "../../../../atoms/DropdownWithSearchInput";
import DropdownFilterStatus from "../../../../atoms/DropdownFilterStatus";
import { useSelector } from "react-redux";
import EditKandangModal from "../../../../molecules/master/kandang/EditKandangModal";
import Cookies from "js-cookie";
import { ButtonIcon } from '../../../../atoms/ButtonIcon'

const TABLE_HEAD = [
  "No",
  "Pemilik kandang",
  "Nama kandang",
  "ID kandang",
  "Nomor Kandang",
  "Kapasitas",
  "Kabupaten/kota",
  "Provinsi",
  "Nama unit",
  "Status kandang",
  "",
];

const Kandang = () => {
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  let currentPageCookies = parseInt(Cookies.get("current-page-kandang"));
  const [currentPage, setCurrentPage] = useState(
    currentPageCookies ? currentPageCookies : 1
  );
  const [totalPages, setTotalPages] = useState(1);
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [search, setSearch] = useState("");
  const [searchProvince, setSearchProvince] = useState("");
  const [searchCity, setSearchCity] = useState("");
  const [cage, setCage] = useState([]);
  const [isDelete, setIsDelete] = useState(false);
  const [selectedId, setSelectedId] = useState({});
  const alert = JSON.parse(localStorage.getItem("response success"));
  const alertDelete = JSON.parse(localStorage.getItem("response delete"));
  const alertEditSuccess = JSON.parse(
    localStorage.getItem("responseEditSuccess")
  );
  const [notifEditSuccess, setNotifEditSuccess] = useState(alertEditSuccess);
  const [deleteNotif, setDeleteNotif] = useState(alertDelete);
  const [notif, setNotif] = useState(alert);

  const sortableColumns = [
    "",
    "owner.fullName",
    "name",
    "maxCapacity",
    "city.name",
    "city.province.name",
    "unit.name",
    "isActive",
  ];
  const [sortBy, setSortBy] = useState("createdAt");
  const [descending, setDescending] = useState(true);
  const [regions, setRegions] = useState([]);
  const [checkedRegions, setCheckedRegions] = useState([]);
  const [checkedCities, setCheckedCities] = useState([]);
  const [checkedUnits, setCheckedUnits] = useState([]);
  const [checkedRegionsString, setCheckedRegionsString] = useState("");
  const [checkedCitiesString, setCheckedCitiesString] = useState("");
  const [checkedUnitsString, setCheckedUnitsString] = useState("");
  const [lastLabelProvince, setLastLabelProvince] = useState("");
  const [lastLabelCities, setLastLabelCities] = useState("");
  const [lastLabelUnits, setLastLabelUnits] = useState("");
  const [cities, setCities] = useState([]);
  const [initialCapacity, setInitialCapacity] = useState(null);
  const [validationErrorInitial, setValidationErrorInitial] = useState("");
  const [finalCapacity, setFinalCapacity] = useState(null);
  const [validationErrorFinal, setValidationErrorFinal] = useState("");

  const [selectedUnit, setSelectedUnit] = useState([0]);
  const [selectedRegion, setSelectedRegion] = useState([0]);
  const [isActiveStatus, setIsActiveStatus] = useState(null);
  const [startFetch, setStartFetch] = useState(false);

  const [notifDelete, setNotifDelete] = useState(false);
  const [responseDelete, setResponseDelete] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [listMasterUnits, setListMasterUnits] = useState([]);
  const [searchMasterUnit, setSearchMasterUnit] = useState("");

  const [isWarningDeleteOpen, setWarningDeleteOpen] = useState(false);
  const [messageError, setMessageError] = useState("");

  const [implementFilter, setImplementFilter] = useState(false);
  const [reloadFilter, setReloadFilter] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState();
  const [labelStatus, setLabelStatus] = useState(null);
  const [countFilter, setCountFilter] = useState(0);
  const [isFetchError, setIsFetchError] = useState(false);
  const tableRef = useRef(null);
  const [kandangId, setKandangId] = useState();
  const [isEditCage, setIsEditCage] = useState(false);
  const [isOpenFilterStatus, setIsOpenFilterStatus] = useState(false);
  const [users, setUsers] = useState(null);
  const [searchRegionNew, setSearchRegionNew] = useState("");
  const [searchRegions, setSearchRegions] = useState([]);
  const [lastLabelUnit, setLastLabelUnit] = useState("");
  const [newRegions, setNewRegions] = useState([]);
  const [newLastLabel, setNewLastLabel] = useState("");
  const [checkRegions, setChecksRegions] = useState([]);
  const [checkedNewRegionsString, setCheckedNewRegionsString] = useState("");
  const [newLastLabelUnit, setNewLastLabelUnit] = useState("");
 

  Cookies.set("current-page-kandang", currentPage);

  const handleCloseWarningDelete = () => {
    setWarningDeleteOpen(false);
  };

  const handleCloseEditDialog = () => {
    setIsEditCage(false);
  };

  useEffect(() => {
    const dataFetchListKandang = async () => {
      setIsLoading(true);
      try {
        const params = {
          itemPerPage: search.length > 0 ? null : 10,
          search: search,
          currentPage: search.length > 0 ? null : currentPage - 1,
          sortBy: sortBy,
          descending: descending,
        };

        if (selectedStatus !== null) {
          params.isActive = selectedStatus;
        }

        if (implementFilter === true || reloadFilter === true) {
          params.province = checkedRegionsString ? checkedRegionsString : "";
          params.city = checkedCitiesString ? checkedCitiesString : "";
          params.initialCapacity =
            initialCapacity !== null ? initialCapacity : null;
          params.finalCapacity = finalCapacity !== null ? finalCapacity : null;
          params.unit = checkedUnitsString ? checkedUnitsString : "";
          params.region = checkedNewRegionsString
            ? checkedNewRegionsString
            : "";
        }
        const responseList = await api.get("/v1/houses", { params });

        setCage(responseList?.data?.contents);
        setTotalPages(responseList?.data?.totalPages);
        if (!responseList) {
          setIsOpenFilter(false);
        }
        setIsFetchError(false);
      } catch (error) {
        console.log(error.message);
        setIsFetchError(true);
      } finally {
        setIsLoading(false);
      }
    };

    dataFetchListKandang();
  }, [
    search,
    currentPage,
    descending,
    isActiveStatus,
    sortBy,
    implementFilter,
    reloadFilter,
    selectedStatus,
  ]);

  useEffect(() => {
    const dataFetchDropdownRegion = async () => {
      try {
        const responseRegionDropdown = await api.get(
          `/v1/regions/id/province/dropdown`,
          {
            params: {
              search: searchProvince,
            },
          }
        );
        setRegions(responseRegionDropdown.data);
      } catch (error) {}
    };

    dataFetchDropdownRegion();
  }, [searchProvince]);

  useEffect(() => {
    setTimeout(() => {
      setNotif(false);
      localStorage.clear("response success");
    }, 4000);
  }, [notif]);

  useEffect(() => {
    setTimeout(() => {
      setNotifEditSuccess(false);
      localStorage.clear("responseEditSuccess");
    }, 4000);
  }, [notifEditSuccess]);

  useEffect(() => {
    setTimeout(() => {
      setDeleteNotif(false);
      localStorage.clear("response delete");
    }, 4000);
  }, [deleteNotif]);

  const handleCloseDianlog = () => {
    setSelectedId(null);
    setIsDelete(false);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleSort = (column) => {
    if (sortableColumns.includes(column)) {
      setCurrentPage(1);
      if (sortBy === column) {
        // Toggle descending if the same column is clicked
        setDescending(!descending);
      } else {
        // Change sorting column and set default descending to false
        setSortBy(column);
        setDescending(false);
      }
    }
  };

  const handleConfirmationDelete = async () => {
    try {
      const response = await api.delete(`/v1/houses/${kandangId}`);
      handleCloseDianlog();
      setNotifDelete(true);

      localStorage.setItem("response delete", JSON.stringify(!notifDelete));

      setResponseDelete(response.status);
      setKandangId(null);
      setIsDelete(true);
      setTimeout(() => setIsDelete(false, 2000));
      window.location.reload();
    } catch (error) {
      handleCloseDianlog();
      setWarningDeleteOpen(true);
      return error;
    }
  };

  useEffect(() => {
    if (responseDelete) {
      setTimeout(() => {
        setNotifDelete(true);
        setNotifDelete(false);
      }, 2000);
    }
  }, [notifDelete, responseDelete]);

  const statusKandang = [
    {
      id: 1,
      IsActive: true,
      labels: ["Aktif"],
      labelsPlaceholder: "Aktif",
    },
    {
      id: 2,
      IsActive: false,
      labels: ["Rehat"],
      labelsPlaceholder: "Rehat",
    },
  ];

  const handleTerapkanFilter = () => {
    setIsOpenFilter(false);
    setCountFilter(0);
    let count = 0;

    if (implementFilter === true) {
      setReloadFilter(true);
    }

    if (checkedRegionsString !== "") {
      count = count + 1;
    }

    if (checkedCitiesString !== "") {
      count = count + 1;
    }

    if (checkedUnitsString !== "") {
      count = count + 1;
    }

    if (checkedNewRegionsString !== "") {
      count = count + 1;
    }

    if (initialCapacity?.length > 0 || finalCapacity?.length > 0) {
      count = count + 1;
    }

    setCountFilter(count);
    setImplementFilter(true);
    setCurrentPage(1);
  };

  useEffect(() => {
    setReloadFilter(false);
  }, [reloadFilter]);

  const handleResetFilter = () => {
    setImplementFilter(false);
    setIsOpenFilter(false);
    setSelectedRegion("");
    setSelectedUnit("");
    setInitialCapacity(null);
    setIsActiveStatus(null);
    setFinalCapacity(null);
    setCheckedCities([]);
    setCheckedCitiesString("");
    setCheckedRegions([]);
    setCheckedRegionsString("");
    setCheckedUnits([]);
    setCheckedUnitsString("");
    setCountFilter(0);
    setValidationErrorInitial("");
    setValidationErrorFinal("");
    setNewLastLabelUnit("");
    setNewLastLabel("");
    setCheckedNewRegionsString("");
    setChecksRegions([]);
  };

  const handleResetFilterStatus = () => {
    setSelectedStatus("");
    setLabelStatus("");
    setIsOpenFilterStatus(false);
  };

  const handleFilterUnit = (val) => {
    setSelectedUnit(val.id);
  };

  const handleFilterMasterUnit = (val) => {};

  const handleFilterRegion = (val) => {
    setSelectedRegion(val);
    setStartFetch(true);
  };

  const handleFilterStatus = (val) => {
    setSelectedStatus(val);
    setIsOpenFilterStatus(false);
    handlePageChange(1);
    if (val === true) {
      setLabelStatus("Aktif");
    } else if (val === false) {
      setLabelStatus("Rehat");
    } else if (val === null) {
      setLabelStatus("Semua Status");
    }
  };
  useEffect(() => {
    if (startFetch) {
      const fetchData = async () => {
        const data = await api.get(`/v1/regions/id/cities/dropdown`, {
          params: {
            search: searchCity,
            province: checkedRegionsString,
          },
        });
        setCities(data?.data);
      };
      fetchData();
    }
  }, [startFetch, checkedRegionsString, searchCity]);

  useEffect(() => {
    const fetchMasterUnit = async () => {
      try {
        const params = {
          search: searchMasterUnit,
          descending: false
        };
        const responseList = await api.get("/v1/units/dropdown", { params });
        setListMasterUnits(responseList?.data?.contents);
      } catch (error) {
        console.log(error.message);
      }
    };

    const fetchUsers = async () => {
      try {
        const responseUsers = await api.get("/v1/users/me");
        // console.log("response", responseUsers?.data)
        setUsers(responseUsers?.data);
      } catch (error) {
        console.log(error);
      }
    };

    const fetchRegion = async () => {
      try {
        const response = await api.get("/v1/regions/dropdown", {
          params: {
            search: searchRegionNew,
          },
        });
        setNewRegions(response?.data?.contents);
      } catch (error) {
        console.error("Error fetching roles:", error);
      }
    };

    fetchUsers();
    fetchRegion();
    fetchMasterUnit();
  }, [searchMasterUnit]);

  const handleClickDetail = (val) => {
    navigate(`/kandang/${val}`);
  };
  const handleClickEdit = (val) => {
    setKandangId(val);
    setIsEditCage(true);
  };
  const handleClickDelete = (val) => {
    setKandangId(val);
    setIsDelete(true);
  };

  const handleChangePage = (target) => {
    setCurrentPage(target);
  };

  const handleInitialCapacityChange = (e) => {
    const inputValue = e.target.value;

    if (inputValue <= 1000000) {
      setInitialCapacity(inputValue);
      setValidationErrorInitial("");
    } else {
      setValidationErrorInitial(
        "Nilai inisial tidak bisa melebihi 1 juta ekor."
      );
    }
  };

  const handleFinalCapacityChange = (e) => {
    const inputValue = e.target.value;

    if (inputValue <= 1000000) {
      setFinalCapacity(inputValue);
      setValidationErrorFinal("");
    } else {
      setValidationErrorFinal("Nilai final tidak bisa melebihi 1 juta ekor.");
    }
  };

  const handleSearchChange = (e) => {
    const keywordSearch = e.target.value;
    setSearch(keywordSearch);
    handlePageChange(1);
  };


  return (
    <div className="flex h-auto overflow-hidden">
      <div className="flex flex-col flex-1 overflow-hidden">
        <div className="p-4">
          <Card className="w-full h-auto border border-border-secondary shadow-none p-0 m-0">
            <CardHeader
              floated={false}
              shadow={false}
              className="p-2 m-0 border-none w-full"
            >
              <Typography
                variant="h2"
                className="text-xl font-bold p-4 text-text-primary"
              >
                Kandang
              </Typography>

              <div className="flex justify-between gap-8">
                <div className="text-left flex gap-4 pl-4 mb-1">
                  <Input
                    type="text"
                    id="search"
                    placeholder="Cari pemilik kandang, nama kandang"
                    className="min-w-[350px] max-w-[420px] border border-border-secondary"
                    size="medium"
                    value={search}
                    onChange={handleSearchChange}
                    componentLeft={<MagnifyingGlassIcon className="h-5 w-5" />}
                  />

                  <div>
                    <Popover
                      placement="bottom"
                      open={isOpenFilter}
                      handler={() => setIsOpenFilter(!isOpenFilter)}
                    >
                      <PopoverHandler className="cursor-pointer">
                        <div>
                          <button
                            className={`${
                              countFilter > 0
                                ? "border-button-infoBorder"
                                : "border-neutral-300"
                            } p-5 h-11 px-xl py-2.5 justify-center items-center gap-md inline-flex text-base font-medium leading-normal rounded-md bg-white hover:bg-neutral-50 border text-text-secondary focus:shadow-sm focus:shadow-gray-300 hover:border-border-secondary`}
                          >
                            <div
                              className={`justify-center min-w-6 w-full items-center gap-2.5 flex cursor-pointer ${
                                countFilter > 0
                                  ? "text-fg-infoPrimary"
                                  : "text-text-secondary"
                              }`}
                            >
                              <div className={`w-auto font-medium`}>Filter</div>
                            </div>
                            <div className="w-6 h-6 relative flex items-center">
                              <FiFilter
                                className={`h-5 w-5 ${
                                  countFilter > 0 && "text-fg-infoPrimary"
                                }`}
                              />
                            </div>
                            {countFilter > 0 && (
                              <div className="w-6 h-6 px-3 py-1 bg-fg-infoPrimary rounded-full justify-center items-center gap-1 inline-flex">
                                <div className="text-white text-xs font-medium leading-[18px]">
                                  {countFilter}
                                </div>
                              </div>
                            )}
                          </button>
                        </div>
                      </PopoverHandler>

                      <PopoverContent className="top-[210px] left-[500px] z-50 w-[576px]">
                        {user?.level === "ALL_DATA" && (
                          <>
                            <Typography className="flex justify-between font-normal text-[11px] text-text-disabled leading-none text-quarterary w-full min-h-full">
                              Lokasi
                            </Typography>
                            <Typography className="flex justify-between font-medium text-xs text-text-primary leading-[18px] w-full min-h-full mt-6">
                              Provinsi
                            </Typography>

                            <div className="mt-2">
                              <DropdownWithSearchInput
                                placeholder="Semua provinsi"
                                placeholderInputSearch="Cari Provinsi"
                                options={regions?.contents}
                                onSelect={handleFilterRegion}
                                search={searchProvince}
                                setSearch={setSearchProvince}
                                onSelectString={setCheckedRegionsString}
                                lastLabel={lastLabelProvince}
                                setLastLabel={setLastLabelProvince}
                                positionClassName="top-[335px] left-[520px]"
                                selectCheckBox={checkedRegions}
                                setSelectCheckbox={setCheckedRegions}
                              />
                            </div>

                            <Typography className="flex justify-between font-medium text-xs text-text-primary leading-[18px] w-full min-h-full mt-6">
                              Kabupaten/Kota
                            </Typography>

                            <div className="mt-2">
                              <DropdownWithSearchInput
                                placeholder="Semua kab/kota"
                                placeholderInputSearch="Cari kab/kota"
                                options={cities?.contents}
                                onSelect={handleFilterUnit}
                                search={searchCity}
                                setSearch={setSearchCity}
                                onSelectString={setCheckedCitiesString}
                                lastLabel={lastLabelCities}
                                setLastLabel={setLastLabelCities}
                                positionClassName="top-[425px] left-[520px]"
                                selectCheckBox={checkedCities}
                                setSelectCheckbox={setCheckedCities}
                              />
                            </div>
                            <Typography className="flex justify-between font-medium text-xs text-text-primary leading-[18px] w-full min-h-full mt-6">
                              Unit
                            </Typography>

                            <div className="mt-2">
                              <DropdownWithSearchInput
                                placeholder="Semua unit"
                                placeholderInputSearch="Cari unit"
                                options={listMasterUnits}
                                onSelect={handleFilterMasterUnit}
                                search={searchMasterUnit}
                                setSearch={setSearchMasterUnit}
                                onSelectString={setCheckedUnitsString}
                                lastLabel={lastLabelUnits}
                                setLastLabel={setLastLabelUnits}
                                positionClassName="top-[515px] left-[520px]"
                                selectCheckBox={checkedUnits}
                                setSelectCheckbox={setCheckedUnits}
                              />
                            </div>
                          </>
                        )}

                        {user?.level === "MITRA" && (
                          <>
                            <Typography className="flex justify-between font-medium text-xs text-text-primary leading-[18px] w-full min-h-full mt-6">
                              Region
                            </Typography>

                            <div className="mt-2">
                              <DropdownWithSearchInput
                                placeholder="Pilih region"
                                placeholderInputSearch="Cari region"
                                options={newRegions}
                                onSelect={handleFilterRegion}
                                search={searchRegions}
                                setSearch={setSearchRegions}
                                onSelectString={setCheckedNewRegionsString}
                                lastLabel={newLastLabel}
                                setLastLabel={setNewLastLabel}
                                positionClassName="top-[335px] left-[520px]"
                                selectCheckBox={checkRegions}
                                setSelectCheckbox={setChecksRegions}
                              />
                            </div>

                            <Typography className="flex justify-between font-medium text-xs text-text-primary leading-[18px] w-full min-h-full mt-6">
                              Unit
                            </Typography>

                            <div className="mt-2">
                              <DropdownWithSearchInput
                                placeholder="Semua unit"
                                placeholderInputSearch="Cari unit"
                                options={listMasterUnits}
                                onSelect={handleFilterMasterUnit}
                                search={searchMasterUnit}
                                setSearch={setSearchMasterUnit}
                                onSelectString={setCheckedUnitsString}
                                lastLabel={newLastLabelUnit}
                                setLastLabel={setNewLastLabelUnit}
                                positionClassName="top-[515px] left-[520px]"
                                selectCheckBox={checkedUnits}
                                setSelectCheckbox={setCheckedUnits}
                              />
                            </div>
                          </>
                        )}

                        {user?.level === "REGION" && (
                          <>
                            <Typography className="flex justify-between font-medium text-xs text-text-primary leading-[18px] w-full min-h-full mt-6">
                              Unit
                            </Typography>

                            <div className="mt-2">
                              <DropdownWithSearchInput
                                placeholder="Semua unit"
                                placeholderInputSearch="Cari unit"
                                options={listMasterUnits}
                                onSelect={handleFilterMasterUnit}
                                search={searchMasterUnit}
                                setSearch={setSearchMasterUnit}
                                onSelectString={setCheckedUnitsString}
                                lastLabel={lastLabelUnits}
                                setLastLabel={setLastLabelUnits}
                                positionClassName="top-[515px] left-[520px]"
                                selectCheckBox={checkedUnits}
                                setSelectCheckbox={setCheckedUnits}
                              />
                            </div>
                          </>
                        )}
                        <Typography className="flex justify-between font-medium leading-none text-[11px] text-text-disabled w-full min-h-full mt-6">
                          Kapasitas kandang
                        </Typography>

                        <div className="mt-2 flex justify-between items-center">
                          <div className="relative w-full">
                            <Input
                              type="number"
                              id="initialCapasity"
                              placeholder="Kapasitas minimal"
                              className="max-w-full w-[200px]"
                              value={initialCapacity}
                              size="medium"
                              onChange={handleInitialCapacityChange}
                              placeholderSize={"medium"}
                              componentRight={
                                <Typography className="font-medium text-sm text-text-placeholder">
                                  Ekor
                                </Typography>
                              }
                            />
                          </div>

                          <hr className="mx-4 px-2 border-2 rounded-md border-neutral-500" />
                          <div className="relative w-full">
                            <Input
                              type="number"
                              id="finalCapasity"
                              placeholder="Kapasitas maksimal"
                              className="max-w-full"
                              value={finalCapacity}
                              size="medium"
                              onChange={handleFinalCapacityChange}
                              placeholderSize={"medium"}
                              componentRight={
                                <Typography className="font-medium text-sm text-text-placeholder">
                                  Ekor
                                </Typography>
                              }
                            />
                          </div>
                        </div>
                        <div className="flex justify-between items-center">
                          <div className="relative w-full text-center">
                            {validationErrorInitial && (
                              <p className="text-red-500 text-[11px] font-normal mt-2">
                                {validationErrorInitial}
                              </p>
                            )}
                          </div>
                          <hr className="mx-4 px-2 border-none" />
                          <div className="relative w-full text-center">
                            {validationErrorFinal && (
                              <p className="text-red-500 text-[11px] font-normal mt-2">
                                {validationErrorFinal}
                              </p>
                            )}
                          </div>
                        </div>

                        <div className="border-t border-gray-300 my-4" />

                        <div className="divide-y flex w-full gap-8 justify-between mt-4">
                          <div onClick={handleResetFilter}>
                            <Typography className="flex justify-between items-center text-sm text-text-primary font-medium leading-tight w-full min-h-full cursor-pointer">
                              Atur Ulang
                            </Typography>
                          </div>

                          <Button
                            size={"small"}
                            label="Terapkan"
                            onClick={handleTerapkanFilter}
                            className="cursor-pointer"
                          />
                        </div>
                      </PopoverContent>
                    </Popover>
                  </div>

                  <div>
                    <DropdownFilterStatus
                      data={statusKandang}
                      handleSelect={handleFilterStatus}
                      popoverContentPosition={"top-[240px] left-[600px]"}
                      label={labelStatus}
                      selectedStatus={selectedStatus}
                      onReset={handleResetFilterStatus}
                      isOpenFilter={isOpenFilterStatus}
                      setIsOpenFilter={setIsOpenFilterStatus}
                    />
                  </div>
                </div>

                <div className="text-center flex justify-end mr-[30px]">
                  <Button
                    label="Kandang baru"
                    size="medium"
                    type="btnPrimary"
                    componentRight={<Plus className="h-5 w-5" />}
                    onClick={() => navigate("/kandang/tambah-kandang")}
                  />
                </div>
              </div>
            </CardHeader>

            {isLoading ? (
              <div className="flex justify-center items-center h-screen">
                <LoadingAnimation />
              </div>
            ) : (
              <>
                {cage?.length === 0 || isFetchError ? (
                  <div className=" flex justify-center items-center h-screen">
                    <div>
                      <div className="flex justify-center">
                        <EmptyIcon className="ml-[30px]" />
                      </div>

                      <Typography
                        variant="h2"
                        className="text-xl font-semibold p-4 text-center text-text-secondary"
                      >
                        Data tidak ditemukan
                      </Typography>

                      <Typography
                        variant="medium"
                        className="flex justify-center font-normal leading-none w-full text-text-secondary min-h-full bg-gray-950"
                      >
                        Data yang Anda cari tidak ditemukan. Coba kata kunci
                        lain
                      </Typography>
                    </div>
                  </div>
                ) : (
                  <>
                    <CardBody className="p-0 relative overflow-scroll max-w-full max-h-[960px] custom-scrollbar">
                      <table
                        ref={tableRef}
                        className="table-auto w-full min-w-max text-left mt-4 border-separate border-spacing-0"
                      >
                        <thead>
                          <tr>
                            {TABLE_HEAD?.map((head, index) => {
                              return (
                                <th
                                  key={head}
                                  className={`border-y border-border-secondary bg-neutral-50 py-2 px-6  ${
                                    index === 10
                                      ? "sticky right-0 ml-4 z-10 p-0 shadow-[rgba(0,0,10,0.2)_-3px_0_12px_-5px] border-l"
                                      : ""
                                  }`}
                                >
                                  <div
                                    className={`flex justify-between items-center`}
                                  >
                                    <Typography
                                      className={`flex items-center text-sm font-semibold text-text-quarterary leading-tight h-[21px] ${
                                        index === 0 && index === 1
                                      }`}
                                    >
                                      {head}
                                    </Typography>
                                    {index !== 0 &&
                                      index !== 9 &&
                                      index !== TABLE_HEAD.length - 1 && (
                                        <button
                                          onClick={() =>
                                            handleSort(sortableColumns[index])
                                          }
                                          className="focus:outline-none ml-1"
                                        >
                                          <ChevronUpDownIcon
                                            strokeWidth={2}
                                            className={`h-4 w-4 text-right ${
                                              descending
                                                ? "transform rotate-180"
                                                : ""
                                            } text-text-quarterary`}
                                          />
                                        </button>
                                      )}
                                  </div>
                                </th>
                              );
                            })}
                          </tr>
                        </thead>

                        <tbody>
                          {cage?.map((val, index) => {
                            const isLast = index === cage.length - 1;
                            const classes = isLast
                              ? "p-4"
                              : "p-4 border-b border-border-secondary";

                            return (
                              <tr key={val.id}>
                                <td className={classes}>
                                  <div className="flex items-center gap-3">
                                    <Typography className="text-text-primary text-sm font-medium leading-tight pl-2">
                                      {(currentPage - 1) * 10 + index + 1}
                                    </Typography>
                                  </div>
                                </td>

                                <td className={`${classes} max-w-[250px]`}>
                                  <Typography className="text-text-primary text-sm font-medium leading-tight pl-2">
                                    {val?.owner?.fullName}
                                  </Typography>
                                </td>

                                <td className={`${classes} max-w-[250px]`}>
                                  <Typography className="text-text-primary text-sm font-medium leading-6 pl-2">
                                    {val?.name}
                                  </Typography>
                                </td>

                                <td className={`${classes} max-w-[250px]`}>
                                  <Typography className="text-text-primary text-sm font-medium leading-6 pl-2">
                                    {val?.code || "-"}
                                  </Typography>
                                </td>

                                <td className={`${classes} max-w-[250px]`}>
                                  <Typography className="text-text-primary text-sm font-medium leading-6 pl-2">
                                    {val.sequence === null ? "-" : val.sequence}
                                  </Typography>
                                </td>

                                <td className={classes}>
                                  <Typography className="text-text-primary text-sm font-medium leading-tight pl-2">
                                    {val.maxCapacity.toLocaleString("id-ID")}
                                  </Typography>
                                </td>

                                <td className={classes}>
                                  <Typography className="text-text-primary text-sm font-medium leading-tight pl-2">
                                    {val?.city?.labels}
                                  </Typography>
                                </td>

                                <td className={classes}>
                                  <Typography className="text-text-primary text-sm font-medium leading-tight pl-2">
                                    {val?.province?.labels}
                                  </Typography>
                                </td>

                                <td className={classes}>
                                  <Typography className="text-text-primary text-sm font-medium leading-tight pl-2">
                                    {val?.unit?.labels}
                                  </Typography>
                                </td>

                                <td className={`${classes}`}>
                                  <Typography className="flex justify-center text-text-primary text-sm font-medium leading-tight">
                                    <div className="w-max">
                                      <Chip
                                        size="sm"
                                        variant="ghost"
                                        value={val.isActive ? "Aktif" : "Rehat"}
                                        className={`text-sm font-inter font-normal leading-[18px] border normal-case ${
                                          val.isActive
                                            ? "text-green-600 border-green-200 bg-green-25"
                                            : "text-neutral-800 border-neutral-200 bg-neutral-50"
                                        }`}
                                        icon={
                                          val.isActive ? (
                                            <span className="mx-auto mt-1 block h-2 w-2 rounded-full bg-green-500" />
                                          ) : (
                                            <span className="mx-auto mt-1 block h-2 w-2 rounded-full bg-gray-500" />
                                          )
                                        }
                                      />
                                    </div>
                                  </Typography>
                                </td>

                                <td
                                  className={`border-l border-b border-border-secondary bg-white sticky right-0 p-4 shadow-[rgba(0,0,10,0.2)_-3px_1px_12px_-5px]`}
                                >
                                  <div className="flex justify-between gap-2 m-0">
                                    {user?.role !== "Super Admin" &&
                                    val.isActive === true ? (
                                      <ButtonIcon
                                        className="z-25"
                                        icon="editDisabled"
                                        type="disabled"
                                        size="small"
                                        disabled="disabled"
                                      />
                                    ) : (
                                      <ButtonIcon
                                        className="z-25"
                                        onClick={() => handleClickEdit(val.id)}
                                        icon="edit"
                                        type="outlineWarning"
                                        size="small"
                                      />
                                    )}
                                    <ButtonIcon
                                      className="z-25"
                                      onClick={() => handleClickDetail(val.id)}
                                      icon="detail"
                                      type="outlineInfo"
                                      size="small"
                                    />
                                    {val.isActive === false ? (
                                      <ButtonIcon
                                        className="z-25"
                                        onClick={() =>
                                          handleClickDelete(val.id)
                                        }
                                        icon="delete"
                                        type="outlineError"
                                        size="small"
                                      />
                                    ) : (
                                      <ButtonIcon
                                        className="z-25"
                                        icon="deleteDisabled"
                                        type="disabled"
                                        size="small"
                                        disabled="disabled"
                                      />
                                    )}
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </CardBody>

                    <CardFooter className="items-center border-t border-border-secondary p-sm">
                      <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={handleChangePage}
                      />
                    </CardFooter>
                  </>
                )}
              </>
            )}
          </Card>
        </div>
      </div>

      <ConfirmDialog
        isOpen={isDelete}
        onClose={handleCloseDianlog}
        onConfirm={handleConfirmationDelete}
        title="Hapus data kandang"
        content="Anda yakin ingin menghapus data kandang?"
        btnLabel="Hapus"
        btnType="btnError"
      />

      <EditKandangModal
        isOpen={isEditCage}
        onClose={handleCloseEditDialog}
        cageID={kandangId}
      />

      {notifEditSuccess && (
        <AlertNotification
          icon={<FaCheckCircle />}
          color="green"
          message="Data profil kandang berhasil diubah."
        />
      )}

      {deleteNotif && (
        <AlertNotification
          icon={<FaCheckCircle />}
          color="green"
          message="Kandang berhasil dihapus."
        />
      )}

      <AlertModal
        isOpen={isWarningDeleteOpen}
        onClose={handleCloseWarningDelete}
        onConfirm={handleCloseWarningDelete}
        title="Tidak bisa menghapus data kandang"
        content={messageError}
        btnLabel="Oke"
        btnType="btnError"
        icon={
          <ExclamationTriangleIcon className="h-[58px] w-[58px] m-xl p-lg text-red-500 bg-red-100 rounded-full" />
        }
      />
    </div>
  );
};

export default Kandang;
