/* eslint-disable no-unused-expressions */
/* eslint-disable no-unused-vars */

import React, { useState, useEffect, useRef, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  Typography,
} from "@material-tailwind/react";
import { FaCheckCircle } from "react-icons/fa";
import api from "../../../../../api/configApi";
import Input from "../../../../../atoms/Input";
import { Button } from "../../../../../atoms/Button";
import {
  PlusIcon,
  ChevronUpDownIcon,
  MagnifyingGlassIcon,
  PencilIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/outline";
import { ReactComponent as EmptyIcon } from "../../../../../assets/icons/emptyIconn.svg";
import ConfirmDialog from "../../../../../atoms/ConfirmDialog";
import AlertNotification from "../../../../../atoms/AlertNotification";
import LoadingAnimation from "../../../../../atoms/LoadingAnimation";
import AlertModal from "../../../../../atoms/AlertModal";
import { ButtonIcon } from "../../../../../atoms/ButtonIcon";
import { ReactComponent as DetailPage } from "../../../../../assets/icons/open_page_detail.svg";
import moment from "moment";

const RecordingHarian = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [searchTerm, setSearchTerm] = useState("");
  const [isSearchProcess, setIsSearchProcess] = useState(false);
  const [getId, setGetId] = useState("");

  const [isDelete, setIsDelete] = useState(false);
  const [notifDelete, setNotifDelete] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isWarningSapronakOpen, setWarningSapronakOpen] = useState(false);
  const [isWarningDailyRecordCompleted, setWarningDailyRecordCompleted] =
    useState(false);
  const alertAdd = JSON.parse(localStorage.getItem("responseAddSuccess"));
  const alertEdit = JSON.parse(localStorage.getItem("responseSuccessEdit"));
  const [notifAdd, setNotfAdd] = useState(alertAdd);
  const [notifEdit, setNotfEdit] = useState(alertEdit);
  const [isDeleteSuccess, setDeleteSuccess] = useState(false);
  const [isDeleteError, setDeleteError] = useState(false);

  const [dailyRecords, setDailyRecords] = useState([]);
  const [sapronak, setSapronak] = useState([]);
  const [dayAge, setDayAge] = useState();
  const [currentPage, setCurrentPage] = useState();
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [sortBy, setSortBy] = useState("recordDate");
  const [descending, setDescending] = useState(false);
  const [isFetchError, setIsFetchError] = useState(false);
  const [loadInfiniteScroll, setLoadInifiniteScroll] = useState(false)
  const observer = useRef()

  const TABLE_HEAD = [
    "Tanggal",
    "Umur",
    "Populasi",
    "Deplesi",
    "Deplesi cum",
    <div class="p-0 m-0 grid grid-rows-2 w-full bg-[#22252A]">
      <div class="row-span-1 border bg-[#22252A] border-border-secondary text-center py-2 border-t-0">
        Pakan
      </div>
      <div className="row-span-1 flex justify-between">
        <Typography className="w-full bg-[#5A5C60] text-sm font-medium leading-tight border-l border-border-secondary text-center py-2">
          act
        </Typography>
        <Typography className="w-full bg-[#5A5C60] text-sm font-medium leading-tight border-x border-border-secondary text-center py-2">
          std
        </Typography>
      </div>
    </div>,
    <div class="p-0 m-0 grid grid-rows-2 w-full">
      <div class="row-span-1 border bg-[#22252A] border-border-secondary text-center border-t-0 border-l-0 py-2">
        FeedIntake
      </div>
      <div class="row-span-1 flex justify-start">
        <Typography className="w-full text-sm font-medium leading-tight text-center py-2 bg-[#5A5C60]">
          act
        </Typography>
        <Typography className="w-full text-sm font-medium leading-tight border-x border-border-secondary text-center py-2 bg-[#5A5C60]">
          std
        </Typography>
      </div>
    </div>,
    <div class="p-0 m-0 grid grid-rows-2 w-full">
      <div class="row-span-1 border bg-[#22252A] border-border-secondary text-center border-t-0 border-l-0 py-2">
        BW
      </div>
      <div class="row-span-1 flex justify-start">
        <Typography className="w-full text-sm font-medium leading-tight text-center py-2 bg-[#5A5C60]">
          act
        </Typography>
        <Typography className="w-full text-sm font-medium leading-tight border-x border-border-secondary text-center py-2 bg-[#5A5C60]">
          std
        </Typography>
      </div>
    </div>,
    <div class="p-0 m-0 grid grid-rows-2 w-full">
      <div class="row-span-1 border border-border-secondary border-t-0 border-l-0 py-2 text-center">
        FCR
      </div>
      <div class="row-span-1 flex justify-start">
        <Typography className="w-full text-sm font-medium leading-tight text-center py-2 bg-[#5A5C60]">
          act
        </Typography>
        <Typography className="w-full text-sm font-medium leading-tight border-x border-border-secondary  text-center py-2 bg-[#5A5C60]">
          std
        </Typography>
        <Typography className="w-full text-sm font-medium leading-tight border-r border-border-secondary text-center py-2 bg-[#5A5C60]">
          diff
        </Typography>
      </div>
    </div>,
    "",
  ];

  const sortableColumns = [
    "recordDate",
    "",
    "dailyPopulation",
    "",
    "cumulativeDepletion",
    "harvest",
  ];

  const dataSetting = [
    { id: 1, label: "Ubah", icon: <PencilIcon className="h-5 w-5" /> },
    { id: 3, label: "Detail", icon: <DetailPage /> },
  ];

  const handleClickEdit = (val) => {
    navigate(`/periode-budidaya/periode-berjalan/${id}/dataharian/ubah-data-harian/${val}`);
  }

  const handleClickDetail = (val) => {
    navigate(`/periode-budidaya/periode-berjalan/${id}/dataharian/${val}`);
  }


  const handleConfirmationDelete = async () => {
    try {
      await api.delete(`/v1/dailyRecords/${getId}`);
      handleCloseDialog();
      setNotifDelete(true);

      localStorage.setItem("response delete", JSON.stringify(!notifDelete));
      setDeleteSuccess(true);
      setTimeout(() => setDeleteSuccess(false), 4000);
      window.location.reload();
      localStorage.setItem("settingType", "data");
    } catch (error) {
      setDeleteError(true);
      handleCloseDialog();
      setTimeout(() => setDeleteError(false), 4000);
      setErrorMessage(error.response.data.error);
      console.log(error);
    }
  };

  const handleCloseDialog = () => {
    setGetId(null);
    setIsDelete(false);
  };

  useEffect(() => {
    setTimeout(() => {
      setNotfAdd(false);
      localStorage.clear("responseAddSuccess");
    }, 2000);
  }, [notifAdd]);

  useEffect(() => {
    setTimeout(() => {
      setNotfEdit(false);
      localStorage.clear("responseSuccessEdit");
    }, 2000);
  }, [notifEdit]);

  const handleSort = (column) => {
    if (sortableColumns.includes(column)) {
      if (sortBy === column) {
        // Toggle descending if the same column is clicked
        setDescending(!descending);
      } else {
        // Change sorting column and set default descending to false
        setSortBy(column);
        setDescending(false);
      }
    }
  };

  const handleSearchChange = (e) => {
    setIsSearchProcess(!isSearchProcess);
    const keywordSearch = e.target.value;
    setSearchTerm(keywordSearch);
    setIsSearchProcess(false);
  };


  useEffect(() => {
      if (searchTerm !== "") {
          setCurrentPage(1);
      }
      setIsSearchProcess(true);
  
      const fetchData = async () => {
        setIsLoading(true);
        try {
            const params = {
                itemPerPage: 10,
                search: searchTerm,
                currentPage: currentPage - 1,
                sortBy: sortBy,
                descending: descending,
                periodId: id,
            };

            const response = await api.get("/v1/dailyRecords/list", { params });
            // setDailyRecords(prevRecords => [...prevRecords, ...response.data.contents])
            setDailyRecords(response.data.contents)
            setTotalPages(response.data.totalPages);
            setIsFetchError(false);
        } catch (error) {
            console.error("Error fetching daily records data:", error);
            setIsFetchError(true);
        } finally {
            setIsLoading(false);
            setLoadInifiniteScroll(false);  // Corrected spelling here
        }
      };
  
      const fetchProducts = async () => {
        try {
            const params = {
                periodId: id,
            };
            const response = await api.get("/v1/sapronak/stocks", { params });
            setSapronak(response?.data);
        } catch (error) {
            console.log(error);
        }
      };


      fetchData();
      fetchProducts();
  }, [currentPage, searchTerm, sortBy, descending, id]);
  
  useEffect(() => {
    setLoadInifiniteScroll(false);  // Reset loadInfiniteScroll state when search term changes
  }, [searchTerm]);
  

  useEffect(() => {
    const fetchNextRecord = async () => {
      try {
        const params = {
          periodId: id,
        };
        const response = await api.get("/v1/dailyRecords/nextRecord", {
          params,
        });
        setDayAge(response?.data?.day);
      } catch (error) {
        console.log(error);
      }
    };

    fetchNextRecord();
  }, [id]);


  const handleAddClick = () => {
    if (sapronak.feedStocks?.length === 0) {
      setWarningSapronakOpen(true);
    } else if (dayAge === -1) {
      setWarningDailyRecordCompleted(true);
    } else {
      navigate(`/periode-budidaya/periode-berjalan/${id}/dataharian/tambah-data-harian`);
    }
  };

  const handleCloseWarningSapronak = () => {
    setWarningSapronakOpen(false);
  };

  const handleCloseWarningDailyRecordCompleted = () => {
    setWarningDailyRecordCompleted(false);
  };

  useEffect(() => {
    if (searchTerm !== "") {
      setCurrentPage(1)
    }
  }, [searchTerm])

  return (
    <div className="p-0 m-0 flex xl:overflow-hidden overflow-hidden w-full">
      <Card className="p-0 w-full grid sm:grid-cols-1 shadow-none">
        <CardHeader
          floated={false}
          shadow={false}
          className="py-md border-none"
        >
          <div className="grid grid-cols-4 gap-8">
            <div className="text-left ml-2">
              <Input
                type="text"
                id="search"
                placeholder="Pencarian.."
                className="max-w-[364px]"
                value={searchTerm}
                onChange={handleSearchChange}
                size="medium"
                componentLeft={<MagnifyingGlassIcon className="h-5 w-5" />}
                componentRight={
                  !isSearchProcess && (
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                      className="animate-spin fill-neutral-400 max-w-sm"
                    >
                      <path
                        d="M10.72,19.9a8,8,0,0,1-6.5-9.79A7.77,7.77,0,0,1,10.4,4.16a8,8,0,0,1,9.49,6.52A1.54,1.54,0,0,0,21.38,12h.13a1.37,1.37,0,0,0,1.38-1.54,11,11,0,1,0-12.7,12.39A1.54,1.54,0,0,0,12,21.34h0A1.47,1.47,0,0,0,10.72,19.9Z"
                        className="spinner_6kVp"
                      />
                    </svg>
                  )
                }
              />
            </div>
            <div className="relative"></div>
            <div></div>
            <div className="text-center flex justify-end">
              <Button
                label="Tambah data harian"
                size="medium"
                type="btnPrimary"
                onClick={handleAddClick}
                componentRight={<PlusIcon className="h-5 w-5" />}
              />
            </div>
          </div>
        </CardHeader>

        {isLoading ? (
          <div className="flex justify-center items-center">
            <LoadingAnimation />
          </div>
        ) : (
          <>
            {dailyRecords?.length === 0 || isFetchError ? (
              <div className=" flex justify-center items-center mr-3 mt-[-12px]">
                <div>
                  <div className="flex justify-center">
                    <EmptyIcon className="ml-[30px]" />
                  </div>
                  <Typography className="text-md text-text-secondary font-semibold p-4 text-center">
                    Data tidak ditemukan
                  </Typography>

                  <Typography className="flex justify-between text-text-quarterary font-normal leading-none w-full min-h-full">
                    Data yang Anda cari tidak ditemukan. Coba kata kunci lain
                  </Typography>
                </div>
              </div>
            ) : (
              <>
                <CardBody className="p-0 m-0 overflow-scroll max-h-[660px]">
                  <table className="w-full min-w-max table-auto text-left h-[60%]">
                    <thead className="top-0 z-10 sticky">
                      <tr>
                        {TABLE_HEAD.map((head, index) => (
                          <th
                            key={head}
                            className={`border-y text-text-white border-[#22252A] bg-[#22252A] p-0 ${
                              index === TABLE_HEAD.length - 1
                                ? "w-[40px] sticky right-0 ml-4 z-10 p-0 shadow-[rgba(0,0,10,0.2)_-3px_0_12px_-5px]"
                                : index === TABLE_HEAD.length - 2
                                ? "w-[210px]"
                                : index === 1 ||
                                  index === 2 ||
                                  index === 3 ||
                                  index === 4
                                ? "w-[100px]"
                                : "w-[140px]"
                            }`}
                          >
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="flex justify-center font-normal leading-none text-text-white"
                            >
                              {head}{" "}
                              {index !== 5 &&
                                index !== 6 &&
                                index !== 7 &&
                                index !== 8 &&
                                index !== 9 &&
                                index !== 2 &&
                                index !== 3 &&
                                index !== 4 &&
                                index !== TABLE_HEAD.length - 1 && (
                                  <button
                                    onClick={() =>
                                      handleSort(sortableColumns[index])
                                    }
                                    className="focus:outline-none ml-1"
                                  >
                                    <ChevronUpDownIcon
                                      strokeWidth={2}
                                      className={`h-4 w-4 text-text-white ${
                                        descending ? "transform rotate-180" : ""
                                      }`}
                                    />
                                  </button>
                                )}
                            </Typography>
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {dailyRecords.map((daily, index) => {
                        console.log("dayli records", dailyRecords)
                        const isLast = index === dailyRecords?.length - 1;
                        const classes = isLast
                          ? "p-4 z-2"
                          : "p-4 border-b border-border-secondary";

                        return (
                        <tr
                          key={daily.id}
                          className={
                            (index + 1) % 2 === 0 && `bg-neutral-100 `
                          }
                        >
                          <td className={classes}>
                            <div className="flex items-center gap-3 text-text-primary text-sm text-center font-medium leading-tight">
                              {moment(daily.recordDate).format(
                                "DD MMMM YYYY"
                              )}
                            </div>
                          </td>
                          <td
                            className={`${classes} text-text-primary text-sm font-medium leading-tight text-center`}
                          >
                            {daily.day}
                          </td>
                          <td
                            className={`${classes} text-text-primary text-sm font-medium leading-tight text-center`}
                          >
                            {daily.dailyPopulation?.toLocaleString("id-ID")}
                          </td>
                          <td
                            className={`${classes} text-text-primary text-sm font-medium leading-tight text-center`}
                          >
                            {daily.totalDepletion === 0 ? "-" : daily.totalDepletion?.toLocaleString("id-ID")}
                          </td>
                          <td
                            className={`${classes} text-text-primary text-sm font-medium leading-tight text-center`}
                          >
                            {daily.cumulativeDepletion === 0 ? "-" : daily.cumulativeDepletion.toLocaleString("id-ID")}
                          </td>
                         
                          <td
                            className={`${classes} text-text-primary text-sm font-medium leading-tight text-center`}
                          >
                            <div class="grid grid-cols-2">
                              <div class="col-span-1">
                                {daily.dailyFeedUsed === 0 ? "-" : daily.dailyFeedUsed?.toLocaleString("id-ID")}
                              </div>
                              <div class="col-span-1">
                                {daily.standardFeed === 0 ? "-" : (daily.standardFeed*50).toLocaleString("id-ID")}
                                {/* kg */}
                              </div>
                            </div>
                          </td>
                          <td
                            className={`${classes} text-text-primary text-sm font-medium leading-tight text-center`}
                          >
                            <div class="grid grid-cols-2">
                              <div class="col-span-1">{daily.feedIntake === 0 ? "-" : daily.feedIntake?.toLocaleString("id-ID")}</div>
                              <div class="col-span-1">{daily.standardFi === 0 ? "-" : daily.standardFi?.toLocaleString("id-ID")}</div>
                              {/* <div class="col-span-1">kg</div> */}
                            </div>
                          </td>
                          <td
                            className={`${classes} text-text-primary text-sm font-medium leading-tight text-center`}
                          >
                            <div class="grid grid-cols-2">
                              <div class="col-span-1">
                                {daily.averageWeight === 0 ? "-" : daily.averageWeight?.toLocaleString("id-ID")}
                              </div>
                              <div class="col-span-1">
                                {daily.standardWeight === 0 ? "-" : daily.standardWeight?.toLocaleString("id-ID")}
                              </div>
                            </div>
                          </td>
                          <td
                            className={`${classes} text-text-primary text-sm font-medium leading-tight text-center`}
                          >
                            <div class="grid grid-cols-3">
                              <div class="col-span-1">{daily.actualFcr === 0 ? "-" : daily.actualFcr?.toLocaleString("id-ID")}</div>
                              <div class="col-span-1">
                                {daily.standardFcr === 0 ? "-" : daily.standardFcr?.toLocaleString("id-ID")}
                              </div>
                              <div class="col-span-1">
                                {daily.diffFcr > 0 ? (
                                  <Typography className="text-text-errorPrimary text-md font-medium leading-tight">
                                    {daily.diffFcr === 0 ? "-" : daily.diffFcr?.toLocaleString("id-ID")}
                                  </Typography>
                                ) :  daily.diffFcr === 0.0 ? (
                                  <Typography className="text-text-primary text-sm font-medium leading-tight text-center">
                                     {daily.diffFcr === 0 ? "-" : daily.diffFcr?.toLocaleString("id-ID")}
                                  </Typography>
                                ) : (
                                  <Typography className="text-text-successPrimary text-md font-medium leading-tight">
                                     {daily.diffFc === 0 ? "-" : daily.diffFc?.toLocaleString("id-ID")}
                                  </Typography>
                                )}
                              </div>
                            </div>
                          </td>
                          <td
                            className="border-l border-b border-border-secondary bg-white sticky right-0 p-4 shadow-[rgba(0,0,10,0.2)_-3px_1px_12px_-5px] z-0"
                          >
                            <div className="flex justify-between gap-2 m-0">
                              <ButtonIcon
                                className="z-25"
                                onClick={() => handleClickEdit(daily.id)}
                                icon="edit"
                                type="outlineWarning"
                                size="small"
                              />
                              <ButtonIcon
                                className="z-25"
                                onClick={() => handleClickDetail(daily.id)}
                                icon="detail"
                                type="outlineInfo"
                                size="small"
                              />

                            </div>
                          </td>
                          </tr>
                        )
                      })}

                     {loadInfiniteScroll && <Typography className="flex justify-center">...Loading</Typography>}
                    </tbody>
                  </table>
                </CardBody>
                <CardFooter className="py-sm items-center border-t border-blue-gray-50">
                </CardFooter>
              </>
            )}
          </>
        )}
      </Card>
      <ConfirmDialog
        isOpen={isDelete}
        onClose={handleCloseDialog}
        onConfirm={handleConfirmationDelete}
        title="Hapus Data Harian"
        content="Anda yakin ingin melanjutkan tindakan ini?"
        btnLabel="Hapus"
        btnType="btnError"
      />

      <AlertModal
        isOpen={isWarningSapronakOpen}
        onClose={handleCloseWarningSapronak}
        onConfirm={handleCloseWarningSapronak}
        title="Tidak bisa menambahkan data harian"
        content="Tambahkan data sapronak terlebih dahulu sebelum menambahkan data harian."
        btnLabel="Oke"
        btnType="btnError"
        icon={
          <ExclamationTriangleIcon className="h-[58px] w-[58px] m-xl p-lg text-red-500 bg-red-100 rounded-full" />
        }
      />

      <AlertModal
        isOpen={isWarningDailyRecordCompleted}
        onClose={handleCloseWarningDailyRecordCompleted}
        onConfirm={handleCloseWarningDailyRecordCompleted}
        title="Data harian sudah lengkap"
        content="Saat ini semua data harian Anda sudah lengkap, Anda dapat menambahkan kembali pada hari berikutnya"
        btnLabel="Oke"
        btnType="btnWarning"
        icon={
          <ExclamationTriangleIcon className="h-[58px] w-[58px] mb-lg p-lg text-primary-600 bg-primary-100 rounded-full" />
        }
      />

      {isDeleteSuccess && (
        <AlertNotification
          icon={<FaCheckCircle />}
          color="green"
          message="Data harian berhasil di hapus."
        />
      )}

      {isDeleteError && (
        <AlertNotification
          icon={<FaCheckCircle />}
          color="red"
          message={errorMessage}
        />
      )}
      {notifAdd && (
        <AlertNotification
          icon={<FaCheckCircle />}
          color="green"
          message="Data harian berhasil ditambah."
        />
      )}

      {notifEdit && (
        <AlertNotification
          icon={<FaCheckCircle />}
          color="green"
          message="Data harian berhasil diubah."
        />
      )}
    </div>
  );
};

export default RecordingHarian;
