import React, { useState, useRef, useEffect } from "react";
import { Calendar } from "@hassanmojab/react-modern-calendar-datepicker";
import "@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css";
import { CiCalendar } from "react-icons/ci";

const RangeDatePicker = ({setStartDate, setFinalDate, selectedDayRange, setSelectedDayRange}) => {


  useEffect(() => {
    if (selectedDayRange.from && selectedDayRange.to) {
      const formatToYYYYMMDD = (day) =>
        `${day.year}-${String(day.month).padStart(2, "0")}-${String(day.day).padStart(2, "0")}`;

      setStartDate(formatToYYYYMMDD(selectedDayRange.from));
      setFinalDate(formatToYYYYMMDD(selectedDayRange.to));
    }
  }, [selectedDayRange, setStartDate, setFinalDate]);

  const [isCalendarVisible, setIsCalendarVisible] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const [isCalendarAbove, setIsCalendarAbove] = useState(false);

  const inputRef = useRef(null);
  const calendarRef = useRef(null);

  // Logika untuk menyesuaikan posisi kalender (atas/bawah)
  useEffect(() => {
    const adjustCalendarPosition = () => {
      if (inputRef.current && calendarRef.current) {
        const inputRect = inputRef.current.getBoundingClientRect();
        const calendarRect = calendarRef.current.getBoundingClientRect();

        const spaceBelow = window.innerHeight - inputRect.bottom;
        const spaceAbove = inputRect.top;

        if (
          spaceBelow < calendarRect.height &&
          spaceAbove > calendarRect.height
        ) {
          setIsCalendarAbove(true);
        } else {
          setIsCalendarAbove(false);
        }
      }
    };

    if (isCalendarVisible) {
      adjustCalendarPosition();
      window.addEventListener("resize", adjustCalendarPosition);
    }

    return () => {
      window.removeEventListener("resize", adjustCalendarPosition);
    };
  }, [isCalendarVisible]);

  // Fungsi untuk menangani klik di luar kalender dan input
  const handleClickOutside = (event) => {
    if (
      inputRef.current &&
      calendarRef.current &&
      !inputRef.current.contains(event.target) &&
      !calendarRef.current.contains(event.target)
    ) {
      setIsAnimating(true);
      setTimeout(() => setIsCalendarVisible(false), 200);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative">
      <div
        ref={inputRef}
        className="flex items-center p-xs cursor-pointer border border-border-primary rounded-md pr-lg"
        tabIndex={0}
        onFocus={() => {
          setIsCalendarVisible(true);
          setIsAnimating(false);
        }}
      >
        <input
          id="range-input"
          type="text"
          value={
            selectedDayRange.from && selectedDayRange.to
              ? `${selectedDayRange.from.day}/${selectedDayRange.from.month}/${selectedDayRange.from.year} - ${selectedDayRange.to.day}/${selectedDayRange.to.month}/${selectedDayRange.to.year}`
              : ""
          }
          placeholder="Pilih tanggal"
          readOnly
          className="flex-grow rounded-md p-lg h-8 text-text-placeholder focus:outline-none"
        />
        <CiCalendar className="w-5 h-5" />
      </div>
      {isCalendarVisible && (
        <div
          ref={calendarRef}
          className={`absolute z-10 bg-white border border-gray-300 rounded-lg shadow-lg transition-transform duration-900 ${
            isAnimating ? "opacity-0 scale-25" : "opacity-100 scale-100"
          } ${isCalendarAbove ? "bottom-full mb-2" : "top-full mt-2"}`}
        >
          <Calendar
            value={selectedDayRange}
            onChange={setSelectedDayRange}
            shouldHighlightWeekends
            calendarClassName="custom-calendar"
            colorPrimary="#F5BE01"
            colorPrimaryLight="#FEF8E2"
            colorPrimaryDark="#F5BE01"
          />
        </div>
      )}
    </div>
  );
};

export default RangeDatePicker;
