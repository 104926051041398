/* eslint-disable no-unused-vars */
import { ChevronUpDownIcon } from "@heroicons/react/24/outline";
import {
  Avatar,
  Card,
  CardBody,
  CardFooter,
  Popover,
  PopoverContent,
  PopoverHandler,
  Typography,
} from "@material-tailwind/react";
import moment from "moment";
import "moment/locale/id";
import { ReactComponent as BurgerDots } from "../../assets/icons/burgers-dot.svg";
import { ReactComponent as EditIcon } from "../../assets/icons/edit_icon.svg";
import { ReactComponent as DeleteIcon } from "../../assets/icons/deleted.svg";
import { ReactComponent as IconSapronak } from "../../assets/icons/sapronak-icon.svg"
import { ReactComponent as IconRightblue } from "../../assets/icons/chevron-right-blue.svg"
import { useNavigate, useParams } from "react-router-dom";
import ConfirmDialog from "../../atoms/ConfirmDialog";
import { useEffect, useState } from "react";
import Pagination from "../../atoms/Pagination";
import DefaultImagePakan from "../../img/Pakan_image.png";
import api from "../../api/configApi";
import AlertNotification from "../../atoms/AlertNotification";
import { FaCheckCircle } from "react-icons/fa";
import { Button } from "../../atoms/Button";
import DrawerSapronak from "../../atoms/DrawerSapronak";
import TagLabel from "../../atoms/TagLabel";

const TABLE_HEAD = [
  "Produk",
  "Tanggal masuk",
  "Jumlah pakan masuk (zak)",
  "Jumlah pakan masuk (kg)",
  "Harga Satuan",
  "Total Harga",
  "",
];

const TablePakan = ({
  dataTable,
  totalPages,
  currentPage,
  setCurrentPage,
  descending,
  sortableColumns,
  handleSort,
  periodId
}) => {
  const navigate = useNavigate();
  // eslint-disable-next-line no-unused-vars
  const dataSetting = [
    { id: 1, label: "Ubah", icon: <EditIcon /> },
    { id: 2, label: "Hapus", icon: <DeleteIcon /> },
  ];
  const [showAll, setShowAll] = useState(false)
  const [openDialog, setOpenDialog] = useState(false);
  const [getId, setGetId] = useState(null);
  const [errorNotifDelete, setErrorNotifDelete] = useState(false);
  const [errorDeleteMessage, setErrorDeleteMessage] = useState(false);
  const [responseStockFeed, setResponseStockFeed] = useState(null)
  const [loading, setLoading] = useState(false)


  const handleChangePage = (target) => {
    setCurrentPage(target);
  };

  // const handleClickToDetail = (val) => {
  //   console.log("val", val)
  // }

  const handleShowAll = () => {
    setShowAll(!showAll)
  }

  const handleConfirmationDelete = async () => {
    try {
      await api.delete(`/v1/feeds/in/${getId}`);
      setOpenDialog(false);
      window.location.reload();
      localStorage.setItem("settingType", "sapronak");
      localStorage.setItem("settingTypeSapronak", "pakan");
      localStorage.setItem(
        "successNotifDeleteFeed",
        JSON.stringify(true)
      );
    } catch (error) {
      setOpenDialog(false);
      setErrorNotifDelete(!errorNotifDelete);
      setErrorDeleteMessage(error.response.data.error);
    }
  };

  const handleConditionalRoute = (target) => {
    if (target === 1) {
      navigate(`/pakan/update-pakan/${getId}`);
    } else if (target === 2) {
      setOpenDialog(!openDialog);
    }
  };

  useEffect(() => {
    setLoading(true)

    const fetchStock = async () => {
      try {
        const responseStock = await api.get(`/v1/feeds/stock/list`, {
          params: {
            periodId: periodId
          }
        })
        setResponseStockFeed(responseStock?.data?.contents)
      } catch (error) {
        console.log("error", error)
      } finally {
        setLoading(false)
      }
    }

    fetchStock()
  }, [periodId])

  useEffect(() => {
    setTimeout(() => {
      setErrorNotifDelete(false);
      setErrorDeleteMessage("");
    }, 4000);
  }, [errorNotifDelete]);


  return (
    <>
      <Card className="h-full w-full shadow-none">
        <Typography className="text-md font-semibold p-6 text-text-primary">
          Jenis pakan ({responseStockFeed?.length})
        </Typography>

        <div className="flex flex-wrap p-[20px] gap-4">

      {loading
          ? Array(3) 
      .fill(0)
      .map((_, index) => (
        <Card
          key={index}
          className="flex-shrink-0 w-full sm:w-[40%] lg:w-[26%] h-auto shadow-none border border-border-secondary rounded-[8px]"
        >
          <CardBody className="relative overflow-hidden border-border-secondary rounded p-3">
            <div className="flex justify-between items-start">
              <div>
                <div className="h-6 bg-gray-300 animate-pulse rounded w-2/3 mb-2"></div>
                <div className="h-4 bg-gray-300 animate-pulse rounded w-1/3"></div>
              </div>
              <div className="w-[40px] h-[40px] sm:w-[50px] sm:h-[50px] bg-gray-300 animate-pulse rounded"></div>
            </div>

            <div className="flex justify-between mt-[10px]">
              <div className="h-4 bg-gray-300 animate-pulse rounded w-1/2"></div>
              <div className="h-4 bg-gray-300 animate-pulse rounded w-1/4"></div>
            </div>

            <div className="flex justify-between mt-[10px]">
              <div className="h-4 bg-gray-300 animate-pulse rounded w-1/2"></div>
              <div className="h-4 bg-gray-300 animate-pulse rounded w-1/4"></div>
            </div>
          </CardBody>
        </Card>
      ))
      : responseStockFeed?.slice(0, 3).map((val) => (
          <Card
            key={val.id} 
            className="flex-shrink-0 w-full sm:w-[40%] lg:w-[26%] h-auto shadow-none border border-border-secondary rounded-[8px]"
          >
            <CardBody className="relative overflow-hidden border-border-secondary rounded p-3">
              <div className="flex justify-between items-start">
                <div>
                  <Typography className="text-lg sm:text-xl text-text-primary font-bold leading-tight">
                    {val.labels}
                  </Typography>
                  <div className="text-start">
                    <TagLabel size="xsmall" type="info" children={val?.type?.labels} />
                  </div>
                </div>
                <IconSapronak className="w-[40px] h-[40px] sm:w-[50px] sm:h-[50px]" />
              </div>

              <div className="flex justify-between mt-[10px]">
                <Typography className="text-text-quarterary text-sm sm:text-sm font-normal leading-tight">
                  Stok tersedia (Sak)
                </Typography>
                <Typography className="text-sm sm:text-sm text-text-primary font-bold leading-tight">
                  {val.stock / 50} Sak
                </Typography>
              </div>

              <div className="flex justify-between mt-[10px]">
                <Typography className="text-text-quarterary text-sm sm:text-sm font-normal leading-tight">
                  Stok tersedia (kg)
                </Typography>
                <Typography className="text-sm sm:text-sm text-text-primary font-bold leading-tight">
                  {typeof val.stock === "number"
                    ? val.stock.toLocaleString("id-ID")
                    : JSON.stringify(val.stock)}{" "}
                  kg
                </Typography>
              </div>
            </CardBody>
          </Card>
        ))}


          {responseStockFeed?.length > 3 && (
            <div className="mt-[50px]">
              <Button
                label="Lihat lainya"
                type="btnLinkBlue"
                onClick={handleShowAll}
                componentRight={<IconRightblue />}
              />
            </div>
          )}

        </div>

        {dataTable?.contents?.length === 0 ? (
          <div className="flex justify-center items-center h-screen">
            <div>
              <div className="flex justify-center">
                <Avatar
                  src={DefaultImagePakan}
                  alt="product image"
                  variant="rounded"
                  className="w-48 h-48"
                />
              </div>
              <Typography
                variant="h2"
                color="blue-gray"
                className="text-xl font-semibold p-4 text-center"
              >
                Data tidak ditemukan
              </Typography>

              <Typography
                variant="medium"
                color="blue-gray"
                className="flex justify-between font-normal leading-none opacity-70 w-full min-h-full bg-gray-950"
              >
                Data yang Anda cari tidak ditemukan. Coba kata kunci lain
              </Typography>
            </div>
          </div>
        ) : (
          <>
            <CardBody className="relative overflow-scroll max-w-full max-h-[960px] p-0">   
              <div className="flex justify-start p-[20px] mt-[14px]">
                <Typography className="text-md text-text-primary font-bold leading-tight">
                  Riwayat penambahan
                </Typography>
              </div>


              <table className="table-auto w-full min-w-max text-left mt-4 border-separate border-spacing-">
                <thead className="border-y border-border-secondary bg-neutral-50">
                  <tr>
                    {TABLE_HEAD?.map((head, index) => (
                      <th
                        key={head}
                        className={`border-y border-border-secondary bg-neutral-25 p-lg`}
                      >
                        <div
                          className={`flex ${
                            index === 0 || index === 1
                              ? "justify-between"
                              : "justify-end"
                          } `}
                        >
                          <Typography
                            className={`flex items-center leading-none h-[21px] font-bold text-xs text-text-primary ${
                              index === 0 || index === 1
                                ? "justify-start"
                                : "justify-end"
                            }`}
                          >
                            {head}
                          </Typography>
                          {index !== 5 && TABLE_HEAD.length - 1 && (
                            <button
                              onClick={() => handleSort(sortableColumns[index])}
                              className="focus:outline-none ml-1"
                            >
                              <ChevronUpDownIcon
                                strokeWidth={2}
                                className={` h-4 w-4 ${
                                  descending ? "transform rotate-180" : ""
                                }`}
                              />
                            </button>
                          )}
                        </div>
                      </th>
                    ))}
                  </tr>
                </thead>

                <tbody>
                  {dataTable?.contents?.map((val, index) => {
                    const isLast = index === dataTable.length - 1;
                    const clases = isLast
                      ? "p-lg"
                      : `p-lg border-t border-border-secondary`;

                    return (
                      <tr key={val?.id}>
                        <td className={clases + " pl-0"}>
                          <div className="flex items-center gap-3">
                            <div className="flex justify-around gap-2">
                              <div className="pl-3xl">
                                {val?.image === undefined ? (
                                  <Avatar
                                    src={DefaultImagePakan}
                                    alt="product image"
                                    variant="rounded"
                                  />
                                ) : (
                                  val?.image
                                )}
                              </div>

                              <div className="text-left grid grid-rows-2 items-center">
                                <Typography className="text-text-primary text-sm font-normal leading-tight">
                                  {val?.feed?.labels}
                                </Typography>

                                <Typography
                                  variant="small"
                                  className="text-text-quarterary text-xs text-left"
                                >
                                  {val?.feed?.type?.labels}
                                </Typography>
                              </div>
                            </div>
                          </div>
                        </td>

                        <td className={clases}>
                          <Typography className="text-text-primary text-sm font-normal leading-tight">
                            {moment(val?.dateIn)
                              .locale("id")
                              .format("D MMMM YYYY")}
                          </Typography>
                        </td>

                        <td className={clases + " text-right"}>
                          <Typography className="text-text-primary text-sm font-normal leading-tight">
                            {/* {moment(val?.dateIn).locale('id').format("D MMMM YYYYY")} */}
                            {(val?.qtyIn / 50).toLocaleString("id-ID")} zak
                          </Typography>
                        </td>

                        <td className={clases + " text-right"}>
                          <Typography className="text-text-primary text-sm font-normal leading-tight">
                            {/* {moment(val?.dateIn).locale('id').format("D MMMM YYYYY")} */}
                            {val?.qtyIn.toLocaleString("id-ID")} kg
                          </Typography>
                        </td>

                        <td className={clases + " text-right"}>
                          <Typography className="text-text-primary text-sm font-normal leading-tight">
                            {/* {moment(val?.dateIn).locale('id').format("D MMMM YYYYY")} */}
                            Rp{val?.price.toLocaleString("id-ID")}
                          </Typography>
                        </td>

                        <td className={clases + " text-right"}>
                          <Typography className="text-text-primary text-sm font-normal leading-tight">
                            {/* {moment(val?.dateIn).locale('id').format("D MMMM YYYYY")} */}
                            Rp
                            {(
                              parseFloat(val.qtyIn) *
                              parseFloat(val.price).toFixed()
                            ).toLocaleString("id-ID")}
                          </Typography>
                        </td>

                        <td className={clases}>
                          <Popover placement="left">
                            <PopoverHandler onClick={() => setGetId(val.id)}>
                              <button className="flex items-center">
                                <BurgerDots />
                              </button>
                            </PopoverHandler>

                            <PopoverContent className="w-[8%]">
                              {dataSetting.map((val) => (
                                <div
                                  onClick={() => handleConditionalRoute(val.id)}
                                  className="flex gap-4 cursor-pointer mt-2 hover:opacity-50"
                                >
                                  <div>{val.icon}</div>
                                  <div>{val.label}</div>
                                </div>
                              ))}
                            </PopoverContent>
                          </Popover>
                          {/* <ButtonIcon 
                            className="z-45"
                            icon="detail"
                            type="transparent"
                            size="xsmall"
                            onClick={() => handleClickToDetail(val.id)}
                          /> */}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </CardBody>

            <CardFooter className="items-center border-t border-border-secondary p-0">
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handleChangePage}
              />
            </CardFooter>
          </>
        )}
      </Card>

      <div>
        <ConfirmDialog
          isOpen={openDialog}
          onClose={() => setOpenDialog(!openDialog)}
          title="Hapus Pakan Masuk"
          content="Anda yakin ingin melanjutkan tindakan ini?"
          btnLabel="Hapus"
          onConfirm={handleConfirmationDelete}
          btnType="btnError"
        />
      </div>

      {errorNotifDelete && (
        <AlertNotification
          icon={<FaCheckCircle />}
          color="red"
          message={errorDeleteMessage}
        />
      )}

      <div className="mt-20">
        <DrawerSapronak
          isOpen={showAll}
          onClose={() => setShowAll(!showAll)}
          response={responseStockFeed}
          className="h-[100px]"
        />
      </div>
    </>

    
  );
};

export default TablePakan;
