/* eslint-disable no-unused-vars */
import { Card, Dialog, IconButton } from "@material-tailwind/react"
import { useState } from "react";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch"
import useFetchImage from "../tablePanen/HookFetchingPanen";
import { FiRotateCcw, FiZoomIn, FiZoomOut, FiDownload } from "react-icons/fi";


const ModalImage = ({
  open,
  setOpen,
  url,
}) => {
  const [zoomLevel, setZoomLevel] = useState(1);
  const [rotation, setRotation] = useState(0);

  console.log("data", url)

  const handleZoomChange = (zoomIn) => {
    setZoomLevel(zoomIn);
  };

  const handleRotate = () => {
    setRotation((prevRotation) => (prevRotation + 90) % 360); // Tambah 90 derajat
  };

  return (
    <Dialog
      open={open}
      handler={setOpen}
      className="fixed flex z-100 mr-[100px] ml-[100px]"
    >
      <div className="p-5">
        <Card className="w-[539px] h-[718px] overflow-y-scroll rounded-md p-0 m-0 border-none shadow-none scrollbar-hidden">
          <TransformWrapper
              initialScale={1}
              minScale={1}
              maxScale={4}
              wheel={{ disabled: true }}
              zoomIn={{
                step: 0.1,
              }}
              zoomOut={{
                step: 0.1,
              }}
              onZoomChange={({ scale }) => handleZoomChange(scale)}
            >
              {({ zoomIn, zoomOut, setTransform }) => (
                <>
                  <div className="relative flex items-center justify-center h-full w-full bg-black rounded-md">
                    <TransformComponent>
                      <img
                        className={`object-cover object-center transform transition-transform duration-500 ease-in-out h-[700px]`}
                        // src={imgUrl?.url}
                        src={url}
                        alt="nota"
                        style={{
                          transform: `rotate(${rotation}deg) scale(${zoomLevel})`,
                          cursor: zoomLevel > 1 ? "move" : "default",
                        }}
                      />
                    </TransformComponent>

                    <div className="flex justify-between left-8 bottom-[2%] absolute items-center bg-black bg-opacity-40 rounded-lg gap-2 z-50">
                      <IconButton
                        onClick={() => {
                          zoomOut();
                          setZoomLevel((prev) => Math.max(prev - 0.7, 1));
                        }}
                        className="relative bg-transparent"
                      >
                        <FiZoomOut className="h-7 w-7 text-white hover:text-neutral-300" />
                      </IconButton>
                      <input
                        type="range"
                        min="1"
                        max="3"
                        step="0.1"
                        value={zoomLevel}
                        onChange={(e) => {
                          const newScale = parseFloat(e.target.value);
                          setTransform(0, 0, newScale);
                          setZoomLevel(newScale);
                        }}
                        className="relative bg-gray-200 cursor-pointer"
                        style={{
                          width: "150px",
                          appearance: "none",
                          height: "2px",
                          background: "#ddd",
                          outline: "none",
                          opacity: "0.9",
                          transition: "opacity 0.2s",
                          borderRadius: "5px",
                          accentColor: "white",
                        }}
                      />
                      
                      <IconButton
                        onClick={() => {
                          zoomIn();
                          setZoomLevel((prev) => Math.min(prev + 0.7, 3));
                        }}
                        className="relative bg-transparent"
                      >
                        <FiZoomIn className="h-7 w-7 text-white hover:text-neutral-300" />
                      </IconButton>

                      <IconButton
                        onClick={handleRotate}
                        className="relative bg-transparent"
                      >
                        <FiRotateCcw className="h-7 w-7 text-white hover:text-neutral-300" />
                      </IconButton>
                    </div>

                    <div className="flex justify-end">
                      <div className="bottom-2 absolute">
                        <a
                          href={url}
                          download="nota.jpg"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <IconButton className="relative bg-black bg-opacity-40">
                            <FiDownload className="h-7 w-7 text-white hover:text-neutral-300" />
                          </IconButton>
                        </a>
                      </div>
                    </div>
                  </div>
                </>
              )}

          </TransformWrapper>
        </Card>
      </div>
    </Dialog>
  )
}

export default ModalImage