import React, { useRef, useState } from "react";
import { ReactComponent as ChevronDown } from "../assets/icons/chevron_down.svg";

const sizes = {
  xsmall: { input: "h-7 rounded-sm", icon: "h-5 w-5" },
  small: { input: "h-8 rounded-sm", icon: "h-5 w-5" },
  medium: { input: "h-11 rounded-md", icon: "h-5 w-5" },
  large: { input: "h-14 rounded-md", icon: "h-6 w-6" },
};

const FormDropdownSearch = ({
  options,
  onSelect,
  placeholder,
  size,
  color,
  className,
  type,
  disabled,
  search,
  setSearch,
  defaultValue,
  isLast,
  settype
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState("");
  const inputRef = useRef();


  const handleSearchChange = (e) => {
    setSearch(setSelected("") || e.target.value);
  };
  const handleToggle = () => setIsOpen(!isOpen);
  const handleSelect = (selectedOption) => {
    onSelect(selectedOption);
    setIsOpen(false);
    setSelected(selectedOption.labels || selectedOption.name || search || "");
  };

  const handleClickOutside = (event) => {
    if (inputRef.current && !inputRef.current.contains(event.target)) {
      setIsOpen(false);
      if (setSearch) {
        setSearch("");
      }
    }
  };

  document.addEventListener("mousedown", handleClickOutside);

  const height = sizes[size]["input"];
  return (
    <div className="relative" ref={inputRef}>
      {isOpen && isLast && (
        <ul className="absolute z-10 rounded-lg shadow bg-white w-full mt-[-142px] overflow-scroll max-h-[132px] py-2 border border-border-primary">
          {options.length > 0 ? (
            options?.map((item, index) => (
            <li
              key={index}
              className="p-2 pl-4 hover:bg-gray-100 cursor-pointer text-text-secondary"
              onClick={() => handleSelect(item)}
            >
              {item.labels || item.name}
            </li>
          ))          
        ) : (
            <li><span className="text-text-secondary px-4">Tidak ada data</span></li>
           )}
        </ul>
      )}
      <div className="flex justify-between items-center">
        <input
          type={type}
          placeholder={placeholder}
          className={`${height} w-full border border-${color}-500 ${className} text-text-secondary pl-lg px-sm py-xs justify-start items-center gap-xxs inline-flex focus:outline-none focus:border-primary-400 focus:ring focus:ring-primary-200 placeholder:font-light ${
            defaultValue === true
              ? "placeholder:text-text-secondary"
              : "placeholder:text-text-placeholder"
          } `}
          value={selected || search || ""}
          onChange={handleSearchChange}
          onClick={handleToggle}
          disabled={disabled}
        />

        <div className="absolute inset-y-0 right-0 flex items-center pr-lg focus:outline-none ">
          <ChevronDown
            className={`${
              isOpen ? "transform rotate-180" : ""
            } text-neutral-300`}
            onClick={handleToggle}
          />
        </div>
      </div>

      {isOpen && !isLast && (
        <ul className="absolute z-10 rounded-lg shadow bg-white w-full mt-2 overflow-scroll max-h-[132px] py-2 border border-border-primary">
          {options?.length > 0 ? (
            options?.map((item, index) => (
              <li
                key={index}
                className="p-2 pl-4 hover:bg-gray-100 cursor-pointer text-text-secondary"
                onClick={() => handleSelect(item)}
              >
                {item.labels || item.name}
              </li>
            ))
          ) : (
           <li><span className="text-text-secondary px-4">Tidak ada data</span></li>
          )}
        </ul>
      )}
    </div>
  );
};

export default FormDropdownSearch;
