import {
  Card,
  CardBody,
  CardHeader,
  Tab,
  Tabs,
  TabsHeader,
  Typography,
} from "@material-tailwind/react";
import { format } from "date-fns";
import idLocale from "date-fns/locale/id";
import { ReactComponent as ChevronLeft } from "../../../../assets/icons/chevron-left.svg";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import api from "../../../../api/configApi";
import TagLabel from "../../../../atoms/TagLabel";
import RekapPeriodeBudidayaSelesai from "./TabDetailPeriodeSelesai/RekapPeriodeBudidayaSelesai";
import PerformaLabaRugi from "./TabDetailPeriodeSelesai/PerformaLabaRugi";
import RincianTransaksi from "./TabDetailPeriodeSelesai/RincianTransaksi";
import { LuUser2 } from "react-icons/lu";
import { HiOutlineUserCircle } from "react-icons/hi";
import { Button } from "../../../../atoms/Button";
import { CgSoftwareDownload } from "react-icons/cg";
import { TiLocationOutline } from "react-icons/ti";

const DetailPeriodeSelesai = () => {
  const { id } = useParams();
  const [responseDetail, setResponseDetail] = useState(null);
  const [settingType, setSettingType] = useState(
    localStorage.getItem("settingType") || "rekapitulasi"
  );
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const data = [
    {
      label: "Rekapitulasi Budidaya",
      value: "rekapitulasi",
    },

    {
      label: "Performa laba-rugi",
      value: "performa",
    },

    {
      label: "Rincian Transaksi",
      value: "rincianTransaksi",
    },
  ];

  useEffect(() => {
    const fetchDetailPeriod = async () => {
      try {
        setLoading(true);
        const responseId = await api.get(`/v1/rearingPeriods/${id}/end`);
        setResponseDetail(responseId?.data);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    fetchDetailPeriod();
  }, [id]);

  const handleExportLaporan = async () => {
    try {
      // Mengirim permintaan untuk mendapatkan URL file Excel
      const response = await api.get(`/v1/rearingPeriods/${id}/toExcel`, {
        responseType: "blob", // Menetapkan tipe respons sebagai blob
      });

      const contentDisposition = response.headers["content-disposition"];

      let fileName = "rearing_periods.xlsx";

      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename="?([^"]+)"?/);
        if (fileNameMatch && fileNameMatch[1]) {
          fileName = fileNameMatch[1];
        }
      }

      // Membuat URL dari blob
      const url = window.URL.createObjectURL(new Blob([response.data]));

      // Membuat elemen anchor untuk mengunduh file
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);

      // Menambahkan elemen anchor ke dokumen
      document.body.appendChild(link);

      // Mengklik elemen anchor secara otomatis untuk memulai unduhan
      link.click();

      // Menghapus URL objek setelah unduhan selesai
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex h-auto xl:overflow-hidden overflow-hidden">
      <div className="flex flex-col flex-1 overflow-hidden">
        <div className="p-4">
          <Card className="w-full h-auto border">
            <CardHeader
              floated={false}
              shadow={false}
              className="p-md border-none"
            >
              <div className="grid grid-cols-3">
                <div className="col-span-2">
                  <div className="flex justify-betwen">
                    <div
                      className="mt-3 cursor-pointer"
                      onClick={() =>
                        navigate("/periode-budidaya/periode-selesai")
                      }
                    >
                      <ChevronLeft />
                    </div>
                    <Typography className="text-xl font-bold p-2 text-text-primary leading-[30px]">
                      {responseDetail?.houseName}
                    </Typography>
                  </div>
                  <div className="ml-7 mt-2">
                    <div className="flex justify-betwen">
                      <LuUser2 className="h-5 w-5" />
                      <Typography className="ml-2 text-sm text-text-secondary font-normal">
                        {"Pemilik: "}
                        {responseDetail?.houseOwner}
                      </Typography>
                    </div>
                    <div className="flex justify-betwen mt-3">
                      <TiLocationOutline className="h-5 w-5" />
                      <Typography className="ml-2 text-sm text-text-secondary font-normal">
                        {responseDetail?.address} {" | "}{" "}
                        {responseDetail?.unit || "-"}
                      </Typography>
                    </div>
                    <div className="flex justify-betwen mt-3">
                      <HiOutlineUserCircle className="h-5 w-5" />
                      <Typography className="ml-2 text-sm text-text-secondary font-normal">
                        {"PPL: "}
                        {responseDetail?.ppl || "-"}
                      </Typography>
                    </div>
                    <div className="flex mt-4 gap-3">
                      <TagLabel
                        children={responseDetail?.houseType}
                        type="primary"
                      />
                      <TagLabel
                        children={`${responseDetail?.totalFloor} Lantai`}
                        type="primary"
                      />
                      <TagLabel
                        children={`Mulai Periode: ${format(
                          new Date(responseDetail?.periodeStart || null),
                          "dd MMMM yyyy",
                          { locale: idLocale }
                        )}`}
                        type="primary"
                      />
                      <TagLabel
                        children={`Populasi Awal: ${responseDetail?.docInPopulation?.toLocaleString(
                          "id-ID"
                        )}`}
                        type="primary"
                      />
                    </div>
                  </div>
                </div>
                <div className="text-center flex justify-end mr-[30px] col-span-1 items-center">
                  <Button
                    label="Download Laporan"
                    size="medium"
                    type="btnPrimary"
                    componentLeft={<CgSoftwareDownload className="h-5 w-5" />}
                    onClick={handleExportLaporan}
                  />
                </div>
              </div>
            </CardHeader>

            <CardBody className="p-0 hidden:overflow-scroll mt-4">
              <div>
                <Tabs
                  value={settingType}
                  className="mt-[10px] max-w-[85%] md:w-[500%]"
                >
                  <TabsHeader
                    className="rounded-none bg-transparent p-0 z-0"
                    indicatorProps={{
                      className:
                        "bg-transparent border-b-4 border-[#F9D865] shadow-none rounded-none gap-x-8",
                    }}
                  >
                    {data?.map(({ label, value }) => (
                      <Tab
                        key={value}
                        value={value}
                        onClick={() => setSettingType(value)}
                        className={`text-sm font-semibold w-full ${
                          settingType === value
                            ? "bg-white text-text-primary p-1 h-9"
                            : "bg-white text-text-quarterary p-1 h-9"
                        }`}
                      >
                        <Typography
                          variant="h6"
                          className="gap-y-5 w-full ml-3"
                        >
                          {label}
                        </Typography>
                      </Tab>
                    ))}
                  </TabsHeader>
                </Tabs>
                <div className="border-t border-border-secondary w-full" />

                <div className="w-full flex justify-start">
                  {settingType === "rekapitulasi" ? (
                    <RekapPeriodeBudidayaSelesai
                      ResponseDetail={responseDetail}
                      loading={loading}
                    />
                  ) : settingType === "performa" ? (
                    <PerformaLabaRugi
                      ResponseDetail={responseDetail}
                      loading={loading}
                    />
                  ) : settingType === "rincianTransaksi" ? (
                    <RincianTransaksi
                      ResponseDetail={responseDetail}
                      loading={loading}
                    />
                  ) : null}
                </div>
              </div>
            </CardBody>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default DetailPeriodeSelesai;
