/* eslint-disable no-unused-vars */
import {
  Tab,
  Tabs,
  TabsHeader,
  Typography,
  Card,
} from "@material-tailwind/react";
import Input from "../../../../../atoms/Input";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { Button } from "../../../../../atoms/Button";
import { ReactComponent as ChevronDown } from "../../../../../assets/icons/chevron-down-black.svg";
import { useEffect, useState } from "react";
import TablePakan from "../../../../../molecules/table_budidaya/TablePakan";
import api from "../../../../../api/configApi";
import { useNavigate } from "react-router-dom";
import TableOvk from "../../../../../molecules/table_budidaya/TableOvk";
import TableDoc from "../../../../../molecules/table_budidaya/TableDoc";
import AlertNotification from "../../../../../atoms/AlertNotification";
import { FaCheckCircle } from "react-icons/fa";
import LoadingAnimation from "../../../../../atoms/LoadingAnimation";
import * as Sentry from "@sentry/react";
import { useRef } from "react";

const Sapronak = ({ periodId }) => {
  const [settingTypeSapronak, setSettingTypeSapronak] = useState(
    localStorage.getItem("settingTypeSapronak") || "pakan"
  );
  const [docResponse, setDocResponse] = useState([]);
  const [feedResponse, setFeedResponse] = useState([]);
  const [ovkResponse, setOvkResponse] = useState([]);
  const [currentPageFeed, setCurrentPageFeed] = useState(1);
  const [totalPage, setTotalPages] = useState(1);
  const [currentPageOvk, setCurrentPageOvk] = useState(1);
  const [currentPageDoc, setCurrentPageDoc] = useState(1);
  const [totalPageOvk, setTotalPageOvk] = useState(1);
  const [feedSearch, setFeedSearch] = useState("");
  const [ovkSearch, setOvkSearch] = useState("");
  const sortableColumnsOvk = ["ovk.name", "dateIn", "qtyIn", "price"];
  const [descendingOvk, setDescendingOvk] = useState(false);
  const [sortByOvk, setSortByOvk] = useState("");

  const [descendingFeed, setDescendingFeed] = useState(false);
  const sortableColumnsFeed = ["feed.name", "dateIn", "qtyIn", "price"];
  const [sortByFeed, setSortByFeed] = useState("");

  const alertAddFeed = JSON.parse(localStorage.getItem("successNotifAddFeed"));
  const alertAddOVK = JSON.parse(localStorage.getItem("successNotifAddOVK"));
  const alertEditFeed = JSON.parse(
    localStorage.getItem("successNotifUpdateFeed")
  );

  const alertEditOVK = JSON.parse(
    localStorage.getItem("successNotifUpdateOVK")
  );

  const alertDeleteFeed = JSON.parse(
    localStorage.getItem("successNotifDeleteFeed")
  );

  const alertDeleteOvk = JSON.parse(
    localStorage.getItem("successNotifDeleteOvk")
  );

  const [notifAddFeed, setNotifAddFeed] = useState(alertAddFeed);
  const [notifAddOVK, setNotifAddOVK] = useState(alertAddOVK);
  const [notifEditFeed, setNotifEditFeed] = useState(alertEditFeed);
  const [notifEditOVK, setNotifEditOVK] = useState(alertEditOVK);

  const [notifDeleteFeed, setNotifDeleteFeed] = useState(alertDeleteFeed);
  const [notifDeleteOvk, setNotifDeleteOvk] = useState(alertDeleteOvk);

  const [loading, setLoading] = useState(false);
  const [isOpenAdd, setIsOpenAdd] = useState(false);

  const messageSuccess = notifAddFeed
    ? "Stok Pakan berhasil ditambah."
    : notifAddOVK
    ? "Stok OVK berhasil ditambah."
    : notifEditFeed
    ? "Stok pakan berhasil diubah."
    : notifEditOVK && "Stok OVK berhasil diubah.";

  const navigate = useNavigate();
  const dataTab = [
    {
      label: "DOC",
      value: "doc",
    },

    {
      label: "Pakan",
      value: "pakan",
    },

    {
      label: "OVK",
      value: "ovk",
    },
  ];

  const dropdownRef = useRef(null); // Ref untuk elemen dropdown

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpenAdd(false); // Tutup dropdown jika klik di luar elemen
      }
    };

    // Tambahkan event listener ke dokumen
    document.addEventListener("mousedown", handleClickOutside);

    // Hapus event listener saat komponen di-unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const handleSortFeed = (column) => {
    if (sortableColumnsFeed.includes(column)) {
      if (sortByFeed === column) {
        // Toggle descending if the same column is clicked
        setDescendingFeed(!descendingFeed);
      } else {
        // Change sorting column and set default descending to false
        setSortByFeed(column);
        setDescendingFeed(false);
      }
    }
  };

  const handleSortOvk = (column) => {
    if (sortableColumnsOvk.includes(column)) {
      if (sortByOvk === column) {
        // Toggle descending if the same column is clicked
        setDescendingOvk(!descendingOvk);
      } else {
        // Change sorting column and set default descending to false
        setSortByOvk(column);
        setDescendingOvk(false);
      }
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setNotifAddFeed(false);
      localStorage.clear("successNotifAddFeed");
    }, 4000);
  }, [notifAddFeed]);

  useEffect(() => {
    setTimeout(() => {
      setNotifEditFeed(false);
      localStorage.clear("successNotifUpdateFeed");
    }, 4000);
  }, [notifEditFeed]);

  useEffect(() => {
    setTimeout(() => {
      setNotifAddOVK(false);
      localStorage.clear("successNotifAddOVK");
    }, 4000);
  }, [notifAddOVK]);

  useEffect(() => {
    setTimeout(() => {
      setNotifEditOVK(false);
      localStorage.clear("successNotifUpdateOVK");
    }, 4000);
  }, [notifEditOVK]);

  useEffect(() => {
    setTimeout(() => {
      setNotifDeleteFeed(false);
      localStorage.clear("successNotifDeleteFeed");
    }, 4000);
  }, [notifDeleteFeed]);

  useEffect(() => {
    setTimeout(() => {
      setNotifDeleteOvk(false);
      localStorage.clear("successNotifDeleteOvk");
    }, 4000);
  }, [notifDeleteOvk]);

  useEffect(() => {
    setLoading(true);
    const fetchingDoc = async () => {
      try {
        const responseDoc = await api.get(`/v1/rearingPeriods/${periodId}`);
        setDocResponse(responseDoc?.data);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    fetchingDoc();
  }, [periodId]);

  useEffect(() => {
    const fetchingFeed = async () => {
      setLoading(true);
      try {
        const responseFeed = await api.get("/v1/feeds/in", {
          params: {
            search: settingTypeSapronak === "pakan" ? feedSearch : null,
            periodId: periodId,
            itemPerPage: 10,
            currentPage: currentPageFeed - 1,
            descending: descendingFeed,
            sortBy: sortByFeed,
          },
        });
        setTotalPages(responseFeed?.data?.totalPages);
        setFeedResponse(responseFeed?.data);
      } catch (error) {
        Sentry.captureException(error);
      } finally {
        setLoading(false);
      }
    };
    fetchingFeed();
  }, [
    periodId,
    currentPageFeed,
    feedSearch,
    settingTypeSapronak,
    descendingFeed,
    sortByFeed,
  ]);

  const reloadFeedData = () => {
    const fetchingFeed = async () => {
      setLoading(true);
      try {
        const responseFeed = await api.get("/v1/feeds/in", {
          params: {
            search: settingTypeSapronak === "pakan" ? feedSearch : null,
            periodId: periodId,
            itemPerPage: 10,
            currentPage: currentPageFeed - 1,
            descending: descendingFeed,
            sortBy: sortByFeed,
          },
        });
        setTotalPages(responseFeed?.data?.totalPages);
        setFeedResponse(responseFeed?.data);
      } catch (error) {
        Sentry.captureException(error);
      } finally {
        setLoading(false);
      }
    };
    fetchingFeed();
  };

  useEffect(() => {
    const fetchingOvk = async () => {
      setLoading(true);
      try {
        const responseOvk = await api.get("/v1/ovks/in", {
          params: {
            search: settingTypeSapronak === "ovk" ? ovkSearch : null,
            periodId: periodId,
            itemPerPage: 10,
            currentPage: currentPageOvk - 1,
            descending: descendingOvk,
            sortBy: sortByOvk,
          },
        });
        setOvkResponse(responseOvk?.data);
        setTotalPageOvk(responseOvk?.data?.totalPages);
      } catch (error) {
        Sentry.captureException(error);
      } finally {
        setLoading(false);
      }
    };
    fetchingOvk();
  }, [
    periodId,
    currentPageOvk,
    ovkSearch,
    settingTypeSapronak,
    descendingOvk,
    sortByOvk,
  ]);

  const reloadOvkData = () => {
    const fetchingOvk = async () => {
      setLoading(true);
      try {
        const responseOvk = await api.get("/v1/ovks/in", {
          params: {
            search: settingTypeSapronak === "ovk" ? ovkSearch : null,
            periodId: periodId,
            itemPerPage: 10,
            currentPage: currentPageOvk - 1,
            descending: descendingOvk,
            sortBy: sortByOvk,
          },
        });
        setOvkResponse(responseOvk?.data);
        setTotalPageOvk(responseOvk?.data?.totalPages);
      } catch (error) {
        Sentry.captureException(error);
      } finally {
        setLoading(false);
      }
    };
    fetchingOvk();
  };

  const dataAdd = [
    {
      id: 1,
      value: "Tambah Pakan",
    },

    {
      id: 2,
      value: "Tambah OVK",
    },
  ];

  const handleRouteSapronak = (val) => {
    if (val === 1) {
      navigate(`/periode-budidaya/periode-berjalan/${periodId}/sapronak/tambah-pakan`);
    } else if (val === 2) {
      navigate(`/periode-budidaya/periode-berjalan/${periodId}/sapronak/tambah-ovk`);
    } else {
      return null;
    }
  };

  return (
    <div className="flex h-auto xl:overflow-hidden overflow-hidden w-full">
      <div className="flex flex-col flex-1 overflow-hidden">
        <div className="flex justify-between p-3xl">
          {/* <Input
            type="text"
            id="search"
            placeholder="Cari"
            size="medium"
            onChange={
              settingTypeSapronak === "pakan"
                ? (e) => setFeedSearch(e.target.value)
                : (e) => setOvkSearch(e.target.value)
            }
            componentLeft={<MagnifyingGlassIcon className="h-5 w-5" />}
          /> */}
        </div>

        <div className="px-3xl py-lg flex justify-between mt-[-40px]">
          <Tabs
            value={settingTypeSapronak}
            className="mt-[10px] min-w-[200px] max-w-[200px]"
          >
            <TabsHeader>
              {dataTab.map(({ label, value }) => (
                <Tab
                  key={value}
                  value={value}
                  onClick={() => setSettingTypeSapronak(value)}
                  className={`text-sm font-semibold w-full `}
                >
                  <Typography variant="h6" className="flex items-center gap-2">
                    {label}
                  </Typography>
                </Tab>
              ))}
            </TabsHeader>
          </Tabs>

          <div ref={dropdownRef}>
            <Button
              label="Tambah"
              size="medium"
              type="btnPrimary"
              componentRight={<ChevronDown className="" />}
              onClick={() => setIsOpenAdd(!isOpenAdd)}
            />
            {isOpenAdd && (
              <Card className="z-[9999] absolute right-2 mt-4 border border-border-secondary">
                {dataAdd?.map((val) => (
                  <div
                    className="px-xl py-[10px] flex items-start w-full text-start text-text-secondary font-normal leading-normal gap-y-4 cursor-pointer hover:bg-gray-100"
                    onClick={() => handleRouteSapronak(val.id)}
                  >
                    {val.value}
                  </div>
                ))}
              </Card>
            )}
          </div>
        </div>

        {loading ? (
          <div className="flex justify-center items-center h-screen">
            <LoadingAnimation />
          </div>
        ) : (
          <div className="mt-[-12px]">
            {settingTypeSapronak === "doc" ? (
              <TableDoc
                dataTable={docResponse}
                totalPages={totalPage}
                currentPage={currentPageDoc}
                setCurrentPage={setCurrentPageDoc}
              />
            ) : settingTypeSapronak === "pakan" ? (
              <TablePakan
                dataTable={feedResponse}
                totalPages={totalPage}
                currentPage={currentPageFeed}
                setCurrentPage={setCurrentPageFeed}
                setDescending={setDescendingFeed}
                sortableColumns={sortableColumnsFeed}
                handleSort={handleSortFeed}
                reloadTable={reloadFeedData}
                sortBy={sortByFeed}
                periodId={periodId}
              />
            ) : settingTypeSapronak === "ovk" ? (
              <TableOvk
                dataTable={ovkResponse}
                totalPages={totalPageOvk}
                currentPage={currentPageOvk}
                setCurrentPage={setCurrentPageOvk}
                setDescending={setDescendingOvk}
                sortableColumns={sortableColumnsOvk}
                handleSort={handleSortOvk}
                reloadTable={reloadOvkData}
                sortBy={sortByOvk}
                periodId={periodId}
              />
            ) : null}
          </div>
        )}
      </div>
      {(notifAddFeed || notifAddOVK) && (
        <AlertNotification
          icon={<FaCheckCircle />}
          color="green"
          message={messageSuccess}
        />
      )}

      {(notifEditFeed || notifEditOVK) && (
        <AlertNotification
          icon={<FaCheckCircle />}
          color="green"
          message={messageSuccess}
        />
      )}

      {notifDeleteFeed && (
        <AlertNotification
          icon={<FaCheckCircle />}
          color="green"
          message={"Data pakan masuk berhasil dihapus."}
        />
      )}

      {notifDeleteOvk && (
        <AlertNotification
          icon={<FaCheckCircle />}
          color="green"
          message={"Data OVK masuk berhasil dihapus."}
        />
      )}

    </div>
  );
};

export default Sapronak;
