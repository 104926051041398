import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Switch,
  Typography,
  Radio,
} from "@material-tailwind/react";
import Input from "../../../../atoms/Input";
import api from "../../../../api/configApi";
import { Button } from "../../../../atoms/Button";
import AlertNotification from "../../../../atoms/AlertNotification";
import FormDropdownSearch from "../../../../atoms/FormDropdownSearch";
import TextArea from "../../../../atoms/TextArea";
import { FaCheckCircle } from "react-icons/fa";
import ConfirmDialog from "../../../../atoms/ConfirmDialog";
import FileUpload from "../../../../atoms/FileUpload";
import PhoneInput from "../../../../molecules/login/PhoneInput";
import { ReactComponent as ChevronLeft } from "../../../../assets/icons/chevron-left.svg";
import * as Sentry from "@sentry/react";

const EditBakul = ({ onReloadData }) => {
  const { id } = useParams();
  const bakulId = id;
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [countryCode, setCountryCode] = useState("");

  const [address, setAddress] = useState("");
  const [npwp, setNpwp] = useState("");
  const [bakulType, setBakulType] = useState("INDIVIDUAL");

  const [regions, setRegions] = useState([]);
  const [cities, setCities] = useState([]);

  const [selectedRegion, setSelectedRegion] = useState("");
  const [selectedCities, setSelectedCities] = useState("");

  const [isEditSuccess, setEditSuccess] = useState(false);
  const [isEditError, setIsEditError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [searchCity, setSearchCity] = useState("");
  const [searchProvince, setSearchProvince] = useState("");

  const [isActive, setActive] = useState(false);

  const [isAddDialogOpen, setAddDialogOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [firstPreviewURL, setFirstPreviewURL] = useState();

  const [selectedLabelRegion, setSelectedLabelRegion] = useState("");
  const [selectedLabelCity, setSelectedLabelCity] = useState("");

  const [isEmailValid, setIsEmailValid] = useState(true);

  const isSubmitDisabled =
    !name ||
    !phone ||
    !address ||
    !bakulType ||
    !selectedRegion ||
    !selectedCities ||
    (!firstPreviewURL && !selectedImage);

  useEffect(() => {
    const fetchBakulData = async () => {
      try {
        const response = await api.get(`/v1/bakuls/${bakulId}`);

        const bakulData = response.data;
        setName(bakulData.name);
        setPhone(bakulData.phone);
        setEmail(bakulData.email);
        setNpwp(bakulData.npwp);
        setBakulType(bakulData.bakulType?.id);
        setSelectedCities(bakulData.city?.id);
        setSelectedRegion(bakulData.province?.id);
        setAddress(bakulData.address);
        setActive(bakulData.isActive);
        setSelectedLabelCity(bakulData.city?.labels);
        setSelectedLabelRegion(bakulData.province?.labels);

        const fetchKTP = async () => {
          try {
            const responseKTP = await api.get(`${bakulData.media.ktp}/url`);
            setFirstPreviewURL(responseKTP.data.url);
          } catch (error) {}
        };

        fetchKTP();
      } catch (error) {
        Sentry.captureException(error);
      }
    };

    fetchBakulData();
  }, [bakulId]);

  const handleSwitchChange = () => {
    setActive(!isActive);
  };

  const handleOpenAddDialog = () => {
    setAddDialogOpen(true);
  };

  const handleCloseAddDialog = () => {
    setAddDialogOpen(false);
  };

  const handleSelectRegion = (select) => {
    setSelectedRegion(select.id);
  };

  const handleSelectCities = (select) => {
    setSelectedCities(select.id);
  };

  const handleEmailChange = (e) => {
    let newEmail = e.target.value;

    if (newEmail.length < 99) {
      setEmail(newEmail);
      validateEmail(newEmail);
    }
  };

  const validateEmail = (inputEmail) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailRegex.test(inputEmail)) {
      setIsEmailValid(false);
    } else {
      setIsEmailValid(true);
    }
  };

  const handleNpwpChange = (e) => {
    let newNpwp = e.target.value;

    // Pastikan hanya angka yang diterima
    newNpwp = newNpwp.replace(/\D/g, "");

    if (!isNaN(newNpwp) && newNpwp.length <= 16) {
      setNpwp(newNpwp);
    }
  };

  const handleNameChange = (e) => {
    let newName = e.target.value;

    if (newName.length < 100) {
      setName(newName);
    }
  };
  
  useEffect(() => {
    const dataFetchDropdownRegion = async () => {
      try {
        const responseRegionDropdown = await api.get(
          "/v1/regions/id/province/dropdown", {
            params: {
              search: searchProvince,
            },
          }
        );
        setRegions(responseRegionDropdown.data);
      } catch (error) {
        Sentry.captureException(error);
      }
    };
    dataFetchDropdownRegion();
  }, [searchProvince]);

  useEffect(() => {
    const fetchCities = async () => {
      const data = await api.get(`/v1/regions/id/cities/dropdown`, {
        params: {
          search: searchCity,
          province: selectedRegion,
        },
      });
      setCities(data?.data);
    };

    fetchCities();
  }, [searchCity, selectedRegion]);

  const handleSave = async () => {
    setIsSubmitting(true);

    const formData = new FormData();

    if (selectedImage) {
      formData.append("ktp", selectedImage);
    }

    const newBakul = {
      name: name,
      address: address,
      city: { id: selectedCities },
      isActive: isActive,
      bakulType: bakulType,
    };

    if (npwp?.length > 0) {
      newBakul.npwp = npwp;
    }

    if (email?.length > 0) {
      console.log("email:", email);
      newBakul.email = email;
    }

    // Tambahkan pengecekan apakah nomorTelepon adalah string dan tidak kosong
    if (typeof phone === "string" && phone.trim() !== "") {
      let formattedPhone = phone;

      // Hilangkan angka 0 di depan jika ada
      formattedPhone = formattedPhone.replace(/^0+/, "");

      // Jika phone tidak mengandung kode negara, tambahkan countryCode
      if (!formattedPhone.startsWith(countryCode)) {
        formattedPhone = countryCode + formattedPhone;
      }
      newBakul.phone = formattedPhone;
    }

    formData.append("data", JSON.stringify(newBakul));

    try {
      const response = await api.put(
        `/v1/bakuls/${bakulId}/updateWithFile`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      console.log("Bakul berhasil diubah:", response);

      setEditSuccess(true);

      navigate("/master/bakul");
      localStorage.setItem(
        "responseSuccessEdit",
        JSON.stringify(!isEditSuccess)
      );

      if (onReloadData) {
        onReloadData();
      }
    } catch (error) {
      setIsSubmitting(false);
      console.error("Gagal mengubah bakul:", error.response.data.error);
      setIsEditError(true);
      setErrorMessage(
        error.response.data.error?.name || error.response.data.error
      );
      setTimeout(() => setIsEditError(false), 2000);
      Sentry.captureException(error);
    }
  };

  const handleBack = () => {
    navigate("/master/bakul");
  };

  const handleFileUpload = (files) => {
    setSelectedImage(files ? files[0] : null);
  };

  const handleBakulTypeChange = (event) => {
    setBakulType(event.target.value);
  };

  return (
    <div className="p-4 flex justify-center">
      <div>
        <Card className="border w-[800px] shadow-none border-border-secondary">
          <CardHeader floated={false} shadow={false} className="rounded-b-none">
            <Typography className="text-text-primary flex items-center text-xl font-bold">
            <div className="cursor-pointer pr-2" onClick={handleBack}>
              <ChevronLeft />
            </div>
              Ubah bakul
            </Typography>
          </CardHeader>
          <div className="flex justify-center w-full">
            <CardBody className="w-[440px]">
              <div className="mb-3xl">
                <div>
                  <Typography className="block text-sm font-semibold leading-tight text-text-primary mb-2">
                    Bakul{" "}
                    <span className="text-red-500 text-sm font-semibold leading-tight">
                      *
                    </span>
                  </Typography>
                  <Input
                    type="text"
                    id="name"
                    placeholder="Masukkan nama"
                    className="max-w-md"
                    value={name}
                    size="medium"
                    onChange={handleNameChange}
                  />
                </div>
              </div>
              <div className="my-3xl">
                <div>
                  <Typography className="block text-sm font-semibold leading-tight text-text-primary mb-2">
                    Nomor Telepon{" "}
                    <span className="text-red-500 text-sm font-normal leading-tight">
                      *
                    </span>
                  </Typography>
                  <PhoneInput
                    phone={phone}
                    setPhone={setPhone}
                    setCountryCode={setCountryCode}
                    phoneIsNull={true}
                    isLabelsActive={false}
                  />
                </div>
              </div>

              <div className="mb-3xl">
                <Typography className="text-sm font-semibold leading-tight block text-text-primary mb-2">
                  Email:
                </Typography>
                <Input
                  type="email"
                  id="email"
                  placeholder="Masukkan email"
                  value={email}
                  size="medium"
                  onChange={handleEmailChange}
                />
                {!isEmailValid && email && (
                  <p className="text-red-500 text-sm mt-1">
                    Masukkan alamat email yang valid.
                  </p>
                )}
              </div>

              <Typography
                variant="lead"
                color="#101828"
                className="text-sm font-semibold pt-2 text-text-primary"
              >
                Provinsi <span className="text-red-500">*</span>
              </Typography>

              <div className="mt-2 mb-3xl">
                <FormDropdownSearch
                  onSelect={handleSelectRegion}
                  placeholder={selectedLabelRegion}
                  defaultValue={true}
                  options={regions?.contents}
                  setSearch={setSearchProvince}
                  search={searchProvince}
                  size="medium"
                />
              </div>

              <Typography className="text-sm font-semibold text-text-primary">
                Kabupaten / kota <span className="text-[#F04438]">*</span>
              </Typography>

              <div className="mt-2 mb-3xl">
                <FormDropdownSearch
                  onSelect={handleSelectCities}
                  placeholder={selectedLabelCity}
                  defaultValue={true}
                  options={cities?.contents}
                  setSearch={setSearchCity}
                  search={searchCity}
                  size="medium"
                />
              </div>

              <Typography className="text-sm font-semibold text-text-primary">
                Alamat<span className="text-red-500">*</span>
              </Typography>

              <div className="mt-2 mb-3xl">
                <TextArea
                  placeholder="Masukkan alamat bakul"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  className={"placeholder:text-text-placeholder"}
                />
              </div>
              <Typography className="text-sm font-semibold text-text-primary">
                Tipe bakul<span className="text-red-500">*</span>
              </Typography>
              <div className="flex gap-5 mt-2">
                <Radio
                  name="bakulType"
                  label={
                    <Typography className="hover:text-text-primary text-text-secondary font-medium transition-colors text-sm">
                      Individu
                    </Typography>
                  }
                  color="amber"
                  value="INDIVIDUAL"
                  checked={bakulType === "INDIVIDUAL"}
                  onChange={handleBakulTypeChange}
                />
                <Radio
                  name="bakulType"
                  label={
                    <Typography className="hover:text-text-primary text-text-secondary font-medium transition-colors text-sm">
                      Perusahaan
                    </Typography>
                  }
                  color="amber"
                  value="COMPANY"
                  checked={bakulType === "COMPANY"}
                  onChange={handleBakulTypeChange}
                />
              </div>

              <div className="mt-3xl">
                <div>
                  <Typography className="block text-sm font-semibold text-text-primary mb-2">
                    Nomor NPWP
                  </Typography>
                  <Input
                    type="text"
                    id="name"
                    placeholder="Masukkan nomor NPWP"
                    className="max-w-md"
                    value={npwp}
                    size="medium"
                    onChange={handleNpwpChange}
                  />
                </div>
              </div>
              <div className="container mx-auto my-3xl">
                <Typography className="text-sm font-semibold pb-2 text-text-primary">
                  Unggah KTP <span className="text-red-500">*</span>
                </Typography>
                <FileUpload
                  onFileUpload={handleFileUpload}
                  firstPreviewURL={firstPreviewURL}
                  setFirstPreviewURL={setFirstPreviewURL}
                />
              </div>
              <div className="items-center gap-4">
                <Typography className="block text-text-primary text-sm font-semibold mb-md">
                  Status bakul
                </Typography>
                <div>
                  <Switch
                    className="checked:bg-bg-brandSolid"
                    onChange={handleSwitchChange}
                    checked={isActive}
                  />
                  <span className="m-2 text-sm">
                    {isActive ? "Aktif" : "Tidak Aktif"}
                  </span>
                </div>
              </div>

              <div className="mt-9 items-center gap-4">
                <div className="my-3"></div>
                <div></div>
              </div>
            </CardBody>
          </div>
          <CardFooter className="flex justify-end border-t border-border-secondary p-lg">
            <Button
              label="Batal"
              size="medium"
              type="btnSecondary"
              className="mr-md"
              onClick={handleBack}
            />
            <Button
              label="Simpan"
              size="medium"
              type={`${
                isSubmitDisabled || isSubmitting
                  ? "btnPrimaryDisabled"
                  : "btnPrimary"
              }`}
              onClick={handleOpenAddDialog}
              disabled={isSubmitDisabled || isSubmitting}
              componentLeft={
                isSubmitting && (
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    className="animate-spin fill-neutral-400"
                  >
                    <path
                      d="M10.72,19.9a8,8,0,0,1-6.5-9.79A7.77,7.77,0,0,1,10.4,4.16a8,8,0,0,1,9.49,6.52A1.54,1.54,0,0,0,21.38,12h.13a1.37,1.37,0,0,0,1.38-1.54,11,11,0,1,0-12.7,12.39A1.54,1.54,0,0,0,12,21.34h0A1.47,1.47,0,0,0,10.72,19.9Z"
                      className="spinner_6kVp"
                    />
                  </svg>
                )
              }
            />
          </CardFooter>
        </Card>

        <ConfirmDialog
          isOpen={isAddDialogOpen}
          onClose={handleCloseAddDialog}
          onConfirm={handleSave}
          title="Ubah data bakul"
          content="Apakah anda yakin ingin melanjutkan tindakan ini?"
          btnLabel="Lanjutkan"
          btnType="btnPrimary"
        />

        {isEditError && (
          <AlertNotification
            icon={<FaCheckCircle />}
            color="red"
            message={errorMessage}
          />
        )}
      </div>
    </div>
  );
};

export default EditBakul;
