/* eslint-disable no-unused-vars */
/* eslint-disable no-unreachable */
import { Outlet, useLocation } from "react-router-dom";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { fixWording } from "../../../shared/conditionalWording";
import { useDispatch, useSelector } from "react-redux";
import { setActiveMenu, setActiveSubMenu } from "../../features/menuSlice";


const Wrapper = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const activeSubMenu = useSelector((state) => state.menu.activeSubMenu);
  const urlPath = location.pathname
    .split("/")
    .filter((segment) => segment !== "");

  // Filter out segments that look like UUIDs (adjust the pattern if needed)
  const breadCrumpSegment = urlPath
  .slice(0)
  .filter((segment) =>
    segment
  );

  var latestSegment = ''

  const breadcrumbsData = breadCrumpSegment.map((segment, index) => {
    let link;
    let label = fixWording(segment);
  
    const isUUID = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(segment);
  
    if (
      latestSegment.includes("tambah") ||
      latestSegment.includes("edit") ||
      latestSegment.includes("ubah") ||
      latestSegment.includes("Detail") ||
      latestSegment.includes("ubah-data-harian") ||
      latestSegment.includes("update")
    ) {
      label = "";
    } else if (isUUID) {
      label = "Detail";
      link = `/${breadCrumpSegment.slice(0, index + 1).join("/")}`;
    }
    latestSegment = segment;

    // Construct the link
    if (segment === "dashboard") {
      link = "/dashboard";
      dispatch(setActiveMenu("dashboard"));
      if (activeSubMenu) {
        dispatch(setActiveSubMenu(""));
      }
    } else if (
      ["master", 
        "periode-budidaya", 
        "master", 
        "tambah-ovk", 
        "stok", 
        "pengaturan-pengguna", 
        "ubah-data-harian",
        "pakan",
        "monitoring-growing"
      ].includes(segment) || label === "Detail"
    ) {
      link = "#"; // Non-clickable links
    } else if (!isUUID) {
      link = `/${breadCrumpSegment.slice(0, index + 1).join("/")}`;
    }
  
    // Define styles based on the link value
    const style =
      link === "#" || label === "Detail"
        ? { color: "#999", cursor: "pointer", pointerEvents: "none" } // Non-clickable style
        : { color: "#32353A", cursor: "pointer" }; // Clickable style
  
    return {
      label,
      link,
      style,
    };
  });
  

  return (
    <div>
      <div className="flex h-screen overflow-hidden">
        <Sidebar />

        <div className="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden">
          <Header breadcrumbData={breadcrumbsData} />
          <main>
            <div className="mx-auto py-lg px-sm md:px-lg md:py-xl 2xl:px-xl 2xl:py-2xl mt-[45px]">
              <Outlet />
            </div>
          </main>
        </div>
      </div>
    </div>
  );
};

export default Wrapper;
