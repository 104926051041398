/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Switch,
  Typography,
  List,
  ListItem,
  Chip,
} from "@material-tailwind/react";
import Input from "../../../atoms/Input";
import api from "../../../api/configApi";
import { Button } from "../../../atoms/Button";
import { CheckboxCust } from "../../../atoms/Checkbox";
import AlertNotification from "../../../atoms/AlertNotification";
import { FaCheckCircle } from "react-icons/fa";
import Dropdown from "../../../atoms/DropdownWithReset";

const AddUserRole = ({ onReloadData }) => {
  const navigate = useNavigate();
  const [role, setRole] = useState("");
  const [menus, setMenus] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [permissionValues, setPermissionValues] = useState({});
  const [isAddSuccess, setAddSuccess] = useState(false);
  const [isActive, setActive] = useState(true);
  const [activeMenuId, setActiveMenuId] = useState("DASHBOARD");
  const [isAddError, setIsAddError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [selectAllValues, setSelectAllValues] = useState({});
  const [levels, setLevels] = useState([])
  const [selectLevels, setSelectLevels] = useState(null)

  const isSubmitDisabled = !role;

  useEffect(() => {
    const fetchMenus = async () => {
      try {
        const response = await api.get("/v1/roles/menus");
        setMenus(response.data.contents);
      } catch (error) {
        console.error("Error fetching menus:", error);
      }
    };

    const fetchPermissions = async () => {
      try {
        const response = await api.get("/v1/roles/permissions", {
          params: {
            menu: activeMenuId,
          },
        });
        setPermissions(response.data.contents);
      } catch (error) {
        console.error("Error fetching menus:", error);
      }
    };

    const fetchRole = async () => {
      try {
        const responseRole = await api.get('/v1/roles/levels')
        setLevels(responseRole.data.contents)
      } catch (error) {
        console.error("error fetching role", error)
      }
    }

    fetchRole()
    fetchMenus();
    fetchPermissions();
  }, [activeMenuId]);

  useEffect(() => {
    if (activeMenuId && !selectAllValues[activeMenuId]) {
      // Initialize selectAllValues for the new active menu
      const initialSelectAllValues = {};
      permissions.forEach((permission) => {
        initialSelectAllValues[permission.id] = false;
      });
      setSelectAllValues({
        ...selectAllValues,
        [activeMenuId]: initialSelectAllValues,
      });
      setSelectAll(false);
    } else if (activeMenuId) {
      // Restore selectAll state for the previous active menu
      setSelectAll(
        Object.values(selectAllValues[activeMenuId]).some((value) => value)
      );
    }
  }, [activeMenuId, permissions, selectAllValues]);

  const handleSelectAllChange = () => {
    const updatedPermissionValues = { ...permissionValues };
    const updatedSelectAllValues = { ...selectAllValues };

    if (selectAll) {
      // Uncheck all permissions for the active menu
      updatedPermissionValues[activeMenuId] = {};
      permissions.forEach((permission, index) => {
        updatedPermissionValues[activeMenuId][permission.id] = false;
        updatedSelectAllValues[activeMenuId][index] = false;
      });
    } else {
      // Check all permissions for the active menu
      updatedPermissionValues[activeMenuId] = {};
      permissions.forEach((permission, index) => {
        updatedPermissionValues[activeMenuId][permission.id] = true;
        updatedSelectAllValues[activeMenuId][index] = true;
      });
    }

    setSelectAll(!selectAll);
    setPermissionValues(updatedPermissionValues);
    setSelectAllValues(updatedSelectAllValues);
  };

  const handleSelectLevel = (select) => {
    // setLevels(select.id)
    setSelectLevels(select.id)
  }

  const handlePermissionCheckboxChange = (menuId, permission) => {
    const updatedPermissionValues = { ...permissionValues };

    if (!updatedPermissionValues[menuId]) {
      updatedPermissionValues[menuId] = {};
    }

    updatedPermissionValues[menuId][permission.id] =
      !updatedPermissionValues[menuId][permission.id];
    setPermissionValues(updatedPermissionValues);
  };

  const handleSwitchChange = () => {
    setActive(!isActive);
  };

  const handleSave = async () => {
    setIsSubmitting(true);
    try {
      const newUserRole = {
        name: role,
        isActive: isActive,
        level: selectLevels,
        permissions: menus
          .filter(
            (menu) =>
              permissionValues[menu.id] &&
              Object.values(permissionValues[menu.id]).some((value) => value)
          )
          .map((menu) => ({
            menu: menu.id,
            permissions: Object.keys(permissionValues[menu.id])
              .filter((permissionId) => permissionValues[menu.id][permissionId])
              .map((permissionId) => ({ id: permissionId })),
          })),
      };

      const response = await api.post("/v1/roles", newUserRole);

      setPermissionValues({});

      setAddSuccess(true);

      navigate("/pengaturan-pengguna/role-akses");
      localStorage.setItem("responseAddSuccess", JSON.stringify(!isAddSuccess));

      if (onReloadData) {
        onReloadData();
      }
    } catch (error) {
      setIsSubmitting(false);
      console.error("Gagal menambahkan user role:", error.response.data.error);
      setIsAddError(true);
      setErrorMessage(
        error.response.data.error?.name || error.response.data.error
      );
      setTimeout(() => setIsAddError(false), 2000);
    }
  };

  const handleBack = () => {
    setActiveMenuId(null);
    navigate("/pengaturan-pengguna/role-akses");
  };


  return (
    <div className="p-4">
      <div>
        <Card className="border shadow-none">
          <CardHeader floated={false} shadow={false} className="rounded-b-none">
            <Typography className="text-xl font-bold text-text-primary">
              Buat role baru
            </Typography>
          </CardHeader>
          <CardBody className="p-5">
            <div className="mb-4 grid grid-cols-1 gap-4 w-full sm:grid-cols-2 sm:max-w-[75%]">
              <div>
                <label className="block text-sm font-semibold text-text-primary">
                  Pilih Level{" "}
                  <span className="text-red-500 text-sm font-normal leading-tight">
                    *
                  </span>
                </label>

                <div className="max-w-sm mt-md">
                  <Dropdown 
                    placeholder="Pilih level"
                    options={levels}
                    setSelectedLabel={selectLevels}
                    onSelect={handleSelectLevel}
                  />
                </div>
              </div>

              <div>
                <label className="block text-sm font-semibold text-text-primary">
                  Role{" "}
                  <span className="text-red-500 text-sm font-normal leading-tight">
                    *
                  </span>
                </label>
                <Input
                  type="text"
                  id="role"
                  placeholder="Masukkan nama role"
                  className="max-w-sm mt-md"
                  value={role}
                  size="medium"
                  onChange={(e) => setRole(e.target.value)}
                />
              </div>

            </div>
            <label className="block text-sm font-semibold text-text-primary mb-md">
              Akses
            </label>
            <Card className="shadow-none border border-border-secondary">
              <CardHeader
                floated={false}
                shadow={false}
                className="grid grid-cols-3 gap-4 m-0 p-0 border rounded-b-none bg-neutral-50"
              >
                <div className="m-0 p-lg rounded-md">
                  <Typography className="flex pl-3 font-semibold items-center leading-tight gap-5 text-sm text-text-quarterary">
                    Akses menu
                  </Typography>
                </div>
                <div className="col-span-2 border-l-2 h-full m-0 p-lg">
                  <Typography className="flex pl-3 font-semibold items-center leading-tight gap-5 text-sm text-text-quarterary">
                    Aksi yang diizinkan
                  </Typography>
                </div>
              </CardHeader>
              <CardBody className="grid grid-cols-3 gap-4 m-0 border rounded-md rounded-t-none p-0">
                <div
                  className="col-span-1"
                  style={{ maxHeight: "320px", overflowY: "auto" }}
                >
                  <List className="p-lg">
                    {menus.map((menu) => (
                      <>
                        <ListItem
                          className="grid grid-cols-2 gap-4 border-none mx-0 p-lg focus:bg-neutral-200"
                          onClick={() => setActiveMenuId(menu.id)}
                          selected={activeMenuId === menu.id}
                        >
                          <Typography className="flex font-medium items-center leading-normal gap-5 text-base text-text-quarterary">
                            {menu.labels}
                          </Typography>
                          <Typography className="flex font-medium leading-tight gap-5 text-text-secondary text-base items-end justify-end">
                            {permissionValues[menu.id] &&
                              Object.values(permissionValues[menu.id]).filter(
                                (value) => value
                              ).length > 0 && (
                                <Chip
                                  className="w-6 h-[26px] px-1.5 py-0.5 bg-gray-50 text-sm text-text-secondary rounded-md border border-border-secondary justify-center items-center gap-1 inline-flex"
                                  value={`${
                                    Object.values(
                                      permissionValues[menu.id]
                                    ).filter((value) => value).length
                                  }`}
                                />
                              )}
                          </Typography>
                        </ListItem>
                      </>
                    ))}
                  </List>
                </div>
                <div
                  className="col-span-2 border-l-2 m-0 p-0"
                  style={{ maxHeight: "320px", overflowY: "auto" }}
                >
                  <ul className="h-full mx-0 col-span-2">
                    <li className="rounded-none pb-sm p-md flex justify-start">
                      <div>
                        {" "}
                        <CheckboxCust
                          className="checked:bg-bg-brandSolid"
                          onChange={handleSelectAllChange}
                          checked={selectAll}
                        />{" "}
                      </div>
                      <div className="flex items-center text-text-secondary">
                        Semua
                      </div>
                    </li>

                    {menus.map((menu) => (
                      <>
                        {activeMenuId && menu.id === activeMenuId && (
                          <>
                            {permissions.map((permission) => (
                              <li
                                className="p-md flex justify-start"
                                key={permission.id}
                              >
                                <div>
                                  <CheckboxCust
                                    color="lightBlue"
                                    className="checked:bg-bg-brandSolid"
                                    checked={
                                      permissionValues[menu.id]?.[permission.id]
                                    }
                                    onChange={() =>
                                      handlePermissionCheckboxChange(
                                        menu.id,
                                        permission
                                      )
                                    }
                                  />{" "}
                                </div>
                                <div className="flex items-center text-text-secondary">
                                  {permission.labels}
                                </div>
                              </li>
                            ))}
                          </>
                        )}
                      </>
                    ))}
                    {!activeMenuId && (
                      <div className="flex justify-center items-center h-[250px]">
                        <div className="w-[216px] text-center">
                          <span
                            className={`text-text-quarterary text-sm font-normal leading-tight`}
                          >
                            Harap pilih{" "}
                          </span>
                          <span className="text-text-quarterary text-sm font-semibold leading-tight">
                            Akses menu
                          </span>
                          <span className="text-text-quarterary text-sm font-normal leading-tight">
                            {" "}
                            terlebih dahulu sebelum memilih{" "}
                          </span>
                          <span className="text-text-quarterary text-sm font-semibold leading-tight">
                            Aksi
                          </span>
                          <span className="text-text-quarterary text-sm font-normal leading-tight">
                            {" "}
                            yang diizinkan.
                          </span>
                        </div>
                      </div>
                    )}
                  </ul>
                </div>
              </CardBody>
            </Card>
            <div className="mt-9 items-center gap-4">
              <div className="my-3">
                <label className="block text-text-primary text-sm font-semibold">
                  Aktifkan Role?
                </label>
              </div>
              <div>
                <Switch
                  className="checked:bg-bg-brandSolid"
                  onChange={handleSwitchChange}
                  checked={isActive}
                />
                <span className="m-2 text-sm text-text-secondary">
                  {isActive ? "Aktif" : "Tidak Aktif"}
                </span>
              </div>
            </div>
          </CardBody>
          <CardFooter className="flex justify-end border-t border-border-secondary p-lg">
            <Button
              label="Batal"
              size="medium"
              type="btnSecondary"
              className="mr-md"
              onClick={handleBack}
            />
            <Button
              label="Simpan"
              size="medium"
              type={`${
                isSubmitDisabled || isSubmitting
                  ? "btnPrimaryDisabled"
                  : "btnPrimary"
              }`}
              onClick={handleSave}
              disabled={isSubmitDisabled || isSubmitting}
              componentLeft={
                isSubmitting && (
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    className="animate-spin fill-neutral-400"
                  >
                    <path
                      d="M10.72,19.9a8,8,0,0,1-6.5-9.79A7.77,7.77,0,0,1,10.4,4.16a8,8,0,0,1,9.49,6.52A1.54,1.54,0,0,0,21.38,12h.13a1.37,1.37,0,0,0,1.38-1.54,11,11,0,1,0-12.7,12.39A1.54,1.54,0,0,0,12,21.34h0A1.47,1.47,0,0,0,10.72,19.9Z"
                      className="spinner_6kVp"
                    />
                  </svg>
                )
              }
            />
          </CardFooter>
        </Card>
        {isAddError && (
          <AlertNotification
            icon={<FaCheckCircle />}
            color="red"
            message={errorMessage}
          />
        )}
      </div>
    </div>
  );
};

export default AddUserRole;
