import React from "react";

import { cva } from "class-variance-authority";

import { cn } from "../../shared/utils";

const badgeStatusVariants = cva(
  "px-[6px] py-[2px] border rounded-[6px] flex items-center gap-1 w-fit text-xs font-medium",
  {
    variants: {
      variant: {
        SETTING: "bg-primary-25 text-primary-600 border-primary-200",
        BERJALAN: "border-blue-200 bg-blue-25 text-blue-600",
        SELESAI: "bg-neutral-25 text-neutral-600 border-neutral-200",
        CLOSING: "bg-neutral-25 text-neutral-600 border-neutral-200",
      },
    },
    defaultVariants: {
      variant: "SETTING",
    },
  },
);

const dotsVariants = cva("w-[6px] h-[6px] rounded-full flex-shrink-0", {
  variants: {
    dots: {
      SETTING: "bg-primary-600",
      BERJALAN: "bg-blue-600",
      SELESAI: "bg-neutral-600",
      CLOSING: "bg-neutral-600",
    },
  },
});

const BadgeStatus = ({
  className,
  variant,
  dots,
  text,
  disableDots = false,
  ...props
}) => {
  const parsingText = (text) => {
    switch (text) {
      case "SETTING":
        return "Setting";
      case "BERJALAN":
        return "Berjalan";
      case "SELESAI":
        return "Selesai";
      case "CLOSING":
        return "Selesai";
      case "UNKNOWN":
        return "-";
      default:
        return "";
    }
  };

  const parsedText = parsingText(text);

  return (
    <div className={cn(badgeStatusVariants({ variant }), className)} {...props}>
      {!disableDots && <div className={dotsVariants({ dots })} />}
      {parsedText || "-"}
    </div>
  );
};

export default BadgeStatus;
