/* eslint-disable no-unused-vars */
import React, { useRef, useState, useEffect } from "react";
import { ReactComponent as ChevronDown } from "../assets/icons/chevron_down.svg";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { MdOutlineDone } from "react-icons/md";
import {
  Popover,
  PopoverContent,
  PopoverHandler,
} from "@material-tailwind/react";

export const DropdownSingleWithSearch = ({
  options,
  onSelect,
  placeholder,
  defaultSelected,
  selectedOption,
  onReset,
  setSelectedLabel,
  setSelectedLevel,
  positionClassName,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [labelSelected, setLabelSelected] = useState("");
  const [levelSelected, setLevelSelected] = useState("");

  const inputRef = useRef();

  useEffect(() => {
    setLabelSelected(selectedOption ? selectedOption.labels : "");
    setLevelSelected(selectedOption ? selectedOption.level : "");
  }, [selectedOption]);

  const filteredOptions = Array.isArray(options)
    ? options.filter((item) =>
        item?.labels?.toLowerCase().includes(searchTerm?.toLowerCase())
      )
    : [];

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);

    if (e.target.value === "") {
      setLabelSelected("");
      setLevelSelected("");
    }
  };

  const handleSelect = (selectedOption) => {
    onSelect(selectedOption);
    setIsOpen(false);
    setLabelSelected(selectedOption.labels || "");
    setLevelSelected(selectedOption.level || "");
    if (typeof setSelectedLabel === "function") {
      setSelectedLabel(selectedOption ? selectedOption.labels : "");
    }
    if (typeof setSelectedLevel === "function") {
      setSelectedLevel(selectedOption ? selectedOption.level : "");
    }
    setSearchTerm("");
  };

  const handleClickOutside = (event) => {
    if (inputRef.current && !inputRef.current.contains(event.target)) {
      setIsOpen(false);
      setSearchTerm("");
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <Popover
      placement="bottom-start"
      open={isOpen}
      handler={() => setIsOpen(!isOpen)}
    >
      <PopoverHandler className="cursor-pointer min-w-[230px] w-full">
        <div>
          <button
            className={`border-border-primary w-full min-w-[230px] p-5 h-11 py-2.5 justify-center items-center gap-md inline-flex text-base font-medium leading-normal rounded-md bg-white hover:bg-neutral-50 border text-text-placeholder focus:shadow-sm focus:shadow-gray-300 hover:border-neutral-300`}
          >
            <div
              className={`justify-start min-w-6 w-full items-center flex cursor-pointer text-text-placeholder font-normal text-base`}
            >
              <div className="w-auto ">
                {labelSelected
                  ? labelSelected
                  : placeholder
                  ? placeholder
                  : "Pilih Data"}
              </div>
            </div>
            <div className="w-6 h-6 relative">
              <ChevronDown
                className={`absolute mt-[2.5px] ${
                  isOpen ? "transform rotate-180" : ""
                } text-text-placeholder`}
              />
            </div>
          </button>
        </div>
      </PopoverHandler>

      <PopoverContent
        className={`border border-border-primary ${
          positionClassName ? positionClassName : "top-[415px] left-[520px]"
        } p-2 w-full max-w-[350px] gap-4 z-50`}
      >
        <div className="flex items-start w-full min-w-[230px] gap-y-4 cursor-pointer">
          <div className="py-0">
            <div className="sticky top-0 bg-white border-b border-border-primary flex pr-2">
              <div className="relative p-2 w-full">
                <input
                  type="text"
                  className="min-w-[310px] max-w-[370px] w-full border-none pr-5xl placeholder:text-text-placeholder text-text-secondary px-sm py-xs justify-start items-center gap-xxs inline-flex focus:outline-none"
                  placeholder="Cari..."
                  value={searchTerm}
                  onChange={handleSearchChange}
                />
                <div className="absolute inset-y-0 right-0 flex items-center focus:outline-none">
                  <MagnifyingGlassIcon className="h-5 w-5" />
                </div>
              </div>
            </div>

            <div className="overflow-y-auto max-h-[250px]">
              {filteredOptions?.length > 0 ? (
                filteredOptions?.map((options, index) => (
                  <div
                    className="flex cursor-pointer hover:bg-gray-100 my-1"
                    onClick={() => handleSelect(options)}
                  >
                    <div
                      key={index}
                      className="flex relative z-30 items-center px-4 py-2 text-sm text-text-secondary w-full justify-between "
                    >
                      {options?.labels || options?.name || " "}
                    </div>
                    <div className="items-center flex justify-end p-2">
                      {labelSelected === options?.labels && (
                        <MdOutlineDone className="w-5 h-5 text-green-500" />
                      )}
                    </div>
                  </div>
                ))
              ) : (
                <div className="my-3 flex justify-start">
                <span className="text-text-secondary px-4">Tidak ada data</span>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* <div className="flex w-full justify-start border-t px-4 py-3">
          <button
            className={`w-full p-0 h-6 justify-start items-center inline-flex text-base font-medium leading-normal rounded-md bg-white border-none text-neutral-700 shadow-none`}
          >
            <div
              className={`justify-start min-w-6 w-full text-sm items-center flex cursor-pointer text-blue-600 hover:text-blue-700`}
              onClick={onReset}
            >
              Atur ulang
            </div>
          </button>
        </div> */}
      </PopoverContent>
    </Popover>
  );
};
