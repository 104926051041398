import React, { useRef, useState } from "react";
import {ReactComponent as ChevronDown} from '../assets/icons/chevron_down.svg'

const sizes = {
  xsmall: { input: "h-7 rounded-sm", icon: "h-5 w-5" },
  small: { input: "h-8 rounded-sm", icon: "h-5 w-5" },
  medium: { input: "h-11 rounded-md", icon: "h-5 w-5" },
  large: { input: "h-14 rounded-md", icon: "h-6 w-6" },
};

const Dropdown = ({ options, onSelect, placeholder, size, disabled }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  // const [selected, setSelected] = useState("");
  const [labelSelected, setLabelSelected] = useState("")
  const inputRef = useRef()

  const filteredOptions = options?.filter(item => 
    item?.labels?.toLowerCase().includes(searchTerm.toLowerCase())
  ) 


  const handleSearchChange = (e) => setSearchTerm(e.target.value)
  const handleToggle = () => setIsOpen(!isOpen);
  const handleSelect = (selectedOption) => {
    // console.log(selectedOption.id);
    onSelect(selectedOption)
    setIsOpen(false);
    // setSelected(selectedOption.id)
    setLabelSelected(selectedOption.labels)
  };

  const handleClickOutside = event => {
    if (inputRef.current && !inputRef.current.contains(event.target)) {
      setIsOpen(false);
      setSearchTerm('');
    }
  };

  document.addEventListener('mousedown', handleClickOutside);
  const height = sizes[size]["input"]

  return (
    <div className="relative">
      <input
        type="text"
        placeholder={placeholder}
        className={`${height} w-full rounded-lg border border-gray-300 p-2 focus:outline-none focus:border-blue-500`}
        value={searchTerm || labelSelected}
        onChange={handleSearchChange}
        onClick={handleToggle}
        style={{ caretColor: 'transparent' }}
        disabled={disabled}
      />
      <ChevronDown
        className={`absolute right-4 top-3 ${isOpen ? 'transform rotate-180' : ''} text-gray-500`}
        onClick={handleToggle}
      />
      {isOpen && (
        <ul className="relative z-30 rounded-lg shadow bg-white w-full mt-1 overflow-y-auto max-h-60">
          {filteredOptions?.map((item, index) => (
            <li
              key={index}
              className="rounded-lg px-2 py-1 hover:bg-gray-100 cursor-pointer gap-4"
              onClick={() => handleSelect(item)}
            >
              {item.labels}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Dropdown;





