import React from "react";
import { CheckboxCust } from "../../atoms/Checkbox";
import { Chip } from "@material-tailwind/react";
import { FiExternalLink } from "react-icons/fi";
import { Link } from "react-router-dom";

const TableMonGrow = ({
  data,
  setSelectedItem,
  monGrowType,
  selectAll,
  setSelectAll,
  selectedRows,
  setSelectedRows,
}) => {
  const handleShowDetail = (item) => {
    setSelectedItem(item);
  };

  const TABLE_HEAD = [
    {
      title: "ID Periode",
      rowSpan: 2,
      className: "px-lg border-y border-secondary min-w-[200px]",
    },
    {
      title: "TGL DOC In",
      rowSpan: 2,
      className: "px-lg border-y border-l border-secondary min-w-[120px]",
    },
    {
      title: "Umur Sampling",
      rowSpan: 2,
      className: "px-lg border-y border-l border-border-secondary",
    },
    {
      title: "BW (Gram)",
      colSpan: 2,
      className: "px-lg text-center border-t border-l border-border-secondary w-[200px]",
      subHeaders: [
        {
          title: "Std",
          className:
            "px-lg text-center border-y border-l border-border-secondary",
        },
        {
          title: "Act",
          className:
            "px-lg text-center border-y border-l border-border-secondary",
        },
      ],
    },
    {
      title: "FCR",
      colSpan: 3,
      className: "px-lg text-center border-t border-l border-border-secondary w-[300px]",
      subHeaders: [
        {
          title: "Std",
          className:
            "px-lg text-center border-y border-l border-border-secondary",
        },
        {
          title: "Act",
          className:
            "px-lg text-center border-y border-l border-border-secondary",
        },
        {
          title: "Diff",
          className:
            "px-lg text-center border-y border-l border-border-secondary",
        },
      ],
    },
    {
      title: "Daily Gain",
      rowSpan: 2,
      className: "px-lg border-y border-l border-border-secondary",
    },
    {
      title: "CDPL",
      rowSpan: 2,
      className: "px-lg border-y border-l border-border-secondary",
    },
    {
      title: "Cum Feed (G/E)",
      rowSpan: 2,
      className: "px-lg border-y border-l border-border-secondary",
    },
    {
      title: "IP",
      rowSpan: 2,
      className: "px-lg border-y border-l border-border-secondary",
    },
  ];

  const handleCheckboxChange = (id) => {
    setSelectedRows((prevSelectedRows) => {
      if (prevSelectedRows?.includes(id)) {
        return prevSelectedRows?.filter((rowID) => rowID !== id);
      } else {
        return [...prevSelectedRows, id];
      }
    });

    setSelectAll(false);
  };

  const handleCheckAllChange = () => {
    setSelectAll(!selectAll);
    setSelectedRows(selectAll ? [] : data.map((row) => row.id));
  };

  return (
    <table className="table-auto w-full border-separate border-spacing-0">
      <thead className=" bg-neutral-50 text-left text-sm font-bold text-text-primary sticky top-0 z-10">
        <tr>
          <th className="px-lg border-y border-secondary w-[60px] max-w-[60px]" rowSpan={2}>
            <CheckboxCust
              className="checked:bg-primary-500"
              onChange={handleCheckAllChange}
              checked={selectAll}
            />
          </th>
          {TABLE_HEAD.map((head, index) =>
            head.colSpan ? (
              <th key={index} colSpan={head.colSpan} className={head.className}>
                {head.title}
              </th>
            ) : (
              <th key={index} rowSpan={head.rowSpan} className={head.className}>
                {head.title}
              </th>
            )
          )}
        </tr>
        <tr>
          {TABLE_HEAD.filter((head) => head.subHeaders).map((head, index) =>
            head.subHeaders.map((subHead, subIndex) => (
              <th key={`${index}-${subIndex}`} className={subHead.className}>
                {subHead.title}
              </th>
            ))
          )}
        </tr>
      </thead>
      <tbody className="text-sm text-left">
        {data?.map((item) => (
          <React.Fragment key={item.id}>
            <tr>
              <td
                className={`px-lg py-md w-[60px] max-w-[60px] border-b min-w-full border-border-secondary`}
                rowSpan={item.dailyRecords.length || 1}
              >
                <CheckboxCust
                  className="checked:bg-primary-500"
                  onChange={() => handleCheckboxChange(item.id)}
                  checked={selectedRows?.includes(item.id)}
                />
              </td>
              <td
                className={`px-lg py-md border-b border-border-secondary min-w-full`}
                rowSpan={item.dailyRecords.length || 1}
              >
                <div
                  className="cursor-pointer font-bold text-sm text-text-primary hover:text-text-secondary my-xs"
                  onClick={() => handleShowDetail(item)}
                >
                  {item.house?.owner?.fullName}
                </div>
                <div
                  className="cursor-pointer text-xs font-normal text-text-quarterary hover:text-text-secondary leading-tight"
                  onClick={() => handleShowDetail(item)}
                >
                  {item.analyticAccount}
                </div>
                <div className="w-max py-md">
                  <Chip
                    size="sm"
                    variant="ghost"
                    value={item.age + " Hari"}
                    className={`text-sm font-normal bg-transparent border border-border-secondary leading-[18px] normal-case`}
                    icon={
                      <span
                        className={`mx-auto mt-1 block h-2 w-2 rounded-full ${
                          monGrowType === "berjalan"
                            ? "bg-green-500"
                            : "bg-neutral-400"
                        }`}
                      />
                    }
                  />
                </div>
                <div>
                  <Link
                    to={`/periode-budidaya/periode-berjalan/${item.id}/dataharian`}
                    className="text-button-linkFg text-sm font-bold hover:underline flex items-start gap-1"
                  >
                    Data Harian <FiExternalLink className="h-4 w-4" />
                  </Link>
                </div>
              </td>
              <td
                className={`px-lg py-md border-b border-l border-border-secondary" text-center`}
                rowSpan={item.dailyRecords.length || 1}
              >
                {new Date(item.docInDate).toLocaleDateString()}
              </td>
              {item.dailyRecords.length > 0 ? (
                <React.Fragment>
                  <td className="px-lg py-md text-right border-b border-l border-border-secondary">
                    {item.dailyRecords[0].samplingAge}
                  </td>
                  <td className="px-lg py-md text-right border-b border-l border-border-secondary">
                    {item.dailyRecords[0].standardWeight?.toLocaleString("id-ID") || "-"}
                  </td>
                  <td className="px-lg py-md text-right border-b border-l border-border-secondary">
                    {item.dailyRecords[0].avgWeight?.toLocaleString("id-ID") || "-"}
                  </td>
                  <td className="px-lg py-md text-right border-b border-l border-border-secondary">
                    {item.dailyRecords[0].standardFcr?.toLocaleString("id-ID") || "-"}
                  </td>
                  <td className="px-lg py-md text-right border-b border-l border-border-secondary">
                    {item.dailyRecords[0].actualFcr?.toLocaleString("id-ID") || "-"}
                  </td>
                  <td
                    className={`px-lg py-md border-l border-b border-border-secondary text-right ${
                      item.dailyRecords[0].diffFcr < 0
                        ? "text-text-successPrimary"
                        : "text-text-errorPrimary"
                    }`}
                  >
                    {item.dailyRecords[0].diffFcr < 0
                      ? "(" +
                        item.dailyRecords[0].diffFcr?.toLocaleString("id-ID") +
                        ")"
                      : item.dailyRecords[0].diffFcr?.toLocaleString("id-ID")}
                  </td>
                  <td className="px-lg py-md border-b border-l border-border-secondary text-right">
                    {item.dailyRecords[0].dailyGain}
                  </td>
                  <td className="px-lg py-md border-b border-l border-border-secondary text-right">
                    {item.dailyRecords[0].cdpl + "%"}
                  </td>
                  <td className="px-lg py-md border-b border-l border-border-secondary text-right">
                    {item.dailyRecords[0].cumFeed || "-"}
                  </td>
                  <td className="px-lg py-md border-b border-l border-border-secondary text-right">
                    {item.dailyRecords[0].ip?.toLocaleString("id-ID") || "-"}
                  </td>
                </React.Fragment>
              ) : (
                <td
                  className="px-lg py-md text-center border-b border-l border-border-secondary"
                  colSpan="10"
                >
                  <span className="text-gray-500 italic">
                    Belum ada data harian
                  </span>
                </td>
              )}
            </tr>
            {item.dailyRecords.length > 0 &&
              item.dailyRecords.slice(1).map((record, index) => (
                <tr key={`${item.id}-record-${index}`}>
                  <td className="px-lg py-md text-right border-b border-l border-border-secondary">
                    {record?.samplingAge}
                  </td>
                  <td className="px-lg py-md text-right border-b border-l border-border-secondary">
                    {record?.avgWeight?.toLocaleString("id-ID") || "-"}
                  </td>
                  <td className="px-lg py-md text-right border-b border-l border-border-secondary">
                    {record?.standardWeight?.toLocaleString("id-ID") || "-"}
                  </td>
                  <td className="px-lg py-md text-right border-b border-l border-border-secondary">
                    {record?.standardFcr?.toLocaleString("id-ID") || "-"}
                  </td>
                  <td className="px-lg py-md text-right border-b border-l border-border-secondary">
                    {record?.actualFcr?.toLocaleString("id-ID")  || "-"}
                  </td>
                  <td
                    className={`px-lg py-md border-l border-b border-border-secondary text-right ${
                      record.diffFcr < 0
                        ? "text-text-successPrimary"
                        : "text-text-errorPrimary"
                    }`}
                  >
                    {record.diffFcr < 0
                      ? "(" + record.diffFcr.toLocaleString("id-ID") + ")"
                      : record.diffFcr.toLocaleString("id-ID")}
                  </td>

                  <td className="px-lg py-md border-b border-l border-border-secondary text-right">
                    {record.dailyGain}
                  </td>
                  <td className="px-lg py-md border-b border-l border-border-secondary text-right">
                    {record.cdpl + "%"}
                  </td>
                  <td className="px-lg py-md border-b border-l border-border-secondary text-right">
                    {record.cumFeed?.toLocaleString("id-ID") || "-"}
                  </td>
                  <td className="px-lg py-md border-b border-l border-border-secondary text-right">
                    {record.ip?.toLocaleString("id-ID")  || "-"}
                  </td>
                </tr>
              ))}

            {monGrowType === "closing" && (
              <tr>
                <td
                  className="bg-bg-infoPrimary border-b border-border-secondary"
                  colSpan={2}
                ></td>
                <td className="bg-bg-infoPrimary border-b border-l border-border-secondary text-right px-lg py-md"></td>
                <td className="bg-bg-infoPrimary border-b border-l border-border-secondary text-right px-lg py-md">
                  {item?.recap?.totalRecord} Hari
                </td>
                <td className="bg-bg-infoPrimary border-b border-l border-border-secondary text-right px-lg py-md">
                  -
                </td>
                <td className="bg-bg-infoPrimary border-b border-l border-border-secondary text-right px-lg py-md">
                  {item?.recap?.averageWeight?.toLocaleString("id-ID") }
                </td>
                <td className="bg-bg-infoPrimary border-b border-l border-border-secondary text-right px-lg py-md">
                  {item?.recap?.standardFcr?.toLocaleString("id-ID") }
                </td>
                <td className="bg-bg-infoPrimary border-b border-l border-border-secondary text-right px-lg py-md">
                  {item?.recap?.actualFcr?.toLocaleString("id-ID") }
                </td>
                <td
                    className={`bg-bg-infoPrimary border-b border-l border-border-secondary text-right px-lg py-md ${
                      item?.recap?.diffFcr < 0
                        ? "text-text-successPrimary"
                        : "text-text-errorPrimary"
                    }`}
                  >
                    {item?.recap?.diffFcr< 0
                      ? "(" + item?.recap?.diffFcr?.toLocaleString("id-ID") + ")"
                      : item?.recap?.diffFcr?.toLocaleString("id-ID")}
                  </td>
                <td className="bg-bg-infoPrimary border-b border-l border-border-secondary text-right px-lg py-md">
                  -
                </td>
                <td className="bg-bg-infoPrimary border-b border-l border-border-secondary text-right px-lg py-md">
                  {item?.recap?.cdpl}
                </td>
                <td className="bg-bg-infoPrimary border-b border-l border-border-secondary text-right px-lg py-md">
                  {item?.recap?.cumFeed?.toLocaleString("id-ID")}
                </td>
                <td className="bg-bg-infoPrimary border-b border-l border-border-secondary text-right px-lg py-md">
                  {item?.recap?.ip?.toLocaleString("id-ID")}
                </td>
              </tr>
            )}
          </React.Fragment>
        ))}
      </tbody>
    </table>
  );
};

export default TableMonGrow;
