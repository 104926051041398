import React, { useState, useEffect, useCallback, useRef } from "react";
import bg from "../../img/Wave-cloud.png";
import ilustration from "../../img/Illustration.svg";
import {
  Tabs,
  TabsHeader,
  Tab,
  Card,
  Typography,
} from "@material-tailwind/react";

import { FaCheckCircle, FaEye, FaEyeSlash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import EmailInput from "../../molecules/login/EmailInput";
import PhoneInput from "../../molecules/login/PhoneInput";

import { Button } from "../../atoms/Button";
import { Logo } from "../../atoms/Logo";
import Input from "../../atoms/Input";
import OTPVerificationForm from "../../molecules/login/FormVerificationOTP";
import api from "../../api/configApiAuth";
import ModalVerificationOTP from "../../molecules/login/ModalVerificationOTP";
import FormForgotPassword from "../../molecules/login/FormForgotPassword";
import { useDispatch, useSelector } from "react-redux";
import { LoginUser, reset, setToken } from "../../molecules/features/authSlice";
import AlertNotification from "../../atoms/AlertNotification";

const Login = () => {
  const navigate = useNavigate();
  const [loginType, setLoginType] = useState("phone");

  const [email, setEmail] = useState("");

  const [phone, setPhone] = useState("");
  const [countryCode, setCountryCode] = useState("");

  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const [isPasswordLengthValid, setIsPasswordLengthValid] = useState(true);
  const [isPasswordEmpty, setIsPasswordEmpty] = useState(false);

  const [showOTPVerification, setShowOTPVerification] = useState(false);
  const [typeOTPVerification, setTypeOTPVerification] = useState("");

  const [receiverOTP, setReceiverOTP] = useState("");
  const [otpSession, setOtpSession] = useState("");
  const [showOTPModal, setShowOTPModal] = useState(false);

  const [emailForgotPassword, setEmailForgotPassword] = useState("");
  const [showForgotPassword, setShowForgotPassword] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoginError, setIsLoginError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const statusSucced = JSON.parse(localStorage.getItem("success"));
  const [notifSuccess, setNotifuSuccess] = useState(false);

  const passwordRef = useRef(null);

  const dispatch = useDispatch();
  // const token = useSelector(selectToken)

  const { user, isError, isLoginSuccess, isLoading, message } = useSelector(
    (state) => state.auth
  );

  // const handleShowOTPVerification = () => {
  //   setShowOTPVerification(!showOTPVerification);
  // };

  const handleShowOTPVerification = useCallback(() => {
    setShowOTPVerification(
      (prevShowOTPVerification) => !prevShowOTPVerification
    );
  }, []);

  const handleShowForgotPassword = () => {
    // setShowForgotPassword(!showForgotPassword);
    navigate('/lupa-password')
  };

  const checkPasswordLength = (newPassword) => {
    if (newPassword.length < 8) {
      setIsPasswordLengthValid(false);
    } else {
      setIsPasswordLengthValid(true);
    }
  };

  const handlePasswordBlur = () => {
    // Validasi saat fokus keluar dari field password
    if (password.trim() === "") {
      setIsPasswordEmpty(true);
    } else {
      setIsPasswordEmpty(false);
    }
  };

  const isSubmitDisabled =
    loginType === "email" ? !email || !password : !phone || !password;

  const auth = async (event) => {
    event.preventDefault();

    if (!isSubmitting) {
      let formattedPhone = phone;

      // Hilangkan angka 0 di depan jika ada
      formattedPhone = formattedPhone.replace(/^0+/, "");

      // Jika phone tidak mengandung kode negara, tambahkan countryCode
      if (!formattedPhone.startsWith(countryCode)) {
        formattedPhone = countryCode + formattedPhone;
      }

      dispatch(
        LoginUser({ loginType, phone: formattedPhone, email, password })
      );
    }
  };

  useEffect(() => {
    if (statusSucced === 200) {
      setNotifuSuccess(true);
    }
  }, [statusSucced]);

  useEffect(() => {
    if (statusSucced === 200) {
      setTimeout(() => {
        setNotifuSuccess(false);
        localStorage.clear("success");
      }, 2000);
    }
  }, [statusSucced]);

  useEffect(() => {
    if (user || isLoginSuccess) {
      if (loginType === "phone") {
        if (user?.otpSession === null) {
          navigate("/dashboard");
        } else {
          setOtpSession(user?.otpSession);
          if (isLoginSuccess) {
            setShowOTPModal(true);
          }
        }
      } else {
        if (user?.otpSession === null) {
          navigate("/dashboard");
        } else {
          api
            .post("v1/auth/sendOtp", {
              username: email,
              otpSession: user?.otpSession,
              destination: "EMAIL",
            })

            .then((response) => {
              setOtpSession(response?.data?.otpSession);
              setTypeOTPVerification("Email");
              setReceiverOTP(email);
              handleShowOTPVerification();
              return response.data;
              // eslint-disable-next-line no-unreachable
              dispatch(setToken(response.payload.accessToken));
            });
        }
      }
    }
    if (isError) {
      if (message) {
        setErrorMessage(message);
      }
      setIsLoginError(true);
      setTimeout(() => setIsLoginError(false), 4000);
    }

    // dispatch(setToken(response))

    dispatch(reset());
  }, [
    user,
    isLoginSuccess,
    isError,
    email,
    loginType,
    message,
    navigate,
    dispatch,
    handleShowOTPVerification,
  ]);

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    checkPasswordLength(newPassword);
  };

  // Fungsi untuk menangani pengiriman OTP melalui WhatsApp
  const handleSendWhatsAppOTP = () => {
    if (!isSubmitting) {
      setIsSubmitting(true);
      let formattedPhone = phone;

      // Hilangkan angka 0 di depan jika ada
      formattedPhone = formattedPhone.replace(/^0+/, "");

      // Jika phone tidak mengandung kode negara, tambahkan countryCode
      if (!formattedPhone.startsWith(countryCode)) {
        formattedPhone = countryCode + formattedPhone;
      }
      api
        .post("v1/auth/sendOtp", {
          username: formattedPhone,
          otpSession: otpSession,
          destination: "WHATSAPP",
        })
        .then((response) => {
          setTypeOTPVerification("WHATSAPP");
          setReceiverOTP(formattedPhone);
          handleShowOTPVerification();
          setShowOTPModal(false);
          setIsSubmitting(false);
          setOtpSession(response?.data?.otpSession);
          navigate("/");
          return response.data;
        });
    }
  };

  // Fungsi untuk menangani pengiriman OTP melalui SMS
  const handleSendSMSOTP = () => {
    setShowOTPModal(false);
    setTypeOTPVerification("SMS");
    setReceiverOTP(phone);
    handleShowOTPVerification();
    alert("Mengirim OTP melalui SMS...");
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const data = [
    {
      label: "Nomor Telepon",
      value: "phone",
    },
    {
      label: "Alamat Email",
      value: "email",
    },
  ];

  useEffect(() => {});

  const handleCloseModal = () => {
    setShowOTPModal(false);
  };

  const handleBack = () => {
    setShowOTPVerification(false);
    setShowForgotPassword(false);
  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter" && password.length >= 8) {
      auth(e);
    }
  };

  const handleUsernameKeyDown = (e) => {
    if (e.key === "Enter") {
      if (passwordRef.current) {
        passwordRef.current.focus();
      }
    }
  };

  const handlePhoneKeyDown = (e) => {
    if (e.key === 'Enter') {
      if (passwordRef.current) {
        passwordRef.current.focus();
      }
    }
  };

  return (
    <div className="flex flex-col md:flex-row h-screen">
      {/* Row 1 - Logo dan Footer */}
      <div className="w-full bg-white md:p-10 p-4 flex flex-col justify-between">
        <Logo />

        {!showOTPVerification && !showForgotPassword && (
          <div className="flex justify-center items-center md:min-h-[700px] min-h-[500px]">
            <Card className="w-[484px] max-w-[484px] md:min-w-sm shadow-none md:shadow-md md:border-t-2 md:border-gray-100 rounded-xl md:p-4xl p-xs">
              <Typography
                type="h2"
                className="text-xl font-bold text-neutral-900 mb-xs"
              >
                Selamat Datang 👋
              </Typography>
              <Typography
                type="small"
                className="text-sm font-normal text-neutral-500 mb-4xl"
              >
                Silakan masuk untuk mengakses akun Anda.
              </Typography>
              {isLoginError && (
                <div className="w-[420px] h-20 p-4 bg-red-50 rounded-lg shadow border border-red-300 justify-start items-start gap-3 inline-flex mb-lg">
                  <p className="self-stretch text-gray-900 text-base font-medium  leading-normal">
                    <Typography
                      type="h2"
                      variant="medium"
                      color="blue-gray"
                      className="flex justify-between font-normal leading-none opacity-70 w-full min-h-full bg-gray-950 mt-4"
                    >
                      {errorMessage}
                    </Typography>
                  </p>
                </div>
              )}

              {/* Tabs untuk Login */}
              <div>
                <Tabs
                  value={loginType}
                  className=" bg-gray-100 rounded-lg h-11"
                >
                  <TabsHeader
                    className=" cursor-pointer justify-center text-neutral-800 z-0"
                    indicatorProps={{
                      className: "bg-transparent",
                    }}
                  >
                    {data.map(({ label, value }) => (
                      <Tab
                        key={value}
                        value={value}
                        onClick={() => setLoginType(value)}
                        className={`text-sm font-semibold ${
                          loginType === value
                            ? " bg-white rounded-sm shadow text-slate-700 p-1 h-9"
                            : " text-neutral-500 p-1 h-9"
                        }`}
                      >
                        {label}
                      </Tab>
                    ))}
                  </TabsHeader>
                </Tabs>
              </div>

              {loginType === "phone" ? (
                <div className="mt-4xl">
                  <PhoneInput
                    phone={phone}
                    setPhone={setPhone}
                    setCountryCode={setCountryCode}
                    onKeyDown={handlePhoneKeyDown}
                  />
                </div>
              ) : (
                <EmailInput
                  email={email}
                  setEmail={setEmail}
                  onKeyDown={handleUsernameKeyDown}
                />
              )}
              <div className="mt-2xl">
                <div className="grid grid-cols-2 gap-4">
                  <div className="mb-md">
                    <label
                      htmlFor="password"
                      className="text-sm font-medium text-neutral-900"
                    >
                      Kata Sandi
                    </label>
                  </div>

                  {/* Lupa Kata Sandi */}
                  <div className="text-right mb-md flex justify-end items-end">
                    <Button
                      type="btnLinkBlue"
                      size="small"
                      onClick={handleShowForgotPassword}
                      label="Lupa Kata Sandi?"
                    />
                  </div>
                </div>
                <div className="relative">
                  <Input
                    type={showPassword ? "text" : "password"}
                    id="password"
                    className={`${
                      (!isPasswordLengthValid || isPasswordEmpty) &&
                      "border-red-500"
                    }`}
                    placeholder="Masukkan kata sandi"
                    value={password}
                    onChange={handlePasswordChange}
                    onBlur={handlePasswordBlur}
                    size="medium"
                    onKeyDown={handleKeyDown}
                    ref={passwordRef}
                    componentRight={
                      <button onClick={toggleShowPassword}>
                        {showPassword ? (
                          <FaEyeSlash className="h-5 w-5 text-neutral-400" />
                        ) : (
                          <FaEye className="h-5 w-5 text-neutral-400" />
                        )}
                      </button>
                    }
                  />
                </div>
                {password === "" && isPasswordEmpty && (
                  <p className="text-red-500 text-sm mt-1">
                    Kata sandi tidak boleh kosong.
                  </p>
                )}
                {!isPasswordLengthValid && password && (
                  <p className="text-red-500 text-sm mt-1">
                    Kata sandi harus memiliki minimal 8 karakter.
                  </p>
                )}
              </div>
              <div className="mb-2xl"></div>
              {/* Tombol Masuk */}
              <Button
                label="Masuk"
                size="medium"
                type={`${
                  isSubmitDisabled || isLoading
                    ? "btnPrimaryDisabled"
                    : "btnPrimary"
                }`}
                onClick={auth}
                disabled={isSubmitDisabled || isLoading}
                componentLeft={
                  isLoading && (
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                      className="animate-spin fill-neutral-400"
                    >
                      <path
                        d="M10.72,19.9a8,8,0,0,1-6.5-9.79A7.77,7.77,0,0,1,10.4,4.16a8,8,0,0,1,9.49,6.52A1.54,1.54,0,0,0,21.38,12h.13a1.37,1.37,0,0,0,1.38-1.54,11,11,0,1,0-12.7,12.39A1.54,1.54,0,0,0,12,21.34h0A1.47,1.47,0,0,0,10.72,19.9Z"
                        className="spinner_6kVp"
                      />
                    </svg>
                  )
                }
              />
            </Card>
          </div>
        )}

        {showOTPVerification && (
          <OTPVerificationForm
            type={typeOTPVerification}
            receiverOTP={receiverOTP}
            otpSession={otpSession}
            onBack={handleBack}
          />
        )}

        {showForgotPassword && (
          <FormForgotPassword
            email={emailForgotPassword}
            setEmail={setEmailForgotPassword}
            onBack={handleBack}
          />
        )}
        {/* Footer */}
        <h2 className="text-gray-600 h-auto text-sm md:text-left text-center mt-4">
          © {new Date().getFullYear()} PT Integrasi Teknologi Unggas. All right
          reserved
        </h2>
      </div>

      {/* Row 2 */}
      <div
        className="w-[476px] max-w-[476px] min-h-[850px] h-auto bg-cover bg-no-repeat bg-center hidden lg:flex shadow-none"
        style={{
          background: `url(${bg}), linear-gradient(152deg, #FCA631 3.24%, #F5BE01 39.52%, #FFDD69 88.67%)`,
          backgroundSize: "100% 100%",
        }}
      >
        <div className="ml-7xl mr-7xl mt-[200px]">
          <div className="w-[234px] h-7 px-3 py-0.5 bg-white rounded-[40px] justify-center items-center gap-2.5 inline-flex mb-lg">
            <div className="grow shrink basis-0 text-orange-500 text-base font-semibold leading-normal">
              #Your Daily Poultry Partner
            </div>
          </div>
          <div className="w-[348px]">
            <span className="text-primary-900 text-3xl text-justify font-bold font-inter leading-[38px]">
              Optimalkan peternakan Anda dengan teknologi{" "}
            </span>
            <span className="text-orange-700 text-3xl font-bold font-inter leading-[38px]">
              Smart Farming.
            </span>
            <div className="flex justify-start mt-3xl">
              <img
                src={ilustration}
                alt="BroilerX"
                className="w-[501.12px] h-full relative"
              />
            </div>
          </div>
        </div>
      </div>
      {/* Modal untuk memilih pengiriman OTP */}
      {showOTPModal && (
        <ModalVerificationOTP
          phone={phone}
          password={password}
          otpSession={otpSession}
          onClose={handleCloseModal}
          onSendWhatsAppOTP={handleSendWhatsAppOTP}
          onSendSMSOTP={handleSendSMSOTP}
        />
      )}

      {notifSuccess && (
        <AlertNotification
          icon={<FaCheckCircle />}
          color="green"
          message="Password berhasil dirubah"
        />
      )}
    </div>
  );
};

export default Login;
