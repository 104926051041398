import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import { IconButton } from "@material-tailwind/react";
import { format } from "date-fns";
import { FiEdit, FiTrash2 } from "react-icons/fi";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useDebounce } from "use-debounce";

import { useConfirm } from "../../../../../app/use-confirm";
import { ReactComponent as BurgerDotIcon } from "../../../../../assets/icons/burgers-dot.svg";
import TableCellItem from "../../../../../atoms/Table/TableCellItem";
import TableHeaderItem from "../../../../../atoms/Table/TableHeaderItem";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../../../../atoms/V2/Popover";
import BadgeStatus from "../../../../../molecules/periodeBudidaya/BadgeStatus";
import { formatIDR } from "../../../../../shared/utils";
import {
  useDeleteMasterPeriod,
  useDownloadExcelMasterPeriod,
  useGetMasterPeriod,
} from "../../../../../useCases/MasterPeriod";
import { useGetUserDropdownList } from "../../../../../useCases/User";

const MasterPeriode = createContext(undefined);

export const MasterPeriodeStore = ({ children }) => {
  const navigate = useNavigate();

  const { user } = useSelector((state) => state.auth);

  const isDataTotalItemSet = useRef(false);

  const [currentPage, setCurrentPage] = useState(0);
  const [search, setSearch] = useState("");
  const [value] = useDebounce(search, 500);
  const [sortBy, setSortBy] = useState("createdAt");
  const [descending, setDescending] = useState(true);
  const [itemPerPage, setItemPerPage] = useState(10);
  const [dataTotalItem, setDataTotalItem] = useState(0);
  const [isDownloadFilter, setIsDownloadFilter] = useState(false);
  const [isDialogDownloadOpen, setIsDialogDownloadOpen] = useState(false);

  const [selectedPPL, setSelectedPPL] = useState(null);
  const [initialCapacity, setInitialCapacity] = useState("");
  const [finalCapacity, setFinalCapacity] = useState("");
  const [docInDate, setDocInDate] = useState({
    from: null,
    to: null,
  });

  const [selectedDelete, setSelectedDelete] = useState({
    id: null,
    houseName: "",
  });
  const [filteredItems, setFilteredItems] = useState({
    initialCapacity: "",
    finalCapacity: "",
    ppl: "",
    docInInitial: "",
    docInFinal: "",
  });
  const [isFilter, setIsFilter] = useState(false);

  const [ConfirmDelete, confirm] = useConfirm(
    "Hapus master periode?",
    `Anda yakin ingin menghapus periode nama kandang <b>“${selectedDelete?.houseName}”</b> ?`,
  );

  const query = useGetMasterPeriod({
    itemPerPage,
    currentPage,
    sortBy,
    descending,
    search: value,
    initialCapacity: filteredItems.initialCapacity,
    finalCapacity: filteredItems.finalCapacity,
    ppl: filteredItems.ppl,
    docInFinal: filteredItems.docInFinal,
    docInInitial: filteredItems.docInInitial,
  });
  const mutationDownloadExcel = useDownloadExcelMasterPeriod();
  const data = query.data?.contents;
  const queryPPL = useGetUserDropdownList({
    role: "PPL",
    unit: user?.unit ? user.unit : "",
  });
  const mutateDelete = useDeleteMasterPeriod(selectedDelete?.id);

  useEffect(() => {
    if (query.data && !isDataTotalItemSet.current) {
      setDataTotalItem(query.data?.totalItems);
      isDataTotalItemSet.current = true;
    } else if (query.data && isDataTotalItemSet.current && !isFilter) {
      setDataTotalItem(query.data?.totalItems);
    }
  }, [query.data, isFilter]);

  const handleSort = (column) => {
    if (sortBy === column) {
      setDescending(!descending);
    } else {
      setSortBy(column);
      setDescending(false);
    }
    setCurrentPage(0);
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
    setCurrentPage(0);
  };

  const handleResetFilter = () => {
    setInitialCapacity("");
    setFinalCapacity("");
    setSelectedPPL(null);
    setDocInDate({
      from: null,
      to: null,
    });
    setFilteredItems({
      initialCapacity: "",
      finalCapacity: "",
      ppl: null,
      docInInitial: "",
      docInFinal: "",
    });
    setCurrentPage(0);
    setIsFilter(false);
  };

  const handleApplyFilter = () => {
    setFilteredItems({
      initialCapacity: initialCapacity,
      finalCapacity: finalCapacity,
      ppl: selectedPPL,
      docInInitial: docInDate.from
        ? format(docInDate.from, "yyyy-MM-dd")
        : null,
      docInFinal: docInDate.to ? format(docInDate.to, "yyyy-MM-dd") : null,
    });
    setCurrentPage(0);
    setIsFilter(true);
  };

  const handleDelete = async () => {
    const ok = await confirm();

    if (ok) {
      mutateDelete.mutate(undefined, {
        onSuccess: () => {
          selectedDelete({
            id: null,
            houseName: "",
          });
          setCurrentPage(0);
        },
      });
    }
  };

  const handleDownloadExcel = async () => {
    mutationDownloadExcel.mutate(
      {
        search: isDownloadFilter ? value : "",
        initialCapacity: isDownloadFilter ? filteredItems.initialCapacity : "",
        finalCapacity: isDownloadFilter ? filteredItems.finalCapacity : "",
        ppl: isDownloadFilter ? filteredItems.ppl : "",
        docInInitial: isDownloadFilter ? filteredItems.docInInitial : "",
        docInFinal: isDownloadFilter ? filteredItems.docInFinal : "",
        unit: user?.unit,
      },
      {
        onSuccess: () => {
          setIsDialogDownloadOpen(false);
        },
      },
    );
  };

  const isDisableFeature =
    query.isLoading || query.data?.contents?.length === 0;

  const columns = [
    {
      accessorKey: "left_action",
      header: ({ column }) => {
        return (
          <TableHeaderItem
            title="No"
            disableSorting
            customClass="text-center flex-shrink-0"
          />
        );
      },
      cell: ({ row }) => {
        const number = row.index + 1 + currentPage * itemPerPage;

        return (
          <TableCellItem
            value={number}
            isNumber={false}
            customClass="text-center flex-shrink-0"
          />
        );
      },
      size: 48,
    },
    {
      accessorKey: "house_name",
      header: ({ column }) => {
        const value = column.id;
        return (
          <TableHeaderItem
            title="Nama kandang"
            value={value}
            handleSort={handleSort}
            isDescending={descending}
            customClass="w-[211px]"
          />
        );
      },
      cell: ({ row }) => {
        const label = row.original?.house?.labels;
        const subLabel = row.original?.house?.code || "-";
        return (
          <TableCellItem
            variant="withBottomText"
            value={label}
            valueBottomSubText={subLabel}
            customClass="w-[211px]"
          />
        );
      },
    },
    {
      accessorKey: "sequence",
      header: ({ column }) => {
        const value = column.id;
        return (
          <TableHeaderItem
            title="Periode"
            value={value}
            handleSort={handleSort}
            isDescending={descending}
          />
        );
      },
      cell: ({ row }) => {
        const value = row.original?.sequence || 0;
        return <TableCellItem value={value} />;
      },
    },
    {
      accessorKey: "analyticAccount",
      header: ({ column }) => {
        const value = column.id;
        return (
          <TableHeaderItem
            title="ID Periode"
            value={value}
            handleSort={handleSort}
            isDescending={descending}
          />
        );
      },
      cell: ({ row }) => {
        const value = row.original?.analyticAccount || "-";
        return <TableCellItem isNumber={false} value={value} />;
      },
    },
    {
      accessorKey: "house.ppl.fullname",
      header: ({ column }) => {
        const value = column.id;
        return (
          <TableHeaderItem
            title="PPL"
            value={value}
            handleSort={handleSort}
            isDescending={descending}
          />
        );
      },
      cell: ({ row }) => {
        const value = row.original?.house?.ppl?.fullName || "-";
        return (
          <TableCellItem
            value={value}
            isNumber={false}
            customClass="text-[#484B4F] max-w-[121px]"
          />
        );
      },
    },
    {
      accessorKey: "docInPopulation",
      header: ({ column }) => {
        const value = column.id;
        return (
          <TableHeaderItem
            title="Populasi"
            value={value}
            handleSort={handleSort}
            isDescending={descending}
          />
        );
      },
      cell: ({ row }) => {
        const value = row.original?.docInPopulation;
        return (
          <TableCellItem value={value} customClass="text-text-secondary" />
        );
      },
    },
    {
      accessorKey: "docInDate",
      header: ({ column }) => {
        const value = column.id;
        return (
          <TableHeaderItem
            title="Rencana DOC-in"
            value={value}
            handleSort={handleSort}
            isDescending={descending}
          />
        );
      },
      cell: ({ row }) => {
        const value = row.original?.docInDate || new Date();
        const formatNumber = format(new Date(value), "yyyy/MM/dd");

        return (
          <TableCellItem
            value={formatNumber}
            isNumber={false}
            customClass="text-text-secondary"
          />
        );
      },
    },
    {
      accessorKey: "periodEnd",
      header: ({ column }) => {
        const value = column.id;
        return (
          <TableHeaderItem
            title="Tanggal closing"
            value={value}
            handleSort={handleSort}
            isDescending={descending}
          />
        );
      },
      cell: ({ row }) => {
        const value = row.original?.periodEnd || "-";
        const formatNumber = format(
          new Date(row.original?.periodEnd || new Date()),
          "yyyy/MM/dd",
        );

        return (
          <TableCellItem
            value={row.original?.periodEnd ? formatNumber : value}
            isNumber={false}
            customClass="text-text-secondary"
          />
        );
      },
    },
    {
      accessorKey: "Diff FCR",
      header: ({ column }) => {
        return (
          <TableHeaderItem
            title="Diff FCR"
            disableSorting
            customClass="justify-center font-bold py-1 "
          />
        );
      },
      columns: [
        {
          accessorKey: "diffFcrHistoryOne",
          header: () => {
            return <TableHeaderItem title="Diff FCR-1" disableSorting />;
          },
          cell: ({ row }) => {
            const value = row.original?.house?.diffFcrHistoryOne || 0;
            return (
              <TableCellItem
                value={value}
                customClass="text-text-secondary w-[74px]"
              />
            );
          },
        },
        {
          accessorKey: "diffFcrHistoryTwo",
          header: () => {
            return <TableHeaderItem title="Diff FCR-2" disableSorting />;
          },
          cell: ({ row }) => {
            const value = row.original?.house?.diffFcrHistoryTwo || 0;
            return (
              <TableCellItem
                value={value}
                customClass="text-text-secondary w-[74px]"
              />
            );
          },
        },
        {
          accessorKey: "diffFcrHistoryThree",
          header: () => {
            return <TableHeaderItem title="Diff FCR-3" disableSorting />;
          },
          cell: ({ row }) => {
            const value = row.original?.house?.diffFcrHistoryThree || 0;
            return (
              <TableCellItem
                value={value}
                customClass="text-text-secondary w-[74px]"
              />
            );
          },
        },
      ],
    },
    {
      accessorKey: "ip",
      header: () => {
        return (
          <TableHeaderItem
            title="IP"
            disableSorting
            customClass="justify-center font-bold py-1 "
          />
        );
      },
      columns: [
        {
          accessorKey: "ipHistoryOne",
          header: () => {
            return <TableHeaderItem title="IP-1" disableSorting />;
          },
          cell: ({ row }) => {
            const value = row.original?.house?.ipHistoryOne || 0;
            return (
              <TableCellItem
                value={value}
                customClass="text-text-secondary w-14"
              />
            );
          },
        },
        {
          accessorKey: "ipHistoryTwo",
          header: () => {
            return (
              <TableHeaderItem title="IP-2" customClass="w-14" disableSorting />
            );
          },
          cell: ({ row }) => {
            const value = row.original?.house?.ipHistoryTwo || 0;
            return (
              <TableCellItem value={value} customClass="text-text-secondary" />
            );
          },
        },
        {
          accessorKey: "ipHistoryThree",
          header: () => {
            return (
              <TableHeaderItem title="IP-3" customClass="w-14" disableSorting />
            );
          },
          cell: ({ row }) => {
            const value = row.original?.house?.ipHistoryThree || 0;
            return (
              <TableCellItem value={value} customClass="text-text-secondary" />
            );
          },
        },
      ],
    },
    {
      accessorKey: "house.guaranteeType",
      header: ({ column }) => {
        const value = column.id;

        return (
          <TableHeaderItem
            title="Jenis Jaminan"
            value={value}
            handleSort={handleSort}
            isDescending={descending}
          />
        );
      },
      cell: ({ row }) => {
        const value = row.original?.house?.guaranteeType?.labels || "-";
        return (
          <TableCellItem
            value={value}
            isNumber={false}
            customClass="text-text-secondary"
          />
        );
      },
    },
    {
      accessorKey: "house.guaranteeAmount",
      header: ({ column }) => {
        const value = column.id;
        return (
          <TableHeaderItem
            title="Nilai Jaminan"
            value={value}
            handleSort={handleSort}
            isDescending={descending}
          />
        );
      },
      cell: ({ row }) => {
        const value = row.original?.house?.guaranteeAmount || 0;

        return (
          <TableCellItem
            value={value === 0 ? "-" : formatIDR(value)}
            customClass="text-text-secondary"
          />
        );
      },
    },
    {
      accessorKey: "guaranteePerChick",
      header: ({ column }) => {
        const value = column.id;
        return (
          <TableHeaderItem
            title="Jaminan per-ekor"
            value={value}
            disableSorting
          />
        );
      },
      cell: ({ row }) => {
        const value = row.original?.guaranteePerChick || 0;
        return (
          <TableCellItem
            value={value}
            variant="disable-currency"
            customClass="text-text-secondary absolute inset-0 bg-[#F1F1F1]"
          />
        );
      },
    },
    {
      accessorKey: "status",
      header: ({ column }) => {
        const value = column.id;
        return (
          <TableHeaderItem
            title="Status"
            value={value}
            customClass="text-center w-full justify-center"
            disableSorting
          />
        );
      },
      cell: ({ row }) => {
        const value = row.original.status || "-";
        return (
          <div className="flex w-full justify-end">
            <BadgeStatus
              variant={value}
              dots={value}
              text={value}
              disableDots={value === "UNKNOWN"}
            />
          </div>
        );
      },
    },
    {
      accessorKey: "action",
      header: "",
      cell: ({ row }) => {
        const value = row.original.id;
        const house = row.original.house.labels;
        return (
          <>
            <Popover>
              <PopoverTrigger asChild>
                <IconButton variant="text">
                  <BurgerDotIcon />
                </IconButton>
              </PopoverTrigger>
              <PopoverContent
                align="start"
                side="right"
                className="max-w-44 p-0"
              >
                <div className="flex flex-col">
                  <button
                    onClick={() => {
                      navigate(
                        `/periode-budidaya/master-periode/edit-periode/${value}`,
                      );
                    }}
                    className="flex items-center gap-md px-xl py-[10px] transition-colors hover:bg-gray-50"
                  >
                    <FiEdit className="size-4" />
                    <span className="text-sm">Edit</span>
                  </button>
                  <button
                    className="flex items-center gap-md px-xl py-[10px] text-fg-errorPrimary transition-colors hover:bg-gray-50"
                    onClick={() => {
                      setSelectedDelete({
                        id: value,
                        houseName: house,
                      });
                      handleDelete();
                    }}
                  >
                    <FiTrash2 className="size-4" />
                    <span className="text-sm">Hapus</span>
                  </button>
                </div>
              </PopoverContent>
            </Popover>
          </>
        );
      },
    },
  ];

  const pinningConfigs = [
    { column: "left_action", direction: "left" },
    { column: "house_name", direction: "left" },
    { column: "action", direction: "right" },
  ];

  return (
    <MasterPeriode.Provider
      value={{
        query,
        data,
        columns,
        ConfirmDelete,
        pinningConfigs,
        currentPage,
        setCurrentPage,
        search,
        setSearch,
        value,
        sortBy,
        setSortBy,
        descending,
        setDescending,
        selectedPPL,
        setSelectedPPL,
        initialCapacity,
        setInitialCapacity,
        finalCapacity,
        setFinalCapacity,
        docInDate,
        setDocInDate,
        handleResetFilter,
        handleApplyFilter,
        selectedDelete,
        setSelectedDelete,
        handleDelete,
        queryPPL,
        handleSort,
        navigate,
        itemPerPage,
        setItemPerPage,
        handleSearch,
        filteredItems,
        handleDownloadExcel,
        mutationDownloadExcel,
        dataTotalItem,
        isDownloadFilter,
        setIsDownloadFilter,
        isDisableFeature,
        isDialogDownloadOpen,
        setIsDialogDownloadOpen,
        isFilter,
      }}
    >
      {children}
    </MasterPeriode.Provider>
  );
};

export const useMasterPeriode = () => {
  const context = useContext(MasterPeriode);

  if (context === undefined) {
    throw new Error(
      "useMasterPeriode must be used within a MasterPeriodeStore",
    );
  }

  return context;
};
