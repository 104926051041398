/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import {
  ChevronUpDownIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Typography,
  Chip,
  Alert,
  IconButton,
} from "@material-tailwind/react";
import Input from "../../atoms/Input";
import moment from "moment";
import Pagination from "../../atoms/Pagination";
import CustomDatePicker from "../../atoms/DatepickerForm";
import { ReactComponent as EmptyIcon } from "../../assets/icons/emptyIconn.svg";
import { ReactComponent as DocumentFindIcon } from "../../assets/icons/document-find-icon.svg";
import { FaCheckCircle } from "react-icons/fa";
import { FaRegCircleCheck } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import api from "../../api/configApi";
import DropdownWithSearchInput from "../../atoms/DropdownWithSearchInput";
import LoadingAnimation from "../../atoms/LoadingAnimation";
import { Button } from "../../atoms/Button";
import DropdownFilterStatus from "../../atoms/DropdownFilterStatusMultiple";
import * as Sentry from "@sentry/react";
import Cookies from "js-cookie";
import { CheckboxCust } from "../../atoms/Checkbox";
import { CgSoftwareDownload } from "react-icons/cg";
import ConfirmDialog from "../../atoms/ConfirmDialog";
import AlertNotification from "../../atoms/AlertNotification";
import { useSelector } from "react-redux";
import { ButtonIcon } from "../../atoms/ButtonIcon";

const TablePanen = ({
  conditionalStatus,
  optionsUnit,
  optionStatus,
  searchUnit,
  setSearchUnit,
  lastLabelUnits,
  setLastLabelUnits,
  checkedUnits,
  setCheckedUnits,
  setCheckedUnitsString,
}) => {
  let currentPagePanen = parseInt(Cookies.get("current-page-panen"));
  const [dataTable, setDataTable] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalPage, setTotalPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(
    currentPagePanen ? currentPagePanen : 1
  );
  const [date, setDate] = useState(null);
  const [search, setSearch] = useState("");
  const [descending, setDescending] = useState(true);
  const [sortBy, setSortBy] = useState("createdAt");
  const [selectUnit, setSelectUnit] = useState("");
  const [isFetchError, setIsFetchError] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [labelStatus, setLabelStatus] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState();
  const [modalConfirmation, setModalconfirmation] = useState(false);
  const [isDownloadSuccess, setIsDownloadSuccess] = useState(false);
  const [listMasterUnits, setListMasterUnits] = useState([]);
  const [searchMasterUnit, setSearchMasterUnit] = useState("");

  const { user } = useSelector((state) => state.auth);

  Cookies.set("current-page-panen", currentPage);
  const [selectAll, setSelectAll] = useState(false);

  const handleCheckAllChange = () => {
    setSelectAll(!selectAll);
    setSelectedRows(selectAll ? [] : dataTable.map((row) => row.id));
  };

  const TABLE_HEAD = [
    <CheckboxCust
      className="checked:bg-primary-500"
      onChange={handleCheckAllChange}
      checked={selectAll}
    />,
    "Tanggal Panen",
    "Nama Kandang",
    "Unit",
    "ID Periode",
    "Nama Bakul",
    "Data Timbang",
    "Status",
    "",
  ];

  const navigate = useNavigate();
  const shortableColumn = [
    "",
    "harvestDate",
    "rearingPeriod.house.name",
    "rearingPeriod.house.unit.name",
    "rearingPeriod.analyticAccount",
    "bakul.name",
    "totalHarvestData",
    "totalPrice",
    "",
    "",
  ];

  const handleCheckboxChange = (id) => {
    if (selectedRows?.includes(id)) {
      setSelectedRows(selectedRows.filter((rowID) => rowID !== id));
    } else {
      setSelectedRows([...selectedRows, id]);
    }

    if (selectAll) {
      setSelectAll(false);
    }
  };

  const handleSearch = (e) => {
    const targetValue = e.target.value;
    setSearch(targetValue);
    handleChangePage(1);
  };

  const handleFilterUnit = (val) => {
    handleChangePage(1);
    setSelectUnit(val);
  };

  const handleFilterStatus = (val) => {
    handleChangePage(1);
    setSelectedStatus(val);
    if (val === "WAITING_FOR_REVIEW") {
      setLabelStatus("Menunggu review");
    } else if (val === "ON_REVIEW") {
      setLabelStatus("Sedang di review");
    } else if (val === "DONE_REVIEW") {
      setLabelStatus("Selesai di review");
    } else if (val === null) {
      setLabelStatus("Semua Status");
    }
  };

  const handleResetFilterStatus = () => {
    setSelectedStatus("");
    setLabelStatus("");
  };

  const handleShort = (column) => {
    if (shortableColumn?.includes(column)) {
      if (sortBy === column) {
        setDescending(!descending);
      } else {
        setSortBy(column);
        setDescending(false);
      }
    }
  };

  const handleFilterDate = (date) => {
    handleChangePage(1);
    setDate(date);
    localStorage.setItem("selectedDate", moment(date?.$d).format("YYYY-MM-DD"));
  };

  useEffect(() => {
    setLoading(true);
    const fetchHarvest = async () => {
      try {
        let stringUnit =
          checkedUnits.length > 0 ? checkedUnits.join(",") : null;

        const params = {
          itemPerPage: 10,
          search: search,
          currentPage: currentPage - 1,
          descending: descending,
          sortBy: sortBy,
        };

        if (stringUnit !== null) {
          params.unit = stringUnit;
        }

        if (selectedStatus !== null || selectedStatus !== "") {
          params.status = selectedStatus;
        }

        if (date !== null) {
          params.harvestDate = moment(date?.$d).format("YYYY-MM-DD");
        }

        const dataHArvest = await api.get("/v2/harvests", { params });
        setDataTable(dataHArvest?.data?.contents);
        setTotalPage(dataHArvest?.data?.totalPages);
        setIsFetchError(false);
      } catch (error) {
        Sentry.captureException(error);
        setIsFetchError(true);
      } finally {
        setLoading(false);
      }
    };

    fetchHarvest();
  }, [currentPage, date, descending, search, selectedStatus, checkedUnits]);

  const handleChangePage = (e) => {
    setCurrentPage(e);
  };

  const handleClickChangePageDetail = (id) => {
    navigate(`/panen/${id}`);
  };

  const handleDownload = async () => {
    try {
      let stringId = selectedRows.length > 0 ? selectedRows.join(",") : null;

      let ids = stringId === null ? "" : "?id=" + stringId;

      const response = await api.get(`/v2/harvests/toXLSX` + ids, {
        responseType: "blob",
      });

      const contentDisposition = response.headers["content-disposition"];
      let fileName = "panen.xlsx";

      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename="?([^"]+)"?/);
        if (fileNameMatch && fileNameMatch[1]) {
          fileName = fileNameMatch[1];
        }
      }

      const url = window.URL.createObjectURL(new Blob([response.data]));

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);

      document.body.appendChild(link);

      link.click();

      window.URL.revokeObjectURL(url);
      setSelectedRows([]);
      setModalconfirmation(false);
      setIsDownloadSuccess(true);
      setTimeout(() => setIsDownloadSuccess(false), 3000);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const fetchMasterUnit = async () => {
      try {
        const params = {
          search: searchMasterUnit,
          descending: false
        };
        const responseList = await api.get("/v1/units/dropdown", { params });
        setListMasterUnits(responseList?.data?.contents);
      } catch (error) {
        console.log(error.message);
      }
    };
  }, [])

  const handleResetSelectedRows = () => {
    setSelectedRows([]);
    setSelectAll(false);
  };

  const handleOpenModalConfirmation = () => {
    setModalconfirmation(true);
  };

  const handleOpenModalConfirmationAllData = () => {
    setModalconfirmation(true);
    handleResetSelectedRows();
  };

  const handleFilterMasterUnit = (val) => {};

  return (
    <div className="flex w-full h-auto overflow-hidden">
      <div className="flex flex-col flex-1 overflow-hidden">
        <div className="">
          <Card className="h-full w-full shadow-none">
            <CardHeader
              floated={false}
              shadow={false}
              className="p-md border-none"
            >
              <div className="flex justify-start w-full">
                <div className="text-left flex justify-between gap-4 mb-1 w-full">
                  <div className="flex justify-start items-start gap-4">
                    <Input
                      size="medium"
                      type="text"
                      id="search"
                      value={search}
                      onChange={handleSearch}
                      placeholder="Cari kandang atau bakul"
                      className="w-[268px] border-border-primary"
                      componentLeft={
                        <MagnifyingGlassIcon className="h-5 w-5" />
                      }
                    />

                    <div className="mt-[-12px]">
                      <CustomDatePicker
                        placeholder="Pilih tanggal"
                        value={date}
                        onChange={handleFilterDate}
                        format={"DD MMMM YYYY"}
                        defaultValue={() => date}
                        className={
                          "flex placeholder:items-center placeholder:text-lg h-[43px] border-border-primary"
                        }
                        size="large"
                      />
                    </div>

                    <div>
                      <DropdownWithSearchInput
                        placeholder="Semua unit"
                        placeholderInputSearch="Cari unit"
                        options={optionsUnit}
                        onSelect={handleFilterUnit}
                        search={searchUnit}
                        setSearch={setSearchUnit}
                        onSelectString={setCheckedUnitsString}
                        lastLabel={lastLabelUnits}
                        setLastLabel={setLastLabelUnits}
                        positionClassName="top-[350px] left-[1190px] border-border-primary"
                        selectCheckBox={checkedUnits}
                        setSelectCheckbox={setCheckedUnits}
                        className={"min-w-[180px]"}
                      />
                    </div>

              
                    {/* {user?.role === "Super Admin" && user?.level === "REGION" && (
                      <div>
                        <DropdownWithSearchInput
                          placeholder="Semua unit"
                          placeholderInputSearch="Cari unit"
                          options={optionsUnit}
                          onSelect={handleFilterUnit}
                          search={searchUnit}
                          setSearch={setSearchUnit}
                          onSelectString={setCheckedUnitsString}
                          lastLabel={lastLabelUnits}
                          setLastLabel={setLastLabelUnits}
                          positionClassName="top-[350px] left-[1190px] border-border-primary"
                          selectCheckBox={checkedUnits}
                          setSelectCheckbox={setCheckedUnits}
                          className={"min-w-[180px]"}
                        />
                      </div>
                    )} */}

                    <div>
                      <DropdownFilterStatus
                        data={optionStatus}
                        handleSelect={handleFilterStatus}
                        popoverContentPosition={"top-[240px] left-[760px]"}
                        selectedStatus={selectedStatus}
                        label={labelStatus}
                        onReset={handleResetFilterStatus}
                      />
                    </div>
                  </div>

                  <Button
                    type="btnLinkBlue"
                    size={"small"}
                    label="Unduh data"
                    onClick={handleOpenModalConfirmationAllData}
                    className="cursor-pointer h-[40px]"
                    componentLeft={<CgSoftwareDownload className="h-5 w-5" />}
                  />
                </div>
              </div>
            </CardHeader>

            <CardBody className="p-0 relative overflow-scroll max-w-full max-h-[960px] custom-scrollbar">
              {loading ? (
                <div className="flex justify-center items-center h-screen">
                  <LoadingAnimation />
                </div>
              ) : (
                <>
                  {dataTable?.length === 0 || isFetchError ? (
                    <div className=" flex justify-center items-center h-screen w-full">
                      <div>
                        <div className="flex justify-center">
                          <EmptyIcon className="ml-[30px]" />
                        </div>

                        <Typography
                          variant="h2"
                          className="text-xl font-semibold p-4 text-center text-text-secondary"
                        >
                          Data tidak ditemukan
                        </Typography>

                        <Typography
                          variant="medium"
                          className="flex justify-center font-normal leading-none w-full text-text-secondary min-h-full bg-gray-950"
                        >
                          Data yang Anda cari tidak ditemukan. Coba kata kunci
                          lain
                        </Typography>
                      </div>
                    </div>
                  ) : (
                    <table className="table-auto w-full min-w-max text-left mt-4 border-separate border-spacing-0">
                      <thead>
                        <tr>
                          {TABLE_HEAD.map((head, index) => (
                            <th
                              key={head}
                              className={`border-y border-border-secondary bg-neutral-50 py-2 px-6  ${
                                index === 0
                                  ? "sticky left-0 z-10 pl-4"
                                  : index === 1
                                  ? "sticky left-[85px] z-10"
                                  : index === 2
                                  ? "border-r sticky left-[253px] shadow-[6px_0px_12px_-5px_rgba(0,0,10,0.2)] z-10"
                                  : index === 8
                                  ? "sticky right-0 ml-4 z-10 p-0 shadow-[rgba(0,0,10,0.2)_-3px_0_12px_-5px] border-l"
                                  : ""
                              }`}
                            >
                              <Typography
                                className={`flex text-sm text-text-tertiary justify-between font-bold leading-tight`}
                              >
                                {head}
                                {""}
                                {index !== 0 &&
                                  index !== 7 &&
                                  index !== 8 &&
                                  index !== TABLE_HEAD.length - 1 && (
                                    <button
                                      onClick={() =>
                                        handleShort(shortableColumn[index])
                                      }
                                      className="focus:outline-none ml-1"
                                    >
                                      <ChevronUpDownIcon
                                        strokeWidth={2}
                                        className={`h-4 w-4 ${
                                          descending
                                            ? "transform rotate-180"
                                            : ""
                                        }`}
                                      />
                                    </button>
                                  )}
                              </Typography>
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {dataTable?.map((harvest, index) => {
                          const isLast = index === harvest.length - 1;
                          const classes = isLast
                            ? "p-4"
                            : "p-4 border-b border-blue-gray-50";

                          return (
                            <tr
                              key={harvest.id}
                              className={
                                (index + 1) % 2 === 0 && `bg-neutral-50`
                              }
                            >
                              <td
                                className={`border-b border-border-secondary sticky left-0 p-4 ${
                                  (index + 1) % 2 === 0
                                    ? "bg-neutral-50"
                                    : "bg-white"
                                }`}
                              >
                                <CheckboxCust
                                  className="checked:bg-primary-500"
                                  onChange={() =>
                                    handleCheckboxChange(harvest.id)
                                  }
                                  checked={selectedRows?.includes(harvest.id)}
                                />
                              </td>

                              <td
                                className={`border-b border-border-secondary sticky left-[85px] p-4 ${
                                  (index + 1) % 2 === 0
                                    ? "bg-neutral-50"
                                    : "bg-white"
                                }`}
                              >
                                <Typography className="text-neutral-900 text-sm font-medium leading-tight">
                                  {moment(harvest?.harvestDate).format(
                                    "DD MMMM YYYY"
                                  )}
                                </Typography>
                              </td>

                              <td
                                className={`border-r border-b border-border-secondary sticky left-[253px] p-4 shadow-[6px_0px_12px_-5px_rgba(0,0,10,0.2)] ${
                                  (index + 1) % 2 === 0
                                    ? "bg-neutral-50"
                                    : "bg-white"
                                }`}
                              >
                                <Typography
                                  className={`${
                                    conditionalStatus
                                      ? "text-[#2E90FA] "
                                      : "text-neutral-900"
                                  }  font-bold text-sm leading-tight`}
                                >
                                  {harvest?.rearingPeriod.house?.labels}
                                </Typography>
                              </td>
                              <td className={classes}>
                                <Typography className="text-neutral-900 text-sm font-medium leading-tight">
                                  {harvest?.rearingPeriod?.house?.unit?.labels}
                                </Typography>
                              </td>

                              <td className={classes}>
                                <Typography className="text-neutral-900 text-sm font-medium leading-tight">
                                  {harvest?.rearingPeriod?.analyticAccount}
                                </Typography>
                              </td>

                              <td className={classes}>
                                <Typography className="text-neutral-900 text-sm font-medium leading-tight">
                                  {harvest.bakul?.labels}
                                </Typography>
                              </td>

                              <td className={classes}>
                                <Typography className="text-neutral-900 text-sm font-medium leading-tight">
                                  {harvest.totalHarvestData}
                                </Typography>
                              </td>
                              <td className={classes}>
                                <div className="w-max">
                                  <Chip
                                    size="sm"
                                    variant="ghost"
                                    value={
                                      harvest?.status?.id ===
                                      "WAITING_FOR_REVIEW"
                                        ? "Menunggu direview"
                                        : harvest?.status?.id === "ON_REVIEW"
                                        ? "Sedang direview"
                                        : "Selesai direview"
                                    }
                                    className={`text-sm font-inter font-normal leading-[18px] border normal-case ${
                                      harvest?.status?.id ===
                                      "WAITING_FOR_REVIEW"
                                        ? "text-[#C49801] border-[#FBE497] bg-[#FEF8E2]"
                                        : harvest?.status?.id === "ON_REVIEW"
                                        ? "text-[#1570EF] border-[#B2DDFF] bg-[#F5FAFF]"
                                        : "text-[#079455] border-[#ABEFC6] bg-[#F6FEF9]"
                                    }`}
                                    icon={
                                      harvest?.status?.id ===
                                      "WAITING_FOR_REVIEW" ? (
                                        <span className="mx-auto mt-1 block h-2 w-2 rounded-full bg-[#F7CB33] content-['']" />
                                      ) : harvest?.status?.id ===
                                        "ON_REVIEW" ? (
                                        <span className="mx-auto mt-1 block h-2 w-2 rounded-full bg-[#53B1FD]  content-['']" />
                                      ) : (
                                        <span className="mx-auto mt-1 block h-2 w-2 rounded-full bg-[#17B26A] content-['']" />
                                      )
                                    }
                                  />
                                </div>
                              </td>
                              <td
                                className={`border-l border-b border-border-secondary ${
                                  (index + 1) % 2 === 0
                                    ? "bg-neutral-50"
                                    : "bg-white"
                                } sticky right-0 p-4 shadow-[rgba(0,0,10,0.2)_-3px_1px_12px_-5px]`}
                              >
                                <ButtonIcon
                                  className="z-25"
                                  onClick={() =>
                                    handleClickChangePageDetail(harvest?.id)
                                  }
                                  icon="detail"
                                  type="outlineInfo"
                                  size="small"
                                />
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  )}
                </>
              )}
            </CardBody>

            <CardFooter className="items-center border-t border-blue-gray-50 p-sm sticky right-0">
              <Pagination
                currentPage={currentPage}
                totalPages={totalPage}
                onPageChange={handleChangePage}
              />
            </CardFooter>
          </Card>
        </div>
      </div>
      <ConfirmDialog
        isOpen={modalConfirmation}
        onClose={() => setModalconfirmation(!modalConfirmation)}
        content={
          selectedRows.length > 0
            ? `Kamu akan mengunduh ${selectedRows.length} data pada baris yang sudah di centang, apakah kamu yakin akan melanjutkan?`
            : "Kamu akan mengunduh semua data pada semua halaman, apakah kamu yakin akan melanjutkan?"
        }
        title={
          selectedRows.length > 0
            ? `Unduh ${selectedRows.length} data terpilih`
            : "Unduh semua data"
        }
        btnLabel="Lanjutkan"
        onConfirm={handleDownload}
        classNameContent={"text-md"}
      />
      {isDownloadSuccess && (
        <AlertNotification
          icon={<FaCheckCircle />}
          color="green"
          message="Berhasil unduh data"
        />
      )}

      {selectedRows.length > 0 && (
        <div className="fixed top-[210px] animate-slide-up animate-fade-in items-center z-[120]">
          <Alert
            icon={<FaRegCircleCheck />}
            className="items-center bg-white shadow-lg text-text-secondary flex justify-between m-0 border border-border-secondary"
            action={
              <div>
                <Button
                  type="btnLinkBlue"
                  size={"small"}
                  label="Unduh"
                  onClick={handleOpenModalConfirmation}
                  className="cursor-pointer h-[40px]"
                />
                <IconButton
                  color="blue-gray"
                  size="sm"
                  variant="text"
                  onClick={handleResetSelectedRows}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                    className="h-5 w-5"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </IconButton>
              </div>
            }
          >
            {selectedRows.length} Data terpilih
          </Alert>
        </div>
      )}
    </div>
  );
};

export default TablePanen;
