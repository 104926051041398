import React, { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
} from "@material-tailwind/react";
import { FaCheckCircle } from "react-icons/fa";
import Input from "../../atoms/Input";
import { Button } from "../../atoms/Button";
import api from "../../api/configApi";

import AlertNotification from "../../atoms/AlertNotification";
import ConfirmDialog from "../../atoms/ConfirmDialog";
import PhoneInput from "../../molecules/login/PhoneInput";
import Dropdown from "../../atoms/DropdownWithReset";

import { useSelector } from "react-redux";

const EditPeternakModal = ({
  isOpen,
  onClose,
  onUpdate,
  userId,
  setSelectedEditUserId,
  onReloadData,
}) => {
  const [nama, setNama] = useState("");
  const [idPeternak, setIdPeternak] = useState("");
  const [isActive, setIsActive] = useState();
  const [unit, setUnit] = useState();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [isEditDialogOpen, setEditDialogOpen] = useState(false);
  const [isEditSuccess, setEditSuccess] = useState(false);

  const [phone, setPhone] = useState("");
  const [isPhoneValid, setIsPhoneValid] = useState(true);
  const [countryCode, setCountryCode] = useState("62");

  const [isEditError, setEditError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [units, setUnits] = useState([]);
  const [selectedUnit, setSelectedUnit] = useState(null);

  const [selectedLabel, setSelectedLabel] = useState("");
  const [phoneVerification, setPhoneVerfication] = useState(null);
  const [phoneBefore, setPhoneBefore] = useState("");

  const rolePerimissions =
    selectedLabel &&
    (selectedLabel === "Admin Unit" ||
      selectedLabel === "PPL" ||
      selectedLabel === "Admin Sales");

  const isSubmitDisabled =
    !nama ||
    !phone ||
    !idPeternak ||
    !isPhoneValid ||
    (phoneVerification === false && phoneBefore !== phone) ||
    (!selectedUnit && rolePerimissions);

  const { user } = useSelector((state) => state.auth);

  const handleOpenEditDialog = () => {
    setEditDialogOpen(true);
  };

  const handleCloseEditDialog = () => {
    setSelectedEditUserId(null);
    setEditDialogOpen(false);
  };

  const handleCloseModal = () => {
    setNama("");
    setPhone("");
    setUnit("");
    setSelectedUnit("");
    setSelectedLabel("");
    setIsPhoneValid(true);
    setPhoneVerfication(null);
    setPhoneBefore("");
    onClose();
  };

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await api.get(`/v1/farmers/${userId}`);
        setNama(response.data.fullName);
        setPhoneBefore(response.data.phoneNumber);
        setPhone(response.data.phoneNumber);
        setSelectedLabel(response.data.role?.labels);
        setIsActive(response.data.isActive);
        setUnit(response.data.unit?.id);
        setSelectedUnit(response.data.unit);
        setIdPeternak(response.data.code);
      } catch (error) {
        console.error("Error fetching farmer:", error);
      }
    };

    if (isOpen && userId) {
      fetchUser();
    }
  }, [isOpen, userId]);

  useEffect(() => {
    const fetchUnits = async () => {
      try {
        const response = await api.get("/v1/units/dropdown");
        setUnits(response.data.contents);
      } catch (error) {
        console.error("Error fetching roles:", error);
      }
    };

    fetchUnits();
  }, [isOpen]);

  const handleUpdate = async () => {
    let requestEditData = {
      fullName: nama,
      code: idPeternak,
      unit: { id: unit },
      isActive: isActive,
    };

    if (typeof phone === "string" && phone.trim() !== "") {
      let formattedPhone = phone;

      formattedPhone = formattedPhone.replace(/^0+/, "");

      if (formattedPhone !== "") {
        if (!formattedPhone.startsWith(countryCode)) {
          formattedPhone = countryCode + formattedPhone;
        }
        requestEditData.phoneNumber = formattedPhone;
      }
    }

    handleCloseEditDialog();
    try {
      setIsSubmitting(true);
      const response = await api.put(`/v1/farmers/${userId}`, requestEditData);
      console.log("Farmer data updated:", response.data);
      setIsSubmitting(false);
      if (onUpdate) {
        onUpdate(response.data);
      }

      if (onReloadData) {
        onReloadData();
      }

      setEditSuccess(true);
      setTimeout(() => setEditSuccess(false), 2000);

      setPhoneVerfication(null);
      handleCloseModal();
      setIdPeternak("");
    } catch (error) {
      // if (error.response.data.error?.fullName) {
      //   setNama("");
      //   setIdPeternak("");
      //   setPhoneVerfication(null);
      //   setPhoneBefore("");
      // } else if (error.response.data.error?.phoneNumber) {
      //   setPhone("");
      //   setIdPeternak("");
      //   setPhoneVerfication(null);
      //   setPhoneBefore("");
      // } else {
      //   setNama("");
      //   setIdPeternak("");
      //   setPhone("");
      //   setPhoneVerfication(null);
      //   setPhoneBefore("");
      // }

      setErrorMessage(
        error.response.data.error?.fullName ||
          error.response.data.error?.phoneNumber ||
          error.response.data.error
      );
      console.error("Error updating farmer data:", error);
      setEditError(true);
      setTimeout(() => setEditError(false), 4000);
      setIsSubmitting(false);
    }
  };

  const handleChangeUnit = (select) => {
    setUnit(select.id);
    setSelectedUnit(select.id);
  };

  const handleIdPeternakChange = (e) => {
    let newID = e.target.value;

    if (newID.length < 25) {
      setIdPeternak(newID);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }

    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [isOpen]);

  return (
    <div>
      {isOpen && <div className="fixed inset-0 bg-gray-800 opacity-50 z-[50]"></div>}
      <Card
        className={`fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-[60] ${
          isOpen ? "block" : "hidden"
        } rounded-xl flex-col justify-start items-start`}
        style={{ width: "800px" }}
      >
        <CardHeader
          floated={false}
          shadow={false}
          className="border-b border-border-secondary rounded-b-none m-0 p-0"
        >
          <button
            className="absolute top-6 right-4 text-gray-500"
            onClick={handleCloseModal}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
          {/* Modal Title */}
          <Typography
            type="h2"
            className="text-lg text-text-primary font-semibold p-4"
          >
            Ubah peternak
          </Typography>
        </CardHeader>
        <CardBody className="p-5">
          <div className="grid grid-cols-1">
            <div className="mb-md">
              <Typography className="text-text-primary text-sm font-semibold leading-tight">
                Nama Peternak:{" "}
                <span className="text-red-500 text-sm font-normal leading-tight">
                  *
                </span>
              </Typography>
            </div>
            <Input
              type="text"
              id="name"
              placeholder="Masukkan Nama Peternak"
              value={nama}
              size="medium"
              onChange={(e) => setNama(e.target.value)}
            />
          </div>
          <div className="grid grid-cols-1 my-5">
            <div className="mb-md">
              <Typography className="text-text-primary text-sm font-semibold leading-tight">
                ID Peternak:{" "}
                <span className="text-red-500 text-sm font-normal leading-tight">
                  *
                </span>
              </Typography>
            </div>
            <Input
              type="text"
              id="name"
              placeholder="Masukkan ID Peternak"
              value={idPeternak}
              size="medium"
              onChange={handleIdPeternakChange}
            />
          </div>
          <div className="grid grid-cols-1 gap-4 my-5">
            <div>
              <Typography className="text-text-primary text-sm font-semibold leading-tight mb-2">
                Nomor telepon:{" "}
                <span className="text-red-500 text-sm font-normal leading-tight">
                  *
                </span>
              </Typography>
              <PhoneInput
                phone={phone}
                setPhone={setPhone}
                setCountryCode={setCountryCode}
                phoneIsNull={true}
                isLabelsActive={false}
                phoneValid={setIsPhoneValid}
                verification={phoneVerification}
                setVerification={setPhoneVerfication}
                phoneBefore={phoneBefore}
                isEdit={true}
              />
            </div>
          </div>
          {user?.role === "Super Admin" && (
            <div className="grid grid-cols-1 my-5">
              <Typography className="text-text-primary text-sm font-semibold leading-tight mb-md">
                Unit:{" "}
                <span className="text-red-500 text-sm font-normal leading-tight">
                  *
                </span>
              </Typography>
              <Dropdown
                onSelect={handleChangeUnit}
                placeholder="Pilih unit"
                options={units}
                selectedOption={selectedUnit}
              />
            </div>
          )}
        </CardBody>
        <CardFooter className="flex justify-end border-t border-border-secondary p-lg">
          <Button
            label="Keluar"
            size="medium"
            type="btnSecondary"
            className="mr-md"
            onClick={handleCloseModal}
          />
          <Button
            label="Simpan"
            size="medium"
            type={`${
              isSubmitDisabled || isSubmitting
                ? "btnPrimaryDisabled"
                : "btnPrimary"
            }`}
            onClick={handleOpenEditDialog}
            disabled={isSubmitDisabled || isSubmitting}
            componentLeft={
              isSubmitting && (
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  className="animate-spin fill-neutral-400"
                >
                  <path
                    d="M10.72,19.9a8,8,0,0,1-6.5-9.79A7.77,7.77,0,0,1,10.4,4.16a8,8,0,0,1,9.49,6.52A1.54,1.54,0,0,0,21.38,12h.13a1.37,1.37,0,0,0,1.38-1.54,11,11,0,1,0-12.7,12.39A1.54,1.54,0,0,0,12,21.34h0A1.47,1.47,0,0,0,10.72,19.9Z"
                    className="spinner_6kVp"
                  />
                </svg>
              )
            }
          />
        </CardFooter>
      </Card>
      <ConfirmDialog
        isOpen={isEditDialogOpen}
        onClose={handleCloseEditDialog}
        onConfirm={handleUpdate}
        title="Ubah peternak"
        content="Anda yakin ingin melanjutkan tindakan ini?"
        btnLabel="Lanjutkan"
        btnType="btnPrimary"
      />
      {isEditSuccess && (
        <AlertNotification
          icon={<FaCheckCircle />}
          color="green"
          message="Data peternak berhasil di ubah."
        />
      )}
      {isEditError && (
        <AlertNotification
          icon={<FaCheckCircle />}
          color="red"
          message={errorMessage}
        />
      )}
    </div>
  );
};

export default EditPeternakModal;
