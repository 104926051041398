/* eslint-disable no-unused-vars */
import React, { useRef, useState, useEffect } from "react";
import { ReactComponent as ChevronDown } from "../assets/icons/chevron_down.svg";

const Dropdown = ({
  options,
  onSelect,
  placeholder,
  defaultSelected,
  selectedOption,
  onReset,
  onSatuan,
  setSelectedLabel,
  selectedValue,
  setSelectedLevel,
  isLoading,
  isError
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [labelSelected, setLabelSelected] = useState("");
  const [levelSelected, setLevelSelected] = useState("");

  const inputRef = useRef();


  // useEffect(() => {
  //   setLabelSelected(selectedOption ? selectedOption.labels : "");
  //   setLevelSelected(selectedOption ? selectedOption.level : ""); // Ensure level is also set on initial load
  // }, [selectedOption]);

  useEffect(() => {
    if (selectedOption) {
      setLabelSelected(selectedOption.labels || selectedOption?.house?.labels);
      setLevelSelected(selectedOption.level);
    } else {
      setLabelSelected("");
      setLevelSelected("");
      setSearchTerm("");
    }
  }, [selectedOption]);

  const filteredOptions = Array.isArray(options)
    ? options.filter((item) =>
        item?.labels?.toLowerCase().includes(searchTerm?.toLowerCase()) || item?.house?.labels.toLowerCase().includes(searchTerm?.toLowerCase())
      )
    : [];

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);

    if (e.target.value === "") {
      setLabelSelected("");
      setLevelSelected("");
    }
  };

  const handleToggle = () => setIsOpen(!isOpen);

  const handleSelect = (selectedOption) => {
    onSelect(selectedOption);
    setIsOpen(false);
    setLabelSelected(selectedOption.labels || selectedOption?.house?.labels || "");
    setLevelSelected(selectedOption.level || "");
    // setSelectedLabel(selectedOption ? selectedOption.labels : "");
    if (typeof setSelectedLabel === "function") {
      setSelectedLabel(selectedOption ? selectedOption.labels : "");
    }
    if (typeof setSelectedLevel === "function") {
      setSelectedLevel(selectedOption ? selectedOption.level : "");
    }
    setSearchTerm("");
  };

  const handleClickOutside = (event) => {
    if (inputRef.current && !inputRef.current.contains(event.target)) {
      setIsOpen(false);
      setSearchTerm("");
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative" ref={inputRef}>
      <input
        type="text"
        placeholder={
          labelSelected
            ? labelSelected
            : defaultSelected
            ? selectedValue
            : placeholder
        }
        className={`h-11 rounded-md w-full border border-gray-300 p-2 pl-3 text-text-primary placeholder:text-text-placeholder px-sm py-xs justify-start items-center gap-xxs inline-flex focus:outline-none focus:border-primary-400 focus:ring focus:ring-primary-200 disabled:bg-white
          ${isError ? "border-red-500" : "border-gray-300" }  
        `}
        // value={
        //   searchTerm
        //     ? searchTerm
        //     : labelSelected
        //     ? labelSelected
        //     : selectedValue
        // }
        value={
          searchTerm ||
          (selectedOption === null ? "" : labelSelected || selectedValue)
        }
        onChange={handleSearchChange}
        onClick={handleToggle}
        style={{ caretColor: "gray" }}
      />
      <ChevronDown
        className={`absolute right-4 top-3 ${
          isOpen ? "transform rotate-180" : ""
        } text-gray-500`}
        onClick={handleToggle}
      />
      {isOpen && (
        <ul
          className={
            onSatuan
              ? `absolute z-10 rounded-lg shadow bg-white w-full mt-2 overflow-scroll max-h-[202px] py-2 border border-neutral-300`
              : `absolute z-10 rounded-lg shadow bg-white w-full mt-2 overflow-scroll max-h-[152px] py-2 border border-neutral-300`
          }
        >
          {isLoading ? (
            <li className="p-2 pl-4 hover:bg-gray-100 cursor-pointer text-text-placeholder">
              Loading...
            </li>
          ) : filteredOptions?.length > 0 ? (
            filteredOptions?.map((item, index) => (
              <li
                key={index}
                className="p-2 pl-4 hover:bg-gray-100 cursor-pointer text-text-tertiary"
                onClick={() => handleSelect(item)}
              >
                {item.labels || item.name || item.house.labels ||""}
              </li>
            ))
          ) : (
            <li className="m-2">Tidak ada data</li>
          )}
        </ul>
      )}
    </div>
  );
};

export default Dropdown;
