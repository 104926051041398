import React, { useState } from "react";

import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import {
  Card,
  CardBody,
  CardHeader,
  Typography,
} from "@material-tailwind/react";
import { Link } from "react-router-dom";
import { useDebounce } from "use-debounce";

import { ButtonIcon } from "../../../../atoms/ButtonIcon";
import Input from "../../../../atoms/Input";
import LoadingAnimation from "../../../../atoms/LoadingAnimation";
import Table from "../../../../atoms/Table/Table";
import TableCellItem from "../../../../atoms/Table/TableCellItem";
import TableHeaderItem from "../../../../atoms/Table/TableHeaderItem";
import NotFound from "../../../../atoms/V2/NotFound";
import { ROUTES } from "../../../../shared/routes";
import { useGetMasterPeriodRegionList } from "../../../../useCases/MasterPeriod";

const MasterPeriodeRegion = () => {
  const [search, setSearch] = useState("");
  const [value] = useDebounce(search, 500);
  const [sortBy, setSortBy] = useState("createdAt");
  const [descending, setDescending] = useState(true);

  const query = useGetMasterPeriodRegionList({
    sortBy,
    descending,
    search: value,
  });
  const data = query.data?.contents;

  const handleSort = (column) => {
    if (sortBy === column) {
      setDescending(!descending);
    } else {
      setSortBy(column);
      setDescending(false);
    }
  };

  const columns = [
    {
      accessorKey: "unitName",
      header: ({ column }) => {
        const value = column.id;
        return (
          <TableHeaderItem
            title="Nama Unit"
            value={value}
            handleSort={handleSort}
            isDescending={descending}
            customClass="py-[6px]"
          />
        );
      },
      cell: ({ row }) => {
        const value = row.original.unitName;
        return (
          <Link to={ROUTES.masterPeriodeRegionDetail(row.original.id)}>
            <TableCellItem
              value={value}
              variant="actionLink"
              isNumber={false}
              customClass="max-w-[200px]"
            />
          </Link>
        );
      },
    },
    {
      accessorKey: "settingCount",
      header: ({ column }) => {
        const value = column.id;
        return (
          <TableHeaderItem
            title="Periode setting"
            value={value}
            handleSort={handleSort}
            isDescending={descending}
          />
        );
      },
      cell: ({ row }) => {
        const value = row.original.settingCount;
        return <TableCellItem value={value} />;
      },
    },
    {
      accessorKey: "runningCount",
      header: ({ column }) => {
        const value = column.id;
        return (
          <TableHeaderItem
            title="Periode Berjalan"
            value={value}
            handleSort={handleSort}
            isDescending={descending}
          />
        );
      },
      cell: ({ row }) => {
        const value = row.original.runningCount;
        return <TableCellItem value={value} />;
      },
    },
    {
      accessorKey: "closingCount",
      header: ({ column }) => {
        const value = column.id;
        return (
          <TableHeaderItem
            title="Periode Selesai"
            value={value}
            handleSort={handleSort}
            isDescending={descending}
          />
        );
      },
      cell: ({ row }) => {
        const value = row.original.closingCount;
        return <TableCellItem value={value} />;
      },
    },
    {
      accessorKey: "totalPeriods",
      header: ({ column }) => {
        const value = column.id;
        return (
          <TableHeaderItem
            title="Total Periode"
            value={value}
            handleSort={handleSort}
            isDescending={descending}
          />
        );
      },
      cell: ({ row }) => {
        const value = row.original.totalPeriods;
        return <TableCellItem value={value} />;
      },
    },
    {
      accessorKey: "action",
      header: "",
      cell: ({ row }) => {
        const value = row.original.id;
        return (
          <div className="mx-auto w-5 table-fixed py-[10px]">
            <div className="flex w-full items-center justify-center">
              <Link to={ROUTES.masterPeriodeRegionDetail(value)}>
                <ButtonIcon icon="detail" type="outlineInfo" size="small" />
              </Link>
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <div className="z-0 flex h-auto w-full overflow-hidden">
      <div className="flex flex-1 flex-col overflow-hidden">
        <div className="p-4 pb-0">
          <Card className="m-0 h-auto w-full p-0 shadow-none">
            <CardHeader
              floated={false}
              shadow={false}
              className="m-0 w-full rounded-none rounded-t-xl border border-b-0 border-border-secondary px-3xl py-xl"
            >
              <div className="space-y-xl">
                <Typography
                  variant="h2"
                  className="text-xl font-bold text-text-primary"
                >
                  Master Periode
                </Typography>
                <div className="flex items-center justify-between">
                  <Input
                    id="search"
                    type="search"
                    size="small"
                    value={search}
                    placeholder="Cari pemilik kandang, nama kandang"
                    onChange={(e) => setSearch(e.target.value)}
                    componentLeft={<MagnifyingGlassIcon className="h-5 w-5" />}
                    className="h-9 !w-[364px] flex-shrink-0 border border-border-primary !py-2"
                  />
                </div>
              </div>
            </CardHeader>
            <CardBody className="max-h-[960px] max-w-full p-0">
              {query.isLoading ? (
                <div className="flex h-[75dvh] w-full items-center justify-center">
                  <LoadingAnimation />
                </div>
              ) : query.data?.contents?.length === 0 || query.isError ? (
                <div className="flex h-[75dvh] w-full flex-col items-center justify-center">
                  <NotFound />
                </div>
              ) : (
                <>
                  <Table
                    data={data || []}
                    columns={columns}
                    hideFooter
                    className="max-h-[73vh] lg:max-h-[67vh] xl:max-h-[74vh] 2xl:max-h-[76vh]"
                  />
                </>
              )}
            </CardBody>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default MasterPeriodeRegion;
