import React from "react";

import MasterPeriodeFeature from "./feature";
import { MasterPeriodeStore } from "./hook";

const MasterPeriode = () => {
  return (
    <MasterPeriodeStore>
      <MasterPeriodeFeature />
    </MasterPeriodeStore>
  );
};

export default MasterPeriode;
