/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Switch,
  Typography,
} from "@material-tailwind/react";
import Input from "../../../../atoms/Input";
import api from "../../../../api/configApi";
import { Button } from "../../../../atoms/Button";
import AlertNotification from "../../../../atoms/AlertNotification";
import FormDropdownSearch from "../../../../atoms/FormDropdownSearch";
import TextArea from "../../../../atoms/TextArea";
import { FaCheckCircle } from "react-icons/fa";
import ConfirmDialog from "../../../../atoms/ConfirmDialog";
import { ReactComponent as ChevronLeft } from "../../../../assets/icons/chevron-left.svg";
import * as Sentry from "@sentry/react";

const AddUnit = ({ onReloadData }) => {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [addCodeUnit, setAddCodeUnit] = useState("")
  const [codeUnitEmpty, setCodeUnitEmpty] = useState(false)
  const [isValid, setIsValid] = useState(false)
  const [regions, setRegions] = useState([]);
  const [cities, setCities] = useState([]);

  const [listUser, setListUsers] = useState([]);

  const [selectedHeadOfUnit, setSelectedHeadOfUnit] = useState("");
  const [selectedRegion, setSelectedRegion] = useState("");
  const [selectedCities, setSelectedCities] = useState("");

  const [isAddSuccess, setAddSuccess] = useState(false);
  const [isAddError, setIsAddError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [searchHeadOfUnit, setSearchHeadOfUnit] = useState("");
  const [searchCity, setSearchCity] = useState("");
  const [searchProvince, setSearchProvince] = useState("");

  const [IsNameEmpty, setIsNameEmpty] = useState(false);
  const [IsAddressEmpty, setIsAddressEmpty] = useState(false);
  const [isActive, setActive] = useState(false);

  const [isAddDialogOpen, setAddDialogOpen] = useState(false);
  const [region, setRegion] = useState([])
  const [searchRegion, setSearchRegion] = useState("")
  const [selectedRegions, setSelectedRegions] = useState("")
  const [showErrorMessage, setShowErrorMessage] = useState(false)

  const isSubmitDisabled =
    !name ||
    !address ||
    !addCodeUnit ||
    !selectedHeadOfUnit ||
    !selectedRegion ||
    !selectedRegions ||
    !selectedCities;

  const handleSwitchChange = () => {
    setActive(!isActive);
  };

  const handleOpenAddDialog = () => {
    setAddDialogOpen(true);
  };

  const handleCloseAddDialog = () => {
    setAddDialogOpen(false);
  };

  const handleSelectHeadOfUnit = (select) => {
    setSelectedHeadOfUnit(select.id);
  };

  const handleSelectRegion = (select) => {
    setSelectedRegion(select.id);
  };

  const handleSelectCities = (select) => {
    setSelectedCities(select.id);
  };

  const handleSelectRegions = (select) => {
    setSelectedRegions(select.id)
  }

  const handleNameBlur = () => {
    if (name.trim() === "") {
      setIsNameEmpty(true);
    } else {
      setIsNameEmpty(false);
    }
  };

  const handleCodeUnitBlur = () => {
    if (addCodeUnit.trim() === "") {
      setCodeUnitEmpty(true)
    } else {
      setCodeUnitEmpty(false)
    }
  }

  const handleAddressBlur = () => {
    if (address.trim() === "") {
      setIsAddressEmpty(true);
    } else {
      setIsAddressEmpty(false);
    }
  };

  const handleChangeCodeUnit = (e) => {
    const value = e.target.value;
    const regex = /^(?=.*[a-zA-Z]{3})(?=.*\d{1})[a-zA-Z\d]{4}$/;

    if (value.length <= 4) {
      setAddCodeUnit(value)
    }

    if (regex.test(value)) {
      setIsValid(true)
    } else {
      setIsValid(false)
    }
  }


  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const params = {
          search: searchHeadOfUnit,
          role: "Kepala Unit"
        };
        const response = await api.get("/v1/users/dropdown", { params });
        setListUsers(response?.data?.contents);
      } catch (error) {
        Sentry.captureException(error);
      }
    };

    fetchUsers();
  }, [searchHeadOfUnit]);


  useEffect(() => {
    const dataFetchDropdownRegion = async () => {
      try {
        const responseRegionDropdown = await api.get(
          "/v1/regions/id/province/dropdown", {
            params: {
              search: searchProvince,
            },
          }
        );
        setRegions(responseRegionDropdown.data);
      } catch (error) {
        Sentry.captureException(error);
      }
    };

    const dataFetchRegion = async () => {
      try {
        const responseRegion = await api.get('/v1/regions/dropdown', {
          params: {
            search: searchRegion
          }
        })
        setRegion(responseRegion.data)
      } catch (error) {
        console.log(error)
      }
    }

    dataFetchRegion()
    dataFetchDropdownRegion();
  }, [searchProvince, searchRegion]);


  useEffect(() => {
    const fetchCities = async () => {
      const data = await api.get(`/v1/regions/id/cities/dropdown`, {
        params: {
          search: searchCity,
          province: selectedRegion,
        },
      });
      setCities(data?.data);
    };

    fetchCities();
  }, [ searchCity, selectedRegion]);

  const handleNameChange = (e) => {
    let newName = e.target.value;

    if (newName.length < 100) {
      setName(newName);
    }
  };

  const handleSave = async () => {
    setIsSubmitting(true);
    const newUnit = {
      name: name,
      address: address,
      code: addCodeUnit,
      headOfUnit: { id: selectedHeadOfUnit },
      city: { id: selectedCities },
      province: { id: selectedRegion },
      region: { id: selectedRegions },
      isActive: isActive,
    };
    try {
      const response = await api.post("/v1/units", newUnit);

      console.log("Unit berhasil ditambahkan:", response.data);

      setAddSuccess(true);

      navigate("/master/unit");
      localStorage.setItem("responseAddSuccess", JSON.stringify(!isAddSuccess));

      if (onReloadData) {
        onReloadData();
      }
    } catch (error) {
      handleCloseAddDialog();
      setIsSubmitting(false);
      console.error("Gagal menambahkan unit:", error.response.data.error);
      setIsAddError(true);
      setShowErrorMessage(true)
      setErrorMessage(
        error.response.data.error?.name || error.response.data.error
      );
      setTimeout(() => setIsAddError(false), 2000);
    }
  };

  const handleBack = () => {
    navigate("/master/unit");
  };


  return (
    <div className="p-4 flex justify-center">
      <div>
        <Card className="border w-[800px] shadow-none border-neutral-300">
          <CardHeader floated={false} shadow={false} className="rounded-b-none">

            <Typography className="text-xl font-bold text-text-primary flex items-center">
              <div className="cursor-pointer pr-2" onClick={handleBack}>
                <ChevronLeft />
              </div>
              Tambah unit
            </Typography>
          </CardHeader>
          <div className="flex justify-center w-full">
            <CardBody className="w-[440px]">
              <div className="mb-3xl">
                <div>
                <Typography className="block text-sm font-semibold text-text-primary leading-tight mb-2">
                    Region{" "}
                    <span className="text-red-500 text-sm font-normal leading-tight">
                      *
                    </span>
                  </Typography>

                  <FormDropdownSearch 
                    placeholder="Pilih region"
                    options={region?.contents}
                    size="medium"
                    setSearch={setSearchRegion}
                    search={searchRegion}
                    onSelect={handleSelectRegions}
                  />
                </div>

                <div className="mt-5">
                  <Typography className="block text-sm font-semibold text-text-primary leading-tight mb-2">
                    Nama Unit{" "}
                    <span className="text-red-500 text-sm font-normal leading-tight">
                      *
                    </span>
                  </Typography>
                  <Input
                    type="text"
                    id="name"
                    placeholder="Masukkan nama unit"
                    className={`max-w-md ${IsNameEmpty && "border-red-500"}`}
                    value={name}
                    size="medium"
                    onChange={handleNameChange}
                    onBlur={handleNameBlur}
                  />
                </div>
                {name === "" && IsNameEmpty && (
                  <p className="text-red-500 text-sm mt-1">
                    Kolom ini tidak boleh kosong
                  </p>
                )}
              </div>

              <Typography
                className="text-sm font-semibold text-text-primary leading-tight"
              >
                Kode unit <span className="text-red-500">*</span>
              </Typography>

              <div className="mt-md mb-3xl">
                <Input
                  type="text"
                  id="codeUnit"
                  placeholder="Masukkan kode unit"
                  className={`max-w-md ${codeUnitEmpty && "border-red-500"}`}
                  value={addCodeUnit?.toLocaleUpperCase("id-ID")}
                  size="medium"
                  onChange={handleChangeCodeUnit}
                  onBlur={handleCodeUnitBlur}
                />

                {showErrorMessage && (
                  <p className="text-red-500 text-sm mt-1">{errorMessage}</p>
                )}

                {addCodeUnit === "" && codeUnitEmpty &&  (
                  <p className="text-red-500 text-sm mt-1">
                    Kolom ini tidak boleh kosong
                  </p>
                )}
              </div>

              <Typography
                className="text-sm font-semibold text-text-primary leading-tight"
              >
                Kepala Unit <span className="text-red-500">*</span>
              </Typography>

              <div className="mt-md mb-3xl">
                <FormDropdownSearch
                  onSelect={handleSelectHeadOfUnit}
                  placeholder="Pilih Kepala Unit"
                  options={listUser}
                  setSearch={setSearchHeadOfUnit}
                  search={searchHeadOfUnit}
                  size="medium"
                />
              </div>

              <Typography
                className="text-sm font-semibold text-text-primary leading-tight"
              >
                Provinsi <span className="text-red-500">*</span>
              </Typography>

              <div className="mt-2">
                <FormDropdownSearch
                  onSelect={handleSelectRegion}
                  placeholder="Pilih provinsi"
                  options={regions?.contents}
                  setSearch={setSearchProvince}
                  search={searchProvince}
                  size="medium"
                />
              </div>

              <Typography
                className="text-sm mt-3xl font-semibold text-text-primary leading-tight"
              >
                Kabupaten / kota <span className="text-red-500">*</span>
              </Typography>

              <div className="mt-2 mb-3xl">
                <FormDropdownSearch
                  onSelect={handleSelectCities}
                  placeholder="Pilih kota/kabupaten"
                  options={cities?.contents}
                  setSearch={setSearchCity}
                  search={searchCity}
                  size="medium"
                />
              </div>

              <Typography
                className="text-sm font-semibold text-text-primary leading-tight"
              >
                Alamat Unit <span className="text-red-500">*</span>
              </Typography>

              <div className="mt-md">
                <TextArea
                  placeholder="Masukkan alamat unit"
                  value={address}
                  className={`${IsAddressEmpty && "border-red-500"} placeholder:text-text-placeholder`}
                  onChange={(e) => setAddress(e.target.value)}
                  onBlur={handleAddressBlur}
                />
              </div>
              {address === "" && IsAddressEmpty && (
                <p className="text-red-500 text-sm mt-1">
                  Kolom ini tidak boleh kosong
                </p>
              )}
              <div className="mt-3xl items-center gap-4">
                <div className="my-md">
                  <label className="block font-semibold text-text-primary leading-tight text-sm">
                    Status unit
                  </label>
                </div>
                <div>
                  <Switch
                    className="checked:bg-bg-brandSolid"
                    onChange={handleSwitchChange}
                    checked={isActive}
                  />
                  <span className="m-2 text-sm">
                    {isActive ? "Aktif" : "Tidak Aktif"}
                  </span>
                </div>
              </div>

              <div className="mt-9 items-center gap-4">
                <div className="my-3"></div>
                <div></div>
              </div>
            </CardBody>
          </div>

          <CardFooter className="flex justify-end border-t border-border-secondary p-lg">
            <Button
              label="Batal"
              size="medium"
              type="btnSecondary"
              className="mr-md"
              onClick={handleBack}
            />
            <Button
              label="Simpan"
              size="medium"
              type={`${
                isSubmitDisabled || isSubmitting || isValid
                  ? "btnPrimaryDisabled"
                  : "btnPrimary"
              }`}
              onClick={handleOpenAddDialog}
              disabled={isSubmitDisabled || isSubmitting ||  isValid}
              componentLeft={
                isSubmitting && (
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    className="animate-spin fill-neutral-400"
                  >
                    <path
                      d="M10.72,19.9a8,8,0,0,1-6.5-9.79A7.77,7.77,0,0,1,10.4,4.16a8,8,0,0,1,9.49,6.52A1.54,1.54,0,0,0,21.38,12h.13a1.37,1.37,0,0,0,1.38-1.54,11,11,0,1,0-12.7,12.39A1.54,1.54,0,0,0,12,21.34h0A1.47,1.47,0,0,0,10.72,19.9Z"
                      className="spinner_6kVp"
                    />
                  </svg>
                )
              }
            />
          </CardFooter>
        </Card>

        <ConfirmDialog
          isOpen={isAddDialogOpen}
          onClose={handleCloseAddDialog}
          onConfirm={handleSave}
          title="Konfirmasi tambah unit"
          content="Pastikan semua informasi unit yang ditambahkan sudah benar sebelum menyimpan."
          btnLabel="Lanjutkan"
          btnType="btnPrimary"
        />

        {isAddError && (
          <AlertNotification
            icon={<FaCheckCircle />}
            color="red"
            message={errorMessage}
          />
        )}
      </div>
    </div>
  );
};

export default AddUnit;
