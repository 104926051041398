/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/img-redundant-alt */

import { useEffect, useState } from "react";
import api from "../../../api/configApi";
import { useNavigate, useParams } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  Typography,
  Chip,
} from "@material-tailwind/react";
import { ReactComponent as BackArrow } from "../../../assets/icons/chevron-left.svg";
import { Button } from "../../../atoms/Button";
import ToasterAlert from "../../../atoms/Toaster";
import moment from "moment";
import { ReactComponent as DataTimbangIcon } from "../../../assets/icons/data_timbang_icon.svg";
import ModalInputPanen from "../../../molecules/tablePanenV2/modalInputPanen";
import useFetchImage from "../../../molecules/tablePanen/HookFetchingPanen";
import ConfirmDialog from "../../../atoms/ConfirmDialog";
import LoadingAnimation from "../../../atoms/LoadingAnimation";
import * as Sentry from "@sentry/react";
import ModalImage from "../../../molecules/tablePanenV2/modalImage";
import { ReactComponent as StatusFarming } from "../../../assets/icons/DetailFarming/status.svg";
import { ReactComponent as DateFarming } from "../../../assets/icons/DetailFarming/date.svg";
import { ReactComponent as Unit } from "../../../assets/icons/DetailFarming/unit.svg";
import { ReactComponent as IdPeriode } from "../../../assets/icons/DetailFarming/id_periode.svg";
import { ReactComponent as NamaBakul } from "../../../assets/icons/DetailFarming/nama-bakul.svg";
import { ReactComponent as PPL } from "../../../assets/icons/DetailFarming/ppl.svg";

const DetailPanen = () => {
  const [responseDetail, setResponseDetail] = useState(null);
  const { id } = useParams();
  const [openModal, setOpenModal] = useState(false);
  const [groupingHistory, setGroupingHistory] = useState(null);
  const [modalConfirmation, setModalconfirmation] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [fetchData, setFetchData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dataTimbang, setDataTimbang] = useState(null);
  const navigate = useNavigate();
  const [idDetail, setIdDetail] = useState("");
  const [urlImg, setUrlImg] = useState("");
  const [showPhoto, setShowPhoto] = useState(false);

  const handleInputData = (idDetail, urlImg) => {
    setIdDetail(idDetail);
    setUrlImg(urlImg);
    setOpenModal(!openModal);
  };

  const handleShowImage = (urlImg) => {
    setUrlImg(urlImg);
    setShowPhoto(!showPhoto);
  };

  const reloadData = async () => {
    try {
      const data = await api.get(`/v2/harvests/${id}`);
      setDataTimbang(data?.data?.details);
      setResponseDetail(data?.data);
      setGroupingHistory(
        Object.groupBy(data?.data?.history, ({ fieldName }) => fieldName)
      );
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  useEffect(() => {
    const refetchDetail = async () => {
      setLoading(true);

      try {
        const data = await api.get(`/v2/harvests/${id}`);
        setDataTimbang(data?.data?.details);
        setResponseDetail(data?.data);
        setGroupingHistory(
          Object.groupBy(data?.data?.history, ({ fieldName }) => fieldName)
        );
      } catch (err) {
        Sentry.captureException(err);
      } finally {
        setLoading(false);
      }
    };

    if (fetchData) {
      setFetchData(false);
    }

    refetchDetail();
  }, [id, fetchData]);

  const timeHarvest = [
    {
      id: 1,
      label: "Status",
      value: responseDetail?.status?.id,
      icon: <StatusFarming />,
      statusFarm: true,
    },
    {
      id: 2,
      label: "Tanggal",
      value: moment(responseDetail?.harvestDate).format("DD MMMM YYYY"),
      icon: <DateFarming />,
    },
    {
      id: 3,
      label: "Unit",
      value: responseDetail?.rearingPeriod?.house?.unit?.labels,
      icon: <Unit />,
    },
    {
      id: 4,
      label: "ID periode",
      value: responseDetail?.rearingPeriod?.analyticAccount,
      icon: <IdPeriode />,
    },
    {
      id: 5,
      label: "Nama bakul",
      value: responseDetail?.bakul?.labels,
      icon: <NamaBakul />,
    },
    {
      id: 6,
      label: "PPL",
      value: responseDetail?.rearingPeriod?.house?.ppl?.labels,
      icon: <PPL />,
    },
  ];

  const handleClickReview = async () => {
    try {
      const responseReview = await api.post(
        `/v2/harvests/${responseDetail?.id}/review`
      );
      if (responseReview.status === 200) {
        setIsSuccess(true);
      }
      localStorage.setItem("validate success", JSON.stringify(!isSuccess));
      localStorage.getItem(
        "response success",
        JSON.stringify(responseReview.status)
      );
      navigate("/panen");
    } catch (err) {
      Sentry.captureException(err);
    }
  };

  const FetchedImageComponent = ({ url }) => {
    const { data, error } = useFetchImage(url);

    if (error)
      return (
        <div className="text-center h-[169px] w-[169px] flex justify-center items-center">
          Failed to load image
        </div>
      );
    if (!data)
      return (
        <div className="text-center h-[169px] w-[169px] flex justify-center items-center">
          Loading...
        </div>
      );

    return (
      <img
        className="h-full w-full object-cover object-center rounded-xl"
        onClick={() => handleShowImage(data.url)}
        src={data.url}
        alt="harvest"
      />
    );
  };

  return (
    <div className="flex h-auto xl:overflow-hidden overflow-auto">
      {loading ? (
        <div className="flex justify-center items-center h-screen w-full">
          <LoadingAnimation />
        </div>
      ) : (
        <div className="flex flex-col flex-1 overflow-hidden mt-2xl">
          <Card className="w-full h-auto border p-0 m-0">
            <CardHeader
              floated={false}
              shadow={false}
              className="p-0 m-0 mt-sm rounded-none border-x-0 border-t-0 border-b border-border-secondary"
            >
              <div className="p-3xl">
                <Typography className="text-xl text-text-primary font-bold relative">
                  <div className="flex gap-2 items-center">
                    <BackArrow
                      onClick={() => navigate("/panen")}
                      className="cursor-pointer h-5 w-5"
                    />
                    Detail
                  </div>
                </Typography>
              </div>
            </CardHeader>

            <CardBody className="p-0 m-0 hidden:overflow-scroll">
              <Card
                className="border-none m-0 p-0"
                floated={false}
                shadow={false}
              >
                <CardHeader
                  floated={false}
                  shadow={false}
                  className="py-3xl mx-4xl mt-sm rounded-none border-x-0 border-t-0 border-b border-border-secondary"
                >
                  <div className="flex items-center justify-between">
                    <Typography className="text-2xl text-text-primary font-bold">
                      {responseDetail?.rearingPeriod?.house?.labels}
                    </Typography>
                  </div>
                </CardHeader>
                <CardBody>
                  <div className="mt-3 p-4 rounded-lg w-full">
                    <div className="w-full">
                      {timeHarvest?.map((val, key) => (
                        <div key={val.id} className="grid grid-cols-2 w-[46%]">
                          <Typography className=" flex  gap-3 text-sm font-medium leading-tight text-text-tertiary p-lg">
                            {val.icon} {val.label}
                          </Typography>

                          <div className="flex justify-start">
                            {val.statusFarm === true ? (
                              <div className="w-max ml-[14px]">
                                <Chip
                                  size="sm"
                                  variant="ghost"
                                  value={
                                    val.value === "WAITING_FOR_REVIEW"
                                      ? "Menunggu direview"
                                      : val.value === "ON_REVIEW"
                                      ? "Sedang direview"
                                      : "Selesai direview"
                                  }
                                  className={`text-sm font-inter font-normal leading-[18px] border normal-case ${
                                    val.value === "WAITING_FOR_REVIEW"
                                      ? "text-[#C49801] border-[#FBE497] bg-[#FEF8E2]"
                                      : val.value === "ON_REVIEW"
                                      ? "text-[#1570EF] border-[#B2DDFF] bg-[#F5FAFF]"
                                      : "text-[#079455] border-[#ABEFC6] bg-[#F6FEF9]"
                                  }`}
                                  icon={
                                    val.value === "WAITING_FOR_REVIEW" ? (
                                      <span className="mx-auto mt-1 block h-2 w-2 rounded-full bg-[#F7CB33] content-['']" />
                                    ) : val.value === "ON_REVIEW" ? (
                                      <span className="mx-auto mt-1 block h-2 w-2 rounded-full bg-[#53B1FD]  content-['']" />
                                    ) : (
                                      <span className="mx-auto mt-1 block h-2 w-2 rounded-full bg-[#17B26A] content-['']" />
                                    )
                                  }
                                />
                              </div>
                            ) : (
                              <Typography className=" text-base flex justify-start font-bold leading-normal text-text-secondary px-4 py-2">
                                {val.value}
                              </Typography>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="mt-3xl border border-border-primary w-full p-lg rounded-lg">
                    {responseDetail?.note || "Tidak ada catatan"}
                  </div>
                </CardBody>
              </Card>

              <Card
                className="p-0 m-3xl h-auto border"
                floated={false}
                shadow={false}
              >
                <CardHeader
                  floated={false}
                  shadow={false}
                  className="p-0 m-0 border-b rounded-b-none"
                >
                  <div className="flex items-center p-2xl">
                    <DataTimbangIcon />
                    <Typography className="text-xl text-text-primary font-semibold p-2">
                      Data timbang
                    </Typography>
                  </div>
                </CardHeader>

                <CardBody className="p-2xl m-0 hidden:overflow-scroll">
                  <div className="w-full">
                    <ToasterAlert
                      type="info"
                      className="w-full shadow-none"
                      label="Perhatian: mohon periksa dengan teliti!"
                      description="Pastikan untuk memeriksa setiap data dengan teliti. Jika Anda menemukan data yang tidak sesuai, Anda dapat mengubahnya sebelum menyelesaikan proses."
                    />
                  </div>
                  <div className="w-full">
                    {dataTimbang?.map((val, index) => (
                      <Card
                        key={index}
                        className="w-full border border-border-primary mt-4 shadow-none"
                      >
                        <div className="flex justify-start z-0 items-center p-lg">
                          <div>
                            <div className="h-[169px] w-[169px]">
                              {val?.media?.labels ? (
                                <div className="cursor-pointer hover:opacity-45 h-full w-full rounded-md">
                                  <FetchedImageComponent
                                    url={val?.media?.labels}
                                  />
                                </div>
                              ) : (
                                <div className="text-center">
                                  No Image Available
                                </div>
                              )}
                            </div>
                          </div>

                          {val?.doNumber ? (
                            <div className="w-full h-auto">
                              <div className="flex justify-between items-center p-lg">
                                <Typography className="text-lg text-text-primary font-bold">
                                  {val?.doNumber}
                                </Typography>
                                <Button
                                  type="btnInfoOutlined"
                                  size={"small"}
                                  label="Edit Data"
                                  onClick={() =>
                                    handleInputData(val?.id, val?.media?.labels)
                                  }
                                  className="cursor-pointer h-[40px]"
                                />
                              </div>
                              <div className="flex justify-start items-center px-lg">
                                <Typography className="px-sm bg-black text-white text-xs font-medium leading-[18px] rounded-sm">
                                  {val?.licenseNumber}
                                </Typography>
                                <div className="border-x border-border-primary px-md ml-md text-sm font-normal leading-tight text-text-primmary">
                                  {val?.harvestType?.labels}
                                </div>
                                <div className="p-md text-sm font-normal text-text-primmary">
                                  {val?.chickenType?.labels}
                                </div>
                              </div>
                              <div className="flex justify-start items-center p-md gap-4">
                                <div className="bg-neutral-100 p-md w-full">
                                  <Typography className="text-xs text-text-tertiary leading-[18px] font-normal">
                                    Jumlah Panen
                                  </Typography>
                                  <div className="flex justify-start gap-2 items-center">
                                    <Typography className="text-base text-text-primary font-bold leading-normal">
                                      {val?.totalHarvest.toLocaleString(
                                        "id-ID"
                                      ) || 0}
                                    </Typography>
                                    <Typography className="text-sm text-text-secondary leading-tight font-normal">
                                      Ekor
                                    </Typography>
                                  </div>
                                </div>
                                <div className="bg-neutral-100 p-md w-full">
                                  <Typography className="text-xs text-text-tertiary leading-[18px] font-normal">
                                    Tonase
                                  </Typography>
                                  <div className="flex justify-start gap-2 items-center">
                                    <Typography className="text-base text-text-primary font-bold leading-normal">
                                      {val?.totalTonnage.toLocaleString(
                                        "id-ID"
                                      ) || 0}
                                    </Typography>
                                    <Typography className="text-sm text-text-secondary leading-tight font-normal">
                                      kg
                                    </Typography>
                                  </div>
                                </div>
                                <div className="bg-neutral-100 p-md w-full">
                                  <Typography className="text-xs text-text-tertiary leading-[18px] font-normal">
                                    Rata-rata BW
                                  </Typography>
                                  <div className="flex justify-start gap-2 items-center">
                                    <Typography className="text-base text-text-primary font-bold leading-normal">
                                      {val?.averageBw.toLocaleString("id-ID") ||
                                        0}
                                    </Typography>
                                    <Typography className="text-sm text-text-secondary leading-tight font-normal">
                                      kg
                                    </Typography>
                                  </div>
                                </div>
                                <div className="bg-neutral-100 p-md w-full">
                                  <Typography className="text-xs text-text-tertiary leading-[18px] font-normal">
                                    Harga pasar
                                  </Typography>
                                  <div className="flex justify-start gap-2 items-center">
                                    <Typography className="text-sm text-text-secondary leading-tight font-normal">
                                      Rp
                                    </Typography>
                                    <Typography className="text-base text-text-primary font-bold leading-normal">
                                      {val?.marketPrice.toLocaleString("id-ID")}
                                    </Typography>
                                  </div>
                                </div>
                                <div className="bg-neutral-100 p-md w-full">
                                  <Typography className="text-xs text-text-tertiary leading-[18px] font-normal">
                                    Harga kontrak
                                  </Typography>
                                  <div className="flex justify-start gap-2 items-center">
                                    <Typography className="text-sm text-text-secondary leading-tight font-normal">
                                      Rp
                                    </Typography>
                                    <Typography className="text-base text-text-primary font-bold leading-normal">
                                      {val?.contractPrice.toLocaleString(
                                        "id-ID"
                                      )}
                                    </Typography>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="w-full">
                              <div className="text-center">
                                <Typography className="text-text-primary text-base font-medium leading-normal px-lg mx-auto">
                                  Belum ada data di inputkan
                                </Typography>

                                <Button
                                  type="btnPrimary"
                                  size="small"
                                  label="Tambahkan Data"
                                  onClick={() =>
                                    handleInputData(val?.id, val?.media?.labels)
                                  }
                                  className="cursor-pointer h-[40px] mt-4 mx-auto block"
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      </Card>
                    ))}
                  </div>
                </CardBody>
              </Card>
            </CardBody>
          </Card>
        </div>
      )}

      <ConfirmDialog
        isOpen={modalConfirmation}
        onClose={() => setModalconfirmation(!modalConfirmation)}
        content="Pastikan semua data sudah benar karena setelah ini, perubahan tidak dapat dilakukan."
        title="Selesai review data?"
        btnLabel="Lanjutkan"
        onConfirm={handleClickReview}
      />

      <ModalImage
        open={showPhoto}
        setOpen={() => setShowPhoto(!showPhoto)}
        url={urlImg}
      />

      <ModalInputPanen
        open={openModal}
        setOpen={() => setOpenModal(!openModal)}
        harvestId={id}
        detailId={idDetail}
        url={urlImg}
        setUrl={setUrlImg}
        onReloadData={reloadData}
      />
    </div>
  );
};

export default DetailPanen;
