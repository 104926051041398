import { z } from "zod";

export const GUARANTEE_TYPE = [
  {
    label: "Sertifikat",
    value: "SERTIFICATE",
  },
  {
    label: "BPKB",
    value: "BPKB",
  },
  {
    label: "Uang Tunai",
    value: "CASH_MONEY",
  },
];

export const schema = z.object({
  house: z.string().min(1, {
    message: "Kandang belum diisi",
  }),
  ppl: z.string().optional(),
  sequence: z
    .number({
      message: "Periode belum diisi",
    })
    .min(1, {
      message: "Masukkan periode yang valid",
    }),
  docInDate: z.date(),
  docInPopulation: z
    .number({
      message: "Populasi belum diisi",
    })
    .min(1, {
      message: "Masukkan populasi yang valid",
    }),
  guaranteeType: z.string(),
  guaranteeAmount: z.number().min(1, {
    message: "Masukkan nominal jaminan yang valid",
  }),
  differentFcrHistory1: z.coerce.number(),
  differentFcrHistory2: z.coerce.number(),
  differentFcrHistory3: z.coerce.number(),
  performanceIndexHistory1: z.coerce.number(),
  performanceIndexHistory2: z.coerce.number(),
  performanceIndexHistory3: z.coerce.number(),
});
