/* eslint-disable no-unused-vars */

const EmptyPage = () => {
  return (
   <>
     {/* <SimpleHeader/> */}

    <div className="flex justify-center items-center h-screen">
      You don't have permission to accees page pelase contact admin      
    </div>
   </>
  )
}

export default EmptyPage