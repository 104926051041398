import { Dialog, DialogBody, DialogFooter, DialogHeader, IconButton, Typography } from "@material-tailwind/react"
import { Button } from "../../atoms/Button"
import Input from "../../atoms/Input"
import InputNumber from "../../atoms/NumberFormat"
import FormDropdownSearch from "../../atoms/FormDropdownSearch"

const ModalEditPanen = ({
  open,
  setOpen,
  ModalHeader,
  formDropdown,
  formInputDo,
  formInputRegisterNumber,
  formInputPrice,
  handleOnclickSave,
  handleOnclickCancel,
  disabled,
  valueInput,
  onChangeInput,
  dropdownPlaceholder,
  onSelect,
  optionsBuyyer,
  setSearchBuyyer,
  searchBuyyer,
  valueInputDo,
  setValueInputDo,
  inputRegisterNumber,
  setInputRegisterNumber,
  inputPrice, 
  setInputPrice,
  error,
  errorType
}) => {

  return (
    <Dialog open={open} handler={setOpen}>
      <DialogHeader className="flex justify-between">
        <Typography
          variant="h5"
          color="blue-gray"
        >
          {ModalHeader}
        </Typography>

        <IconButton
          color="blue-gray"
          size="sm"
          variant="text"
          onClick={setOpen}
        >
          <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
              className="h-5 w-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
          </svg>
        </IconButton>
      </DialogHeader>
      <div className="border-t border-[#EAECF0] my-4 w-full" />

      <DialogBody className="mt-4">
        <div className="p-4 mt-[-8px]">
          {formDropdown && (
            <>
              <Typography
                variant="sm"
                color="blue-gray"
              >
                Pembeli
              </Typography>

              <FormDropdownSearch
                placeholder={dropdownPlaceholder}
                onSelect={onSelect}
                options={optionsBuyyer}
                size="medium"
                setSearch={setSearchBuyyer}
                search={searchBuyyer}
              />
            </>
          )}

          {formInputDo && (
            <>
               <Typography
             variant="sm"
             color="blue-gray"
            >
              Nomor Do
            </Typography>

            <Input
              size="medium"
              value={valueInputDo}
              onChange={setValueInputDo}
              className="max-w-full"
              type="text"
              // disabled={disabledNoDo}
            />
            {error && <p className="text-red-500 text-sm mt-1">{error}</p>}
            </>
          )}

          {formInputRegisterNumber && (
            <>
              <Typography
                variant="sm"
                color="blue-gray"
              >
              Plat Nomor
            </Typography>

              <Input
                size="medium"
                value={inputRegisterNumber}
                onChange={setInputRegisterNumber}
              />
              {error && <p className="text-red-500 text-sm mt-1">{error}</p>}
           </>
          )}

          {formInputPrice && (
            <>
              <Typography
                variant="sm"
                color="blue-gray"
              >
                {""}
              </Typography>

              <InputNumber
                size="medium"
                className="max-w-full"
                value={inputPrice}
                onChange={setInputPrice}
              />
              {error && <p className="text-red-500 text-sm mt-1">{error}</p>}
            </>
          )}
        </div>
      </DialogBody>

      <DialogFooter className="flex justify-end gap-4">
        <Button
          label="Batal"
          onClick={handleOnclickCancel}
          type="btnSecondary"
        />

        <Button
          label="Simpan"
          onClick={handleOnclickSave}
          disabled={disabled}
          type={errorType}
        />
      </DialogFooter>
    </Dialog>
  )
}

export default ModalEditPanen