export const ROUTES_PATH = {
  masterPeriode: "/periode-budidaya/master-periode",
  masterPeriodeDetail: "/periode-budidaya/master-periode/:id",
  masterPeriodeTambahPeriode: "/periode-budidaya/master-periode/tambah-periode",
  masterPeriodeEditPeriode: "/periode-budidaya/master-periode/edit-periode/:id",
  masterPeriodeRegion: "/periode-budidaya/region-master-periode",
  masterPeriodeRegionDetail: "/periode-budidaya/region-master-periode/:id",
};

export const ROUTES = {
  masterPeriodeDetail: (id) => `/periode-budidaya/master-periode/${id}`,
  masterPeriodeEditPeriode: (id) =>
    `/periode-budidaya/master-periode/edit-periode/${id}`,
  masterPeriodeRegionDetail: (id) =>
    `/periode-budidaya/region-master-periode/${id}`,
};
