import {
  Card,
  CardBody,
  CardHeader,
  Typography,
} from "@material-tailwind/react";
import moment from "moment";
import "moment/locale/id";
import LoadingAnimation from "../../../../../atoms/LoadingAnimation";
moment.locale("id");


const RekapPeriodeBudidayaSelesai = ({ ResponseDetail, loading }) => {
  const dataPerformace = [
    {
      id: 1,
      label: "Umur",
      value: 0,
    },
    {
      id: 2,
      label: "FCR",
      value: ResponseDetail?.standardFcr,
    },
    {
      id: 3,
      label: "Diff FCR",
      value: ResponseDetail?.diffFcr?.toLocaleString("id-ID"),
    },
    {
      id: 4,
      label: "ADG",
      value: (
        <div>
          {ResponseDetail?.adg}{" "}
          <span className="text-sm font-normal text-neutral-500">g/hari</span>
        </div>
      ),
    },
    {
      id: 5,
      label: "IP",
      value: (
        <div>
          {ResponseDetail?.ip?.toLocaleString("id-ID")}{" "}
          <span className="text-sm font-normal text-neutral-500">kg</span>
        </div>
      ),
    },
    {
      id: 6,
      label: "Bobot rata rata",
      value: (
        <div>
          {ResponseDetail?.averageWeight / 1000}{" "}
          <span className="text-sm font-normal text-neutral-500">kg</span>
        </div>
      ),
    },
  ];

  const dataInformationFeeding = [
    {
      id: 1,
      label: "Pakan Masuk",
      value: `${ResponseDetail?.totalFeedIn?.toLocaleString("id-ID")} kg`,
    },

    {
      id: 2,
      label: "Pakan Pakai",
      value: `${ResponseDetail?.totalFeedUsed?.toLocaleString("id-ID")} kg`,
    },

    {
      id: 3,
      label: "",
      value: ``,
    },

    {
      id: 4,
      label: "Stok Pakan",
      value: `${ResponseDetail?.totalFeedStock?.toLocaleString("id-ID")} kg`,
    },
    {
        id: 5,
        label: "Mutasi Pakan",
        value: `-`,
      },
    {
      id: 6,
      label: "FI Act",
      value: `${ResponseDetail?.totalFeedIn?.toLocaleString("id-ID")} kg`,
    },
  ];

  const inforsmationDepletion = [
    {
      id: 1,
      label: "Pemisahan",
      value: `${ResponseDetail?.totalFeedStock?.toLocaleString("id-ID")} ekor`,
    },
    {
      id: 2,
      label: "Kematian",
      value: `${ResponseDetail?.totalFeedIn?.toLocaleString("id-ID")} kg`,
    },
    {
      id: 3,
      label: "",
      value: ``,
    },
    {
      id: 4,
      label: "Jumlah deplesi",
      value: `${ResponseDetail?.totalFeedUsed?.toLocaleString("id-ID")} kg`,
    },
    {
      id: 5,
      label: "Deplesi",
      value: `${ResponseDetail?.totalFeedIn?.toLocaleString("id-ID")} kg`,
    },
    {
      id: 6,
      label: "Std Deplesi",
      value: `${ResponseDetail?.totalFeedIn?.toLocaleString("id-ID")} kg`,
    },
  ];

  return (
    <div className="felx h-auto xl:overflow-hidden overflow-hidden w-full">
      <div className="flex flex-col flex-1 overflow-hidden">
        {loading ? (
          <>
            <LoadingAnimation />
          </>
        ) : (
          <div className="p-4 ml-[g20px] grid sm:grid-cols-1">
            <div className="grid grid-cols-1 gap-4">
              <div className="w-full p-4">
                <Card className="w-full border shadow-none">
                  <CardHeader
                    floated={false}
                    shadow={false}
                    className="p-2 border-none mt-1"
                  >
                    <Typography
                      className="text-base font-semibold items-center text-text-secondary"
                    >
                      Performa Produksi
                    </Typography>
                  </CardHeader>

                  <CardBody className="p-2 overflow-scroll max-w-full max-h-[960px] z-10 border-t">
                    <div className="grid grid-cols-6 gap-y-8">
                      {dataPerformace?.map((val) => (
                        <>
                          <div key={val.id} className="mt-4">
                            <div className="w-full">
                              <Typography
                                className="mt-[-10] text-left ml-[25px] text-base font-normal text-text-quarterary"
                              >
                                {val.label}
                              </Typography>
                            </div>

                            <div className="mt-1">
                              <Typography
                                className="text-left ml-[25px] text-lg font-semibold text-text-primary"
                              >
                                {val?.value}
                              </Typography>
                            </div>
                          </div>
                        </>
                      ))}
                    </div>
                    <div className="bg-neutral-100 p-3 my-4 mx-6 rounded-lg">
                      <Typography
                        className="font-normal text-sm weight-[400] text-text-quarterary"
                      >
                        Performa ini adalah akumulasi dari data harian.
                      </Typography>
                    </div>
                  </CardBody>
                </Card>
              </div>
            </div>

            <div className="grid grid-cols-2 md:grid-cols-2 sm:grid-cols-1 w-full h-auto">
              <div className="w-full p-4">
                <Card className="w-full border shadow-none">
                  <CardHeader
                    floated={false}
                    shadow={false}
                    className="p-md border-none mt-1"
                  >
                    <Typography
                      className="text-base font-semibold items-center text-text-secondary"
                    >
                      Deplesi
                    </Typography>
                  </CardHeader>

                  <CardBody className="p-0 overflow-scroll max-w-full max-h-[960px] z-10 border-t">
                    <div className="grid grid-cols-3 gap-y-6 my-4">
                      {inforsmationDepletion?.map((val) => (
                        <>
                          <div key={val.id}>
                            <div className="w-full">
                              <Typography
                                className="text-left ml-[25px] font-normal text-text-quarterary text-base"
                              >
                                {val.label}
                              </Typography>
                            </div>

                            <div className="mt-2">
                              <Typography
                                className="text-left ml-[25px] text-lg font-semibold text-text-primary"
                              >
                                {val?.value}
                              </Typography>
                            </div>
                          </div>
                        </>
                      ))}
                    </div>
                  </CardBody>
                </Card>
              </div>

              <div className="w-full p-4">
                <Card className="w-full border shadow-none">
                  <CardHeader
                    floated={false}
                    shadow={false}
                    className="p-md border-none mt-1"
                  >
                    <Typography
                      className="font-semibold items-center text-text-secondary"
                    >
                      Pakan
                    </Typography>
                  </CardHeader>

                  <CardBody className="p-0 overflow-scroll max-w-full max-h-[960px] z-10 border-t">
                    <div className="grid grid-cols-3 gap-y-6 my-4">
                      {dataInformationFeeding?.map((val) => (
                        <>
                          <div key={val.id}>
                            <div className="w-full">
                              <Typography
                                variant="h6"
                                className="text-left ml-[25px] font-normal text-text-quarterary"
                              >
                                {val.label}
                              </Typography>
                            </div>

                            <div className="mt-2">
                              <Typography
                                variant="h6"
                                color="blue-gray"
                                className="text-left ml-[25px] text-lg font-semibold text-text-primary"
                              >
                                {val?.value}
                              </Typography>
                            </div>
                          </div>
                        </>
                      ))}
                    </div>
                  </CardBody>
                </Card>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default RekapPeriodeBudidayaSelesai;
