/* eslint-disable no-sparse-arrays */
/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";

import { useNavigate, Link } from "react-router-dom";
import { useSelector } from "react-redux";

const LiveStockManager = () => {
    const { user } = useSelector((state) => state.auth);
    const navigate = useNavigate();

    useEffect(() => {
        if(user.role === "Admin Unit"){
            return navigate(`/stok/livebird/${user.unit}`);
        } else {
            return navigate("/stok/livebird-region");
        }
    }, [user]);

}

export default LiveStockManager;