import React from "react";

const Document = () => {
  return (
    <div className="p-4">
      <h2 className="text-xl font-semibold mb-4">this is Document</h2>
    </div>
  );
};

export default Document;
