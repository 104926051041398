import React from "react";

import MasterPeriodeFeature from "./feature";
import { MasterPeriodeRegionDetailStore } from "./hook";

const MasterPeriode = () => {
  return (
    <MasterPeriodeRegionDetailStore>
      <MasterPeriodeFeature />
    </MasterPeriodeRegionDetailStore>
  );
};

export default MasterPeriode;
