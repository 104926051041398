/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Select, Option } from "@material-tailwind/react";
import { CheckboxCust } from "../atoms/Checkbox";
import MenuWithCheckBox from "../atoms/MenuWithCheckBox";
import Dropdown from "../atoms/Dropdown";
import DropdownStockSearch from "../atoms/DropdownStockSearch";

const dataProvince = {
  "contents": [
    {
      "id": "14",
      "labels": "Daerah Istimewa Yogyakarta"
    },
    {
      "id": "34",
      "labels": "Papua Barat"
    },
    {
      "id": "33",
      "labels": "Papua"
    },
    {
      "id": "23",
      "labels": "Kalimantan Timur"
    },
    {
      "id": "12",
      "labels": "Jawa Barat"
    },
    {
      "id": "29",
      "labels": "Gorontalo"
    },
    {
      "id": "7",
      "labels": "Bengkulu"
    },
    {
      "id": "32",
      "labels": "Maluku Utara"
    },
    {
      "id": "8",
      "labels": "Lampung"
    },
    {
      "id": "13",
      "labels": "Jawa Tengah"
    },
    {
      "id": "5",
      "labels": "Jambi"
    },
    {
      "id": "9",
      "labels": "Kepulauan Bangka Belitung"
    },
  ],
};

const TestingComponent = () => {
  const [selectId, setSelectId] = useState(null)

  // console.log("select id", selectId);
  return (
    <div>
      <Dropdown
        size="xsmall"
        options={dataProvince.contents}
      />
    </div>
  );
};

export default TestingComponent;
