/* eslint-disable react/jsx-no-undef */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Breadcrumbs } from "@material-tailwind/react";
import {ReactComponent as HomeBreadCrump} from '../assets/icons/home_breadcrump.svg'
import { ReactComponent as IconRight } from '../assets/icons/chevron-right.svg'
import { Link } from "react-router-dom";

 
const BreadCrumbs = ({ 
  items, 
}) => {
  items = items.filter((val) => val.label !== "" && val.label !== null)

  // console.log("items mapping", items.map((val) => val.label === "Panen"))
  return (
    <Breadcrumbs
      separator={<IconRight />}
      className="bg-transparent"
    >
      <Link to="/dashboard" className="flex px-md items-center cursor-pointer">
        <HomeBreadCrump />
      </Link>

      {items?.map((val, index) => (
        <div key={val?.label} className="flex items-center">
          <Link
            to={val.link}
            className={`flex px-md items-center ${
              ["Panen", "Dashboard", "Kandang", "Mutasi"].includes(val.label)
                ? "text-gray-500 hover:text-gray-700 hover:font-extrabold cursor-pointer" 
                : index === items.length - 1
                ? "text-[#242629] font-extrabold bg-[#F8F8F8]" 
                : index === 0
                ? "text-gray-500 cursor-default hover:text-gray-500" 
                : "text-gray-500 hover:text-gray-700" 
            }`}
          >
            {val?.label}
          </Link>

          {!val?.url ? null : (
            <Link
              to={val?.label}
              className={`${val.style} flex px-4 items-center cursor-not-allowed`}
            >
              {val?.label}
            </Link>
          )}
        </div>
      ))}


    </Breadcrumbs>

  );
}

export default BreadCrumbs

