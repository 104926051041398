import React from "react";

import { Typography } from "@material-tailwind/react";

import { cn, formatIDR } from "../../shared/utils";

const TableCellItem = (props) => {
  const {
    variant = "text",
    value,
    valueTopSubText,
    valueBottomSubText,
    isNumber = true,
    customClass,
    customClassTopSubText,
    customClassBottomSubText,
  } = props;

  switch (variant) {
    case "text": {
      return (
        <Typography
          className={cn(
            "w text-sm font-medium leading-tight text-text-primary",
            customClass,
            isNumber && "text-right",
          )}
        >
          {isNumber ? value.toLocaleString("id-ID") : value}
        </Typography>
      );
    }
    case "withSubText": {
      return (
        <div>
          <Typography
            className={cn(
              "text-xs font-normal leading-tight text-text-quarterary",
              customClassTopSubText,
            )}
          >
            {valueTopSubText}
          </Typography>
          <Typography
            className={cn(
              "text-sm font-medium leading-tight text-text-primary",
              customClass,
            )}
          >
            {value}
          </Typography>
          <Typography
            className={cn(
              "text-xs font-normal leading-tight text-text-quarterary",
              customClassBottomSubText,
            )}
          >
            {valueBottomSubText}
          </Typography>
        </div>
      );
    }
    case "withTopText": {
      return (
        <div>
          <Typography
            className={cn(
              "text-xs font-normal leading-tight text-text-quarterary",
              customClassTopSubText,
            )}
          >
            {valueTopSubText}
          </Typography>
          <Typography
            className={cn(
              "text-sm font-medium leading-tight text-text-primary",
              customClass,
            )}
          >
            {value}
          </Typography>
        </div>
      );
    }
    case "withBottomText": {
      return (
        <div className="py-1">
          <Typography
            className={cn(
              "text-sm font-medium leading-tight text-text-primary",
              customClass,
            )}
          >
            {value}
          </Typography>
          <Typography
            className={cn(
              "text-xs leading-tight text-text-quarterary",
              customClassBottomSubText,
            )}
          >
            {valueBottomSubText}
          </Typography>
        </div>
      );
    }
    case "actionLink": {
      return (
        <Typography
          className={cn(
            "text-sm font-medium leading-tight text-text-infoPrimary",
            customClass,
          )}
        >
          {value}
        </Typography>
      );
    }
    case "disable-currency": {
      return (
        <div
          className={cn(
            "inset-0 bg-[#F1F1F1] text-sm font-medium",
            customClass,
          )}
        >
          <p className="flex h-full w-full items-center justify-end px-3">
            {value === 0 ? "-" : formatIDR(value)}
          </p>
        </div>
      );
    }
    default: {
      return (
        <Typography
          className={cn(
            "text-sm font-medium leading-tight text-text-primary",
            customClass,
            isNumber && "text-right",
          )}
        >
          {isNumber ? value.toLocaleString("id-ID") : value}
        </Typography>
      );
    }
  }
};

export default TableCellItem;
