/* eslint-disable no-const-assign */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-self-compare */
/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Typography,
  Switch,
} from "@material-tailwind/react";
import { ReactComponent as ChevronLeft } from "../../assets/icons/chevron-left.svg";
import CustomDatePicker from "../../atoms/DatepickerForm";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ReactComponent as PlusBlus } from "../../assets/icons/plus_blue.svg";
import { Button } from "../../atoms/Button";
import api from "../../api/configApi";
import ConfirmDialog from "../../atoms/ConfirmDialog";
import DropdownSapronak from "../../atoms/DropdownSapronak";
import moment from "moment";
import InputNumber from "../../atoms/NumberFormat";
import DefaultImagePakan from '../../img/Pakan_image.png'

const FormFeed = () => {
  const [formData, setFormData] = useState([{ qtyIn: "", price: "" }]);
  const [formDataConvert, setFormDataConvert] = useState([
    { qtyIn: "", price: "" },
  ]);
  const navigate = useNavigate();
  const { id } = useParams();
  const [selectedDate, setSelectedDate] = useState(null);
  const [openConfirm, setOpenConfirm] = useState(false);
  // const [search, setSearch] = useState("");
  const [responseOptions, setResponseOptions] = useState([]);
  const [success, setSuccess] = useState(false);
  const [selectId, setSelectId] = useState([]);
  const [error, setError] = useState("");
  const [satuanArray, setSatuanArray] = useState([false]);
  const [searchProduct, setSearchProduct] = useState([""]);
  const [indexActive, setIndexActive] = useState(0);
  const disabled = formData.map((form) => !form.feed || !form.qtyIn || !form.price || !selectedDate);

  const handleSwitchChange = (index) => {
    setSatuanArray((prevSatuan) => {
      const updatedSatuan = [...prevSatuan];
      updatedSatuan[index] = !updatedSatuan[index];
      return updatedSatuan;
    });
  };

  const handleAddForm = () => {
    setFormData((prevFormdata) => [...prevFormdata, { qtyIn: "", price: "" }]);
    setFormDataConvert((prevFormdataConvert) => [
      ...prevFormdataConvert,
      { qtyIn: "", price: "" },
    ]);

    setSearchProduct((prevSearchProduct) => {
      const updatedSearchProduct = [...prevSearchProduct];
      updatedSearchProduct.splice(indexActive + 1, 0, "");
      return updatedSearchProduct;
    });

    setSatuanArray((prevSatuanArray) => [...prevSatuanArray, false]);
  };

  const handleDeleteForm = (index) => {
    // console.log("id", index);
    setFormData(formData?.filter((item, i) => i !== index));
    setFormDataConvert(formDataConvert?.filter((item, i) => i !== index));
    setSelectId(
      selectId
        .filter((item, i) => i !== index)
        .concat(selectId.slice(index + 1))
    );
  };

  const handleChange = (event, index, field) => {
    setFormData((prevFormdata) => {
      const newFormData = [...prevFormdata];
      let updatedValue = event.target.value;

      // Jika field adalah qtyIn, ubah ke integer dan hapus tanda "."
      if (field === "qtyIn" || field === "price") {
        updatedValue = parseInt(event.target.value.replace(/\./g, ""), 10);
      }

      newFormData[index][field] = updatedValue;

      return newFormData;
    });

    setFormDataConvert((prevFormdataConvert) => {
      const newFormDataConvert = [...prevFormdataConvert];
      let updatedValueConvert = event.target.value;

      // console.log(field);

      if (field === "qtyIn" || field === "price") {
        updatedValueConvert = parseInt(event.target.value.replace(/\./g, ""), 10);
      }

      if (field === "qtyIn") {
        // console.log("satuan array: " + satuanArray[index]);
        if (satuanArray[index] === false) {
          newFormDataConvert[index][field] = updatedValueConvert * 50;
        } else {
          newFormDataConvert[index][field] = updatedValueConvert;
        }
      } else {
        newFormDataConvert[index][field] = updatedValueConvert;
      }

      return newFormDataConvert;
    });
  };

  useEffect(() => {
    const dataFetchingListProduk = async () => {
      try {
        const responseDropdown = await api.get("/v1/feeds/dropdown", {
          params: {
            search: searchProduct[indexActive],
            // exclude: selectId.join(","),
          },
        });

        setResponseOptions(responseDropdown.data);
      } catch (error) {
        console.log(error);
      }
    };

    dataFetchingListProduk();
  }, [id, searchProduct, indexActive, selectId]);

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log(formData);
  };

  const handleSelect = (event, index) => {
    // Tambahkan id produk feed yang dipilih ke dalam string
    const selectedFeedId = event.id.toString();

    setSelectId((prevIds) => {
      const newIds = [...prevIds];
      newIds[index] = selectedFeedId;
      return newIds;
    });

    setFormData((prevFormData) =>
      prevFormData.map((item, idx) => ({
        ...item,
        feed: {
          ...item.feed,
          id: idx === index ? event.id : item.feed.id,
        },
      }))
    );

    setFormDataConvert((prevFormDataConvert) =>
      prevFormDataConvert.map((item, idx) => ({
        ...item,
        feed: {
          ...item.feed,
          id: idx === index ? event.id : item.feed.id,
        },
      }))
    );
  };

  const handleSetSearchProduct = (value, index) => {
    setSearchProduct((prevSearchProduct) => {
      const newSearchProduct = [...prevSearchProduct];
      newSearchProduct[index] = value;
      return newSearchProduct;
    });
    setIndexActive(index);
    // handleSetActiveIndex(index);
  };

  const handleChangeAddFeed = async () => {
    let reqBody = {
      feedInList: formDataConvert,
      rearingPeriod: { id: id },
      dateIn: moment(selectedDate?.$d).format("YYYY-MM-DD"),
    };

    try {
      const addFeedRequest = await api.post("/v1/feeds/in/bulk", reqBody);
      setSuccess(true);
      localStorage.setItem("successNotifAddFeed", JSON.stringify(!success));
      // console.log(addFeedRequest);
      navigate(`/periode-budidaya/periode-berjalan/${id}/sapronak`);
      localStorage.setItem("settingType", "sapronak");
      localStorage.setItem("settingTypeSapronak", "pakan");
    } catch (error) {
      console.log(error);
      setError(error.message);
    }
  };

  const handleBack = () => {
    navigate(`/periode-budidaya/periode-berjalan/${id}/sapronak`);
    localStorage.setItem("settingType", "sapronak");
    localStorage.setItem("settingTypeSapronak", "pakan");
  };

  // console.log("form data convert:", formDataConvert);
  // console.log("satuan array:", satuanArray);
  // console.log(selectId);

  const handleSelectDate = (date) => {
    setSelectedDate(date);
  };


  const disableDate = (current) => {
    return current && current.isAfter(new Date(), 'day');
  }

  return (
    <div className="flex h-auto xl:overflow-hidden overflow-hidden">
      <div className="flex flex-col flex-1 overflow-hidden">
        <div>
          <Card className="w-max-md flex justify-center item-center border border-border-secondary shadow-none m-0 p-0">
            <CardHeader
              floated={false}
              shadow={false}
              className="p-3xl m-0 border-none"
            >
              <div className="flex justify-start">
                <div className="mt-1 mr-sm cursor-pointer" onClick={handleBack}>
                  <ChevronLeft />
                </div>
                <Typography
                  className="text-xl text-text-primary font-bold"
                >
                  Tambah Pakan
                </Typography>
              </div>
            </CardHeader>

            <CardBody className="p-3xl m-0">
              <div>
                <Typography
                  className="text-text-primary text-sm font-semibold leading-tight"
                >
                  Tanggal Masuk
                </Typography>

                <CustomDatePicker
                  placeholder="Pilih Tanggal"
                  className="w-full text-text-primary px-sm py-xs justify-start items-center gap-xxs inline-flex focus:outline-none focus:border-primary-400 focus:ring focus:ring-primary-200 mt-3"
                  format={"DD MMMM YYYY"}
                  value={selectedDate}
                  onChange={handleSelectDate}
                  disableDate={disableDate}
                />
              </div>
              <hr className="border-t border-border-secondary mx-1 my-3xl" />

              <div>
                <div className="mx-auto w-full bg-bg-secondary border border-border-primary rounded-sm">
                  <div className="p-3xl">
                    <form onSubmit={handleSubmit}>
                      {formData?.map((form, index) => (
                        <>
                          <div className="flex justify-between" key={index}>
                            <div>
                              <Typography
                                className="text-text-primary font-semibold text-sm leading-tight"
                              >
                                Produk
                              </Typography>
                            </div>

                            {index !== 0 && (
                            <div>
                              <div
                                className="flex justify-start gap-2 cursor-pointer mr-1"
                                onClick={() => handleDeleteForm(index)}
                              >
                                <div>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                  >
                                    <path
                                      d="M6 2H10M2 4H14M12.6667 4L12.1991 11.0129C12.129 12.065 12.0939 12.5911 11.8667 12.99C11.6666 13.3412 11.3648 13.6235 11.0011 13.7998C10.588 14 10.0607 14 9.00623 14H6.99377C5.93927 14 5.41202 14 4.99889 13.7998C4.63517 13.6235 4.33339 13.3412 4.13332 12.99C3.90607 12.5911 3.871 12.065 3.80086 11.0129L3.33333 4M6.66667 7V10.3333M9.33333 7V10.3333"
                                      stroke="#D92D20"
                                      stroke-width="1.33333"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                </div>

                                <div>
                                  <Typography
                                    type="small"
                                    className="text-[#D92D20] text-sm font-medium leading-tight"
                                  >
                                    Hapus{" "}
                                  </Typography>
                                </div>
                              </div>
                            </div>
                            )}
                          </div>

                          <div className="mt-md">
                            <DropdownSapronak
                              size="medium"
                              placeholder="Cari Produk"
                              defaultProduct={DefaultImagePakan}
                              search={searchProduct[index]}
                              options={responseOptions.contents}
                              onSelect={(selectedFeed) =>
                                handleSelect(selectedFeed, index)
                              }
                              setSearch={(value) =>
                                handleSetSearchProduct(value, index)
                              }
                            />

                            <div className="flex justify-between w-full my-3xl p-1 gap-4">
                              <div className="w-[100%]">
                                <div className="grid grid-cols-2">
                                  <Typography
                                    className="text-text-primary font-semibold text-sm leading-tight mb-md"
                                  >
                                    Kuantitas
                                  </Typography>
                                  <div className="flex items-center gap-2 justify-end pr-1 mb-md">
                                    <label className="block text-text-secondary text-sm font-medium leading-tight">
                                      zak
                                    </label>
                                    <Switch
                                      className="bg-primary-500 checked:bg-primary-500"
                                      onChange={() => handleSwitchChange(index)}
                                      checked={satuanArray[index]}
                                    />
                                    <label className="block text-text-secondary text-sm font-medium leading-tight">
                                      kg
                                    </label>
                                  </div>
                                </div>

                                <InputNumber
                                  id={`kuantitas-${form.id}`}
                                  placeholder="Masukan Kuantitas"
                                  size="medium"
                                  className="w-[120px]"
                                  value={form.qtyIn}
                                  onChange={(e) =>
                                    handleChange(e, index, "qtyIn")
                                  }
                                  componentRight={
                                    <Typography
                                      type="small"
                                      className="text-gray-500 text-md font-medium leading-tight"
                                    >
                                      {satuanArray[index] === false
                                        ? "zak"
                                        : "kg"}
                                    </Typography>
                                  }
                                />
                              </div>

                              <div className="w-[100%]">
                                <Typography
                                  className="text-text-primary font-semibold text-sm leading-tight mb-md"
                                >
                                  Harga satuan per (kg)
                                </Typography>
                                <InputNumber
                                  type="text"
                                  id="hargaSatuan"
                                  placeholder="Masukkan harga satuan"
                                  className="max-w-full"
                                  value={form.price}
                                  size="medium"
                                  // decimalScale={2}
                                  onChange={(e) =>
                                    handleChange(e, index, "price")
                                  }
                                  componentLeft={
                                    <Typography
                                      type="small"
                                      className="text-gray-500 text-md font-medium leading-tight"
                                    >
                                      Rp
                                    </Typography>
                                  }
                                />
                              </div>
                            </div>
                          </div>

                          <hr className="border-t border-border-secondary my-3xl w-full" />
                        </>
                      ))}
                      <div
                        className="flex justify-start gap-2 cursor-pointer"
                        onClick={handleAddForm}
                      >
                        <div>
                          <PlusBlus />
                        </div>

                        <div>
                          <Typography
                            className="text-[#1570EF] text-base font-medium leading-tight"
                          >
                            Tambah produk
                          </Typography>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </CardBody>

            <CardFooter className="flex justify-end border-t border-border-secondary gap-4 p-3 mr-4">
              <Button
                label="Batal"
                type="btnSecondary"
                size="medium"
                onClick={handleBack}
                // disabled={disabled[0]
                //   ? "btnPrimaryDisabled"
                //   : "btnPrimary"
                // }
              />

              <Button
                label="Simpan"
                size="medium"
                type={disabled[0]
                  ? "btnPrimaryDisabled"
                  : "btnPrimary"
                }
                disabled={disabled[0]}
                onClick={() => setOpenConfirm(!openConfirm)}
              />
            </CardFooter>
          </Card>

          <ConfirmDialog
            title="Konfirmasi Simpan Data Sapronak"
            content="Pastikan Semua informasi sudah benar sebelum melanjutkan"
            isOpen={openConfirm}
            onClose={() => setOpenConfirm(!openConfirm)}
            btnLabel="Simpan"
            onConfirm={handleChangeAddFeed}
          />
        </div>
      </div>
    </div>
  );
};

export default FormFeed;
