/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import {
  Card,
  CardBody,
  CardHeader,
  Typography,
} from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import TablePanen from "../../../molecules/tablePanenV2/tablePanen";
import api from "../../../api/configApi";

const Panen = () => {
  const [settingType, setSettingtype] = useState("DataIn");
  const [unit, setUnit] = useState([]);
  const [lastLabelUnits, setLastLabelUnits] = useState("");
  const [checkedUnits, setCheckedUnits] = useState([]);
  const [checkedUnitsString, setCheckedUnitsString] = useState("");
  const [searchUnit, setSearchUnit] = useState("");

  const statusPanen = [
    {
      id: 1,
      value: "WAITING_FOR_REVIEW",
      labels: ["Menunggu review"],
      labelsPlaceholder: "Menunggu review",
    },
    {
      id: 2,
      value: "ON_REVIEW",
      labels: ["Sedang direview"],
      labelsPlaceholder: "Sedang di review",
    },
    {
      id: 3,
      value: "DONE_REVIEW",
      labels: ["Selesai direview"],
      labelsPlaceholder: "Selesai di review",
    },
  ];

  useEffect(() => {
    const fetchMasterUnit = async () => {
      try {
        const params = {
          search: searchUnit,
          descending: false
        };
        const responseList = await api.get("/v1/units/dropdown", { params });
        setUnit(responseList?.data?.contents);
      } catch (error) {
        console.log(error.message);
      }
    };

    fetchMasterUnit();
  }, [searchUnit]);

  useEffect(() => {
    const responseSuccess = JSON.parse(
      localStorage.getItem("response success")
    );

    if (responseSuccess === 200) {
      setSettingtype("finishing");
    }
  }, []);

  return (
    <div className={`flex h-auto overflow-hidden`}>
      <div className="flex flex-col flex-1 overflow-hidden">
        <div className="p-4">
          <Card className="h-full w-full border border-neutral-300 shadow-none">
            <CardHeader
              floated={false}
              shadow={false}
              className="p-3xl m-0 border-none"
            >
              <Typography className="text-xl text-text-primary font-bold leading-[30px]">
                Panen
              </Typography>
            </CardHeader>
            <div className="border-t border-gray-300 w-full" />

            <CardBody className="p-0 overflow-scroll max-h-[860px]">
              <div className="w-full flex justify-start">
                <TablePanen
                  optionsUnit={unit}
                  optionStatus={statusPanen}
                  lastLabelUnits={lastLabelUnits}
                  setLastLabelUnits={setLastLabelUnits}
                  searchUnit={searchUnit}
                  setSearchUnit={setSearchUnit}
                  checkedUnits={checkedUnits}
                  setCheckedUnits={setCheckedUnits}
                  setCheckedUnitsString={setCheckedUnitsString}
                />
              </div>
            </CardBody>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default Panen;
