import React from "react";

import PropTypes from "prop-types";

import { cn } from "../../shared/utils";

const TableFooterItem = ({ value, isNumber = true, customClass }) => {
  return (
    <p
      className={cn(
        "text-sm font-bold leading-tight text-text-primary",
        customClass,
        isNumber && "text-right",
      )}
    >
      {isNumber ? value?.toLocaleString("id-ID") : value}
    </p>
  );
};

TableFooterItem.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default TableFooterItem;
