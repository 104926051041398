import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  Typography,
} from "@material-tailwind/react";
import moment from "moment";
import "moment/locale/id";
import AlertNotification from "../../../../../atoms/AlertNotification";
import { FaCheckCircle } from "react-icons/fa";
import LoadingAnimation from "../../../../../atoms/LoadingAnimation";
import { Button } from "../../../../../atoms/Button";
import ConfirmDialog from "../../../../../atoms/ConfirmDialog";
import api from "../../../../../api/configApi";
import * as Sentry from '@sentry/react'
moment.locale("id");

const Rekapitulasi = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isStopDialogOpen, setStopDialogOpen] = useState(false);
  const [isStopSuccess, setStopSuccess] = useState(false);
  const [isStopError, setIsStopError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [totalPurchaseFeed, setTotalPurchaseFeed] = useState();
  const [totalPurchaseOVK, setTotalPurchaseOVK] = useState();
  const [totalPurchaseDoc, setTotalPurchaseDoc] = useState();
  const [totalPurchase, setTotalPurchase] = useState();

  useEffect(() => {
    const fetchRekap = async () => {
      try {
        setLoading(true);
        const response = await api.get(`/v1/rearingPeriods/${id}`);
        setTotalPurchaseDoc(response.data.totalPurchaseDoc);
        setTotalPurchaseFeed(response.data.totalPurchaseFeed);
        setTotalPurchaseOVK(response.data.totalPurchaseOvk);
        setTotalPurchase(response.data.totalPurchase);
      } catch (error) {
        Sentry.captureException(error)
      } finally {
        setLoading(false);
      }
    };

    fetchRekap();
  }, [id]);

  const handleOpenStopDialog = () => {
    setStopDialogOpen(true);
  };

  const handleCloseStopDialog = () => {
    setStopDialogOpen(false);
  };

  const saveFinishPeriod = async () => {
    try {
      const response = await api.put(`/v1/rearingPeriods/${id}/stop`);
      // console.log("Berhasil mengakhiri periode.", response);
      setStopSuccess(true);
      handleCloseStopDialog();

      navigate(`/periode-budidaya/periode-selesai`);
      localStorage.setItem(
        "responseSuccessStopPeriod",
        JSON.stringify(!isStopSuccess)
      );

      return response;
    } catch (error) {
      Sentry.captureException(error)
      handleCloseStopDialog();
      setIsSubmitting(false);
      console.error("Gagal mengakhiri periode budidaya", error.response);
      setIsStopError(true);
      setErrorMessage(
        error.response.data.error?.name || error.response.data.error
      );
      setTimeout(() => setIsStopError(false), 2000);

      setIsStopError(true);
      setErrorMessage(
        error.response?.data?.error?.name || error.response?.data?.error
      );
      setTimeout(() => setIsStopError(false), 2000);
    }
  };
  return (
    <div className="felx h-auto xl:overflow-hidden overflow-hidden w-full">
      <div className="flex flex-col flex-1 overflow-hidden">
        {loading ? (
          <>
            <div className="flex justify-center items-center h-screen">
              <LoadingAnimation />
            </div>
          </>
        ) : (
          <div className="grid grid-cols-2 w-full h-auto">
            <div className="w-full p-4">
              <Card className="w-full border shadow-none p-0 m-0">
                <CardHeader floated={false} shadow={false} className="m-0 p-4">
                  <Typography
                    className="text-base text-left font-semibold text-text-secondary"
                  >
                    Detail transaksi
                  </Typography>
                </CardHeader>

                <CardBody className="p-4 m-0 overflow-scroll max-w-full max-h-[960px] border-t z-10">
                  <div className="grid grid-cols-3 gap-y-4">
                    <div className="col-span-1 font-normal text-text-secondary text-base">Pembelian DOC</div>
                    <div className="col-span-1"></div>
                    <div className="col-span-1 text-text-secondary text-base">
                      {new Intl.NumberFormat("id-ID", {
                        style: "currency",
                        currency: "IDR",
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      }).format(totalPurchaseDoc)}
                    </div>
                    <div className="col-span-1 font-normal text-text-secondary text-base">Pembelian Pakan</div>
                    <div className="col-span-1"></div>
                    <div className="col-span-1 text-text-secondary text-base">
                      {new Intl.NumberFormat("id-ID", {
                        style: "currency",
                        currency: "IDR",
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      }).format(totalPurchaseFeed)}
                    </div>
                    <div className="col-span-1 font-normal text-text-secondary">Pembelian OVK</div>
                    <div className="col-span-1"></div>
                    <div className="col-span-1 text-text-secondary text-base">
                      {new Intl.NumberFormat("id-ID", {
                        style: "currency",
                        currency: "IDR",
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      }).format(totalPurchaseOVK)}
                    </div>
                  </div>
                  <div className="grid grid-cols-3 mb-2 mt-8 border p-2 bg-primary-25 border-primary-400 rounded-md text-gray-900">
                    <div className="col-span-1 text-text-secondary">Total Belanja</div>
                    <div className="col-span-1"></div>
                    <div className="col-span-1 font-semibold text-text-secondary">
                      {new Intl.NumberFormat("id-ID", {
                        style: "currency",
                        currency: "IDR",
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      }).format(totalPurchase)}
                    </div>
                  </div>
                </CardBody>
              </Card>
              <Card className="w-full border-none shadow-none pt-4">
                <Button
                  label="Akhiri Periode"
                  onClick={handleOpenStopDialog}
                  disabled={isSubmitting}
                  size="medium"
                  type={`${isSubmitting ? "btnPrimaryDisabled" : "btnPrimary"}`}
                />
              </Card>
            </div>
          </div>
        )}
      </div>
      {/* Komponen dialog konfirmasi edit*/}
      <ConfirmDialog
        isOpen={isStopDialogOpen}
        onClose={handleCloseStopDialog}
        onConfirm={saveFinishPeriod}
        title="Konfirmasi akhiri periode"
        content="Apakah anda yakin akan mengakhiri periode budidaya?"
        btnLabel="Ya, lanjutkan"
        btnType="btnPrimary"
      />
      {/* Alert untuk notifikasi error */}
      {isStopError && (
        <AlertNotification
          icon={<FaCheckCircle />}
          color="red"
          message={errorMessage}
        />
      )}
    </div>
  );
};

export default Rekapitulasi;
