import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  checkedProvinces: [],
  lastLabelProvince: "",
  checkedProvincesString: "",
  checkedCities: [],
  lastLabelCities: "",
  checkedCitiesString: "",
  checkedRegions: [],
  lastLabelRegion: "",
  checkedRegionsString: "",
  checkedUnits: [],
  lastLabelUnit: "",
  checkedUnitsString: "",
  selectedUnit: null,
  selectedUnitId: "",
  selectedPpl: null,
  selectPpllabels: null,
  initialCapacity: null,
  finalCapacity: null,
  implementFilter: false,
  reloadFilter: false,
  countFilter: 0,
};

const filterRearingPeriodSlice = createSlice({
  name: 'filterRearingPeriod',
  initialState,
  reducers: {
    setCheckedProvinces: (state, action) => {
      state.checkedProvinces = action.payload;
    },
    setLastLabelProvince: (state, action) => {
      state.lastLabelProvince = action.payload;
    },
    setCheckedProvincesString: (state, action) => {
      state.checkedProvincesString = action.payload;
    },
    setCheckedCities: (state, action) => {
      state.checkedCities = action.payload;
    },
    setLastLabelCities: (state, action) => {
      state.lastLabelCities = action.payload;
    },
    setCheckedCitiesString: (state, action) => {
      state.checkedCitiesString = action.payload;
    },
    setCheckedRegions: (state, action) => {
      state.checkedRegions = action.payload;
    },
    setLastLabelRegion: (state, action) => {
      state.lastLabelRegion = action.payload;
    },
    setCheckedRegionsString: (state, action) => {
      state.checkedRegionsString = action.payload;
    },
    setCheckedUnits: (state, action) => {
      state.checkedUnits = action.payload;
    },
    setLastLabelUnit: (state, action) => {
      state.lastLabelUnit = action.payload;
    },
    setCheckedUnitsString: (state, action) => {
      state.checkedUnitsString = action.payload;
    },
    setSelectedUnit: (state, action) => {
      state.selectedUnit= action.payload;
    },
    setSelectedPpl: (state, action) => {
      state.selectedPpl = action.payload;
    },
    setSelectPpllabels: (state, action) => {
      state.selectedPpl = action.payload
    },
    setSelectedUnitId: (state, action) => {
      state.selectedUnitId= action.payload;
    },
    setInitialCapacity: (state, action) => {
      state.initialCapacity = action.payload;
    },
    setFinalCapacity: (state, action) => {
      state.finalCapacity = action.payload;
    },
    setImplementFilter: (state, action) => {
      state.implementFilter = action.payload;
    },
    setReloadFilter: (state, action) => {
      state.reloadFilter = action.payload;
    },
    setCountFilter: (state, action) => {
      state.countFilter = action.payload;
    },
    resetFilters: (state) => {
      state.checkedProvinces = [];
      state.lastLabelProvince = "";
      state.checkedProvincesString = "";
      state.checkedCities = [];
      state.lastLabelCities = "";
      state.checkedCitiesString = "";
      state.checkedRegions = [];
      state.lastLabelRegion = "";
      state.checkedRegionsString = "";
      state.checkedUnits = [];
      state.lastLabelUnit = "";
      state.checkedUnitsString = "";
      state.selectedUnit = "";
      state.selectedUnitId= "";
      state.selectedPpl = ""
      state.initialCapacity = "";
      state.finalCapacity = "";
      state.implementFilter = false;
      state.reloadFilter = false;
      state.countFilter = 0;
      
    },
  },
});

export const {
  setCheckedProvinces,
  setLastLabelProvince,
  setCheckedProvincesString,
  setCheckedCities,
  setLastLabelCities,
  setCheckedCitiesString,
  setCheckedRegions,
  setLastLabelRegion,
  setCheckedRegionsString,
  setCheckedUnits,
  setLastLabelUnit,
  setCheckedUnitsString,
  setSelectedUnit,
  setSelectedUnitId,
  selectedPpl,
  setSelectedPpl,
  setInitialCapacity,
  setFinalCapacity,
  setImplementFilter,
  setReloadFilter,
  setCountFilter,
  resetFilters,
  setSelectPpllabels
} = filterRearingPeriodSlice.actions;

export default filterRearingPeriodSlice.reducer;
