/* eslint-disable no-unused-vars */
// import { Card } from "@material-tailwind/react"
import { Card, Typography } from "@material-tailwind/react";
import { Logo } from "../../../atoms/Logo";
import Input from "../../../atoms/Input";
import { Button } from "../../../atoms/Button";
import forgotImg from "../../../img/Forgot password.png";
import { useState } from "react";
import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import AskOtp from "../forgotPasswordV2/ButtonAskOptSession";
import FormVerifOTP from "./FormVerifOTP";
import FormChangePassword from "./FormChangePassword";
import api from '../../../api/configApi'
import PageChangeeSuccess from "./ChangeSuccessPage";

const ForgotPassword = () => {
  const navigate = useNavigate()
  const [emailPhone, setEmailPhone] = useState("")
  const [showOtpPassword, setShowOtpPasswowrd] = useState(false)
  const [showModalOtpVerif, setShowModalOtpVerif] = useState(false)
  const [showFormChange, setShowFormChange] = useState(false)
  const [showSucees, setShowSuccess] = useState(false)
  const [typeOTPVerification, setTypeOTPVerification] = useState("");
  const [receiverOTP, setReceiverOTP] = useState("");
  const [otpSession, setOtpSession] = useState("");
  const [isNumber, setIsNumber] = useState(false)
  const [userName, setUserName] = useState("")
  const [showError, setShowError] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [responseOtp, setResponseOtp] = useState(null)
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isPhoneValid, setIsPhoneValid] = useState(false);
  const phoneRegex = /^(?:\+62|62)\d{9,11}$/;

  const isDisabled = !userName

  const handleChangeUsername = (e) => {
    let value = e.target.value;
  
    if (/^08/.test(value)) {
      value = value.replace(/^08/, "62");
    }

    setUserName(value);
  
    // Regex for email validation
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const isValidEmail = emailRegex.test(value) && value.includes(".com"); // Ensure domain contains ".com"
    setIsEmailValid(isValidEmail);
  
    const phoneRegex = /^(?:\+62|62)\d{9,11}$/; 
    const isValidPhone = phoneRegex.test(value);
    setIsPhoneValid(isValidPhone);

  };

  const isButtonDisabled = !isEmailValid && !isPhoneValid;

  const handleNext = async () => {
    // setShowModalOtpVerif(!showModalOtpVerif)

    let proccessedUsername = userName
    if (/^08\d+/.test(proccessedUsername)) {
      proccessedUsername = proccessedUsername.replace(/^08/, "62")
    }


    try {
      const newUsernamePassword = {
        username: userName
      }
      localStorage.setItem("newUsernamePassword", JSON.stringify(newUsernamePassword));

      const response = await api.post("/v1/auth/verifyCreds", newUsernamePassword)
      setResponseOtp(response)
      setShowModalOtpVerif(!showModalOtpVerif)
    } catch (error) {
      setShowError(true)
      setTimeout(() => {
        setShowError(false);
      }, 3000);
      setErrorMessage(error?.response?.data?.error)
    }
  }

  const handleBack = () => {
    setShowOtpPasswowrd(false)
  }

  return (
    <div className="flex flex-col md:flex-row h-screen relative">
      <div className="w-full bg-white md:p-10 flex flex-col justify-between relative">
        <Logo />
    
        {!showOtpPassword && !showModalOtpVerif && (
          <div className="flex justify-center items-center md:min-h-[700px] min-h-[500px] overflow-hidden">
            <Card className="w-[484px] max-w-[484px] md:min-w-sm shadow-none md:shadow-md md:border-t-2 md:border-gray-100 rounded-xl md:p-4xl p-xs">
              <div
                className="items-start cursor-pointer"
                onClick={() => navigate('/')}
              >
                <ArrowLeftIcon strokeWidth={2} className="h-4 w-4" />
              </div>
    
              <div className="w-[150px] h-[150px] flex justify-center items-center ml-[150px]">
                <img src={forgotImg} alt="forgot-password-img" />
              </div>
    
              <Typography
                type="h2"
                className="text-xl font-bold text-neutral-900 mb-xs text-center"
              >
                Lupa Kata Sandi
              </Typography>
    
              <Typography
                type="small"
                className="text-sm font-normal text-neutral-500 mb-4xl text-center"
              >
                Masukkan email atau nomor HP terdaftar. Kami akan mengirimkan kode
                verifikasi untuk atur ulang kata sandi.
              </Typography>
    
              <div className="mb-2xl text-center">
                <input
                  className={`border rounded p-2 w-full ${
                    showError ? "border-red-500" : "border-gray-300"
                  }`}
                  size="medium"
                  placeholder="Nomor HP atau Email"
                  value={userName}
                  onChange={handleChangeUsername}
                />

                {showError && (
                  <Typography
                    type="small"
                    className="text-sm text-start font-normal text-red-500 mt-[11px] mb-4"
                  >
                    {errorMessage}
                  </Typography>
                )}
              </div>
    
              <Button
                label="Lanjutkan"
                size="medium"
                disabled={isDisabled || isButtonDisabled}
                type={isDisabled || isButtonDisabled ? "btnPrimaryDisabled" : "btnPrimary"}
                onClick={handleNext}
              />
            </Card>
          </div>
        )}
    
        {showModalOtpVerif && (
          <>
            <div className="absolute inset-0 bg-transparent bg-opacity-50 z-40"></div>
            <div className="absolute inset-0 z-50 flex justify-center items-center">
              <AskOtp
                onClose={() => setShowModalOtpVerif(!showModalOtpVerif)}
                onNext={() => setShowOtpPasswowrd(!showOtpPassword)}
                response={responseOtp}
                loadingProcces={false}
              />
            </div>
          </>
        )}
    
        {showOtpPassword && (
          <>
            <div className="absolute inset-0 bg-transparent bg-opacity-50 z-40"></div>
            <div className="absolute inset-0 z-50 flex justify-center items-center">
              <FormVerifOTP
                type={typeOTPVerification}
                receiverOTP={receiverOTP}
                otpSession={otpSession}
                onBack={handleBack}
                isNumber={isNumber}
                isNext={() => setShowFormChange(!showFormChange)}
              />
            </div>
          </>
        )}

        {showFormChange && (
          <>
             <div className="absolute inset-0 bg-transparent bg-opacity-50 z-40"></div>
             <div className="absolute inset-0 z-50 flex justify-center items-center">
              <FormChangePassword 
                onClose={() => setShowSuccess(!showSucees)}
              />
             </div>
          </>
        )}

        {showSucees && (
           <>
           <div className="absolute inset-0 bg-transparent bg-opacity-50 z-40"></div>
           <div className="absolute inset-0 z-50 flex justify-center items-center">
            <PageChangeeSuccess />
           </div>
        </>
        )}
    
        <h2 className="text-gray-600 h-auto text-sm md:text-left text-center mt-4">
          © 2023 PT Integrasi Teknologi Unggas. All right reserved
        </h2>
      </div>
    </div>
  
  )
}

export default ForgotPassword