/* eslint-disable no-unused-expressions */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  ChevronUpDownIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";

import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Typography,
  Chip,
  Alert,
  IconButton,
} from "@material-tailwind/react";
import moment from "moment";
import { Button } from "../../../atoms/Button";
import { useEffect, useState } from "react";
import Pagination from "../../../atoms/Pagination";
import { FaCheckCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import api from "../../../api/configApi";
import ConfirmDialog from "../../../atoms/ConfirmDialog";
import AlertNotification from "../../../atoms/AlertNotification";
import Input from "../../../atoms/Input";
import { ReactComponent as EmptyIcon } from "../../../assets/icons/emptyIconn.svg";
import LoadingAnimation from "../../../atoms/LoadingAnimation";
import { useRef } from "react";
import DropdownFilterStatus from "../../../atoms/DropdownFilterStatus";
import { useSelector } from "react-redux";
import Cookies from "js-cookie";
import { CheckboxCust } from "../../../atoms/Checkbox";
import CustomDatePicker from "../../../atoms/DatepickerForm";
import DropdownWithSearchInput from "../../../atoms/DropdownWithSearchInput";
import { CgSoftwareDownload } from "react-icons/cg";
import { FaRegCircleCheck } from "react-icons/fa6";
import { GrStatusWarning } from "react-icons/gr";
import { ButtonIcon } from "../../../atoms/ButtonIcon";
import { ReactComponent as ArrowDownAction } from "../../../assets/icons/arrowDown.svg";
import { ReactComponent as Download } from "../../../assets/icons/download_mutasi.svg";
import { ReactComponent as Addmutation } from "../../../assets/icons/tambah_mutasi.svg";
import LoadingAnimationRounded from "../../../atoms/LoadingAnimationRounded";

const Mutasi = () => {
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  let currentPageCookies = parseInt(Cookies.get("current-page-mutasi"));
  const [currentPage, setCurrentPage] = useState(
    currentPageCookies ? currentPageCookies : 1
  );
  const [totalPages, setTotalPages] = useState(1);
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [search, setSearch] = useState("");
  const [mutation, setMutation] = useState([]);
  const alert = JSON.parse(localStorage.getItem("response success"));
  const alertDelete = JSON.parse(localStorage.getItem("response delete"));
  const alertEditSuccess = JSON.parse(
    localStorage.getItem("responseEditSuccess")
  );
  const [notifEditSuccess, setNotifEditSuccess] = useState(alertEditSuccess);
  const [deleteNotif, setDeleteNotif] = useState(alertDelete);
  const [notif, setNotif] = useState(alert);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [dateOut, setDateOut] = useState(null);

  const handleCheckAllChange = () => {
    setSelectAll(!selectAll);
    setSelectedRows(selectAll ? [] : mutation.map((row) => row.id));
  };

  const TABLE_HEAD = [
    <CheckboxCust
      className="checked:bg-primary-500"
      onChange={handleCheckAllChange}
      checked={selectAll}
    />,
    "Tanggal mutasi",
    "Kandang asal",
    "Kandang tujuan",
    "Unit",
    "Jenis pakan",
    "Jumlah (sak)",
    "Tonase (kg)",
    "Status",
    "",
  ];

  const sortableColumns = [
    "",
    "dateOut",
    "rearingPeriod.house.name",
    "destination.house.name",
    "rearingPeriod.house.unit.name",
    "feed.name",
    "qtyOut",
    "qtyOut",
    "status",
  ];
  const [sortBy, setSortBy] = useState("createdAt");
  const [descending, setDescending] = useState(true);
  const [checkedUnits, setCheckedUnits] = useState([]);
  const [checkedUnitsString, setCheckedUnitsString] = useState("");

  const [isActiveStatus, setIsActiveStatus] = useState(null);

  const [notifDelete, setNotifDelete] = useState(false);
  const [responseDelete, setResponseDelete] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [listMasterUnits, setListMasterUnits] = useState([]);

  const [reloadFilter, setReloadFilter] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState();
  const [labelStatus, setLabelStatus] = useState(null);
  const [isFetchError, setIsFetchError] = useState(false);
  const tableRef = useRef(null);
  const [isOpenFilterStatus, setIsOpenFilterStatus] = useState(false);
  const [selectUnit, setSelectUnit] = useState("");
  const [searchUnit, setSearchUnit] = useState("");
  const [lastLabelUnits, setLastLabelUnits] = useState("");
  const [modalConfirmation, setModalconfirmation] = useState(false);
  const [isDownloadSuccess, setIsDownloadSuccess] = useState(false);
  const [isDownloadError, setIsDownloadError] = useState(false);
  const [modalConfirmationById, setModalconfirmationById] = useState(false);
  const [isDateOutFilterNull, setIsDateOutFilterNull] = useState(false);
  const [warningMessageDownload, setWarningMessageDownload] = useState("");
  const [messageError, setMessageError] = useState("");
  const [activeAction, setActiveAction] = useState(false);
  const [showDownloadNotif, setShowDownloadNotif] = useState(false);

  Cookies.set("current-page-mutasi", currentPage);

  const handleFilterUnit = (val) => {
    handleChangePage(1);
    setSelectUnit(val);
  };

  const handleSearch = (e) => {
    const targetValue = e.target.value;
    setSearch(targetValue);
    handleChangePage(1);
  };

  useEffect(() => {
    const dataFetchListMutasi = async () => {
      setIsLoading(true);
      handleResetSelectedRows();
      try {
        const params = {
          itemPerPage: search.length > 0 ? null : 10,
          search: search,
          currentPage: search.length > 0 ? null : currentPage - 1,
          sortBy: sortBy,
          descending: descending,
        };

        if (selectedStatus !== null) {
          params.doneReview = selectedStatus;
        }

        if (dateOut !== null) {
          params.dateOut = moment(dateOut?.$d).format("YYYY-MM-DD");
        }

        if (checkedUnitsString.length > 0) {
          params.unit = checkedUnitsString;
        }

        const responseList = await api.get("/v1/feeds/out", { params });

        setMutation(responseList?.data?.contents);
        setTotalPages(responseList?.data?.totalPages);
        if (!responseList) {
          setIsOpenFilter(false);
        }
        setIsFetchError(false);
      } catch (error) {
        console.log(error.message);
        setIsFetchError(true);
      } finally {
        setIsLoading(false);
      }
    };

    dataFetchListMutasi();
  }, [
    search,
    currentPage,
    descending,
    isActiveStatus,
    sortBy,
    reloadFilter,
    selectedStatus,
    dateOut,
    checkedUnitsString,
  ]);

  useEffect(() => {
    setTimeout(() => {
      setNotif(false);
      localStorage.clear("response success");
    }, 4000);
  }, [notif]);

  useEffect(() => {
    setTimeout(() => {
      setNotifEditSuccess(false);
      localStorage.clear("responseEditSuccess");
    }, 4000);
  }, [notifEditSuccess]);

  useEffect(() => {
    setTimeout(() => {
      setDeleteNotif(false);
      localStorage.clear("response delete");
    }, 4000);
  }, [deleteNotif]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleSort = (column) => {
    if (sortableColumns.includes(column)) {
      setCurrentPage(1);
      if (sortBy === column) {
        // Toggle descending if the same column is clicked
        setDescending(!descending);
      } else {
        // Change sorting column and set default descending to false
        setSortBy(column);
        setDescending(false);
      }
    }
  };

  const handleFilterDate = (date) => {
    handleChangePage(1);
    setDateOut(date);
    localStorage.setItem("selectedDate", moment(date?.$d).format("YYYY-MM-DD"));
  };

  useEffect(() => {
    if (responseDelete) {
      setTimeout(() => {
        setNotifDelete(true);
        setNotifDelete(false);
      }, 2000);
    }
  }, [notifDelete, responseDelete]);

  const status = [
    {
      id: 1,
      IsActive: true,
      labels: ["Selesai direview"],
      labelsPlaceholder: "Selesai direview",
    },
    {
      id: 0,
      IsActive: false,
      labels: ["Menunggu direview"],
      labelsPlaceholder: "Menunggu direview",
    },
  ];

  useEffect(() => {
    setReloadFilter(false);
  }, [reloadFilter]);

  const handleResetFilterStatus = () => {
    setSelectedStatus("");
    setLabelStatus("");
    setIsOpenFilterStatus(false);
  };

  const handleFilterStatus = (val) => {
    setSelectedStatus(val);
    setIsOpenFilterStatus(false);
    handlePageChange(1);
    if (val === true) {
      setLabelStatus("Selesai");
    } else if (val === false) {
      setLabelStatus("Menunggu");
    } else if (val === null) {
      setLabelStatus("Semua Status");
    }
  };

  const handleResetFilterUnit = () => {
    setCheckedUnits([]);
    setCheckedUnitsString("");
  };

  useEffect(() => {
    const fetchMasterUnit = async () => {
      try {
        const params = {
          search: searchUnit,
          descending: false,
        };
        const responseList = await api.get("/v1/units/dropdown", { params });
        setListMasterUnits(responseList?.data?.contents);
      } catch (error) {
        console.log(error.message);
      }
    };

    fetchMasterUnit();
  }, [searchUnit]);

  const handleClickDetail = (val) => {
    navigate(`/mutasi/${val}`);
  };

  const handleChangePage = (target) => {
    setCurrentPage(target);
  };

  const handleCheckboxChange = (id) => {
    setSelectedRows((prevSelectedRows) => {
      if (prevSelectedRows.includes(id)) {
        return prevSelectedRows.filter((rowID) => rowID !== id);
      } else {
        return [...prevSelectedRows, id];
      }
    });

    // Ensure selectAll is toggled off when a single checkbox changes
    setSelectAll(false);
  };

  const handleOpenModalConfirmationAllData = () => {
    setModalconfirmation(true);
    handleResetSelectedRows();
  };

  const handleOpenModalConfirmationById = (id) => {
    handleResetSelectedRows();
    setModalconfirmationById(true);
    setSelectedRows([id]);
  };
  const handleOpenModalConfirmation = () => {
    setModalconfirmation(true);
  };

  const handleResetSelectedRows = () => {
    setSelectedRows([]);
    setSelectAll(false);
  };

  const handleCloseModalDialogDownloadById = () => {
    handleResetSelectedRows();
    setModalconfirmationById(!modalConfirmationById)
  };

  const reqDownload = async (params) => {
    try {
      setShowDownloadNotif(true);

      // Check if ids are provided in the params, and format the query string
      let ids = params.ids ? "id=" + params.ids : "";
      let dateOut = params.dateOut ? "dateOut=" + params.dateOut : "";

      let andParams = ids.length > 0 && dateOut.length > 0 ? "&" : ""

      const multipleParams = ids + andParams + dateOut
      
      const response = await api.get(`/v1/feeds/out/toPdf?` + multipleParams , {
        responseType: "blob",
        
      });

      const contentDisposition = response.headers["content-disposition"];
      let fileName = "mutasi.pdf";

      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename="?([^"]+)"?/);
        if (fileNameMatch && fileNameMatch[1]) {
          fileName = fileNameMatch[1];
        }
      }

      const url = window.URL.createObjectURL(new Blob([response.data]));

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(url);

      setIsDownloadSuccess(true);
      setTimeout(() => setIsDownloadSuccess(false), 3000);
    } catch (error) {
      setModalconfirmation(false);
      setModalconfirmationById(false);
      setIsDownloadError(true);
      setTimeout(() => setIsDownloadError(false), 3000);
      setMessageError("Tidak ada data yang bisa di unduh");
      console.log(error);
    } finally {
      setShowDownloadNotif(false);
    }
  };

    const handleDownload = async () => {
      try {
        const params = {};

        if (modalConfirmation) {
          if (selectedRows.length > 0) {
            params.ids = selectedRows.map((row) => row);
            reqDownload(params);
          } else {
            if (user?.level === "ALL_DATA" || user?.level === "REGION") {
              if (dateOut !== null && checkedUnits !== null) {
                params.dateOut = moment(dateOut?.$d).format("YYYY-MM-DD");
                reqDownload(params);
              } else {
                setIsDateOutFilterNull(true);
                setWarningMessageDownload(
                  "Pilih tanggal dan unit terlebih dahulu"
                );
                setTimeout(() => setIsDateOutFilterNull(false), 3000);
              }
            } else {
              if (dateOut !== null) {
                params.dateOut = moment(dateOut?.$d).format("YYYY-MM-DD");
                reqDownload(params);
              } else {
                setIsDateOutFilterNull(true);
                setWarningMessageDownload("Pilih tanggal terlebih dahulu");
                setTimeout(() => setIsDateOutFilterNull(false), 3000);
              }
            }
          }
        } else if (modalConfirmationById) {
          if (selectedRows.length > 0) {
            params.ids = selectedRows.map((row) => row);
          }
          reqDownload(params);
        }

        setSelectedRows([]);
        setModalconfirmation(false);
        setModalconfirmationById(false);
      } catch (error) {
        setModalconfirmation(false);
        setModalconfirmationById(false);
        console.log(error);
      }
    };

  const dataAction = [
    {
      id: 1,
      label: "Download",
      icon: <Download />,
    },

    {
      id: 2,
      label: "Tambah data mutasi",
      icon: <Addmutation />,
    },
  ];

  const handleClickRoute = (val) => {
    if (val === 1) {
      handleOpenModalConfirmationAllData();
    }

    if (val === 2) {
      navigate("/mutasi/tambah-mutasi");
    }
  };

  return (
    <div className="flex h-auto overflow-hidden">
      <div className="flex flex-col flex-1 overflow-hidden">
        <div className="p-4">
          <Card className="h-full w-full border border-neutral-300 shadow-none">
            <CardHeader
              floated={false}
              shadow={false}
              className="p-3xl m-0 border-none"
            >
              <Typography className="text-xl text-text-primary font-bold leading-[30px]">
                Mutasi pakan
              </Typography>
            </CardHeader>
            <div className="border-t border-gray-300 w-full" />

            <CardBody className="p-0 overflow-scroll max-h-[860px]">
              <Card className="w-full h-auto border-0 shadow-none p-0 m-0">
                <CardHeader
                  floated={false}
                  shadow={false}
                  className="p-md border-none"
                >
                  <div className="flex justify-start w-full">
                    <div className="text-left flex justify-between gap-4 mb-1 w-full">
                      <div className="flex justify-start gap-6">
                        <Input
                          size="medium"
                          type="text"
                          id="search"
                          value={search}
                          onChange={handleSearch}
                          placeholder="Cari nama kandang"
                          className="w-[328px] border-border-primary"
                          componentLeft={
                            <MagnifyingGlassIcon className="h-5 w-5" />
                          }
                        />

                        <div className="mt-[-12px]">
                          <CustomDatePicker
                            placeholder="Pilih tanggal"
                            value={dateOut}
                            onChange={handleFilterDate}
                            format={"DD MMMM YYYY"}
                            defaultValue={() => dateOut}
                            className={
                              "flex placeholder:items-center placeholder:text-lg h-[43px] border-border-primary"
                            }
                            size="large"
                          />
                        </div>
                        {(user?.level === "ALL_DATA" ||
                          user?.level === "REGION") && (
                          <div>
                            <DropdownWithSearchInput
                              placeholder="Semua unit"
                              placeholderInputSearch="Cari unit"
                              options={listMasterUnits}
                              onSelect={handleFilterUnit}
                              search={searchUnit}
                              setSearch={setSearchUnit}
                              onSelectString={setCheckedUnitsString}
                              lastLabel={lastLabelUnits}
                              setLastLabel={setLastLabelUnits}
                              positionClassName="top-[350px] left-[1190px] border-border-primary"
                              selectCheckBox={checkedUnits}
                              setSelectCheckbox={setCheckedUnits}
                              className={"min-w-[180px]"}
                              onReset={handleResetFilterUnit}
                            />
                          </div>
                        )}

                        <div>
                          <DropdownFilterStatus
                            data={status}
                            handleSelect={handleFilterStatus}
                            popoverContentPosition={"top-[240px] left-[760px]"}
                            selectedStatus={selectedStatus}
                            label={labelStatus}
                            onReset={handleResetFilterStatus}
                          />
                        </div>
                      </div>

                      {user?.level === "UNIT" ? (
                        <>
                          <div className="relative">
                            <Button
                              type="btnPrimary"
                              size="medium"
                              label="Aksi"
                              onClick={() => setActiveAction(!activeAction)}
                              componentRight={<ArrowDownAction className="h-5 w-5" />}
                            />

                            {activeAction && (
                              <div
                                className="fixed right-10 top-60 z-50 w-[17%] h-auto shadow-lg rounded-lg bg-white"
                                // style={{ maxHeight: "30vh", overflowY: "auto" }} // Adjust `maxHeight` as needed
                              >
                                <Card className="overflow-hidden">
                                  <CardBody>
                                    <div className="space-y-2 p-2">
                                      {dataAction?.map((item) => (
                                        <div
                                          key={item.label}
                                          className="flex items-center gap-2 cursor-pointer p-2 hover:bg-gray-100 rounded-md transition-colors"
                                          onClick={() => handleClickRoute(item.id)}
                                        >
                                          <div>{item.icon}</div>
                                          <Typography className="text-sm font-medium text-gray-800 w-[100%]">
                                            {item.label}
                                          </Typography>
                                        </div>
                                      ))}
                                    </div>
                                  </CardBody>
                                </Card>
                              </div>
                            )}
                          </div>
                        </>
                      ) : (
                        <Button
                          type="btnLinkBlue"
                          size={"small"}
                          label="Unduh data"
                          onClick={handleOpenModalConfirmationAllData}
                          className="cursor-pointer h-[40px]"
                          componentLeft={
                            <CgSoftwareDownload className="h-5 w-5" />
                          }
                        />
                      )}
                    </div>
                  </div>
                </CardHeader>

                {isLoading ? (
                  <div className="flex justify-center items-center h-screen">
                    <LoadingAnimation />
                  </div>
                ) : (
                  <>
                    {mutation?.length === 0 || isFetchError ? (
                      <div className=" flex justify-center items-center h-screen">
                        <div>
                          <div className="flex justify-center">
                            <EmptyIcon className="ml-[30px]" />
                          </div>

                          <Typography
                            variant="h2"
                            className="text-xl font-semibold p-4 text-center text-text-secondary"
                          >
                            Data tidak ditemukan
                          </Typography>

                          <Typography
                            variant="medium"
                            className="flex justify-center font-normal leading-none w-full text-text-secondary min-h-full bg-gray-950"
                          >
                            Data yang Anda cari tidak ditemukan. Coba kata kunci
                            lain
                          </Typography>
                        </div>
                      </div>
                    ) : (
                      <>
                        <CardBody className="p-0 relative overflow-scroll max-w-full max-h-[960px] custom-scrollbar">
                          <table className="table-auto w-full min-w-max text-left mt-4 border-separate border-spacing-0">
                            <thead className="border-y border-border-secondary bg-neutral-50">
                              <tr>
                                {TABLE_HEAD?.map((head, index) => {
                                  return (
                                    <th
                                      key={head}
                                      className={`border-y border-border-secondary bg-neutral-50 py-2 px-4  ${
                                        index === 0
                                          ? "sticky left-0 z-10 pl-lg"
                                          : index === 9
                                          ? "sticky right-0 ml-4 z-10 p-0 shadow-[rgba(0,0,10,0.2)_-3px_0_12px_-5px] border-l"
                                          : ""
                                      }`}
                                    >
                                      <div
                                        className={`flex justify-between items-center`}
                                      >
                                        <Typography
                                          className={`flex items-center text-sm font-semibold text-text-quarterary leading-tight h-[21px] ${
                                            index === 0 && index === 1
                                          }`}
                                        >
                                          {head}
                                        </Typography>
                                        {index !== 0 &&
                                          index !== TABLE_HEAD.length - 1 && (
                                            <button
                                              onClick={() =>
                                                handleSort(
                                                  sortableColumns[index]
                                                )
                                              }
                                              className="focus:outline-none ml-1"
                                            >
                                              <ChevronUpDownIcon
                                                strokeWidth={2}
                                                className={`h-4 w-4 text-right ${
                                                  descending
                                                    ? "transform rotate-180"
                                                    : ""
                                                } text-text-quarterary`}
                                              />
                                            </button>
                                          )}
                                      </div>
                                    </th>
                                  );
                                })}
                              </tr>
                            </thead>

                            <tbody>
                              {mutation?.map((val, index) => {
                                const isLast = index === mutation.length - 1;
                                const classes = isLast
                                  ? "p-lg"
                                  : "p-lg border-b border-border-secondary";

                                return (
                                  <tr key={val.id}>
                                    <td
                                      className={`border-b  bg-white sticky left-0 px-lg shadow-[rgba(0,0,10,0.2)_-3px_1px_12px_-5px]`}
                                    >
                                      <div className="flex justify-between max-w-[500px]">
                                        <CheckboxCust
                                          className="checked:bg-primary-500"
                                          onChange={() =>
                                            handleCheckboxChange(val?.id)
                                          }
                                          checked={selectedRows?.includes(
                                            val.id
                                          )}
                                        />
                                      </div>
                                    </td>

                                    <td className={`${classes} max-w-[250px]`}>
                                      <Typography className="text-text-primary text-sm font-medium leading-tight pl-2">
                                        {val.dateOut}
                                      </Typography>
                                    </td>

                                    <td className={`${classes} max-w-[250px]`}>
                                      <Typography className="text-text-primary text-sm font-medium leading-6 pl-2">
                                        {val?.rearingPeriod?.house?.labels}
                                      </Typography>
                                    </td>

                                    <td className={`${classes} max-w-[250px]`}>
                                      <Typography className="text-text-primary text-sm font-medium leading-6 pl-2">
                                        {val?.destination?.house?.labels}
                                      </Typography>
                                    </td>

                                    <td className={classes}>
                                      <Typography className="text-text-primary text-sm font-medium leading-tight pl-2">
                                        {val?.rearingPeriod?.house?.unit
                                          ?.labels || "-"}
                                      </Typography>
                                    </td>

                                    <td className={classes}>
                                      <Typography className="text-text-primary text-sm font-medium leading-tight pl-2">
                                        {val?.feed?.labels}
                                      </Typography>
                                      <Typography className="pl-2 text-sm text-text-quarterary">
                                        {val?.feed?.type?.labels}
                                      </Typography>
                                    </td>

                                    <td className={classes}>
                                      <Typography className="text-text-primary text-sm font-medium leading-tight pl-2">
                                        {val?.qtyOut / 50}
                                      </Typography>
                                    </td>

                                    <td className={classes}>
                                      <Typography className="text-text-primary text-sm font-medium leading-tight pl-2">
                                        {val?.qtyOut}
                                      </Typography>
                                    </td>

                                    <td className={`${classes}`}>
                                      <Typography className="flex justify-center text-text-primary text-sm font-medium leading-tight">
                                        <div className="w-max">
                                          <Chip
                                            size="sm"
                                            variant="ghost"
                                            value={
                                              val?.status?.id ===
                                              "WAITING_FOR_REVIEW"
                                                ? "Menunggu direview"
                                                : val?.status?.id ===
                                                  "ON_REVIEW"
                                                ? "Sedang direview"
                                                : "Selesai"
                                            }
                                            className={`text-sm font-inter font-normal leading-[18px] border normal-case ${
                                              val?.status?.id ===
                                              "WAITING_FOR_REVIEW"
                                                ? "text-[#C49801] border-[#FBE497] bg-[#FEF8E2]"
                                                : val?.status?.id ===
                                                  "ON_REVIEW"
                                                ? "text-[#1570EF] border-[#B2DDFF] bg-[#F5FAFF]"
                                                : "text-[#079455] border-[#ABEFC6] bg-[#F6FEF9]"
                                            }`}
                                          />
                                        </div>
                                      </Typography>
                                    </td>

                                    <td
                                      className={`border-l border-b border-border-secondary bg-white sticky right-0 p-lg shadow-[rgba(0,0,10,0.2)_-3px_1px_12px_-5px]`}
                                    >
                                      <div className="flex justify-between gap-2 m-0">
                                        {val.status?.id !== "DONE_REVIEW" ? (
                                          <ButtonIcon
                                            className="z-25"
                                            icon="downloadDisabled"
                                            type="disabled"
                                            size="small"
                                            disabled="disabled"
                                          />
                                        ) : (
                                          <ButtonIcon
                                            className="z-25"
                                            onClick={() =>
                                              handleOpenModalConfirmationById(
                                                val.id
                                              )
                                            }
                                            icon="download"
                                            type="outlineSucced"
                                            size="small"
                                          />
                                        )}

                                        <ButtonIcon
                                          className="z-25"
                                          onClick={() =>
                                            handleClickDetail(val.id)
                                          }
                                          icon="detail"
                                          type="outlineInfo"
                                          size="small"
                                        />
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </CardBody>

                        <CardFooter className="items-center border-t border-border-secondary p-sm">
                          <Pagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            onPageChange={handleChangePage}
                          />
                        </CardFooter>
                      </>
                    )}
                  </>
                )}
              </Card>
            </CardBody>
          </Card>
        </div>
      </div>

      <ConfirmDialog
        isOpen={modalConfirmation}
        onClose={() => setModalconfirmation(!modalConfirmation)}
        content={
          selectedRows.length > 0
            ? `Kamu akan mengunduh ${selectedRows.length} data pada baris yang sudah di centang, apakah kamu yakin akan melanjutkan?`
            : "Kamu akan mengunduh semua data pada semua halaman, apakah kamu yakin akan melanjutkan?"
        }
        title={
          selectedRows.length > 0
            ? `Unduh ${selectedRows.length} data terpilih`
            : "Unduh semua data"
        }
        btnLabel="Lanjutkan"
        onConfirm={handleDownload}
        classNameContent={"text-md"}
      />

      <ConfirmDialog
        isOpen={modalConfirmationById}
        onClose={handleCloseModalDialogDownloadById}
        content={"Kamu akan mendowload 1 data yang telah dipilih menjadi .pdf"}
        title={"Unduh data?"}
        btnLabel="Lanjutkan"
        onConfirm={handleDownload}
        classNameContent={"text-md"}
      />

      {isDownloadSuccess && (
        <AlertNotification
          icon={<FaCheckCircle />}
          color="green"
          message="Berhasil unduh data"
        />
      )}

      {isDateOutFilterNull && (
        <AlertNotification
          icon={<GrStatusWarning className="w-5 h-5" />}
          color="orange"
          message={warningMessageDownload}
        />
      )}
      {isDownloadError && (
        <AlertNotification
          icon={<FaCheckCircle />}
          color="red"
          message={messageError}
        />
      )}

      {showDownloadNotif && (
        <AlertNotification
          color={"#0C111D"}
          message="Sedang mengunduh data mutasi"
          icon={<LoadingAnimationRounded white />}
        />
      )}

      {selectedRows.length > 0 && (
        <div className="fixed top-[210px] animate-slide-up animate-fade-in items-center z-[120]">
          <Alert
            icon={<FaRegCircleCheck />}
            className="items-center bg-white shadow-lg text-text-secondary flex justify-between m-0 border border-border-secondary"
            action={
              <div>
                <Button
                  type="btnLinkBlue"
                  size={"small"}
                  label="Unduh"
                  onClick={handleOpenModalConfirmation}
                  className="cursor-pointer h-[40px]"
                />
                <IconButton
                  color="blue-gray"
                  size="sm"
                  variant="text"
                  onClick={handleResetSelectedRows}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                    className="h-5 w-5"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </IconButton>
              </div>
            }
          >
            {selectedRows.length} Data terpilih
          </Alert>
        </div>
      )}
    </div>
  );
};

export default Mutasi;
