import {
  Card,
  CardBody,
  CardHeader,
  Typography,
} from "@material-tailwind/react";
import moment from "moment";
import "moment/locale/id";
import LoadingAnimation from "../../../../../atoms/LoadingAnimation";
moment.locale("id");

const PerformaLabaRugi = ({ ResponseDetail, loading }) => {

  return (
    <div className="felx h-auto xl:overflow-hidden overflow-hidden w-full">
      <div className="flex flex-col flex-1 overflow-hidden">
        {loading ? (
          <>
            <LoadingAnimation />
          </>
        ) : (
          <div className="p-4 ml-[g20px] grid sm:grid-cols-1">
            <div className="grid grid-cols-1 gap-4">
              <div className="w-full p-4">
                <Card className="w-full border border-border-secondary shadow-none">
                  <CardHeader
                    floated={false}
                    shadow={false}
                    className="p-2 border-none mt-1"
                  >
                    <Typography
                      className="font-semibold text-base items-center text-text-secondary"
                    >
                      Sapronak dan hasil panen
                    </Typography>
                  </CardHeader>

                  <CardBody className="p-0 overflow-scroll max-w-full max-h-[960px] z-10 border-t-2 border-border-secondary text-sm">
                    <div className="flex justify-start items-center">
                      <div className="w-[300px] bg-gray-100 p-4 pl-6 text-text-quarterary font-semibold">Doc</div>
                      <div className="w-full flex justify-end p-4 pr-12 font-medium text-text-primary">
                        Rp{ResponseDetail.totalPurchaseDoc?.toLocaleString("id-ID")}
                      </div>
                    </div>
                    <div className="flex justify-start items-center border-t border-border-secondary">
                      <div className="w-[300px] bg-gray-100 p-4 pl-6 text-text-quarteraryfont-semibold">
                        Pakan
                      </div>
                      <div className="w-full flex justify-end p-4 pr-12 font-medium text-text-primary">
                        Rp{ResponseDetail.totalPurchaseFeed?.toLocaleString("id-ID")}
                      </div>
                    </div>
                    <div className="flex justify-start items-center border-t border-border-secondary">
                      <div className="w-[300px] bg-gray-100 p-4 pl-6 text-text-quarterary font-semibold">Obat</div>
                      <div className="w-full flex justify-end p-4 pr-12 font-medium text-text-primary">
                        Rp{ResponseDetail.totalPurchaseOvk?.toLocaleString("id-ID")}
                      </div>
                    </div>
                    <div className="flex justify-start items-center border-t border-border-secondary">
                      <div className="w-[300px] bg-neutral-50 rounded-b-lg p-4 pl-6 text-text-quarterary font-semibold">
                        Penjualan Panen
                      </div>
                      <div className="w-full flex justify-end p-4 pr-12 font-medium text-text-primary">
                        Rp{ResponseDetail.totalHarvestSales?.toLocaleString("id-ID")}
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </div>
            </div>

            <div className="grid grid-cols-2 md:grid-cols-2 sm:grid-cols-1 w-full h-auto">
              <div className="w-full p-4"></div>

              <div className="w-full p-4"></div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PerformaLabaRugi;
