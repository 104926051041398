import React, { useEffect } from "react";

import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

import TestingComponent from "../src/pages/testing";
import "./App.css";
import BaseUrlRouter from "./baseUrlRoute.js";
import FormFeed from "./molecules/formFeed/AddFeed.jsx";
import FormOvk from "./molecules/formFeed/AddOvk.jsx";
import UpdateFeed from "./molecules/formFeed/UpdateFeed.jsx";
import UpdateOvk from "./molecules/formFeed/UpdateOvk.jsx";
import Wrapper from "./molecules/global/wrapper";
import ForgotPassword from "./molecules/login/forgotPasswordV2/ForgotPassword.jsx";
import Dashboard from "./pages/admin/Dashboard";
import Document from "./pages/admin/Document.jsx";
import EmptyPage from "./pages/admin/EmptyPage.jsx";
import KYC from "./pages/admin/KYC.jsx";
import LiveStockManager from "./pages/admin/LiveStock/LiveStockManager.jsx";
import LiveStockUnit from "./pages/admin/LiveStock/LiveStockUnit.jsx";
import OptionalLiveStock from "./pages/admin/LiveStock/OptionRoleLivebird.jsx";
import AddBakul from "./pages/admin/Master/Bakul/AddBakul.jsx";
import EditBakul from "./pages/admin/Master/Bakul/EditBakul.jsx";
import MasterBakul from "./pages/admin/Master/Bakul/MasterBakul.jsx";
import MasterDoc from "./pages/admin/Master/Doc/MasterDoc.jsx";
import DetailKandang from "./pages/admin/Master/Kandang/DetailKandang.jsx";
import Kandang from "./pages/admin/Master/Kandang/MasterKandang.jsx";
import RecomendationAddPpl from "./pages/admin/Master/Kandang/RecomendationAddPpl.jsx";
import TambahKandang from "./pages/admin/Master/Kandang/TambahKandang.jsx";
import MasterOvk from "./pages/admin/Master/Ovk/MasterOvk.jsx";
import MasterPakan from "./pages/admin/Master/Pakan/MasterPakan.jsx";
import AddRegion from "./pages/admin/Master/Region/AddRegion.jsx";
import EditRegion from "./pages/admin/Master/Region/EditRegion.jsx";
import MasterRegion from "./pages/admin/Master/Region/MasterRegion.jsx";
import AddUnit from "./pages/admin/Master/Unit/AddUnit.jsx";
import EditUnit from "./pages/admin/Master/Unit/EditUnit.jsx";
import MasterUnit from "./pages/admin/Master/Unit/MasterUnit.jsx";
import AddMutasi from "./pages/admin/Mutasi/AddMutasi.jsx";
import DetailMutasi from "./pages/admin/Mutasi/DetailMutasi.jsx";
import EditMutasi from "./pages/admin/Mutasi/EditMutasi.jsx";
import Mutasi from "./pages/admin/Mutasi/Mutasi.jsx";
import SelesaiReview from "./pages/admin/Panen/selesaiReview.jsx";
import DetailPanen from "./pages/admin/PanenV2/DetailPanen.jsx";
import Panen from "./pages/admin/PanenV2/Panen.jsx";
import MasterPeriode from "./pages/admin/periode_budidaya/MasterPeriode/index.jsx";
import MasterPeriodeEdit from "./pages/admin/periode_budidaya/MasterPeriodeEdit/index.jsx";
import MasterPeriodeRegion from "./pages/admin/periode_budidaya/MasterPeriodeRegion/index.jsx";
import MasterPeriodeRegionDetail from "./pages/admin/periode_budidaya/MasterPeriodeRegionDetail/index.jsx";
import MasterPeriodeTambah from "./pages/admin/periode_budidaya/MasterPeriodeTambah/index.jsx";
import MonGrow from "./pages/admin/periode_budidaya/MonGrow.jsx";
import DetailPeriod from "./pages/admin/periode_budidaya/periode_berjalan/DetailPeriod.jsx";
import PeriodeBudidaya from "./pages/admin/periode_budidaya/periode_berjalan/PeriodeBudidaya.jsx";
import AddRecordingHarian from "./pages/admin/periode_budidaya/periode_berjalan/Recording/AddRecordingHarian.jsx";
import DetailRecordingHarian from "./pages/admin/periode_budidaya/periode_berjalan/Recording/DetailRecordingHarian.jsx";
import EditRecordingHarian from "./pages/admin/periode_budidaya/periode_berjalan/Recording/EditRecordingHarian.jsx";
import DetailPeriodeSelesai from "./pages/admin/periode_budidaya/PeriodeSelesai/DetailPeriodeSelesai.jsx";
import PeriodeSelesai from "./pages/admin/periode_budidaya/PeriodeSelesai/PeriodeBudidayaSelesai.jsx";
import Peternak from "./pages/admin/Peternak/Peternak.jsx";
import RecomendationAddHouse from "./pages/admin/Peternak/RecomendationAddHouse.jsx";
import Ovk from "./pages/admin/Stock/Ovk";
import Pakan from "./pages/admin/Stock/Pakan";
import User from "./pages/admin/User";
import AddUserRole from "./pages/admin/UserRole/AddUserRole";
import EditUserRole from "./pages/admin/UserRole/EditUserRole";
import UserRole from "./pages/admin/UserRole/UserRole";
import CreateNewPasswordPage from "./pages/login/CreateNewPasswordPage";
import EmailSentPage from "./pages/login/EmailSentPage";
import LoginPage from "./pages/login/LoginPage";
import DeletedAccount from "./pages/login/RequestDeleteAccount.jsx";
import PrivateRouter from "./privateRoute.js";
import { ROUTES_PATH } from "./shared/routes.js";

function App() {
  useEffect(() => {
    document.title = "BroilerX Management Dashboard";
  }, []);

  return (
    <div>
      <Router>
        <Routes>
          <Route element={<BaseUrlRouter />}>
            <Route path="/" element={<LoginPage />} />
          </Route>
          <Route path="/email-terkirim" element={<EmailSentPage />} />
          <Route
            path="/buat-password-baru"
            element={<CreateNewPasswordPage />}
          />
          <Route path="/lupa-password" element={<ForgotPassword />} />
          <Route path="/emptyPage" element={<EmptyPage />} />
          <Route element={<PrivateRouter />}>
            <Route path="/requestdeleteaccount" element={<DeletedAccount />} />
            <Route element={<Wrapper />}>
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/KYC" element={<KYC />} />
              <Route path="/Dokumen" element={<Document />} />
              <Route
                path="/pengaturan-pengguna/role-akses"
                element={<UserRole />}
              />
              <Route
                path="/pengaturan-pengguna/role-akses/tambah-user-role"
                element={<AddUserRole />}
              />
              <Route
                path="/pengaturan-pengguna/role-akses/:id"
                element={<EditUserRole />}
              />
              <Route path="/pengaturan-pengguna/user" element={<User />} />
              <Route
                path="/pengaturan-pengguna/farmer"
                element={<Peternak />}
              />
              <Route
                path="/pengaturan-pengguna/farmer/recomendationAddHouse"
                element={<RecomendationAddHouse />}
              />
              <Route path="/stok/pakan" element={<Pakan />} />
              <Route path="/stok/ovk" element={<Ovk />} />
              <Route
                path="/periode-budidaya/periode-berjalan/:id/sapronak/tambah-ovk"
                element={<FormOvk />}
              />
              <Route
                path="/periode-budidaya/periode-berjalan/:id/sapronak/tambah-pakan"
                element={<FormFeed />}
              />
              <Route path="/ovk/update-ovk/:id" element={<UpdateOvk />} />
              <Route path="/pakan/update-pakan/:id" element={<UpdateFeed />} />
              <Route
                path={ROUTES_PATH.masterPeriode}
                element={<MasterPeriode />}
              />
              <Route
                path={ROUTES_PATH.masterPeriodeTambahPeriode}
                element={<MasterPeriodeTambah />}
              />
              <Route
                path={ROUTES_PATH.masterPeriodeEditPeriode}
                element={<MasterPeriodeEdit />}
              />
              <Route
                path={ROUTES_PATH.masterPeriodeRegion}
                element={<MasterPeriodeRegion />}
              />
              <Route
                path={ROUTES_PATH.masterPeriodeRegionDetail}
                element={<MasterPeriodeRegionDetail />}
              />
              <Route
                path="/periode-budidaya/periode-berjalan"
                element={<PeriodeBudidaya />}
              />
              <Route
                path="/periode-budidaya/periode-berjalan/:id/sapronak"
                element={<DetailPeriod />}
              />
              <Route
                path="/periode-budidaya/periode-berjalan/:id/period"
                element={<DetailPeriod />}
              />
              <Route
                path="/periode-budidaya/periode-berjalan/:id/dataharian"
                element={<DetailPeriod />}
              />
              <Route
                path="/periode-budidaya/periode-berjalan/:id/rekapitulasi"
                element={<DetailPeriod />}
              />
              <Route
                path="/periode-budidaya/periode-berjalan/:id/dataharian/tambah-data-harian"
                element={<AddRecordingHarian />}
              />
              <Route
                path="/periode-budidaya/periode-berjalan/:periodId/dataharian/ubah-data-harian/:id"
                element={<EditRecordingHarian />}
              />
              <Route
                path="/periode-budidaya/periode-berjalan/:periodId/dataharian/:id"
                element={<DetailRecordingHarian />}
              />
              <Route
                path="/periode-budidaya/monitoring-growing/berjalan"
                element={<MonGrow />}
              />
              <Route
                path="/periode-budidaya/monitoring-growing/closing"
                element={<MonGrow />}
              />
              <Route
                path="/periode-budidaya/periode-selesai"
                element={<PeriodeSelesai />}
              />
              <Route
                path="/periode-budidaya/periode-selesai/:id"
                element={<DetailPeriodeSelesai />}
              />
              <Route path="/panen" element={<Panen />} />
              <Route path="/panen/:id" element={<DetailPanen />} />
              <Route
                path="/panen/selesaiReview/:id"
                element={<SelesaiReview />}
              />
              <Route path="/kandang" element={<Kandang />} />
              <Route
                path="/kandang/tambah-kandang"
                element={<TambahKandang />}
              />
              <Route
                path="/kandang/recomendation-add-ppl/:id"
                element={<RecomendationAddPpl />}
              />
              <Route path="/kandang/:id" element={<DetailKandang />} />
              <Route path="/master/doc" element={<MasterDoc />} />
              <Route path="/master/ovk" element={<MasterOvk />} />
              <Route path="/master/pakan" element={<MasterPakan />} />
              <Route path="/master/unit" element={<MasterUnit />} />
              <Route path="/master/unit/tambah-unit" element={<AddUnit />} />
              <Route path="/master/unit/edit-unit/:id" element={<EditUnit />} />
              <Route path="/master/bakul" element={<MasterBakul />} />
              <Route path="/master/bakul/tambah-bakul" element={<AddBakul />} />
              <Route
                path="/master/bakul/edit-bakul/:id"
                element={<EditBakul />}
              />
              <Route path="/master/region" element={<MasterRegion />} />
              <Route
                path="/master/region/tambah-region"
                element={<AddRegion />}
              />
              <Route
                path="/master/region/edit-region/:id"
                element={<EditRegion />}
              />
              <Route path="/mutasi" element={<Mutasi />} />
              <Route path="/mutasi/tambah-mutasi" element={<AddMutasi />} />
              <Route path="/mutasi/:id" element={<DetailMutasi />} />
              <Route path="/mutasi/edit-mutasi/:id" element={<EditMutasi />} />
              <Route
                path="/stok/livebird-region"
                element={<LiveStockManager />}
              />
              <Route path="/stok/livebird/:id" element={<LiveStockUnit />} />
              <Route path="/stok/livebird-unit" element={<LiveStockUnit />} />
              <Route path="/stok/livebird" element={<OptionalLiveStock />} />
              <Route path="/testing" element={<TestingComponent />} />
            </Route>
          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
