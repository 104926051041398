import { Card, Typography } from "@material-tailwind/react"
import { ReactComponent as Success } from '../../../assets/icons/change-success-2.svg'
import { Button } from "../../../atoms/Button"
import { useNavigate } from "react-router-dom"

const PageChangeeSuccess = () => {
  const navigate = useNavigate()

  return (
    <div className="flex justify-center items-center md:min-h-[700px] min-h-[500px] overflow-hidden">
      <Card className="w-[484px] h-[484px] max-w-[484px] md:min-w-sm shadow-none md:shadow-md md:border-t-2 md:border-gray-100 rounded-xl md:p-4xl p-xs mt-[23px]">

        <div className="w-[150px] h-[150px] flex justify-center items-center ml-[150px] mt-[30px]">
          <Success />
        </div>

        <Typography
          type="h2"
          className="text-xl font-bold text-neutral-900 mb-xs text-center mt-[36px] ml-[4px]"
        >
         Kata sandi berhasil diubah
        </Typography>

        <Typography
          type="small"
          className="text-sm font-normal text-neutral-500 mb-4xl text-center"
        >
          Silakan masuk menggunakan kata sandi baru.
        </Typography>

        <Button
          label="Masuk"
          size="medium"
          className="mt-[16px]"
          onClick={() => navigate('/')}
        />
      </Card>
    </div>
  )
}

export default PageChangeeSuccess