import { Dialog, DialogBody, DialogFooter, DialogHeader, IconButton, Typography } from "@material-tailwind/react"
// import Dropdown from "../../../atoms/Dropdown"
import { Button } from "../../../atoms/Button"
import FormDropdownSearch from "../../../atoms/FormDropdownSearch"
import Input from "../../../atoms/Input"

const ModalAddMember = ({
  open,
  setOpen,
  handleOnclickCancel,
  handleOnclickSave,
  disabled, 
  options,
  handleOnSelect,
  optionsPeranUser,
  handleSelectPeranUser,
  placeholderUser,
  placeholderPermissions,
  editMember,
  setSearch,
  search,
  handleChangePeran,
  valuePeran
}) => {

  return (
    <Dialog open={open} handler={setOpen}>
      <DialogHeader className="flex justify-between p-6 m-0 items-center">
        <Typography
          variant="h5"
          className="text-neutral-800"
        >
          {editMember ? 'Edit Anggota' : 'Tambah Anggota'}
        </Typography>

        <IconButton
          color="blue-gray"
          size="sm"
          variant="text"
          onClick={setOpen}
        >
          <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
              className="h-5 w-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
          </svg>
        </IconButton>
      </DialogHeader>
      <div className="border-t border-[#EAECF0] w-full" />

      <DialogBody className="p-0 m-0 pt-2 px-2">
        <div className="p-4">
          <Typography
            variant="small"
            className="font-semibold text-neutral-800"
          >
            Anggota <span className="text-[#F04438]">*</span>
          </Typography>

          <div className="mt-2">
            <FormDropdownSearch
              id="name"
              size="medium"
              placeholder={placeholderUser}
              options={options}
              onSelect={handleOnSelect}
              disabled={disabled}
              setSearch={setSearch}
              search={search}
            />
          </div>

          <Typography
            variant="small"
            color="blue-gray"
            className="font-semibold text-neutral-800 mt-4"
          >
            Peran User <span className="text-[#F04438]">*</span>
          </Typography>

          <div className="mt-2">
            {/* <Dropdown
              size="medium"
              placeholder={placeholderPermissions}
              options={optionsPeranUser}
              onSelect={handleSelectPeranUser}
            /> */}
            <Input
              size="medium"
              placeholder={placeholderPermissions}
              onChange={handleChangePeran}
              className="max-w-full"
              value={valuePeran}
              disabled={true}
            />
          </div>
        </div>
      </DialogBody>
      <div className="border-t border-[#EAECF0] my-4 w-full" />

      <DialogFooter className="mt-[-20px] gap-4 p-6">
        <Button
          label="Batal"
          onClick={handleOnclickCancel}
          type="btnSecondary"
        />

        <Button
          label="Simpan"
          onClick={handleOnclickSave}
        />
      </DialogFooter>
    </Dialog>
  )
}

export default ModalAddMember