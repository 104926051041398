import {
  Card,
  CardBody,
  CardHeader,
  Typography,
} from "@material-tailwind/react";
import moment from "moment";
import "moment/locale/id";
import LoadingAnimation from "../../../../../atoms/LoadingAnimation";
import BgCardPerformanceURL from "../../../../../assets/icons/bg-card-performance.svg";
moment.locale("id");

const RekapPeriodeBudidaya = ({ ResponseDetail, loading }) => {
  const dataPerformace = [
    {
      id: 1,
      label: "IP",
      value: ResponseDetail?.ip?.toLocaleString("id-ID"),
    },

    {
      id: 2,
      label: "FCR Actual",
      value: ResponseDetail?.actualFcr,
    },

    {
      id: 3,
      label: "FCR standard",
      value: ResponseDetail?.standardFcr,
    },

    {
      id: 4,
      label: "Diff FCR",
      value: ResponseDetail?.diffFcr,
    },

    {
      id: 5,
      label: "ADG",
      value:ResponseDetail?.adg,
      satuan: "g/hari",
    },

    {
      id: 6,
      label: "Deplesi",
      value: ResponseDetail?.depletionPercentage,
      satuan: "%",
    },

    {
      id: 7,
      label: "Tonase",
      value: ResponseDetail?.totalWeight / 1000,
      satuan: "kg",
    },

    {
      id: 8,
      label: "Bobot rata-rata",
      value: ResponseDetail?.averageWeight / 1000,
      satuan: "kg",
    },

    {
      id: 9,
      label: "Umur",
      value: ResponseDetail?.dayOfPeriod,
      satuan: "hari"
    },

    {
      id: 10,
      label: "Panen",
      value: ResponseDetail?.totalHarvest.toLocaleString("id-ID"),
    },
  ];


  const dataDoc = [
    {
      id: 1,
      label: "Jenis DOC",
      value: ResponseDetail?.doc?.labels,
    },

    {
      id: 2,
      label: "Bobot Awal",
      value: `${ResponseDetail?.docInWeight} g`,
    },
  ];

  const dataInformationFeeding = [
    {
      id: 1,
      label: "Stok Pakan",
      value: `${ResponseDetail?.totalFeedStock?.toLocaleString("id-ID")} kg`,
    },

    {
      id: 2,
      label: "Pakan Masuk",
      value: `${ResponseDetail?.totalFeedIn?.toLocaleString("id-ID")} kg`,
    },

    {
      id: 3,
      label: "Pakan Pakai",
      value: `${ResponseDetail?.totalFeedUsed?.toLocaleString("id-ID")} kg`,
    },

    {
      id: 4,
      label: "FI",
      value: `${ResponseDetail?.feedIntake?.toLocaleString("id-ID")} g/ekor`,
    },
  ];

  const infromationDepletion = {
    id: 1,
    totalDeplesi: "Total Deplesi",
    nilaiTotalDeplesi: ResponseDetail?.totalDepletion,
    moralitas: "Mortalitas",
    nilaiMoralitas: ResponseDetail?.totalMortality,
    culling: "Culling",
    nilaiCulling: ResponseDetail?.totalCulling,
  };

  return (
    <div className="felx h-auto xl:overflow-hidden overflow-hidden w-full">
      <div className="flex flex-col flex-1 overflow-hidden">
        {loading ? (
          <>
            <div className="flex justify-center items-center h-screen">
              <LoadingAnimation hegith={"200px"} width={"200px"} />
            </div>
          </>
        ) : (
          <div className="p-2xl grid sm:grid-cols-1">
            <div className="w-full">
              <Card className="w-full shadow-none border border-none m-0 p-0">
                <CardHeader
                  floated={false}
                  shadow={false}
                  className="py-lg p-0 m-0 my-xl border-none"
                >
                  <div className="h-[10px] p-0 w-full relative bg-white flex items-center">
                    <Typography
                      variant="h7"
                      className="text-lg font-bold text-text-secondary"
                    >
                      Performa
                    </Typography>
                  </div>
                </CardHeader>

                <CardBody className="p-0 m-0 max-w-full z-10">
                  <div className="grid grid-cols-4 md:grid-cols-4 sm:grid-cols-1 gap-8">
                    {dataPerformace?.map((val) => (
                      <Card className="w-full h-[110px] shadow-none border-2 border-[#c49801] p-0 m-0">
                        <CardHeader
                          shadow={false}
                          floated={false}
                          className="border-none m-0 p-0 bg-yellow-50"
                          style={{
                            backgroundImage: `url(${BgCardPerformanceURL})`,
                            backgroundPosition: 'right',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'contain'
                          }}
                        >
                          <div className="flex justify-between">
                            <div className="p-3xl">
                              <Typography
                                className="text-base font-medium text-text-primary leading-normal"
                              >
                                {val.label}
                              </Typography>
                              <div className="flex justify-start items-center">
                                <Typography
                                  className={`text-2xl text-text-brandSecondary font-bold leading-loose`}
                                >
                                  {val?.value}
                                </Typography>
                                {val?.satuan && (
                                  <Typography
                                    className={`text-sm text-text-primary font-normal leading-tight pl-sm`}
                                  >
                                    {val?.satuan}
                                  </Typography>
                                )}
                              </div>
                            </div>
                          </div>
                        </CardHeader>
                      </Card>
                    ))}
                  </div>
                  <div className="bg-bg-infoPrimary p-3 m-4 mx-0 rounded-lg">
                    <Typography
                      variant="sm"
                      className="font-[14px] weight-[400] text-text-brandPrimary text-sm"
                    >
                      Performa ini adalah akumulasi dari data harian.
                    </Typography>
                  </div>
                </CardBody>
              </Card>
            </div>

            <div className="grid grid-cols-3 md:grid-cols-3 sm:grid-cols-1 mt-5 w-full h-auto gap-4">
              <div className="w-full">
                <Card className="w-full h-[239px] shadow-none border border-border-secondary m-0 p-0">
                  <CardHeader
                    floated={false}
                    shadow={false}
                    className="py-lg px-4xl m-0 border-none"
                  >
                    <div className="w-full relative bg-white flex items-center">
                      <Typography className="text-md font-bold text-text-secondary">
                        Informasi Deplesi
                      </Typography>
                    </div>
                  </CardHeader>
                  <hr className="border-t border-[#EAECF0] w-full" />

                  <CardBody className="p-0 max-w-full max-h-[960px] z-10">
                    <div className="text-start">
                      <div key={infromationDepletion.id} className="mt-4">
                        <div className="w-full">
                          <div className=" mt-lg mx-4xl mb-2xl">
                            <div className="mt-4 flex justify-between">
                              <Typography className=" text-text-tertiary font-normal text-base leading-normal">
                                {infromationDepletion?.moralitas}
                              </Typography>

                              <Typography className="mt-1 mb-2 text-base font-bold text-text-primary leading-normal">
                                {infromationDepletion?.nilaiMoralitas}
                              </Typography>
                            </div>

                            <div className="mt-4 flex justify-between">
                              <Typography
                                variant="h6"
                                color="blue-gray"
                                className=" text-text-tertiary font-normal text-base leading-normal"
                              >
                                {infromationDepletion?.culling}
                              </Typography>

                              <Typography className="mt-1 mb-2 text-base font-bold text-text-primary leading-normal">
                                {infromationDepletion?.nilaiCulling}
                              </Typography>
                            </div>
                          </div>
                          <div className="flex justify-between bg-bg-primary_hover py-lg px-2xl mx-lg mb-2xl">
                            <Typography className="text-text-primary text-sm font-medium leading-tight">
                              {infromationDepletion?.totalDeplesi}
                            </Typography>

                            <Typography className="text-base text-text-errorPrimary font-bold leading-normal">
                              {infromationDepletion?.nilaiTotalDeplesi}
                            </Typography>
                          </div>
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </div>

              <div className="w-full">
                <Card className="w-full h-[239px] shadow-none border border-border-secondary m-0 p-0">
                  <CardHeader
                    floated={false}
                    shadow={false}
                    className="p-lg m-0 border-none"
                  >
                    <div className="h-[10px] p-3 w-full relative bg-white flex items-center">
                      <Typography
                        variant="h7"
                        className="text-md font-bold text-text-secondary"
                      >
                        Informasi DOC
                      </Typography>
                    </div>
                  </CardHeader>
                  <hr className="border-t border-[#EAECF0] w-full" />

                  <CardBody className="p-2 max-w-full max-h-[960px] z-10">
                    <div className=" grid grid-cols-2 justify-between p-4 w-full gap-y-3">
                      {dataDoc?.map((val) => (
                        <>
                          <div>
                            <Typography className="text-text-tertiary font-normal text-base leading-normal">
                              {val?.label}
                            </Typography>
                          </div>
                          <div>
                            <Typography className="text-base font-bold text-right text-text-primary leading-normal">
                              {val?.value}
                            </Typography>
                          </div>
                        </>
                      ))}
                    </div>
                  </CardBody>
                </Card>
              </div>

              <div className="w-full">
                <Card className="w-full h-[239px] shadow-none border border-border-secondary m-0 p-0">
                  <CardHeader
                    floated={false}
                    shadow={false}
                    className="p-lg m-0 border-none"
                  >
                    <div className="h-[10px] p-3 w-full relative bg-white flex items-center">
                      <Typography
                        variant="h7"
                        className="text-md font-bold text-text-secondary"
                      >
                        Informasi Pakan
                      </Typography>
                    </div>
                  </CardHeader>
                  <hr className="border-t border-border-secondary w-full" />

                  <CardBody className="px-3xl pt-lg pb-2xl">
                    {dataInformationFeeding?.map((val) => (
                      <div className="py-sm">
                        <div key={val.id} className="flex justify-between">
                          <div className="w-full">
                            <Typography className="text-left text-base font-normal text-text-tertiary leading-normal">
                              {val?.label}
                            </Typography>
                          </div>

                          <div className="w-full flex justify-end">
                            <Typography className="text-left font-bold text-base text-text-primary leading-normal">
                              {val?.value}
                            </Typography>
                          </div>
                        </div>
                      </div>
                    ))}
                  </CardBody>
                </Card>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default RekapPeriodeBudidaya;
