/* eslint-disable jsx-a11y/role-supports-aria-props */
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import { CheckboxCust } from "./Checkbox";
import { ReactComponent as ChevronDown } from "../assets/icons/chevron_down.svg";
import {
  Popover,
  PopoverContent,
  PopoverHandler,
} from "@material-tailwind/react";

const DropdownWithSearchInput = ({
  options,
  placeholder,
  placeholderInputSearch,
  onSelect,
  disabled,
  search,
  setSearch,
  onSelectString,
  lastLabel,
  setLastLabel,
  positionClassName,
  selectCheckBox,
  setSelectCheckbox,
  className,
  onReset,
  disableSearch,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  //   const [selectCheckBox, setSelectCheckbox] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  //   const [selectLabel, setSelectLabel] = useState("");

  const handleSelect = (selectOptions) => {
    // const onClose = selectOptions.labels.length === 0;
    // setSelectLabel(selectOptions?.labels);
    setLastLabel(selectOptions?.labels);

    if (selectOptions && selectOptions === "selectAll") {
      setSelectAll(!selectAll);
      setSelectCheckbox(selectAll ? [] : options?.map((item) => item.id));
    } else {
      let update;
      if (selectCheckBox && selectCheckBox?.includes(selectOptions?.id)) {
        update = selectCheckBox?.filter((check) => check !== selectOptions.id);
      } else {
        update = [...selectCheckBox, selectOptions?.id];
      }
      setSelectCheckbox(update);
      onSelect(update);
      onSelectString && onSelectString(update.join(","));
    }

    if (setSearch) {
      setSearch("");
    }
    // setIsOpen(onClose);
  };

  const handleOpen = () => {
    setIsOpen(!isOpen);
    if(disableSearch !== true){
      setSearch("");
    }
    
  };

  return (
    <div className="relative">
      <Popover placement="bottom" open={isOpen} handler={handleOpen}>
        <PopoverHandler className="cursor-pointer w-full">
          <div>
            <button
              className={`${className} p-5 h-11 w-full px-xl py-2.5 border-border-primary justify-center items-center gap-md inline-flex text-base font-normal leading-normal rounded-md bg-white hover:bg-neutral-50 border text-text-placeholder focus:shadow-sm focus:shadow-gray-300 hover:border-border-secondary`}
            >
              <div
                className={`justify-between min-w-6 w-full items-center gap-2.5 flex cursor-pointer`}
              >
                <div className={`w-auto`}>
                  {selectCheckBox?.length === 0
                    ? placeholder
                    : selectCheckBox?.length === options?.length
                    ? placeholder
                    : `${lastLabel}`}
                </div>
              </div>
              <div className="w-6 h-6 relative flex items-center">
                <ChevronDown
                  className={`absolute mt-[2.5px] ${
                    isOpen ? "transform rotate-180" : ""
                  } text-text-placeholder`}
                />
              </div>
            </button>
          </div>
        </PopoverHandler>
        {/* <PopoverContent
          className={`${
            positionClassName ? positionClassName : "top-[415px] left-[520px]"
          } p-0 py-2 w-full overflow-y-auto max-h-[250px] max-w-[350px] gap-4 z-50 border border-border-primary`}
        >
          <ul>
            <div className="pb-2 border-b border-border-primary flex">
              <div className="relative px-2">
                <input
                  type="text"
                  className={`min-w-[310px] max-w-[370px] w-full border-none pr-5xl placeholder:text-text-placeholder text-text-secondary px-sm py-xs justify-start items-center gap-xxs inline-flex focus:outline-none `}
                  placeholder={placeholderInputSearch}
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
                <div className="absolute inset-y-0 right-0 flex items-center focus:outline-none">
                  <MagnifyingGlassIcon className="h-5 w-5" />
                </div>
              </div>
            </div>
            <div className="mt-2">
              {options?.length  > 0 ? (
                options?.map((item, index) => (
                  <li
                    key={index}
                    className="px-4 py-1 hover:bg-neutral-50 cursor-pointer"
                    aria-disabled={disabled}
                    onClick={() => handleSelect(item)}
                  >
                    <div className="flex justify-between">
                      <div className="flex justify-start items-center text-text-secondary">
                        {item.labels}
                      </div>
                      <div className="flex justify-end">
                        <CheckboxCust
                          onChange={() => handleSelect(item)}
                          checked={
                            selectCheckBox && selectCheckBox.includes(item?.id)
                          }
                        />{" "}
                      </div>
                    </div>
                  </li>
                ))
              ) : (
                <span className="text-text-secondary px-4">Tidak ada data</span>
              )}
            </div>
          </ul>
          <div className="flex w-full justify-start border-t px-4 py-3">
          <button
            className={`w-full p-0 h-6 justify-start items-center inline-flex text-base font-medium leading-normal rounded-md bg-white border-none text-neutral-700 shadow-none`}
          >
            <div
              className={`justify-start min-w-6 w-full text-sm items-center flex cursor-pointer text-blue-600 hover:text-blue-700`}
              onClick={onReset}
            >
              Atur ulang
            </div>
          </button>
        </div>
        </PopoverContent> */}
        <PopoverContent
          className={`border border-border-primary ${
            positionClassName ? positionClassName : "top-[415px] left-[520px]"
          } p-2 w-full max-w-[350px] gap-4 z-50`}
        >
          {disableSearch !== true && (
            <div className="sticky top-0 bg-white border-b border-border-primary flex pr-2">
              <div className="relative p-2 w-full">
                <input
                  type="text"
                  className="min-w-[310px] max-w-[370px] w-full border-none pr-5xl placeholder:text-text-placeholder text-text-secondary px-sm py-xs justify-start items-center gap-xxs inline-flex focus:outline-none"
                  placeholder={placeholderInputSearch}
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
                <div className="absolute inset-y-0 right-0 flex items-center focus:outline-none">
                  <MagnifyingGlassIcon className="h-5 w-5" />
                </div>
              </div>
            </div>
          )}

          <div className="overflow-y-auto max-h-[250px]">
            <ul>
              {options?.length > 0 ? (
                options?.map((item, index) => (
                  <li
                    key={index}
                    className="px-4 py-1 hover:bg-neutral-50 cursor-pointer"
                    aria-disabled={disabled}
                    onClick={() => handleSelect(item)}
                  >
                    <div className="flex justify-between">
                      <div className="flex justify-start items-center text-text-secondary">
                        {item.labels}
                      </div>
                      <div className="flex justify-end">
                        <CheckboxCust
                          onChange={() => handleSelect(item)}
                          checked={
                            selectCheckBox && selectCheckBox?.includes(item?.id)
                          }
                        />{" "}
                      </div>
                    </div>
                  </li>
                ))
              ) : (
                <li className="p-lg"><span className="text-text-secondary">Tidak ada data</span></li>
                
              )}
            </ul>
          </div>
          {onReset && (
            <div className="sticky bottom-0 bg-white border-t border-border-primary px-4 py-3">
              <button
                className="w-full p-0 h-6 justify-start items-center inline-flex text-base font-medium leading-normal rounded-md bg-white border-none text-neutral-700 shadow-none"
                onClick={onReset}
              >
                <div className="justify-start min-w-6 w-full text-sm items-center flex cursor-pointer text-blue-600 hover:text-blue-700">
                  Atur ulang
                </div>
              </button>
            </div>
          )}
        </PopoverContent>
      </Popover>
    </div>
  );
};

export default DropdownWithSearchInput;
