/* eslint-disable no-unused-vars */
import {
  Card,
  CardBody,
  CardHeader,
  Tab,
  Tabs,
  TabsHeader,
  Typography,
} from "@material-tailwind/react";
import { ReactComponent as ChevronLeft } from "../../../../assets/icons/chevron-left.svg";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import { ReactComponent as OpenPage } from "../../../../assets/icons/OpenPage.svg";
import api from "../../../../api/configApi";
import TagLabel from "../../../../atoms/TagLabel";
import RekapPeriodeBudidaya from "./TabDetailPeriode/RekapPeriodeBudidaya";
import Sapronak from "./TabDetailPeriode/Sapronak";
import RecordingHarian from "./Recording/RecordingHarian";
import Rekapitulasi from "./TabDetailPeriode/Rekapitulasi";
import * as Sentry from "@sentry/react";
import { Button } from "../../../../atoms/Button";
import { ReactComponent as IconLocation } from "../../../../assets/icons/icon_location_periode.svg";
import { ReactComponent as IconPPL } from "../../../../assets/icons/icon_ppl_periode.svg";
import { ReactComponent as IconUser } from "../../../../assets/icons/icon_user_periode.svg";
import ModalEditPeriod from "../../../../molecules/periodeBudidaya/ModalEditPeriode";
import { HiOutlinePencilAlt } from "react-icons/hi";
import moment from "moment";
import AlertWarning from "../../../../atoms/Alert";
import ToasterAlert from "../../../../atoms/Toaster";

const DetailPeriod = () => {
  const { id } = useParams();
  const location = useLocation()
  const pathSegments = location.pathname.split('/');  
  const lastSegment = pathSegments[pathSegments.length - 1];

  const [responseDetail, setResponseDetail] = useState(null);
  // const [settingType, setSettingType] = useState(() => {
  //   const params = new URLSearchParams(location.search);
  //   return params.get("tab") || "period";
  // });
  const [settingType, setSettingType] = useState(lastSegment)
  const [openEdit, setOpenEdit] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showNotif, setShowNotif] = useState(false)
  const [difference, setDifference] = useState({ years: 0, months: 0, days: 0 });

  const data = useMemo(
    () => [
      {
        label: "Ringkasan",
        value: "period",
      },
      {
        label: "Sapronak",
        value: "sapronak",
      },
      {
        label: "Data Harian",
        value: "dataharian",
      },
      {
        label: "Rekapitulasi",
        value: "rekapitulasi",
      },
    ],
    [] // Empty dependency array ensures this array is only created once.
  );

  const handleTabClick = (value) => {
    setSettingType(value);

    // Update URL with query parameter when tab changes
    navigate(`/periode-budidaya/periode-berjalan/${id}/${value}`)
  };

  useEffect(() => {
    const fetchDetailPeriod = async () => {
      try {
        setLoading(true);
        const responseId = await api.get(`/v1/rearingPeriods/${id}`);
        setResponseDetail(responseId?.data);
      } catch (error) {
        Sentry.captureException(error);
        return error;
      } finally {
        setLoading(false);
      }
    };

    fetchDetailPeriod();
  }, [id]);

  const handleRedirectTodetailCage = (val) => {
    localStorage.setItem("flagging detail periode", JSON.stringify(val));
    navigate(`/kandang/${val}`);
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const tabParam = params.get("tab");


    if (tabParam && data.some((tab) => tab.value === tabParam)) {
      setSettingType(tabParam); 
    } else {
      const lastPathSegment = location.pathname.split("/").pop();
      if (data.some((tab) => tab.value === lastPathSegment)) {
        setSettingType(lastPathSegment); 
      } else {
        setSettingType("period"); 
      }
    }
  }, [location.pathname, location.search, data]);



  return (
    <>
      <div className={`flex xl:overflow-hidden overflow-hidden`}>
        <div className="flex flex-col flex-1 overflow-hidden">
          <div className="p-4">
            <Card
              className={`w-full border border-neutral-200 shadow-none p-0 m-0 ${
                settingType === "data"
                  ? "w-[81%] sm:w-[85%] md:w-[80%] lg:w-[100%]"
                  : "w-full"
              }`}
            >
              <CardHeader
                floated={false}
                shadow={false}
                className="p-0 m-0 border-none"
              >
                <div className="flex justify-between w-full items-center">
                  <div className="ml-3xl">
                    <div className="flex justify-betwen items-center py-md">
                      <div
                        className="cursor-pointer"
                        onClick={() =>
                          navigate("/periode-budidaya/periode-berjalan")
                        }
                      >
                        <ChevronLeft />
                      </div>
                      <Typography className="text-xl font-bold p-2 text-text-primary leading-[30px]">
                        {responseDetail?.house?.labels}
                      </Typography>
                      <div
                        className="cursor-pointer"
                        onClick={() =>
                          handleRedirectTodetailCage(responseDetail?.house?.id)
                        }
                      >
                        <OpenPage />
                      </div>
                    </div>
                    <div className="flex pb-md">
                      <Typography className=" text-text-secondary text-sm font-normal flex items-center leading-tight gap-2">
                        <IconUser /> Pemilik :
                      </Typography>
                      <Typography className="ml-sm text-text-secondary font-semibold text-sm leading-tight flex items-center">
                        {responseDetail?.house?.owner?.fullName}
                      </Typography>
                    </div>

                    <div className="flex pb-md">
                      <Typography className="text-text-secondary text-sm font-normal flex items-center leading-tight gap-2">
                        <IconLocation /> Unit <div className="pl-xl">:</div> 
                      </Typography>
                      <Typography className="ml-sm text-text-secondary font-semibold text-sm leading-tight flex items-center">
                        {responseDetail?.house?.unit?.labels}
                      </Typography>
                    </div>

                    <div className="flex">
                      <Typography className=" text-text-secondary text-sm font-normal flex items-center leading-tight gap-2">
                        <IconPPL /> PPL <div className="pl-xl">:</div> 
                      </Typography>
                      <Typography className="ml-sm text-text-secondary font-semibold text-sm leading-tight flex items-center">
                        {responseDetail?.house?.ppl?.fullName}
                      </Typography>
                    </div>

                    <div className="flex mt-4 gap-3">
                      <TagLabel
                        children={`Periode ke :  ${responseDetail?.sequence}`}
                        type="primary"
                      />
                      <TagLabel
                        children={`Tanggal DOC in : ${moment(responseDetail?.docInDate).format(
                          "D/MM/YYYY"
                        )}`}
                        type="primary"
                      />
                      <TagLabel
                        children={`Populasi awal: ${responseDetail?.docInPopulation?.toLocaleString(
                          "id-ID"
                        )}`}
                        type="primary"
                      />
                      <TagLabel
                        children={`Populasi sekarang: ${responseDetail?.currentPopulation?.toLocaleString(
                          "id-ID"
                        )}`}
                        type="primary"
                      />
                    </div>
                  </div>

                  <div className="mx-2xl">
                    <Button
                    type="btnInfoOutlined"
                      size={"small"}
                      label="Edit Detail Periode"
                      onClick={() => setOpenEdit(!openEdit)}
                      className="cursor-pointer h-[40px]"
                      componentLeft={<HiOutlinePencilAlt className="w-5 h-5" />}
                    />
                  </div>
                </div>
                
                <div className="p-5 ml-[12px]">
                  {responseDetail?.inputDaysLate > 7 ? (
                    <div>
                      {/* <AlertWarning 
                        type="outlineWarning"
                        icon="warning"
                        children={`Data harian Anda belum diperbarui dalam 7 hari terakhir. Mohon lakukan pembaruan untuk memastikan pemantauan yang optimal.`}
                      />   */}
                      <ToasterAlert
                        label="Data harian Anda belum diperbarui dalam 7 hari terakhir. Mohon lakukan pembaruan untuk memastikan pemantauan yang optimal."
                        type="warning"
                      />
                    </div>
                  ) : (
                    <div>
                      <ToasterAlert
                        label={`${responseDetail?.lastDailyRecordDate === null ? "Anda baru memulai periode ini. Pastikan untuk mulai menginput data harian Anda." :  `Data terakhir di update ${responseDetail?.inputDaysLate} hari yang lalu (${moment(responseDetail?.lastDailyRecordDate).format('L')})` }`}
                      />
                    </div>
                  )}
                </div>
                
              </CardHeader>

              <CardBody className="p-0 hidden:overflow-scroll mt-4">
                <div className="border-t border-gray-300 w-full mt-[2px] pb-2" />
                <div>
                <Tabs value={settingType} className="m-0 p-0 px-2 max-w-[85%] md:w-[500%]">
                  <TabsHeader
                    className="rounded-none bg-transparent p-0 m-0 z-0"
                    indicatorProps={
                      location.pathname.endsWith(settingType) ? { className: "bg-transparent border-b-[3px] border-primary-300 shadow-none rounded-none gap-x-6 p-0 m-0"} : null
                      
                    }
                  >
                    {data.map(({ label, value }) => (
                      <Tab
                        key={value}
                        value={value}
                        onClick={() => handleTabClick(value)}
                        className={`m-0 p-0 px-lg text-sm font-semibold w-full ${
                          settingType === value
                            ? "bg-white text-text-secondary p-1 h-9"
                            : "bg-white text-text-quarterary p-1 h-9"
                        }`}
                      >
                        <Typography variant="h6" className="gap-y-5 w-full">
                          {label}
                        </Typography>
                      </Tab>
                    ))}
                  </TabsHeader>
                </Tabs>

                  <div className="w-full flex justify-start">
                    {settingType === "period" ? (
                      <RekapPeriodeBudidaya ResponseDetail={responseDetail} loading={loading} />
                    ) : settingType === "sapronak" ? (
                      <Sapronak periodId={id} />
                    ) : settingType === "dataharian" ? (
                      <RecordingHarian />
                    ) : settingType === "rekapitulasi" ? (
                      <Rekapitulasi />
                    ) : null}
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>
        </div>
      </div>

      <ModalEditPeriod
        isOpen={openEdit}
        getId={id}
        onClose={() => setOpenEdit(!openEdit)}
      />
    </>
  );
};

export default DetailPeriod;
