import React from "react";

const Ovk = () => {
  return (
    <div className="p-4">
      <h2 className="text-xl font-semibold mb-4">Stok OVK</h2>
    </div>
  );
};

export default Ovk;
