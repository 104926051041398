import React from "react";

import MasterPeriodeTambahFeature from "./feature";
import { MasterPeriodeTambahStore } from "./hook";

const MasterPeriodeTambah = () => {
  return (
    <MasterPeriodeTambahStore>
      <MasterPeriodeTambahFeature />
    </MasterPeriodeTambahStore>
  );
};

export default MasterPeriodeTambah;
