import { useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { getMe } from "./molecules/features/authSlice";

const PrivateRouter = () => {
  const auth = Cookies.get("accessToken");
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(getMe());
  }, [dispatch]);

  // const isPermitted = user?.permissions?.some(
  //   (permission) =>
  //     permission === "Lihat daftar_Kandang" ||
  //     permission === "Lihat daftar_Periode Budidaya_Periode Berjalan" ||
  //     permission === "Lihat daftar_Periode Budidaya_Periode Selesai" ||
  //     permission === "Lihat daftar_Master_OVK" ||
  //     permission === "Lihat daftar_Master_DOC" ||
  //     permission === "Lihat daftar_Master_Pakan" ||
  //     permission === "Lihat daftar_Pengaturan Pengguna_Role Akses" ||
  //     permission === "Lihat daftar_Pengaturan Pengguna_Pengguna" ||
  //     permission === "Lihat daftar_Dashboard" ||
  //     permission === "Lihat detail_Periode Budidaya_Periode Berjalan" ||
  //     permission === "Mulai periode_Periode Budidaya_Periode Berjalan" ||
  //     permission === "Ekspor laporan_Periode Budidaya_Periode Selesai" ||
  //     permission === "Lihat daftar_Master_OVK" ||
  //     permission === "Lihat detail_Master_OVK" ||
  //     permission === "Tambah data_Master_OVK" ||
  //     permission === "Ubah data_Master_OVK" ||
  //     permission === "Hapus data_Master_OVK" ||
  //     permission === "Lihat daftar_Master_DOC" ||
  //     permission === "Lihat detail_Master_DOC" ||
  //     permission === "Tambah data_Master_DOC" ||
  //     permission === "Ubah data_Master_DOC" ||
  //     permission === "Hapus data_Master_DOC" ||
  //     permission === "Lihat daftar_Master_Pakan" ||
  //     permission === "Lihat detail_Master_Pakan" ||
  //     permission === "Tambah data_Master_Pakan" ||
  //     permission === "Ubah data_Master_Pakan" ||
  //     permission === "Hapus data_Master_Pakan" ||
  //     permission === "Lihat daftar_Pengaturan Pengguna_Pengguna" ||
  //     permission === "Lihat detail_Pengaturan Pengguna_Pengguna" ||
  //     permission === "Tambah data_Pengaturan Pengguna_Pengguna" ||
  //     permission === "Ubah data_Pengaturan Pengguna_Pengguna" ||
  //     permission === "Hapus data_Pengaturan Pengguna_Pengguna" ||
  //     permission === "Lihat daftar_Pengaturan Pengguna_Role Akses" ||
  //     permission === "Lihat detail_Pengaturan Pengguna_Role Akses" ||
  //     permission === "Tambah data_Pengaturan Pengguna_Role Akses" ||
  //     permission === "Ubah data_Pengaturan Pengguna_Role Akses" ||
  //     permission === "Hapus data_Pengaturan Pengguna_Role Akses" ||
  //     permission === "Lihat daftar_Kandang" ||
  //     permission === "Lihat detail_Kandang" ||
  //     permission === "Tambah data_Kandang" ||
  //     permission === "Ubah data_Kandang" ||
  //     permission === "Hapus data_Kandang"
  // )

  const permittedPermissions = [
    "Lihat daftar_Kandang",
    "Lihat daftar_Periode Budidaya_Periode Berjalan",
    "Lihat daftar_Periode Budidaya_Periode Selesai",
    "Lihat daftar_Master_OVK",
    "Lihat daftar_Master_DOC",
    "Lihat daftar_Master_Pakan",
    "Lihat daftar_Pengaturan Pengguna_Role Akses",
    "Lihat daftar_Pengaturan Pengguna_Pengguna",
    "Lihat daftar_Dashboard",
    "Lihat detail_Periode Budidaya_Periode Berjalan",
    "Mulai periode_Periode Budidaya_Periode Berjalan",
    "Ekspor laporan_Periode Budidaya_Periode Selesai",
    "Lihat detail_Master_OVK",
    "Tambah data_Master_OVK",
    "Ubah data_Master_OVK",
    "Hapus data_Master_OVK",
    "Lihat detail_Master_DOC",
    "Tambah data_Master_DOC",
    "Ubah data_Master_DOC",
    "Hapus data_Master_DOC",
    "Lihat detail_Master_Pakan",
    "Tambah data_Master_Pakan",
    "Ubah data_Master_Pakan",
    "Hapus data_Master_Pakan",
    "Lihat daftar_Master_Region",
    "Lihat detail_Master_Region",
    "Tambah data_Master_Region",
    "Ubah data_Master_Region",
    "Lihat daftar_Master_Unit",
    "Lihat detail_Master_Unit",
    "Tambah data_Master_Unit",
    "Ubah data_Master_Unit",
    "Hapus data_Master_Unit",
    "Lihat daftar_Master_Bakul",
    "Lihat detail_Master_Bakul",
    "Tambah data_Master_Bakul",
    "Ubah data_Master_Bakul",
    "Hapus data_Master_Bakul",
    "Lihat daftar_Panen",
    "Lihat daftar data masuk_Panen",
    "Lihat daftar selesai review_Panen",
    "Lihat detail_Panen",
    "Ubah data_Panen",
    "Lihat detail_Pengaturan Pengguna_Pengguna",
    "Tambah data_Pengaturan Pengguna_Pengguna",
    "Ubah data_Pengaturan Pengguna_Pengguna",
    "Hapus data_Pengaturan Pengguna_Pengguna",
    "Lihat detail_Pengaturan Pengguna_Role Akses",
    "Tambah data_Pengaturan Pengguna_Role Akses",
    "Ubah data_Pengaturan Pengguna_Role Akses",
    "Hapus data_Pengaturan Pengguna_Role Akses",
    "Lihat detail_Kandang",
    "Tambah data_Kandang",
    "Ubah data_Kandang",
    "Hapus data_Kandang",
    "Lihat daftar_Mutasi",
    "Lihat detail_Mutasi",
    "Tambah data_Mutasi",
    "Ubah data_Mutasi",
    "Hapus data_Mutasi",
  ];

  // Remove duplicate permissions
  const uniquePermissions = [...new Set(permittedPermissions)];

  const isPermitted =
    user?.permissions?.some((permission) =>
      uniquePermissions.includes(permission)
    ) ?? false;
  localStorage.setItem("permitted", JSON.stringify(isPermitted));

  return auth ? <Outlet /> : <Navigate to="/" />;
};

export default PrivateRouter;
