import React from "react";
import { Card, Typography } from "@material-tailwind/react";
import { Button } from "../../atoms/Button";
import waIcon from "../../img/Social.png";
import smsIcon from "../../img/message-text-square-02.png";
import { FaChevronRight } from "react-icons/fa";

const ModalVerificationOTP = ({
  phone,
  onClose,
  onSendWhatsAppOTP,
  onSendSMSOTP,
}) => {
  const maskedPhoneNumber = phone.slice(-4);

  return (
    <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-50">
      <Card className="w-[400px] bg-white rounded-xl relative p-3xl">
        <button
          className="absolute top-2 right-2 text-gray-500"
          onClick={onClose}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
        <Typography type="h2" className="text-xl text-center font-semibold text-slate-900 ">
          Pilih Metode Verifikasi
        </Typography>
        <Typography type="small" className="text-sm text-center text-slate-800 ">
          Silahkan pilih metode pengiriman OTP untuk melanjutkan proses masuk
        </Typography>

        <Button
          onClick={onSendWhatsAppOTP}
          className="mt-2xl"
          size="large"
          type="btnSecondary"
          componentLeft={<img src={waIcon} alt="WA Icon" />}
          componentRight={
            <FaChevronRight className="h-5 w-5 text-slate-600 hover:text-neutral-800" />
          }
          label={
            <>
             <div className="flex">
                <Typography
                  type="small"
                  className="text-slate-700 text-base font-semibold text-left"
                >
                  Kirim lewat WhatsApp
                </Typography>
                <Typography
                  type="small"
                  className="text-neutral-500 text-sm font-medium pl-[80px]"
                >
                  (+62) **********{maskedPhoneNumber}
                </Typography>
             </div>
            </>
          }
        />

         <Button
          onClick={onSendWhatsAppOTP}
          className="mt-2xl"
          size="large"
          type="btnSecondary"
          componentLeft={<img src={waIcon} alt="WA Icon" />}
          componentRight={
            <FaChevronRight className="h-5 w-5 text-slate-600 hover:text-neutral-800" />
          }
          label={
            <>
             <div className="flex">
                <Typography
                  type="small"
                  className="text-slate-700 text-base font-semibold text-left"
                >
                  Kirim lewat WhatsApp
                </Typography>
                <Typography
                  type="small"
                  className="text-neutral-500 text-sm font-medium pl-[80px]"
                >
                  (+62) **********{maskedPhoneNumber}
                </Typography>
             </div>
            </>
          }
        />

        <Button
          onClick={onSendSMSOTP}
          className="mt-2xl"
          size="large"
          type="btnSecondary"
          componentLeft={<img src={smsIcon} alt="SMS Icon" />}
          componentRight={
            <FaChevronRight className="h-5 w-5 text-slate-600 hover:text-neutral-800" />
          }
          label={
            <>
              <Typography
                type="small"
                className="text-slate-700 text-base font-semibold text-left pr-[125px]"
              >
                Kirim lewat SMS
              </Typography>
              <Typography
                type="small"
                className="text-neutral-500 text-sm font-medium text-left"
              >
                (+62) **********{maskedPhoneNumber}
              </Typography>
            </>
          }
        />
      </Card>
    </div>
  );
};

export default ModalVerificationOTP;
