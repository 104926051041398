import { Spinner } from "@material-tailwind/react"

const Loading = ({ size, withText }) => {
  const getSizeSpinner = () =>{
    switch (size) {
      case "small": 
        return "h-6 w-6";
      case "medium": 
        return "h-8 w-8"
      case "large":
        return "h-10 w-10"
      case "xlarge": 
        return "h-12 w-12"
      default:
        return "h-4 w-4"
    }
  }

  return (
   <>
     <div className="relative">
      <div className="flex flex-col">
        <div className="items-center">
          <Spinner
            className={getSizeSpinner()}
            color="amber"
          />
        </div>
        {withText && (
          <div className="pt-3 ml-[-3px]">
            Loading...
          </div>
        )}
      </div>
     </div>
   </>
  )
}

export default Loading