
const PasswordStrengthIndicator = ({ strength }) => {
  let colorClass = "";
  let text = "";

  switch (strength) {
    case "weak":
      colorClass = "text-red-500";
      text = "Sangat Lemah";
      break;
    case "medium":
      colorClass = "text-orange-500";
      text = "Sedang";
      break;
    case "strong":
      colorClass = "text-green-500";
      text = "Sangat Kuat";
      break;
    default:
      colorClass = "text-gray-500";
      text = "";
  }

  return (
    <div className="mt-2">
      <span className="text-slate-700 text-xs font-semibold leading-[18px]">
        Kekuatan:
      </span>{" "}
      <span className={`text-xs font-normal leading-[18px] ${colorClass}`}>
        {text}
      </span>
    </div>
  );
};

export default PasswordStrengthIndicator;
