import React, { useEffect, useState } from "react";

import { Card, Typography } from "@material-tailwind/react";
import { useNavigate } from "react-router-dom";

import { Logo } from "../../atoms/Logo";
import { Button } from "../../atoms/Button";
import { FaChevronLeft } from "react-icons/fa";
import emailSent from "../../img/Email sent.png";

const EmailSentPage = () => {
  const [email, setEmail] = useState("")
  const navigate = useNavigate();
  const handleBack = () => {
    navigate("/");
  };

  useEffect(() => {
    const dataEmail = JSON.parse(localStorage.getItem("emailSender"))
    setEmail(dataEmail)
  }, [])

  // console.log(email);

  const sensorMiddleCharacter = (text, start, end) => {
    const chars = text?.split("")
    for (let i = start; i < chars?.length - end; i ++) {
      chars[i] = 'x'
    }

    return chars?.join("")
  }

  // censored email
  const partialHiddenEmail = (email) => {
    const emailParts = email?.split('@')
    const username =  emailParts?.length > 0 ? emailParts[0] : undefined
    const domain = emailParts?.length > 0 ? emailParts[1] : undefined

    // console.log(username);

    const sensorUsername = sensorMiddleCharacter(username, 2, 2)
    const senesoDomain = sensorMiddleCharacter(domain, 1, 6)

    return `${sensorUsername}@${senesoDomain}`
  }

  return (
    <div className="flex flex-col md:flex-row h-screen">
      <div className="w-full bg-white md:p-10 py-10 px-5 flex flex-col justify-between">
        <Logo />

        <div className="flex justify-center items-center min-h-[700px]">
          <Card className="xl:w-[484px] xl:h-[350px] w-auto xl:max-w-sm md:min-w-sm shadow-md border-t-2 border-gray-100 rounded-xl p-4xl ">
            <div className="flex-col justify-center items-center inline-flex">
              <img src={emailSent} alt="emailSent" />
              <Typography
                type="h2"
                className="text-xl text-center font-bold text-neutral-900 mb-xs"
              >
                Cek Email Anda
              </Typography>
              <Typography
                type="small"
                className="text-sm text-center font-normal text-neutral-500 mb-4xl"
              >
                Silahkan periksa tautan pemulihan kata sandi yang telah kami
                kirim ke email: <span className="text-neutral-900 text-sm font-semibold leading-tight">{partialHiddenEmail(email)}</span>
              </Typography>
            </div>
            <Button
              size="small"
              type="btnLinkBlack"
              componentLeft={
                <FaChevronLeft className="h-3 w-3 text-slate-600 hover:text-neutral-800 mt-[7px]" />
              }
              label="Kembali ke halaman masuk"
              onClick={handleBack}
            />
          </Card>
        </div>

        <h2 className="text-gray-600 h-auto text-sm mt-4">
          © 2023 PT Integrasi Teknologi Unggas. All right reserved
        </h2>
      </div>
    </div>
  );
};

export default EmailSentPage;
