// PhoneInput.jsx
import React, { useState, useCallback } from "react";
import Input from "../../atoms/Input";
import { countryData } from "../../data/Country";
import api from "../../api/configApi";
import { FaCheck } from "react-icons/fa6";
import debounce from "lodash.debounce";
// import { FaChevronDown } from "react-icons/fa";
import {
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  Button,
} from "@material-tailwind/react";

const PhoneInputForm = ({
  phone,
  setPhone,
  setCountryCode,
  phoneIsNull,
  isLabelsActive,
  phoneValid,
  labelDisable,
  verification,
  setVerification,
  phoneBefore,
  isEdit,
  onKeyDown
}) => {
  const [selectedCountry, setSelectedCountry] = useState("ID");
  const { flag, labelCode } = countryData[selectedCountry];
  const [isPhoneEmpty, setIsPhoneEmpty] = useState(false);
  const [isPhoneValid, setIsPhoneValid] = useState(true);
  const [phoneErrorMessage, setPhoneErrorMessage] = useState("");

  const validatePhone = (inputPhone) => {
    const phoneRegex = /^\d{9,13}$/;

    if (!phoneRegex.test(inputPhone)) {
      setIsPhoneValid(false);
      setPhoneErrorMessage("Nomor telepon tidak valid");
      phoneValid && phoneValid(false);
    } else {
      setIsPhoneValid(true);
      setPhoneErrorMessage("");
      phoneValid && phoneValid(true);
    }
  };

  // const handlePhoneChange = async (e) => {
  //   let newPhone = e.target.value;

  //   // Pastikan hanya angka yang diterima
  //   newPhone = newPhone.replace(/\D/g, "");

  //   // console.log(verification);
  //   if (
  //     verification === null ||
  //     verification === true ||
  //     verification === false
  //   ) {
  //     if (newPhone.length >= 10) {
  //       try {
  //         let formattedPhone = newPhone;

  //         // Hilangkan angka 0 di depan jika ada
  //         // formattedPhone = formattedPhone.replace(/^0+/, "");

  //         // if (formattedPhone !== "") {
  //         // Jika phone tidak mengandung kode negara, tambahkan countryCode
  //         if (!formattedPhone.startsWith(countryData[selectedCountry].code)) {
  //           formattedPhone = countryData[selectedCountry].code + formattedPhone;
  //         }
  //         const response = await api.get(
  //           `/v1/users/phoneAvailable/${formattedPhone}`
  //         );

  //         setVerification(response?.data?.isAvailable);

  //         console.log("response: ", response?.data?.isAvailable);
  //       } catch (error) {
  //         console.error("Error verification phone:", error);
  //       }
  //     } else if (newPhone.length < 10) {
  //       setVerification(null);
  //     }
  //   }

  //   if (!isNaN(newPhone) && newPhone.length < 14) {
  //     setCountryCode(countryData[selectedCountry].code);
  //     setPhone(newPhone);
  //     validatePhone(newPhone);
  //   }
  // };

  const handlePhoneChange = async (e) => {
    let newPhone = e.target.value;

    // Pastikan hanya angka yang diterima
    newPhone = newPhone.replace(/\D/g, "");

    if (!isNaN(newPhone) && newPhone.length < 14) {
      setCountryCode(countryData[selectedCountry].code);
      setPhone(newPhone);
      validatePhone(newPhone);
    }
    if (
      verification === null ||
      verification === true ||
      verification === false
    ) {
      // Hanya lakukan verifikasi jika panjang nomor minimal 10
      if (newPhone.length >= 10) {
        debounceCheckPhone(newPhone);
      } else {
        setVerification(null);
      }
    }
  };

  // Fungsi untuk verifikasi nomor telepon
  const checkPhoneAvailability = async (phone) => {
    try {
      let formattedPhone = phone;

      // Hilangkan angka 0 di depan jika ada
      formattedPhone = formattedPhone.replace(/^0+/, "");
      // Jika phone tidak mengandung kode negara, tambahkan countryCode
      if (!formattedPhone.startsWith(countryData[selectedCountry].code)) {
        formattedPhone = countryData[selectedCountry].code + formattedPhone;
      }

      // console.log("formatted phone: ", formattedPhone);
      const response = await api.get(
        `/v1/users/phoneAvailable/${formattedPhone}`
      );

      if (isEdit === true && (phoneBefore && phoneBefore === formattedPhone)) {
        setVerification(true);
      } else {
        setVerification(response?.data?.isAvailable);
      }

      // console.log("is available: ", response?.data?.isAvailable);
    } catch (error) {
      console.error("Error verification phone:", error);
    }
  };

  // Buat fungsi debounce
  // eslint-disable-next-line
  const debounceCheckPhone = useCallback(
    debounce((phone) => {
      checkPhoneAvailability(phone);
    }, 100),
    [selectedCountry, countryData]
  );

  // const debounceCheckPhone = useRef(
  //   debounce(async (phone) => {
  //     try {
  //       let formattedPhone = phone;

  //       console.log(isEdit)

  //       // Hilangkan angka 0 di depan jika ada
  //       formattedPhone = formattedPhone.replace(/^0+/, "");

  //       if (!formattedPhone.startsWith(countryData[selectedCountry].code)) {
  //         formattedPhone = countryData[selectedCountry].code + formattedPhone;
  //       }

  //       const response = await api.get(
  //         `/v1/users/phoneAvailable/${formattedPhone}`
  //       );
  //       if(isEdit === true && (phoneBefore && phoneBefore === formattedPhone)) {
  //         setVerification(true);
  //       } else {
  //         setVerification(response?.data?.isAvailable);
  //       }

  //       // console.log("response: ", response?.data?.isAvailable);
  //     } catch (error) {
  //       console.error("Error verification phone:", error);
  //     }
  //   }, 500)
  // ).current;

  // useEffect(() => {
  //   return () => {
  //     // Bersihkan debounce saat komponen di-unmount
  //     debounceCheckPhone.cancel();
  //   };
  // }, [debounceCheckPhone]);

  const handlePhoneBlur = () => {
    // Validasi saat fokus keluar dari field phone
    // console.log({ phoneIsNull });
    if (phoneIsNull && phoneIsNull === true) {
      console.log("nomor telepon boleh kosong");
    } else {
      if (phone.trim() === "") {
        setIsPhoneEmpty(true);
      } else {
        setIsPhoneEmpty(false);
      }
    }
  };

  const handleCountryChange = (countryCode) => {
    // masih error countryCode undefined saat memilih list kode negara
    // console.log(countryCode);
    setSelectedCountry(countryCode);
  };

  return (
    <div className="">
      {isLabelsActive !== false && (
        <div className="mb-md">
          <label
            htmlFor="phone"
            className="text-sm font-medium text-neutral-900"
          >
            Nomor Telepon
          </label>
        </div>
      )}

      <div className="relative">
        <Input
          type="tel"
          id="phone"
          placeholder="Masukkan nomor telepon"
          size="medium"
          className={`${isPhoneEmpty && "border-red-500"}`}
          value={phone}
          onChange={handlePhoneChange}
          onBlur={handlePhoneBlur}
          onKeyDown={onKeyDown}
          componentLeft={
            <Menu placement="bottom-start">
              <MenuHandler>
                <Button
                  ripple={false}
                  variant="text"
                  color="blue-gray"
                  className="flex h-10 justify-center items-center gap-1 border-none pl-1"
                  disabled
                >
                  {flag}
                  <span className="text-neutral-900 text-base font-normal leading-normal ml-auto">
                    {labelCode}{" "}
                  </span>{" "}
                  {/* <FaChevronDown className="h-3 w-3 text-neutral-400" />{" "} */}
                  <span className="text-gray-300 text-base font-normal leading-normal ml-xs mr-auto">
                    |
                  </span>
                </Button>
              </MenuHandler>
              <MenuList className="max-h-[20rem] max-w-[18rem]">
                {Object.keys(countryData).map((countryCode) => (
                  <MenuItem
                    key={countryCode}
                    value={countryCode}
                    className="flex items-center gap-2 my-md"
                    onClick={
                      countryCode
                        ? (e) => handleCountryChange(e.target.value)
                        : "ID"
                    }
                  >
                    {countryData[countryCode].flag}
                    <span className="ml-auto">
                      {countryData[countryCode].code}
                    </span>
                  </MenuItem>
                ))}
              </MenuList>
            </Menu>
          }
          componentRight={
            verification === true && <FaCheck className="text-green-600" />
          }
        />
        {phone === "" && isPhoneEmpty && (
          <p className="text-red-500 text-sm mt-1">
            Nomor telepon tidak boleh kosong.
          </p>
        )}

        {verification === false && (
          <p className="text-red-500 text-sm mt-1">
            Nomor telah terdaftar, silakan masukkan nomor lain.
          </p>
        )}

        {!isPhoneValid && phone && (
          <p className="text-red-500 text-sm mt-1">{phoneErrorMessage}</p>
        )}
      </div>
    </div>
  );
};

export default PhoneInputForm;
