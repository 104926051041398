import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Switch,
  Typography,
  List,
  ListItem,
  Chip,
} from "@material-tailwind/react";
import Input from "../../../atoms/Input";
import api from "../../../api/configApi";
import { Button } from "../../../atoms/Button";
import { CheckboxCust } from "../../../atoms/Checkbox";
import { useParams } from "react-router-dom";
import ConfirmDialog from "../../../atoms/ConfirmDialog";
import AlertNotification from "../../../atoms/AlertNotification";
import { FaCheckCircle } from "react-icons/fa";
import Dropdown from "../../../atoms/DropdownWithReset";

const EditUserRole = () => {
  const navigate = useNavigate();
  const [roleName, setRoleName] = useState("");
  const [isActive, setIsActive] = useState(null);
  const [menus, setMenus] = useState([]);
  const [permissionValues, setPermissionValues] = useState({});
  const [permissions, setPermissions] = useState([]);
  const [activeMenuId, setActiveMenuId] = useState("DASHBOARD");
  const [isEditSuccess, setEditSuccess] = useState(false);
  const [isEditError, setIsEditError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  // const []
  const [selectedLevels, setSelectedLevels] = useState("")

  const { id } = useParams();
  const roleId = id;

  const [isEditDialogOpen, setEditDialogOpen] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [selectAllValues, setSelectAllValues] = useState({});
  const [levels, setLevels] = useState([])
  const [selectLevels, setSelectLevels] = useState(null)

  const handleOpenEditDialog = () => {
    setEditDialogOpen(true);
  };

  const handleCloseEditDialog = () => {
    setEditDialogOpen(false);
  };

  useEffect(() => {
    const fetchRoleData = async () => {
      try {
        const response = await api.get(`/v1/roles/${roleId}`);

        const roleData = response.data;
        setRoleName(roleData.name);
        setIsActive(roleData.isActive);
        setSelectedLevels(roleData.level.labels)

        // Set permission values dari data role yang diterima
        setPermissionValues(
          roleData.permissions.length > 0 &&
            roleData.permissions.reduce((acc, menu) => {
              const menuPermissions = menu.permissions.reduce(
                (menuAcc, permission) => {
                  menuAcc[permission.id] = {
                    id: permission.id,
                    label: permission.label,
                  };
                  return menuAcc;
                },
                {}
              );

              acc[menu.menu] = menuPermissions;
              return acc;
            }, {})
        );
      } catch (error) {
        console.error("Gagal mengambil data role:", error.message);
      }
    };

    fetchRoleData();
  }, [roleId]);

  useEffect(() => {
    // Load data menu menggunakan GET request
    const fetchMenuData = async () => {
      try {
        const response = await api.get("/v1/roles/menus");
        setMenus(response.data.contents);
      } catch (error) {
        console.error("Gagal mengambil data menu:", error.message);
      }
    };

    const fetchPermissions = async () => {
      try {
        const response = await api.get("/v1/roles/permissions", {
          params: {
            menu: activeMenuId,
          },
        });
        setPermissions(response.data.contents);
      } catch (error) {
        console.error("Error fetching menus:", error);
      }
    };

    const fetchRole = async () => {
      try {
        const responseRole = await api.get('/v1/roles/levels')
        setLevels(responseRole.data.contents)
      } catch (error) {
        console.error("error fetching role", error)
      }
    }

    fetchRole()
    fetchMenuData();
    fetchPermissions();
  }, [activeMenuId]);

  useEffect(() => {
    if (activeMenuId && !selectAllValues[activeMenuId]) {
      // Initialize selectAllValues for the new active menu
      const initialSelectAllValues = {};
      permissions.forEach((permission) => {
        initialSelectAllValues[permission.id] = false;
      });
      setSelectAllValues({
        ...selectAllValues,
        [activeMenuId]: initialSelectAllValues,
      });
      setSelectAll(false);
    } else if (activeMenuId) {
      // Restore selectAll state for the previous active menu
      setSelectAll(
        Object.values(selectAllValues[activeMenuId]).some((value) => value)
      );
    }
  }, [activeMenuId, permissions, selectAllValues]);

  const handleSelectAllChange = () => {
    const updatedPermissionValues = { ...permissionValues };
    const updatedSelectAllValues = { ...selectAllValues };

    if (selectAll) {
      // Uncheck all permissions for the active menu
      updatedPermissionValues[activeMenuId] = {};
      permissions.forEach((permission, index) => {
        updatedPermissionValues[activeMenuId][permission.id] = false;
        updatedSelectAllValues[activeMenuId][index] = false;
      });
    } else {
      // Check all permissions for the active menu
      updatedPermissionValues[activeMenuId] = {};
      permissions.forEach((permission, index) => {
        updatedPermissionValues[activeMenuId][permission.id] = true;
        updatedSelectAllValues[activeMenuId][index] = true;
      });
    }

    setSelectAll(!selectAll);
    setPermissionValues(updatedPermissionValues);
    setSelectAllValues(updatedSelectAllValues);
  };

  const handlePermissionCheckboxChange = (menuId, permission) => {
    const updatedPermissionValues = { ...permissionValues };

    if (!updatedPermissionValues[menuId]) {
      updatedPermissionValues[menuId] = {};
    }

    updatedPermissionValues[menuId][permission.id] =
      !updatedPermissionValues[menuId][permission.id];
    setPermissionValues(updatedPermissionValues);
  };

  // console.log("get levels", selectLevels)

  const handleSaveUserRole = async () => {
    const updatedUserRole = {
      name: roleName,
      isActive: isActive,
      level: selectLevels,
      permissions: menus
        .filter(
          (menu) =>
            permissionValues[menu.id] &&
            Object.values(permissionValues[menu.id]).some((value) => value)
        )
        .map((menu) => ({
          menu: menu.id,
          permissions: Object.keys(permissionValues[menu.id])
            .filter((permissionId) => permissionValues[menu.id][permissionId])
            .map((permissionId) => ({ id: permissionId })),
        })),
    };
    try {
      await api.put(`/v1/roles/${roleId}`, updatedUserRole);
      setRoleName("");
      setIsActive(true);
      setPermissionValues({});
      setEditSuccess(true);

      navigate("/pengaturan-pengguna/role-akses");

      localStorage.setItem(
        "responseSuccessEdit",
        JSON.stringify(!isEditSuccess)
      );
    } catch (error) {
      handleCloseEditDialog();
      console.error("Gagal mengubah user role:", error.response.data.error);
      setErrorMessage(
        error.response.data.error?.name ||
          error.response.data.error?.company ||
          error.response.data.error
      );
      setIsEditError(true);
      setTimeout(() => setIsEditError(false), 3000);
    }
  };

  const handleSelectLevel = (select) => {
    // setLevels(select.id)
    setSelectLevels(select.id)
  }

  const handleBack = () => {
    setActiveMenuId(null);
    navigate("/pengaturan-pengguna/role-akses");
  };


  return (
    <div className="p-4">
      <Card className="border">
        <CardHeader floated={false} shadow={false} className="rounded-b-none">
          <Typography className="text-xl font-bold text-text-primary">
            Ubah Role
          </Typography>
        </CardHeader>
        <CardBody className="p-5">
          <div className="mb-4 grid grid-cols-1 gap-4 w-full sm:grid-cols-2 sm:max-w-[75%]">
            <div>
              <label className="block text-sm font-semibold text-text-primary">
                Pilih Level{" "}
                <span className="text-red-500 text-sm font-normal leading-tight">
                  *
                </span>
              </label>

              <div className="max-w-sm mt-md">
                <Dropdown
                  placeholder={selectedLevels}
                  options={levels}
                  setSelectedLabel={selectLevels}
                  onSelect={handleSelectLevel}
                  selectedValue={selectedLevels}
                  defaultSelected={true}
                  // setSelectedLevel={setSelectLevels}
                ></Dropdown>
              </div>
            </div>

            <div>
              <label className="block text-sm font-semibold text-text-primary">
                Role{" "}
                <span className="text-red-500 text-sm font-normal leading-tight">
                  *
                </span>
              </label>
              <Input
                type="text"
                id="role"
                placeholder="Masukkan nama role"
                className="max-w-sm mt-md"
                size="medium"
                value={roleName}
                onChange={(e) => setRoleName(e.target.value)}
              />
            </div>
          </div>
          <label className="block text-sm font-semibold text-text-primary mb-md">
              Akses
            </label>
          <Card className="shadow-none border border-border-secondary">
            <CardHeader
              floated={false}
              shadow={false}
              className="grid grid-cols-3 gap-4 m-0 border border-border-secondary rounded-b-none p-0 bg-neutral-50"
            >
              <div className="m-0 p-lg rounded-md">
                <Typography className="flex pl-3 font-semibold items-center leading-normal gap-5 text-sm text-text-quarterary">
                  Akses menu
                </Typography>
              </div>
              <div className="col-span-2 border-l-2 h-full m-0 p-lg">
                <Typography className="flex pl-3 font-semibold items-center leading-normal gap-5 text-sm text-text-quarterary">
                  Aksi yang diizinkan
                </Typography>
              </div>
            </CardHeader>
            <CardBody className="grid grid-cols-3 gap-4 m-0 p-0 border rounded-md rounded-t-none">
              <div
                className="col-span-1"
                style={{ maxHeight: "320px", overflowY: "auto" }}
              >
                <List>
                  {menus.map((menu) => (
                    <>
                      <ListItem
                        className="grid grid-cols-2 gap-4 border-none mx-0"
                        onClick={() => setActiveMenuId(menu.id)}
                        selected={activeMenuId === menu.id}
                      >
                        <Typography className="flex font-medium items-center leading-none opacity-90 gap-5 text-base text-text-quarterary">
                          {menu.labels}
                        </Typography>
                        <Typography className="flex font-medium leading-none opacity-90 gap-5 text-base items-end justify-end">
                          {permissionValues[menu.id] &&
                            Object.values(permissionValues[menu.id]).filter(
                              (value) => value
                            ).length > 0 && (
                              <Chip
                                className="w-6 h-[26px] px-1.5 py-0.5 bg-neutral-50 text-sm text-text-quarterary rounded-md border border-border-secondary justify-center items-center gap-1 inline-flex"
                                value={`${
                                  Object.values(
                                    permissionValues[menu.id]
                                  ).filter((value) => value).length
                                }`}
                              />
                            )}
                        </Typography>
                      </ListItem>
                    </>
                  ))}
                </List>
              </div>
              <div
                className="col-span-2 border-l-2"
                style={{ maxHeight: "320px", overflowY: "auto" }}
              >
                <ul className="h-full mx-0">
                  <li className="rounded-none pb-sm p-md flex justify-start">
                    <div>
                      {" "}
                      <CheckboxCust
                        className="checked:bg-bg-brandSolid"
                        onChange={handleSelectAllChange}
                        checked={selectAll}
                      />{" "}
                    </div>
                    <div className="flex items-center text-text-secondary">
                      Semua
                    </div>
                  </li>
                  {menus.map((menu) => (
                    <>
                      {activeMenuId && menu.id === activeMenuId && (
                        <>
                          {permissions.map((permission) => (
                            <li
                              className="p-md flex justify-start"
                              key={permission.id}
                            >
                              <div>
                                <CheckboxCust
                                  color="lightBlue"
                                  className="checked:bg-bg-brandSolid p-0"
                                  checked={
                                    permissionValues[menu.id]?.[permission.id]
                                  }
                                  onChange={() =>
                                    handlePermissionCheckboxChange(
                                      menu.id,
                                      permission
                                    )
                                  }
                                />{" "}
                              </div>
                              <div className="flex items-center text-text-quarterary">
                                {permission.labels}
                              </div>
                            </li>
                          ))}
                        </>
                      )}
                    </>
                  ))}
                  {!activeMenuId && (
                    <div className="flex justify-center items-center h-[250px]">
                      <div className="w-[216px] text-center">
                        <span
                          className={`text-text-quarterary text-sm font-normal leading-tight`}
                        >
                          Harap pilih{" "}
                        </span>
                        <span className="text-text-quarterary text-sm font-semibold leading-tight">
                          Akses menu
                        </span>
                        <span className="text-text-quarterary text-sm font-normal selection:leading-tight">
                          {" "}
                          terlebih dahulu sebelum memilih{" "}
                        </span>
                        <span className="text-text-quarterary text-sm font-semibold leading-tight">
                          Aksi
                        </span>
                        <span className="text-text-quarterary text-sm font-normal leading-tight">
                          {" "}
                          yang diizinkan.
                        </span>
                      </div>
                    </div>
                  )}
                </ul>
              </div>
            </CardBody>
          </Card>
          <div className="mt-9 items-center gap-4">
            <div className="my-3">
              <label className="block text-text-primary text-sm font-bold">
                Aktifkan Role?
              </label>
            </div>
            <div>
              <Switch
                className="checked:bg-bg-brandSolid"
                checked={isActive}
                onChange={() => setIsActive(!isActive)}
              />{" "}
              <span className="m-2 text-sm text-text-secondary">
                {isActive ? "Aktif" : "Tidak Aktif"}
              </span>
            </div>
          </div>
        </CardBody>
        <CardFooter className="flex justify-end border-t border-border-secondary p-lg">
          <Button
            label="Batal"
            size="medium"
            type="btnSecondary"
            className="mr-md"
            onClick={handleBack}
          />
          <Button label="Simpan" size="medium" onClick={handleOpenEditDialog} />
        </CardFooter>
      </Card>

      <ConfirmDialog
        isOpen={isEditDialogOpen}
        onClose={handleCloseEditDialog}
        onConfirm={handleSaveUserRole}
        title="Ubah role"
        content="Anda yakin ingin melanjutkan tindakan ini?"
        btnLabel="Lanjutkan"
        btnType="btnPrimary"
      />

      {isEditError && (
        <AlertNotification
          icon={<FaCheckCircle />}
          color="red"
          message={errorMessage}
        />
      )}
    </div>
  );
};

export default EditUserRole;
