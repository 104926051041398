/* eslint-disable no-unused-expressions */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
} from "@material-tailwind/react";
import Input from "../../../atoms/Input";
import api from "../../../api/configApi";
import { Button } from "../../../atoms/Button";
import AlertNotification from "../../../atoms/AlertNotification";
import TextArea from "../../../atoms/TextArea";
import { FaCheckCircle } from "react-icons/fa";
import ConfirmDialog from "../../../atoms/ConfirmDialog";
import { ReactComponent as ChevronLeft } from "../../../assets/icons/chevron-left.svg";
import CustomDatePicker from "../../../atoms/DatepickerForm";
import Dropdown from "../../../atoms/DropdownWithReset";
import * as Sentry from "@sentry/react";
import InputNumber from "../../../atoms/NumberFormat";
import DefaultImagePakan from "../../../img/Pakan_image.png";
import DropdownSapronak from "../../../atoms/DropdownSapronak";
import { ReactComponent as PlusBlus } from "../../../assets/icons/plus_blue.svg";
import moment from "moment";

const EditMutasi = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [isEditSuccess, setEditSuccess] = useState(false);
  const [isEditError, setIsEditError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [isEditDialogOpen, setEditDialogOpen] = useState(false);

  const [selectedDate, setSelectedDate] = useState(null);
  const [dateOutBefore, setDateOutBefore] = useState("");
  const [searchKandang, setSearchKandang] = useState("");
  const [selectedDestination, setSelectedDestination] = useState(null);
  const [destinationId, setDestinationId] = useState(null);
  const [destinationDropdown, setDestinationDropdown] = useState(null);
  const [platNomor, setPlatNomor] = useState("");
  const [qty, setQty] = useState("");
  const [searchFeed, setSearchFeed] = useState("");
  const [feeds, setFeeds] = useState([]);
  const [selectedFeed, setSelectedFeed] = useState();
  const [additionalInfoPpl, setAdditionalInfoPpl] = useState("");
  const [additionalInfoAdmin, setAdditionalInfoAdmin] = useState("");
  const [destinationEstimation, setDestinationEstimation] = useState("");
  const [isOpenInfoAdmin, setIsOpenInfoAdmin] = useState(false);
  const [fetchData, setFetchData] = useState(false);
  const [placeholderDate, setPlaceholderDate] = useState("");
  const [rearingPeriodId, setRearingPeriodId] = useState("");
  const [feedId, setFeedId] = useState("");
  const [feedName, setFeedName] = useState("");
  const [unitId, setUnitId] = useState("");
  const [isLoadingDestionation, setIsLoadingDestionation] = useState(false);
  const [isLoadingFeedstock, setIsLoadingFeedstock] = useState(false);

  const [feedIdExclude, setFeedIdExclude] = useState([]);

  const feedIdExcludeString = feedIdExclude.join(",");

  const isSubmitDisabled = !qty || !platNomor;

  const handleOpenEditDialog = () => {
    setEditDialogOpen(true);
  };

  const handleCloseEditDialog = () => {
    setEditDialogOpen(false);
  };

  const handleChangePlatNomor = (e) => {
    const valuePlatNomor = e.target.value;
    setPlatNomor(valuePlatNomor);
  };

  const handleChangeQty = (e) => {
    const valueQty = e.target.value;
    setQty(valueQty);
  };

  useEffect(() => {
    const refetchDetail = async () => {
      try {
        const data = await api.get(`/v1/feeds/out/${id}`);
        setPlaceholderDate(moment(data?.data?.dateOut).format("DD MMMM YYYY"));
        setDateOutBefore(data?.data?.dateOut);
        setQty(data?.data?.qtyOut / 50);
        setPlatNomor(data?.data?.licenseNumber);
        setDestinationEstimation(data?.data?.destinationEstimation);
        setAdditionalInfoPpl(data?.data?.creatorNotes);
        setRearingPeriodId(data?.data?.rearingPeriod?.id);
        setSelectedDestination(data?.data?.destination?.house);
        setDestinationId(data?.data?.destination?.id);
        setSelectedFeed(data?.data?.feed);
        setFeedId(data?.data?.feed?.id);
        setFeedName(data?.data?.feed?.labels);
        setUnitId(data?.data?.rearingPeriod?.house?.unit?.id);
        setAdditionalInfoAdmin(data?.data?.reviewerNotes);
      } catch (err) {
        Sentry.captureException(err);
      }
    };

    if (fetchData) {
      setFetchData(false);
    }

    refetchDetail();
  }, [id, fetchData]);

  useEffect(() => {
    const dataFetchDropdownFeeds = async () => {
      try {
        const responseFeedsDropdown = await api.get("/v1/feeds/dropdown", {
          params: {
            search: searchFeed,
          },
        });
        setFeeds(responseFeedsDropdown.data);
      } catch (error) {
        Sentry.captureException(error);
      }
    };
    dataFetchDropdownFeeds();
  }, [searchFeed]);

  useEffect(() => {
    const dropdownDestination = async () => {
      setIsLoadingDestionation(true);
      try {
        if (unitId?.length > 0) {
          const responseDestionationDropdown = await api.get(
            "/v1/rearingPeriods/dropdown/mutation",
            {
              params: {
                isActive: false,
                search: searchKandang,
                unit: unitId,
              },
            }
          );

          const processedDestinationDropdown =
            responseDestionationDropdown?.data?.contents?.map((item) => ({
              id: item?.id,
              labels: item?.house?.labels,
            })) || [];

          setDestinationDropdown(processedDestinationDropdown);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoadingDestionation(false);
      }
    };

    dropdownDestination();
  }, [searchKandang, unitId]);

  const handleSave = async () => {
    setIsSubmitting(true);

    let parseQtyOut;
    if (typeof qty === "string") {
      if (qty?.includes(".")) {
        parseQtyOut = qty?.replace(/\./g, "");
      } else {
        parseQtyOut = qty;
      }
    }

    const floatQtyOut = parseFloat(
      parseQtyOut?.includes(",") ? parseQtyOut?.replace(",", ".") : parseQtyOut
    );

    let qtyOutInKg = floatQtyOut ? floatQtyOut * 50 : qty * 50;

    const newMutasi = {
      rearingPeriod: { id: rearingPeriodId },
      feed: { id: feedId },
      destination: { id: destinationId },
      dateOut:
        selectedDate !== null
          ? moment(selectedDate?.$d).format("YYYY-MM-DD")
          : dateOutBefore,
      qtyOut: qtyOutInKg,
      licenseNumber: platNomor,
      destinationEstimation: destinationEstimation,
    };

    if (additionalInfoAdmin?.length > 0) {
      newMutasi.reviewerNotes = additionalInfoAdmin;
    }

    try {
      const editMutasi = await api.put(`/v1/feeds/out/${id}`, newMutasi);

      setEditSuccess(true);
      handleCloseEditDialog();
      navigate(`/mutasi/${id}`);
      localStorage.setItem(
        "responseSuccessEditMutasi",
        JSON.stringify(!isEditSuccess)
      );
    } catch (error) {
      console.log(error);
      setIsSubmitting(false);
      setIsEditError(true);
      handleCloseEditDialog();
      setErrorMessage(
        error.response?.data?.error?.name || error.response?.data?.error
      );
      setTimeout(() => setIsEditError(false), 2000);
    }
  };

  const handleBack = () => {
    navigate(`/mutasi/${id}`);
  };

  const handleChangeDate = (date) => {
    setSelectedDate(date);
  };

  const handleAddInfoAdmin = () => {
    setIsOpenInfoAdmin(!isOpenInfoAdmin);
  };

  const handleSelectDestination = (destination) => {
    setDestinationId(destination.id);
    setSelectedDestination(destination);
  };

  const handleSelectFeed = (feed) => {
    setSelectedFeed(feed);
    setFeedId(feed.id);
  };

  useEffect(() => {
    const fetchFeeds = async () => {
      setIsLoadingFeedstock(true);
      try {
        if (rearingPeriodId?.length > 0) {
          const params = {
            search: searchFeed,
            periodId: rearingPeriodId,
            exclude: feedIdExcludeString,
          };
          const response = await api.get("/v1/feeds/stock/list", { params });
          setFeeds(response?.data);
        }
      } catch (error) {
        Sentry.captureException(error);
      } finally {
        setIsLoadingFeedstock(false);
      }
    };

    fetchFeeds();
  }, [searchFeed, rearingPeriodId, feedIdExcludeString]);

  return (
    <div className="p-4 flex justify-center">
      <div>
        <Card className="border w-[800px] shadow-none border-border-secondary p-xl">
          <CardHeader floated={false} shadow={false} className="rounded-b-none">
            <Typography className="flex items-center text-xl font-bold text-text-primary">
              <div className="cursor-pointer pr-2" onClick={handleBack}>
                <ChevronLeft />
              </div>
              Ubah data mutasi
            </Typography>
          </CardHeader>
          <div className="flex justify-center w-full">
            <CardBody className="w-full">
              <Card className="shadow-none bg-gray-100 p-2xl">
                <div className="grid grid-cols-1 my-6">
                  <Typography className="text-text-secondary text-sm font-medium leading-tight">
                    Tanggal Mutasi
                  </Typography>
                  <CustomDatePicker
                    placeholder={placeholderDate}
                    className="w-full h-[42px] text-text-primary px-sm py-xs justify-start items-center gap-xxs inline-flex focus:outline-none focus:border-primary-400 focus:ring focus:ring-primary-200"
                    onChange={handleChangeDate}
                    value={selectedDate}
                    format={"DD MMMM YYYY"}
                  />
                </div>
                <div className="grid grid-cols-1 gap-sm">
                  <Typography className="text-text-secondary text-sm font-medium leading-tight">
                    Kandang tujuan
                  </Typography>
                  <Dropdown
                    placeholder="Pilih kandang tujuan"
                    options={destinationDropdown}
                    onSelect={handleSelectDestination}
                    selectedOption={selectedDestination}
                    isLoading={isLoadingDestionation}
                  />
                </div>
                <div className="grid grid-cols-1 gap-sm my-6">
                  <Typography className="text-text-secondary text-sm font-medium leading-tight">
                    Jenis pakan
                  </Typography>
                  <DropdownSapronak
                    size="medium"
                    placeholder={feedName}
                    defaultProduct={DefaultImagePakan}
                    search={searchFeed}
                    options={feeds.contents}
                    onSelect={handleSelectFeed}
                    setSearch={setSearchFeed}
                    className="placeholder-gray-900"
                    isLoading={isLoadingFeedstock}
                  />
                </div>
                <div className="grid grid-cols-1 gap-sm">
                  <Typography className="text-text-secondary text-sm font-medium leading-tight">
                    Kuantitas
                  </Typography>
                  <InputNumber
                    id={`kuantitas`}
                    placeholder="Masukkan kuantitas"
                    size="medium"
                    className=" placeholder:text-text-placeholderSubtle"
                    value={qty}
                    onChange={handleChangeQty}
                    decimalScale={2}
                    componentRight={
                      <Typography
                        type="small"
                        className="text-gray-500 text-md font-medium leading-tight"
                      >
                        zak
                      </Typography>
                    }
                  />
                </div>
                <div className="grid grid-cols-1 gap-sm my-6">
                  <Typography className="text-text-secondary text-sm font-medium leading-tight">
                    Plat nomor
                  </Typography>
                  <Input
                    size="medium"
                    value={platNomor}
                    onChange={handleChangePlatNomor}
                    placeholder={"Masukkan nomor plat mobil"}
                    className={"placeholder:text-text-placeholderSubtle"}
                  />
                </div>
                <div className="grid grid-cols-1 gap-sm">
                  <Typography className="text-text-secondary text-sm font-medium leading-tight">
                    Estimasi jarak (km)
                  </Typography>
                  <Input
                    type="number"
                    id="estimasi"
                    placeholder="Masukkan estimasi jarak"
                    className=""
                    value={destinationEstimation}
                    size="medium"
                    onChange={(e) => setDestinationEstimation(e.target.value)}
                  />
                </div>
                <div className="grid grid-cols-1 gap-sm mt-6">
                  <Typography className="text-text-secondary text-sm font-medium leading-tight">
                    Keterangan
                  </Typography>
                  <div className="border border-border-disabled w-full pl-sm pt-sm pr-2xl pb-8xl rounded-lg text-text-placeholderSubtle text-base font-normal bg-bg-disabled">
                    {additionalInfoPpl
                      ? additionalInfoPpl
                      : "Tidak ada catatan"}
                  </div>
                </div>
              </Card>
              {!isOpenInfoAdmin && (
                <div className="flex justify-end mt-4xl">
                  <Button
                    type="btnLinkBlue"
                    size="small"
                    label="Tambahkan catatan"
                    onClick={handleAddInfoAdmin}
                    className="cursor-pointer h-[40px]"
                    componentLeft={<PlusBlus />}
                  />
                </div>
              )}

              {(isOpenInfoAdmin || additionalInfoAdmin?.length > 0 )&& (
                <Card className="shadow-none bg-gray-100 p-2xl mt-4xl">
                  <div className="grid grid-cols-1 gap-sm">
                    <Typography className="text-text-secondary text-sm font-medium leading-tight">
                      Catatan Admin
                    </Typography>
                    <TextArea
                      placeholder="Tidak ada catatan"
                      value={additionalInfoAdmin}
                      onChange={(e) => setAdditionalInfoAdmin(e.target.value)}
                    />
                  </div>
                </Card>
              )}
            </CardBody>
          </div>

          {/* Save Button */}
          <CardFooter className="flex justify-end border-t border-border-secondary p-lg">
            <Button
              label="Batal"
              size="medium"
              type="btnSecondary"
              className="mr-md"
              onClick={handleBack}
            />
            <Button
              label="Simpan"
              size="medium"
              type={`${
                isSubmitDisabled || isSubmitting
                  ? "btnPrimaryDisabled"
                  : "btnPrimary"
              }`}
              onClick={handleOpenEditDialog}
              disabled={isSubmitDisabled || isSubmitting}
              componentLeft={
                isSubmitting && (
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    className="animate-spin fill-neutral-400"
                  >
                    <path
                      d="M10.72,19.9a8,8,0,0,1-6.5-9.79A7.77,7.77,0,0,1,10.4,4.16a8,8,0,0,1,9.49,6.52A1.54,1.54,0,0,0,21.38,12h.13a1.37,1.37,0,0,0,1.38-1.54,11,11,0,1,0-12.7,12.39A1.54,1.54,0,0,0,12,21.34h0A1.47,1.47,0,0,0,10.72,19.9Z"
                      className="spinner_6kVp"
                    />
                  </svg>
                )
              }
            />
          </CardFooter>
        </Card>

        {/* Komponen dialog konfirmasi tambah data*/}
        <ConfirmDialog
          isOpen={isEditDialogOpen}
          onClose={handleCloseEditDialog}
          onConfirm={handleSave}
          title="Konfirmasi Penyimpanan"
          content="Anda akan menyimpan perubahan terbaru, apakah kamu yakin akan melanjutkan?"
          btnLabel="Lanjutkan"
          btnType="btnPrimary"
        />
        {/* Alert untuk notifikasi error */}
        {isEditError && (
          <AlertNotification
            icon={<FaCheckCircle />}
            color="red"
            message={errorMessage}
          />
        )}
      </div>
    </div>
  );
};

export default EditMutasi;
