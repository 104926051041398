import React, { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
} from "@material-tailwind/react";
import Input from "../../../atoms/Input";
import { FaCheckCircle } from "react-icons/fa";
import { Button } from "../../../atoms/Button";
import api from "../../../api/configApi";
import AlertNotification from "../../../atoms/AlertNotification";
import ConfirmDialog from "../../../atoms/ConfirmDialog";
import Dropdown from "../../../atoms/DropdownWithReset";

const EditPakanModal = ({
  isOpen,
  onClose,
  onReloadData,
  pakanId,
  onUpdate,
  setSelectedEditPakanId,
}) => {
  const [name, setName] = useState("");
  const [supplier, setSupplier] = useState("");
  const [type, setType] = useState("");
  const [types, setTypes] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [isEditSuccess, setEditSuccess] = useState(false);

  const isSubmitDisabled = !name || !supplier;

  const [isEditDialogOpen, setEditDialogOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  const [isEditError, setEditError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const fetchPakans = async () => {
      try {
        const response = await api.get(`/v1/feeds/${pakanId}`);
        setName(response.data.name);
        setSupplier(response.data.supplier);
        setSelectedOption(response.data.type);
        // console.log(response.data.type.label);
      } catch (error) {
        console.error("Error fetching feeds:", error);
      }
    };

    const fetchTypes = async () => {
      try {
        const response = await api.get("/v1/feeds/feedType");
        setTypes(response.data.contents);
      } catch (error) {
        console.error("Error fetching feed types:", error);
      }
    };

    if (isOpen && pakanId) {
      fetchPakans();
    }

    fetchTypes();
  }, [isOpen, pakanId]);

  const handleOpenEditDialog = () => {
    setEditDialogOpen(true);
  };

  const handleCloseEditDialog = () => {
    setSelectedEditPakanId(null);
    setEditDialogOpen(false);
  };

  const handleUpdate = async () => {
    let requestEditData = {
      name: name,
      supplier: supplier,
    };

    if (type !== "") {
      requestEditData.type = type;
    }

    handleCloseEditDialog();
    try {
      setIsSubmitting(true);
      const response = await api.put(`/v1/feeds/${pakanId}`, requestEditData);
      console.log("pakan data updated:", response.data);
      setIsSubmitting(false);
      if (onUpdate) {
        onUpdate(response.data);
      }
      if (onReloadData) {
        onReloadData();
      }

      setEditSuccess(true);
      setTimeout(() => setEditSuccess(false), 2000);

      onClose();
    } catch (error) {
      console.error("Error updating pakan data:", error);
      setErrorMessage(
        error.response.data.error?.name ||
          error.response.data.error?.supplier ||
          error.response.data.error
      );
      setEditError(true);
      setTimeout(() => setEditError(false), 3000);
      setIsSubmitting(false);
      onClose();
    }
  };

  const handleChangeType = (select) => {
    const selectedType = select;
    setType(selectedType.id);
    setSelectedOption(selectedType);
  };

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }

    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [isOpen]);

  return (
    <div>
      {/* Modal Overlay */}
      {isOpen && <div className="fixed inset-0 bg-gray-800 opacity-50 z-[50]"></div>}
      <Card
        className={`fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-[60] ${
          isOpen ? "block" : "hidden"
        } rounded-xl flex-col justify-start items-start`}
        style={{ width: "800px" }}
      >
        <Card>
          <CardHeader
            floated={false}
            shadow={false}
            className="border-b border-border-secondary rounded-b-none my-1 m-0"
          >
            <button
              className="absolute top-6 right-4 text-gray-500"
              onClick={onClose}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
            {/* Modal Title */}
            <Typography
              className="text-xl text-text-primary font-bold mb-1 p-4 leading-normal"
            >
              Ubah pakan
            </Typography>
          </CardHeader>
          <CardBody className="p-3xl">
            <div className="grid grid-cols-1 mb-3xl">
              <Typography
                className="text-text-primary text-sm font-semibold leading-tight"
              >
                Nama pakan:{" "}
                <span className="text-red-500 text-sm font-normal leading-tight">
                  *
                </span>
                <Input
                  type="text"
                  id="name"
                  placeholder="Masukkan Nama Pakan"
                  className="mt-md"
                  value={name}
                  size="medium"
                  onChange={(e) => setName(e.target.value)}
                />
              </Typography>
            </div>
            <div className="grid grid-cols-1 my-3xl">
              <div>
                <Typography
                  className="text-text-primary text-sm font-semibold leading-tight"
                >
                  Supplier pakan:{" "}
                  <span className="text-red-500 text-sm font-normal leading-tight">
                    *
                  </span>
                </Typography>
                <Input
                  type="text"
                  id="company"
                  placeholder="Masukkan Supplier pakan"
                  className="mt-md"
                  value={supplier}
                  size="medium"
                  onChange={(e) => setSupplier(e.target.value)}
                />
              </div>
            </div>
            <div className="grid grid-cols-1">
              <Typography
                type="small"
                className="text-text-primary text-sm font-semibold leading-tight"
              >
                Bentuk:{" "}
                <span className="text-red-500 text-sm font-normal leading-tight">
                  *
                </span>
              </Typography>
              <Dropdown
                onSelect={handleChangeType}
                placeholder="Pilih Bentuk"
                options={types}
                selectedOption={selectedOption}
              ></Dropdown>
            </div>
          </CardBody>
          <CardFooter className="flex justify-end border-t border-border-secondary p-lg">
            <Button
              label="Batal"
              size="medium"
              type="btnSecondary"
              className="mr-md"
              onClick={onClose}
            />
            <Button
              label="Simpan"
              size="medium"
              type={`${
                isSubmitDisabled || isSubmitting
                  ? "btnPrimaryDisabled"
                  : "btnPrimary"
              }`}
              onClick={handleOpenEditDialog}
              disabled={isSubmitDisabled || isSubmitting}
              componentLeft={
                isSubmitting && (
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    className="animate-spin fill-neutral-400"
                  >
                    <path
                      d="M10.72,19.9a8,8,0,0,1-6.5-9.79A7.77,7.77,0,0,1,10.4,4.16a8,8,0,0,1,9.49,6.52A1.54,1.54,0,0,0,21.38,12h.13a1.37,1.37,0,0,0,1.38-1.54,11,11,0,1,0-12.7,12.39A1.54,1.54,0,0,0,12,21.34h0A1.47,1.47,0,0,0,10.72,19.9Z"
                      className="spinner_6kVp"
                    />
                  </svg>
                )
              }
            />
          </CardFooter>
        </Card>
      </Card>
      {/* Komponen dialog konfirmasi edit*/}
      <ConfirmDialog
        isOpen={isEditDialogOpen}
        onClose={handleCloseEditDialog}
        onConfirm={handleUpdate}
        title="Ubah pakan"
        content="Anda yakin ingin melanjutkan tindakan ini?"
        btnLabel="Lanjutkan"
        btnType="btnPrimary"
      />
      {isEditSuccess && (
        <AlertNotification
          icon={<FaCheckCircle />}
          color="green"
          message="Data pakan berhasil diubah."
        />
      )}
      {/* Alert untuk notifikasi error */}
      {isEditError && (
        <AlertNotification
          icon={<FaCheckCircle />}
          color="red"
          message={errorMessage}
        />
      )}
    </div>
  );
};

export default EditPakanModal;
