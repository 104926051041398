// features/menuSlice.js
// import { createSlice } from '@reduxjs/toolkit';

// const initialState = {
//   activeMenu: '',
//   breadcrumbs: [],
// };

// const menuSlice = createSlice({
//   name: 'menu',
//   initialState,
//   reducers: {
//     setActiveMenu: (state, action) => {
//       state.activeMenu = action.payload;
//     },
//     setBreadcrumbs: (state, action) => {
//       state.breadcrumbs = action.payload;
//     },
//   },
// });

// export const { setActiveMenu, setBreadcrumbs } = menuSlice.actions;

// export default menuSlice.reducer;

import { createSlice } from '@reduxjs/toolkit';
import Cookies from "js-cookie";

const initialState = {
  activeMenu: Cookies.get('activeMenu') || 'dashboard',
  activeSubMenu: Cookies.get('activeSubMenu') || '',
  isSidebarOpen: Cookies.get('isSidebarOpen') || true,
  isDropdownOpen: Cookies.get('isDropdownOpen') || false,
  breadcrumbs: [],
};

const menuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    setActiveMenu: (state, action) => {
      state.activeMenu = action.payload;
      Cookies.set('activeMenu', action.payload);
    },
    setActiveSubMenu: (state, action) => {
        state.activeSubMenu = action.payload;
        Cookies.set('activeSubMenu', action.payload);
    },
    setIsSidebarOpen: (state, action) => {
      state.isSidebarOpen = action.payload;
      Cookies.set('isSidebarOpen', action.payload);
    },
    setIsDropdownOpen: (state, action) => {
      state.isDropdownOpen = action.payload;
      Cookies.set('isDropdownOpen', action.payload);
    },
    setBreadcrumbs: (state, action) => {
      state.breadcrumbs = action.payload;
    },
  },
});

export const { setActiveMenu, setActiveSubMenu, setBreadcrumbs, setIsSidebarOpen, setIsDropdownOpen } = menuSlice.actions;

export default menuSlice.reducer;

