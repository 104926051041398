/* eslint-disable no-unused-vars */
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Switch,
  Typography,
} from "@material-tailwind/react";
import { FaCheckCircle } from "react-icons/fa";
import { ReactComponent as ChevronLeft } from "../../assets/icons/chevron-left.svg";
import CustomDatePicker from "../../atoms/DatepickerForm";
import DropdownSapronak from "../../atoms/DropdownSapronak";
import { Button } from "../../atoms/Button";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../api/configApi";
import ConfirmDialog from "../../atoms/ConfirmDialog";
import moment from "moment";
import "moment/locale/id";
import InputNumber from "../../atoms/NumberFormat";
import AlertNotification from "../../atoms/AlertNotification";

const UpdateFeed = () => {
  const { id } = useParams();
  const [dateIn, setDateIn] = useState(null);
  const [productName, setNameProduct] = useState("");
  const [productId, setProductId] = useState("");
  const [qtyIn, setQtyIn] = useState("");
  const [price, setPrice] = useState("");
  const [rearingPeriod, setRearingPeriod] = useState("");
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [updateFeedNotif, setUpdateFeedNotif] = useState(false);
  const [options, setOptions] = useState(null);
  const [search, setSearch] = useState("");

  const [isEditError, setIsEditError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [satuan, setSatuan] = useState(false)

  const navigate = useNavigate();

  useEffect(() => {
    const fetchCage = async () => {
      try {
        const response = await api.get(`/v1/feeds/in/${id}`);

        setDateIn(new Date(response.data.dateIn));
        // setDateIn(response.data.dateIn)
        setNameProduct(response?.data?.feed?.labels);
        setProductId(response?.data?.feed?.id);
        setSatuan(false);
        setQtyIn(response?.data?.qtyIn / 50);
        setPrice(response?.data?.price);
        setRearingPeriod(response?.data?.rearingPeriod?.id);
      } catch (error) {
        console.log(error);
      }
    };

    const fetchingDropdownFeed = async () => {
      try {
        const responseDropdown = await api.get("/v1/feeds/dropdown", {
          params: {
            search: search,
          },
        });
        setOptions(responseDropdown.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchingDropdownFeed();
    fetchCage();
  }, [id, search]);


  const handleEditFeed = async () => {
    const parseQtyIn = +qtyIn?.toString()?.replace(/\./g, "");
    const parsePrice = +price?.toString()?.replace(/\./g, "");
    let reqBody = {
      feed: { id: productId },
      rearingPeriod: { id: rearingPeriod },
      dateIn: moment(dateIn?.$d).format("YYYY-MM-DD"),
      qtyIn: satuan === true ? parseQtyIn : parseQtyIn * 50,
      price: parsePrice,
    };

    try {
      const editFormFeed = await api.put(`/v1/feeds/in/${id}`, reqBody);
      setUpdateFeedNotif(true);
      navigate(`/periode-budidaya/periode-berjalan/${rearingPeriod}/sapronak`);
      localStorage.setItem(
        "successNotifUpdateFeed",
        JSON.stringify(!updateFeedNotif)
      );
      console.log(editFormFeed, "success response");
      localStorage.setItem("settingType", "sapronak");
      localStorage.setItem("settingTypeSapronak", "pakan");
    } catch (error) {
      setOpenConfirmation(false);
      console.log(error);
      setIsEditError(true);
      setErrorMessage(
        error.response?.data?.error?.name || error.response?.data?.error
      );
      setTimeout(() => setIsEditError(false), 3000);
    }
  };

  const handleSelect = (event) => {
    setNameProduct(event);
  };

  const handleChangeDate = (date) => {
    setDateIn(date);
  };

  const handleChangeSatuan = (satuan) => {
    setSatuan(satuan)
    if(satuan === false){
      setQtyIn(qtyIn / 50);
    } else {
      setQtyIn(qtyIn * 50);
    }
  };

  const handleBack = () => {
    navigate(`/periode-budidaya/periode-berjalan/${rearingPeriod}/period`);
    localStorage.setItem("settingType", "sapronak");
    localStorage.setItem("settingTypeSapronak", "pakan");
  };

  return (
    <div className="flex h-auto xl:overflow-hidden overflow-hidden">
      <div className="flex flex-col flex-1 overflow-hidden">
        <div className="p-4">
          <Card className="w-max-md flex justify-center item-centers border">
            <CardHeader
              floated={false}
              shadow={false}
              className="p-md border-none"
            >
              <div className="flex justify-start">
                <div className="mt-2 justify-start" onClick={handleBack}>
                  <ChevronLeft />
                </div>

                <Typography
                  variant="h2"
                  color="blue-gray"
                  className="text-xl font-semibold p-2"
                >
                  Ubah Pakan
                </Typography>

                {/* <CustomDatePicker/> */}
              </div>
            </CardHeader>

            <CardBody className="p-4">
              <div className="p-5">
                <Typography
                  type="small"
                  className="text-gray-900 text-sm font-medium leading-tight"
                >
                  Tanggal Masuk
                </Typography>

                <CustomDatePicker
                  placeholder={moment(dateIn).format("DD MMMM YYYY")}
                  // value={dateIn}
                  onChange={handleChangeDate}
                  format={"DD MMMM YYYY"}
                  className="w-full text-neutral-800 px-sm py-xs justify-start items-center gap-xxs inline-flex focus:outline-none focus:border-primary-400 focus:ring focus:ring-primary-200 mt-3"
                />
              </div>
              <hr className="border-t border-gray-300 w-full my-4" />

              <div className="mt-5 p-5">
                <div className="mx-auto w-full bg-[#EAECF0] rounded-sm">
                  <div className="p-4">
                    <div className="ml-4">
                      <Typography
                        type="small"
                        className="text-gray-900 text-sm font-medium leading-tight"
                      >
                        Produk
                      </Typography>

                      <div className="mt-2">
                        <DropdownSapronak
                          size="small"
                          options={options?.contents}
                          onSelect={handleSelect}
                          placeholder={productName}
                          setSearch={setSearch}
                          search={search}
                          disabled={true}
                          className="placeholder-gray-900"
                        />

                        <div className="flex justify-between w-full mt-5 p-1 gap-4">
                          <div className="mt-3 w-[100%]">
                            <div className="grid grid-cols-2">
                              <Typography
                                type="small"
                                className="text-gray-900 text-sm font-medium leading-tight"
                              >
                                Kuantitas
                              </Typography>

                              <div className="flex items-center gap-2 justify-end pr-1">
                                <label className="block text-blue-gray-800 text-sm font-normal">
                                  zak
                                </label>
                                <Switch
                                  className="bg-primary-500 checked:bg-primary-500"
                                  onChange={() => handleChangeSatuan(!satuan)}
                                  checked={satuan}
                                />
                                <label className="block text-blue-gray-800 text-sm font-normal">
                                  kg
                                </label>
                              </div>
                            </div>

                            <InputNumber
                              type="number"
                              placeholder="Masukan Kuantitas"
                              size="small"
                              className="mt-3 w-[120px]"
                              value={qtyIn}
                              onChange={(e) => setQtyIn(e.target.value)}
                              componentRight={
                                <Typography
                                  type="small"
                                  className="text-gray-500 mt-3 text-md font-medium leading-tight"
                                >
                                  {satuan === false
                                  ? "zak"
                                  : "kg"}
                                </Typography>
                              }
                            />
                          </div>


                          <div className="mt-3 w-[100%]">
                            <Typography
                              type="small"
                              className="text-gray-900 text-sm font-medium leading-tight"
                            >
                              Harga satuan per ({satuan === false ? "zak" : "kg"})
                            </Typography>

                            <InputNumber
                              size="small"
                              className="mt-3 w-[120px]"
                              placeholder="Rp Masukan Harga Satuan"
                              value={price}
                              onChange={(e) => setPrice(e.target.value)}
                              componentLeft={
                                <Typography
                                  type="small"
                                  className="text-gray-500 mt-3 text-md font-medium leading-tight"
                                >
                                  Rp
                                </Typography>
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </CardBody>
            <hr className="border-t border-gray-300 w-full my-4" />

            <CardFooter className="flex justify-end border-t border-gray-50 p-lg gap-4  mr-4">
              <Button
                label="Batal"
                type="btnSecondary"
                size="medium"
                onClick={handleBack}
              />

              <Button
                label="Simpan"
                size="medium"
                onClick={() => setOpenConfirmation(!openConfirmation)}
              />
            </CardFooter>
          </Card>

          <ConfirmDialog
            title="Konfirmasi perubahan data"
            content="Apakah Anda yakin ingin mengubah data stok Pakan ?"
            isOpen={openConfirmation}
            onClose={() => setOpenConfirmation(!openConfirmation)}
            btnLabel="Ya, Lanjutkan"
            onConfirm={handleEditFeed}
          />

          {/* Alert untuk notifikasi error */}
          {isEditError && (
            <AlertNotification
              icon={<FaCheckCircle />}
              color="red"
              message={errorMessage}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default UpdateFeed;
