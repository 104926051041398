import React, { createContext, useContext, useEffect, useState } from "react";

import { zodResolver } from "@hookform/resolvers/zod";
import { format } from "date-fns";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { ROUTES_PATH } from "../../../../../shared/routes";
import { useGetHousesList } from "../../../../../useCases/Houses";
import {
  useEditMasterPeriod,
  useGetMasterPeriodDetail,
} from "../../../../../useCases/MasterPeriod";
import { schema } from "../config";

const MasterPeriodeEdit = createContext(undefined);

export const MasterPeriodeEditStore = ({ children }) => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { user } = useSelector((state) => state.auth);

  const [selectedPPL, setSelectedPPL] = useState(null);
  const [selectedOwner, setSelectedOwner] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const form = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      periode: 1,
      ppl: "",
    },
  });

  const queryDetail = useGetMasterPeriodDetail(id);

  useEffect(() => {
    if (queryDetail.data) {
      const data = queryDetail.data;
      form.setValue("house", data?.house?.id);
      form.setValue("ppl", data?.house?.ppl?.fullName || "");
      form.setValue("sequence", data?.sequence || 1);
      form.setValue("idPeriode", data?.analyticAccount || "");
      form.setValue(
        "docInDate",
        data?.docInDate ? new Date(data.docInDate) : new Date(),
      );
      form.setValue("docInPopulation", data?.docInPopulation || 0);
      form.setValue("guaranteeType", data?.house?.guaranteeType?.id || "");
      form.setValue("guaranteeAmount", data?.house?.guaranteeAmount || 0);
      form.setValue("differentFcrHistory1", data.house.diffFcrHistoryOne || 0);
      form.setValue("differentFcrHistory2", data.house.diffFcrHistoryTwo || 0);
      form.setValue(
        "differentFcrHistory3",
        data.house.diffFcrHistoryThree || 0,
      );
      form.setValue("performanceIndexHistory1", data.house.ipHistoryOne || 0);
      form.setValue("performanceIndexHistory2", data.house.ipHistoryTwo || 0);
      form.setValue("performanceIndexHistory3", data.house.ipHistoryThree || 0);
      setSelectedPPL(data?.house?.ppl?.id || "");
      setSelectedOwner(data?.house?.id || "");
    }
  }, [queryDetail.data, form]);

  const queryHouses = useGetHousesList({
    unit: user?.unit,
  });
  const mutateEdit = useEditMasterPeriod(id);
  const isLoading = queryDetail.isLoading || queryHouses.isLoading;

  const handleOnSubmit = () => {
    setIsDialogOpen(true);
  };

  const handleMutate = () => {
    const values = form.getValues();
    const payload = {
      house: {
        id: values.house,
      },
      ppl: {
        id: selectedPPL || "",
      },
      docInDate: format(values.docInDate, "yyyy-MM-dd"),
      sequence: values.sequence,
      docInPopulation: values.docInPopulation,
      guaranteeType: values.guaranteeType,
      guaranteeAmount: values.guaranteeAmount,
      differentFcrHistory1: values.differentFcrHistory1,
      differentFcrHistory2: values.differentFcrHistory2,
      differentFcrHistory3: values.differentFcrHistory3,
      performanceIndexHistory1: values.performanceIndexHistory1,
      performanceIndexHistory2: values.performanceIndexHistory2,
      performanceIndexHistory3: values.performanceIndexHistory3,
    };

    mutateEdit.mutate(payload, {
      onSuccess: () => {
        navigate(ROUTES_PATH.masterPeriode);
      },
    });
  };

  return (
    <MasterPeriodeEdit.Provider
      value={{
        form,
        handleOnSubmit,
        handleMutate,
        isDialogOpen,
        setIsDialogOpen,
        mutateEdit,
        isLoading,
        queryDetail,
        queryHouses,
        selectedPPL,
        setSelectedPPL,
        selectedOwner,
        setSelectedOwner,
      }}
    >
      {children}
    </MasterPeriodeEdit.Provider>
  );
};

export const useMasterPeriodeEdit = () => {
  const context = useContext(MasterPeriodeEdit);

  if (context === undefined) {
    throw new Error(
      "useMasterPeriodeEdit must be used within a MasterPeriodeEditStore",
    );
  }

  return context;
};
