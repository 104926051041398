export const Primary = {
    bg: "bg-primary-500",
    bg_hover: "hover:bg-primary-600",
    fg: "text-neutral-950",
    fg_hover: "text-neutral-950",
    border: "border-primary-500",
    border_hover: "hover:border-primary-600",
  }

  export const Secondary = {
    bg: "bg-white",
    bg_hover: "hover:bg-neutral-50",
    fg: "text-neutral-700",
    fg_hover: "text-neutral-800",
    border: "border-neutral-300",
    border_hover: "hover:border-neutral-300",
  }

  export const Error = {
    bg: "bg-red-500",
    bg_hover: "hover:bg-red-600",
    fg: "text-white",
    fg_hover: "text-white",
    border: "border-red-500",
    border_hover: "hover:border-red-600",
  }