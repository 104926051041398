/* eslint-disable jsx-a11y/img-redundant-alt */
import useFetchImage from "../atoms/HookFetchingImage";
import {
  Dialog,
  DialogBody,
  DialogHeader,
  IconButton,
} from "@material-tailwind/react";

const ImageDialog = ({
  open,
  handlerClose,
  classNameDialog,
  size,
  url,
  withoutFetching,
  img,
}) => {
  const { data } = useFetchImage(url);

  return (
    <Dialog
      size={size || "md"}
      open={open}
      handler={handlerClose}
      className={`${classNameDialog} max-w-[90vw] max-h-[90vh]`}
    >
      <DialogHeader className="justify-end">
        <IconButton
          color="white"
          size="sm"
          variant="text"
          onClick={handlerClose}
          className="p-0"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="black"
            strokeWidth={2}
            className="h-7 w-7 pt-1"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </IconButton>
      </DialogHeader>

      <DialogBody className="mt-[-20px] flex justify-center items-center">
        {withoutFetching ? (
          <img
            alt="image detail harvest"
            className="rounded-lg object-cover w-full max-w-[90vw] max-h-[80vh] sm:max-w-[728px] sm:max-h-[638px]"
            src={img}
          />
        ) : data?.url ? (
          <img
            alt="image detail harvest"
            className="rounded-lg object-cover w-full max-w-[90vw] max-h-[80vh] sm:max-w-[728px] sm:max-h-[638px]"
            src={data.url}
          />
        ) : (
          <div className="flex items-center text-neutral-500 text-xl sm:text-4xl font-semibold">
            Loading...
          </div>
        )}
      </DialogBody>
    </Dialog>
  );
};

export default ImageDialog;
