/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/img-redundant-alt */

import { useEffect, useState } from "react";
import api from "../../../api/configApi";
import { useNavigate, useParams } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  Typography,
  Chip,
} from "@material-tailwind/react";
import { ReactComponent as BackArrow } from "../../../assets/icons/chevron-left.svg";
import { Button } from "../../../atoms/Button";
import ToasterAlert from "../../../atoms/Toaster";
import moment from "moment";
import { ReactComponent as DataTimbangIcon } from "../../../assets/icons/data_timbang_icon.svg";
import useFetchImage from "../../../atoms/HookFetchingImage";
import ConfirmDialog from "../../../atoms/ConfirmDialog";
import LoadingAnimation from "../../../atoms/LoadingAnimation";
import * as Sentry from "@sentry/react";
import { CgSoftwareDownload } from "react-icons/cg";
import { FiEdit } from "react-icons/fi";
import { IoMdCheckboxOutline } from "react-icons/io";
import ImageDialog from "../../../atoms/ModalDialogImage";
import { useSelector } from "react-redux";
import AlertNotification from "../../../atoms/AlertNotification";
import { FaCheckCircle } from "react-icons/fa";
import { IoCloseCircleOutline } from "react-icons/io5";
import LoadingAnimationRounded from "../../../atoms/LoadingAnimationRounded";

const DetailMutasi = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const successAlert = JSON.parse(localStorage.getItem("response add success"))
  const [successNotif, setSuccessNotif] = useState(successAlert)
  const [detailMutasi, setDetailMutasi] = useState(null);
  const [isValidateDialogOpen, setIsValidateDialogOpen] = useState(false);
  const [isValidateSuccess, setIsValidateSuccess] = useState(false);
  const [isValidateError, setIsValidateError] = useState(false);
  const [fetchData, setFetchData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openFeedImg, setOpenFeedImg] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const { user } = useSelector((state) => state.auth);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const alertEdit = JSON.parse(
    localStorage.getItem("responseSuccessEditMutasi")
  );
  const [isDownloadSuccess, setIsDownloadSuccess] = useState(false);
  const [isDownloadError, setIsDownloadError] = useState(false);
  const [modalConfirmationById, setModalconfirmationById] = useState(false);
  const [messageError, setMessageError] = useState("");
  const [notifEdit, setNotifEdit] = useState(alertEdit);
  const [showDownloadNotif, setShowDownloadNotif] = useState(false)
  const [disabledButton, setDisabledButton] = useState(false)



  useEffect(() => {
    setTimeout(() => {
      setNotifEdit(false);
      localStorage.clear("responseSuccessEditMutasi");
    }, 4000);
  }, [notifEdit]);

  useEffect(() => {
    const refetchDetail = async () => {
      setLoading(true);

      try {
        const data = await api.get(`/v1/feeds/out/${id}`);
        setDetailMutasi(data?.data);
      } catch (err) {
        Sentry.captureException(err);
      } finally {
        setLoading(false);
      }
    };

    if (fetchData) {
      setFetchData(false);
    }

    refetchDetail();
  }, [id, fetchData]);

  const convertKgInzak = (data) => {
    const zakCount = data / 50
    const roundedZakCount = Math.floor(zakCount)

    console.log("mutasi", roundedZakCount)
    return roundedZakCount
  }

  const timeHarvest = [
    {
      id: 1,
      label: "Tanggal pakan keluar",
      value: moment(detailMutasi?.dateOut).format("DD MMMM YYYY"),
    },
    {
      id: 2,
      label: "Kandang tujuan",
      value:
        detailMutasi?.destination?.house?.labels +
        " (Periode ke " +
        detailMutasi?.destination?.sequence +
        ")",
    },
    {
      id: 3,
      label: "Jenis pakan",
      value: detailMutasi?.feed?.labels,
    },
    {
      id: 4,
      label: "Kuantitas (Sak)",
      value: convertKgInzak(detailMutasi?.qtyOut),
    },
    {
      id: 5,
      label: "Tonase (kg)",
      value: detailMutasi?.qtyOut,
    },
    {
      id: 6,
      label: "Estimasi jarak (km)",
      value: detailMutasi?.destinationEstimation,
    },
    {
      id: 7,
      label: "Plat nomor",
      value: detailMutasi?.licenseNumber,
    },
    {
      id: 8,
      label: "Keterangan",
      value: detailMutasi?.creatorNotes || "-",
    },
    {
      id: 10,
      label: "Status",
      value: (
        <Chip
          size="sm"
          variant="ghost"
          value={
            detailMutasi?.status?.id === "WAITING_FOR_REVIEW"
              ? "Menunggu direview"
              : detailMutasi?.status?.id === "ON_REVIEW"
              ? "Sedang direview"
              : "Selesai"
          }
          className={`text-sm font-inter font-normal leading-[18px] border normal-case ${
            detailMutasi?.status?.id === "WAITING_FOR_REVIEW"
              ? "text-[#C49801] border-[#FBE497] bg-[#FEF8E2]"
              : detailMutasi?.status?.id === "ON_REVIEW"
              ? "text-[#1570EF] border-[#B2DDFF] bg-[#F5FAFF]"
              : "text-[#079455] border-[#ABEFC6] bg-[#F6FEF9]"
          }`}
        />
      ),
    },
  ];

  if (detailMutasi?.reviewerNotes?.length > 0) {
    timeHarvest.splice(8, 0, {
      id: 9,
      label: "Catatan Admin",
      value: detailMutasi?.reviewerNotes || "-",
    });
  }

  const handleOpenEditForm = () => {
    navigate(`/mutasi/edit-mutasi/${id}`);
  };

  const FetchedImageComponent = ({ url }) => {
    const { data, error } = useFetchImage(url);

    if (error)
      return (
        <div className="text-center flex justify-center items-center">
          Failed to load image
        </div>
      );
    if (!data)
      return (
        <div className="text-center flex justify-center items-center pt-16">
          Loading...
        </div>
      );

    return (
      <img
        className="h-full w-full object-cover object-center rounded-xl"
        src={data.url}
        alt="mutasi"
      />
    );
  };

  const handleDetailImg = (url) => {
    setImageUrl(url);
    setOpenFeedImg(true);
  };

  const filteredFeature = (featurePermission) => {
    return user?.permissions?.includes(featurePermission);
  };

  const handleValidateMutasi = async () => {
    try {
      const validateBody = {
        rearingPeriod: { id: detailMutasi?.rearingPeriod?.id },
        feed: { id: detailMutasi?.feed?.id },
      };

      const response = await api.put(
        `/v1/feeds/out/${id}/submit`,
        validateBody
      );
      handleCloseValidateDialog();

      setIsValidateSuccess(true);
      setTimeout(() => setIsValidateSuccess(false), 2000);
      window.location.reload();
      return response;
    } catch (error) {
      Sentry.captureException(error);
      handleCloseValidateDialog();
      setIsSubmitting(false);
      console.error("Validate data failed!", error.response);
      setIsValidateError(true);
      setErrorMessage(
        error.response.data.error?.name || error.response.data.error
      );
      setTimeout(() => setIsValidateError(false), 2000);

      setIsValidateError(true);
      setErrorMessage(
        error.response?.data?.error?.name || error.response?.data?.error
      );
      setTimeout(() => setIsValidateError(false), 2000);
    }
  };

  const handleOpenValidateDialog = () => {
    setIsValidateDialogOpen(true);
  };

  const handleCloseValidateDialog = () => {
    setIsValidateDialogOpen(false);
  };

  const handleOpenModalConfirmation = () => {
    setModalconfirmationById(true);
  };

  const handleDownload = async () => {
    setDisabledButton(true)

    try {
      setShowDownloadNotif(true)

      let ids = "?id=" + id;

      const response = await api.get(`/v1/feeds/out/toPdf` + ids, {
        responseType: "blob",
      });

      const contentDisposition = response.headers["content-disposition"];
      let fileName = "mutasi.pdf";

      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename="?([^"]+)"?/);
        if (fileNameMatch && fileNameMatch[1]) {
          fileName = fileNameMatch[1];
        }
      }

      const url = window.URL.createObjectURL(new Blob([response.data]));

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);

      document.body.appendChild(link);

      link.click();

      window.URL.revokeObjectURL(url);
      setModalconfirmationById(false);
      setIsDownloadSuccess(true);
      setIsValidateDialogOpen(false)
      setTimeout(() => setIsDownloadSuccess(false), 3000);
    } catch (error) {
      setModalconfirmationById(false);
      setIsDownloadError(true);
      setTimeout(() => setIsDownloadError(false), 3000);
      setMessageError("Tidak bisa download, status masih belum di review");
      console.log(error);
    } finally {
      setShowDownloadNotif(false)
      setDisabledButton(false)
    }
  };

  console.log("disabled", disabledButton)

  return (
    <div className="flex h-auto xl:overflow-hidden overflow-auto">
      {loading ? (
        <div className="flex justify-center items-center h-screen w-full">
          <LoadingAnimation />
        </div>
      ) : (
        <div className="flex flex-col flex-1 overflow-hidden mt-2xl">
          <Card className="w-full h-auto border p-0 m-0">
            <CardHeader
              floated={false}
              shadow={false}
              className="p-0 m-0 mt-sm rounded-none border-x-0 border-t-0 border-b border-border-secondary"
            >
              <div className="p-3xl">
                <Typography className="text-xl text-text-primary font-bold relative">
                  <div className="flex gap-2 items-center">
                    <BackArrow
                      onClick={() => navigate("/mutasi")}
                      className="cursor-pointer h-5 w-5"
                    />
                    Detail
                  </div>
                </Typography>
              </div>
            </CardHeader>

            <CardBody className="p-0 m-0 hidden:overflow-scroll">
              <Card
                className="border-none m-0 p-0"
                floated={false}
                shadow={false}
              >
                <CardHeader
                  floated={false}
                  shadow={false}
                  className="py-3xl mx-4xl mt-sm rounded-none border-0 border-border-secondary"
                >
                  <div className="flex items-center justify-between">
                    <div>
                      <div className="flex gap-2">
                        <Typography className="text-2xl text-text-primary font-bold">
                          {detailMutasi?.rearingPeriod?.house?.labels}
                        </Typography>
                        <Typography className="text-2xl text-text-primary font-normal">
                          (Periode {detailMutasi?.rearingPeriod?.sequence})
                        </Typography>
                      </div>

                      <Typography className="text-xl text-text-primary font-medium">
                        {detailMutasi?.rearingPeriod?.house?.unit?.labels ||
                          "-"}
                      </Typography>
                    </div>

                    <div className="w-max flex gap-2">
                      {filteredFeature("Ubah data_Mutasi") &&
                        detailMutasi?.status?.id !== "DONE_REVIEW" && (
                          <Button
                            label="Ubah"
                            size="medium"
                            type="btnOutlinedGrey"
                            componentLeft={<FiEdit className="h-5 w-5" />}
                            onClick={() => handleOpenEditForm(123)}
                          />
                        )}
                      {filteredFeature("Validasi data_Mutasi") &&
                        detailMutasi?.status?.id !== "DONE_REVIEW" && (
                          <Button
                            label="Validasi data"
                            size="medium"
                            type="btnPrimary"
                            componentRight={
                              <IoMdCheckboxOutline className="w-6 h-6" />
                            }
                            onClick={handleOpenValidateDialog}
                            disabled={isSubmitting}
                          />
                        )}

                      {user?.level === "ALL_DATA" ||
                      user?.level === "REGION" ? (
                        <Button
                          label="Download"
                          size="medium"
                          type="btnPrimary"
                          componentRight={
                            <CgSoftwareDownload className="h-6 w-6" />
                          }
                          onClick={() =>
                            handleOpenModalConfirmation(detailMutasi?.id)
                          }
                        />
                      ) : (
                        <>
                          {filteredFeature("Download data_Mutasi") &&
                            detailMutasi?.status?.id === "DONE_REVIEW" && (
                              <Button
                                label="Download"
                                size="medium"
                                type={disabledButton ? "btnPrimaryDisabled" : "btnPrimary"}
                                componentRight={
                                  <CgSoftwareDownload className="h-6 w-6" />
                                }
                                onClick={
                                  // handleOpenModalConfirmation(detailMutasi?.id)
                                  handleDownload
                                }
                                disabled={disabledButton}
                              />
                            )}
                        </>
                      )}
                    </div>
                  </div>
                </CardHeader>
                <CardBody>
                  <div className="w-full">
                    <ToasterAlert
                      type="info"
                      className="h-[90px] w-full shadow-none rounded-none"
                      label="Perhatian: mohon periksa dengan teliti!"
                      description="Pastikan untuk memeriksa setiap data dengan teliti. Jika Anda menemukan data yang tidak sesuai, Anda dapat mengubahnya sebelum menyelesaikan proses."
                    />
                  </div>
                  <div className="mt-3 p-4 rounded-lg w-full">
                    <div className="w-full">
                      {timeHarvest?.map((val) => (
                        <div className="flex">
                          <div className="min-w-[300px]">
                            <Typography className="text-sm font-medium leading-tight text-text-tertiary p-lg">
                              {val.label}
                            </Typography>
                          </div>

                          <Typography className="text-base font-bold leading-normal text-text-secondary p-lg">
                            {val.value}
                          </Typography>
                        </div>
                      ))}
                    </div>
                  </div>
                </CardBody>
              </Card>

              <Card
                className="p-0 m-3xl h-auto border"
                floated={false}
                shadow={false}
              >
                <CardHeader
                  floated={false}
                  shadow={false}
                  className="p-0 m-0 border-b rounded-b-none"
                >
                  <div className="flex items-center p-2xl">
                    <DataTimbangIcon />
                    <Typography className="text-xl text-text-primary font-semibold p-2">
                      Foto Dokumentasi
                    </Typography>
                  </div>
                </CardHeader>

                <CardBody className="p-2xl m-0 hidden:overflow-scroll">
                  <div className="w-full flex gap-6">
                    {detailMutasi?.documentations?.map((valDoc) => (
                      <div className="flex justify-start items-center py-lg">
                        <div>
                          <div className="h-[169px] w-[169px]">
                            {valDoc?.labels ? (
                              <div className="flex gap-6 items-center">
                                <Card
                                  className="h-40 w-60 cursor-pointer overflow-hidden transition-opacity hover:opacity-90"
                                  onClick={() =>
                                    handleDetailImg(valDoc?.labels)
                                  }
                                >
                                  <FetchedImageComponent url={valDoc?.labels} />
                                </Card>
                              </div>
                            ) : (
                              <div className="text-center flex items-center">
                                No Image Available
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </CardBody>
              </Card>
            </CardBody>
          </Card>
        </div>
      )}

      <ConfirmDialog
        isOpen={isValidateDialogOpen}
        onClose={() => setIsValidateDialogOpen(!isValidateDialogOpen)}
        content="Setelah melakukan validasi data tidak bisa diubah kembali, apakah kamu yakin akan melanjutkan?"
        title="Validasi data sudah sesuai"
        btnLabel="Lanjutkan"
        onConfirm={handleValidateMutasi}
      />

      <ImageDialog
        open={openFeedImg}
        handlerClose={() => setOpenFeedImg(!imageUrl)}
        url={imageUrl}
        classNameDialog="bg-transparent shadow-none"
        size="xl"
      />

      {isDownloadSuccess && (
        <AlertNotification
          icon={<FaCheckCircle />}
          color="green"
          message="Berhasil unduh data"
        />
      )}

      {isDownloadError && (
        <AlertNotification
          icon={<IoCloseCircleOutline className="w-6 h-6" />}
          color="red"
          message={messageError}
        />
      )}

      {isValidateError && (
        <AlertNotification
          icon={<FaCheckCircle />}
          color="red"
          message={errorMessage}
        />
      )}

      {isValidateSuccess && (
        <AlertNotification
          icon={<FaCheckCircle />}
          color="green"
          message="Barhasil validasi data."
        />
      )}

      {successNotif && (
        <AlertNotification 
          icon={<FaCheckCircle />}
          color="green"
          message="Berhasil menambah data mutasi"
        />
      )}

      {showDownloadNotif && (
        <AlertNotification 
          color={'#0C111D'}
          icon={
            <LoadingAnimationRounded white />
          }
          message="Sedang mengunduh data mutasi"
        />
      )}

      {notifEdit && (
        <AlertNotification
          icon={<FaCheckCircle />}
          color="green"
          message="Barhasil ubah data."
        />
      )}

      <ConfirmDialog
        isOpen={modalConfirmationById}
        onClose={() => setModalconfirmationById(!modalConfirmationById)}
        content={"Kamu akan mendowload 1 data yang telah dipilih menjadi .pdf"}
        title={"Unduh data?"}
        btnLabel="Lanjutkan"
        onConfirm={handleDownload}
        classNameContent={"text-md"}
      />
    </div>
  );
};

export default DetailMutasi;
