/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../../../atoms/Button";
import Input from "../../../atoms/Input";
import {
  Card,
  Typography,
  CardBody,
  Chip,
  CardHeader,
  CardFooter,
} from "@material-tailwind/react";
import {
  PlusIcon,
  ChevronUpDownIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import { FaCheckCircle } from "react-icons/fa";

import api from "../../../api/configApi";
import ConfirmDialog from "../../../atoms/ConfirmDialog";
import AlertNotification from "../../../atoms/AlertNotification";
import Pagination from "../../../atoms/Pagination";
import { ReactComponent as EmptyIcon } from "../../../assets/icons/emptyIconn.svg";
import LoadingAnimation from "../../../atoms/LoadingAnimation";
import { ButtonIcon } from "../../../atoms/ButtonIcon";

const TABLE_HEAD = ["No", "Role", "Jumlah Pengguna", "Status", "Aksi"];

const UserRole = () => {
  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState("");
  const [isSearchProcess, setIsSearchProcess] = useState(false);

  const [roles, setRoles] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const [selectedDeleteRoleId, setSelectedDeleteRoleId] = useState(null);
  const [isDeleteSuccess, setDeleteSuccess] = useState(false);
  const [isDeleteError, setDeleteError] = useState(false);

  const sortableColumns = ["", "name", "totalUser", "isActive"];
  const [sortBy, setSortBy] = useState("");
  const [descending, setDescending] = useState(true);
  const alertAdd = JSON.parse(localStorage.getItem("responseAddSuccess"));
  const alertEdit = JSON.parse(localStorage.getItem("responseSuccessEdit"));
  const [notifAdd, setNotfAdd] = useState(alertAdd);
  const [notifEdit, setNotfEdit] = useState(alertEdit);
  const [isLoading, setIsLoading] = useState(true);
  const [isFetchError, setIsFetchError] = useState(false);

  const handleAddClick = () => {
    navigate("/pengaturan-pengguna/role-akses/tambah-user-role");
  };

  const handleOpenDeleteDialog = (userId) => {
    setSelectedDeleteRoleId(userId);
    setDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setSelectedDeleteRoleId(null);
    setDeleteDialogOpen(false);
  };

  const reloadData = async () => {
    try {
      const response = await api.get("/v1/roles", {
        params: {
          itemPerPage: 10,
          search: searchTerm,
          currentPage: currentPage - 1,
          sortBy: sortBy,
          descending: descending,
        },
      });
      setRoles(response.data.contents);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error("Error fetching roles data:", error);
    }
  };

  const handleConfirmDelete = async () => {
    try {
      const response = await api.delete(`/v1/roles/${selectedDeleteRoleId}`);
      handleCloseDeleteDialog();

      if (reloadData()) {
        reloadData();
      }
      setDeleteSuccess(true);
      setTimeout(() => setDeleteSuccess(false), 4000);
    } catch (error) {
      console.error("Error deleting user:", error);
      setDeleteError(true);
      setTimeout(() => setDeleteError(false), 4000);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await api.get("/v1/roles", {
          params: {
            itemPerPage: 10,
            search: searchTerm,
            currentPage: currentPage - 1,
            sortBy: sortBy,
            descending: descending,
          },
        });
        setRoles(response.data.contents);
        setTotalPages(response.data.totalPages);
        setIsFetchError(false);
      } catch (error) {
        console.error("Error fetching roles data:", error);
        setIsFetchError(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [currentPage, searchTerm, sortBy, descending]);

  useEffect(() => {
    setTimeout(() => {
      setNotfAdd(false);
      localStorage.clear("responseAddSuccess");
    }, 2000);
  }, [notifAdd]);

  useEffect(() => {
    setTimeout(() => {
      setNotfEdit(false);
      localStorage.clear("responseSuccessEdit");
    }, 2000);
  }, [notifEdit]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleOpenEditModal = (userId) => {
    navigate(`/pengaturan-pengguna/role-akses/${userId}`);
  };

  const handleSort = (column) => {
    if (sortableColumns.includes(column)) {
      if (sortBy === column) {
        // Toggle descending if the same column is clicked
        setDescending(!descending);
      } else {
        // Change sorting column and set default descending to false
        setSortBy(column);
        setDescending(false);
      }
    }
  };

  const handleSearchChange = (e) => {
    setIsSearchProcess(!isSearchProcess);
    const keywordSearch = e.target.value;
    setSearchTerm(keywordSearch);
    setIsSearchProcess(false);
    handlePageChange(1);
  };

  return (
    <div className={`flex h-auto overflow-hidden`}>
      <div className="flex flex-col flex-1 overflow-hidden">
        <div className="p-4">
          <Card className="w-full h-auto border shadow-none border-border-secondary">
            <CardHeader
              floated={false}
              shadow={false}
              className="p-md border-none"
            >
              <Typography className="text-xl font-bold text-text-primary mb-4">
                Role Akses
              </Typography>
              <div className="grid grid-cols-4 gap-8">
                <div className="text-left">
                  <Input
                    type="text"
                    id="search"
                    placeholder="Pencarian.."
                    className="max-w-sm"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    size="medium"
                    componentLeft={<MagnifyingGlassIcon className="h-5 w-5" />}
                  />
                </div>
                <div></div>
                <div></div>
                <div className="text-center flex justify-end">
                  <Button
                    label="Tambah role"
                    size="medium"
                    type="btnPrimary"
                    onClick={handleAddClick}
                    componentRight={<PlusIcon className="h-5 w-5" />}
                  />
                </div>
              </div>
            </CardHeader>

            {isLoading ? (
              <div className="flex justify-center items-center h-screen">
                <LoadingAnimation />
              </div>
            ) : (
              <>
                {roles?.length === 0 || isFetchError ? (
                  <div className=" flex justify-center items-center h-screen mr-3">
                    <div>
                      <div className="flex justify-center">
                        <EmptyIcon className="ml-[30px]" />
                      </div>
                      <Typography className="text-xl text-text-secondary font-semibold p-4 text-center">
                        Data tidak ditemukan
                      </Typography>

                      <Typography className="flex justify-between text-text-quarterary font-normal leading-none w-full min-h-full">
                        Data yang Anda cari tidak ditemukan. Coba kata kunci
                        lain
                      </Typography>
                    </div>
                  </div>
                ) : (
                  <>
                    <CardBody className="p-0 max-h-[660px]">
                      <table className="w-full min-w-max table-auto text-left">
                        <thead>
                          <tr>
                            {TABLE_HEAD.map((head, index) => (
                              <th
                                key={head}
                                className="border-y border-border-secondary bg-neutral-50 py-2 px-lg"
                                onClick={() => handleSort(head)}
                              >
                                <Typography className="flex justify-between text-sm font-semibold text-text-quarterary leading-none">
                                  {head}{" "}
                                  {index !== 0 &&
                                    index !== TABLE_HEAD.length - 1 &&
                                    head !== "Jumlah Pengguna" && (
                                      <button
                                        onClick={() =>
                                          handleSort(sortableColumns[index])
                                        }
                                        className="focus:outline-none ml-1"
                                      >
                                        <ChevronUpDownIcon
                                          strokeWidth={2}
                                          className={`h-4 w-4 ${
                                            descending
                                              ? "transform rotate-180"
                                              : ""
                                          }`}
                                        />
                                      </button>
                                    )}
                                </Typography>
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {roles?.map((role, index) => {
                            const isLast = index === roles.length - 1;
                            const classes = isLast
                              ? "p-lg"
                              : "p-lg border-b border-border-secondary";

                            return (
                              <tr key={role.id}>
                                <td className={classes}>
                                  <div className="flex items-center gap-3">
                                    <Typography className="text-text-primary text-sm font-medium leading-tight">
                                      {(currentPage - 1) * 10 + index + 1}
                                    </Typography>
                                  </div>
                                </td>
                                <td className={classes}>
                                  <Typography className="text-text-primary text-sm font-medium leading-tight">
                                    {role.name}
                                  </Typography>
                                </td>
                                <td className={classes}>
                                  <Typography
                                    variant="small"
                                    color="blue-gray"
                                    className="text-text-primary text-sm font-medium leading-tight"
                                  >
                                    {role.totalUser + " Orang"}
                                  </Typography>
                                </td>
                                <td className={classes}>
                                  <div className="w-max">
                                    <Chip
                                      size="sm"
                                      variant="ghost"
                                      value={
                                        role.isActive === true
                                          ? "Aktif"
                                          : "Tidak aktif"
                                      }
                                      className={`text-sm font-inter font-normal leading-[18px] border normal-case ${
                                        role.isActive === true
                                          ? "text-green-600 border-green-200 bg-green-25"
                                          : "text-text-secondary border-neutral-200 bg-neutral-50"
                                      }`}
                                      icon={
                                        role.isActive === true ? (
                                          <span className="mx-auto mt-1 block h-2 w-2 rounded-full bg-green-500 content-['']" />
                                        ) : (
                                          <span className="mx-auto mt-1 block h-2 w-2 rounded-full bg-gray-500 content-['']" />
                                        )
                                      }
                                    />
                                  </div>
                                </td>
                                <td className={`${classes}`}>
                                  <div className="flex justify-start gap-2 m-0">
                                    <ButtonIcon
                                      className="z-25"
                                      onClick={() =>
                                        handleOpenEditModal(role.id)
                                      }
                                      icon="edit"
                                      type="outlineWarning"
                                      size="small"
                                    />
                                    {role?.totalUser > 0 ? (
                                      <ButtonIcon
                                        className="z-25"
                                        icon="deleteDisabled"
                                        type="disabled"
                                        size="small"
                                        disabled="disabled"
                                      />
                                    ) : (
                                      <ButtonIcon
                                        className="z-25"
                                        onClick={() =>
                                          handleOpenDeleteDialog(role.id)
                                        }
                                        icon="delete"
                                        type="outlineError"
                                        size="small"
                                      />
                                    )}
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </CardBody>
                    <CardFooter className="items-center border-t border-border-secondary p-sm">
                      <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={handlePageChange}
                      />
                    </CardFooter>
                  </>
                )}
              </>
            )}
          </Card>
          <ConfirmDialog
            isOpen={isDeleteDialogOpen}
            onClose={handleCloseDeleteDialog}
            onConfirm={handleConfirmDelete}
            title="Hapus Role"
            content="Anda yakin ingin melanjutkan tindakan ini?"
            btnLabel="Hapus"
            btnType="btnError"
            disabled={!roles?.map((val) => val.totalUser > 0)}
          />
          {isDeleteSuccess && (
            <AlertNotification
              icon={<FaCheckCircle />}
              color="green"
              message="Data role berhasil di hapus."
            />
          )}

          {isDeleteError && (
            <AlertNotification
              icon={<FaCheckCircle />}
              color="red"
              message="Terjadi kesalahan saat menghapus role."
            />
          )}
          {notifAdd && (
            <AlertNotification
              icon={<FaCheckCircle />}
              color="green"
              message="Data role berhasil ditambah."
            />
          )}

          {notifEdit && (
            <AlertNotification
              icon={<FaCheckCircle />}
              color="green"
              message="Data role berhasil diubah."
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default UserRole;
