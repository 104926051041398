import React from "react";

const Pakan = () => {

  return (
    <div className="p-4">
        <h2 className="text-xl font-semibold mb-4">Stok Pakan</h2>
      </div>
  );
};

export default Pakan;
