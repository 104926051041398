import React from "react";

import { CalendarIcon } from "@heroicons/react/24/outline";
import { Typography } from "@material-tailwind/react";
import { format } from "date-fns";
import { id as indoLocale } from "date-fns/locale";
import { IoChevronDown, IoChevronUp } from "react-icons/io5";

import { Button } from "../../../../../atoms/V2/Button";
import { Calendar } from "../../../../../atoms/V2/DatePicker";
import { DropdownSingleWithSearch } from "../../../../../atoms/V2/DropdownSingleWithSearch";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../../../../atoms/V2/Form";
import { Input } from "../../../../../atoms/V2/Input";
import { InputNumber } from "../../../../../atoms/V2/InputNumber";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../../../../atoms/V2/Popover";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../../../atoms/V2/Select";
import { cn } from "../../../../../shared/utils";
import { GUARANTEE_TYPE } from "../config";
import { useMasterPeriodeEdit } from "../hook";

const FormEdit = () => {
  const {
    form,
    handleOnSubmit,
    queryHouses,
    setSelectedPPL,
    setSelectedOwner,
  } = useMasterPeriodeEdit();

  return (
    <>
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(handleOnSubmit)}
          className="space-y-8"
        >
          <div className="rounded-md border border-border-secondary">
            <div className="rounded-t-md bg-bg-tertiary px-5 py-3">
              <Typography className="text-lg font-semibold text-text-primary">
                Data master
              </Typography>
            </div>
            <div className="grid grid-cols-2 gap-6 p-5">
              <FormField
                control={form.control}
                name="house"
                render={({ field }) => {
                  const transformedOptions = queryHouses.data?.contents?.map(
                    (house) => ({
                      id: house.id,
                      labels: house.name,
                      name: house.name,
                      code: house.code,
                      owner: house.owner?.id || null,
                      ppl: house.ppl?.id || null,
                      pplName: house.ppl?.fullName || "-",
                    }),
                  );

                  const selectedOption = transformedOptions?.find(
                    (item) => item?.id === field?.value,
                  );

                  return (
                    <FormItem>
                      <FormLabel isRequire>Kandang</FormLabel>
                      <FormControl>
                        <DropdownSingleWithSearch
                          id={field.name}
                          options={transformedOptions}
                          disabled={true}
                          isError={form.formState.errors.house}
                          placeholder="Pilih Kandang"
                          placeholderSearch="Cari nama kandang"
                          selectedOption={selectedOption}
                          onSelect={(value) => {
                            field.onChange(value.id || null);
                            form.setValue("ppl", value?.pplName || "-");
                            setSelectedOwner(value?.id || null);
                            setSelectedPPL(value?.ppl || null);
                          }}
                          content={(option) => (
                            <div className="flex max-w-80 flex-col py-[10px]">
                              <p className="text-base text-text-secondary">
                                {option?.name}
                              </p>
                              <p className="text-xs text-text-quarterary">
                                {option?.code || "-"}
                              </p>
                            </div>
                          )}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  );
                }}
              />

              <FormField
                control={form.control}
                name="ppl"
                render={({ field }) => {
                  return (
                    <FormItem>
                      <FormLabel>PPL</FormLabel>
                      <FormControl>
                        <Input
                          {...field}
                          id={field.name}
                          disabled
                          placeholder="Nama PPL"
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  );
                }}
              />

              <FormField
                control={form.control}
                name="sequence"
                render={({ field }) => {
                  return (
                    <FormItem>
                      <FormLabel>Periode</FormLabel>
                      <FormControl>
                        <Input
                          id={field.name}
                          type="number"
                          placeholder="Periode"
                          value={field.value}
                          onChange={(e) =>
                            field.onChange(Number(e.target.value))
                          }
                          variant={
                            form.formState.errors.sequence ? "error" : "default"
                          }
                          min="1"
                          disabled={true}
                          rightComponent={
                            <div
                              className={cn(
                                "absolute right-0 ml-[12px] flex h-full flex-col items-center justify-center rounded-r-md border border-l-0 bg-bg-secondary_hover px-[6px]",
                                form.formState.errors.sequence
                                  ? "border-fg-errorPrimary"
                                  : "border-gray-300",
                              )}
                            >
                              <button
                                type="button"
                                onClick={() => field.onChange(field.value + 1)}
                                disabled={true}
                              >
                                <IoChevronUp className="size-4 text-fg-primary" />
                              </button>
                              <button
                                type="button"
                                onClick={() =>
                                  field.onChange(Math.max(1, field.value - 1))
                                }
                                disabled={true}
                              >
                                <IoChevronDown className="size-4 text-fg-primary" />
                              </button>
                            </div>
                          }
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  );
                }}
              />

              <FormField
                control={form.control}
                name="idPeriode"
                render={({ field }) => {
                  return (
                    <FormItem>
                      <FormLabel>ID Periode</FormLabel>
                      <FormControl>
                        <Input
                          {...field}
                          id={field.name}
                          disabled
                          placeholder="ID Periode"
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  );
                }}
              />

              <FormField
                control={form.control}
                name="docInDate"
                render={({ field }) => {
                  return (
                    <FormItem>
                      <FormLabel isRequire>Rencana DOC in</FormLabel>
                      <FormControl>
                        <Popover>
                          <PopoverTrigger asChild>
                            <Button
                              id={field.name}
                              variant={
                                form.formState.errors.docInDate
                                  ? "btnErrorOutlined"
                                  : "btnOutlinedGrey"
                              }
                              className={cn(
                                "w-full justify-between pr-3 text-left font-normal",
                                !field.value && "text-gray-400",
                              )}
                            >
                              {field.value ? (
                                format(field.value, "PPP", {
                                  locale: indoLocale,
                                })
                              ) : (
                                <span>Pilih Tanggal</span>
                              )}
                              <CalendarIcon className="h-4 w-4" />
                            </Button>
                          </PopoverTrigger>
                          <PopoverContent
                            className="w-auto bg-white p-0"
                            align="start"
                          >
                            <Calendar
                              mode="single"
                              selected={field.value}
                              onSelect={field.onChange}
                              autoFocus
                            />
                          </PopoverContent>
                        </Popover>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  );
                }}
              />

              <FormField
                control={form.control}
                name="docInPopulation"
                render={({ field }) => {
                  return (
                    <FormItem>
                      <FormLabel isRequire>Populasi DOC in (Ekor)</FormLabel>
                      <FormControl>
                        <InputNumber
                          id={field.name}
                          value={field.value || ""}
                          onValueChange={(values) => {
                            field.onChange(
                              values.value ? Number(values.value) : "",
                            );
                          }}
                          isError={form.formState.errors.docInPopulation}
                          placeholder="Masukkan populasi"
                          rightComponent={
                            <span className="text-sm text-text-placeholder">
                              Ekor
                            </span>
                          }
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  );
                }}
              />
            </div>
          </div>

          <div className="rounded-md border border-border-secondary">
            <div className="rounded-t-md bg-bg-tertiary px-5 py-3">
              <Typography className="text-lg font-semibold text-text-primary">
                Riwayat
              </Typography>
            </div>
            <div className="grid grid-cols-12 gap-6 p-5">
              <div className="col-span-6">
                <FormField
                  control={form.control}
                  name="guaranteeType"
                  render={({ field }) => {
                    return (
                      <FormItem>
                        <FormLabel isRequire>Jenis jaminan</FormLabel>
                        <Select
                          onValueChange={field.onChange}
                          value={field.value}
                        >
                          <FormControl>
                            <SelectTrigger
                              isError={form.formState.errors.guaranteeType}
                              className="text-sm"
                            >
                              <SelectValue placeholder="Pilih jenis jaminan" />
                            </SelectTrigger>
                          </FormControl>
                          <SelectContent>
                            {GUARANTEE_TYPE.map((item, index) => (
                              <SelectItem
                                key={`guarantee-type-${index}`}
                                value={item.value}
                                className="text-sm"
                              >
                                {item.label}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                        <FormMessage />
                      </FormItem>
                    );
                  }}
                />
              </div>
              <div className="col-span-6">
                <FormField
                  control={form.control}
                  name="guaranteeAmount"
                  render={({ field }) => {
                    return (
                      <FormItem>
                        <FormLabel isRequire>Nominal jaminan</FormLabel>

                        <FormControl>
                          <InputNumber
                            id={field.name}
                            value={field.value || ""}
                            onValueChange={(values) => {
                              field.onChange(
                                values.value ? Number(values.value) : "",
                              );
                            }}
                            isError={form.formState.errors.guaranteeAmount}
                            type="currency"
                            placeholder="Masukkan nominal jaminan"
                          />
                        </FormControl>

                        <FormMessage />
                      </FormItem>
                    );
                  }}
                />
              </div>

              <div className="col-span-12 space-y-2">
                <h3 className="text-base font-bold text-text-primary">
                  Diff FCR
                </h3>

                <div className="grid grid-cols-3 gap-6">
                  <FormField
                    control={form.control}
                    name="differentFcrHistory1"
                    render={({ field }) => {
                      return (
                        <FormItem>
                          <FormLabel isRequire>Diff-1</FormLabel>

                          <FormControl>
                            <InputNumber
                              id={field.name}
                              value={field.value ?? "0"}
                              onValueChange={(values) => {
                                field.onChange(
                                  values.value === "" ? 0 : values.value,
                                );
                              }}
                              isError={
                                form.formState.errors.differentFcrHistory1
                              }
                              placeholder="Diff-1"
                              allowNegative={true}
                            />
                          </FormControl>

                          <FormMessage />
                        </FormItem>
                      );
                    }}
                  />
                  <FormField
                    control={form.control}
                    name="differentFcrHistory2"
                    render={({ field }) => {
                      return (
                        <FormItem>
                          <FormLabel isRequire>Diff-2</FormLabel>

                          <FormControl>
                            <InputNumber
                              id={field.name}
                              value={field.value ?? "0"}
                              onValueChange={(values) => {
                                field.onChange(
                                  values.value === "" ? 0 : values.value,
                                );
                              }}
                              isError={
                                form.formState.errors.differentFcrHistory2
                              }
                              placeholder="Diff-2"
                              allowNegative={true}
                            />
                          </FormControl>

                          <FormMessage />
                        </FormItem>
                      );
                    }}
                  />
                  <FormField
                    control={form.control}
                    name="differentFcrHistory3"
                    render={({ field }) => {
                      return (
                        <FormItem>
                          <FormLabel isRequire>Diff-3</FormLabel>

                          <FormControl>
                            <InputNumber
                              id={field.name}
                              value={field.value ?? "0"}
                              onValueChange={(values) => {
                                field.onChange(
                                  values.value === "" ? 0 : values.value,
                                );
                              }}
                              isError={
                                form.formState.errors.differentFcrHistory3
                              }
                              placeholder="Diff-3"
                              allowNegative={true}
                            />
                          </FormControl>

                          <FormMessage />
                        </FormItem>
                      );
                    }}
                  />
                </div>
              </div>
              <div className="col-span-12 space-y-2">
                <h3 className="text-base font-bold text-text-primary">IP</h3>

                <div className="grid grid-cols-3 gap-6">
                  <FormField
                    control={form.control}
                    name="performanceIndexHistory1"
                    render={({ field }) => {
                      return (
                        <FormItem>
                          <FormLabel isRequire>IP-1</FormLabel>

                          <FormControl>
                            <InputNumber
                              id={field.name}
                              value={field.value ?? "0"}
                              onValueChange={(values) => {
                                field.onChange(values.value);
                              }}
                              isError={
                                form.formState.errors.performanceIndexHistory1
                              }
                              placeholder="IP-1"
                              allowNegative={true}
                              allowDecimal={false}
                            />
                          </FormControl>

                          <FormMessage />
                        </FormItem>
                      );
                    }}
                  />
                  <FormField
                    control={form.control}
                    name="performanceIndexHistory2"
                    render={({ field }) => {
                      return (
                        <FormItem>
                          <FormLabel isRequire>IP-2</FormLabel>

                          <FormControl>
                            <InputNumber
                              id={field.name}
                              value={field.value ?? "0"}
                              onValueChange={(values) => {
                                field.onChange(values.value);
                              }}
                              isError={
                                form.formState.errors.performanceIndexHistory2
                              }
                              placeholder="IP-2"
                              allowNegative={true}
                              allowDecimal={false}
                            />
                          </FormControl>

                          <FormMessage />
                        </FormItem>
                      );
                    }}
                  />
                  <FormField
                    control={form.control}
                    name="performanceIndexHistory3"
                    render={({ field }) => {
                      return (
                        <FormItem>
                          <FormLabel isRequire>IP-3</FormLabel>

                          <FormControl>
                            <InputNumber
                              id={field.name}
                              value={field.value ?? "0"}
                              onValueChange={(values) => {
                                field.onChange(values.value);
                              }}
                              isError={
                                form.formState.errors.performanceIndexHistory3
                              }
                              placeholder="IP-3"
                              allowNegative={true}
                              allowDecimal={false}
                            />
                          </FormControl>

                          <FormMessage />
                        </FormItem>
                      );
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-end">
            <Button type="submit" size="small" className="w-fit">
              Simpan
            </Button>
          </div>
        </form>
      </Form>
    </>
  );
};

export default FormEdit;
