import { useQuery } from "@tanstack/react-query";

import api from "../../api/configApi";

const useGetHousesDropdown = (search = "", isActive = false) => {
  const query = useQuery({
    queryKey: ["get-houses-dropdown", { search, isActive }],
    queryFn: async () => {
      const response = await api.get("/v1/houses/dropdown", {
        params: {
          search,
          isActive,
        },
      });
      return response;
    },
    select: ({ data }) => data?.contents,
  });

  return query;
};

export default useGetHousesDropdown;
