import {
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Typography,
} from "@material-tailwind/react";
import PropTypes from "prop-types";
import { Button } from "./Button";
import ToasterAlert from "./Toaster";
import Input from "./Input";
import { CheckboxCust } from "./Checkbox";
import { useState } from "react";


const ConfirmDialogDeleteAccount = ({ isOpen, onClose, onConfirm, className, btnType, disabled }) => {
  const [checkEd, setCheckEd] = useState(false)
  const handleIsChecked = (e) => {
    setCheckEd(e.target.checked)
  }

  return (
    <Dialog size="sm" open={isOpen} toggler={() => onClose()}>
      <DialogHeader toggler={() => onClose()}>
        <Typography
          variant="h5"
        >
          Anda Yakin untuk Menghapus Akun ini ?
        </Typography>
      </DialogHeader>

      <div className="mt-[4px] border-t border-gray-300 my-4 w-full"/>
      <DialogBody className="mt-[-30px]">
        <div className="p-4">
          <Typography
            variant="h5"
            className="text-[16px] text-neutral-900 leading-tight"
          >
            Akun yang terhapus tidak dapat di pulihkan kembali. Semua data akun yang tersimpan akan dihapus.
          </Typography>
        </div>

        <div className="p-4">
          <ToasterAlert
            description="Penting: Akun akan di nonaktifkan permanen dalam jangka waktu 7x24 jam. Jika Anda berubah pikiran silakan masuk menggunakan email/no.telepon dan password yang terdaftar."
            className="text-[#84CAFF]"
          />
        </div>

        <div className="p-4" style={{ userSelect: 'none' }}>
          <Typography
            variant="small"
            className="text-[16px] text-neutral-700 leading-tight"
          >
            Silahkan ketik kata <b>"HAPUS"</b> untuk konfirmasi
          </Typography>

          <Input
            size="medium"
            className="mt-3"
            placeholder="HAPUS"
          />

          <div className="flex">
            <div className="ml-[-12px]">
              <CheckboxCust
                checked={checkEd}
                onChange={handleIsChecked}
              />
            </div>

            <div className="mt-2">
              <Typography>
                Saya memamahami dan menerima semua resiko yang telah dijelaskan terkait penghapusan akun.
              </Typography>
            </div>
          </div>
        </div>
      </DialogBody>
      <DialogFooter className="border-t">
        <Button
          label="Batal"
          size="medium"
          type="btnSecondary"
          className={`${className} mr-md`}
          onClick={() => onClose()}
        />
        <Button
          label="Hapus akun saya"
          size="medium"
          type={btnType}
          onClick={onConfirm}
          // disabled={!checkEd ? "btnSecondar" : "btnLinkRed"}
        />
      </DialogFooter>
    </Dialog>
  );
};

ConfirmDialogDeleteAccount.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  title: PropTypes.string,
  content: PropTypes.string,
};

export default  ConfirmDialogDeleteAccount;
