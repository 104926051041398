import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Switch,
  Typography,
  Radio,
} from "@material-tailwind/react";
import Input from "../../../../atoms/Input";
import api from "../../../../api/configApi";
import { Button } from "../../../../atoms/Button";
import AlertNotification from "../../../../atoms/AlertNotification";
import FormDropdownSearch from "../../../../atoms/FormDropdownSearch";
import TextArea from "../../../../atoms/TextArea";
import { FaCheckCircle } from "react-icons/fa";
import ConfirmDialog from "../../../../atoms/ConfirmDialog";
import FileUpload from "../../../../atoms/FileUpload";
import PhoneInput from "../../../../molecules/login/PhoneInput";
import { ReactComponent as ChevronLeft } from "../../../../assets/icons/chevron-left.svg";
import * as Sentry from "@sentry/react";

const AddBakul = ({ onReloadData }) => {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [countryCode, setCountryCode] = useState("");

  const [address, setAddress] = useState("");
  const [npwp, setNpwp] = useState("");
  const [bakulType, setBakulType] = useState("INDIVIDUAL");

  const [regions, setRegions] = useState([]);
  const [cities, setCities] = useState([]);

  const [selectedRegion, setSelectedRegion] = useState("");
  const [selectedCities, setSelectedCities] = useState("");

  const [isAddSuccess, setAddSuccess] = useState(false);
  const [isAddError, setIsAddError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [searchCity, setSearchCity] = useState("");
  const [searchProvince, setSearchProvince] = useState("");

  const [isActive, setActive] = useState(false);

  const [isAddDialogOpen, setAddDialogOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const [IsNameEmpty, setIsNameEmpty] = useState(false);
  const [IsAddressEmpty, setIsAddressEmpty] = useState(false);

  const [isEmailValid, setIsEmailValid] = useState(true);

  const isSubmitDisabled =
    !name ||
    !phone ||
    !address ||
    !bakulType ||
    !selectedRegion ||
    !selectedCities ||
    !selectedImage;

  const handleSwitchChange = () => {
    setActive(!isActive);
  };

  const handleOpenAddDialog = () => {
    setAddDialogOpen(true);
  };

  const handleCloseAddDialog = () => {
    setAddDialogOpen(false);
  };

  const handleSelectRegion = (select) => {
    setSelectedRegion(select.id);
  };

  const handleSelectCities = (select) => {
    setSelectedCities(select.id);
  };

  const handleNameChange = (e) => {
    let newName = e.target.value;

    if (newName.length < 99) {
      setName(newName);
    }
  };

  const handleNameBlur = () => {
    if (name.trim() === "") {
      setIsNameEmpty(true);
    } else {
      setIsNameEmpty(false);
    }
  };

  const handleAddressBlur = () => {
    if (address.trim() === "") {
      setIsAddressEmpty(true);
    } else {
      setIsAddressEmpty(false);
    }
  };

  const handleEmailChange = (e) => {
    let newEmail = e.target.value;

    if (newEmail.length < 99) {
      setEmail(newEmail);
      validateEmail(newEmail);
    }
  };

  const handleNpwpChange = (e) => {
    let newNpwp = e.target.value;

    // Pastikan hanya angka yang diterima
    newNpwp = newNpwp.replace(/\D/g, "");

    if (!isNaN(newNpwp) && newNpwp.length <= 16) {
      setNpwp(newNpwp);
    }
  };

  const validateEmail = (inputEmail) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailRegex.test(inputEmail)) {
      setIsEmailValid(false);
    } else {
      setIsEmailValid(true);
    }
  };

  useEffect(() => {
    const dataFetchDropdownRegion = async () => {
      try {
        const responseRegionDropdown = await api.get(
          "/v1/regions/id/province/dropdown", {
            params: {
              search: searchProvince,
            },
          }
        );
        setRegions(responseRegionDropdown.data);
      } catch (error) {
        Sentry.captureException(error);
      }
    };
    dataFetchDropdownRegion();
  }, [searchProvince]);

  useEffect(() => {
    const fetchCities = async () => {
      const data = await api.get(`/v1/regions/id/cities/dropdown`, {
        params: {
          search: searchCity,
          province: selectedRegion,
        },
      });
      setCities(data?.data);
    };

    fetchCities();
  }, [searchCity, selectedRegion]);

  const handleSave = async () => {
    setIsSubmitting(true);

    // console.log(selectedImage);
    const formData = new FormData();
    formData.append("ktp", selectedImage);

    // Persiapkan data bakul
    const newBakul = {
      name: name,
      address: address,
      city: { id: selectedCities },
      isActive: isActive,
      bakulType: bakulType,
    };

    if (npwp.length > 0) {
      newBakul.npwp = npwp;
    }

    if (email.length > 0) {
      newBakul.email = email;
    }

    // Tambahkan pengecekan apakah nomorTelepon adalah string dan tidak kosong
    if (typeof phone === "string" && phone.trim() !== "") {
      let formattedPhone = phone;

      // Hilangkan angka 0 di depan jika ada
      formattedPhone = formattedPhone.replace(/^0+/, "");

      // Jika phone tidak mengandung kode negara, tambahkan countryCode
      if (!formattedPhone.startsWith(countryCode)) {
        formattedPhone = countryCode + formattedPhone;
      }
      newBakul.phone = formattedPhone;
    }

    // Masukkan data bakul ke dalam formData sebagai JSON
    formData.append("data", JSON.stringify(newBakul));

    // console.log(selectedImage);
    // console.log(JSON.stringify(newBakul));
    // console.log(formData);

    try {
      const response = await api.post("/v1/bakuls/createWithFile", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      console.log("Bakul berhasil ditambahkan:", response);

      setAddSuccess(true);

      navigate("/master/bakul");
      localStorage.setItem("responseAddSuccess", JSON.stringify(!isAddSuccess));

      if (onReloadData) {
        onReloadData();
      }
    } catch (error) {
      handleCloseAddDialog();
      setIsSubmitting(false);
      console.error("Gagal menambahkan bakul:", error.response.data.error);
      Sentry.captureException(error);
      setIsAddError(true);
      setErrorMessage(
        error.response.data.error?.name || error.response.data.error
      );
      setTimeout(() => setIsAddError(false), 2000);
    }
  };

  const handleBack = () => {
    navigate("/master/bakul");
  };

  const handleFileUpload = (files) => {
    setSelectedImage(files ? files[0] : null);
    // console.log(files);
  };

  const handleBakulTypeChange = (event) => {
    setBakulType(event.target.value);
  };
  // console.log(selectedImage);
  return (
    <div className="p-4 flex justify-center">
      <div>
        <Card className="border w-[800px] shadow-none border-border-secondary">
          <CardHeader floated={false} shadow={false} className="rounded-b-none">
            <Typography className="flex items-center text-xl font-bold text-text-primary">
              <div className="cursor-pointer pr-2" onClick={handleBack}>
                <ChevronLeft />
              </div>
              Tambah bakul
            </Typography>
          </CardHeader>
          <div className="flex justify-center w-full">
            <CardBody className="w-[440px]">
              <div className="mb-3xl">
                <div>
                  <Typography className="block text-sm font-semibold text-text-primary mb-2">
                    Nama{" "}
                    <span className="text-red-500 text-sm font-normal leading-tight">
                      *
                    </span>
                  </Typography>
                  <Input
                    type="text"
                    id="name"
                    placeholder="Masukkan nama"
                    className={`max-w-md ${IsNameEmpty && "border-red-500"}`}
                    value={name}
                    size="medium"
                    onChange={handleNameChange}
                    onBlur={handleNameBlur}
                  />
                </div>
                {name === "" && IsNameEmpty && (
                  <p className="text-red-500 text-sm mt-1">
                    Kolom ini tidak boleh kosong
                  </p>
                )}
              </div>
              <div className="my-3xl">
                <div>
                  <Typography className="block text-sm font-semibold text-text-primary mb-2">
                    Nomor Telepon{" "}
                    <span className="text-red-500 text-sm font-normal leading-tight">
                      *
                    </span>
                  </Typography>
                  {/* <Input
                    type="text"
                    id="name"
                    placeholder="Masukkan nama bakul"
                    className="max-w-md"
                    value={phone}
                    size="medium"
                    onChange={(e) => setPhone(e.target.value)}
                  /> */}
                  <PhoneInput
                    phone={phone}
                    setPhone={setPhone}
                    setCountryCode={setCountryCode}
                    phoneIsNull={false}
                    isLabelsActive={false}
                  />
                </div>
              </div>
              <div className="mb-3xl">
                <Typography className="text-sm font-semibold leading-tight block text-text-primary mb-2">
                  Email:
                </Typography>
                <Input
                  type="email"
                  id="email"
                  placeholder="Masukkan email"
                  value={email}
                  size="medium"
                  onChange={handleEmailChange}
                />
                {!isEmailValid && email && (
                  <p className="text-red-500 text-sm mt-1">
                    Masukkan alamat email yang valid.
                  </p>
                )}
              </div>

              <Typography className="text-sm font-semibold leading-tight block text-text-primary mb-2">
                Provinsi <span className="text-red-500">*</span>
              </Typography>

              <div className="mt-2 mb-3xl">
                <FormDropdownSearch
                  onSelect={handleSelectRegion}
                  placeholder="Pilih provinsi"
                  options={regions?.contents}
                  setSearch={setSearchProvince}
                  search={searchProvince}
                  size="medium"
                />
              </div>

              <Typography className="text-sm font-semibold leading-tight block text-text-primary mb-2">
                Kabupaten / kota <span className="text-red-500 ">*</span>
              </Typography>

              <div className="mt-2 mb-3xl">
                <FormDropdownSearch
                  onSelect={handleSelectCities}
                  placeholder="Pilih kota/kabupaten"
                  options={cities?.contents}
                  setSearch={setSearchCity}
                  search={searchCity}
                  size="medium"
                />
              </div>

              <Typography className="text-sm font-semibold leading-tight block text-text-primary mb-2">
                Alamat<span className="text-red-500">*</span>
              </Typography>

              <div className="mt-2">
                <TextArea
                  placeholder="Masukkan alamat bakul"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  onBlur={handleAddressBlur}
                  className={"placeholder:text-text-placeholder"}
                />
              </div>
              {address === "" && IsAddressEmpty && (
                <p className="text-red-500 text-sm mt-1">
                  Kolom ini tidak boleh kosong
                </p>
              )}
              <Typography className="text-sm font-semibold leading-tight block text-text-primary mb-2 mt-3xl">
                Tipe bakul<span className="text-red-500">*</span>
              </Typography>
              <div className="flex gap-5 mt-2">
                <Radio
                  name="bakulType"
                  label={
                    <Typography className="hover:text-text-primary text-text-secondary font-medium transition-colors text-sm">
                      Individu
                    </Typography>
                  }
                  color="amber"
                  value="INDIVIDUAL"
                  checked={bakulType === "INDIVIDUAL"}
                  onChange={handleBakulTypeChange}
                />
                <Radio
                  name="bakulType"
                  label={
                    <Typography className="hover:text-text-primary text-text-secondary font-medium transition-colors text-sm">
                      Perusahaan
                    </Typography>
                  }
                  color="amber"
                  value="COMPANY"
                  checked={bakulType === "COMPANY"}
                  onChange={handleBakulTypeChange}
                />
              </div>

              <div className="mt-3xl">
                <div>
                  <Typography className="block text-sm font-semibold text-text-primary mb-2">
                    Nomor NPWP
                  </Typography>
                  <Input
                    type="text"
                    id="name"
                    placeholder="Masukkan nomor NPWP"
                    className="max-w-md"
                    value={npwp}
                    size="medium"
                    onChange={handleNpwpChange}
                  />
                </div>
              </div>
              <div className="container mx-auto">
                <Typography className="text-sm font-semibold pt-6 pb-2 text-text-primary">
                  Unggah KTP <span className="text-[#F04438]">*</span>
                </Typography>
                <FileUpload onFileUpload={handleFileUpload} />
              </div>
              <div className="mt-9 items-center gap-4">
                <div className="my-3">
                  <Typography className="block text-text-primary text-sm font-semibold">
                    Status bakul
                  </Typography>
                </div>
                <div>
                  <Switch
                    className="checked:bg-bg-brandSolid"
                    onChange={handleSwitchChange}
                    checked={isActive}
                  />
                  <span className="m-2 text-sm">
                    {isActive ? "Aktif" : "Tidak Aktif"}
                  </span>
                </div>
              </div>

              <div className="mt-9 items-center gap-4">
                <div className="my-3"></div>
                <div></div>
              </div>
            </CardBody>
          </div>

          {/* Save Button */}
          <CardFooter className="flex justify-end border-t border-border-secondary p-lg">
            <Button
              label="Batal"
              size="medium"
              type="btnSecondary"
              className="mr-md"
              onClick={handleBack}
            />
            <Button
              label="Simpan"
              size="medium"
              type={`${
                isSubmitDisabled || isSubmitting
                  ? "btnPrimaryDisabled"
                  : "btnPrimary"
              }`}
              onClick={handleOpenAddDialog}
              disabled={isSubmitDisabled || isSubmitting}
              componentLeft={
                isSubmitting && (
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    className="animate-spin fill-neutral-400"
                  >
                    <path
                      d="M10.72,19.9a8,8,0,0,1-6.5-9.79A7.77,7.77,0,0,1,10.4,4.16a8,8,0,0,1,9.49,6.52A1.54,1.54,0,0,0,21.38,12h.13a1.37,1.37,0,0,0,1.38-1.54,11,11,0,1,0-12.7,12.39A1.54,1.54,0,0,0,12,21.34h0A1.47,1.47,0,0,0,10.72,19.9Z"
                      className="spinner_6kVp"
                    />
                  </svg>
                )
              }
            />
          </CardFooter>
        </Card>

        {/* Komponen dialog konfirmasi tambah data*/}
        <ConfirmDialog
          isOpen={isAddDialogOpen}
          onClose={handleCloseAddDialog}
          onConfirm={handleSave}
          title="Konfirmasi tambah bakul"
          content="Pastikan semua informasi bakul yang ditambahkan sudah benar sebelum menyimpan."
          btnLabel="Lanjutkan"
          btnType="btnPrimary"
        />
        {/* Alert untuk notifikasi error */}
        {isAddError && (
          <AlertNotification
            icon={<FaCheckCircle />}
            color="red"
            message={errorMessage}
          />
        )}
      </div>
    </div>
  );
};

export default AddBakul;
