import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api/configApiAuth";
import Cookies from "js-cookie";
import * as Sentry from '@sentry/react'

const initialState = {
  user: null,
  isLoginError: false,
  isLoginSuccess: false,
  verifyOtp: null,
  isError: false,
  isSuccess: false,
  isVerifyOtpSuccess: false,
  isVerifyOtpFailed: false,
  isLoading: false,
  message: "",
  accessToken: null,
  changePassword: null,
  changeSuccess: false,
  isLogout: null,
  isLogoutSuccess: false,
  isLogoutError: false,
  token: null,
  valuePage: null
};

export const LoginUser = createAsyncThunk(
  "user/LoginUser",
  async (user, thunkAPI) => {
    if (user.loginType === "phone") {
      if (user.phone && user.password) {
        try {
          const response = await api.post("v1/auth/login", {
            username: user.phone,
            password: user.password,
          });

          return response.data;
        } catch (error) {
          Sentry.captureException(error)
          if (error.response) {
            const message = error.response.data.error?.username || error.response.data.error;
            return thunkAPI.rejectWithValue(message);
          }
        }
      }
    } else {
      if (user.email && user.password) {
        try {
          const response = await api.post("v1/auth/login", {
            username: user.email,
            password: user.password,
          });

          return response.data;
        } catch (error) {
          Sentry.captureException(error)
          if (error.response) {
            const message = error.response.data.error?.username || error.response.data.error;
            return thunkAPI.rejectWithValue(message);
          }
        }
      }
    }
  }
);

export const VerifyOtp = createAsyncThunk(
  "user/VerifyOtp",
  async (verifyOtp, thunkAPI) => {
    try {
      const response = await api.post("v1/auth/verifyOtp", {
        username: verifyOtp.receiverOTP,
        otpSession: verifyOtp.otpSession,
        otp: verifyOtp.otpString,
      });
      return response.data;
    } catch (error) {
      Sentry.captureException(error)
      if (error.response) {
        const message = error.response.data.error;
        return thunkAPI.rejectWithValue(message);
      }
    }
  }
);

export const ChangePassword = createAsyncThunk(
  "user/changePassword",
  async (user, thunkAPI) => {
    try {
      await api.post("v1/auth/requestForgotPassword", {
        email: user.email,
      });
    } catch (error) {
      Sentry.captureException(error)
      if (error.response) {
        const message = error.response.data.error;
        return thunkAPI.rejectWithValue(message);
      }
    }
  }
);

export const loadTokenFromCookiesStorage = createAsyncThunk(
  "auth/loadTokenFromCookiesStorage",
  async (_, thunkAPI) => {
    const token = Cookies.get("accessToken");
    if (token) {
      return token;
    } else {
      return thunkAPI.rejectWithValue("Token not found in cookies storage.");
    }
  }
);

export const getMe = createAsyncThunk("user/getMe", async (_, thunkAPI) => {
  try {
    const token = Cookies.get("accessToken");
    const response = await api.get("/v1/users/me", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    Cookies.remove("accessToken");
    Sentry.captureException(error)
    if (error.response) {
      const message = error.response.data.error;
      return thunkAPI.rejectWithValue(message);
    }
  }
});

export const LogOut = createAsyncThunk("user/LogOut", async (_, thunkApi) => {
  try {
    const token = Cookies.get("accessToken");
    const response = await api.delete("/v1/auth/logout", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    // Clear cookies on successful logout
    Cookies.remove("accessToken");
    return response.data;
  } catch (error) {
    Sentry.captureException(error)
    const message = error?.response?.data?.error;
    return thunkApi.rejectWithValue(message);
  }
});

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    reset: (state) => initialState,
    setToken: (state, action) => {
      state.token = action.payload;
    },
    clearToken: (state) => {
      state.token = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(LoginUser.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(LoginUser.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isLoginSuccess = true;
      state.user = action.payload;
      if (action?.payload?.otpSession === null) {
        state.accessToken = action.payload;
        Cookies.set("accessToken", action.payload.accessToken, {
          expires: 7,
          secure: true,
        });
      }
    });
    builder.addCase(LoginUser.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.message = action.payload;
    });

    builder.addCase(VerifyOtp.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(VerifyOtp.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isVerifyOtpSuccess = true;
      state.verifyOtp = action.payload;
      state.accessToken = action.payload;
      Cookies.set("accessToken", action.payload.accessToken, {
        expires: 7,
        secure: true,
      });
    });
    builder.addCase(VerifyOtp.rejected, (state, action) => {
      state.isLoading = false;
      state.isVerifyOtpFailed = true;
      state.message = action.payload;
    });

    // Get User Login
    builder.addCase(getMe.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getMe.fulfilled, (state, action) => {
      state.isLoading = false;
      // state.isSuccess = true;
      state.user = action.payload;
    });
    builder.addCase(getMe.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.message = action.payload;
    });

    // get change password
    builder.addCase(ChangePassword.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(ChangePassword.fulfilled, (state, actions) => {
      state.isLoading = false;
      state.changeSuccess = true;
      state.changePassword = actions.payload;
    });
    builder.addCase(ChangePassword.rejected, (state, actions) => {
      state.isLoading = false;
      state.isError = true;
      state.message = actions.payload;
    });

    // logout
    builder.addCase(LogOut.pending, (state, actions) => {
      state.isLoading = true;
    });
    builder.addCase(LogOut.fulfilled, (state, actions) => {
      Cookies.remove("accessToken");
      Cookies.remove("activeMenu");
      Cookies.remove("activeSubMenu");
      state.user = null;
      state.verifyOtp = null;
      state.isLoading = false;
      state.isLogoutSuccess = true;
      state.isLogout = actions.payload;
    });
    builder.addCase(LogOut.rejected, (state, actions) => {
      state.isLoading = false;
      state.isLogoutError = true;
      state.message = actions.payload;
    });
  },
});

export const { reset, setToken, clearToken } = authSlice.actions;
// export const { setToken, clearToken } = authSlice.actions
export const selectToken = (state) => state.auth.token;
export default authSlice.reducer;
