/* eslint-disable no-mixed-operators */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
} from "@material-tailwind/react";
import Input from "../../atoms/Input";
import { FaCheckCircle } from "react-icons/fa";
import { Button } from "../../atoms/Button";
import api from "../../api/configApi";
import AlertNotification from "../../atoms/AlertNotification";
import PhoneInput from "../../molecules/login/PhoneInput";
import Dropdown from "../../atoms/DropdownWithReset";
import ToasterAlert from "../../atoms/Toaster";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const AddPeternakModal = ({ isOpen, onClose, onReloadData }) => {
  const navigate = useNavigate();
  const [nama, setNama] = useState("");
  const [idPeternak, setIdPeternak] = useState("");

  const [email, setEmail] = useState(null);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [unit, setUnit] = useState();
  const [isActive, setIsActive] = useState(false);

  const [units, setUnits] = useState([]);
  const [selectedUnit, setSelectedUnit] = useState(null);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [phone, setPhone] = useState("");
  const [isPhoneValid, setIsPhoneValid] = useState(true);
  const [countryCode, setCountryCode] = useState("");

  const [isAddSuccess, setAddSuccess] = useState(false);
  const [isAddError, setAddError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [selectedLabel, setSelectedLabel] = useState("");

  const [phoneVerification, setPhoneVerfication] = useState(null);

  const { user } = useSelector((state) => state.auth);

  const isSubmitDisabled =
    !nama ||
    !idPeternak ||
    !phone ||
    !phoneVerification ||
    (email && !isEmailValid) ||
    (phone && !isPhoneValid);

  useEffect(() => {
    const fetchUnits = async () => {
      try {
        const response = await api.get("/v1/units/dropdown");
        setUnits(response.data.contents);
      } catch (error) {
        console.error("Error fetching roles:", error);
      }
    };

    fetchUnits();
  }, [isOpen]);

  const handleSave = async () => {
    let requestData = {
      fullName: nama,
      code: idPeternak,
      unit: { id: unit },
      isActive: true,
    };

    try {
      if (typeof phone === "string" && phone.trim() !== "") {
        let formattedPhone = phone;

        formattedPhone = formattedPhone.replace(/^0+/, "");
        if (!formattedPhone.startsWith(countryCode)) {
          formattedPhone = countryCode + formattedPhone;
        }
        requestData.phoneNumber = formattedPhone;
      }

      setIsSubmitting(true);

      const response = await api.post("/v1/farmers", requestData);

      if (onReloadData) {
        onReloadData();
      }

      setAddSuccess(true);

      setNama("");
      setIdPeternak("");
      setPhone("");
      setIsSubmitting(false);
      setIsActive(false);
      setSelectedLabel("");
      setPhoneVerfication(null);
      onClose();
      localStorage.setItem("response success add farmer", JSON.stringify(!isAddSuccess));
      navigate("/pengaturan-pengguna/farmer/recomendationAddHouse");
    } catch (error) {
      // if (error.response.data.error?.fullName) {
      //   setNama("");
      // } else if (error.response.data.error?.phoneNumber) {
      //   setPhone("");
      // } else {
      //   setNama("");
      //   setIdPeternak("");
      //   setPhone("");
      //   setIsActive(false);
      //   setSelectedLabel("");
      //   setPhoneVerfication(null);
      // }

      setErrorMessage(
        error.response.data.error?.fullName ||
          error.response.data.error?.phoneNumber ||
          error.response.data.error
      );
      setIsSubmitting(false);
      setAddError(true);
      setTimeout(() => setAddError(false), 3000);
      // console.error("Error saving user data:", error);
      console.log(error);
    }
  };

  const handleChangeUnit = (select) => {
    setUnit(select.id);
    setSelectedUnit(select.id);
  };

  const handleNameChange = (e) => {
    let newName = e.target.value;

    if (newName.length < 100) {
      setNama(newName);
    }
  };

  const handleIdPeternakChange = (e) => {
    let newID = e.target.value;

    if (newID.length < 25) {
      setIdPeternak(newID);
    }
  };

  const handleCloseModal = () => {
    setNama("");
    setIdPeternak("");
    setPhone("");
    setIsEmailValid(true);
    setIsPhoneValid(true);
    onClose();
    setIsActive(false);
    setSelectedLabel("");
    setPhoneVerfication(null);
  };

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }

    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [isOpen]);

  return (
    <div>
      {/* Modal Overlay */}
      {isOpen && <div className="fixed inset-0 bg-gray-800 opacity-50 z-[50]"></div>}
      <Card
        className={`fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-[60] ${
          isOpen ? "block" : "hidden"
        } rounded-xl flex-col justify-start items-start`}
        style={{ width: "800px" }}
      >
        <Card>
          <CardHeader
            floated={false}
            shadow={false}
            className="border-b m-0 p-0 border-blue-gray-50 rounded-b-none"
          >
            <button
              className="absolute top-6 right-4 text-gray-500"
              onClick={handleCloseModal}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
            {/* Modal Title */}
            <Typography
              type="h2"
              className="text-lg text-text-primary font-semibold p-4"
            >
              Tambah peternak
            </Typography>
          </CardHeader>
          <CardBody className="p-5">
            <div className="grid-cols-4 gap-4">
              <div className="text-center relativ">
                <ToasterAlert
                  label=""
                  description="Setelah menekan tombol “tambah”, peternak baru akan ditambahkan dan sistem akan mengirimkan informasi akun telah berhasil dibuat kepada peternak"
                  className={`shadow-none bg-bg-infoPrimary border border-border-inforounded-lg text-text-primary text-base font-medium leading-normal`}
                />
              </div>
            </div>
            <div className="grid grid-cols-1 my-5">
              <Typography className="text-text-primary text-sm font-semibold leading-tight">
                Nama Peternak:{" "}
                <span className="text-red-500 text-sm font-normal leading-tight">
                  *
                </span>
              </Typography>
              <Input
                type="text"
                id="name"
                placeholder="Masukkan Nama Peternak"
                className="mt-md"
                value={nama}
                size="medium"
                onChange={handleNameChange}
              />
            </div>
            <div className="grid grid-cols-1 my-5">
              <Typography className="text-text-primary text-sm font-semibold leading-tight">
                ID Peternak:{" "}
                <span className="text-red-500 text-sm font-normal leading-tight">
                  *
                </span>
              </Typography>
              <Input
                type="text"
                id="name"
                placeholder="cth : YYK1/BX38**"
                className="mt-md"
                value={idPeternak}
                size="medium"
                onChange={handleIdPeternakChange}
              />
            </div>
            <div className="grid grid-cols-1 gap-4 my-5">
              <div>
                <Typography className="text-text-primary text-sm font-semibold leading-tight mb-md">
                  Nomor telepon:{" "}
                  <span className="text-red-500 text-sm font-normal leading-tight">
                    *
                  </span>
                </Typography>
                <PhoneInput
                  phone={phone}
                  setPhone={setPhone}
                  setCountryCode={setCountryCode}
                  phoneIsNull={false}
                  isLabelsActive={false}
                  phoneValid={setIsPhoneValid}
                  verification={phoneVerification}
                  setVerification={setPhoneVerfication}
                />
              </div>
            </div>

            {user?.level === "ALL_DATA" || user?.level === "MITRA" || user?.level === "REGION" && (
              <div className="grid grid-cols-1 my-5">
                <Typography className="text-text-primary text-sm font-semibold leading-tight mb-md">
                  Unit:{" "}
                  <span className="text-red-500 text-sm font-normal leading-tight">
                    *
                  </span>
                </Typography>
                <Dropdown
                  onSelect={handleChangeUnit}
                  placeholder="Pilih unit"
                  options={units}
                  selectedOption={selectedUnit}
                />
              </div>
            )}
          </CardBody>
          <CardFooter className="flex justify-end border-t border-border-secondary p-lg">
            <Button
              label="Keluar"
              size="medium"
              type="btnSecondary"
              className="mr-md"
              onClick={handleCloseModal}
            />
            <Button
              label="Tambah"
              size="medium"
              type={`${
                isSubmitDisabled || isSubmitting
                  ? "btnPrimaryDisabled"
                  : "btnPrimary"
              }`}
              onClick={handleSave}
              disabled={isSubmitDisabled || isSubmitting}
              componentLeft={
                isSubmitting && (
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    className="animate-spin fill-neutral-400"
                  >
                    <path
                      d="M10.72,19.9a8,8,0,0,1-6.5-9.79A7.77,7.77,0,0,1,10.4,4.16a8,8,0,0,1,9.49,6.52A1.54,1.54,0,0,0,21.38,12h.13a1.37,1.37,0,0,0,1.38-1.54,11,11,0,1,0-12.7,12.39A1.54,1.54,0,0,0,12,21.34h0A1.47,1.47,0,0,0,10.72,19.9Z"
                      className="spinner_6kVp"
                    />
                  </svg>
                )
              }
            />
          </CardFooter>
        </Card>
      </Card>
      {/* {isAddSuccess && (
        <AlertNotification
          icon={<FaCheckCircle />}
          color="green"
          message="Data peternak berhasil ditambah."
        />
      )} */}

      {/* Alert untuk notifikasi error */}
      {isAddError && (
        <AlertNotification
          icon={<FaCheckCircle />}
          color="red"
          message={errorMessage}
        />
      )}
    </div>
  );
};

export default AddPeternakModal;
