/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/alt-text */
import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import api from "../../../../../api/configApi";
import { Card, CardBody, CardHeader, Chip, Typography } from "@material-tailwind/react";
// import * as Sentry from '@sentry/react'
import { ReactComponent as ChevronLeft } from "../../../../../assets/icons/chevron-left.svg";
import moment from "moment";
import { Button } from "../../../../../atoms/Button";
import LoadingAnimation from "../../../../../atoms/LoadingAnimation";
import { ReactComponent as DetailOpenPage } from '../../../../../assets/icons/open_page_detail.svg'
import axios from "axios";
import Cookies from "js-cookie";



const DetailRecordingHarian = () => {
  const [response, setResponse] = useState(null)
  const [responsePerformace, setResponsePerformance] = useState(null)
  const { id } = useParams()
  const [loading, setLoading] = useState(false)
  const [dataImage, setDataImage] = useState([])
  const navigate = useNavigate()
  const token = Cookies.get("accessToken");

  console.log("params", id)

  useEffect(() => {
    const handleFetching = async () => {
      setLoading(true)
      try {
        const responseDetail = await api.get(`/v1/dailyRecords/${id}`)
        setResponse(responseDetail?.data)
      } catch (error) {
        console.log(error)
      } finally {
        setLoading(false)
      }
    }

    const handleperformanceStandard = async () => {
      try {
        const responsePerformanceFetch = await api.get(`v1/performanceStandard/daily?age=${response?.day}`)
        setResponsePerformance(responsePerformanceFetch?.data)
      } catch (error) {
        console.log("error", error)
      } finally {
        setLoading(false)
      }
    }

    handleperformanceStandard()
    handleFetching()
  }, [id, response?.day])

  const dayliPerform = [
    {id: 1, label: "FCR act", value: response?.actualFcr},
    {id: 2, label: "Std FCR", value: response?.standardFcr},
    {id: 3, label: "Diff FCR", value: response?.diffFcr},
    {id: 4, label: "Feed intake", value: response?.feedIntake?.toLocaleString("id-ID")},
    {id: 6, label: "Std FI", value: response?.standardFi}
  ]

  const Deplesi = [
    {id: 1, label: "Kematian", value: `${response?.mortality} Ekor`},
    {id: 2, label: "Culling", value: ` ${response?.culling} Ekor`}
  ]

  // itungan
  const average = response?.samplingWeight / response?.samplingPopulation
  const peformanceBw = responsePerformace?.contents?.map((val) => val.bw)

  const Bw = [
    {id: 1, label: "Rata-rata bobot timbang", value: `${average || 0} g`},
    {id: 2, label: "Std", value: `${peformanceBw} g` }
  ]

  useEffect(() => {
    const fetchingImage = async () => {
      try {
        const responseImage = await Promise.all(
          response?.documentation?.map(async (url) => await axios.get(`${url}/url`, {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          }))
        )

        const fethched = responseImage?.map((value) => value?.data)
        setDataImage(fethched)
      } catch (error) {
        console.log(error)
      }
    }

    fetchingImage()
  }, [response?.documentation, token])

  const handleBack = () => {
    // /periode-budidaya/periode-berjalan/dataharian/ubah-data-harian/${val}/${id}
    navigate(`/periode-budidaya/periode-berjalan/${response?.rearingPeriod?.id}/dataharian`)
    localStorage.setItem("settingType", "data");
  }

  return (
    <div className="flex h-auto xl:overflow-hidden overflow-hidden">
      <div className="flex flex-col flex-1 overflow-hidden">
        <div className="p-4">
          <Card className="w-full h-auto border">
            {loading ? (
              <div className="flex justify-center items-center h-screen">
                <LoadingAnimation/>
              </div>
            ) : (
              <>
                <CardHeader
                  floated={false}
                  shadow={false}
                  className="p-md border-none"
                >
                  <div className="flex justify-between">
                    <div>
                      <div className="flex">
                        <div
                          className="mt-2 cursor-pointer"
                          onClick={handleBack}
                        >
                          <ChevronLeft />
                        </div>
                        <Typography
                          variant="h2"
                          color="blue-gray"
                          className="text-xl font-semibold p-2"
                        >
                          {moment(response?.recordDate).format('LL')}
                        </Typography>
                      </div>

                      <div className="flex ml-[35px]">
                        <Typography
                          variant="sm"
                          color="blue-gray"
                          className="left-[-4] font-[12px] right-6"
                        >
                          Umur: 
                        </Typography>
                        {""}
                        <Typography
                          variant="md"
                          color="blue-gray"
                          className="text-xl font-semibold p-2 mt-[-9px]"
                        >
                          {response?.day} Hari
                        </Typography>
                      </div>

                      <div className="ml-[32px]">
                        <Chip 
                          variant="warning"
                          value={`populasi: ${response?.dailyPopulation?.toLocaleString('id-ID')}`}
                          className="bg-[#FEF8E2] border-[#FBE497] text-[#161513] w-[117px]"
                          size="sm"
                        />
                      </div>
                    </div>       

                    <div className="text-center mt-[14px]">
                      <Button
                        label="Edit data harian"
                        size="medium"
                        componentRight={<DetailOpenPage/>}
                        onClick={() => navigate(`/periode-budidaya/periode-berjalan/${response?.rearingPeriod?.id}/dataharian/ubah-data-harian/${id}`)}
                      />
                    </div>
                  </div>
                  
                </CardHeader>

                <CardBody className="p-0 hidden:overflow-scroll mt-4">
                  <div className="border-t border-gray-300 my-4 w-full mt-[2px]" />

                  <div className="p-5 mt-[-10px]">
                    <Typography
                      variant="h2"
                      color="blue-gray"
                      className="text-xl font-semibold p-4"
                    >
                      Performa harian
                    </Typography>
                    <Card className="w-full h-auto border">
                      <CardBody className="p-3 hidden:overflow-scroll mt-0">
                        {dayliPerform?.map((val, index) => (
                          <div key={index} className="flex justify-between">
                            <Typography
                              variant="sm"
                              color="blue-gray"
                              className="left-[-4] right-6 mt-3 font-[400]"
                            >
                              {val.label}
                            </Typography>

                            <Typography
                              variant="sm"
                              color="blue-gray"
                              className="left-[-4] right-6 mt-3 font-[500]"
                            >
                              {val.value}
                            </Typography>
                          </div>
                        ))}
                      </CardBody>
                    </Card>
                  </div>

                  <div className="p-5 mt-[-10px]">
                    <Typography
                      variant="h2"
                      color="blue-gray"
                      className="text-xl font-semibold p-4"
                    >
                      Deplesi
                    </Typography>

                    <Card className="w-full h-auto border">
                      <CardBody className="p-3 hidden:overflow-scroll mt-0">
                        {Deplesi.map((val, index) => (
                          <div key={index} className="flex justify-between">
                            <Typography
                              variant="sm"
                              color="blue-gray"
                              className="left-[-4] right-6 mt-3 font-[400]"
                            >
                              {val.label}
                            </Typography>

                            <Typography
                              variant="sm"
                              color="blue-gray"
                              className="left-[-4] font-[500] right-6 mt-3"
                            >
                              {val.value}
                            </Typography>
                          </div>
                        ))}

                        <div className="grid grid-cols-2 gap-4 border-t-2 border-dotted border-neutral-300 rounded-none p-0 m-0 mt-3" />
                        <div className="flex justify-between">
                          <Typography
                            variant="md"
                            color="blue-gray"
                            className="text-xl font-semibold p-0 mt-2"
                          >
                            Total
                          </Typography>

                          <Typography
                            variant="sm"
                            color="blue-gray"
                            className="left-[-4] font-semibold right-6 mt-3 text-[#F04438]"
                          >
                            {response?.totalDepletion} Ekor
                          </Typography>
                        </div>

                        <div className="border-t border-gray-300 my-4 w-full mt-[2px]" />
                        <div className="flex justify-between mt-[-20px]">
                          <Typography
                            variant="sm"
                            color="blue-gray"
                            className="left-[-4] right-6 mt-3"
                          >
                            Std
                          </Typography>

                          <Typography
                            variant="sm"
                            color="blue-gray"
                            className="left-[-4] right-6 mt-3"
                          >
                            {response?.standardDepletion} Ekor
                          </Typography>
                        </div>
                      </CardBody>
                    </Card>
                  </div>

                  <div className="p-5 mt-[-10px]">
                    <Typography
                      variant="h2"
                      color="blue-gray"
                      className="text-xl font-semibold p-4"
                    >
                      BW
                    </Typography>

                    <Card className="w-full h-auto border">
                      <CardBody className="p-3 hidden:overflow-scroll mt-0">
                        {Bw?.map((val, index) => (
                          <div key={index} className="flex justify-between">
                            <Typography
                              variant="sm"
                              color="blue-gray"
                              className="left-[-4] right-6 mt-3 font-[400]"
                            >
                              {val.label}
                            </Typography>

                            <Typography
                              variant="sm"
                              color="blue-gray"
                              className="left-[-4] right-6 mt-3 font-[500]"
                            >
                              {val.value}
                            </Typography>
                          </div>
                        ))}
                      </CardBody>
                    </Card>
                  </div>
                </CardBody>

                <div className="p-5 mt-[-10px]">
                  <Typography
                    variant="h2"
                    color="blue-gray"
                    className="text-xl font-semibold p-4"
                  >
                   Pakan
                  </Typography>

                  <Card className="w-full h-auto border">
                    <CardHeader
                      floated={false}
                      shadow={false}
                      className="p-md border-none"
                    >
                      <div className="flex justify-between">
                        <Typography
                          variant="sm"
                          color="blue-gray"
                          className="font-semibold p-0"
                        >
                          Merk Pakan
                        </Typography>

                        <Typography
                          variant="sm"
                          color="blue-gray"
                          className="font-semibold p-0"
                        >
                          Berat
                        </Typography>
                      </div>
                    </CardHeader>
                    <div className="border-t border-gray-300 my-4 w-full mt-[2px]" />

                    <CardBody className="p-md max-h-full">
                      
                        {response?.usedFeeds?.map((val, index) => (
                          <>
                            <div key={index} className="flex justify-between p-2">
                              <Typography
                                variant="sm"
                                color="blue-gray"
                                className="left-[-4] right-6 font-[400]"
                              >
                                {val?.labels}
                              </Typography>

                              <Typography
                                variant="sm"
                                color="blue-gray"
                                className="left-[-4] right-6 font-[500]"
                              >
                                {val?.usedFeed?.qtyUsed} Kg
                              </Typography>
                            </div>

                            <div className="grid grid-cols-2 gap-4 border-t-2 border-dotted border-neutral-300 rounded-none p-0 m-0 mt-3" />
                            <div className="flex justify-between">
                              <Typography
                                variant="md"
                                color="blue-gray"
                                className="text-xl font-semibold p-2 mt-2"
                              >
                                Total
                              </Typography>

                              <Typography
                                variant="sm"
                                color="blue-gray"
                                className="text-xl font-semibold p-2 mt-2"
                              >
                                {val.usedFeed?.qtyUsed?.toLocaleString("id-ID")} Kg
                              </Typography>

                            </div>
                          </>
                        ))}

                    </CardBody>
                  </Card>
                </div>

                <div className="p-5 mt-[-10px]">
                  <Typography
                    variant="h2"
                    color="blue-gray"
                    className="text-xl font-semibold p-4"
                  >
                   OVK
                  </Typography>

                  <Card className="w-full h-auto border">
                    <CardHeader
                      floated={false}
                      shadow={false}
                      className="p-md border-none"
                    >
                      <div className="flex justify-between">
                        <Typography
                          variant="sm"
                          color="blue-gray"
                          className="font-semibold p-0"
                        >
                          Merk OVK
                        </Typography>

                        <Typography
                          variant="sm"
                          color="blue-gray"
                          className="font-semibold p-0"
                        >
                          Qty
                        </Typography>
                      </div>
                    </CardHeader>
                    <div className="border-t border-gray-300 my-4 w-full mt-[2px]" />

                    <CardBody className="p-md max-h-full">
                      
                        {response?.usedOvks?.map((val, index) => (
                          <>
                            <div key={index} className="flex justify-between p-2">
                              <Typography
                                variant="sm"
                                color="blue-gray"
                                className="left-[-4] right-6 font-[400]"
                              >
                                {val?.labels}
                              </Typography>

                              <Typography
                                variant="sm"
                                color="blue-gray"
                                className="left-[-4] right-6 font-[500]"
                              >
                                {val?.usedOvk?.qtyUsed} Item
                              </Typography>
                            </div>
                          </>
                        ))}

                      {/* </div> */}
                    </CardBody>
                  </Card>

                  <div className="p-0 mt-[80px]">
                    <Typography
                      variant="h2"
                      color="blue-gray"
                      className="text-xl font-semibold p-4"
                    >
                    Informasi Tambahan
                    </Typography>

                    <div className="p-3">
                      <Typography
                        variant="sm"
                        color="blue-gray"
                        className="left-[-4] right-6 mt-[-16px] text-[#667085] font-[600]"
                      >
                        Foto
                      </Typography>
                    </div>

                    <div className="flex ml-[15px] mt-2 gap-3">
                      {dataImage.length > 0 ? (
                         <>
                          {dataImage?.map((val) => (
                            <img src={val?.url} className="w-[150px] h-[150px] rounded-lg" />
                          ))}
                         </>
                      ) : (
                        "-"
                      )}
                    </div>

                    <div className="p-3 mt-10">
                      <Typography
                        variant="sm"
                        color="blue-gray"
                        className="left-[-4] right-6 mt-[-16px] text-[#667085] font-[600]"
                      >
                        Catatan 
                      </Typography>


                      <Typography
                        variant="sm"
                        color="blue-gray"
                        className="left-[-4] right-6 text-[#667085]"
                      >
                        {response?.notes === null ||  response?.notes === "" ? "-" : response?.notes}
                      </Typography>
                    </div>
                  </div>

                </div>
              </>
            )}
          </Card>
        </div>
      </div>
    </div>
  )
}

export default DetailRecordingHarian