import Lottie from "lottie-react";
import chickenAnimation from "../assets/chicken_animation.json";

const LoadingAnimation = ({
  width,
  height
}) => {
  return <Lottie 
    loop={true} 
    animationData={chickenAnimation} 
    width={width}
    height={height}
  />;
};


export default LoadingAnimation