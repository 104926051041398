/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import hijabersinlove from "../../img/hijab_love_emoji.png";
import { Typography } from "@material-tailwind/react";
import { useDispatch, useSelector } from "react-redux";
import { getMe } from "../../molecules/features/authSlice";
import EmptyPermissions from '../../assets/icons/permissions_null.png'

const Dashboard = () => {
  // const ConditionalPermitted = JSON.parse(localStorage.getItem("permitted"))
  const dispatch = useDispatch()
  const { user } = useSelector((state) => state.auth)

  useEffect(() => {
    dispatch(getMe())
  }, [dispatch])


  const getGreeting = () => {
    const now = new Date()
    const hours = now.getHours()

    if (hours >= 4 && hours < 12) {
      return "Selamat Pagi"
    } else if (hours >= 12 && hours < 17) {
      return "Selamat Siang"
    } else if (hours >= 17 && hours < 18) {
      return "Selamat Malam"
    } else {
      return "Hallo"
    }
  }

  return (
    <div className="p-4">
      {user?.permissions.length === 0 ? (
       <>
          <div className="flex justify-center items-center h-screen">
            <div>
              <img src={EmptyPermissions} alt="permissions is null" className="ml-[70px]"/> 
              <Typography
                variant="medium"
                color="blue-gray"
                className="text-xl font-normal p-4 text-center"
              >
               You don't have permission, please contact admin immediately.
              </Typography>
            </div>
          </div>
        </>
      ) : (
         <h2 className="text-xl font-semibold mb-4">Dashboard</h2>
      )}
     
      {/* <div className="flex justify-center items-center">
        <Typography
          type="h2"
          className="text-xl text-neutral-900 font-bold"
        >
          {getGreeting()}, Tunggu dulu ya gesss, masih otw development nich.
        </Typography>
      </div> */}

      {/* <div className="flex justify-center items-center">
        <img
          src={hijabersinlove}
          className="w-[750px] h-[750px] flex justify-center items-center" alt="hijabers in love"
        />
      </div> */}
    </div>
  );
};

export default Dashboard;
