import { DatePicker } from "antd";
import moment from "moment";

const CustomDatePicker = ({
  props,
  placeholder,
  value,
  onChange,
  status,
  disabled,
  format,
  defaultValue,
  disableDate,
  className,
  size,
}) => {
  // Function to handle conditional date disabling
  const disabledDate = (current) => {
    if (typeof disableDate === "function") {
      return disableDate(current); // Use the passed `disableDate` function
    }
    // Default behavior: Disable future dates
    return current && current > moment().endOf("day");
  };

  // Cell render to style specific dates
  const dateCellRender = (date) => {
    const customStyle = {};
    if (
      date?.$d?.getDate() === value?.$d?.getDate() &&
      date?.$d?.getMonth() === value?.$d?.getMonth()
    ) {
      customStyle.backgroundColor = "#F5BE01";
    }

    return (
      <div className="ant-picker-cell-inner" style={customStyle}>
        {date.date()}
      </div>
    );
  };

  return (
    <div className="relative">
      <DatePicker
        className={`${
          className ? className : "block h-[38px]"
        } w-full mt-3 focus:border-primary-400 hover:border-border-primary placeholder-custom rounded-md cursor-pointer ${
          defaultValue === true
            ? "placeholder:text-text-secondary"
            : "placeholder:text-text-quarterary"
        }`}
        popupClassName="custom"
        onChange={onChange}
        placeholder={placeholder}
        value={value}
        disabled={disabled}
        status={status}
        format={format}
        cellRender={dateCellRender}
        disabledDate={disabledDate} // Dynamically use the function
        size={size}
      />
    </div>
  );
};

export default CustomDatePicker;
