import { flexRender } from "@tanstack/react-table";

import { cn } from "../../shared/utils";
import { calculatePinnedPosition, isLastPinnedColumn } from "./config";

const TableData = ({ rows }) => {
  return (
    <tbody>
      {rows.map((row) => (
        <tr key={row.id} className="group bg-white hover:bg-bg-secondary">
          {row.getVisibleCells().map((cell) => {
            const isPinned = cell.column.getIsPinned();
            const pinnedPosition = calculatePinnedPosition(
              cell,
              row.getVisibleCells(),
            );
            const isLastPinned = isLastPinnedColumn(
              cell,
              row.getVisibleCells(),
            );

            const isLastRow = row.id === rows[rows.length - 1].id;
            const isLastCell =
              cell.id === row.getVisibleCells().slice(-1)[0].id;

            return (
              <td
                key={cell.id}
                className={cn(
                  "relative border-border-secondary px-lg group-hover:bg-bg-secondary",
                  isPinned && "sticky z-[2] bg-white",
                  isPinned === "right" && isLastPinned && "w-fit border-l",
                  isLastRow ? "" : "border-b",
                  isLastCell ? "" : "border-r",
                )}
                style={{
                  ...pinnedPosition,
                }}
              >
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            );
          })}
        </tr>
      ))}
    </tbody>
  );
};

export default TableData;
