/* eslint-disable no-sparse-arrays */
/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Typography,
  Radio,
  CardFooter,
  Popover,
  PopoverHandler,
  PopoverContent,
} from "@material-tailwind/react";
import Input from "../../../atoms/Input";
import {
  ChevronUpDownIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import { Button } from "../../../atoms/Button";
import { useState } from "react";
import api from "../../../api/configApi";
import { useNavigate, useParams } from "react-router-dom";
import AlertNotification from "../../../atoms/AlertNotification";
import { FaCheckCircle } from "react-icons/fa";
import { ReactComponent as EmptyIcon } from "../../../assets/icons/emptyIconn.svg";
import LoadingAnimation from "../../../atoms/LoadingAnimation";
import * as Sentry from "@sentry/react";
import { useSelector } from "react-redux";
import { ReactComponent as Download } from "../../../assets/icons/download_icon_black.svg";
import { useDebounce } from "use-debounce";
import { CheckboxCust } from "../../../atoms/Checkbox";
import ConfirmDialog from "../../../atoms/ConfirmDialog";
import LoadingAnimationRounded from "../../../atoms/LoadingAnimationRounded";
import Loading from "../../../atoms/Loading";
import Pagination from "../../../atoms/Pagination";
import { ReactComponent as ChevronLeft } from "../../../assets/icons/chevron-left.svg";
import moment from "moment";
import { FiFilter } from "react-icons/fi";
import DropdownWithSearchInput from "../../../atoms/DropdownWithSearchInput";

const LiveStockUnit = () => {
  const { user } = useSelector((state) => state.auth);
  const { id } = useParams();

  const navigate = useNavigate();
  const [sortBy, setSortBy] = useState("houseName");
  const [descending, setDescending] = useState(true);
  const [search, setSearch] = useState("");
  const [debouncedSearch] = useDebounce(search, 500);
  const [isLoading, setIsLoading] = useState(true);
  const [isFetchError, setIsFetchError] = useState(false);
  const [isLoadingDownload, setIsLoadingDownload] = useState(false);
  const [isDownloadSuccess, setIsDownloadSuccess] = useState(false);
  const [isDownloadError, setIsDownloadError] = useState(false);
  const [livestockById, setLivestockByid] = useState(null);
  const [livestocksByUnit, setLivestocksByUnit] = useState(null);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [modalConfirmation, setModalconfirmation] = useState(false);
  const [downloadType, setDownloadType] = useState("all_data");
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [countFilter, setCountFilter] = useState(0);
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [pplList, setPplList] = useState(null);
  const [checkedPopulations, setCheckedPopulations] = useState([]);
  const [checkedPopulationsString, setCheckedPopulationsString] = useState("");
  const [lastLabelPopulation, setLastLabelPopulation] = useState("");
  const [checkedPpls, setCheckedPpls] = useState([]);
  const [checkedPplsString, setCheckedPplsString] = useState("");
  const [lastLabelPpl, setLastLabelPpl] = useState("");
  const [checkedIntervals, setCheckedIntervals] = useState([]);
  const [checkedIntervalsString, setCheckedIntervalsString] = useState("");
  const [lastLabelInterval, setLastLabelInterval] = useState("");
  const [searchPpl, setSearchPpl] = useState("");
  const [debouncedSearchPpl] = useDebounce(searchPpl, 500);
  const [implementFilter, setImplementFilter] = useState(false);
  const [reloadFilter, setReloadFilter] = useState(false);
  const [initialPopulation, setInitialPopulation] = useState(null);
  const [finalPopulation, setFinalPopulation] = useState(null);
  const [validationErrorInitial, setValidationErrorInitial] = useState("");
  const [validationErrorFinal, setValidationErrorFinal] = useState("");
  const [countLiveStocks, setCountLiveStocks] = useState(0);
  const [countLiveStocksFilter, setCountLiveStocksFilter] = useState(0);

  const sortableColumns = [
    "",
    "houseName",
    "docInDate",
    "dayOfPeriod",
    "docInPopulation",
    "stock",
    "intervalRange",
    "averageWeight",
    "bwAge",
    "actualFcr",
    "totalHarvest",
    "totalDepletion",
    "totalDepletionPercentage",
    "pplName",
    "docName",
  ];

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleCheckAllChange = () => {
    setSelectAll(!selectAll);
    setSelectedRows(selectAll ? [] : livestocksByUnit.map((row) => row.id));
  };

  const TABLE_HEAD = [
    <CheckboxCust
      className="checked:bg-primary-500"
      onChange={handleCheckAllChange}
      checked={selectAll}
    />,
    "Kandang",
    "DOC in",
    "Umur",
    "Populasi",
    "Stock",
    "Interval",
    "BW Rata-rata",
    "BW umur",
    "FCR",
    "Panen",
    "Deplesi",
    "Deplesi (%)",
    "PPL",
    "Nama DOC",
  ];

  useEffect(() => {
    if (selectedRows.length > 0) {
      setDownloadType("selected_data");
    } else if (countLiveStocksFilter > 0) {
      setDownloadType("filter_data");
    } else {
      setDownloadType("all_data");
    }
  }, [modalConfirmation]);

  useEffect(() => {
    const fetchingList = async () => {
      setIsLoading(true);
      try {
        const params = {
          id: id,
        };

        const response = await api.get(
          "/v1/livestock/withTotalWeightByCategory",
          { params }
        );

        setLivestockByid(response?.data?.liveStocks);
        setIsFetchError(false);
      } catch (error) {
        Sentry.captureException(error);
        setIsFetchError(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchingList();
  }, [id]);

  useEffect(() => {
    const fetchingList = async () => {
      setIsLoading(true);
      try {
        const params = {
          sortBy: sortBy,
          descending: descending,
          search: debouncedSearch,
          unitId: id,
          currentPage: debouncedSearch?.length > 0 ? null : currentPage - 1,
          itemPerPage: debouncedSearch?.length > 0 ? null : 10,
        };

        if (implementFilter === true || reloadFilter === true) {
          params.intervalRange = checkedIntervalsString
            ? checkedIntervalsString
            : "";
          params.ppl = checkedPplsString ? checkedPplsString : "";

          params.initialPopulation =
            initialPopulation?.length > 0 ? initialPopulation : 0;

          params.finalPopulation =
            finalPopulation !== null ? finalPopulation : null;
        }

        const response = await api.get("/v1/livestock-unit", { params });

        setLivestocksByUnit(response?.data?.contents);
        setTotalPages(response?.data.totalPages);
        setTotalItems(response?.data.totalItems);

        if (!response) {
          setIsOpenFilter(false);
        }

        setIsFetchError(false);
      } catch (error) {
        Sentry.captureException(error);
        setIsFetchError(true);
      } finally {
        setIsLoading(false);
      }
    };

    if (debouncedSearch || !debouncedSearch) {
      fetchingList();
    }
  }, [
    debouncedSearch,
    sortBy,
    descending,
    currentPage,
    implementFilter,
    reloadFilter,
  ]);

  useEffect(() => {
    if (
      checkedIntervals?.length > 0 ||
      checkedPpls?.length > 0 ||
      initialPopulation?.length > 0 ||
      finalPopulation?.length > 0 ||
      search?.length > 0
    ) {
      setCountLiveStocksFilter(livestocksByUnit?.length);
    } else {
      setCountLiveStocksFilter(0);
      setCountLiveStocks(totalItems);
    }
  }, [
    totalItems,
    checkedIntervals,
    checkedPpls,
    initialPopulation,
    finalPopulation,
  ]);

  useEffect(() => {
    const fetchingPplList = async () => {
      setIsLoading(true);
      try {
        const params = {
          role: "PPL",
          search: debouncedSearchPpl,
          unit: id,
        };

        const response = await api.get("/v1/users/dropdown", { params });

        setPplList(response?.data?.contents);
        setIsFetchError(false);
      } catch (error) {
        Sentry.captureException(error);
        setIsFetchError(true);
      } finally {
        setIsLoading(false);
      }
    };

    if (debouncedSearchPpl || !debouncedSearchPpl) {
      fetchingPplList();
    }
  }, [debouncedSearchPpl]);

  const handleSort = (column) => {
    if (sortableColumns.includes(column)) {
      if (sortBy === column) {
        setDescending(!descending);
      } else {
        setSortBy(column);
        setDescending(false);
      }
    }
  };

  const reqDownload = async (params) => {
    setIsLoadingDownload(true);
    try {
      let ids = params.ids ? "id=" + params.ids : "";

      let searchVal = search?.length > 0 ? "search=" + search : "";

      let pplParams = params.ppls ? "&ppl=" + params.ppls : "";

      let intervalParams = params.intervals
        ? "&intervalRange=" + params.intervals
        : "";

      let initialParams =
        params.initialPopulation?.length > 0
          ? "&initialPopulation=" + params.initialPopulation
          : "&initialPopulation=0";

      let finalParams = params.finalPopulation?.length > 0
        ? "&finalPopulation=" + params.finalPopulation
        : "";

      let searchParams = searchVal?.length > 0 ? "&" + searchVal : "";

      const multipleParams =
        searchParams + pplParams + intervalParams + initialParams + finalParams;

      const response = await api.get(
        `/v1/livestock-unit/toXLSX?unitId=` + id + "&" + ids + multipleParams,
        {
          responseType: "blob",
        }
      );

      const contentDisposition = response.headers["content-disposition"];
      let fileName = "livebird_unit.pdf";

      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename="?([^"]+)"?/);
        if (fileNameMatch && fileNameMatch[1]) {
          fileName = fileNameMatch[1];
        }
      }

      const url = window.URL.createObjectURL(new Blob([response.data]));

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(url);

      setIsDownloadSuccess(true);
      setTimeout(() => setIsDownloadSuccess(false), 3000);
    } catch (error) {
      setModalconfirmation(false);
      setIsDownloadError(true);
      setTimeout(() => setIsDownloadError(false), 3000);
      // setMessageError("Tidak ada data yang bisa di unduh");
      console.log(error);
    } finally {
      setIsLoadingDownload(false);
    }
  };

  const handleDownloadReport = async () => {
    try {
      const params = {};

      if (modalConfirmation) {
        if (downloadType === "selected_data") {
          params.ids = selectedRows.map((row) => row);
          reqDownload(params);
        } else if (downloadType === "filter_data") {
          params.ppls = checkedPplsString;
          params.intervals = checkedIntervalsString;
          params.initialPopulation = initialPopulation?.length > 0 ? initialPopulation : 0;
          params.finalPopulation = finalPopulation;
          reqDownload(params);
        } else {
          reqDownload(params);
        }
      }

      setSelectedRows([]);
      setModalconfirmation(false);
    } catch (error) {
      setModalconfirmation(false);
      console.log(error);
    }
  };

  const handleOpenModalConfirmationDownload = () => {
    setModalconfirmation(true);
  };

  const handleCheckboxChange = (id) => {
    setSelectedRows((prevSelectedRows) => {
      if (prevSelectedRows.includes(id)) {
        return prevSelectedRows.filter((rowID) => rowID !== id);
      } else {
        return [...prevSelectedRows, id];
      }
    });

    // Ensure selectAll is toggled off when a single checkbox changes
    setSelectAll(false);
  };

  const handleDownloadTypeChange = (event) => {
    setDownloadType(event.target.value);
  };

  const weightRangeByUnitId = [
    { title: "<0,8", value: livestockById && livestockById[0]?.a },
    { title: "0,81-1,00", value: livestockById && livestockById[0]?.b },
    { title: "1,01-1,20", value: livestockById && livestockById[0]?.c },
    { title: "1,21-1,40", value: livestockById && livestockById[0]?.d },
    { title: "1,41-1,60", value: livestockById && livestockById[0]?.e },
    { title: "1,61-1,80", value: livestockById && livestockById[0]?.f },
    { title: "1,81-2,00", value: livestockById && livestockById[0]?.g },
    { title: "2,01-2,30", value: livestockById && livestockById[0]?.h },
    { title: "2,31-2,50", value: livestockById && livestockById[0]?.i },
    { title: "> 2,50", value: livestockById && livestockById[0]?.j },
  ];

  const handleSearchChange = (e) => {
    const keywordSearch = e.target.value;
    setSearch(keywordSearch);
    handlePageChange(1);
  };

  const intervalRangeList = [
    {
      id: "< 0.8",
      labels: "< 0,8",
    },
    {
      id: "1.01 - 1.20",
      labels: "1,01 - 1,20",
    },
    {
      id: "1.21 - 1.40",
      labels: "1,21 - 1,40",
    },
  ];
  const handleFilterPpl = (val) => {};

  const handleFilterInterval = (val) => {};

  const handleInitialPopulationChange = (e) => {
    const inputValue = e.target.value;

    if (inputValue <= 1000000) {
      setInitialPopulation(inputValue);
      setValidationErrorInitial("");
    } else {
      setValidationErrorInitial(
        "Nilai inisial tidak bisa melebihi 1 juta ekor."
      );
    }
  };

  const handleFinalPopulationChange = (e) => {
    const inputValue = e.target.value;

    if (inputValue <= 1000000) {
      setFinalPopulation(inputValue);
      setValidationErrorFinal("");
    } else {
      setValidationErrorFinal("Nilai final tidak bisa melebihi 1 juta ekor.");
    }
  };

  const handleTerapkanFilter = () => {
    setIsOpenFilter(false);
    setCountFilter(0);
    let count = 0;

    if (implementFilter === true) {
      setReloadFilter(true);
    }

    if (checkedPplsString !== "") {
      count = count + 1;
    }

    if (checkedIntervalsString !== "") {
      count = count + 1;
    }

    if (initialPopulation?.length > 0 || finalPopulation?.length > 0) {
      count = count + 1;
    }

    setCountFilter(count);
    setImplementFilter(true);
    setCurrentPage(1);
  };

  useEffect(() => {
    setReloadFilter(false);
  }, [reloadFilter]);

  const handleResetFilter = () => {
    setImplementFilter(false);
    setIsOpenFilter(false);
    setInitialPopulation(null);
    setFinalPopulation(null);
    setCheckedPpls([]);
    setCheckedPplsString("");
    setCheckedIntervals([]);
    setCheckedIntervalsString("");
    setCountFilter(0);
    setValidationErrorInitial("");
    setValidationErrorFinal("");
  };

  return (
    <>
      <div className={`flex w-full h-auto overflow-hidden z-0 `}>
        <div className="flex flex-col flex-1 overflow-hidden">
          <div className="p-lg">
            <div className="flex justify-start items-center pb-xl gap-2">
              {user && user?.level !== "UNIT" && (
                <div
                className="cursor-pointer"
                onClick={() => navigate("/stok/livebird")}
                >
                  <ChevronLeft />
                </div>
              )}

              <Typography className="text-xl font-bold text-text-primary">
                Stok Livebird
              </Typography>
              <Typography className="text-xl font-normal text-text-tertiary">
                ({livestockById && livestockById[0]?.unitName})
              </Typography>
            </div>

            <Card className="w-full h-auto border border-border-secondary shadow-none p-lg m-0 mb-2xl">
              <div className="flex justify-between gap-4 items-center">
                {weightRangeByUnitId?.map((weight, index) => (
                  <div className="border border-border-secondary rounded-lg p-md w-full">
                    <Typography className="text-xs font-normal text-text-tertiary leading-[18px]">
                      {weight?.title}
                    </Typography>
                    <div className="flex justify-start gap-1">
                      <Typography className="text-sm font-bold text-text-primary">
                        {weight?.value}
                      </Typography>
                      <Typography className="text-xs font-normal text-text-primary">
                        ekor
                      </Typography>
                    </div>
                  </div>
                ))}
              </div>
            </Card>
            <Card className="w-full h-auto border border-border-secondary shadow-none p-0 m-0">
              <CardHeader
                floated={false}
                shadow={false}
                className="p-0 m-0 border-none w-full"
              >
                <div className="flex justify-between items-center p-2xl">
                  <div className="flex justify-start gap-2 items-center">
                    <div className="text-left">
                      <Input
                        type="text"
                        id="search"
                        placeholder="Cari nama kandang"
                        className="min-w-[350px] border border-border-primary"
                        size="medium"
                        componentLeft={
                          <MagnifyingGlassIcon className="h-5 w-5" />
                        }
                        value={search}
                        onChange={handleSearchChange}
                      />
                    </div>

                    <div>
                      <Popover
                        placement="bottom"
                        open={isOpenFilter}
                        handler={() => setIsOpenFilter(!isOpenFilter)}
                      >
                        <PopoverHandler className="cursor-pointer">
                          <div>
                            <button
                              className={`${
                                countFilter > 0
                                  ? "border-button-infoBorder"
                                  : "border-neutral-300"
                              } p-5 h-11 px-xl py-2.5 justify-center items-center gap-md inline-flex text-base font-medium leading-normal rounded-md bg-white hover:bg-neutral-50 border text-text-secondary focus:shadow-sm focus:shadow-gray-300 hover:border-border-secondary`}
                            >
                              <div
                                className={`justify-center min-w-6 w-full items-center gap-2.5 flex cursor-pointer ${
                                  countFilter > 0
                                    ? "text-fg-infoPrimary"
                                    : "text-text-secondary"
                                }`}
                              >
                                <div className={`w-auto font-medium`}>
                                  Filter
                                </div>
                              </div>
                              <div className="w-6 h-6 relative flex items-center">
                                <FiFilter
                                  className={`h-5 w-5 ${
                                    countFilter > 0 && "text-fg-infoPrimary"
                                  }`}
                                />
                              </div>
                              {countFilter > 0 && (
                                <div className="w-6 h-6 px-3 py-1 bg-fg-infoPrimary rounded-full justify-center items-center gap-1 inline-flex">
                                  <div className="text-white text-xs font-medium leading-[18px]">
                                    {countFilter}
                                  </div>
                                </div>
                              )}
                            </button>
                          </div>
                        </PopoverHandler>

                        <PopoverContent className="top-[210px] left-[500px] z-50 w-[576px]">
                          <div className="w-full relative space-md">
                            <Typography className="flex justify-between font-medium text-xs text-text-secondary leading-[18px] w-full min-h-full mb-sm">
                              PPL
                            </Typography>

                            <DropdownWithSearchInput
                              placeholder="Semua PPL"
                              options={pplList}
                              onSelect={handleFilterPpl}
                              onSelectString={setCheckedPplsString}
                              lastLabel={lastLabelPpl}
                              setLastLabel={setLastLabelPpl}
                              positionClassName="top-[335px] left-[520px]"
                              selectCheckBox={checkedPpls}
                              setSelectCheckbox={setCheckedPpls}
                              search={searchPpl}
                              setSearch={setSearchPpl}
                              placeholderInputSearch="Cari PPL"
                            />
                          </div>

                          <div className="w-full relative my-xl">
                            <Typography className="flex justify-between font-medium text-xs text-text-secondary leading-[18px] w-full min-h-full mb-sm">
                              Populasi
                            </Typography>

                            <div className="flex justify-between items-center">
                              <div className="relative w-full">
                                <Input
                                  type="number"
                                  id="initialPopulation"
                                  placeholder="Populasi minimal"
                                  className="max-w-full w-[200px]"
                                  value={initialPopulation}
                                  size="medium"
                                  onChange={handleInitialPopulationChange}
                                  placeholderSize={"medium"}
                                  componentRight={
                                    <Typography className="font-medium text-sm text-text-placeholder">
                                      Ekor
                                    </Typography>
                                  }
                                />
                              </div>

                              <hr className="mx-4 px-2 border-2 rounded-md border-neutral-500" />
                              <div className="relative w-full">
                                <Input
                                  type="number"
                                  id="finalPopulation"
                                  placeholder="Populasi maksimal"
                                  className="max-w-full"
                                  value={finalPopulation}
                                  size="medium"
                                  onChange={handleFinalPopulationChange}
                                  placeholderSize={"medium"}
                                  componentRight={
                                    <Typography className="font-medium text-sm text-text-placeholder">
                                      Ekor
                                    </Typography>
                                  }
                                />
                              </div>
                            </div>
                          </div>

                          <div className="w-full relative">
                            <Typography className="flex justify-between font-medium leading-none text-xs text-text-secondary w-full min-h-full mb-sm">
                              Interval
                            </Typography>

                            <DropdownWithSearchInput
                              placeholder="Semua interval"
                              options={intervalRangeList}
                              onSelect={handleFilterInterval}
                              onSelectString={setCheckedIntervalsString}
                              lastLabel={lastLabelInterval}
                              setLastLabel={setLastLabelInterval}
                              positionClassName="top-[335px] left-[520px]"
                              selectCheckBox={checkedIntervals}
                              setSelectCheckbox={setCheckedIntervals}
                              disableSearch={true}
                            />
                          </div>

                          <div className="border-t border-gray-300 my-4" />

                          <div className="divide-y flex w-full gap-8 justify-between mt-4">
                            <div onClick={handleResetFilter}>
                              <Typography className="flex justify-between items-center text-sm text-text-primary font-medium leading-tight w-full min-h-full cursor-pointer">
                                Atur Ulang
                              </Typography>
                            </div>

                            <Button
                              size={"small"}
                              label="Terapkan"
                              onClick={handleTerapkanFilter}
                              className="cursor-pointer"
                            />
                          </div>
                        </PopoverContent>
                      </Popover>
                    </div>
                  </div>
                  <div className="text-center flex justify-end">
                    <Button
                      label="Unduh"
                      size="medium"
                      type={
                        isLoadingDownload
                          ? "btnPrimaryDisabled"
                          : user?.level === "UNIT" || user?.level === "ALL_DATA"
                          ? "btnSecondary"
                          : "btnPrimary"
                      }
                      componentLeft={
                        isLoadingDownload ? (
                          <LoadingAnimationRounded />
                        ) : (
                          <Download />
                        )
                      }
                      onClick={handleOpenModalConfirmationDownload}
                      disabled={isLoadingDownload}
                    />
                  </div>
                </div>
              </CardHeader>

              {livestocksByUnit?.length === 0 || isFetchError ? (
                <div className=" flex justify-center items-center h-screen">
                  <div>
                    <div className="flex justify-center">
                      <EmptyIcon className="ml-[30px]" />
                    </div>
                    <Typography
                      variant="h2"
                      className="text-xl font-semibold p-4 text-center text-neutral-500"
                    >
                      Data tidak ditemukan
                    </Typography>

                    <Typography
                      variant="medium"
                      className="flex justify-between font-normal leading-none text-neutral-500 w-full min-h-full"
                    >
                      Data yang Anda cari tidak ditemukan. Coba kata kunci lain
                    </Typography>
                  </div>
                </div>
              ) : (
                <>
                  <CardBody className="p-0 overflow-scroll max-w-full max-h-[960px] z-10 scrollbar-hide">
                    {isLoading ? (
                      <div className="flex justify-center items-center h-screen">
                        <LoadingAnimation />
                      </div>
                    ) : (
                      <>
                        <table className="table-auto w-full min-w-max text-left border-separate border-spacing-0">
                          <thead className="border-y border-border-secondary bg-bg-secondary">
                            <tr>
                              {TABLE_HEAD?.map((head, index) => (
                                <th
                                  key={index}
                                  className={`border-y border-border-secondary bg-neutral-50 h-xl px-lg ${
                                    index === TABLE_HEAD.length - 1
                                      ? "max-w-[140px]"
                                      : index === 0
                                      ? "max-w-[70px] sticky left-0 ml-4 z-10 p-0 "
                                      : index === 1 &&
                                        "left-[68px] sticky ml-4 z-10 p-0 shadow-[rgba(0,0,10,0.2)_3px_0_10px_-5px] border-r py-lg"
                                  }`}
                                >
                                  <div className="flex justify-between gap-4">
                                    <Typography
                                      className={`flex ${
                                        index !== 10
                                          ? "justify-start"
                                          : "justify-end"
                                      } 
                                      font-semibold leading-tight text-text-primary text-sm`}
                                    >
                                      {head}
                                    </Typography>
                                    {index !== 0 && (
                                      <button
                                        className="focus:outline-none "
                                        onClick={() =>
                                          handleSort(sortableColumns[index])
                                        }
                                      >
                                        <ChevronUpDownIcon
                                          strokeWidth={2}
                                          className={`h-4 w-4 ${
                                            descending
                                              ? "transform rotate-180"
                                              : ""
                                          }`}
                                        />
                                      </button>
                                    )}
                                  </div>
                                </th>
                              ))}
                            </tr>
                          </thead>

                          <tbody>
                            {livestocksByUnit?.map((val, index) => {
                              const isLast =
                                index === livestocksByUnit?.length - 1;
                              const classes = isLast
                                ? "p-lg"
                                : "p-lg border-b border-border-secondary";

                              return (
                                <tr
                                  key={val.id}
                                  className="hover:bg-bg-secondary"
                                >
                                  <td
                                    className={`border-b border-border-secondary hover:bg-bg-secondary bg-white sticky left-0 px-lg`}
                                  >
                                    <div className="flex justify-between max-w-[500px]">
                                      <CheckboxCust
                                        className="checked:bg-primary-500"
                                        onChange={() =>
                                          handleCheckboxChange(val?.id)
                                        }
                                        checked={selectedRows?.includes(val.id)}
                                      />
                                    </div>
                                  </td>
                                  <td
                                    className={`border-r border-b border-border-secondary hover:bg-bg-secondary bg-white sticky left-[68px] px-lg shadow-[rgba(0,0,10,0.2)_3px_0_10px_-5px] w-[220px]`}
                                  >
                                    <div className="space-y-1">
                                      <Typography className="text-text-primary text-sm font-medium leading-tight">
                                        {val?.houseName}
                                      </Typography>
                                      <Typography className="text-text-quarterary text-xs font-medium leading-tight">
                                        {val?.analyticAccount || "-"}
                                      </Typography>
                                    </div>
                                  </td>
                                  <td className={classes}>
                                    <Typography className="text-text-primary text-sm font-medium leading-tight">
                                      {moment(val.docInDate).format("LL")}
                                    </Typography>
                                  </td>

                                  <td className={classes}>
                                    <Typography className="text-text-primary text-sm font-medium leading-tight">
                                      {val?.dayOfPeriod}
                                    </Typography>
                                  </td>
                                  <td className={classes}>
                                    <div className="rounded-md min-w-[65px] w-[70%] text-center text-sm font-inter font-normal leading-[18px] border normal-case border-error-200 bg-error-25 p-xs">
                                      <Typography className="text-error-500 text-sm font-medium leading-tight">
                                        {val?.docInPopulation?.toLocaleString(
                                          "id-ID"
                                        )}
                                      </Typography>
                                    </div>
                                  </td>
                                  <td className={classes}>
                                    <div className="rounded-md min-w-[65px] w-[70%] text-center text-sm font-inter font-normal leading-[18px] border normal-case border-[#B2DDFF] bg-[#F4F9FF] p-xs">
                                      <Typography className="text-blue-600 text-sm font-medium leading-tight">
                                        {val?.stock?.toLocaleString("id-ID")}
                                      </Typography>
                                    </div>
                                  </td>
                                  <td className={classes}>
                                    <Typography className="text-text-primary text-sm font-medium leading-tight">
                                      {val?.intervalRange}
                                    </Typography>
                                  </td>
                                  <td className={classes + " text-right pr-xl"}>
                                    <Typography className="text-text-primary text-sm font-medium leading-tight">
                                      {val?.averageWeight?.toLocaleString(
                                        "id-ID"
                                      )}
                                    </Typography>
                                  </td>
                                  <td className={classes + " text-right pr-xl"}>
                                    <Typography className="text-text-primary text-sm font-medium leading-tight">
                                      {val?.bwAge}
                                    </Typography>
                                  </td>
                                  <td className={classes + " text-right pr-xl"}>
                                    <Typography className="text-text-primary text-sm font-medium leading-tight">
                                      {val?.actualFcr?.toLocaleString("id-ID")}
                                    </Typography>
                                  </td>
                                  <td className={classes + " text-right pr-xl"}>
                                    <Typography className="text-text-primary text-sm font-medium leading-tight">
                                      {val?.totalHarvest?.toLocaleString(
                                        "id-ID"
                                      )}
                                    </Typography>
                                  </td>
                                  <td className={classes + " text-right pr-xl"}>
                                    <Typography className="text-text-primary text-sm font-medium leading-tight">
                                      {val?.totalDepletion?.toLocaleString(
                                        "id-ID"
                                      )}
                                    </Typography>
                                  </td>
                                  <td className={classes + " text-right pr-xl"}>
                                    <Typography className="text-text-primary text-sm font-medium leading-tight">
                                      {val?.totalDepletionPercentage?.toLocaleString(
                                        "id-ID"
                                      )}
                                    </Typography>
                                  </td>
                                  <td className={classes}>
                                    <Typography className="text-text-primary text-sm font-medium leading-tight">
                                      {val?.pplName}
                                    </Typography>
                                  </td>
                                  <td className={classes}>
                                    <Typography className="text-text-primary text-sm font-medium leading-tight">
                                      {val?.docName}
                                    </Typography>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </>
                    )}
                  </CardBody>
                  <CardFooter className="items-center border-t border-border-secondary p-sm">
                    <Pagination
                      currentPage={currentPage}
                      totalPages={totalPages}
                      onPageChange={handlePageChange}
                    />
                  </CardFooter>
                </>
              )}
            </Card>
          </div>
        </div>
      </div>
      <ConfirmDialog
        isOpen={modalConfirmation}
        onClose={() => setModalconfirmation(!modalConfirmation)}
        classNameHeader={"px-3xl"}
        content={
          <div>
            <Typography className="px-md">
              Pilih kategori data yang ingin di unduh
            </Typography>
            <div className="grid grid-rows-2 mt-xl">
              <Radio
                name="downloadType"
                className="p-0"
                disabled={
                  countLiveStocksFilter !== 0 || selectedRows?.length !== 0
                    ? true
                    : false
                }
                label={
                  <Typography className="hover:text-text-primary text-text-secondary font-medium transition-colors text-sm">
                    Semua data ({countLiveStocks})
                  </Typography>
                }
                color="amber"
                value="all_data"
                checked={downloadType === "all_data"}
                onChange={handleDownloadTypeChange}
              />
              <Radio
                name="downloadType"
                disabled={selectedRows?.length === 0 ? true : false}
                label={
                  <Typography className="hover:text-text-primary text-text-secondary font-medium transition-colors text-sm">
                    Data yang telah dipilih ({selectedRows?.length})
                  </Typography>
                }
                color="amber"
                value="selected_data"
                checked={downloadType === "selected_data"}
                onChange={handleDownloadTypeChange}
              />
              <Radio
                name="downloadType"
                className="p-0"
                disabled={countLiveStocksFilter === 0 ? true : false}
                label={
                  <Typography className="hover:text-text-primary text-text-secondary font-medium transition-colors text-sm">
                    Semua data yang telah di filter ({countLiveStocksFilter})
                  </Typography>
                }
                color="amber"
                value="filter_data"
                checked={downloadType === "filter_data"}
                onChange={handleDownloadTypeChange}
              />
            </div>
          </div>
        }
        title={"Unduh data file ke XLS"}
        btnLabel="Lanjutkan"
        onConfirm={handleDownloadReport}
        classNameContent={"text-md"}
      />

      {isLoadingDownload && (
        <AlertNotification
          icon={<Loading />}
          color="black"
          message="Sedang mengunduh data stok"
        />
      )}

      {isDownloadSuccess && (
        <AlertNotification
          icon={<FaCheckCircle />}
          color="green"
          message="Berhasil unduh data stok"
        />
      )}

      {isDownloadError && (
        <AlertNotification
          icon={<FaCheckCircle />}
          color="red"
          message="Tidak bisa download data stok"
        />
      )}
    </>
  );
};

export default LiveStockUnit;
