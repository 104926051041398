import React from "react";

const TextArea = ({
  placeholder,
  label,
  value,
  onChange,
  color,
  className,
}) => {

  return (
    <div className="relative">
      <textarea
        placeholder={placeholder}
        label={label}
        value={value}
        onChange={onChange}
        className={`rounded-md h-[120px] w-full border border-${color}-500 ${className} text-neutral-800 px-md py-md justify-start items-center gap-xxs inline-flex focus:outline-none focus:border-primary-400 focus:ring focus:ring-primary-200 `}
      />
    </div>
  )
}

export default TextArea