import { useQuery } from "@tanstack/react-query";

import api from "../../api/configApi";

const useGetMasterPeriod = ({
  itemPerPage = 10,
  currentPage = 1,
  sortBy = "createdAt",
  descending = true,
  search = "",
  initialCapacity = "",
  finalCapacity = "",
  ppl = "",
  docInInitial = "",
  docInFinal = "",
  unit = "",
}) => {
  const query = useQuery({
    queryKey: [
      "get-master-period",
      {
        itemPerPage,
        currentPage,
        sortBy,
        descending,
        search,
        initialCapacity,
        finalCapacity,
        ppl,
        docInInitial,
        docInFinal,
        unit,
      },
    ],
    queryFn: async () => {
      const response = await api.get("/v1/masterPeriods", {
        params: {
          itemPerPage,
          currentPage,
          sortBy,
          descending,
          search,
          initialCapacity,
          finalCapacity,
          ppl,
          docInInitial,
          docInFinal,
          unit,
        },
      });
      return response;
    },
    select: ({ data }) => data,
  });

  return query;
};

export default useGetMasterPeriod;
