/* eslint-disable no-unused-expressions */
/* eslint-disable no-lone-blocks */
/* eslint-disable no-unused-vars */
import { Card, Typography } from "@material-tailwind/react"
import Input from "../../../atoms/Input"
import { FaCheckCircle, FaEye, FaEyeSlash } from "react-icons/fa";
import { Button } from "../../../atoms/Button";
import { useRef, useState } from "react";
import api from '../../../api/configApi'
import LoadingAnimation from "../../../atoms/LoadingAnimation";


const FormChangePassword = ({ onClose, onNext }) => {
  const passwordRef = useRef(null);
  const confirmPasswordRef = useRef(null)

  const [showPassword, setShowPassword] = useState(false)
  const [newPassword, setNewPassword] = useState("")
  const [newPasswordValidLength, setNewPasswordValidLength] = useState(false)
  const [newPasswordEmpty, setNewPasswordEmpty] = useState(false)

  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState("")
  const [showPasswordConfirmation, setShowPasswordConfirmation] = useState(false)
  const [passwordConfirmationValid, setPasswordConfirmationValid] = useState(false)

  const [showError, setShowError] = useState(false)
  const [errorMesaage, setErrorMessage] = useState("")

  const [loading, setLoading] = useState(false)
  const [isSubmiting, setIsSubmitting] = useState(false);

  const responseSendOtp = JSON.parse(localStorage.getItem("verifyOTP")) || {};
  const isDisabled = !newPassword && !newPasswordConfirmation

  const handleShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleShowPasswordConfirmation = () => {
    setShowPasswordConfirmation(!showPasswordConfirmation)
  }

  const handlePasswordBlur = () => {
    // Validasi saat fokus keluar dari field password
    if (newPassword.trim() === "") {
      setNewPasswordEmpty(true);
    } else {
      setNewPasswordEmpty(false);
    }
  };


  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setNewPassword(newPassword)
   
    if (newPassword.length > 0 && newPassword.length < 8) {
      setNewPasswordValidLength(true)
    } else {
      setNewPasswordValidLength(false)
    }
  }

  const handlePasswordConfirmation = (e) => {
    const passwordConfirmation = e.target.value
    setNewPasswordConfirmation(passwordConfirmation)
  }

  const handleChangePassword = async () => {
    setLoading(true)
    setIsSubmitting(true)

    const passwordNew = {
      newPassword: newPassword
    }

    try {
      const response = await api.post('/v1/users/me/credentials', passwordNew, {
        headers: {
          Authorization: `Bearer ${responseSendOtp?.data?.accessToken}`
        }
      })
      console.log("response", response)
      // localStorage.clear('newUsernamePassword')
      // localStorage.clear('verifyOTP')
      // localStorage.clear('userNameFromButtom')
      // localStorage.clear('responseOTPSession')
      // localStorage.clear('dataConfirmationOTP')
      onClose()
    } catch (error) {
      setShowError(true)
      setErrorMessage(error.response.data.error.newPassword)
      console.log("error", error)
    } finally {
      setLoading(false)
      setIsSubmitting(false)
    }
  }

  // {loading ? (
  //   <div className="flex justify-center items-center md:min-h-[700px] min-h-[500px] overflow-hidden">
  //     <LoadingAnimation />
  //   </div>
  // ) : null}


  return (
    <>
      {loading ? (
        <div className="bg-white flex justify-center items-center fixed inset-0 overflow-hidden">
          <LoadingAnimation />
        </div>
      ) : (
        <div className="flex justify-center items-center md:min-h-[700px] min-h-[500px] overflow-hidden">
          <Card className="w-[484px] h-[484px] max-w-[484px] md:min-w-sm shadow-none md:shadow-md md:border-t-2 md:border-gray-100 rounded-xl md:p-4xl p-xs mt-[25px]">
            <Typography
              type="h2"
              className="text-xl font-bold text-neutral-900 mb-xs text-start"
            >
              Buat kata sandi baru
            </Typography>

            <div className="mt-[30px]">
              <div className="mt-2xl">
                <div className="grid grid-cols-2 gap-4">
                  <div className="mb-md">
                    <label
                      htmlFor="password"
                      className="text-sm font-medium text-neutral-900"
                    >
                      Kata sandi baru
                    </label>
                  </div>
                </div>

                <div className="relative">
                  <Input
                    type={showPassword ? "text" : "password"}
                    id="password"
                    className={`border ${
                      newPasswordValidLength ? "border-red-500" : "border-gray-300"
                    } focus:ring rounded-md`}
                    
                    placeholder="Masukkan kata sandi"
                    value={newPassword}
                    onChange={handlePasswordChange}
                    size="medium"
                    ref={passwordRef}
                    isError={newPasswordValidLength}
                    onBlur={handlePasswordBlur}
                    componentRight={
                      <button type="button" onClick={handleShowPassword}>
                        {showPassword ? (
                          <FaEyeSlash className="h-5 w-5 text-neutral-400" />
                        ) : (
                          <FaEye className="h-5 w-5 text-neutral-400" />
                        )}
                      </button>
                    }
                  />
                </div>

                {newPasswordValidLength ? (
                  <Typography
                    type="small"
                    className="text-sm font-normal text-red-500 mt-[11px] mb-4"
                  >
                    Kata sandi terlalu pendek, minimum 8 karakter
                  </Typography>
                ) : (
                  <Typography
                    type="small"
                    className={`text-sm font-normal ${!newPasswordValidLength ? "text-red-600" : "text-neutral-500"} mb-4xl text-statr mt-[11px]`}
                  >
                    Minimum 8 karakter
                  </Typography>
                )}

                

              </div>

              <div className="mt-[25px]">
                <div className="grid grid-cols-2 gap-4">
                  <div className="mb-md">
                    <label
                      htmlFor="password"
                      className="text-sm font-medium text-neutral-900"
                    >
                      Konfirmasi kata sandi baru
                    </label>
                  </div>
                </div>

                <div className="relative">
                  <Input
                    type={showPasswordConfirmation ? "text" : "password"}
                    id="password"
                    className={`${
                      (passwordConfirmationValid) &&
                      "border-red-500"
                    }`}
                    placeholder="Masukkan kata sandi"
                    value={newPasswordConfirmation}
                    onChange={handlePasswordConfirmation}
                    // onBlur={handlePasswordBlur}
                    size="medium"
                    // onKeyDown={handleKeyDown}
                    isError={showError}
                    ref={confirmPasswordRef}
                    componentRight={
                      <button onClick={handleShowPasswordConfirmation}>
                        {showPasswordConfirmation ? (
                          <FaEyeSlash className="h-5 w-5 text-neutral-400" />
                        ) : (
                          <FaEye className="h-5 w-5 text-neutral-400" />
                        )}
                      </button>
                    }
                  />
                </div>

                {showError && (
                  <Typography
                    type="small"
                    className="text-sm font-normal text-red-500 mt-[11px] mb-4"
                  >
                  {errorMesaage}
                  </Typography>
                )}
              </div>
            </div>

            <Button
              label="Simpan"
              size="medium"
              className="mt-[24px]"
              onClick={handleChangePassword}
              disabled={isDisabled || isSubmiting}
              type={isDisabled || isSubmiting ? 'btnPrimaryDisabled' : 'btnPrimary'}
              componentLeft={
                isSubmiting && (
                  <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  className="animate-spin fill-neutral-400"
                >
                  <path
                    d="M10.72,19.9a8,8,0,0,1-6.5-9.79A7.77,7.77,0,0,1,10.4,4.16a8,8,0,0,1,9.49,6.52A1.54,1.54,0,0,0,21.38,12h.13a1.37,1.37,0,0,0,1.38-1.54,11,11,0,1,0-12.7,12.39A1.54,1.54,0,0,0,12,21.34h0A1.47,1.47,0,0,0,10.72,19.9Z"
                    className="spinner_6kVp"
                  />
                </svg>
                )
              }
            />
          </Card>
        </div>
      )}
    </>
  )
}

export default FormChangePassword