import React from "react";

import NumberFormat from "react-number-format";

import { cn } from "../../shared/utils";

const sizes = {
  xsmall: { input: "h-7 rounded-sm text-sm", icon: "h-5 w-5" },
  small: { input: "h-9 rounded-sm text-sm", icon: "h-5 w-5" },
  medium: { input: "h-11 rounded-md", icon: "h-5 w-5" },
  large: { input: "h-14 rounded-md", icon: "h-6 w-6" },
};

const InputNumber = React.forwardRef(
  (
    {
      placeholder,
      value,
      onChange,
      isError,
      size = "small",
      type = "numeric",
      className,
      leftComponent,
      rightComponent,
      disabled,
      placeholderSize,
      decimalScale,
      onValueChange,
      allowNegative = false,
      allowDecimal = true, // New prop with default value true
      ...props
    },
    ref,
  ) => {
    const height = sizes[size]?.input || sizes.medium.input;
    const isCurrency = type === "currency";

    return (
      <div className="relative">
        {leftComponent && (
          <div className="absolute inset-y-0 left-0 flex items-center pl-3">
            {leftComponent}
          </div>
        )}

        <NumberFormat
          getInputRef={ref}
          thousandSeparator="."
          decimalSeparator=","
          allowNegative={allowNegative}
          fixedDecimalScale={allowDecimal} // Only fix decimal scale if decimals are allowed
          displayType="input"
          type="text"
          onValueChange={(values) => {
            // Ensure zero is properly handled
            onValueChange &&
              onValueChange({
                ...values,
                value: values.floatValue === 0 ? 0 : values.value,
              });
          }}
          decimalScale={allowDecimal ? decimalScale : 0} // Force 0 decimal places if decimals not allowed
          disabled={disabled}
          placeholder={placeholder}
          onChange={onChange}
          isNumericString={true}
          allowLeadingZeros={true}
          value={value === 0 ? "0" : value}
          prefix={isCurrency ? "Rp. " : ""}
          className={cn(
            `${height} w-full border`,
            isError ? "border-fg-errorPrimary" : "border-gray-300",
            leftComponent && isCurrency
              ? "pl-[108px]"
              : leftComponent
                ? "pl-5"
                : "pl-3",
            rightComponent ? "pr-5" : "pr-3",
            "inline-flex items-center justify-start gap-1 px-2 py-1 text-text-primary placeholder:text-text-placeholder focus:border-primary-400 focus:outline-none focus:ring focus:ring-primary-200",
            placeholderSize === "medium" && "placeholder:text-md",
            className,
          )}
          {...props}
        />

        {rightComponent && (
          <div className="absolute inset-y-0 right-0 flex items-center pr-3">
            {rightComponent}
          </div>
        )}
      </div>
    );
  },
);

InputNumber.displayName = "InputNumber";

export { InputNumber };
