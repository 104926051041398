import { ReactComponent as Info } from "../assets/icons/info-circle.svg";
import { ReactComponent as Warning } from "../assets/icons/constraint_warning.svg";
import { Typography } from "@material-tailwind/react";

const ToasterAlert = ({ type, label, description, className }) => {
  const getAlertClasses = () => {
    switch (type) {
      case "success":
        return "bg-green-500 text-white";
      case "warning":
        return "bg-bg-warningPrimary border-border-warning text-text-text-primary ";
      case "error":
        return "bg-red-500 text-white";
      case "info":
        return "bg-bg-infoPrimary border border-border-info ";
      default:
        return "bg-[#EFF8FF] border-[#84CAFF] text-[#667085]";
    }
  };

  const getIconConditional = () => {
    switch (type) {
      case "success":
        return null;
      case "warning":
        return <Warning />;
      default:
        return <Info />;
    }
  };

  return (
    <div
      className={`p-4 text-left border ${getAlertClasses()} shadow-none rounded-lg mb-4 ${className}`}
    >
      <div className="flex justify-start gap-4">
        <div>{getIconConditional()}</div>

        <div>
          <Typography className="text-text-primary text-base font-medium leading-normal">
            {label}
          </Typography>
          <Typography className="text-text-quarterary text-sm font-normal leading-tight">
            {description}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default ToasterAlert;
