/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  Typography,
  CardBody,
  CardFooter,
  Chip,
  PopoverContent,
  Popover,
  PopoverHandler,
} from "@material-tailwind/react";
import {
  PlusIcon,
  ChevronUpDownIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";

import { Button } from "../../../atoms/Button";
import DropdownFilterStatus from "../../../atoms/DropdownFilterStatusMitra";
import Input from "../../../atoms/Input";
import Pagination from "../../../atoms/Pagination";
import AddPeternakModal from "../../../molecules/peternak/AddPeternakModal";
import EditPeternakModal from "../../../molecules/peternak/EditPeternakModal";
import { FaCheckCircle } from "react-icons/fa";

import api from "../../../api/configApi";
import ConfirmDialog from "../../../atoms/ConfirmDialog";
import AlertNotification from "../../../atoms/AlertNotification";
import { ReactComponent as EmptyIcon } from "../../../assets/icons/emptyIconn.svg";
import LoadingAnimation from "../../../atoms/LoadingAnimation";
import * as Sentry from "@sentry/react";
import { FiFilter } from "react-icons/fi";
import { useSelector } from "react-redux";
import { countryData } from "../../../data/Country";
import moment from "moment";
import DropdownWithSearchInput from "../../../atoms/DropdownWithSearchInput";
import { ButtonIcon } from "../../../atoms/ButtonIcon";

const Peternak = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchDisplay, setSearchDisplay] = useState("");

  const [selectedCountry, setSelectedCountry] = useState("ID");
  const [isSearchProcess, setIsSearchProcess] = useState(false);

  const [users, setUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isAddModalOpen, setAddModalOpen] = useState(false);
  const [isEditModalOpen, setEditModalOpen] = useState(false);

  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const [selectedDeleteUserId, setSelectedDeleteUserId] = useState(null);
  const [selectedEditUserId, setSelectedEditUserId] = useState(null);
  const [isDeleteSuccess, setDeleteSuccess] = useState(false);
  const [isDeleteError, setDeleteError] = useState(false);

  const sortableColumns = ["", "fullName", "role.name", "unit.name"];
  const [sortBy, setSortBy] = useState("");
  const [descending, setDescending] = useState(true);
  const [loading, setLoading] = useState(true);
  const [isFetchError, setIsFetchError] = useState(false);

  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [units, setUnits] = useState([]);
  const [roles, setRoles] = useState([]);
  const [searchRole, setSearchRole] = useState("");
  const [searchUnit, setSearchUnit] = useState("");
  const [reloadFilter, setReloadFilter] = useState(false);
  const [isOpenFilterStatus, setIsOpenFilterStatus] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState();
  const [labelStatus, setLabelStatus] = useState(null);
  const [placeholderStatus, setPlaceholederStatus] =
    useState("Status Kemitraan");
  const [countFilter, setCountFilter] = useState(0);
  const [selectedRole, setSelectedRole] = useState(null);
  const [checkedRoles, setCheckedRoles] = useState([]);
  const [searchRegions, setSearchRegions] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [lastLabelRegion, setLastLabelRegion] = useState("");
  const [checkedRegions, setCheckedRegions] = useState("");
  const [checkUnit, setCheckUnit] = useState("");
  const [lastLabelUnit, setLastLabelUnit] = useState("");
  const [implementFilter, setImplementFilter] = useState(false);
  const [checkedUnitsString, setCheckedUnitsString] = useState("");
  const [checkedRegionString, setCheckedRegionsString] = useState("");
  const [selectedUnit, setSelectedUnit] = useState(null);
  const [profile, setProfile] = useState(null);

  const [regions, setRegions] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  const { user } = useSelector((state) => state.auth);

  const handleFilterReset = () => {
    setImplementFilter(false);
    setIsOpen(false);
    setSelectedRole("");
    setSelectedRole("");
    setCheckedRoles([]);
    setCheckedRegions([]);
    setCheckedRegionsString("");
    setCheckUnit([]);
    setCountFilter(0);
  };

  const handleFilterTerapkan = () => {
    setCountFilter(0);
    let count = 0;

    if (implementFilter === true) {
      setReloadFilter(true);
    }

    if (checkedRegionString !== "") {
      count = count + 1;
    }

    if (checkedUnitsString !== "") {
      count = count + 1;
    }

    setCountFilter(count);
    setImplementFilter(true);
    setCurrentPage(1);
  };

  // Filter fitur berdasarkan izin (permissions) yang diberikan
  const filteredFeature = (featurePermission) => {
    return user?.permissions?.includes(featurePermission);
  };

  const TABLE_HEAD = [
    "No",
    "Nama Lengkap",
    "ID Peternak",
    "Nomor Handphone",
    "Aktivitas Terakhir",
    "Aksi",
  ];

  // Masukkan "Unit" ke index ke-3 jika filteredFeature bernilai true
  if (filteredFeature("Lihat Kolom Unit_Pengaturan Pengguna_Peternak")) {
    TABLE_HEAD.splice(4, 0, "Unit");
  }

  // Masukkan "Status kemitraan" ke index ke-4 jika filteredFeature bernilai true
  if (filteredFeature("Lihat Status Kemitraan_Pengaturan Pengguna_Peternak")) {
    TABLE_HEAD.splice(5, 0, "Status kemitraan");
  }

  useEffect(() => {
    setReloadFilter(false);
  }, [reloadFilter]);

  const handleOpenDeleteDialog = (userId) => {
    setSelectedDeleteUserId(userId);
    setDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setSelectedDeleteUserId(null);
    setDeleteDialogOpen(false);
  };

  const handleConfirmDelete = async () => {
    try {
      await api.delete(`/v1/farmers/${selectedDeleteUserId}`);
      handleCloseDeleteDialog();

      if (reloadData()) {
        reloadData();
      }
      setDeleteSuccess(true);
      setTimeout(() => setDeleteSuccess(false), 2000);
    } catch (error) {
      Sentry.captureException(error);
      setDeleteError(true);
      setTimeout(() => setDeleteError(false), 2000);
    }
  };

  const handleOpenEditModal = (userId) => {
    setSelectedEditUserId(userId);
    setEditModalOpen(true);
  };

  const handleCloseEditModal = () => {
    setSelectedEditUserId(null);
    setEditModalOpen(false);
  };

  const handleAddClick = () => {
    setAddModalOpen(true);
  };

  const handleAddModalClose = () => {
    setAddModalOpen(false);
  };

  const handleFilterRegion = (val) => {
    setSelectedRegion(val.id);
  };

  const handleFilterUnit = (val) => {
    setSelectedUnit(val.id);
  };

  const reloadData = async () => {
    try {
      const params = {
        itemPerPage: 10,
        search: searchTerm,
        currentPage: currentPage - 1,
        sortBy: sortBy,
        descending: descending,
        // unit: checkUnit,
        // region: checkRegion
      };

      if (implementFilter === true || reloadFilter === true) {
        params.unit = checkedUnitsString ? checkedUnitsString : "";
        params.region = checkedRegionString ? checkedRegionString : "";
      }

      const response = await api.get("/v1/farmers", { params });
      setUsers(response.data.contents);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  useEffect(() => {
    setIsSearchProcess(true);
    const fetchData = async () => {
      try {
        setLoading(true);
        const params = {
          itemPerPage: 10,
          search: searchTerm,
          currentPage: currentPage - 1,
          sortBy: sortBy,
          descending: descending,
        };

        if (implementFilter === true || reloadFilter === true) {
          console.log("check", checkedRegionString);
          params.unit = checkedUnitsString ? checkedUnitsString : "";
          params.region = checkedRegionString ? checkedRegionString : "";
        }

        if (selectedStatus !== null) {
          params.mitra = selectedStatus;
        }

        const response = await api.get("/v1/farmers", { params });
        setUsers(response.data.contents);
        setTotalPages(response.data.totalPages);
        setIsFetchError(false);
      } catch (error) {
        Sentry.captureException(error);
        setIsFetchError(true);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [
    currentPage,
    searchTerm,
    sortBy,
    descending,
    reloadFilter,
    selectedStatus,
    implementFilter,
  ]);

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const response = await api.get("/v1/roles/dropdown", {
          params: {
            search: searchRole,
          },
        });
        setRoles(response.data.contents);
      } catch (error) {
        console.error("Error fetching roles:", error);
      }
    };

    const fetchRegions = async () => {
      try {
        const response = await api.get("/v1/regions/dropdown", {
          params: {
            search: searchRegions,
          },
        });
        setRegions(response.data.contents);
      } catch (error) {
        console.error("Error fetching roles:", error);
      }
    };

    const fetchUsers = async () => {
      try {
        const responseUser = await api.get("/v1/users/me");
        setProfile(responseUser.data);
      } catch (error) {
        console.log("error", error);
      }
    };

    fetchUsers();
    fetchRoles();
    fetchRegions();
  }, [isOpenFilter, searchRole]);

  useEffect(() => {
    const fetchUnits = async () => {
      try {
        const response = await api.get("/v1/units/dropdown", {
          params: {
            search: searchUnit,
            descending: false
          },
        });
        setUnits(response.data.contents);
      } catch (error) {
        console.error("Error fetching roles:", error);
      }
    };

    fetchUnits();
  }, [isOpenFilter, searchUnit]);

  const handleSort = (column) => {
    if (sortableColumns.includes(column)) {
      if (sortBy === column) {
        setDescending(!descending);
      } else {
        setSortBy(column);
        setDescending(false);
      }
    }
  };

  const handleSearchChange = (e) => {
    setIsSearchProcess(!isSearchProcess);
    const keywordSearch = e.target.value;
    if (
      typeof keywordSearch === "string" &&
      keywordSearch.trim() !== "" &&
      keywordSearch.startsWith("08")
    ) {
      let formattedPhone = keywordSearch;

      formattedPhone = formattedPhone.replace(/^0+/, "");
      if (!formattedPhone.startsWith(countryData[selectedCountry].code)) {
        formattedPhone = countryData[selectedCountry].code + formattedPhone;
      }
      setSearchTerm(formattedPhone);
    } else {
      setSearchTerm(keywordSearch);
    }

    setSearchDisplay(keywordSearch);

    setIsSearchProcess(false);
    handlePageChange(1);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleFilterStatus = (val) => {
    setSelectedStatus(val);
    setIsOpenFilterStatus(false);
    handlePageChange(1);
    if (val === true) {
      setLabelStatus("Kemitraan");
    } else if (val === false) {
      setLabelStatus("Non-mitra");
    } else if (val === null) {
      setLabelStatus("Status Kemitraan");
    }
  };

  const statusKemitraan = [
    {
      id: 1,
      IsActive: true,
      labels: ["Kemitraan"],
      labelsPlaceholder: "Kemitraan",
    },
    {
      id: 2,
      IsActive: false,
      labels: ["Non-mitra"],
      labelsPlaceholder: "Non-mitra",
    },
  ];

  const handleResetFilterStatus = () => {
    setSelectedStatus("");
    setLabelStatus("");
    setIsOpenFilterStatus(false);
  };

  return (
    <div className={`flex h-auto overflow-hidden`}>
      <div className="flex flex-col flex-1 overflow-hidden">
        <div className="p-4">
          <Card className="h-full w-full border border-border-secondary shadow-none">
            <CardHeader
              floated={false}
              shadow={false}
              className="p-md border-none"
            >
              <Typography className="text-xl font-bold text-text-primary mb-4">
                {user?.role === "Super Admin"
                  ? "Peternak"
                  : "Pengguna - Peternak Mitra"}
              </Typography>
              <div className="flex justify-between gap-4">
                <div className="text-left flex gap-4 mb-1">
                  <Input
                    type="text"
                    id="search"
                    placeholder="Pencarian.."
                    className="min-w-[350px] max-w-[420px] border border-border-secondary"
                    value={searchDisplay}
                    onChange={handleSearchChange}
                    size="medium"
                    componentLeft={<MagnifyingGlassIcon className="h-5 w-5" />}
                    componentRight={
                      !isSearchProcess && (
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                          className="animate-spin fill-neutral-400 max-w-sm"
                        >
                          <path
                            d="M10.72,19.9a8,8,0,0,1-6.5-9.79A7.77,7.77,0,0,1,10.4,4.16a8,8,0,0,1,9.49,6.52A1.54,1.54,0,0,0,21.38,12h.13a1.37,1.37,0,0,0,1.38-1.54,11,11,0,1,0-12.7,12.39A1.54,1.54,0,0,0,12,21.34h0A1.47,1.47,0,0,0,10.72,19.9Z"
                            className="spinner_6kVp"
                          />
                        </svg>
                      )
                    }
                  />

                  {profile?.level === "UNIT" ? null : (
                    <div>
                      <Popover
                        placement="bottom"
                        open={isOpen}
                        handler={() => setIsOpen(!isOpen)}
                      >
                        <PopoverHandler className="cursor-pointer">
                          <div>
                            <button
                              className={`${
                                countFilter > 0
                                  ? "border-button-infoBorder"
                                  : "border-neutral-300"
                              } p-5 h-11 px-xl py-2.5 justify-center items-center gap-md inline-flex text-base font-medium leading-normal rounded-md bg-white hover:bg-neutral-50 border text-text-secondary focus:shadow-sm focus:shadow-gray-300 hover:border-border-secondary`}
                            >
                              <div
                                className={`justify-center min-w-6 w-full items-center gap-2.5 flex cursor-pointer ${
                                  countFilter > 0
                                    ? "text-fg-infoPrimary"
                                    : "text-text-secondary"
                                }`}
                              >
                                <div className={`w-auto font-medium`}>
                                  Filter
                                </div>
                              </div>
                              <div className="w-6 h-6 relative flex items-center">
                                <FiFilter
                                  className={`h-5 w-5 ${
                                    countFilter > 0 && "text-fg-infoPrimary"
                                  }`}
                                />
                              </div>
                              {countFilter > 0 && (
                                <div className="w-6 h-6 px-3 py-1 bg-fg-infoPrimary rounded-full justify-center items-center gap-1 inline-flex">
                                  <div className="text-white text-xs font-medium leading-[18px]">
                                    {countFilter}
                                  </div>
                                </div>
                              )}
                            </button>
                          </div>
                        </PopoverHandler>

                        <PopoverContent className="top-[210px] left-[500px] z-50 w-[576px]">
                          <Typography className="flex justify-between font-normal text-[11px] text-text-disabled leading-none text-quarterary w-full min-h-full">
                            Filter berdasarkan
                          </Typography>
                          {/* <Typography className="flex justify-between font-medium text-xs text-text-primary leading-[18px] w-full min-h-full mt-6">
                          Role
                        </Typography>

                        <div className="mt-2">
                          <DropdownWithSearchInput 
                            placeholder="Pilih role"
                            placeholderInputSearch="Cari Role"
                            options={roles}
                            search={searchRole}
                            setSearch={setSearchRole}
                            onSelect={handleFilterRole}
                            onSelectString={setCheckedRolesString}
                            lastLabel={lastLabelRole}
                            setLastLabel={setLastLabelRole}
                            positionClassName="top-[335px] left-[520px]"
                            selectCheckBox={checkedRoles}
                            setSelectCheckbox={setCheckedRoles}
                          />
                        </div> */}

                          {profile?.level === "REGION" ? null : (
                            <>
                              <Typography className="flex justify-between font-medium text-xs text-text-primary leading-[18px] w-full min-h-full mt-6">
                                Region
                              </Typography>
                              <div className="mt-2">
                                <DropdownWithSearchInput
                                  placeholder="Pilih region"
                                  placeholderInputSearch="Cari region"
                                  positionClassName="top-[335px] left-[520px]"
                                  options={regions}
                                  onSelect={handleFilterRegion}
                                  search={searchRegions}
                                  setSearch={setSearchRegions}
                                  onSelectString={setCheckedRegionsString}
                                  lastLabel={lastLabelRegion}
                                  setLastLabel={setLastLabelRegion}
                                  selectCheckBox={checkedRegions}
                                  setSelectCheckbox={setCheckedRegions}
                                />
                              </div>
                            </>
                          )}

                          <Typography className="flex justify-between font-medium text-xs text-text-primary leading-[18px] w-full min-h-full mt-6">
                            Unit
                          </Typography>

                          <div className="mt-2">
                            <DropdownWithSearchInput
                              placeholder="Pilih unit"
                              placeholderInputSearch="Cari unit"
                              onSelect={handleFilterUnit}
                              options={units}
                              search={searchUnit}
                              setSearch={setSearchUnit}
                              positionClassName="top-[335px] left-[520px]"
                              selectCheckBox={checkUnit}
                              onSelectString={setCheckedUnitsString}
                              setSelectCheckbox={setCheckUnit}
                              lastLabel={lastLabelUnit}
                              setLastLabel={setLastLabelUnit}
                            />
                          </div>

                          <div className="border-t border-gray-300 my-4" />

                          <div className="divide-y flex w-full gap-8 justify-between mt-4">
                            <div onClick={handleFilterReset}>
                              <Typography className="flex justify-between items-center text-sm text-text-primary font-medium leading-tight w-full min-h-full cursor-pointer">
                                Atur Ulang
                              </Typography>
                            </div>

                            <Button
                              size={"small"}
                              label="Terapkan"
                              onClick={handleFilterTerapkan}
                              className="cursor-pointer"
                            />
                          </div>
                        </PopoverContent>
                      </Popover>
                    </div>
                  )}
                </div>

                {profile?.level === "MITRA" ? null : (
                  <>
                    <div>
                      {filteredFeature(
                        "Filter Status Kemitraan_Pengaturan Pengguna_Peternak"
                      ) && (
                        <DropdownFilterStatus
                          data={statusKemitraan}
                          handleSelect={handleFilterStatus}
                          popoverContentPosition={"top-[240px] left-[460px]"}
                          label={labelStatus}
                          selectedStatus={selectedStatus}
                          onReset={handleResetFilterStatus}
                          isOpenFilter={isOpenFilterStatus}
                          setIsOpenFilter={setIsOpenFilterStatus}
                          placeholder={placeholderStatus}
                        />
                      )}
                    </div>
                  </>
                )}
                <div></div>
                <div className="text-center flex justify-end">
                  <Button
                    label="Tambah peternak"
                    size="medium"
                    type="btnPrimary"
                    onClick={handleAddClick}
                    componentRight={<PlusIcon className="h-5 w-5" />}
                  />
                </div>
              </div>
            </CardHeader>

            {loading ? (
              <div className="flex justify-center items-center h-screen">
                <LoadingAnimation />
              </div>
            ) : (
              <>
                {users?.length === 0 || isFetchError ? (
                  <div className=" flex justify-center items-center h-screen mr-3">
                    <div>
                      <div className="flex justify-center">
                        <EmptyIcon className="ml-[30px]" />
                      </div>
                      <Typography className="text-xl text-text-secondary font-semibold p-4 text-center">
                        Data tidak ditemukan
                      </Typography>

                      <Typography className="flex justify-between text-text-quarterary font-normal leading-none opacity-70 w-full min-h-full">
                        Data yang Anda cari tidak ditemukan. Coba kata kunci
                        lain
                      </Typography>
                    </div>
                  </div>
                ) : (
                  <>
                    <CardBody className="p-0 overflow-x-scroll scrollbar-hide">
                      <table className="w-full min-w-max table-auto text-left">
                        <thead>
                          <tr>
                            {TABLE_HEAD.map((head, index) => (
                              <th
                                key={head}
                                className={`border-y border-border-secondary bg-neutral-50 py-2 px-lg  ${
                                  index === 7
                                    ? "sticky right-0 ml-4 z-10 p-0 shadow-[rgba(0,0,10,0.2)_-3px_0_12px_-5px] border-l"
                                    : ""
                                }`}
                              >
                                <Typography
                                  className="flex text-sm justify-between font-semibold text-text-quarterary leading-none"
                                >
                                  {profile?.level === "MITRA" && index === 5
                                    ? null
                                    : head}
                                  {profile?.level === "MITRA" ? (
                                    <>
                                      {index !== 0 &&
                                        index !== 5 &&
                                        index !== TABLE_HEAD.length - 1 && (
                                          <button
                                            onClick={() =>
                                              handleSort(sortableColumns[index])
                                            }
                                            className="focus:outline-none ml-1"
                                          >
                                            <ChevronUpDownIcon
                                              strokeWidth={2}
                                              className={`h-4 w-4 ${
                                                descending
                                                  ? "transform rotate-180"
                                                  : ""
                                              }`}
                                            />
                                          </button>
                                        )}
                                    </>
                                  ) : (
                                    <>
                                      {index !== 0 &&
                                        index !== TABLE_HEAD.length - 1 && (
                                          <button
                                            onClick={() =>
                                              handleSort(sortableColumns[index])
                                            }
                                            className="focus:outline-none ml-1"
                                          >
                                            <ChevronUpDownIcon
                                              strokeWidth={2}
                                              className={`h-4 w-4 ${
                                                descending
                                                  ? "transform rotate-180"
                                                  : ""
                                              }`}
                                            />
                                          </button>
                                        )}
                                    </>
                                  )}
                                </Typography>
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {users.map((user, index) => {
                            const isLast = index === users.length - 1;
                            const classes = isLast
                              ? "p-lg"
                              : "p-lg border-b border-border-secondary";

                            return (
                              <tr key={user.id}>
                                <td className={classes}>
                                  <div className="flex items-center gap-3">
                                    <Typography className="text-text-primary text-sm font-medium leading-tight">
                                      {(currentPage - 1) * 10 + index + 1}
                                    </Typography>
                                  </div>
                                </td>
                                <td className={`${classes} max-w-[250px]`}>
                                  <Typography className="text-text-primary text-sm font-medium leading-tight">
                                    {user.fullName}
                                  </Typography>
                                </td>
                                <td className={`${classes} max-w-[250px]`}>
                                  <Typography className="text-text-primary text-sm font-medium leading-tight">
                                    {user?.code || "-"}
                                  </Typography>
                                </td>
                                <td className={classes}>
                                  <Typography className="text-text-primary text-sm font-medium leading-tight">
                                    {user.phoneNumber || "-"}
                                  </Typography>
                                </td>
                                {filteredFeature(
                                  "Lihat Kolom Unit_Pengaturan Pengguna_Peternak"
                                ) && (
                                  <td className={classes}>
                                    <Typography className="text-text-primary text-sm font-medium leading-tight">
                                      {user.unit?.labels || "-"}
                                    </Typography>
                                  </td>
                                )}
                                {filteredFeature(
                                  "Lihat Status Kemitraan_Pengaturan Pengguna_Peternak"
                                ) && (
                                  <>
                                    <td className={`${classes} max-w-[195px]`}>
                                      {profile?.level === "MITRA" ? null : (
                                        <div className="flex justify-center">
                                          <Chip
                                            size="sm"
                                            variant="ghost"
                                            value={
                                              user.tag === "MITRA" ||
                                              user.tag === "MITRA_PASSIVE"
                                                ? "Kemitraan"
                                                : "Non-mitra"
                                            }
                                            className={`w-[85px] text-sm font-inter font-normal leading-[18px] border normal-case ${
                                              user.tag === "MITRA" ||
                                              user.tag === "MITRA_PASSIVE"
                                                ? "text-[#C49801] border-[#FBE497] bg-yellow-50"
                                                : "text-blue-600 border-[#B2DDFF] bg-[#F4F9FF]"
                                            }`}
                                          />
                                        </div>
                                      )}
                                    </td>
                                  </>
                                )}
                                <td className={classes}>
                                  <Typography className="text-text-primary text-sm font-medium leading-tight">
                                    {user?.lastSeen
                                      ? moment(user?.lastSeen).format(
                                          "DD-MM-YYYY HH:mm:ss"
                                        )
                                      : "-"}
                                  </Typography>
                                </td>
                                <td
                                  className={`border-l border-b border-border-secondary bg-white sticky right-0 p-4 shadow-[rgba(0,0,10,0.2)_-3px_1px_12px_-5px]`}
                                >
                                  <div className="flex gap-2 w-max">
                                    <ButtonIcon
                                      className="z-25"
                                      onClick={() =>
                                        handleOpenEditModal(user.id)
                                      }
                                      icon="edit"
                                      type="outlineWarning"
                                      size="small"
                                    />
                                    <ButtonIcon
                                      className="z-25"
                                      onClick={() =>
                                        handleOpenDeleteDialog(user.id)
                                      }
                                      icon="delete"
                                      type="outlineError"
                                      size="small"
                                    />
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </CardBody>
                    <CardFooter className="p-sm items-center border-t border-blue-gray-50">
                      <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={handlePageChange}
                      />
                    </CardFooter>
                  </>
                )}
              </>
            )}
          </Card>
        </div>
      </div>
      <AddPeternakModal
        isOpen={isAddModalOpen}
        onClose={handleAddModalClose}
        onReloadData={reloadData}
      />
      <EditPeternakModal
        isOpen={isEditModalOpen}
        onClose={handleCloseEditModal}
        userId={selectedEditUserId}
        onReloadData={reloadData}
        setSelectedEditUserId={setSelectedEditUserId}
      />

      <ConfirmDialog
        isOpen={isDeleteDialogOpen}
        onClose={handleCloseDeleteDialog}
        onConfirm={handleConfirmDelete}
        title="Hapus peternak"
        content="Anda yakin ingin melanjutkan tindakan ini?"
        btnLabel="Hapus"
        btnType="btnError"
      />

      {isDeleteSuccess && (
        <AlertNotification
          icon={<FaCheckCircle />}
          color="green"
          message="Data peternak berhasil dihapus."
        />
      )}

      {isDeleteError && (
        <AlertNotification
          icon={<FaCheckCircle />}
          color="red"
          message="Terjadi kesalahan saat menghapus peternak."
        />
      )}
    </div>
  );
};

export default Peternak;
