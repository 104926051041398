import React from "react";

import { ChevronUpDownIcon } from "@heroicons/react/24/outline";
import { Typography } from "@material-tailwind/react";

import { cn } from "../../shared/utils";

const TableHeaderItem = (props) => {
  const {
    title,
    handleSort,
    value,
    isDescending,
    disableSorting = false,
    customClass,
  } = props;

  const handleSortClick = () => {
    if (handleSort) {
      handleSort(value);
    }
  };

  return (
    <div
      className={cn(
        "flex gap-4",
        disableSorting ? "justify-start" : "justify-between",
        customClass,
      )}
    >
      <Typography className="flex justify-start text-sm font-semibold leading-tight text-text-primary">
        {title}
      </Typography>
      {!disableSorting && (
        <button onClick={handleSortClick}>
          <ChevronUpDownIcon
            strokeWidth={2}
            className={`h-4 w-4 ${isDescending ? "rotate-180 transform" : ""}`}
          />
        </button>
      )}
    </div>
  );
};

export default TableHeaderItem;
