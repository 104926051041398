import React from "react";

import { cn } from "../../shared/utils";

const Label = React.forwardRef(
  ({ className, isError, isRequire, children, ...props }, ref) => (
    <label
      ref={ref}
      className={cn(
        "text-sm font-medium text-text-secondary",
        className,
        isError && "text-fg-errorPrimary",
      )}
      {...props}
    >
      {children} {isRequire && <span className="text-fg-errorPrimary">*</span>}
    </label>
  ),
);

Label.displayName = "Label";

export { Label };
