import { useEffect, useState } from "react";
import api from "../api/configApi";
import Cookies from "js-cookie";

const useFetchImage = (url) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const token = Cookies.get("accessToken");

  useEffect(() => {
    const fetchdata = async () => {
      try {
        if (url.length > 0) {
          const response = await api.get(`${url}/url`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          setData(response?.data);
        }
      } catch (err) {
        setError(err);
      }
    };

    fetchdata();
  }, [url, token]);

  return { data, error };
};

export default useFetchImage;
