import {
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable,
} from "@tanstack/react-table";
import PropTypes from "prop-types";

import { cn } from "../../shared/utils";
import { getColumnPinningState, validatePinningConfig } from "./config";
import TableColumn from "./TableColumn";
import TableData from "./TableData";
import TableFooter from "./TableFooter";

const Table = (props) => {
  const {
    data,
    columns,
    pinningConfigs = [],
    hideFooter,
    isRoundedBottom = true,
    className,
  } = props;

  // Validate and process pinning configurations
  const validPinningConfigs = validatePinningConfig(
    pinningConfigs.map((config) => ({
      column: config.column,
      direction: config.direction,
    })),
  );

  // Configure columns with pinning
  const configuredColumns = columns.map((col) => {
    const pinConfig = validPinningConfigs.find(
      (config) => config.column === col.accessorKey,
    );
    return {
      ...col,
      ...(pinConfig && { pin: pinConfig.direction }),
    };
  });

  const table = useReactTable({
    data,
    columns: configuredColumns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    enableColumnPinning: true,
    initialState: {
      columnPinning: getColumnPinningState(validPinningConfigs),
    },
  });

  return (
    <div
      className={cn(
        "overflow-y-auto border scrollbar-hide",
        isRoundedBottom && "rounded-b-lg",
        className,
      )}
    >
      <table className="w-full min-w-max table-auto border-separate border-spacing-0 text-left">
        <TableColumn headerGroups={table.getHeaderGroups()} />
        <TableData rows={table.getRowModel().rows} />
        {!hideFooter && <TableFooter footerGroups={table.getFooterGroups()} />}
      </table>
    </div>
  );
};

Table.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  columns: PropTypes.any,
  pinningConfigs: PropTypes.arrayOf(
    PropTypes.shape({
      column: PropTypes.string.isRequired,
      direction: PropTypes.oneOf(["left", "right"]).isRequired,
    }),
  ),
  hideFooter: PropTypes.bool,
};

export default Table;
