import { useQuery } from "@tanstack/react-query";

import api from "../../api/configApi";

const useGetUnitDetail = (id) => {
  const query = useQuery({
    queryKey: ["get-unit-detail", { id }],
    queryFn: async () => {
      const response = await api.get(`/v1/units/${id}`);
      return response.data;
    },
    enabled: !!id,
  });

  return query;
};

export default useGetUnitDetail;
