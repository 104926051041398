import { useQuery } from "@tanstack/react-query";

import api from "../../api/configApi";

const useGetHousesList = ({
  search = "",
  unit = "",
  sortBy = "",
  descending = "",
}) => {
  const query = useQuery({
    queryKey: ["get-houses-list", { search, sortBy, descending, unit }],
    queryFn: async () => {
      const response = await api.get("/v1/houses/list", {
        params: {
          search,
          unit,
          sortBy,
          descending,
        },
      });
      return response.data;
    },
  });

  return query;
};

export default useGetHousesList;
