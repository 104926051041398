import React, { useEffect, useState } from "react";

import { Card, Typography } from "@material-tailwind/react";

import { Logo } from "../../atoms/Logo";
import { Button } from "../../atoms/Button";
import Input from "../../atoms/Input";
import { FaCheckCircle, FaEye, FaEyeSlash } from "react-icons/fa";
import PasswordStrengthIndicator from "../../atoms/PasswordStrengthIndicator";
import PasswordStrengthBar from "../../atoms/PasswordStrengthBar";
import PasswordStrengthList from "../../atoms/PasswordStrengthList";
import api from '../../api/configApiAuth'
import { useLocation, useNavigate} from "react-router-dom";
import AlertNotification from "../../atoms/AlertNotification";
import * as Sentry from '@sentry/react'

const CreateNewPasswordPage = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [isPasswordEmpty, setIsPasswordEmpty] = useState(false); // State untuk menangani password kosong
  const [isConfirmPasswordEmpty, setIsConfirmPasswordEmpty] = useState(false); // State untuk menangani confirm password kosong

  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [textPasswordStrength, setTextPasswordStrength] = useState("");

  const [isLengthValid, setIsLengthValid] = useState(false);
  const [isUpperCaseValid, setIsUpperCaseValid] = useState(false);
  const [isLowerCaseValid, setIsLowerCaseValid] = useState(false);
  const [isNumberValid, setIsNumberValid] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const navigate = useNavigate()
  const paramsSession = queryParams.get('session');
  const parasMail = queryParams.get('mail')
  const [passwordSuccess, setPasswordSuccess] = useState(false)
  const [passwordFailed, setPasswordFailed] = useState(false)

  // const isSubmitDisabled = !password || !confirmPassword;
  const isDisabledBystrength = !isLengthValid || !isUpperCaseValid || !isLowerCaseValid || !isNumberValid
  // console.log("disable by streng", isDisabledBystrength, isSaveDisabled, isSubmitting);

  const handlePasswordBlur = () => {
    // Validasi saat fokus keluar dari field password
    if (password.trim() === "") {
      setIsPasswordEmpty(true);
    } else {
      setIsPasswordEmpty(false);
    }
  };

  const handleConfirmPasswordBlur = () => {
    // Validasi saat fokus keluar dari field password
    if (confirmPassword.trim() === "") {
      setIsConfirmPasswordEmpty(true);
    } else {
      setIsConfirmPasswordEmpty(false);
    }
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    checkPasswordStrength(newPassword);
    checkSaveButtonStatus(newPassword, confirmPassword);
  };

  const handleConfirmPasswordChange = (e) => {
    const newPassword = e.target.value;
    setConfirmPassword(newPassword);
    checkSaveButtonStatus(password, newPassword);
  };

  const checkPasswordStrength = (newPassword) => {
    const passwordLength = newPassword.length;
    const hasUpperCase = /[A-Z]/.test(newPassword);
    const hasLowerCase = /[a-z]/.test(newPassword);
    const hasNumber = /\d/.test(newPassword);

    setIsLengthValid(passwordLength >= 8);
    setIsUpperCaseValid(hasUpperCase);
    setIsLowerCaseValid(hasLowerCase);
    setIsNumberValid(hasNumber);

    if (passwordLength >= 8 && hasUpperCase && hasLowerCase && hasNumber) {
      setPasswordStrength(3);
      setTextPasswordStrength("strong");
    } else if (
      passwordLength >= 8 &&
      (hasUpperCase || hasLowerCase || hasNumber)
    ) {
      setPasswordStrength(2);
      setTextPasswordStrength("medium");
    } else {
      setPasswordStrength(1);
      setTextPasswordStrength("weak");
    }
  };

  const checkSaveButtonStatus = (newPassword, confirmPassword) => {
    setIsSaveDisabled(!(newPassword && confirmPassword));
  };

  const handleSavePassword = async () => {
    // Logika penyimpanan password baru
    const reqBody = {
      email: parasMail,
      password: password,
      session: paramsSession
    }

    setIsSubmitting(true);
    if (password === confirmPassword) {
      try {
        api.post('v1/auth/forgotPassword',reqBody )
        .then((response) => {
          // setResponseSuccess(response.status)
          localStorage.setItem("success", JSON.stringify(response.status))
          return response
        })
        // alert("Password berhasil tersimpan");
        setPasswordSuccess(true)
        navigate('/')
      } catch (error) {
        // console.log(error);
        Sentry.captureException(error)
      }
      // setIsSubmitting(false);
    } else {
      // alert("Password dan konfirmasi password tidak sama");
      setPasswordFailed(true)
    }
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const toggleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  useEffect(() => {
    if (passwordSuccess) {
      setTimeout(() => setPasswordSuccess(false), 4000)
      setIsSubmitting(true)
    }

    if (passwordFailed) {
      setTimeout(() => setPasswordFailed(false), 4000)
      setIsSubmitting(false)
    }
  }, [passwordSuccess, passwordFailed])

  return (
    <div className="flex flex-col md:flex-row h-screen">
      <div className="w-full bg-white md:p-10 p-5 flex flex-col justify-between">
        <Logo />

        <div className="flex justify-center items-center md:min-h-[700px] min-h-[530px]">
          <Card className="w-[484px] max-w-[484px] md:min-w-sm shadow-none md:shadow-md md:border-t-2 md:border-gray-100 rounded-xl md:p-4xl p-xs">
            <Typography
              type="h2"
              className="text-xl font-bold text-neutral-900 mb-xs"
            >
              Buat Kata Sandi Baru
            </Typography>
            <div className="mt-4xl">
              <div className="mb-md">
                <label
                  htmlFor="password"
                  className="text-sm font-medium text-neutral-900"
                >
                  Kata Sandi Baru
                </label>
              </div>

              <div className="relative">
                <Input
                  type={showPassword ? "text" : "password"}
                  id="password"
                  placeholder="Masukkan kata sandi"
                  className={`${isPasswordEmpty && "border-red-500"}`}
                  value={password}
                  onChange={handlePasswordChange}
                  onBlur={handlePasswordBlur}
                  size="medium"
                  componentRight={
                    <button onClick={toggleShowPassword}>
                      {showPassword ? (
                        <FaEyeSlash className="h-5 w-5 text-neutral-400" />
                      ) : (
                        <FaEye className="h-5 w-5 text-neutral-400" />
                      )}
                    </button>
                  }
                />
              </div>
              {isPasswordEmpty && password.trim() === "" && (
                <p className="text-red-500 text-sm mt-1">
                  Password tidak boleh kosong
                </p>
              )}

              {password && (
                <div className="mt-2xl">
                  {/* Strength Bar */}
                  <PasswordStrengthBar
                    passwordStrength={passwordStrength}
                    width="w-[65px]"
                  />
                  <PasswordStrengthIndicator strength={textPasswordStrength} />
                </div>
              )}
            </div>

            <div className="mt-3xl">
              <div className="mb-md">
                <label
                  htmlFor="password"
                  className="text-sm font-medium text-neutral-900"
                >
                  Konfirmasi Kata Sandi Baru
                </label>
              </div>
              <div className="relative">
                <Input
                  type={showConfirmPassword ? "text" : "password"}
                  id="password"
                  placeholder="Masukkan kata sandi"
                  value={confirmPassword}
                  className={`${isConfirmPasswordEmpty && "border-red-500"}`}
                  onChange={handleConfirmPasswordChange}
                  onBlur={handleConfirmPasswordBlur}
                  size="medium"
                  componentRight={
                    <button onClick={toggleShowConfirmPassword}>
                      {showConfirmPassword ? (
                        <FaEyeSlash className="h-5 w-5 text-neutral-400" />
                      ) : (
                        <FaEye className="h-5 w-5 text-neutral-400" />
                      )}
                    </button>
                  }
                />
              </div>
              {isConfirmPasswordEmpty && confirmPassword.trim() === "" && (
                <p className="text-red-500 text-sm mt-1">
                  Konfirmasi password tidak boleh kosong
                </p>
              )}
            </div>
            <div className="mb-2xl"></div>
            <PasswordStrengthList
              isLengthValid={isLengthValid}
              isUpperCaseValid={isUpperCaseValid}
              isLowerCaseValid={isLowerCaseValid}
              isNumberValid={isNumberValid}
            />
            <Button
              label="Simpan"
              size="medium"
              type={`${
                isSaveDisabled || isSubmitting || isDisabledBystrength
                  ? "btnPrimaryDisabled"
                  : "btnPrimary"
              }`}
              onClick={handleSavePassword}
              componentLeft={
                isSubmitting && (
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    className="animate-spin fill-neutral-400"
                  >
                    <path
                      d="M10.72,19.9a8,8,0,0,1-6.5-9.79A7.77,7.77,0,0,1,10.4,4.16a8,8,0,0,1,9.49,6.52A1.54,1.54,0,0,0,21.38,12h.13a1.37,1.37,0,0,0,1.38-1.54,11,11,0,1,0-12.7,12.39A1.54,1.54,0,0,0,12,21.34h0A1.47,1.47,0,0,0,10.72,19.9Z"
                      className="spinner_6kVp"
                    />
                  </svg>
                )
              }
              disabled={isSaveDisabled || isDisabledBystrength}
            />
          </Card>
        </div>

        <h2 className="text-gray-600 h-auto text-sm md:text-left text-center mt-4">
          © 2023 PT Integrasi Teknologi Unggas. All right reserved
        </h2>
      </div>

      {passwordSuccess && (
        <AlertNotification
          icon={<FaCheckCircle/>}
          color="green"
          message="Password Berhasil Tersimpan"
        />
      )}

      {passwordFailed && (
        <AlertNotification
          icon={<FaCheckCircle/>}
          color="red"
          message="Password dan Konfirmasi Password tidak sama"
        />
      )}
    </div>
  );
};

export default CreateNewPasswordPage;
