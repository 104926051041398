import React, { useState, useRef } from "react";
import { FaWindowClose } from "react-icons/fa";
import {
  ArrowUpTrayIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/outline";
import AlertModal from "../atoms/AlertModal";

const FileUpload = ({ onFileUpload, firstPreviewURL, setFirstPreviewURL }) => {
  const [dragging, setDragging] = useState(false);
  const [previewURL, setPreviewURL] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isWarningValidateFileOpen, setWarningValidateFileOpen] =
    useState(false);
  const inputRef = useRef(null); // Referensi ke elemen input

  // useEffect(() => {
  //   // Mengambil gambar dari URL dan membuat blob URL
  //   if (firstPreviewURL) {
  //     fetch(firstPreviewURL)
  //       .then((response) => response.blob())
  //       .then((blob) => {
  //         const blobURL = URL.createObjectURL(blob);
  //         setPreviewURL(blobURL);
  //       })
  //       .catch((error) => console.error("Gagal mengambil gambar:", error));
  //   }
  // }, [firstPreviewURL]);

  const handleDragOver = (e) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setDragging(false);
    const files = e.dataTransfer.files;
    validateAndUpload(files);
  };

  const handleFileInputChange = (e) => {
    const files = e.target.files;
    // console.log(files);
    validateAndUpload(files);
  };

  const handleCloseWarningValidateFile = () => {
    setWarningValidateFileOpen(false);
    // Reset nilai input tipe file
    inputRef.current.value = "";
  };

  const validateAndUpload = (files) => {
    // console.log("test ada");
    const allowedFormats = ["image/jpeg", "image/jpg", "image/png"];
    const maxSizeInBytes = 2 * 1024 * 1024; // 2 MB

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (!allowedFormats.includes(file.type)) {
        setWarningValidateFileOpen(true);
        onFileUpload(null);
        return console.log("not allowed format");
      }
      if (file.size > maxSizeInBytes) {
        setWarningValidateFileOpen(true);
        onFileUpload(null);
        return console.log("invalid max size");
      }
    }

    // Membuat pratinjau gambar untuk file pertama yang diunggah
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewURL(reader.result);
    };
    reader.readAsDataURL(files[0]);

    onFileUpload(files);

    // const formData = new FormData();
    // formData.append("file", files[0]);

    // Simulasi waktu unggahan dengan setTimeout
    setTimeout(() => {
      setUploadProgress(100); // Set progress bar ke 100% setelah selesai unggah
      onFileUpload(files);
    }, 5000); // Simulasi waktu unggah selama 5 detik
  };

  const handleCancelUpload = () => {
    setPreviewURL(null);
    if (setFirstPreviewURL) {
      setFirstPreviewURL(null);
    }
  };

  return (
    <div>
      {previewURL || firstPreviewURL ? (
        <div className="relative mt-4 mx-auto max-w-full h-auto">
          <img
            src={firstPreviewURL ? firstPreviewURL : previewURL}
            alt="Preview"
            className="max-w-full h-auto"
          />
          <button
            className="absolute top-0 right-0 p-1 bg-none rounded-full shadow-none"
            onClick={handleCancelUpload}
          >
            <FaWindowClose className="h-7 w-7 hover:text-gray-800" />
          </button>
        </div>
      ) : (
        <div>
          <div
            className={`border border-dashed bg-neutral-50 rounded-md p-8 text-center grid grid-cols-1 ${
              dragging ? "border-blue-500" : "border-gray-300"
            }`}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
          >
            <div className="rows-span-1">
              {" "}
              <label
                htmlFor="fileInput"
                className="block cursor-pointer mb-2 text-gray-600 hover:text-gray-800"
              >
                <span className="text-blue-500 flex justify-center items-center text-md font-normal">
                  <ArrowUpTrayIcon className="h-6 w-6 mx-2" /> Pilih File
                </span>
              </label>
              <label
                htmlFor="fileInput"
                className="block cursor-pointer font-semibold text-sm mb-2 text-gray-700 hover:text-gray-800"
              >
                Atau tarik untuk unggah KTP{" "}
              </label>
              <input
                ref={inputRef}
                type="file"
                id="fileInput"
                className="hidden"
                onChange={handleFileInputChange}
              />
            </div>
            <span className="rows-span-1 text-xs pt-3">
              Unggah foto dalam format <b>JPG</b> , <b>JPEG</b> , atau{" "}
              <b>PNG</b>
            </span>
            <span className="rows-span-1 text-xs">
              dengan ukuran maksimal <b>2 MB</b>{" "}
            </span>

            {uploadProgress > 0 && uploadProgress < 100 && (
              <div className="mt-2 w-full bg-gray-200 rounded-full">
                <div
                  className="bg-blue-500 h-2 rounded-full"
                  style={{ width: `${uploadProgress}%` }}
                ></div>
              </div>
            )}
          </div>
        </div>
      )}

      <AlertModal
        isOpen={isWarningValidateFileOpen}
        onClose={handleCloseWarningValidateFile}
        onConfirm={handleCloseWarningValidateFile}
        title="Gagal mengunggah"
        content="Hanya format file JPG, JPEG, atau PNG yang diizinkan dengan ukuran maksimal 2 MB."
        btnLabel="Unggah ulang"
        btnType="btnError"
        icon={
          <ExclamationTriangleIcon className="h-[58px] w-[58px] m-xl p-lg text-red-500 bg-red-100 rounded-full" />
        }
      />
    </div>
  );
};

export default FileUpload;
