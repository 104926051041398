import { Avatar, Card, CardBody, Typography } from "@material-tailwind/react";
import moment from "moment";
import "moment/locale/id";
import { ReactComponent as EmptyIcon } from "../../assets/icons/emptyIconn.svg";
import DefaultTableDoc from "../../img/DOC_image.png";
const TABLE_HEAD = [
  "Produk",
  "Tanggal masuk",
  "QTY In",
  "Harga Satuan",
  "Total harga",
  "",
];

const TableDoc = ({ dataTable }) => {
  return (
    <>
      <Card className="h-full w-full shadow-none">
        {dataTable?.contents?.length === 0 ? (
          <div className=" flex justify-center items-center h-screen">
            <div>
              <div className="flex justify-center">
                <EmptyIcon />
              </div>
              <Typography
                variant="h2"
                color="blue-gray"
                className="text-xl font-semibold p-4 text-center"
              >
                Data tidak ditemukan
              </Typography>

              <Typography
                variant="medium"
                color="blue-gray"
                className="flex justify-between font-normal leading-none opacity-70 w-full min-h-full bg-gray-950"
              >
                Data yang Anda cari tidak ditemukan. Coba kata kunci lain
              </Typography>
            </div>
          </div>
        ) : (
          <>
            <CardBody className="overflow-scroll p-0">
              <table className="mt-lg w-full min-x-max table-auto text-left">
                <thead>
                  <tr>
                    {TABLE_HEAD?.map((head, index) => (
                      <th
                        key={head}
                        className={`border-y border-border-secondary bg-neutral-25 p-lg`}
                      >
                        <Typography
                          className={`flex items-center leading-none h-[21px] font-bold text-xs text-text-tertiary ${
                            index === 0 || index === 1
                              ? "justify-start"
                              : "justify-end"
                          }`}
                        >
                          {head}
                        </Typography>
                      </th>
                    ))}
                  </tr>
                </thead>

                <tbody>
                  <tr key={dataTable?.id}>
                    <td className="p-lg pl-0">
                      <div className="flex items-center gap-3">
                        <div className="flex justify-around gap-2">
                          <div className="pl-3xl">
                            {dataTable?.image === undefined ? (
                              <Avatar
                                src={DefaultTableDoc}
                                alt="product image"
                                variant="rounded"
                              />
                            ) : (
                              dataTable?.image
                            )}
                          </div>

                          <div className="text-left grid grid-rows-2 items-center">
                            <Typography className="text-text-primary text-sm font-normal leading-tight">
                              {dataTable?.doc?.labels}
                            </Typography>

                            <Typography className="text-text-tertiary text-xs font-normal">
                              {dataTable?.doc?.strain?.labels}
                            </Typography>
                          </div>
                        </div>
                      </div>
                    </td>

                    <td className="p-lg">
                      <Typography className="text-text-primary text-sm font-normal leading-tight">
                        {moment(dataTable?.docInDate)
                          .locale("id")
                          .format("D MMMM YYYY")}
                      </Typography>
                    </td>

                    <td className="p-lg text-right">
                      <Typography className="text-text-primary text-sm font-normal leading-tight">
                        {dataTable?.docInPopulation?.toLocaleString("id-ID")}
                      </Typography>
                    </td>

                    <td className="p-lg text-right">
                      <Typography className="text-text-primary text-sm font-normal leading-tight">
                        Rp {dataTable?.pricePerChick.toLocaleString("id-ID")}
                      </Typography>
                    </td>

                    <td className="p-lg text-right">
                      <Typography className="text-text-primary text-sm font-normal leading-tight">
                        Rp{" "}
                        {parseFloat(dataTable.totalPurchaseDoc).toLocaleString(
                          "id-ID"
                        )}
                      </Typography>
                    </td>
                  </tr>
                </tbody>
              </table>
            </CardBody>
          </>
        )}
      </Card>
    </>
  );
};

export default TableDoc;
