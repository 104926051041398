/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/img-redundant-alt */
import { useEffect, useState } from "react"
import api from "../../../api/configApi";
import { useNavigate, useParams } from "react-router-dom";
import { Card, CardBody, CardHeader, Typography } from "@material-tailwind/react";
import { ReactComponent as BackArrow } from '../../../assets/icons/chevron-left.svg'
import { Button } from "../../../atoms/Button";
import { ReactComponent as CheckList } from '../../../assets/icons/checklist_review.svg'
import { ReactComponent as Calendar } from '../../../assets/icons/calender.svg'
import ToasterAlert from "../../../atoms/Toaster";
import moment from "moment";
import { ReactComponent as Timer } from '../../../assets/icons/timer.svg'
import { ReactComponent as CarBakul } from '../../../assets/icons/car_bakul.svg'
import { ReactComponent as EditPen } from '../../../assets/icons/edit_pen.svg'
import { ReactComponent as Height } from '../../../assets/icons/data_timbang_icon.svg'
import ModalEditPanen from "../../../molecules/tablePanen/ModalEditPanen";
import TableDetailPanen from "../../../molecules/tablePanen/TableDetailPanen";
import useFetchImage from "../../../molecules/tablePanen/HookFetchingPanen";
import SidebarHistory from "../../../molecules/tablePanen/SidebarHistory";
import ConfirmDialog from "../../../atoms/ConfirmDialog";
import ImageDialog from "../../../molecules/tablePanen/ModalDialogImage";
import LoadingAnimation from "../../../atoms/LoadingAnimation";
import * as Sentry from '@sentry/react'

const DetailPanen = () => {
  const [responseDetail, setResponseDetail] = useState(null)
  const { id } = useParams()
  const [openModal, setOpenModal] = useState(false)
  const [openModalPlat, setOpenModalPlat] = useState(false)
  const [openModalDo, setOpenModalDo] = useState(false)
  const [openModalPrice, setOpenModalPrice] = useState(false)
  const [openSidebarBuyyer, setOpenSidebarBuyyer] = useState(false)
  const [openSidebarRegister, setOpenSidebarRegister] = useState(false)
  const [openSidebarDo, setOpenSidebarDo] = useState(false)
  const [openSidebarPrice, setOpenSidebarPrice] = useState(false)
  const [dataBakul, setDataBakul] = useState(null)
  const [registerNumber, setRegisetrNumber] = useState("")
  const [numberDo, setNumberDo] = useState("")
  const [price, setPrice] = useState("")
  const [buyer, setBuyyers] = useState(null)
  const [searchBuyyer, setSearchBuyyer] = useState("")
  const [showNotifUpdate, setShowNotifUpdate] = useState(false)
  const [showNotifRegister, setShowNotifRegister] = useState(false)
  const [showNotidDo, setShowNotifDo] = useState(false)
  const [showNotifPrice, setShowNotfiPrice] = useState(false)
  const [groupingHistory, setGroupingHistory] = useState(null)
  const [modalConfirmation, setModalconfirmation] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [openVehicle, setOpenVehicle] = useState(false)
  const [openDriver, setOpenDriver] = useState(false)
  const [openDataSclaeOperator, setOpenDataScaleOperator] = useState(false)
  const [openPic, setOpenPic] = useState(false)
  const [fetchData, setFetchData] = useState(false)
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  const handleChangeRegisterNumber = (e) => {
    setRegisetrNumber(e.target.value)
  }

  const handleChangeNumberDo = (e) => {
    setNumberDo(e.target.value)
  }

  const handleChangePrice = (e) => {
    setPrice(e.target.value)
  }

  useEffect(() => {
    const refetchDetail = async () => {
      setLoading(true)

      try {
        const data = await api.get(`/v1/harvests/${id}`)
        // console.log("data", data);
        setResponseDetail(data?.data)
        setRegisetrNumber(data?.data?.licenseNumber)
        setNumberDo(data?.data?.doNumber)
        setPrice(data?.data?.pricePerChicken)
        setGroupingHistory(Object.groupBy(data?.data?.history, ({fieldName}) => fieldName))
      } catch (err) {
        // console.log("error", err);
        Sentry.captureException(err);
      } finally {
        setLoading(false)
      }
    }

    if (fetchData) {
      setFetchData(false)
    }

    refetchDetail()
  }, [id, fetchData])

  const handleRefetchData = () => {
    setFetchData(true)
  }

  const labelTimeHarvest = [
    {id: 1, label: "Periode"},
    {id: 2, label: "Mulai timbang"},
    {id: 3, label: "Selesai timbang"}
  ]

  const responeTimeHarvest = [
    {id: 1, value: responseDetail?.rearingPeriod?.labels},
    {id: 2, value: moment(responseDetail?.startTime, "HH:mm").format("HH:mm"), icon: <Timer/>},
    {id: 3, value: moment(responseDetail?.finishTime, "HH:mm").format("HH:mm"), icon: <Timer/>}
  ]

  const cardDatatimbang = [
    {
      id: 1,
      label: "Jumlah Tonase",
      value: `${responseDetail?.totalNumberOfChicken?.toLocaleString('id-ID')} ekor`,
      desc: `Pada ${responseDetail?.rearingPeriod?.labels}`,
      color: '#667085'
    }, 

    {
      id: 2,
      label: "Jumlah tonase",
      value: `${responseDetail?.totalTonnage.toLocaleString('id-ID')} kg`,
      desc: `Dari ${responseDetail?.totalNumberOfChicken?.toLocaleString('id-ID')} ekor`,
      color: '#FFFAEB'
    },

    {
      id: 3,
      label: "Rata-rata BW",
      value: `${responseDetail?.averageBw?.toLocaleString('id-ID')} kg`,
      desc: `Dari ${responseDetail?.totalNumberOfChicken?.toLocaleString('id-ID')} ekor`,
      color: '#FFFAEB'
    },

    {
      id: 4,
      label: "Total harga",
      value: `${responseDetail?.averageBw?.toLocaleString('id-ID')} kg`,
      desc: `Rp ${responseDetail?.pricePerChicken?.toLocaleString('id-ID')} x ${responseDetail?.totalNumberOfChicken?.toLocaleString('id-ID')} ekor`,
      color: '#FFFAEB'
    }
  ]

  const { data: dataVehicle } = useFetchImage(responseDetail?.media?.vehicle)
  const { data: dataDriver } = useFetchImage(responseDetail?.media?.driver)
  const { data: dataScaleOperator} = useFetchImage(responseDetail?.media?.scaleOperator)
  const { data: dataPic} = useFetchImage(responseDetail?.media?.pic)

  useEffect(() => {
    const fetchingListBakul = async () => {
      const dataBakul = await api.get('/v1/bakuls/dropdown')
      setDataBakul(dataBakul.data.contents)
    }

    fetchingListBakul()
  }, [])

  const handleSelectBuyyer = (select) => {
    setBuyyers(select)
  }

  const updateBakul = async () => {
    let paramsBuyyerId = {
      bakul: {id: buyer?.id}
    }

    try {
      const responseChange = await api.patch(`/v1/harvests/${id}`, paramsBuyyerId)
      // console.log("click", responseChange);
      handleRefetchData()
      // window.location.reload()
      setOpenModal(false)
      if (responseChange.status === 200) {
        setShowNotifUpdate(true)
      }

    } catch (err) {
      // console.log("error", err);
      Sentry.captureException(err);
    }
  }

  // 

  const updateRegisterNumber = async () => {
    let paramsLisenceNumber = {
      licenseNumber: registerNumber
    }

    try {
      const responseUpdateRegister = await api.patch(`/v1/harvests/${id}`, paramsLisenceNumber)
      console.log("update response", responseUpdateRegister);
      setOpenModalPlat(false)
      handleRefetchData()
      // window.location.reload()
      if (responseUpdateRegister.status === 200) {
        setShowNotifRegister(true)
      }
    } catch (err) {
      // console.log("error", err);
      Sentry.captureException(err);
    }
  }

  const updateRegisterDo = async () => {
    let updateNumberDo = {
      doNumber: numberDo
    }

    try {
      const responseNumberDo = await api.patch(`/v1/harvests/${id}`, updateNumberDo)
      setOpenModalDo(false)
      handleRefetchData()
      if (responseNumberDo.status === 200) {
        setShowNotifDo(true)
      }
    } catch (err) {
      // console.log("err", err);
      Sentry.captureException(err);
    }
  }

  const updatePrice = async () => {
    let updatePrice = {
      pricePerChicken: parseInt(price.replace(".", ""))
    }

    try {
      const responsePrice = await api.patch(`/v1/harvests/${id}`, updatePrice)
      setOpenModalPrice(false)
      handleRefetchData()
      if (responsePrice.status === 200) {
        setShowNotfiPrice(true)
      }
    } catch (err) {
      // console.log("error", err);
      Sentry.captureException(err);
    }
  }

  const handleClickReview = async () => {
    try {
      const responseReview = await api.post(`/v1/harvests/${responseDetail?.id}/review`)
      // console.log("response review", responseReview);
      if (responseReview.status === 200) {
        setIsSuccess(true)
      }
      localStorage.setItem('validate success', JSON.stringify(!isSuccess))
      localStorage.getItem("response success", JSON.stringify(responseReview.status))
      navigate('/panen')
    } catch(err) {
      // console.log("error", err);
      Sentry.captureException(err);
    }
  }

  return (
    <div className="flex h-auto xl:overflow-hidden overflow-auto">
      {loading ? (
        <div className="flex justify-center items-center h-screen w-full">
          <LoadingAnimation/>
        </div>
      ) : (
        <div className="flex flex-col flex-1 overflow-hidden">
          <Card className="w-full h-auto border">
            <CardHeader
              floated={false}
              shadow={false}
              className="p-md border-none"
            >
              <div className="flex justify-between w-full">
                <div>
                  <Typography
                    variant="h2"
                    color="blue-gray"
                    className="text-xl font-semibold mb-4 relative"
                  >
                    <div className="flex gap-2">
                      <BackArrow onClick={() => navigate('/panen')} className="cursor-pointer"/> Detail
                    </div>
                  </Typography>
                </div>
              </div>

              <div className="mt-4">
                <Typography
                  variant="h2"
                  color="blue-gray"
                  className="text-xl font-semibold p-2"
                >
                  {responseDetail?.rearingPeriod?.house?.labels}
                </Typography>
              </div>

              <div className="mt-[-6px] flex">
                <div className="p-2">
                  <Calendar/>
                </div>
                <div className="mt-2">
                  {moment(responseDetail?.harvestDate).format("DD MMMM YYYY")}
                </div>
              </div>
              <hr className="border-t border[#EAECF0] w-[460px] my-4 mt-[8px]"/>

              <div className="mt-3 p-4 rounded shadow-md bg-[#F9FAFB] w-[460px]">
                <div className="w-full grid grid-cols-3">
                  {labelTimeHarvest?.map((val) => (
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="ml-[30px]"
                    >
                      {val.label}
                    </Typography>
                  ))}
                </div>
                
                <div className="w-full grid grid-cols-3 mt-2">
                  {responeTimeHarvest?.map((val) => (
                    <Typography
                    variant="small"
                    color="blue-gray"
                    className="ml-[30px]"
                    >
                    <div className="flex gap-1">
                        <div>
                          {val?.icon}
                        </div>

                        <div>
                          {val?.value}
                        </div>
                    </div>
                  </Typography>
                  ))}
                </div>
              </div>
            </CardHeader>

            <CardBody className="p-0 hidden:overflow-scroll mt-4">
              <div className="p-4">
                <Card className="w-full h-auto border">
                  <CardHeader
                    floated={false}
                    shadow={false}
                    className="p-md border-none"
                  >
                    <div className="flex mt-[-12px]">
                      <div className="mt-2">
                        <CarBakul/>
                      </div>
                      <Typography
                        variant="h2"
                        color="blue-gray"
                        className="text-xl font-semibold p-2"
                      >
                        Data bakul
                      </Typography>
                    </div>
                  </CardHeader>
                  <div className="border-t border-[#EAECF0] my-4 w-full" />

                  <div className="w-full">
                    <CardBody className="p-0 hidden:overflow-scroll mt-4">
                      <div className="flex flex-row space-y-8 p-4 gap-20">
                        <div className="w-[240px]">
                          <Typography
                            variant="h6"
                            color="blue-gray"
                            className="text-md font-semibold p-2"
                          >
                            Pembeli
                          </Typography>

                          <Typography
                            variant="h2"
                            color="blue-gray"
                            className="text-xl font-semibold p-2 mt-[-6px]"
                          >
                            {responseDetail?.bakul?.labels}
                          </Typography>
                      </div>

                        <div className="mt-10">
                          <Typography
                            variant="medium"
                            color="blue-gray"
                            className="text-sm w-full flex italic text-[14px] mt-3"
                          >
                            Data telah disesuaikan oleh Admin, {moment(responseDetail?.history[0]?.createdAt).format("DD MMMM YYYY")}. <div className="text-[#1570EF] font-bold cursor-pointer" onClick={() => setOpenSidebarRegister(!openSidebarRegister)}>Lihat riwayat</div>
                          </Typography>
                        </div>
                      </div>
                      <div className="border-t border-[#EAECF0] my-4 w-full" />

                      <div className="flex flex-row space-y-8 p-4 gap-20">
                        <div className="w-[240px]">
                          <Typography
                            variant="h6"
                            color="blue-gray"
                            className="text-md font-semibold p-2"
                          >
                            Plat Nomor
                          </Typography>

                          <Typography
                            variant="h2"
                            color="blue-gray"
                            className="text-xl font-semibold p-2 mt-[-6px]"
                          >
                            {responseDetail?.licenseNumber}
                          </Typography>
                        </div>

                        <div className="mt-[35px]">
                          <Typography
                            variant="medium"
                            color="blue-gray"
                            className="text-sm w-full flex italic text-[14px]"
                          >
                            Data telah disesuaikan oleh Admin {moment(responseDetail?.history[0]?.createdAt).format("DD MMMM YYYY")}. <div className="text-[#1570EF] font-bold cursor-pointer" onClick={() => setOpenSidebarBuyyer(!openSidebarBuyyer)}>Lihat riwayat</div>
                          </Typography>
                        </div>
                      </div>
                      <div className="border-t border-[#EAECF0] my-4 w-full" />

                      <div className="flex flex-row space-y-8 p-4 gap-20">
                        <div className="w-[240px]">
                          <Typography
                            variant="h6"
                            color="blue-gray"
                            className="text-md font-semibold p-2"
                          >
                            Nomor DO
                          </Typography>

                          <Typography
                            variant="h2"
                            color="blue-gray"
                            className="text-xl font-semibold p-2 mt-[-6px]"
                          >
                            {responseDetail?.doNumber}
                          </Typography>
                        </div>

                        <div className="mt-[35px]">
                          <Typography
                            variant="medium"
                            color="blue-gray"
                            className="text-sm w-full flex italic text-[14px]"
                          >
                            Data telah disesuaikan oleh Admin {moment(responseDetail?.history[0]?.createdAt).format("DD MMMM YYYY")}. <div className="text-[#1570EF] font-bold cursor-pointer" onClick={() => setOpenSidebarDo(!openSidebarDo)}>Lihat riwayat</div>
                          </Typography>
                        </div>
                      </div>
                      <div className="border-t border-[#EAECF0] my-4 w-full" />

                      <div className="flex flex-row space-y-8 p-4 gap-20">
                        <div className="w-[240px]">
                          <Typography
                            variant="h6"
                            color="blue-gray"
                            className="text-md font-semibold p-2"
                          >
                            Harga per kilo
                          </Typography>

                          <Typography
                            variant="h2"
                            color="blue-gray"
                            className="text-xl font-semibold p-2 mt-[-6px]"
                          >
                            Rp {responseDetail?.pricePerChicken.toLocaleString("id-ID")}
                          </Typography>
                        </div>

                        <div className="mt-[35px]">
                          <Typography
                            variant="medium"
                            color="blue-gray"
                            className="text-sm w-full flex italic text-[14px]"
                          >
                            Data telah disesuaikan oleh Admin {moment(responseDetail?.history[0]?.createdAt).format("DD MMMM YYYY")}. <div className="text-[#1570EF] font-bold cursor-pointer" onClick={() => setOpenSidebarPrice(!openSidebarPrice)}>Lihat riwayat</div>
                          </Typography>
                        </div>
                      </div>
                      <div className="border-t border-[#EAECF0] my-4 w-full" />
                    </CardBody>
                  </div>
                </Card>

                <Card className="w-full h-auto border mt-[40px]">
                  <CardHeader
                    floated={false}
                    shadow={false}
                    className="p-md border-none"
                  >
                    <div className="flex mt-[-12px]">
                      <div className="mt-2">
                        <Height/>
                      </div>
                      <Typography
                        variant="h2"
                        color="blue-gray"
                        className="text-xl font-semibold p-2"
                      >
                        Data timbang
                      </Typography>
                    </div>
                  </CardHeader>
                  <div className="border-t border-[#EAECF0] my-4 w-full" />

                  <CardBody className="p-0 hidden:overflow-scroll mt-4">
                    <div>
                      <div className="p-4 ml-[g20px] grid sm:grid-cols-1">
                        <div className="grid grid-cols-4 gap-4 md:grid-cols-4">
                          {cardDatatimbang?.map((val) => (
                            <Card className={`w-full h-[95%] ${val.id === 1 ? "bg-[#EFF8FF]" : val.id === 2 ? "bg-[#FFFAEB]" : val.id === 3 ?  "bg-[#FEF3F2]" : "bg-[#ECFDF3]"}`}>
                              <CardHeader
                                shadow={false}
                                floated={false}
                                className={`p-5  border-none ${val.id === 1 ? "bg-[#EFF8FF]" : val.id === 2 ? "bg-[#FFFAEB]" :val.id === 3 ?  "bg-[#FEF3F2]" : "bg-[#ECFDF3"}`}
                              >
                                  <div className="mt-4">
                                    <Typography
                                      variant="h6"
                                      className="text-md mt-[-25px]"
                                    >
                                      {val.label}
                                    </Typography>
                                  </div>

                                  <div className="mt-2">
                                    <Typography
                                      variant="h2"
                                      className={`text-3xl ${val.id === 1 ? "text-[#1570EF]" : val.id === 2 ? "text-[#DC6803]" : val.id === 3 ? "text-[#D92D20]" : "text-[#079455]"}`}
                                    >
                                      {val.value}
                                    </Typography>
                                  </div>

                                  <div className="mt-3">
                                    <Typography
                                      variant="medium"
                                      color="blue-grey"
                                      className="text-[14px] text-md font-bold text-[#667085]"
                                    >
                                      {val.desc}
                                    </Typography>
                                  </div>
                              </CardHeader>
                            </Card>
                          ))}
                        </div>

                        <div className="p-4 mt-5">
                          <Typography
                            variant="h2"
                            color="blue-gray"
                            className="text-xl font-semibold p-2"
                          >
                            Tabel panen
                          </Typography>

                          <div className="mt-2 w-full max-h-[80%]">
                            <TableDetailPanen
                              dataTableHarvest={responseDetail?.harvestWeights}
                            />
                          </div>

                          <div className="p-4 mt-5">
                            <Typography
                              variant="h2"
                              color="blue-gray"
                              className="text-xl font-semibold p-2"
                            >
                              Foto
                            </Typography>

                            <div className="mt-4 container mx-auto">
                              <div className="flex justify-between w-[85%]">
                                <Typography
                                  variant="medium"
                                  color="blue-gray"
                                  className="text-md w-full flex text-[14px] font-[600px]"
                                >
                                  Foto Supir & Kendaraan
                                </Typography>

                                <div className="flex justify-between w-[60%] ">
                                  <div>
                                    <Typography
                                      variant="medium"
                                      color="blue-gray"
                                      className="text-md w-full flex text-[14px] font-[600px]"
                                    >
                                      Foto Penimbang
                                    </Typography>
                                  </div>

                                  <div>
                                    <Typography
                                      variant="medium"
                                      color="blue-gray"
                                      className="text-md w-full flex text-[14px] font-[600px]"
                                    >
                                      Foto penanggung jawab
                                    </Typography>
                                  </div>

                                </div>
                              </div>
                              <div className="grid grid-cols-4 gap-3 w-full mt-[30px]">
                                <Card
                                  className="h-40 w-60 cursor-pointer overflow-hidden transition-opacity hover:opacity-90"
                                  onClick={() => setOpenVehicle(!openVehicle)}
                                >
                                  <img
                                    className="h-full w-full object-cover object-center"
                                    src={dataVehicle?.url}
                                    alt="harvest"
                                  />
                                </Card>
                                <ImageDialog 
                                  open={openVehicle
                                  } 
                                  handlerOpen={() => setOpenVehicle(!openVehicle)}
                                  imageSrc={dataVehicle?.url}
                                />

                                <Card
                                  className="h-40 w-60 cursor-pointer overflow-hidden transition-opacity hover:opacity-90"
                                  onClick={() => setOpenDriver(!openDriver)}
                                >
                                  <img
                                    className="h-full w-full object-cover object-center"
                                    src={dataDriver?.url}
                                    alt="harvest"
                                  />
                                </Card>
                                <ImageDialog 
                                  open={openDriver} 
                                  handlerOpen={() => setOpenDriver(!openDriver)}
                                  imageSrc={dataDriver?.url}
                                />

                                <Card
                                  className="h-40 w-60 cursor-pointer overflow-hidden transition-opacity hover:opacity-90"
                                  onClick={() => setOpenDataScaleOperator(!openDataSclaeOperator)}
                                >
                                  <img
                                    className="h-full w-full object-cover object-center"
                                    src={dataScaleOperator?.url}
                                    alt="harvest"
                                  />
                                </Card>
                                <ImageDialog 
                                  open={openDataSclaeOperator} 
                                  handlerOpen={() => setOpenDataScaleOperator(!openDataSclaeOperator)}
                                  imageSrc={dataScaleOperator?.url}
                                />

                                <Card
                                  className="h-40 w-60 cursor-pointer overflow-hidden transition-opacity hover:opacity-90"
                                  onClick={() => setOpenPic(!openPic)}
                                >
                                  <img
                                    className="h-full w-full object-cover object-center"
                                    src={dataPic?.url}
                                    alt="harvest"
                                  />
                                  <ImageDialog 
                                    open={openPic} 
                                    handlerOpen={() => setOpenPic(!openPic)}
                                    imageSrc={dataPic?.url}
                                  />
                                </Card>
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </div>
            </CardBody>
          </Card>
        </div>
      )}

      {/* modal to confirmation */}
      <ConfirmDialog
        isOpen={modalConfirmation}
        onClose={() => setModalconfirmation(!modalConfirmation)}
        content='Pastikan semua data sudah benar karena setelah ini, perubahan tidak dapat dilakukan.'
        title="Selesai review data?"
        btnLabel="Lanjutkan"
        onConfirm={handleClickReview}
      />

      <ModalEditPanen
        open={openModal}
        setOpen={() => setOpenModal(!openModal)}
        handleOnclickCancel={() => setOpenModal(!openModal)}
        ModalHeader="Sesuaikan data pembeli"
        optionsBuyyer={dataBakul}
        dropdownPlaceholder={responseDetail?.bakul?.labels}
        onSelect={handleSelectBuyyer}
        setSearchBuyyer={setSearchBuyyer}
        handleOnclickSave={updateBakul}
        formDropdown
      />

      <ModalEditPanen
        formInputRegisterNumber
        open={openModalPlat}
        setOpen={() => setOpenModalPlat(!openModalPlat)}
        ModalHeader="Sesuaikan Plat nomor"
        setInputRegisterNumber={handleChangeRegisterNumber}
        inputRegisterNumber={registerNumber}
        handleOnclickSave={updateRegisterNumber}
        handleOnclickCancel={() => setOpenModalPlat(!openModalPlat)}
      />

      <ModalEditPanen
        formInputDo
        ModalHeader="Nomor DO"
        open={openModalDo}
        setOpen={() => setOpenModalDo(!openModalDo)}
        setValueInputDo={handleChangeNumberDo}
        handleOnclickCancel={() => setOpenModalDo(!openModalDo)}
        handleOnclickSave={updateRegisterDo}
        valueInputDo={numberDo}
      />

      <ModalEditPanen
        ModalHeader="Sesuaikan harga per kilo"
        formInputPrice
        open={openModalPrice}
        inputPrice={price}
        setOpen={() => setOpenModalPrice(!openModalPrice)}
        setInputPrice={handleChangePrice}
        handleOnclickCancel={() => setOpenModalPrice(!openModalPrice)}
        handleOnclickSave={updatePrice}
      />

      <SidebarHistory
        open={openSidebarRegister}
        setOpen={() => setOpenSidebarRegister(!openSidebarRegister)}
        dataHistory={groupingHistory?.bakul}
        historyBuyyer
      />      

      <SidebarHistory
        open={openSidebarBuyyer}
        setOpen={() => setOpenSidebarBuyyer(!openSidebarBuyyer)}
        historyRegister
        dataHistory={groupingHistory?.licenseNumber}
      />

      <SidebarHistory
        open={openSidebarDo}
        setOpen={() => setOpenSidebarDo(!openSidebarDo)}
        dataHistory={groupingHistory?.doNumber}
        historyDo
      />

      <SidebarHistory
        historyPrice
        open={openSidebarPrice}
        setOpen={() => setOpenSidebarPrice(!openSidebarPrice)}
        dataHistory={groupingHistory?.pricePerChicken}
      />
    </div>
  )
}

export default DetailPanen