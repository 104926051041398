/* eslint-disable no-unused-vars */
/* eslint-disable no-sequences */
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Typography,
} from "@material-tailwind/react";
import { useEffect, useState } from "react";
import { Button } from "../../../../atoms/Button";
import ToasterAlert from "../../../../atoms/Toaster";
import api from "../../../../api/configApi";
import ConfirmDialog from "../../../../atoms/ConfirmDialog";
import { useNavigate } from "react-router-dom";
import Input from "../../../../atoms/Input";
import FormDropdownSearch from "../../../../atoms/FormDropdownSearch";
import TextArea from "../../../../atoms/TextArea";
import AlertNotification from "../../../../atoms/AlertNotification";
import { FaCheckCircle } from "react-icons/fa";
import { useSelector } from "react-redux";
import InputNumber from "../../../../atoms/NumberFormat";
import { ReactComponent as ArrowIncrement } from "../../../../assets/icons/arrow-increment.svg";
import { ReactComponent as ArrowDecrement } from "../../../../assets/icons/arrow-decrement.svg";
import * as Sentry from "@sentry/react";

const TambahKandang = () => {
  const { user } = useSelector((state) => state.auth);
  const [kapasitas, setKapasitas] = useState("");
  const [jenisKandang, setJenisKandang] = useState(null);
  const [tingkatKandang, setTingkatKadang] = useState("");
  const [alamatKandang, setAlamatKandang] = useState("");
  const [unit, setUnit] = useState([]);
  const [region, setRegion] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [selectUnit, setSelectUnit] = useState(null);
  const [selectMasterUnit, setSelectMasterUnit] = useState("");

  const [confirmation, setConfirmation] = useState(false);
  const [responeTypeCage, setReponseTypeCage] = useState([]);
  const [isSuccessAdd, setIsSuccessAdd] = useState(false);
  const [isAddError, setAddError] = useState(false);

  const [listUser, setListUsers] = useState([]);
  const [listMasterUnit, setListMasterUnit] = useState([]);
  const [selectedListUser, setSelectedListUser] = useState("");
  const [nameCage, setNameCage] = useState("");
  const [searchHouseOwner, setSearchHouseOwner] = useState("");
  const [searchProvince, setSearchProvince] = useState("");
  const [searchHouseType, setSearchHouseType] = useState("");
  const [searchHouseFloor, setSearchHouseFloor] = useState("");
  const [searchCity, setSearchCity] = useState("");
  const [searchMasterUnit, setSearchMasterUnit] = useState("");

  const [validationError, setValidationError] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [cageNumber, setCageNumber] = useState(1);
  const [codeCage, setCodeCage] = useState("");
  const [codeCageMerge, setCodeCageMerge] = useState("");
  const [fetchingTrigger, setFetchingTrigger] = useState(false);
  const [temporarySequence, setTemporarySequence] = useState([]);
  const [showError, setShowError] = useState(false);

  const navigate = useNavigate();

  const lantaiKandang = [
    { id: 1, labels: "1" },
    { id: 2, labels: "2" },
    { id: 3, labels: "3" },
  ];
  const [isSubmiting, setIsSubmitting] = useState(false);
  const isDisabled =
    !nameCage ||
    !alamatKandang ||
    !jenisKandang ||
    !tingkatKandang ||
    !cageNumber ||
    !codeCage ||
    !kapasitas ||
    !selectedListUser ||
    (user?.role === "Super Admin" && !selectMasterUnit);

  const handleCreateCage = async () => {
    setIsSubmitting(true);

    try {
      const addCage = {
        sequence: cageNumber,
        name: nameCage,
        address: alamatKandang,
        type: jenisKandang.id,
        totalFloor: tingkatKandang.id,
        maxCapacity: parseInt(kapasitas.replace(".", "")),
        owner: { id: selectedListUser },
        isActive: false,
        city: selectUnit === null ? null : { id: selectUnit },
      };

      if (selectMasterUnit.length > 0) {
        addCage.unit = { id: selectMasterUnit };
      }

      const response = await api.post("/v1/houses", addCage);
      setConfirmation(false);
      setIsSuccessAdd(true);

      localStorage.setItem("response success", JSON.stringify(!isSuccessAdd));

      const cageId = response.data.content.id;

      navigate(`/kandang/recomendation-add-ppl/${cageId}`);
    } catch (error) {
      setErrorMessage(
        error.response.data.error?.name || error.response.data.error
      );
      setAddError(true);
      setTimeout(() => setAddError(false), 3000);
      console.log(error);
      setConfirmation(false);
      setIsSubmitting(false);
      Sentry.captureException(error);
    }
  };

  useEffect(() => {
    const fetchDataListCage = async () => {
      try {
        const params = {
          search: searchHouseType,
        };
        const response = await api.get("/v1/houses/types", { params });
        setReponseTypeCage(response.data);
      } catch (error) {
        Sentry.captureException(error);
      }
    };

    const fetchUnit = async () => {
      try {
        const responseList = await api.get("/v1/regions/id/cities/dropdown", {
          params: {
            search: searchCity,
            province: selectedRegion,
          },
        });
        setUnit(responseList.data);
      } catch (error) {
        return error;
      }
    };

    const fetchMasterUnit = async () => {
      try {
        const params = {
          search: searchMasterUnit,
        };
        const responseList = await api.get("/v1/units/dropdown", { params });
        setListMasterUnit(responseList?.data?.contents);
      } catch (error) {
        return error;
      }
    };

    fetchMasterUnit();
    fetchUnit();
    fetchDataListCage();
  }, [selectedRegion, searchHouseType, searchCity, searchMasterUnit]);

  useEffect(() => {
    const fetchRegion = async () => {
      try {
        const params = {
          search: searchProvince,
        };
        const responseRegion = await api.get(
          "/v1/regions/id/province/dropdown",
          { params }
        );
        setRegion(responseRegion?.data);
      } catch (error) {
        Sentry.captureException(error);
      }
    };

    fetchRegion();
  }, [selectedRegion, searchProvince]);

  useEffect(() => {
    if (selectedListUser) {
      const checkSequence = async () => {
        try {
          const responseSequence = await api.get(
            `/v1/houses/sequences?owner=${selectedListUser}`
          );
          setTemporarySequence(responseSequence?.data?.registeredSequences);
          setCageNumber(responseSequence?.data?.nextSequence);
          setCodeCage(responseSequence?.data?.code);
          setCodeCageMerge(
            `${
              responseSequence?.data?.code +
              String(responseSequence?.data?.nextSequence).padStart(2, "0")
            }`
          );
        } catch (error) {
          console.log("error", error);
          return error;
        }
      };

      checkSequence();
    }
  }, [selectedListUser]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const params = {
          search: searchHouseOwner,
          showPhone: 1,
        };
        const response = await api.get("/v1/farmers/dropdown", { params });
        setListUsers(response?.data?.contents);
      } catch (error) {
        return error;
      }
    };

    fetchUsers();
  }, [searchHouseOwner]);

  const handleSelectCapasity = (select) => {
    setJenisKandang(select);
  };

  const handleSelectFloor = (select) => {
    setTingkatKadang(select);
  };

  const handleSelectRegion = (select) => {
    setSelectedRegion(select.id);
  };

  const handleSelectunit = (select) => {
    setSelectUnit(select.id);
  };

  const handleSelectOwner = (select) => {
    setSelectedListUser(select.id);

    if (selectedListUser !== null) {
      setFetchingTrigger(true);
    }
  };

  const handleSelectMasterUnit = (select) => {
    setSelectMasterUnit(select.id);
  };

  const handleCapacityChange = (e) => {
    setKapasitas(e.target.value);
  };

  const handleChangeAddres = (e) => {
    if (e.target.value.length <= 100) {
      setAlamatKandang(e.target.value);
    }
  };

  const incrementValue = () => {
    setCageNumber((prevValue) => {
      const newValue = prevValue + 1;
      checkDuplicateDataSequence(newValue);
      setCodeCageMerge(`${codeCage + String(newValue).padStart(2, "0")}`);
      return newValue;
    });
  };

  const decrementValue = () => {
    setCageNumber((prevValue) => {
      if (prevValue > 1) {
        const newValue = prevValue - 1;
        checkDuplicateDataSequence(newValue);
        setCodeCageMerge(`${codeCage + String(newValue).padStart(2, "0")}`);
        return newValue;
      } else {
        return 1;
      }
    });
  };

  const handleChange = (e) => {
    let inputValue = e.target.value;
    let newValue = inputValue === "" ? "" : parseInt(inputValue, 10);

    if (isNaN(newValue) || newValue < 0) {
      newValue = 0;
    } else if (newValue < 0 && inputValue !== "") {
      newValue = 0;
    }

    setCageNumber(newValue);
    setCodeCageMerge(`${codeCage + String(newValue).padStart(2, "0")}`);

    if (newValue > 0) {
      checkDuplicateDataSequence(newValue);
    }
  };

  const checkDuplicateDataSequence = (sequence) => {
    if (sequence !== "" && temporarySequence.includes(sequence)) {
      setShowError(true);
    } else {
      setShowError(false);
    }
  };

  return (
    <div className="flex h-auto xl:overflow-hidden overflow-auto">
      <div className="flex flex-col flex-1 overflow-hidden">
        <Card className="w-full h-auto border shadow-none">
          <CardHeader
            floated={false}
            shadow={false}
            className="p-md border-none"
          >
            <Typography className="text-xl font-bold mb-4 relative text-text-primary">
              Kandang baru
            </Typography>

            <div className="grid-cols-4 gap-4 mt-[40px]">
              <div className="text-center relative">
                <ToasterAlert
                  label="Periksa kembali data Anda"
                  type="info"
                  description="Pastikan data yang dimasukan sesuai dengan ketentuan yang tercantum dalam kontrak yang telah ditandatangani."
                  className={`shadow-none`}
                />
              </div>
            </div>
          </CardHeader>

          <CardBody className="p-0">
            <div className="text-center p-5">
              <Card className="w-full h-auto border shadow-none">
                <CardHeader
                  floated={false}
                  shadow={false}
                  className="border-none text-left rounded-t-lg rounded-b-none m-0"
                >
                  <div className="h-[40px] p-2 w-full relative bg-bg-tertiary pl-4">
                    <Typography className="text-md font-bold text-text-primary">
                      Kepemilikan Kandang
                    </Typography>
                  </div>
                </CardHeader>

                <CardBody className="p-0 max-h-full">
                  <div className="w-full min-w-max text-left p-4">
                    <Typography className="text-sm font-semibold text-text-primary">
                      Pemilik Kandang <span className="text-red-600">*</span>
                    </Typography>

                    <div className="mt-2">
                      <FormDropdownSearch
                        id="name"
                        placeholder="Masukan Nama Pemilik"
                        options={listUser}
                        onSelect={handleSelectOwner}
                        setSearch={setSearchHouseOwner}
                        search={searchHouseOwner}
                        houseOwnerList={true}
                        size="medium"
                        type="text"
                      />
                    </div>
                  </div>
                </CardBody>
              </Card>

              <div className="mt-6">
                <Card className="w-full h-auto border shadow-none">
                  <CardHeader
                    floated={false}
                    shadow={false}
                    className="border-none text-left rounded-b-none rounded-t-lg m-0"
                  >
                    <div className="h-[40px] p-2 w-full relative bg-bg-tertiary pl-4">
                      <Typography className="text-md font-bold text-text-primary">
                        Informasi Kandang
                      </Typography>
                    </div>
                  </CardHeader>

                  <CardBody className="p-0 max-h-full">
                    <div className="w-full min-w-max text-left p-4">
                      <Typography className="text-sm font-semibold text-text-primary">
                        Nama Kandang <span className="text-red-600">*</span>
                      </Typography>

                      <div className="mt-2">
                        <Input
                          type="text"
                          id="name"
                          value={nameCage}
                          onChange={(e) => setNameCage(e.target.value)}
                          placeholder="Masukan nama Kandang"
                          className="max-w-full placeholder:text-text-placeholder"
                          size="medium"
                        />
                      </div>

                      <div className="flex justify-between max-w-full">
                        <div className="w-full lg:w-[46%]">
                          <Typography className="text-sm font-semibold text-text-primary pt-6">
                            No Kandang <span className="text-red-600">*</span>
                          </Typography>

                          <div className="mt-2">
                            <Input
                              type="text"
                              id="cageNumber"
                              value={cageNumber}
                              onChange={handleChange}
                              placeholder="No Kandang"
                              className={`w-[96%] max-w-full placeholder:text-text-placeholder appearance-none ${
                                showError ? "border-red-500" : ""
                              }`}
                              size="medium"
                              disabled={
                                selectedListUser.length > 0 ? false : true
                              }
                              componentRight={
                                <div className={`flex flex-col absolute right-0 justify-between items-center w-[30px] h-full bg-bg-secondary_hover rounded-r-lg border ${showError &&  "border-red-500"}`}>
                                  <button
                                    onClick={incrementValue}
                                    className={`focus:outline-none ${
                                      selectedListUser.length > 0 &&
                                      "cursor-pointer hover:bg-gray-300 rounded-xs"
                                    }`}
                                    disabled={
                                      selectedListUser.length > 0 ? false : true
                                    }
                                  >
                                    <ArrowIncrement className="h-5 w-5 m-0 p-0" />
                                  </button>
                                  <button
                                    onClick={decrementValue}
                                    className={`focus:outline-none ${
                                      selectedListUser.length > 0 &&
                                      "cursor-pointer hover:bg-gray-300 rounded-xs"
                                    }`}
                                    disabled={
                                      selectedListUser.length > 0 ? false : true
                                    }
                                  >
                                    <ArrowDecrement className="h-5 w-5 m-0 p-0" />
                                  </button>
                                </div>
                              }
                            />

                            {showError && (
                              <div className="pt-2">
                                <Typography className="text-sm text-text-errorPrimary w-full h-[30px] text-start font-normal leading-tight">
                                  Kode kandang telah digunakan, masukkan nomor lain.
                                </Typography>
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="w-full lg:w-[48%]">
                          <Typography className="text-sm font-semibold text-text-primary pt-6 text-start">
                            Kode Kandang <span className="text-red-600">*</span>
                          </Typography>

                          <div className="mt-2">
                            <Input
                              type="number"
                              id="name"
                              value={codeCageMerge}
                              placeholder={codeCageMerge}
                              className="w-[490px] placeholder:text-text-placeholder appearance-none"
                              size="medium"
                              disabled={true}
                            />
                          </div>
                        </div>
                      </div>

                      <Typography className="text-sm font-semibold pt-6 text-text-primary">
                        Kapasitas Kandang (ekor){" "}
                        <span className="text-red-600">*</span>
                      </Typography>

                      <div className="mt-2">
                        <InputNumber
                          type="text"
                          id="capasity"
                          placeholder="Masukan kapasitas kandang Contoh : 1000"
                          className="max-w-full"
                          value={kapasitas}
                          size="medium"
                          onChange={handleCapacityChange}
                        />
                        {validationError && (
                          <p className="text-red-500 text-sm mt-2">
                            {validationError}
                          </p>
                        )}
                      </div>

                      <Typography className="text-sm font-semibold pt-6 text-text-primary">
                        Jenis Kandang <span className="text-red-600">*</span>
                      </Typography>

                      <div className="mt-2">
                        <FormDropdownSearch
                          options={responeTypeCage?.contents}
                          placeholder="Pilih Jenis Kandang"
                          onSelect={handleSelectCapasity}
                          setSearch={setSearchHouseType}
                          search={searchHouseType}
                          size="medium"
                        />
                      </div>

                      <Typography className="text-sm font-semibold pt-6 text-text-primary">
                        Tingkat Kandang <span className="text-red-600">*</span>
                      </Typography>

                      <div className="mt-2">
                        <FormDropdownSearch
                          onSelect={handleSelectFloor}
                          placeholder="Pilih Tingkat Kandang"
                          options={lantaiKandang}
                          setSearch={setSearchHouseFloor}
                          search={searchHouseFloor}
                          size="medium"
                        />
                      </div>

                      <Typography className="text-sm font-semibold pt-6 text-text-primary">
                        {/* Provinsi <span className="text-red-600">*</span> */}
                        Provinsi
                      </Typography>

                      <div className="mt-2">
                        <FormDropdownSearch
                          onSelect={handleSelectRegion}
                          placeholder="Pilih Provinsi"
                          options={region?.contents}
                          setSearch={setSearchProvince}
                          search={searchProvince}
                          size="medium"
                        />
                      </div>

                      <Typography className="text-sm font-semibold pt-6 text-text-primary">
                        {/* Kota/Kabupaten <span className="text-red-600">*</span> */}
                        Kota/Kabupaten
                      </Typography>

                      <div className="mt-2">
                        <FormDropdownSearch
                          onSelect={handleSelectunit}
                          placeholder="Pilih Kota/Kabupaten"
                          options={unit?.contents}
                          setSearch={setSearchCity}
                          search={searchCity}
                          size="medium"
                        />
                      </div>

                      <Typography className="text-sm font-semibold pt-6 text-text-primary">
                        Alamat Kandang <span className="text-red-600">*</span>
                      </Typography>
                      <div className="mt-2">
                        <TextArea
                          placeholder="Alamat Kandang"
                          value={alamatKandang}
                          onChange={handleChangeAddres}
                          className="placeholder:text-text-placeholder text-text-primary"
                        />

                        <div>
                          <Typography className="text-sm font-semibold p-1 mt-[-24px] pt-6 text-text-placeholder">
                            {alamatKandang.length} / 100
                          </Typography>
                        </div>
                      </div>
                      {user?.role === "Super Admin" && (
                        <>
                          <Typography className="text-sm font-semibold pt-6 text-text-primary">
                            Unit <span className="text-red-600">*</span>
                          </Typography>

                          <div className="mt-2">
                            <FormDropdownSearch
                              onSelect={handleSelectMasterUnit}
                              placeholder="Pilih Unit"
                              options={listMasterUnit}
                              setSearch={setSearchMasterUnit}
                              search={searchMasterUnit}
                              size="medium"
                              isLast={true}
                            />
                          </div>
                        </>
                      )}
                    </div>
                  </CardBody>
                </Card>
              </div>
            </div>
          </CardBody>

          <CardFooter className="border-t p-0 m-0 border-b-none shadow-none">
            <div className="flex justify-between p-4">
              <Button
                label="Keluar"
                size="medium"
                onClick={() => navigate("/Kandang")}
                type="btnSecondary"
              />
              <div className="flex justify-between gap-4">
                <Button
                  label="Simpan Kandang"
                  onClick={() => setConfirmation(true)}
                  type={
                    isDisabled || isSubmiting
                      ? "btnPrimaryDisabled"
                      : "btnPrimary"
                  }
                  disabled={isDisabled || isSubmiting}
                  componentLeft={
                    isSubmiting && (
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                        className="animate-spin fill-neutral-400"
                      >
                        <path
                          d="M10.72,19.9a8,8,0,0,1-6.5-9.79A7.77,7.77,0,0,1,10.4,4.16a8,8,0,0,1,9.49,6.52A1.54,1.54,0,0,0,21.38,12h.13a1.37,1.37,0,0,0,1.38-1.54,11,11,0,1,0-12.7,12.39A1.54,1.54,0,0,0,12,21.34h0A1.47,1.47,0,0,0,10.72,19.9Z"
                          className="spinner_6kVp"
                        />
                      </svg>
                    )
                  }
                />
              </div>
            </div>
          </CardFooter>
        </Card>
      </div>

      <ConfirmDialog
        title="Konfirmasi buat kandang baru"
        content="Pastikan semua informasi kandang baru sudah benar sebelum menyimpan."
        onConfirm={handleCreateCage}
        isOpen={confirmation}
        onClose={() => setConfirmation(false)}
        btnLabel="Buat kandang"
      />

      {isAddError && (
        <AlertNotification
          icon={<FaCheckCircle />}
          color="red"
          message={errorMessage}
        />
      )}
    </div>
  );
};

export default TambahKandang;
