/* eslint-disable no-unused-vars */

import {ReactComponent as DotSuccess} from '../assets/icons/dot/success_dot.svg'
import {ReactComponent as DotPending} from '../assets/icons/dot/pending_dot.svg'

const TagLabel = ({ type, size, children, showDot }) => {

  const getColorClass = () => {
    switch (type) {
      case "primary": 
        return "bg-[#FEF8E2] text-[#C49801] border-[#FBE497]-90 border-2 border-primary-200";
      case "danger": 
        return "bg-[#FFFBFA] text-[#F04438] border-[#FECDCA]-90"
      case "success": 
        return "bg-[#ABEFC6] text-[#079455] border-[#ABEFC6]-50"
      case "info": 
        return "bg-[#F5FAFF] text-[#1570EF] border-[#B2DDFF]-50"
      default: 
        return "bg-[#dddfe1] color-[#344054 border-[#EAECF0]"
    }
  }

  const getDotColor = () => {
    switch (type) {
      case "success": 
        return <DotSuccess/>
      default: 
        return <DotPending/>
    }
  }


  const getSizesLabel = () => {
    switch (size) {
      case 'xsmall': 
        return "text-xs w-max-32 h-[28px] rounded flex justify-center items-center";
      case 'large': 
        return "text-lg w-max-32 h-[48px] rounded flex justify-center items-center"
      case 'medium': 
        return "text-md  w-max-32 h-[38px] rounded flex justify-center items-center"
      default:
        return "text-sm  w-max-32 rounded flex justify-center items-center p-sm"
    }
  }

  const labelClassName = `rounded-md p-2 ${getColorClass()} ${getSizesLabel()}`
  return (
    <div className={labelClassName}>
      <div className={`flex grid-cols-2 gap-4`}>
        {showDot && (
          <>
            <div className='w-4 h-4 mt-[5px]'>
              {getDotColor()}
            </div>
          </>
        )}
        <div className={!showDot ? 'null' : 'mr-2'}> {children}</div>
      </div>
    </div>
  )
}

export default TagLabel