/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
} from "@material-tailwind/react";
import Input from "../../../atoms/Input";
import { FaCheckCircle } from "react-icons/fa";
import { Button } from "../../../atoms/Button";
import api from "../../../api/configApi";
import AlertNotification from "../../../atoms/AlertNotification";
import ConfirmDialog from "../../../atoms/ConfirmDialog";
import DropdownBentuk from "../../../atoms/DropdownWithReset";
import DropdownSatuan from "../../../atoms/DropdownWithReset";

const EditOvkModal = ({
  isOpen,
  onClose,
  onReloadData,
  ovkId,
  onUpdate,
  setSelectedEditOvkId,
}) => {
  const [name, setName] = useState("");
  const [supplier, setSupplier] = useState("");
  const [unit, setUnit] = useState("");
  const [shape, setShape] = useState("");
  const [units, setUnits] = useState([]);
  const [shapes, setShapes] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [isEditSuccess, setEditSuccess] = useState(false);
  const [isEditError, setEditError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const isSubmitDisabled = !name || !supplier || !unit || !shape;

  const [isEditDialogOpen, setEditDialogOpen] = useState(false);
  const [selectedOptionUnit, setSelectedOptionUnit] = useState(null);
  const [selectedOptionShape, setSelectedOptionShape] = useState(null);

  useEffect(() => {
    const fetchOvks = async () => {
      try {
        const response = await api.get(`/v1/ovks/${ovkId}`);
        setName(response.data.name);
        setSupplier(response.data.supplier);
        setSelectedOptionUnit(response.data.unit);
        setSelectedOptionShape(response.data.shape);
        setShape(response.data.shape?.id);
        setUnit(response.data.unit?.id);
      } catch (error) {
        console.error("Error fetching ovks:", error);
      }
    };

    const fetchUnits = async () => {
      try {
        const response = await api.get("/v1/ovks/unit");
        setUnits(response.data.contents);
      } catch (error) {
        console.error("Error fetching units:", error);
      }
    };

    if (isOpen && ovkId) {
      fetchOvks();
    }

    fetchUnits();
  }, [isOpen, ovkId]);

  useEffect(() => {
    const fetchShapes = async () => {
      try {
        const response = await api.get("/v1/ovks/shape");
        setShapes(response.data.contents);
      } catch (error) {
        console.error("Error fetching shape:", error);
      }
    };

    fetchShapes();
  }, []);

  const handleOpenEditDialog = () => {
    setEditDialogOpen(true);
  };

  const handleCloseEditDialog = () => {
    setSelectedEditOvkId(null);
    setEditDialogOpen(false);
  };

  const handleUpdate = async () => {
    let requestEditData = {
      name: name,
      supplier: supplier,
    };

    if (unit !== "") {
      requestEditData.unit = unit;
    }

    if (shape !== "") {
      requestEditData.shape = shape;
    }

    handleCloseEditDialog();
    try {
      setIsSubmitting(true);
      const response = await api.put(`/v1/ovks/${ovkId}`, requestEditData);
      console.log("OVK data updated:", response.data);
      setIsSubmitting(false);
      if (onUpdate) {
        onUpdate(response.data);
      }
      if (onReloadData) {
        onReloadData();
      }

      setEditSuccess(true);
      setTimeout(() => setEditSuccess(false), 2000);

      setName("");
      setSupplier("");
      setShape("");
      setUnit("");

      onClose();
    } catch (error) {
      console.error("Error updating ovk data:", error);

      setName("");
      setSupplier("");
      setShape("");
      setUnit("");

      setErrorMessage(
        error.response.data.error?.name ||
          error.response.data.error?.supplier ||
          error.response.data.error
      );
      setEditError(true);
      setTimeout(() => setEditError(false), 2000);

      setIsSubmitting(false);
      onClose();
    }
  };

  const handleChangeUnit = (select) => {
    const selectedUnit = select;
    setUnit(selectedUnit.id);
    setSelectedOptionUnit(selectedUnit);
  };

  const handleChangeShape = (select) => {
    const selectedShape = select;
    setShape(selectedShape.id);
    setSelectedOptionShape(selectedShape);
  };

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }

    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [isOpen]);

  return (
    <div>
      {/* Modal Overlay */}
      {isOpen && (
        <div className="fixed inset-0 bg-gray-800 opacity-50 z-[50]"></div>
      )}
      <Card
        className={`fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-[60] ${
          isOpen ? "block" : "hidden"
        } rounded-xl flex-col justify-start items-start`}
        style={{ width: "800px" }}
      >
        <Card>
          <CardHeader
            floated={false}
            shadow={false}
            className="border-b border-border-secondary rounded-b-none my-1 m-0"
          >
            <button
              className="absolute top-6 right-4 text-gray-500"
              onClick={onClose}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
            {/* Modal Title */}
            <Typography
              type="h2"
              className="text-xl text-text-primary font-bold mb-1 p-4"
            >
              Ubah OVK
            </Typography>
          </CardHeader>
          <CardBody className="p-3xl">
            <div className="grid grid-cols-1 mb-3xl">
              <Typography
                type="small"
                className="text-text-primary text-sm font-semibold leading-tight"
              >
                Nama OVK:{" "}
                <span className="text-red-500 text-sm font-normal leading-tight">
                  *
                </span>
              </Typography>
              <Input
                type="text"
                id="name"
                placeholder="Masukkan Nama OVK"
                className="mt-md"
                value={name}
                size="medium"
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="grid grid-cols-1 my-3xl">
              <div>
                <Typography
                  type="small"
                  className="text-text-primary text-sm font-semibold leading-tight"
                >
                  Supplier OVK:{" "}
                  <span className="text-red-500 text-sm font-normal leading-tight">
                    *
                  </span>
                </Typography>
                <Input
                  type="text"
                  id="company"
                  placeholder="Masukkan Supplier OVK"
                  className="mt-md"
                  value={supplier}
                  size="medium"
                  onChange={(e) => setSupplier(e.target.value)}
                />
              </div>
            </div>
            <div className="grid grid-cols-1 mb-3xl">
              <Typography className="text-text-primary text-sm font-semibold leading-tight mb-md">
                Bentuk:{" "}
                <span className="text-red-500 text-sm font-normal leading-tight">
                  *
                </span>
              </Typography>
              <DropdownBentuk
                onSelect={handleChangeShape}
                placeholder="Pilih Bentuk"
                options={shapes}
                selectedOption={selectedOptionShape}
              ></DropdownBentuk>
            </div>
            <div className="grid grid-cols-1">
              <Typography className="text-text-primary text-sm font-semibold leading-tight mb-md">
                Satuan:{" "}
                <span className="text-red-500 text-sm font-normal leading-tight">
                  *
                </span>
              </Typography>
              <DropdownSatuan
                onSelect={handleChangeUnit}
                placeholder="Pilih Satuan"
                options={units}
                selectedOption={selectedOptionUnit}
              ></DropdownSatuan>
            </div>
          </CardBody>
          <CardFooter className="flex justify-end border-t border-border-secondary p-lg">
            <Button
              label="Batal"
              size="medium"
              type="btnSecondary"
              className="mr-md"
              onClick={onClose}
            />
            <Button
              label="Simpan"
              size="medium"
              type={`${
                isSubmitDisabled || isSubmitting
                  ? "btnPrimaryDisabled"
                  : "btnPrimary"
              }`}
              onClick={handleOpenEditDialog}
              disabled={isSubmitDisabled || isSubmitting}
              componentLeft={
                isSubmitting && (
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    className="animate-spin fill-neutral-400"
                  >
                    <path
                      d="M10.72,19.9a8,8,0,0,1-6.5-9.79A7.77,7.77,0,0,1,10.4,4.16a8,8,0,0,1,9.49,6.52A1.54,1.54,0,0,0,21.38,12h.13a1.37,1.37,0,0,0,1.38-1.54,11,11,0,1,0-12.7,12.39A1.54,1.54,0,0,0,12,21.34h0A1.47,1.47,0,0,0,10.72,19.9Z"
                      className="spinner_6kVp"
                    />
                  </svg>
                )
              }
            />
          </CardFooter>
        </Card>
      </Card>
      {/* Komponen dialog konfirmasi edit*/}
      <ConfirmDialog
        isOpen={isEditDialogOpen}
        onClose={handleCloseEditDialog}
        onConfirm={handleUpdate}
        title="Ubah OVK"
        content="Anda yakin ingin melanjutkan tindakan ini?"
        btnLabel="Lanjutkan"
        btnType="btnPrimary"
      />
      {isEditSuccess && (
        <AlertNotification
          icon={<FaCheckCircle />}
          color="green"
          message="Data OVK berhasil diubah."
        />
      )}

      {/* Alert untuk notifikasi error */}
      {isEditError && (
        <AlertNotification
          icon={<FaCheckCircle />}
          color="red"
          message={errorMessage}
        />
      )}
    </div>
  );
};

export default EditOvkModal;
