import { Alert } from "@material-tailwind/react";

const AlertNotification = ({ icon, color, message }) => {
  return (
    <div className="fixed top-4 right-4 animate-slide-up animate-fade-in items-center z-[120]">
      <Alert icon={icon} color={color} className="items-center">
        {message}
      </Alert>
    </div>
  );
};

export default AlertNotification;
