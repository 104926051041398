/* eslint-disable no-unused-vars */
import {
  Card,
  CardBody,
  CardHeader,
  Tab,
  Tabs,
  TabsHeader,
  Typography,
} from "@material-tailwind/react";
import { useEffect, useState } from "react";
import { Button } from "../../../../atoms/Button";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../../../api/configApi";
import EditKandangModal from "../../../../molecules/master/kandang/EditKandangModal";
import { ReactComponent as ChevronLeft } from "../../../../assets/icons/chevron-left.svg";
import ConfirmDialog from "../../../../atoms/ConfirmDialog";
import AlertNotification from "../../../../atoms/AlertNotification";
import { FaCheckCircle } from "react-icons/fa";
import { ReactComponent as EmptyIcon } from "../../../../assets/icons/emptyIconn.svg";
import LoadingAnimation from "../../../../atoms/LoadingAnimation";
import { ReactComponent as DeleteAccess } from "../../../../assets/icons/deleted_icon_access.svg";
import ModalAddMember from "../../../../molecules/master/kandang/modalAddMember";
import { FiEdit, FiTrash2 } from "react-icons/fi";
import AlertModal from "../../../../atoms/AlertModal";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { useSelector } from "react-redux";

import * as Sentry from "@sentry/react";

const DetailKandang = () => {
  const { user } = useSelector((state) => state.auth);
  const [settingType, setSettingType] = useState(
    localStorage.getItem("settingTypeDetailCage") || "profile"
  );
  const { id } = useParams();
  const [detail, setDetail] = useState([]);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const alert = JSON.parse(localStorage.getItem("response success"));
  const [notif, setNotif] = useState(alert);

  const alertEditSuccess = JSON.parse(
    localStorage.getItem("responseEditSuccess")
  );
  const [notifEditSuccess, setNotifEditSuccess] = useState(alertEditSuccess);
  const [loading, setLoading] = useState(false);
  const [openModalAddMember, setOpenModalAddMember] = useState(false);
  const [responseDrodpown, setResponseDropdown] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectRoleUser, setSelectRoleUser] = useState(null);
  const [dataPerimition, setDataPermission] = useState(null);
  const [responseAddRole, setResponseAddRole] = useState(null);
  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const [notifAddPermission, setNotifAddPermission] = useState(false);
  const [searchUserPermission, setSearchUserPermission] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showError, setShowError] = useState(false);
  const [fecthData, setFetchData] = useState(false);
  const [isEditCage, setIsEditCage] = useState(false);
  const [isWarningDeleteOpen, setWarningDeleteOpen] = useState(false);
  const [messageError, setMessageError] = useState("");
  const [unit, setUnit] = useState("");

  const data = [
    {
      label: "Profil Kandang",
      value: "profile",
    },
    {
      label: "Hak Akses",
      value: "akses",
    },
  ];

  useEffect(() => {
    const fetchDetailCage = async () => {
      setLoading(true);
      try {
        const response = await api.get(`/v1/houses/${id}`);
        setDetail(response?.data);
        setUnit(response?.data?.unit?.id);
        return response;
      } catch (error) {
        setError(error);
        Sentry.captureException(error);
      } finally {
        setLoading(false);
      }
    };

    if (fecthData) {
      setFetchData(false);
    }

    const fetchingListPermission = async () => {
      try {
        const fetchingPermission = await api.get(
          `/v1/houses/${id}/permissions`
        );
        setResponseAddRole(fetchingPermission?.data?.contents);
      } catch (err) {
        return err;
      }
    };

    fetchingListPermission();
    fetchDetailCage();
  }, [id, fecthData]);

  useEffect(() => {
    const fetchingDataAccess = async () => {
      try {
        const FetchingDataPermission = await api.get(`/v1/houses/permissions`);
        setDataPermission(FetchingDataPermission?.data?.contents);
      } catch (err) {
        return err;
      }
    };

    if (fecthData) {
      setFetchData(false);
    }

    fetchingDataAccess();
  }, [id, fecthData]);

  useEffect(() => {
    const FetchingListUser = async () => {
      try {
        const params = {
          search: searchUserPermission,
        };

        const response = await api.get(
          `/v1/users/dropdown?role=PPL&unit=${unit}`,
          { params }
        );
        setResponseDropdown(response?.data?.contents);
      } catch (err) {
        return err;
      }
    };

    if (unit) {
      FetchingListUser();
    }
  }, [searchUserPermission, unit]);

  useEffect(() => {
    setTimeout(() => {
      setNotif(false);
      localStorage.clear("response success");
    }, 4000);
  }, [notif]);

  useEffect(() => {
    setTimeout(() => {
      setNotifEditSuccess(false);
      localStorage.clear("responseEditSuccess");
    }, 4000);
  }, [notifEditSuccess]);

  useEffect(() => {
    setTimeout(() => {
      setShowError(false);
    }, 4000);
  }, [showError]);

  const handleSelectUser = (e) => {
    setSelectedUser(e);
  };

  const handleSelectRoleUser = (e) => {
    setSelectRoleUser(e);
  };

  const flagging = localStorage.getItem("flagging period");

  const handleBackwithFlaging = () => {
    if (flagging) {
      navigate("/periode-budidaya/periode-berjalan");
    } else {
      navigate(-1);
    }
  };

  const handleClickSaveNewAccess = async () => {
    try {
      const addRoleUser = {
        role: dataPerimition[1].id,
        user: { id: selectedUser.id },
      };
      const responseSaveNewAccess = await api.post(
        `/v1/houses/${id}/permissions`,
        addRoleUser
      );
      setFetchData(true);
      setNotifAddPermission(true);
      setResponseAddRole(responseSaveNewAccess?.data?.abkList);
    } catch (err) {
      setShowError(true);
      setErrorMessage(err?.response?.data?.error);
      return err;
    }
    setOpenModalAddMember(!openModalAddMember);
  };

  const handleRefetchData = () => {
    setFetchData(true);
  };

  return (
    <div className="flex h-auto xl:overflow-hidden overflow-auto">
      <div className="flex flex-col flex-1 overflow-hidden p-4">
        <Card
          className={`w-full h-auto border border-border-primary shadow-none ${
            isEditCage && "min-h-[950px]"
          }`}
        >
          <CardHeader
            floated={false}
            shadow={false}
            className="p-0 m-0 border-b rounded-b-none"
          >
            <div className="flex justify-betwen p-6">
              <div
                className="mt-2 cursor-pointer"
                onClick={handleBackwithFlaging}
              >
                <ChevronLeft />
              </div>

              <Typography
                variant="h2"
                className="text-xl font-semibold p-2 text-text-primary"
              >
                Pengaturan Kandang
              </Typography>
            </div>
          </CardHeader>

          {loading ? (
            <div className="flex justify-center items-center h-screen">
              <LoadingAnimation />
            </div>
          ) : (
            <CardBody className="hidden:overflow-scroll p-0 m-0">
              {error?.response?.status === 404 ? (
                <div className=" flex justify-center items-center h-screen">
                  <div>
                    <EmptyIcon className="ml-[30px]" />
                    <Typography
                      variant="h2"
                      className="text-xl font-semibold p-4 text-center"
                    >
                      Data tidak ditemukan
                    </Typography>

                    <Typography
                      variant="medium"
                      className="flex justify-between font-normal leading-none opacity-70 w-full min-h-full bg-gray-950"
                    >
                      Data yang Anda cari tidak ditemukan. Coba kata kunci lain
                    </Typography>
                  </div>
                </div>
              ) : (
                <div className="flex gap-8">
                  <Tabs
                    value={settingType}
                    orientation="vertical"
                    className="mt-[30px] w-[20%] ml-6"
                  >
                    <TabsHeader
                      className="rounded-none bg-transparent p-0 z-0"
                      indicatorProps={{
                        className:
                          "bg-transparent border-l-2 border-primary-400 shadow-none rounded-none gap-x-8",
                      }}
                    >
                      {data.map(({ label, value }) => (
                        <Tab
                          key={value}
                          value={value}
                          onClick={() => setSettingType(value)}
                          className={`text-base font-semibold w-full flex justify-start text-left ${
                            settingType === value
                              ? "bg-white text-text-secondary p-1 h-9"
                              : "bg-white text-text-quarterary p-1 h-9"
                          }`}
                        >
                          <Typography variant="h6" className="w-full ml-3">
                            {label}
                          </Typography>
                        </Tab>
                      ))}
                    </TabsHeader>
                  </Tabs>

                  <div className="w-full flex justify-start mb-8">
                    {settingType === "profile" ? (
                      <ProfileKandang
                        settingType={settingType}
                        detailCage={detail}
                        cageID={id}
                        errorCode={error}
                        isEditCage={isEditCage}
                        setIsEditCage={setIsEditCage}
                        isWarningDeleteOpen={isWarningDeleteOpen}
                        setWarningDeleteOpen={setWarningDeleteOpen}
                        messageError={messageError}
                        user={user}
                      />
                    ) : (
                      <HakAkses
                        pplCrue={detail}
                        openModal={openModalAddMember}
                        setOpenModal={() =>
                          setOpenModalAddMember(!openModalAddMember)
                        }
                        onClick={() =>
                          setOpenModalAddMember(!openModalAddMember)
                        }
                        options={responseDrodpown}
                        handleOnSelect={handleSelectUser}
                        optionUSer={dataPerimition}
                        handleSelectUser={handleSelectRoleUser}
                        handleClickSave={handleClickSaveNewAccess}
                        handleCancelAddMember={() =>
                          setOpenModalAddMember(!openModalAddMember)
                        }
                        responsePermissions={responseAddRole}
                        isOpenDeleteConfirm={openDialogDelete}
                        onClose={() => setOpenDialogDelete(!openDialogDelete)}
                        setOpenEditModal={() =>
                          setOpenDialogDelete(!openDialogDelete)
                        }
                        userId={id}
                        handleDeletefetchData={handleRefetchData}
                        loading={loading}
                        notif={notifAddPermission}
                        selectedRole={selectRoleUser}
                        setNotifAddPermission={setNotifAddPermission}
                        setSearch={setSearchUserPermission}
                        search={searchUserPermission}
                        showError={showError}
                        messageError={errorMessage}
                        setShowErrorMessage={setShowError}
                      />
                    )}
                  </div>
                </div>
              )}
            </CardBody>
          )}
        </Card>
      </div>

      {notifEditSuccess && (
        <AlertNotification
          icon={<FaCheckCircle />}
          color="green"
          message="Data profil kandang berhasil diubah."
        />
      )}
    </div>
  );
};

export default DetailKandang;

const ProfileKandang = ({
  detailCage,
  cageID,
  isEditCage,
  setIsEditCage,
  isWarningDeleteOpen,
  setWarningDeleteOpen,
  messageError,
  user,
}) => {
  const [notifDelete, setNotifDelete] = useState(false);
  const [responseDelete, setResponseDelete] = useState("");

  const table_row_title = [
    {
      id: 2,
      title: "Nama Kandang",
      value: detailCage?.name,
    },
    {
      id: 2,
      title: "Nomor Kandang",
      value: detailCage?.sequence,
    },
    {
      id: 2,
      title: "ID Kandang",
      value: detailCage?.code,
    },
    {
      id: 2,
      title: "Kemitraan",
      value: (
        <a
          href="https://broilerx.com"
          target="_blank"
          rel="noreferrer"
          className="font-bold text-text-infoPrimary"
        >
          BrolierX
        </a>
      ),
    },
    {
      id: 3,
      title: "Kapasitas Kandang",
      value: detailCage?.maxCapacity?.toLocaleString("id-ID"),
    },
    {
      id: 4,
      title: "jenis Kandang",
      value: detailCage?.type?.labels,
    },
    {
      id: 5,
      title: "Tingkat Kandang",
      value: detailCage?.totalFloor,
    },
    {
      id: 6,
      title: "Provinsi",
      value: detailCage?.province?.labels,
    },
    {
      id: 7,
      title: "Kota/Kabupaten",
      value: detailCage?.city?.labels,
    },
    {
      id: 8,
      title: "Alamat Kandang",
      value: detailCage?.address,
    },
    {
      id: 9,
      title: "Nama Unit",
      value: detailCage?.unit?.labels,
    },
  ];

  const [isDelete, setIsDelete] = useState(false);

  const handleCloseWarningDelete = () => {
    setWarningDeleteOpen(false);
  };

  const handleCloseEditDialog = () => {
    setIsEditCage(false);
  };

  const handleCloseDeleteDialog = () => {
    setIsDelete(false);
  };

  const handleClickEdit = () => {
    setIsEditCage(true);
  };
  const handleClickDelete = () => {
    setIsDelete(true);
  };

  const handleDeleteDetailCage = async () => {
    try {
      const response = await api.delete(`/v1/houses/${cageID}`);
      handleCloseDeleteDialog();
      setNotifDelete(true);

      localStorage.setItem("response delete", JSON.stringify(!notifDelete));

      setResponseDelete(response.status);
      setIsDelete(true);
      setTimeout(() => setIsDelete(false, 2000));
      window.location.reload();
    } catch (error) {
      handleCloseDeleteDialog();
      setWarningDeleteOpen(true);
      return error;
    }
  };

  return (
    <>
      <div className="h-auto xl:overflow-hidden overflow-auto ml-[-9] mt-9 w-[790px] max-w-[790px]">
        <div className="flex flex-col flex-1 overflow-hidden">
          <div className="flex gap-8">
            <Card className="h-full w-[120%] overflow-x-scroll border border-border-secondary shadow-none">
              <table className="w-full h-auto min-w-max table-auto text-left">
                <thead className="bg-bg-tertiary">
                  <tr>
                    <th
                      key={"profil"}
                      className="border-none p-4 pl-6 sticky left-0"
                    >
                      <Typography className="flex items-center font-bold text-text-primary text-base leading-normal h-[21px]">
                        Profil Kandang
                      </Typography>
                    </th>

                    <th className="flex justify-end  p-4 pl-6 gap-4 sticky right-0 items-center">
                      {user?.role !== "Super Admin" &&
                      detailCage.isActive === true ? (
                        <>
                          <button
                            className="flex justify-center items-center border-2 border-gray-300 text-gray-500 bg-gray-300 bg-opacity-40 p-1 rounded-sm"
                            disabled
                          >
                            <FiEdit className="h-5 w-5" />
                          </button>
                          <button
                            className="flex justify-center items-center border-2 border-gray-300 text-gray-500 bg-gray-300 bg-opacity-40 p-1 rounded-sm"
                            disabled
                          >
                            <FiTrash2 className="h-5 w-5" />
                          </button>
                        </>
                      ) : user?.role === "Super Admin" && detailCage.isActive === true ? (
                        <>
                          <button
                            className="flex justify-center items-center border-2 border-blue-600 text-blue-600 hover:border-blue-700 hover:text-blue-700 p-1 rounded-sm"
                            onClick={() => handleClickEdit(cageID)}
                          >
                            <FiEdit className="h-5 w-5" />
                          </button>
                          <button
                            className="flex justify-center items-center border-2 border-gray-300 text-gray-500 p-1 bg-gray-300 bg-opacity-40 rounded-sm"
                            disabled
                          >
                            <FiTrash2 className="h-5 w-5" />
                          </button>
                        </>
                      ) :  (
                        <>
                          <button
                            className="flex justify-center items-center border-2 border-blue-600 text-blue-600 hover:border-blue-700 hover:text-blue-700 p-1 rounded-sm"
                            onClick={() => handleClickEdit(cageID)}
                          >
                            <FiEdit className="h-5 w-5" />
                          </button>
                          <button
                            className="flex justify-center items-center border-2 border-red-600 text-red-600 hover:border-red-700 hover:text-red-700 p-1 rounded-sm"
                            onClick={() => handleClickDelete(cageID)}
                          >
                            <FiTrash2 className="h-5 w-5" />
                          </button>
                        </>
                      )}
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {table_row_title?.map((val, index) => {
                    const isLast = index === table_row_title.length - 1;
                    const classes = isLast
                      ? "p-4 pl-6"
                      : "p-4 pl-6 border-b border-blue-gray-50";

                    return (
                      <tr key={index}>
                        <td className={`${classes} w-[320px]`}>
                          <Typography className="font-medium text-text-tertiary">
                            {val.title}
                          </Typography>
                        </td>

                        <td className={`${classes} w-[390px] max-w-[390px]`}>
                          <Typography className="font-medium text-text-secondary max-w-[390px] break-words whitespace-pre-line">
                            {val.value}
                          </Typography>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </Card>
          </div>
        </div>
      </div>

      <ConfirmDialog
        isOpen={isDelete}
        onClose={() => setIsDelete(!isDelete)}
        onConfirm={handleDeleteDetailCage}
        title="Hapus Kandang"
        content="Anda yakin ingin menghapus data kandang?"
        btnLabel="Hapus"
        btnType="btnError"
      />

      <EditKandangModal
        isOpen={isEditCage}
        onClose={handleCloseEditDialog}
        cageID={cageID}
      />

      <AlertModal
        isOpen={isWarningDeleteOpen}
        onClose={handleCloseWarningDelete}
        onConfirm={handleCloseWarningDelete}
        title="Tidak bisa menghapus data kandang"
        content={messageError}
        btnLabel="Oke"
        btnType="btnError"
        icon={
          <ExclamationTriangleIcon className="h-[58px] w-[58px] m-xl p-lg text-red-500 bg-red-100 rounded-full" />
        }
      />
    </>
  );
};

const HakAkses = ({
  onClick,
  openModal,
  setOpenModal,
  options,
  handleCancelAddMember,
  handleOnSelect,
  optionUSer,
  handleSelectUser,
  handleClickSave,
  responsePermissions,
  userId,
  handleDeletefetchData,
  loading,
  notif,
  notifEdit,
  selectedRole,
  setNotifAddPermission,
  setSearch,
  search,
  showError,
  messageError,
  setShowErrorMessage,
}) => {
  const Tabble_head = ["Nama", "Email", "Nomor Handphone", "Peran", ""];
  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const [notifDeletePermission, setNotifDeletePermission] = useState(false);
  const [id, setId] = useState(null);
  const [name, setName] = useState(null);
  const [notifEditPermission, setNotifEditPermission] = useState(false);
  const [editAccess, setEditAccess] = useState(false);
  const [editIdPermission, setEditIdPermission] = useState(null);

  const handleDeletePermission = (val) => {
    setId(val.id);

    setOpenDialogDelete(!openDialogDelete);
  };

  const handleDeleteConfirmPermission = async () => {
    try {
      const fetchingDelete = await api.delete(
        `/v1/houses/${userId}/permissions/${id}`
      );
      setOpenDialogDelete(false);
      setNotifDeletePermission(true);
      handleDeletefetchData();
    } catch (err) {
      Sentry.captureException(err);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setNotifDeletePermission(false);
      setNotifEditPermission(false);
      setNotifAddPermission(false);
      setShowErrorMessage(false);
    }, 4000);
  }, [setNotifAddPermission, setShowErrorMessage]);

  const handleClickEditPermission = async () => {
    try {
      const editRole = {
        role: selectedRole.id,
        user: { id: editIdPermission },
      };

      const responseSaveNewAccess = await api.put(
        `/v1/houses/${userId}/permissions`,
        editRole
      );
      setNotifEditPermission(true);
      setEditAccess(false);
      handleDeletefetchData();
    } catch (err) {
      Sentry.captureException(err);
    }
  };

  return (
    <div className="flex h-auto xl:overflow-hidden overflow-auto w-full mt-[45px]">
      <div className="flex flex-col flex-1 overflow-hidden">
        <div className="p-4">
          <Card className="w-full h-auto border shadow-none p-0 m-0">
            <CardHeader
              floated={false}
              shadow={false}
              className="p-0 m-0 border-none w-full"
            >
              <div className="flex justify-between w-[95%] items-center">
                <Typography
                  variant="h2"
                  className="text-xl font-bold p-6 text-text-primary"
                >
                  Hak Akses
                </Typography>

                <div className="mr-[-30px]">
                  <Button onClick={onClick} label="Tambah Anggota" />
                </div>
              </div>
            </CardHeader>

            <CardBody className="p-0 m-0 overflow-scroll max-w-full max-h-[760px]">
              <table className="w-full min-w-max table-auto text-left">
                <thead>
                  <tr>
                    {Tabble_head?.map((head) => {
                      return (
                        <th
                          key={head}
                          className="border-y border-border-secondary bg-neutral-50"
                        >
                          <Typography
                            variant="small"
                            className="flex items-center font-semibold leading-none opacity-70 h-[40px] p-4 text-text-quarterary"
                          >
                            {head}
                            {""}
                          </Typography>
                        </th>
                      );
                    })}
                  </tr>
                </thead>

                {loading ? (
                  <div className="flex justify-center items-center h-screen">
                    <LoadingAnimation />
                  </div>
                ) : (
                  <tbody>
                    {responsePermissions?.map((val, index) => {
                      const isLast = index === responsePermissions.length - 1;
                      const classess = isLast
                        ? "pl-0 p-2"
                        : "pl-0 p-2 border-b border-blue-gray-50";

                      return (
                        <tr key={responsePermissions.id}>
                          <td className={classess}>
                            <Typography className="text-text-primary text-sm font-medium leading-tight p-4">
                              {val?.fullName}
                            </Typography>
                          </td>

                          <td className={classess}>
                            <Typography className="text-text-primary text-sm font-medium leading-tight p-4">
                              {val?.email || "-"}
                            </Typography>
                          </td>
                          <td className={classess}>
                            <Typography className="text-text-primary text-sm font-medium leading-tight p-4">
                              {val?.phoneNumber}
                            </Typography>
                          </td>

                          <td className={classess}>
                            <div className="text-text-primary text-sm font-medium leading-tight p-4">
                              {val.role.labels}
                            </div>
                          </td>

                          <td className={classess}>
                            <Typography className="text-text-primary text-sm font-medium leading-tight cursor-pointer flex gap-2 p-4">

                              {val.role.labels === "Pemilik kandang" ? null : (
                                <div
                                  className="flex"
                                  onClick={() => handleDeletePermission(val)}
                                >
                                  <DeleteAccess />
                                  <div>Delete</div>
                                </div>
                              )}
                            </Typography>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                )}
              </table>
            </CardBody>
          </Card>
        </div>

        <ModalAddMember
          open={openModal}
          setOpen={setOpenModal}
          options={options}
          handleOnSelect={handleOnSelect}
          optionsPeranUser={optionUSer}
          handleSelectPeranUser={handleSelectUser}
          handleOnclickSave={handleClickSave}
          handleOnclickCancel={handleCancelAddMember}
          setSearch={setSearch}
          search={search}
          placeholderUser={"Pilih Anggota"}
          placeholderPermissions={"Pilih peran"}
          valuePeran={optionUSer && optionUSer[1].labels}
        />

        <ModalAddMember
          open={editAccess}
          setOpen={() => setEditAccess(!editAccess)}
          handleOnclickCancel={() => setEditAccess(!editAccess)}
          editMember
          disabled={true}
          placeholderUser={name}
          handleOnclickSave={handleClickEditPermission}
          options={options}
          handleOnSelect={handleOnSelect}
          optionsPeranUser={optionUSer}
          handleSelectPeranUser={handleSelectUser}
          setSearch={setSearch}
          search={search}
        />
      </div>

      <ConfirmDialog
        title="Hapus hak akses anggota"
        content={`Anda yakin ingin menghapus hak akses anggota ?`}
        btnLabel="Hapus"
        btnType="btnError"
        isOpen={openDialogDelete}
        onClose={() => setOpenDialogDelete(!openDialogDelete)}
        onConfirm={handleDeleteConfirmPermission}
      />

      {notif && (
        <AlertNotification
          icon={<FaCheckCircle />}
          color="green"
          message="Data Hak Akses berhasil ditambah."
        />
      )}

      {notifEdit && (
        <AlertNotification
          icon={<FaCheckCircle />}
          color="green"
          message="Data Hak Akses berhasil diubah."
        />
      )}

      {showError && (
        <AlertNotification
          icon={<FaCheckCircle />}
          color="red"
          message={messageError}
        />
      )}

      {notifDeletePermission && (
        <AlertNotification
          icon={<FaCheckCircle />}
          color="green"
          message="Data Hak Akses berhasil dihapus."
        />
      )}
    </div>
  );
};
