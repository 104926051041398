import { useEffect, useRef, useState } from "react";
import BreadCrumbs from "../../atoms/Breadcrumbs";
import profileImage from "../../img/profil_default.jpg";
import { ReactComponent as ArrowDown } from '../../assets/icons/arrowDown.svg'
import {  useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { LogOut } from "../features/authSlice";
import { setActiveMenu } from "../features/menuSlice";
import Cookies from "js-cookie";
import { persistor } from '../../app/store';
import {
  resetFilters,
} from "../features/filterRearingPeriodSlice";

const Header = ({
  breadcrumbData
}) => {
  const [openDropdown, setOpenDropdown] = useState(false)
  const dropdownRef = useRef(null)
  const dispatch = useDispatch()
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate()
  const { user } = useSelector(
    (state) => state.auth
  )
  const isSidebarOpen = useSelector((state) => state.menu.isSidebarOpen);

  const menuDropdown = [
    // {id: 1, label: "Profile",  link: '/profile'},
    {id: 2, label: "Logout",}
  ]

  const handleLogout = async () => {
    dispatch(resetFilters());
    persistor.flush(); 
    dispatch(setActiveMenu("dashboard"));
    if (!isSubmitting) {
      setIsSubmitting(true)
      dispatch(LogOut())
      const token = Cookies.get('accessToken');
      if(!token){
        navigate('/')
      }
      
    }
  }


  const handleClick = (val) => {
    if (val === 1) {
      return null
    } else if (val === 2) {
      handleLogout()
    } else {
      return null
    }
  }  

  // const closeDropdown = () => {
  //   setOpenDropdown(false)
  // }

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        setOpenDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


  return (
    <div className={`px-4 py-3 bg-white border-b border-gray-200 flex justify-between items-center fixed z-40 ${
      isSidebarOpen === true || isSidebarOpen === "true"
        ? " md:w-[calc(100%-250px)] lg:w-[calc(100%-300px)]"
        : " md:w-[calc(100%-50px)] lg:w-[calc(100%-70px)]"
    }`}>

      <div className="flex items-center">
        <BreadCrumbs items={breadcrumbData}/>
      </div>


      <div className="flex items-center justify-between">
        <div className="flex px-md items-center hover:cursor-pointer" onClick={() => setOpenDropdown(!openDropdown)}>
          <img
            src={profileImage}
            alt="Profile"
            className="w-8 h-8 rounded-full mr-2"
          />

          <div>
            <div className="text-md font-semibold text-gray-800">{user?.role?.name}</div>
            <div className="text-sm text-gray-800">{user?.fullName}</div>
          </div>
        </div>
        <div 
          ref={dropdownRef}
          className="flex px-md items-center relative z-500 hover:cursor-pointer"
          onClick={() => setOpenDropdown(!openDropdown)}
        >
          <ArrowDown/>
        </div>
        {openDropdown && (
          <div 
            className="absolute origin-top-right right-4 z-40 w-48 mt-[95px] rounded-md shadow-lg bg-white border border-border-secondary"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="options-menu"
            ref={dropdownRef}
          >
            <div className="py-1" role="none">
              {menuDropdown?.map((val) => (
                <div 
                  key={val}
                  className="flex relative z-30 items-center px-4 py-2 text-sm text-gray-700 cursor-pointer hover:bg-gray-100 justify-between "
                  style={{ maxHeight: '12rem' }}
                  role="menuitem"
                  onClick={() => handleClick(val.id)}
                >
                  <div className="text-left" onClick={() => handleClick(val.id)}>
                    {val.label}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
