import { Alert } from "@material-tailwind/react";
import classNames from "classnames";

import { ReactComponent as DoneIcon } from "../assets/icons/done-icon.svg";
import { ReactComponent as WarningIcon } from "../assets/icons/warning-alert.svg";

const colorTypes = {
  outlineWarning: `bg-bg-warningPrimary border border-button-warningBorder`,
  outlineInfo: `bg-bg-infoPrimary border border-button-infoBorder`,
};

const AlertWarning = ({ icon, type, children }) => {
  const alertIcon = {
    warning: <WarningIcon className="h-6 w-6" />,
    done: <DoneIcon className="h-6 w-6" />,
  };

  const alertClasses = classNames(colorTypes[type] || colorTypes.outlineInfo);

  const selectedIcon = alertIcon[icon] || "";

  return (
    <Alert
      icon={selectedIcon}
      className={`${alertClasses} justify-start rounded-md p-xl`}
    >
      <div>{children}</div>
    </Alert>
  );
};

export default AlertWarning;
