import { createSlice } from "@reduxjs/toolkit";

const savedValueSlice = createSlice({
  name: 'saveValue',
  initialState: {
    value: 0
  },

  reducers: {
    saveValue(state, action) {
      state.value = action.payload
    }
  }
})

export const { saveValue } = savedValueSlice.actions;
export default savedValueSlice.reducer