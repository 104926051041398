/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Typography,
} from "@material-tailwind/react";
import { ReactComponent as ChevronLeft } from "../../assets/icons/chevron-left.svg";
import CustomDatePicker from "../../atoms/DatepickerForm";
import { useEffect, useState } from "react";
// import Input from "../../atoms/Input";
// import FormDropdownSearch from "../../atoms/FormDropdownSearch";
import { useNavigate, useParams } from "react-router-dom";
import { ReactComponent as PlusBlus } from "../../assets/icons/plus_blue.svg";
import { Button } from "../../atoms/Button";
import api from "../../api/configApi";
import "moment/locale/id";
import moment from "moment";
import DropdownSapronak from "../../atoms/DropdownSapronak";
import ConfirmDialog from "../../atoms/ConfirmDialog";
import InputNumber from "../../atoms/NumberFormat";
import DefaultImageOvk from "../../img/OVK.png";

const FormOvk = () => {
  const [formData, setFormData] = useState([{ qtyIn: "", price: "" }]);
  const navigate = useNavigate();
  const { id } = useParams();
  const [selectDate, setSelectdate] = useState(null);
  const [responeOptions, setResponseOptions] = useState(null);
  const [successNotif, setSuccessNotif] = useState(false);
  // const [search, setSearch] = useState("");
  const [openConfirm, setOpenConfirm] = useState(false);
  const [selectId, setSelectId] = useState([]);
  const [error, setError] = useState("");
  const [showError, setShowError] = useState(false);
  const [searchProduct, setSearchProduct] = useState([""]);
  const [indexActive, setIndexActive] = useState(0);
  const disabled = formData.map(
    (form) => !form.ovk || !form.qtyIn || !form.price || !selectDate
  );

  const handleAddForm = () => {
    setFormData((prevFormdata) => [...prevFormdata, { qtyIn: "", price: "" }]);
  };

  const handleDeleteForm = (index) => {
    // console.log("index: ", index);
    // Menghapus item dari formData dengan indeks yang dipilih
    setFormData((prevFormData) => prevFormData.filter((_, i) => i !== index));

    // Menghapus item dari selectId dengan indeks yang dipilih
    setSelectId((prevSelectId) => prevSelectId.filter((_, i) => i !== index));
  };

  // const handleDeleteForm = (index) => {
  //   // Salin array formData ke updatedList
  //   const updatedList = [...formData];
  //   // Hapus elemen pada indeks yang dipilih dari updatedList
  //   updatedList.splice(index, 1);
  //   // Perbarui state formData dengan updatedList
  //   setFormData(updatedList);

  //   // Menghapus nilai productIdExclude pada indeks yang sesuai
  //   const updatedIdExclude = [...selectId];
  //   updatedIdExclude.splice(index, 1);
  //   setSelectId(updatedIdExclude);
  // };

  const handleChange = (event, index, field) => {
    setFormData((prevFormdata) => {
      const newFormData = [...prevFormdata];
      let updatedValue = event.target.value;

      // Jika field adalah qtyIn, ubah ke integer dan hapus tanda "."
      if (field === "qtyIn" || field === "price") {
        updatedValue = parseInt(event.target.value.replace(/\./g, ""), 10);
      }

      newFormData[index][field] = updatedValue;

      return newFormData;
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log(formData);
  };

  useEffect(() => {
    const dataFetchingListProduk = async () => {
      try {
        const responseDropdown = await api.get("/v1/ovks/dropdown", {
          params: {
            search: searchProduct[indexActive],
            exclude: selectId.join(","),
          },
        });
        setResponseOptions(responseDropdown?.data);
      } catch (error) {
        console.log(error);
      }
    };

    dataFetchingListProduk();
  }, [searchProduct, indexActive, selectId]);

  const handleSelect = (event, index) => {
    // Tambahkan id produk feed yang dipilih ke dalam string
    const selectedFeedId = event.id.toString();

    setSelectId((prevIds) => {
      const newIds = [...prevIds];
      newIds[index] = selectedFeedId;
      return newIds;
    });

    setFormData((prevFormData) =>
      prevFormData.map((item, idx) => ({
        ...item,
        ovk: {
          ...item.ovk,
          id: idx === index ? event.id : item.ovk.id,
        },
      }))
    );

    // const updateSelectedId = selectId?.includes(event.id)
    //   ? selectId.filter((select) => select !== event.id)
    //   : [...selectId, event.id];
    // setSelectId(updateSelectedId);

    // setFormData((prevformData) =>
    //   prevformData.map((item) => ({
    //     ...item,
    //     ovk: { id: event.id },
    //   }))
    // );
  };

  const handleSetSearchProduct = (value, index) => {
    setSearchProduct((prevSearchProduct) => {
      const newSearchProduct = [...prevSearchProduct];
      newSearchProduct[index] = value;
      return newSearchProduct;
    });
    setIndexActive(index);
    // handleSetActiveIndex(index);
  };

  const handleChangeAddOvk = async () => {
    let reqBody = {
      ovkInList: formData,
      rearingPeriod: { id: id },
      dateIn: moment(selectDate?.$d).format("YYYY-MM-DD"),
    };

    try {
      const addFeedRequest = await api.post("v1/ovks/in/bulk", reqBody);
      setSuccessNotif(true);
      localStorage.setItem("successNotifAddOVK", JSON.stringify(!successNotif));
      navigate(`/periode-budidaya/periode-berjalan/${id}/sapronak`);
      // console.log(addFeedRequest, "response");
      localStorage.setItem("settingType", "sapronak");
      localStorage.setItem("settingTypeSapronak", "ovk");
    } catch (error) {
      console.log(error);
      setShowError(!showError);
      setError(error.message);
    }
  };

  const handleBack = () => {
    navigate(`/periode-budidaya/periode-berjalan/${id}/sapronak`);
    localStorage.setItem("settingType", "sapronak");
    localStorage.setItem("settingTypeSapronak", "ovk");
  };

  const disableDate = (current) => {
    return current && current.isAfter(new Date(), "day");
  };

  // console.log("form data :", formData);
  // console.log("selected id:" + selectId);

  return (
    <div className="flex justify-center item-center xl:overflow-hidden overflow-hidden">
      <div className="flex flex-col flex-1 overflow-hidden">
        <div>
          <Card className="w-max-md flex justify-center item-center border border-border-secondary shadow-none m-0 p-0">
            <CardHeader
              floated={false}
              shadow={false}
              className="p-3xl m-0 border-none"
            >
              <div className="flex justify-start">
                <div className="mr-sm cursor-pointer" onClick={handleBack}>
                  <ChevronLeft />
                </div>
                <Typography className="text-xl text-text-primary font-bold leading-tight">
                  Tambah OVK
                </Typography>
              </div>
            </CardHeader>

            <CardBody className="p-3xl m-0">
              <div>
                <Typography className="text-text-primary text-sm font-semibold leading-tight">
                  Tanggal Masuk
                </Typography>

                <CustomDatePicker
                  placeholder="Pilih Tanggal"
                  className="w-full text-text-primarypx-sm py-xs justify-start items-center gap-xxs inline-flex focus:outline-none focus:border-primary-400 focus:ring focus:ring-primary-200 mt-3"
                  onChange={(date) => setSelectdate(date)}
                  value={selectDate}
                  format={"DD MMMM YYYY"}
                  disableDate={() => false}
                />
              </div>

              <hr className="border-t border-border-secondary mx-1 my-3xl" />

              <div>
                <div className="mx-auto w-full bg-bg-secondary border border-border-primary rounded-sm">
                  <div className="p-3xl">
                    <form onSubmit={handleSubmit}>
                      {formData?.map((form, index) => (
                        <>
                          <div className="flex justify-between" key={form.id}>
                            <div>
                              <Typography className="text-text-primary font-semibold text-sm leading-tight">
                                Produk
                              </Typography>
                            </div>
                            {index !== 0 && (
                              <div>
                                <div
                                  className="flex justify-start gap-2 cursor-pointer mr-1"
                                  onClick={() => handleDeleteForm(index)}
                                >
                                  <div>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="16"
                                      height="16"
                                      viewBox="0 0 16 16"
                                      fill="none"
                                    >
                                      <path
                                        d="M6 2H10M2 4H14M12.6667 4L12.1991 11.0129C12.129 12.065 12.0939 12.5911 11.8667 12.99C11.6666 13.3412 11.3648 13.6235 11.0011 13.7998C10.588 14 10.0607 14 9.00623 14H6.99377C5.93927 14 5.41202 14 4.99889 13.7998C4.63517 13.6235 4.33339 13.3412 4.13332 12.99C3.90607 12.5911 3.871 12.065 3.80086 11.0129L3.33333 4M6.66667 7V10.3333M9.33333 7V10.3333"
                                        stroke="#D92D20"
                                        stroke-width="1.33333"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                    </svg>
                                  </div>

                                  <div>
                                    <Typography className="text-[#D92D20] text-sm font-medium leading-tight">
                                      Hapus{" "}
                                    </Typography>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>

                          <div className="mt-md">
                            <DropdownSapronak
                              size="medium"
                              placeholder="Pilih OVK"
                              options={responeOptions?.contents}
                              onSelect={(selectedOvk) =>
                                handleSelect(selectedOvk, index)
                              }
                              search={searchProduct[index]}
                              defaultProduct={DefaultImageOvk}
                              setSearch={(value) =>
                                handleSetSearchProduct(value, index)
                              }
                            />

                            <div className="flex justify-between w-full my-3xl p-1 gap-4">
                              <div className="w-[100%]">
                                <Typography className="text-text-primary text-sm font-semibold leading-tight mb-md">
                                  Kuantitas
                                </Typography>

                                <InputNumber
                                  type="text"
                                  placeholder="Masukan Kuantitas"
                                  size="medium"
                                  className="w-[120px]"
                                  value={form.valueKuantitas}
                                  onChange={(e) =>
                                    handleChange(e, index, "qtyIn")
                                  }
                                  componentRight={
                                    <Typography className="text-gray-500 text-md font-medium leading-tight">
                                      Item
                                    </Typography>
                                  }
                                />
                              </div>

                              <div className="w-[100%]">
                                <Typography className="text-text-primary text-sm font-semibold leading-tight mb-md">
                                  Harga satuan
                                </Typography>

                                <InputNumber
                                  type="text"
                                  placeholder="Masukan Harga Satuan"
                                  size="medium"
                                  className="w-[120px]]"
                                  value={form.valueHarga}
                                  onChange={(e) =>
                                    handleChange(e, index, "price")
                                  }
                                  componentLeft={
                                    <Typography
                                      type="small"
                                      className="text-gray-500 text-md font-medium leading-tight"
                                    >
                                      Rp
                                    </Typography>
                                  }
                                />
                              </div>
                            </div>
                          </div>

                          <hr className="border-t border-gray-300 my-3xl w-full" />
                        </>
                      ))}

                      <div
                        className="flex justify-start gap-1 cursor-pointer w-[20%]"
                        onClick={handleAddForm}
                      >
                        <div>
                          <PlusBlus />
                        </div>

                        <div>
                          <Typography className="text-[#1570EF] text-base font-medium leading-tight mt-[0.5px]">
                            Tambah item
                          </Typography>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </CardBody>
            <hr className="border-t border-gray-300 w-full my-4" />

            <CardFooter className="flex justify-end border-t border-gray-50 gap-4 mr-4">
              <Button
                label="Batal"
                type="btnSecondary"
                size="medium"
                onClick={handleBack}
              />

              <Button
                label="Simpan"
                size="medium"
                type={disabled[0] ? "btnPrimaryDisabled" : "btnPrimary"}
                disabled={disabled[0]}
                onClick={() => setOpenConfirm(!openConfirm)}
              />
            </CardFooter>
          </Card>

          <ConfirmDialog
            title="Konfirmasi Simpan data OVK"
            content="Pastikan Semua informasi sudah benar sebelum melanjutkan"
            isOpen={openConfirm}
            onClose={() => setOpenConfirm(!openConfirm)}
            btnLabel="Simpan"
            onConfirm={handleChangeAddOvk}
          />
        </div>
      </div>
    </div>
  );
};

export default FormOvk;
