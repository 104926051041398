/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Typography,
} from "@material-tailwind/react";
import { useEffect, useState } from "react";
import api from "../../api/configApi";
import CustomDatePicker from "../../atoms/DatepickerForm";
import { Button } from "../../atoms/Button";
import "moment/locale/id";
import moment from "moment";
import ConfirmDialog from "../../atoms/ConfirmDialog";
import AlertNotification from "../../atoms/AlertNotification";
import { FaCheckCircle } from "react-icons/fa";
import InputNumber from "../../atoms/NumberFormat";
import Dropdown from "../../atoms/DropdownWithReset";
import Input from "../../atoms/Input";
import { ReactComponent as ArrowIncrement } from '../../assets/icons/arrow-increment.svg'
import { ReactComponent as ArrowDecrement } from '../../assets/icons/arrow-decrement.svg'

moment.locale("id");

const AddPeriod = ({ isOpen, onClose, setReload }) => {
  const [responseDoc, setResponseDoc] = useState(null);
  const [selectedDoc, setSelectedDoc] = useState(null);
  const [selectCage, setSelectCage] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [docPopulation, setDocPopulation] = useState("");
  const [docPrice, setDocPrice] = useState("");
  const [period, setPeriod] = useState(0);
  const [sequencePeriod, setSequencePeriod] = useState("");
  const [docWeight, setDocWeight] = useState("");
  const [isSubmiting, setIsSubmitting] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [cageDropdown, setCageDropdown] = useState(null);
  const [isSuccsess, setIssuccess] = useState(false);
  const [validationPopulationError, setValidationPopulationError] = useState("");
  const [validationDocWeightError, setValidationDocWeightError] = useState("");
  const [validationDocPriceError, setValidationDocPriceError] = useState("");
  const [error, setError] = useState("");
  const [statusError, setStatusError] = useState(false);
  const [searchKandang, setSearchKandang] = useState("");
  const [searchDoc, setSeacrhDoc] = useState("");
  const [selectOptionCage, setSelectOptionCage] = useState(null)
  const [selectOptionDoc, setSelectOtionDoc] = useState(null)
  const [idAnalytic, setIdAnalytic] = useState("");
  const [fetchTrigger, setFetchTrigger] = useState(false)
  const [showError, setShowError] = useState(false)
  const [codePeriode, setCodePeriode] = useState("")
  const [dateDocIn, setDateDocIn] = useState(null)
  const [sequencePeriode, setSequencePeriode] = useState(1)
  const [temporarySequenceCode, setTemporarySequenceCode] = useState([])
  const [codeMerge, setCodeMerge] = useState("")

  useEffect(() => {
    const responseDropdownDoc = async () => {
      try {
        const Responsedoc = await api.get("/v1/docs/dropdown", {
          params: {
            search: searchDoc,
          },
        });
        setResponseDoc(Responsedoc?.data?.contents);
      } catch (err) {
        console.log(err);
      }
    };

    const dropdownCage = async () => {
      try {
        const responseCageDropdown = await api.get("/v1/houses/dropdown", {
          params: {
            isActive: false,
            search: searchKandang,
          },
        });
        setCageDropdown(responseCageDropdown?.data?.contents);
      } catch (error) {
        console.log(error);
      }
    };

    dropdownCage();
    responseDropdownDoc();
  }, [searchKandang, searchDoc, selectOptionCage]);

  useEffect(() => {
    if (selectCage?.id?.length > 0) {
      const checkLastPeriod = async () => {
        try {
          const lastPeriod = await api.get("/v1/rearingPeriods/nextPeriod", {
            params: {
              houseId: selectCage?.id,
            },
          });
          setSequencePeriod(lastPeriod?.data?.sequence);
          if (lastPeriod?.data?.sequence > 1) {
            setPeriod(lastPeriod?.data?.sequence);
          } else {
            setPeriod("");
          }
        } catch (error) {
          console.log(error);
        }
      };

      checkLastPeriod();
    }
  }, [selectCage]);

 

  const handleClose = () => {
    setSelectCage(null);
    setSelectedDoc(null);
    setDocPopulation("");
    setDocPrice("");
    setDocWeight("");
    setSelectedEndDate("");
    setSelectOptionCage(null);
    setSelectOtionDoc(null);
    setIdAnalytic("");
    setDateDocIn("")
    setCodePeriode("")
    setSequencePeriode("")
    setCodeMerge("")
    setSequencePeriode(1)
    setShowError(false)
    onClose();
  };


  const handleCreatePeriod = async () => {
    setIsSubmitting(true);

    try {
      const addPeriod = {
        sequence: sequencePeriode,
        house: { id: selectCage?.id },
        docInPopulation: parseInt(docPopulation.replace(".", "")),
        docInWeight: parseInt(docWeight.replace(".", "")),
        pricePerChick: parseInt(docPrice.replace(".", "")),
        docInDate: moment(dateDocIn?.$d).format("YYYY-MM-DD"),
        doc: { id: selectedDoc?.id },
        analyticAccount: idAnalytic,
      };

      const response = await api.post(`v1/rearingPeriods`, addPeriod);
      console.log("response", response)
      window.location.reload();
      setReload(true);
      setOpenDialog(false);
      setIssuccess(true);
      localStorage.setItem("response success", JSON.stringify(isSuccsess));
      // return response

    } catch (error) {
      console.log(error.response?.data?.error);
      setError(error.response?.data?.error);
      setStatusError(true);
      setOpenDialog(false);
      setDocPopulation("");
      setIsSubmitting(false);

      setSelectCage(null);
      setSelectedDoc(null);
      setDocPopulation("");
      setDocPrice("");
      setDocWeight("");
      setSelectedEndDate("");
      setSelectOptionCage(null);
      setSelectOtionDoc(null);
      setIdAnalytic("");
      setCodeMerge("")
      setSequencePeriode(1)
      setShowError(false)
      onClose();


      setTimeout(() => setStatusError(false), 2000);
    }
  };

  const handleSelectDoc = (val) => {
    const select = val
    setSelectedDoc(val);
    setSelectOtionDoc(select)
  };

  const handleChangeEndDate = (date) => {
    setSelectedEndDate(date);
  };

  const handleDocIndate = (date) => {
    console.log("select", date)
    setDateDocIn(date)
  }

  const handleSelectCage = (cage) => {
    if (selectOptionCage?.id !== null) {
      setFetchTrigger(true)
    }
    const select = cage
    setSelectCage(cage);
    setSelectOptionCage(select)
  };

  useEffect(() => {

    if (fetchTrigger) {
      const fetchingCodeSequence = async () => {
        try {
          const response = await api.get(`/v1/rearingPeriods/sequences`, {
            params: {
              houseId: selectOptionCage.id
            }
          })
          setCodePeriode(response?.data?.code )
          setCodeMerge(`${
            `${response?.data?.code+String(response?.data?.nextSequence)}`
          }`)
          setSequencePeriode(response?.data?.nextSequence)
          setTemporarySequenceCode(response?.data?.registeredSequences)
        } catch (error) {
          console.log("error", error)
        }
      }

      fetchingCodeSequence()
    }
  }, [selectOptionCage, fetchTrigger])


  const handlePopulationChange = (e) => {
    setDocPopulation(e.target.value);
  };

  const handlePeriodChange = (e) => {
    setPeriod(e.target.value);
  };

  const handleWeightChange = (e) => {
    setDocWeight(e.target.value);
  };

  const handleDocPriceChange = (e) => {
    setDocPrice(e.target.value);
  };

  const handleIdAnalyticChange = (e) => {
    let newID = e.target.value;

    if (newID.length < 25) {
      setIdAnalytic(newID);
    }
  };
  
  const date = selectedEndDate ? moment(selectedEndDate?.$d).format("YYYY-MM-DD") : null;
  const disabled =
    !selectCage?.id ||
    !docPopulation ||
    !codePeriode ||
    !docWeight ||
    !docPrice ||
    !dateDocIn ||
    // !date ||
    !selectedDoc.id;

    useEffect(() => {
      if (isOpen) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "";
      }
    
      return () => {
        document.body.style.overflow = "";
      };
    }, [isOpen]);

    const incrementValue = () => {
      setSequencePeriode(prevValue => {
        const newValue = Number(prevValue) + 1
        checkDuplicateDataSequence(newValue)
        setCodeMerge(`${codePeriode}${String(newValue)}`)
        return newValue
      })
    }
  
    const decrementValue = () => {
      setSequencePeriode(prevValue => {
        if (prevValue > 0) {
          const newValue = prevValue - 1
          checkDuplicateDataSequence(newValue)
          setCodeMerge(`${codePeriode}${String(newValue)}`)
          return newValue
        }
      })
    }

    const handleChangeNotNull = (e) => {
      let inputValue = e.target.value;
      let newValue = inputValue === "" ? "" : parseInt(inputValue, 10);
  
      if (isNaN(newValue) || newValue < 0) {
        newValue = 0;
      } else if (newValue < 0 && inputValue !== "") {
        newValue = 0;
      }
  
      setSequencePeriode(newValue);
      setCodeMerge(`${codePeriode}${String(newValue)}`)
  
      if (newValue > 0) {
        checkDuplicateDataSequence(newValue);
      }
    };

    const checkDuplicateDataSequence = (sequence) => {
      if (sequence !== "" && Array.isArray(temporarySequenceCode) && temporarySequenceCode.includes(sequence)) {
        setShowError(true)
      } else {
        setShowError(false)
      }
    }


  return (
    <div>
      {/* Modal Overlay */}
      {isOpen && <div className="fixed inset-0 bg-gray-800 z-[120] opacity-50"></div>}
      <Card
        className={`fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 max-h-[770px] overflow-y-auto ${
          isOpen ? "block" : "hidden"
        } rounded-xl flex-col justify-start items-start z-[150] mb-4xl`}
        style={{ width: "600px" }}
      >
        <Card>
          <CardHeader
            floated={false}
            shadow={false}
            className="border-b border-blue-gray-50 rounded-b-none p-0 m-0 flex justify-between"
          >
            {/* Modal Title */}
            <Typography
              type="h2"
              className="text-xl text-text-primary font-bold p-4"
            >
              Mulai Periode
            </Typography>
            <button className="text-neutral-400 pr-4" onClick={handleClose}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </CardHeader>
          <CardBody className="p-5">
            <div className="grid grid-cols-1 gap-3xl">
              <Typography
                className="text-text-primary text-sm font-medium leading-tight"
              >
                Kandang <span className="text-red-600">*</span>
                <div className="mt-2">
                  <Dropdown 
                    placeholder="Pilih Kandang"
                    options={cageDropdown}
                    onSelect={handleSelectCage}
                    selectedOption={selectOptionCage}
                  />
                </div>
              </Typography>
            </div>

            {/* <div className="grid grid-cols-1 my-5">
              <Typography className="text-text-primary text-sm font-semibold leading-tight">
                ID Analytic:{" "}
                <span className="text-red-500 text-sm font-normal leading-tight">
                  *
                </span>
              </Typography>
              <Input
                type="text"
                id="idAnalytic"
                placeholder="Masukkan ID Analytic"
                className="mt-md placeholder:text-sm"
                value={idAnalytic}
                size="medium"
                onChange={handleIdAnalyticChange}
              />
            </div> */}

            <div className="flex justify-between w-full mt-[12px] gap-3 my-6">
              <div className="w-[50%] mt-[2px]">
                <Typography className="text-sm font-semibold text-text-primary pt-6">
                  Periode <span className="text-red-600">*</span>
                </Typography>

                <div className="mt-2">
                  <Input
                    type="number"
                    id="cageNumber"
                    value={sequencePeriode}
                    onChange={handleChangeNotNull}
                    placeholder="Periode"
                    className={`max-w-full placeholder:text-text-placeholder appearance-none ${showError ? "border-red-500" : ""}`}
                    size="medium"
                    componentRight={
                      <div className="flex flex-col absolute right-[-7px] justify-between items-center w-[30px] h-full bg-[#EAEAEB] rounded-r-lg ml-[12px]">
                        <button onClick={incrementValue}  className="focus:outline-none">
                          <ArrowIncrement className="h-5 w-5" />
                        </button>
                        <button onClick={decrementValue}  className="focus:outline-none">
                          <ArrowDecrement className="h-5 w-5" />
                        </button>
                      </div>
                    }
                  />

                {showError && (
                  <div className="pt-2">
                    <Typography className="text-sm text-text-errorPrimary w-full md:w-[240px] h-[30px] text-start">
                      Kode periode telah digunakan
                    </Typography>
                  </div>
                )}
                </div>
              </div>

              <div className="w-[50%] mt-[37px]">
                <Typography
                  className="text-text-primary text-sm font-medium leading-tight"
                >
                  Kode periode <span className="text-red-600">*</span>
                  <Input
                    type="number"
                    id="name"
                    value={codeMerge}
                    placeholder={codeMerge}
                    className="w-[490px] placeholder:text-text-placeholder appearance-none"
                    size="medium"
                    disabled={true}
                  />
                </Typography>
              </div>
            </div>

            <div className="w-full h-px flex-col justify-center items-center inline-flex mt-[-25px]">
              <div className="w-full h-px relative bg-gray-200" />
            </div>

            <div className="grid grid-cols-1 gap-3xl my-6 mt-[8px]">
              <Typography
                className="text-text-primary text-sm font-medium leading-tight"
              >
                Jenis DOC <span className="text-red-600">*</span>
                <div className="mt-md">
                  <Dropdown 
                    placeholder="Pilih DOC"
                    options={responseDoc}
                    onSelect={handleSelectDoc}
                    selectedOption={selectOptionDoc}
                  />
                </div>
              </Typography>
            </div>
            <div className="grid grid-cols-1 gap-3xl my-6">
              <div>
                <Typography
                  className="text-text-primary text-sm font-medium leading-tight"
                >
                  Populasi DOC in (Ekor) <span className="text-red-600">*</span>
                  <InputNumber
                    id="population"
                    placeholder="Masukkan populasi"
                    className="max-w-full mt-md"
                    value={docPopulation}
                    size="medium"
                    onChange={handlePopulationChange}
                  />
                  {validationPopulationError && (
                    <p className="text-red-500 text-sm mt-2">
                      {validationPopulationError}
                    </p>
                  )}
                </Typography>
              </div>
            </div>
            <div className="grid grid-cols-1 gap-3xl my-6">
              <div>
                <Typography
                  className="text-text-primary text-sm font-medium leading-tight"
                >
                  Bobot awal (Gram) <span className="text-red-600">*</span>
                  <InputNumber
                    type="text"
                    id="docWeight"
                    placeholder="Masukan bobot awal"
                    className="max-w-full mt-md"
                    value={docWeight}
                    size="medium"
                    onChange={handleWeightChange}
                  />
                  {validationDocWeightError && (
                    <p className="text-red-500 text-sm mt-2">
                      {validationDocWeightError}
                    </p>
                  )}
                </Typography>
              </div>
            </div>
            <div className="grid grid-cols-1 gap-3xl my-6">
              <div>
                <Typography
                  className="text-text-primary text-sm font-medium leading-tight"
                >
                  Harga per ekor (Rupiah){" "}
                  <span className="text-red-600">*</span>
                  <InputNumber
                    type="text"
                    id="docPrie"
                    placeholder="Masukkan harga"
                    className="max-w-full mt-md"
                    value={docPrice}
                    size="medium"
                    onChange={handleDocPriceChange}
                  />
                  {validationDocPriceError && (
                    <p className="text-red-500 text-sm mt-2">
                      {validationDocPriceError}
                    </p>
                  )}
                </Typography>
              </div>
            </div>

            <div className="grid grid-cols-1 gap-3xl my-6">
              <div>
                <Typography className="text-text-primary text-sm font-medium leading-tight">
                  Tanggal DOC in (Umur 1 hari) <span className="text-red-600">*</span>
                  <CustomDatePicker
                    placeholder="Pilih Tanggal"
                    className="w-full text-text-primary px-sm py-xs justify-start items-center gap-xxs inline-flex focus:outline-none focus:border-primary-400 focus:ring focus:ring-primary-200 mt-md h-[42px]"
                    onChange={handleDocIndate}
                    // value={selectedEndDate}
                    value={dateDocIn}
                    format={"DD MMMM YYYY"}
                    size="md"
                    disableDate={() => false}
                    // defaultValue={selectedEndDate}
                    // value={selectedEndDate}
                  />
                </Typography>
              </div>
            </div>

            {/* <div className="grid grid-cols-1 gap-3xl my-6">
              <div>
                <Typography className="text-text-primary text-sm font-medium leading-tight">
                  Tanggal mulai budidaya <span className="text-red-600">*</span>
                  <CustomDatePicker
                    placeholder="Pilih Tanggal"
                    className="w-full text-text-primary px-sm py-xs justify-start items-center gap-xxs inline-flex focus:outline-none focus:border-primary-400 focus:ring focus:ring-primary-200 mt-md h-[42px]"
                    onChange={handleChangeEndDate}
                    // value={selectedEndDate}
                    value={selectedEndDate}
                    format={"DD MMMM YYYY"}
                    size="middle"
                    // defaultValue={selectedEndDate}
                    // value={selectedEndDate}
                  />
                </Typography>
              </div>
            </div> */}
          </CardBody>

          <CardFooter className="flex justify-end border-t border-gray-50 p-lg mt-[-20px]">
            <Button
              label="Batal"
              type="btnSecondary"
              size="medium"
              className="mr-md"
              onClick={handleClose}
            />

            <Button
              label="Simpan"
              size="medium"
              disabled={disabled}
              type={`${
                isSubmiting || disabled ? "btnPrimaryDisabled" : "btnPrimary"
              }`}
              onClick={() => setOpenDialog(!openDialog)}
              componentLeft={
                isSubmiting && (
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    className="animate-spin fill-neutral-400"
                  >
                    <path
                      d="M10.72,19.9a8,8,0,0,1-6.5-9.79A7.77,7.77,0,0,1,10.4,4.16a8,8,0,0,1,9.49,6.52A1.54,1.54,0,0,0,21.38,12h.13a1.37,1.37,0,0,0,1.38-1.54,11,11,0,1,0-12.7,12.39A1.54,1.54,0,0,0,12,21.34h0A1.47,1.47,0,0,0,10.72,19.9Z"
                      className="spinner_6kVp"
                    />
                  </svg>
                )
              }
            />
          </CardFooter>
        </Card>
      </Card>

      <ConfirmDialog
        title="Konfirmasi Simpan Data Periode Budidaya"
        content="Pastikan Semua informasi sudah benar sebelum melanjutkan"
        isOpen={openDialog}
        onConfirm={handleCreatePeriod}
        onClose={() => setOpenDialog(false)}
        btnLabel="Simpan"
      />

      {statusError && (
        <AlertNotification
          icon={<FaCheckCircle />}
          color="red"
          message={error}
        />
      )}
    </div>
  );
};

export default AddPeriod;
