import {
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";
import { FaWindowClose } from "react-icons/fa";
import PropTypes from "prop-types";
import { Button } from "./Button";

const AlertModal = ({
  isOpen,
  onClose,
  onConfirm,
  title,
  content,
  btnLabel,
  className,
  btnType,
  disabled,
  icon,
}) => {
  return (
    <>
      <Dialog
        size="sm"
        open={isOpen}
        toggler={() => onClose()}
        className="text-center"
      >
        <div className="text-right">
        <button
          className="top-0 right-0 p-1 rounded-full shadow-none"
          onClick={() => onClose()}
        >
          <FaWindowClose className="h-7 w-7 hover:text-gray-800" />
        </button>
        </div>

        <DialogHeader toggler={() => onClose()} className="grid grid-col-1 p-0 m-0">
          <div className="flex justify-center mb-2">{icon}</div>
          <div className="text-[22px]">{title}</div>
        </DialogHeader>
        <DialogBody className="mt-[-10px] mx-[10px] text-neutral-700">{content}</DialogBody>
        <DialogFooter className="mx-md mb-md mt-[-15px]">
          <Button
            label={btnLabel}
            size="medium"
            type={btnType}
            onClick={() => onConfirm()}
            disabled={disabled}
            className={"w-full"}
          />
        </DialogFooter>
      </Dialog>
    </>
  );
};

AlertModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  title: PropTypes.string,
  content: PropTypes.string,
};

export default AlertModal;
