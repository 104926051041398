/* eslint-disable no-unused-vars */
import { Card, CardBody, CardFooter, CardHeader, Switch, Typography } from "@material-tailwind/react"
import { ReactComponent as ChevronLeft } from "../../../../assets/icons/chevron-left.svg";
import Input from "../../../../atoms/Input";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import api from "../../../../api/configApi";
import { Button } from "../../../../atoms/Button";
import Dropdown from "../../../../atoms/DropdownWithReset";
import ConfirmDialog from "../../../../atoms/ConfirmDialog";

const EditRegion = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const [isActive, setActive] = useState(true);
  const [idRegion, setIdRegion] = useState("")
  const [namaRegion, setNamaRegion] = useState("")
  const [headRegion, setHeadRegion] = useState([])
  const [selectedOption, setSelectedOption] = useState(null);
  const [placeholder, setPlaceholder] = useState("")

  const [type, setType] = useState("");
  const [isSubmitting, setIsSubmiting] = useState(false)
  const [isAddSuccess, setAddSuccess] = useState(false);
  const [isValid, setIsValid] = useState(false)
  const [showConfirmAlert, setShowConfirmAlert] = useState(false)
  const [showErrorMessageId, setShowErrorMessageId] = useState(false)
  const [showErrorMessageRegion, setShowErrorMessageRegion] = useState(false)

  const [errorMessageId, setErrorMessageId] = useState("")
  const [errorMessageRegion, setErrorMessageRegion]  =useState("")

  const isSubmitingDisabled = 
    !idRegion ||
    !namaRegion ||
    !headRegion ||
    !selectedOption

  useEffect(() => {
    const dropdownResponse = async () => {
      const params ={
        role: "Kepala Region"
      }

      try {
        const response = await api.get('/v1/users/dropdown', {params})
        console.log("response", response)
        setHeadRegion(response?.data?.contents)
      } catch (error) {
        console.log("error", error)
      }
    }

    const fetchDetailRegion = async () => {
      try {
        const responseDetail = await api.get(`/v1/regions/${id}`)
        console.log(responseDetail, "response detail")
        setIdRegion(responseDetail.data?.code)
        console.log(responseDetail.data?.headOfRegion?.fullName, "code")
        // setHeadRegion(responseDetail?.data?.headOfRegion)
        setPlaceholder(responseDetail.data?.headOfRegion?.fullName)
        setNamaRegion(responseDetail?.data?.name)
      } catch (error) {
        console.log(error)
      }
    }

    dropdownResponse()
    fetchDetailRegion()
  }, [id])

  const handleSwitchChange = () => {
    setActive(!isActive)
  }

  const handleChangeType = (select) => {
    console.log("select", select)
    const selectedType = select;
    setType(selectedType?.id);
    setSelectedOption(selectedType);
  };

  const handleSaveRegion = async () => {
    
    setShowConfirmAlert(false)

    const params = {
      code: idRegion,
      name: namaRegion,
      headOfRegion: {id: type},
      isActive: isActive
    }

    try {
      setIsSubmiting(true)
      const response = await api.put(`/v1/regions/${id}`, params)
      console.log("response", response)

      setAddSuccess(true)
      setTimeout(() => setAddSuccess(false), 2000)

      setIdRegion("")
      setNamaRegion("")
      setHeadRegion("")
      setActive(false)

      navigate("/master/region")
      localStorage.setItem("responseEditSuccess", JSON.stringify(!isAddSuccess));

    } catch (error) {
      console.log(error)
      setIsSubmiting(false)
      setErrorMessageId(error.response?.data?.error)
      setErrorMessageRegion(error.response?.data?.error)

      if (error.response?.data?.error.includes("Kode")) {
        // setErrorMessageRegion(error.response?.data?.error);
        setShowErrorMessageId(true)
      } else if (error.response?.data?.error.includes("Region")) {
        // setErrorMessage(error.response?.data?.error)
        setShowErrorMessageRegion(true)
      }
    }
  }

  const handleChangeIdRegion = (e) => {
    const value = e.target.value;
    const regex = /^[A-Z]{2}\d{2}\d*$/

    if (value.length <= 4) {
      setIdRegion(value)
    }

    if (regex.test(value)) {
      setIsValid(true)
    } else {
      setIsValid(false)
    }
  }

  const handleChangeNameRegion = (e) => {
    const value = e.target.value

    if (value.length <= 50) {
      setNamaRegion(value)
    }
  }


  return (
    <>
      <div className="p-4 flex justify-center">
        <div>
          <Card className="border w-[800px] shadow-none border-neutral-300">
            <CardHeader floated={false} shadow={false} className="rounded-b-none">
              <Typography className="text-xl font-bold text-text-primary flex items-center">
                <div className="cursor-pointer pr-2" onClick={() => navigate('/master/region')}>
                  <ChevronLeft />
                </div>
                Edit region
              </Typography>
            </CardHeader>

            <div className="flex justify-center w-full">
              <CardBody className="w-[440px]">
                <div className="w-[440px]">
                  <div>
                    <Typography className="block text-sm font-semibold text-text-primary leading-tight mb-2">
                      ID region{" "}
                      <span className="text-red-500 text-sm font-normal leading-tight">
                        *
                      </span>
                    </Typography>
                    <Input
                      type="text"
                      id="code_region"
                      placeholder="Masukan ID Region"
                      size="medium"
                      className={`${(showErrorMessageId) &&  'border-red-500'} max-w-d `}
                      onChange={handleChangeIdRegion}
                      value={idRegion?.toLocaleUpperCase("id-ID")} />
                  </div>
                  {showErrorMessageId && (
                    <p className="text-red-500 text-sm mt-1">{errorMessageId}</p>
                  )}

                  <div className="mt-6 mb-3xl">
                    <Typography className="block text-sm font-semibold text-text-primary leading-tight mb-2">
                      Nama region{" "}
                      <span className="text-red-500 text-sm font-normal leading-tight">
                        *
                      </span>
                    </Typography>
                    <Input
                      type="text"
                      id="nama_region"
                      placeholder="Masukan Nama region"
                      size="medium"
                      className={`${(showErrorMessageRegion) &&  'border-red-500'} max-w-d `}
                      onChange={handleChangeNameRegion}
                      value={namaRegion} 
                    />
                    {showErrorMessageRegion && (
                      <p className="text-red-500 text-sm">{errorMessageRegion}</p>
                    )}
                  </div>

                  <div className="mt-6 mb-3xl">
                    <Typography className="block text-sm font-semibold text-text-primary leading-tight mb-2">
                      Kepala region{" "}
                      <span className="text-red-500 text-sm font-normal leading-tight">
                        *
                      </span>
                    </Typography>
                  
                    <Dropdown
                      onSelect={handleChangeType}
                      placeholder={placeholder}
                      options={headRegion}
                      selectedOption={selectedOption}
                      className="placeholder:text-text-secondary"
                    ></Dropdown>
                  </div>

                  <div className="mt-6 mb-3xl">
                    <div className="my-md">
                      <label className="block font-semibold text-text-primary leading-tight text-sm">
                        Status region
                      </label>
                    </div>
                    <div>
                      <Switch
                        className="checked:bg-bg-brandSolid"
                        onChange={handleSwitchChange}
                        checked={isActive} />
                    </div>
                  </div>
                </div>
              </CardBody>
            </div>

            <CardFooter className="flex justify-end border-t border-border-secondary p-lg">
              <Button
                label="Batal"
                size="medium"
                type="btnSecondary"
                className="mr-md"
                onClick={() => navigate('/master/region')} />

              <Button
                label="simpan"
                size="medium"
                // onClick={handleSaveRegion}
                onClick={() => setShowConfirmAlert(!showConfirmAlert)}
                type={`${isSubmitingDisabled || isSubmitting
                    ? "btnPrimaryDisabled"
                    : "btnPrimary"}`}
                disabled={isSubmitingDisabled || isSubmitting}
                componentLeft={isSubmitting && (
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    className="animate-spin fill-neutral-400"
                  >
                    <path
                      d="M10.72,19.9a8,8,0,0,1-6.5-9.79A7.77,7.77,0,0,1,10.4,4.16a8,8,0,0,1,9.49,6.52A1.54,1.54,0,0,0,21.38,12h.13a1.37,1.37,0,0,0,1.38-1.54,11,11,0,1,0-12.7,12.39A1.54,1.54,0,0,0,12,21.34h0A1.47,1.47,0,0,0,10.72,19.9Z"
                      className="spinner_6kVp" />
                  </svg>
                )} />
            </CardFooter>
          </Card>

          <ConfirmDialog 
            title="Konfirmasi tambah region"
            content="Pastikan semua informasi region yang ditambahkan sudah benar sebelum menyimpan."
            btnLabel="Lanjutkan"
            btnType="btnPrimary"
            onClose={() => setShowConfirmAlert(false)}
            isOpen={showConfirmAlert}
            onConfirm={handleSaveRegion}
          />
        </div>
      </div>
    </>
  )
}

export default EditRegion