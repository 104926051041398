import React from "react";
import { IconButton, ButtonGroup } from "@material-tailwind/react";
import { ArrowRightIcon, ArrowLeftIcon } from "@heroicons/react/24/outline";

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  // const [nextPageIteration, setNextPageIteration] = useState();
  // const [startPageIteration, setStartPageIteration] = useState();


  // useEffect(() => {
  //   if (currentPage >= 4 && currentPage < totalPages - 3) {
  //       setStartPageIteration(currentPage - 1);
  //       setNextPageIteration(currentPage + 1);
  //   }
  // }, [currentPage, totalPages]);


  const handlePaginationChange = (newPage) => {
    onPageChange(newPage);
  };

  const renderPaginationButtons = () => {
    const buttons = [];

    const renderButton = (pageNumber) => (
      <IconButton
        key={pageNumber}
        variant="text"
        size="sm"
        className={`max-h-[34px] flex items-center justify-center rounded-none border-y-neutral-300 border-x-neutral-200 focus:ring-0 ${
          pageNumber === currentPage ? "bg-primary-50 text-primary-600" : ""
        }`}
        onClick={() => handlePaginationChange(pageNumber)}
      >
        {pageNumber}
      </IconButton>
    );

    if (totalPages <= 8) {
      if (totalPages === 0) {
        totalPages = 1;
      }
      for (let i = 1; i <= totalPages; i++) {
        buttons.push(renderButton(i));
      }
    } else {
      if (currentPage < 4) {
        // Display the first 3 pages
        for (let start = 1; start <= 4; start++) {
          buttons.push(renderButton(start));
        }
        buttons.push(
          <IconButton
            key="ellipsis-start"
            variant="text"
            size="sm"
            className="max-h-[34px] flex items-center justify-center rounded-none border-y-neutral-400 border-x-neutral-300"
            disabled
          >
            ...
          </IconButton>
        );
        for (let end = totalPages - 2; end <= totalPages; end++) {
          buttons.push(renderButton(end));
        }
      } 
      // else if (currentPage >= 4 && (currentPage < totalPages - 3)) {

      //   buttons.push(renderButton(1));

      //   buttons.push(
      //     <IconButton
      //       key="ellipsis-start"
      //       variant="text"
      //       size="sm"
      //       className="max-h-[34px] flex items-center justify-center rounded-none border-y-neutral-400 border-x-neutral-300"
      //       disabled
      //     >
      //       {">= 4"}
      //     </IconButton>
      //   );
        
      //   for (let start = startPageIteration; start <= nextPageIteration; start++) {
      //     buttons.push(renderButton(start));
      //   }

      //   buttons.push(
      //     <IconButton
      //       key="ellipsis-start"
      //       variant="text"
      //       size="sm"
      //       className="max-h-[34px] flex items-center justify-center rounded-none border-y-neutral-400 border-x-neutral-300"
      //       disabled
      //     >
      //       ...
      //     </IconButton>
      //   );

      //     buttons.push(renderButton(totalPages));

      // }
       else if (currentPage >= totalPages - 2) {
        // Display the last 3 pages
        buttons.push(renderButton(1));
        buttons.push(
          <IconButton
            key="ellipsis-end"
            variant="text"
            size="sm"
            className="max-h-[34px] flex items-center justify-center border-y-neutral-400 border-x-neutral-300"
            disabled
          >
            ...
          </IconButton>
        );
        for (let i = totalPages - 3; i <= totalPages; i++) {
          buttons.push(renderButton(i));
        }
      } else {
        // Display current page and 3 pages on each side
        buttons.push(renderButton(1));
        buttons.push(
          <IconButton
            key="ellipsis-start"
            variant="text"
            size="sm"
            className="max-h-[34px] flex items-center justify-center border-y-neutral-400 border-x-neutral-300"
            disabled
          >
            ...
          </IconButton>
        );
        for (let i = currentPage - 1; i <= currentPage + 1; i++) {
          buttons.push(renderButton(i));
        }
        buttons.push(
          <IconButton
            key="ellipsis-end"
            variant="text"
            size="sm"
            className="max-h-[34px] flex items-center justify-center border-y-neutral-400 border-x-neutral-300"
            disabled
          >
            ...
          </IconButton>
        );
        buttons.push(renderButton(totalPages));
      }
    }

    return buttons;
  };

  return (
    <div className="flex justify-between pb-sm items-center mx-xl">
      <div className="text-neutral-500 text-sm flex items-center justify-start mt-4 space-x-2">
        Halaman {currentPage} dari {totalPages === 0 ? 1 : totalPages}
      </div>

      <div className="flex items-center justify-end mt-4 space-x-2">
        <ButtonGroup variant="outlined">
          {currentPage > 1 ? (
            <IconButton
              className="min-w-[150px] max-h-[34px] border-neutral-300 border-r-neutral-200 flex normal-case"
              onClick={() => handlePaginationChange(currentPage - 1)}
            >
              <div className="flex gap-2">
                <ArrowLeftIcon strokeWidth={2} className="h-4 w-4" />
                Sebelumnya
              </div>
            </IconButton>
          ) : (
            <IconButton
              className="min-w-[150px] max-h-[34px] border-neutral-300 border-r-neutral-200 normal-case"
              disabled
            >
              <div className="flex gap-2">
                <ArrowLeftIcon strokeWidth={2} className="h-4 w-4" />
                Sebelumnya
              </div>
            </IconButton>
          )}
          {renderPaginationButtons()}
          {currentPage < totalPages ? (
            <IconButton
              className="min-w-[150px] max-h-[34px] border-neutral-300 border-l-neutral-200 normal-case"
              onClick={() => handlePaginationChange(currentPage + 1)}
            >
              <div className="flex gap-2">
                Selanjutnya
                <ArrowRightIcon strokeWidth={2} className="h-4 w-4" />
              </div>
            </IconButton>
          ) : (
            <IconButton
              className="min-w-[150px] max-h-[34px] border-neutral-300 border-l-neutral-200 flex normal-case"
              disabled
            >
              <div className="flex gap-2">
                Selanjutnya
                <ArrowRightIcon strokeWidth={2} className="h-4 w-4" />
              </div>
            </IconButton>
          )}
        </ButtonGroup>
      </div>
    </div>
  );
};

export default Pagination;
