/* eslint-disable no-unused-vars */
import { Card, CardBody, CardFooter, CardHeader, Typography } from "@material-tailwind/react"
import FormDropdownSearch from "../../atoms/FormDropdownSearch"
import InputNumber from "../../atoms/NumberFormat"
import { useEffect, useState } from "react"
import Input from "../../atoms/Input"
import CustomDatePicker from "../../atoms/DatepickerForm"
import { Button } from "../../atoms/Button"
import ConfirmDialog from "../../atoms/ConfirmDialog"
import api from '../../api/configApi'
import moment from "moment"
import AlertNotification from "../../atoms/AlertNotification"
import { FaCheckCircle } from "react-icons/fa"
import { ReactComponent as ArrowIncrement } from '../../assets/icons/arrow-increment.svg'
import { ReactComponent as ArrowDecrement } from '../../assets/icons/arrow-decrement.svg'

const ModalEditPeriod = ({
  onClose,
  isOpen,
  getId,
  onUpdate,
  onReloadData
}) => {
  const [periode, setPeriode] = useState(1)
  const [DocType, setDocType] = useState('')
  const [population, setPopulation] = useState('')
  const [weight, setWeight] = useState('')
  const [price, setPrice] = useState('')
  const [placeholderDate, setPlaceholderDate] = useState('')
  const [cage, setCage] = useState("")
  const [responseDoc, setResponseDoc] = useState([])
  const [placeholderDoc, setPlaceholderDoc] = useState("")
  const [selectDate, setSelectdate] = useState(null)
  const [houseId, setHouseId] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [fetchData, setFetchData] = useState(false)
  const [isEditSuccess, setIsEditSuccess] = useState(false)
  const [docInDateBefore, setDocInDateBefore] = useState("")
  const [fetchTrigger, setFetchTrigger] = useState(false)
  const [showError, setShowError] = useState(false)
  const [codePeriode, setCodePeriode] = useState("")
  const [fethcingSequence, setFetchingSequence] = useState(false)
  const [period, setPeriod] = useState(0)
  const [sequencePeriod, setSequencePeriod] = useState("");
  const [cageNumber, setCageNumber] = useState(0)
  const [sequencePeriode, setSequencePeriode] = useState(null)
  const [cageId, setCageId] = useState(null)
  const [mergePeriode, setMergePeriode] = useState("")

  const isSubmitedDsiabled = 
    !population ||
    !weight ||
    !price 

  useEffect(() => {
    const fetchData = async () => {
      try {
        if(getId){
          const response = await api.get(`/v1/rearingPeriods/${getId}`)
          setCageId(response?.data?.house?.id)
          setCage(response?.data?.house?.labels)
          setPopulation(response?.data?.docInPopulation)
          setPrice(response?.data?.pricePerChick)
          setPeriode(response?.data?.sequence)
          setPlaceholderDoc(response?.data?.doc?.labels)
          setWeight(response?.data?.docInWeight)
          setHouseId(response?.data?.house?.id)
          setDocType(response?.data?.doc?.id)
          setCodePeriode(response?.data?.house?.code)
          setPeriod(response?.data?.house?.period)
          setDocInDateBefore(response?.data?.docInDate);

          const dateMomentFormat = moment(response?.data?.docInDate).format("LL")
          setPlaceholderDate(dateMomentFormat)

          console.log("code", response?.data?.house?.code)
        }
      } catch (error) {
        console.log(error)
      }
    }

    const fetchDataDoc = async () => {
      try {
        const responseDoc = await api.get('/v1/docs')
        setResponseDoc(responseDoc?.data)
      } catch (error) {
        console.log(error)
      }
    }

    fetchDataDoc()
    fetchData()
  }, [getId])

  useEffect(() => {
    const fetchingCodeSequence = async () => {
      if (fetchTrigger) {
        try {
          const response = await api.get(`/v1/rearingPeriods/sequences`, {
            params: {
              houseId: cageId
            }
          })
          console.log("response", response)
          setCodePeriode(response?.data?.code)
          setSequencePeriode(response?.data?.nextSequence)
        } catch (error) {
          console.log("error", error)
        }
      }
    }

    fetchingCodeSequence()
}, [ fetchTrigger, cageId])


  const handleUpdate = async () => {
    try {
      let requestEditData = {
        house: {id: houseId},
        docInPopulation: population,
        docInWeight: weight,
        pricePerChick: price,
        docInDate: selectDate !== null ? moment(selectDate?.$d).format('YYYY-MM-DD') : docInDateBefore,
        doc: {id: DocType}
      }

      setIsSubmitting(true)
      const fetchingResponse = await api.put(`/v1/rearingPeriods/${getId}`, requestEditData)
      setFetchData(true)
      setIsEditSuccess(true)
      setIsSubmitting(false)

      localStorage.setItem("responseEditPeriodeSuccess", JSON.stringify(!isEditSuccess))
      window.location.reload()

      if (onUpdate) {
        onUpdate(fetchingResponse?.data)
      }

      if (onReloadData) {
        onReloadData()
      }

    } catch (error) {
      console.log("error", error)
    }
  }

  const handleSelectDoc = (select) => {
    setDocType(select.id);
  }

  const handleChangeDate = (date) => {
    setSelectdate(date);
  };

  const handlePeriodChange = (e) => {
    setPeriod(e.target.value);
  };

  const incrementValue = () => {
    setPeriod(prevValue => {
      const newValue = prevValue + 1
      // checkDuplicateDataSequence(newValue)
      return newValue
    })
  }

  const decrementValue = () => {
    setPeriod(prevValue => {
      if (prevValue > 0) {
        const newValue = prevValue - 1
        // checkDuplicateDataSequence(newValue)
        return newValue
      }
    })
  }

  const handleChangeNotNull = (e) => {
    let inputValue = e.target.value;
    let newValue = inputValue === '' ? 0 : parseInt(inputValue, 10);
  
    if (isNaN(newValue) || newValue < 0) {
      newValue = 0;
    } else if (newValue < 1 && inputValue !== '') {
      newValue = 1;
    }
  
    setCageNumber(newValue);
    
    if (newValue > 0) {
      // checkDuplicateDataSequence(newValue);
    }
  };


  return (
    <div>
       {isOpen && <div className="fixed inset-0 bg-gray-800 opacity-50 z-50"></div>}

       <Card
         className={`absolute top-1/2 left-1/2 overflow-auto max-h-[950px] w-[600px] transform -translate-x-1/2 -translate-y-1/2 z-[100] ${
          isOpen ? "block" : "hidden"
        } rounded-xl flex-col justify-start items-start`}
       >
          <Card>
            <CardHeader
              floated={false}
              shadow={false}
              className="border-b border-border-secondary rounded-b-none p-0 m-0 flex justify-between items-center"
            >
              <Typography
                type="h2"
                className="text-xl text-text-primary font-bold p-5 m-0"
              >
                Ubah Periode
              </Typography>

              <button className="pr-4 text-gray-500" onClick={onClose}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </CardHeader>

            <CardBody className="p-5">
              <div className="grid grid-cols-1 gap-4">
                <Typography
                  type="small"
                  className="text-text-primary text-sm font-medium leading-tight"
                >
                  Kandang <span className="text-[#F04438]">*</span>
                </Typography>
                <Input 
                    type="text"
                    id="cage"
                    className="placeholder:text-text-placeholder text-text-secondary mt-2"
                    size="medium"
                    disabled={true}
                    value={cage}
                  />
              </div>

              {/* <div className="grid grid-cols-1 gap-4 my-4">
                <Typography
                  type="small"
                  className="text-text-primary text-sm font-medium leading-tight"
                >
                  Periode ke  <span className="text-[#F04438]">*</span>
                </Typography>

                <InputNumber
                  type="number"
                  size="medium"
                  value={periode}
                  disabled={true}
                  className=" placeholder:text-text-placeholder text-text-secondary"
                  componentRight={<div className="grid grid-cols-1">
                    <div className="cursor-pointer" onClick={() => setPeriode(periode +  1)}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19"  viewBox="0 0 20 20" fill="none">
                        <path d="M15 12L10 7L5 12" stroke="#B3B4B6" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                    </div>
                    <div className="cursor-pointer" onClick={() => setPeriode(periode - 1)}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 20 20" fill="none">
                        <path d="M5 7.5L10 12.5L15 7.5" stroke="#B3B4B6" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                    </div>
                  </div>}
                />
              </div> */}

              <div className="flex justify-between w-full mt-[12px] gap-3 my-6">
                <div className="w-[50%] mt-[2px]">
                  <Typography className="text-sm text-text-primary pt-6">
                    Periode <span className="text-red-600">*</span>
                  </Typography>

                  <div className="mt-2">
                    <Input
                      type="number"
                      id="cageNumber"
                      value={period}
                      onChange={handleChangeNotNull}
                      placeholder="Periode"
                      className={`max-w-full placeholder:text-text-placeholder appearance-none ${showError ? "border-red-500" : ""}`}
                      size="medium"
                      componentRight={
                        <div className="flex flex-col absolute right-[-7px] justify-between items-center w-[30px] h-full bg-[#EAEAEB] rounded-r-lg ml-[12px]">
                          <button onClick={incrementValue}  className="focus:outline-none">
                            <ArrowIncrement className="h-5 w-5" />
                          </button>
                          <button onClick={decrementValue}  className="focus:outline-none">
                            <ArrowDecrement className="h-5 w-5" />
                          </button>
                        </div>
                      }
                    />

                  {showError && (
                    <div className="pt-2">
                      <Typography className="text-sm text-text-errorPrimary w-full md:w-[240px] h-[30px] text-start">
                        Kode kandang telah digunakan, masukkan nomor lain.
                      </Typography>
                    </div>
                  )}
                  </div>
                </div>

                <div className="w-[50%] mt-[37px]">
                  <Typography
                    className="text-text-primary text-sm font-medium leading-tight"
                  >
                    Kode periode <span className="text-red-600">*</span>
                    <Input
                      type="number"
                      id="name"
                      value={codePeriode}
                      placeholder={codePeriode}
                      className="w-[490px] placeholder:text-text-placeholder appearance-none"
                      size="medium"
                      disabled={true}
                    />
                  </Typography>
                </div>
              </div>

              <div className="border-t border-[#EAECF0] my-8 w-full" />

              <div className="grid grid-cols-1 gap-4 my-4">
                <Typography
                  type="small"
                  className="text-text-primary text-sm font-medium leading-tight"
                >
                  Jenis DOC  <span className="text-[#F04438]">*</span>
                </Typography>

                <FormDropdownSearch
                  size="medium"
                  placeholder={placeholderDoc}
                  defaultValue={true}
                  options={responseDoc?.contents}
                  onSelect={handleSelectDoc}
                />
              </div>

              <div className="grid grid-cols-1 gap-4 my-4">
                <Typography
                  type="small"
                  className="text-text-primary text-sm font-medium leading-tight"
                >
                  Populasi DOC in (Ekor)  <span className="text-[#F04438]">*</span>
                  <Input 
                    type="text"
                    id="population"
                    className="placeholder:text-text-placeholder text-text-secondary mt-2"
                    size="medium"
                    placeholder="Masukan populasi"
                    value={population}
                    onChange={(e) => setPopulation(e.target.value)}
                  />
                </Typography>
              </div>

              <div className="grid grid-cols-1 gap-4 my-4">
                <Typography
                  type="small"
                  className="text-text-primary text-sm font-medium leading-tight"
                >
                   Bobot awal (Gram)  <span className="text-[#F04438]">*</span>

                   <Input 
                    type="text"
                    id="population"
                    className="placeholder:text-text-placeholder text-text-secondary mt-2"
                    size="medium"
                    placeholder="Masukan bobot awal"
                    value={weight}
                    onChange={(e) => setWeight(e.target.value)}
                  />
                </Typography>
              </div>

              <div className="grid grid-cols-1 gap-4 my-4">
                <Typography
                  type="small"
                  className="text-text-primary text-sm font-medium leading-tight"
                >
                  Harga per ekor (Rupiah)  <span className="text-[#F04438]">*</span>

                  <Input 
                    type="text"
                    id="population"
                    className="placeholder:text-text-placeholder text-text-secondary mt-2"
                    size="medium"
                    placeholder="Masukan harga"
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                  />
                </Typography>
              </div>

              <div className="grid grid-cols-1 gap-4 my-4">
                <Typography
                  type="small"
                  className="text-text-primary text-sm font-medium leading-tight"
                >
                  Tanggal DOC in (Umur 1 hari)  <span className="text-[#F04438]">*</span>
                  <CustomDatePicker
                    placeholder={placeholderDate}
                    className="w-full text-text-primary px-sm py-xs justify-start items-center gap-xxs inline-flex focus:outline-none focus:border-primary-400 focus:ring focus:ring-primary-200 mt-3 placeholder:text-text-secondary"
                    format={"DD MMMM YYYY"}
                    value={selectDate}
                    defaultValue={true}
                    onChange={handleChangeDate}
                  />
                </Typography>
              </div>
            </CardBody>

            <CardFooter className="flex justify-end border-t border-border-secondary p-lg" >
              <Button
                label="Batal"
                size="medium"
                type="btnSecondary"
                className="mr-md"
                onClick={onClose}
              />

              <Button
                label="Simpan"
                size="medium"
                type={`${isSubmitedDsiabled || isSubmitting ? "btnPrimaryDisabled" : "btnPrimary"}`}
                disabled={isSubmitedDsiabled || isSubmitting}
                onClick={() => handleUpdate()}
                // componentLeft={}
              />
            </CardFooter>
          </Card>
        </Card>


        <ConfirmDialog
          title="Ubah Data Periode"
          content="Anda yakin ingin melanjutkan tindakan ini?"
          btnLabel="Lanjutkan"
          btnType="btnPrimary"
        />

        {isEditSuccess && (
          <AlertNotification 
            icon={<FaCheckCircle />}
            color="green"
            message="Data periode berhasil diubah."
          />
        )}
    </div>
  )
}

export default ModalEditPeriod