import { useMutation, useQueryClient } from "@tanstack/react-query";

import api from "../../api/configApi";
import { toast } from "../../atoms/V2/useToast";

const useMutateMasterPeriod = () => {
  const queryClient = useQueryClient();
  const mutate = useMutation({
    mutationFn: async (values) => {
      const response = await api.post("/v1/masterPeriods", values);
      return response;
    },
    onSuccess: () => {
      toast({
        title: "Berhasil buat periode",
        variant: "success",
      });
      queryClient.invalidateQueries({
        queryKey: ["get-master-period"],
      });
      queryClient.invalidateQueries({
        queryKey: ["get-houses-sequences"],
      });
    },
    onError: (error) => {
      console.log(error);
      toast({
        title: "Gagal buat periode",
        description: error.response.data.error,
        variant: "error",
      });
    },
  });

  return mutate;
};

export default useMutateMasterPeriod;
