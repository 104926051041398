/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  Typography,
  CardBody,
  CardFooter,
} from "@material-tailwind/react";
import {
  PlusIcon,
  ChevronUpDownIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";

import { Button } from "../../../../atoms/Button";
import Input from "../../../../atoms/Input";
import Pagination from "../../../../atoms/Pagination";
import AddPakanModal from "../../../../molecules/master/pakan/AddPakanModal";
import EditPakanModal from "../../../../molecules/master/pakan/EditPakanModal";
import { FaCheckCircle } from "react-icons/fa";

import api from "../../../../api/configApi";
import ConfirmDialog from "../../../../atoms/ConfirmDialog";
import AlertNotification from "../../../../atoms/AlertNotification";
import DropdownFilterCheckbox from "../../../../atoms/DropdownFilterCheckbox";
import { ReactComponent as EmptyIcon } from "../../../../assets/icons/emptyIconn.svg";
import LoadingAnimation from "../../../../atoms/LoadingAnimation";
import * as Sentry from "@sentry/react";
import { ReactComponent as EditIconMaster } from "../../../../assets/icons/edit_icon_master.svg";
import { ReactComponent as DeleteIconMaster } from "../../../../assets/icons/delete_icon_master.svg";

const MasterPakan = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [isSearchProcess, setIsSearchProcess] = useState(false);

  const [pakans, setPakans] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isAddModalOpen, setAddModalOpen] = useState(false);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [units, setUnits] = useState([]);

  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const [selectedDeletePakanId, setSelectedDeletePakanId] = useState(null);
  const [selectedEditPakanId, setSelectedEditPakanId] = useState(null);
  const [isDeleteSuccess, setDeleteSuccess] = useState(false);
  const [isDeleteError, setDeleteError] = useState(false);

  const sortableColumns = ["", "name", "supplier", "type"];
  const [sortBy, setSortBy] = useState("");
  const [descending, setDescending] = useState(true);

  const [selectedType, setSelectedType] = useState("");
  const [checkedTypes, setCheckedTypes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isFetchError, setIsFetchError] = useState(false);

  const TABLE_HEAD = [
    "No",
    "Nama",
    "Jenis Pakan",
    "Perusahaan",
    "Aksi",
  ];

  const handleOpenDeleteDialog = (pakanId) => {
    setSelectedDeletePakanId(pakanId);
    setDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setSelectedDeletePakanId(null);
    setDeleteDialogOpen(false);
  };

  const reloadData = async () => {
    try {
      const response = await api.get("/v1/feeds", {
        params: {
          itemPerPage: 10,
          search: searchTerm,
          currentPage: currentPage - 1,
          sortBy: sortBy,
          descending: descending,
        },
      });
      setPakans(response.data.contents);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const handleConfirmDelete = async () => {
    try {
      await api.delete(`/v1/feeds/${selectedDeletePakanId}`);
      handleCloseDeleteDialog();

      if (reloadData()) {
        reloadData();
      }
      setDeleteSuccess(true);
      setTimeout(() => setDeleteSuccess(false), 2000);
    } catch (error) {
      console.error("Error deleting feed:", error);
      setDeleteError(true);
    }
  };

  const handleOpenEditModal = (pakanId) => {
    setSelectedEditPakanId(pakanId);
    setEditModalOpen(true);
  };

  const handleCloseEditModal = () => {
    setSelectedEditPakanId(null);
    setEditModalOpen(false);
  };

  const handleAddClick = () => {
    setAddModalOpen(true);
  };

  const handleAddModalClose = () => {
    setAddModalOpen(false);
  };

  useEffect(() => {
    setIsSearchProcess(true);
    const fetchData = async () => {
      setIsLoading(true);
      try {
        let stringTypeList =
          checkedTypes.length > 0 ? checkedTypes.join(",") : null;
        const params = {
          itemPerPage: 10,
          search: searchTerm,
          currentPage: currentPage - 1,
          sortBy: sortBy,
          descending: descending,
        };

        if (stringTypeList !== null) {
          params.type = stringTypeList;
        }
        const response = await api.get("/v1/feeds", { params });
        setPakans(response.data.contents);
        setTotalPages(response.data.totalPages);
        setIsFetchError(false);
      } catch (error) {
        setIsFetchError(true);
        Sentry.captureException(error);
      } finally {
        setIsLoading(false);
      }
    };

    const fetchUnits = async () => {
      try {
        const response = await api.get("/v1/feeds/feedType");
        setUnits(response.data.contents);
      } catch (error) {
        Sentry.captureException(error);
      }
    };

    fetchData();
    fetchUnits();
  }, [checkedTypes, currentPage, searchTerm, sortBy, descending]);

  const handleSort = (column) => {
    if (sortableColumns.includes(column)) {
      if (sortBy === column) {
        // Toggle descending if the same column is clicked
        setDescending(!descending);
      } else {
        // Change sorting column and set default descending to false
        setSortBy(column);
        setDescending(false);
      }
    }
  };

  const handleSearchChange = (e) => {
    setIsSearchProcess(!isSearchProcess);
    const keywordSearch = e.target.value;
    setSearchTerm(keywordSearch);
    setIsSearchProcess(false);
    handlePageChange(1);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleFilterType = (val) => {
    setSelectedType(val);
  };

  return (
    <div className={`flex h-auto overflow-hidden`}>
      <div className="flex flex-col flex-1 overflow-hidden">
        <div className="p-4">
          <Card className="h-full w-full border shadow-none border-border-secondary">
            <CardHeader
              floated={false}
              shadow={false}
              className="p-2 m-0 border-none w-full"
            >
              <Typography className="text-xl font-bold p-4 text-text-primary">
                Pakan
              </Typography>
              <div className="grid grid-cols-4 px-4 gap-4 mb-4">
                <div className="text-left">
                  <Input
                    type="text"
                    id="search"
                    placeholder="Pencarian.."
                    className="max-w-[300px]"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    size="medium"
                    componentLeft={<MagnifyingGlassIcon className="h-5 w-5" />}
                    componentRight={
                      !isSearchProcess && (
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                          className="animate-spin fill-neutral-400 max-w-sm"
                        >
                          <path
                            d="M10.72,19.9a8,8,0,0,1-6.5-9.79A7.77,7.77,0,0,1,10.4,4.16a8,8,0,0,1,9.49,6.52A1.54,1.54,0,0,0,21.38,12h.13a1.37,1.37,0,0,0,1.38-1.54,11,11,0,1,0-12.7,12.39A1.54,1.54,0,0,0,12,21.34h0A1.47,1.47,0,0,0,10.72,19.9Z"
                            className="spinner_6kVp"
                          />
                        </svg>
                      )
                    }
                  />
                </div>
                <div className="relative">
                  <DropdownFilterCheckbox
                    placeholder="Semua jenis pakan"
                    options={units}
                    onSelect={handleFilterType}
                    setCheckedItems={setCheckedTypes}
                  />
                </div>
                <div></div>
                <div className="text-center flex justify-end">
                  <Button
                    label="Tambah pakan"
                    size="medium"
                    type="btnPrimary"
                    onClick={handleAddClick}
                    componentRight={<PlusIcon className="h-5 w-5" />}
                  />
                </div>
              </div>
            </CardHeader>

            {isLoading ? (
              <div className="flex justify-center items-center h-screen">
                <LoadingAnimation />
              </div>
            ) : (
              <>
                {pakans?.length === 0 || isFetchError ? (
                  <div className=" flex justify-center items-center h-screen">
                    <div>
                      <div className="flex justify-center">
                        <EmptyIcon className="ml-[30px]" />
                      </div>

                      <Typography
                        variant="h2"
                        className="text-xl font-semibold p-4 text-center text-text-secondary"
                      >
                        Data tidak ditemukan
                      </Typography>

                      <Typography
                        variant="medium"
                        className="flex justify-center font-normal leading-none w-full text-text-secondary min-h-full bg-gray-950"
                      >
                        Data yang Anda cari tidak ditemukan. Coba kata kunci
                        lain
                      </Typography>
                    </div>
                  </div>
                ) : (
                  <>
                    <CardBody className="p-0 overflow-x-scroll max-h-[660px]">

                      <table className="w-full min-w-max table-auto text-left">
                        <thead>
                          <tr>
                            {TABLE_HEAD.map((head, index) => (
                              <th
                                key={head}
                                className="border-y border-border-secondary bg-neutral-50 py-2 px-lg"
                              >
                                <Typography className="flex justify-between text-sm font-semibold text-text-quarterary leading-tight">
                                  {head}{" "}
                                  {index !== 0 &&
                                    index !== TABLE_HEAD.length - 1 && (
                                      <button
                                        onClick={() =>
                                          handleSort(sortableColumns[index])
                                        }
                                        className="focus:outline-none ml-1"
                                      >
                                        <ChevronUpDownIcon
                                          strokeWidth={2}
                                          className={`h-4 w-4 ${
                                            descending
                                              ? "transform rotate-180"
                                              : ""
                                          }`}
                                        />
                                      </button>
                                    )}
                                </Typography>
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {pakans.map((pakan, index) => {
                            const isLast = index === pakans.length - 1;
                            const classes = isLast
                              ? "p-lg"
                              : "p-lg border-b border-border-secondary";

                            return (
                              <tr key={pakan.id}>
                                <td className={classes}>
                                  <div className="flex items-center gap-3">
                                    <Typography className="text-text-primary text-sm font-medium leading-tight">
                                      {(currentPage - 1) * 10 + index + 1}.
                                    </Typography>
                                  </div>
                                </td>
                                <td className={classes}>
                                  <Typography className="text-text-primary text-sm font-medium leading-tight">
                                    {pakan.name}
                                  </Typography>
                                </td>
                                <td className={classes}>
                                  <Typography className="text-text-primary text-sm font-medium leading-tight">
                                    {pakan.type?.labels || "-"}
                                  </Typography>
                                </td>
                                <td className={classes}>
                                  <Typography className="text-text-primary text-sm font-medium leading-tight">
                                    {pakan.supplier}
                                  </Typography>
                                </td>
                                <td className={`${classes}`}>
                                  <div className="flex gap-6 w-max">
                                    <button
                                      className="flex items-center"
                                      onClick={() =>
                                        handleOpenEditModal(pakan.id)
                                      }
                                    >
                                      <EditIconMaster className="h-4 w-4" />
                                      <span className="text-text-primary text-sm font-medium leading-tight">
                                        Ubah
                                      </span>
                                    </button>
                                    <button
                                      className="flex items-center"
                                      onClick={() =>
                                        handleOpenDeleteDialog(pakan.id)
                                      }
                                    >
                                      <DeleteIconMaster className="h-4 w-4" />
                                      <span className="text-text-primary text-sm font-medium leading-tight">
                                        Hapus
                                      </span>
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </CardBody>
                    <CardFooter className="p-sm items-center border-t border-border-secondary">
                      <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={handlePageChange}
                      />
                    </CardFooter>
                  </>
                )}
              </>
            )}
          </Card>
        </div>
      </div>
      <AddPakanModal
        isOpen={isAddModalOpen}
        onClose={handleAddModalClose}
        onReloadData={reloadData}
      />

      <EditPakanModal
        isOpen={isEditModalOpen}
        onClose={handleCloseEditModal}
        pakanId={selectedEditPakanId}
        onReloadData={reloadData}
        setSelectedEditPakanId={setSelectedEditPakanId}
      />

      <ConfirmDialog
        isOpen={isDeleteDialogOpen}
        onClose={handleCloseDeleteDialog}
        onConfirm={handleConfirmDelete}
        title="Hapus pakan"
        content="Anda yakin ingin melanjutkan tindakan ini?"
        btnLabel="Hapus"
        btnType="btnError"
      />

      {isDeleteSuccess && (
        <AlertNotification
          icon={<FaCheckCircle />}
          color="green"
          message="Data Pakan berhasil di hapus."
        />
      )}

      {isDeleteError && (
        <AlertNotification
          icon={<FaCheckCircle />}
          color="red"
          message="Terjadi kesalahan saat menghapus Pakan."
        />
      )}
    </div>
  );
};

export default MasterPakan;
