import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../molecules/features/authSlice";
import cageReducer from "../molecules/features/cageSlice";
import saveReducer from '../molecules/features/pageSlice';
import menuReducer from '../molecules/features/menuSlice';
import filterRearingPeriodReducer from '../molecules/features/filterRearingPeriodSlice';

import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; 
import { combineReducers } from "redux";

// Konfigurasi persist
const persistConfig = {
  key: "root",
  storage,
  whitelist: ["filterRearingPeriod"], // Nama slice yang akan dipersist
};

const rootReducer = combineReducers({
    filterRearingPeriod: filterRearingPeriodReducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer);


const store = configureStore({
    reducer: {
        auth: authReducer,
        cage: cageReducer,
        savedValue: saveReducer,
        menu: menuReducer,
        filterRearingPeriod: filterRearingPeriodReducer,
        persistedReducer,
    },
});

const persistor = persistStore(store);

export { store, persistor };