/* eslint-disable no-unused-vars */
import { Card, Drawer, IconButton, Stepper, Step, StepLabel, Typography } from "@material-tailwind/react"
import { useEffect, useState } from "react"
import moment from "moment";
import { ReactComponent as Active } from '../../assets/icons/active_history.svg'
import { ReactComponent as InActive } from '../../assets/icons/inactive_history.svg'

const SidebarHistory = ({
  open,
  setOpen,
  historyBuyyer,
  historyRegister,
  historyDo,
  historyPrice,
  dataHistory,
  conditionalDataHistory
}) => {

  const [activeStep, setActiveStep] = useState(0)

  useEffect(() => {
    if (dataHistory && dataHistory.length > 0) {
      const latestIndex = dataHistory.reduce((maxIndex, data, currentIndex) => {
        const currentCreatedAt = moment(data.createdAt);
        const maxCreatedAt = moment(dataHistory[maxIndex].createdAt);
        return currentCreatedAt.isAfter(maxCreatedAt) ? currentIndex : maxIndex;
      }, 0);
      setActiveStep(latestIndex);
    }
  }, [dataHistory])


  return (
    <Drawer open={open} onClose={setOpen} placement="right">
      <Card
        color="transparent"e
        shadow={false}
        className="h-[calc(100vh-2rem)] w-full p-4"
      >
        <div className="mb-6 flex items-center justify-between">
          {historyBuyyer && (
            <Typography variant="h5" color="blue-gray" className="text-md">
              Riwayat penyesuaian data pembeli
            </Typography>
          )}

          {historyRegister && (
            <Typography variant="h5" color="blue-gray">
              Riwayat penyesuaian plat nomor
            </Typography>
          )}

          {historyDo && (
            <Typography variant="h5" color="blue-gray" className="text-md">
              Riwayat penyesuaian nomor DO
            </Typography>
          )}

          {historyPrice && (
            <Typography variant="h5" color="blue-gray" className="text-md">
              Riwayat penyesuaian harga
            </Typography>
          )}

          <IconButton
            variant="text"
            color="blue-gray"
            onClick={setOpen}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke="currentColor"
              className="h-5 w-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </IconButton>
        </div>
        
        <div className="w-full py-4 px-8 max-w-md mx-auto flex flex-col space-y-4 overflow-scroll">
          {dataHistory?.map((data, index) => (

            <div key={index} className="relative gap-4">
              {/* Step Icon */}
              {/* <div className={`h-3 w-3 rounded-full flex items-center justify-center  ${activeStep === index ? 'bg-blue-500' : 'bg-gray-300'}`} /> */}
              {activeStep === index ? <Active /> : <InActive/>}

              <div className="p-4 mt-[-31px] w-full ml-2">
                <Typography
                  variant="h6"
                  color="blue-gray"
                  className={`text-sm text-center  ${index === activeStep ? 'text-[#344054] font-bold' : 'text-gray-700'}`}
                >
                  {moment(data?.createdAt).format('LLL')}
                </Typography>
                
                <Typography
                  variant="small"
                  color="blue-gray"
                  className="ml-4"
                >
                  {data?.resume}
                </Typography>
              </div>
              {/* Line connection */}
              <div className="mt-[5px]">
                {index !== dataHistory?.length - 1 && (
                  <div className={`absolute top-[15px] w-[3px] left-[4.5px] bg-gray-300 h-[90px]  ${index < activeStep ? 'bg-blue-500' : ''}`} />
                )}
              </div>
            </div>
          ))}
        </div>


      </Card>
    </Drawer>
  )
}

export default SidebarHistory