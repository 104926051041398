import { useState } from "react";

import { Button } from "../atoms/V2/Button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "../atoms/V2/Dialog";

export const useConfirm = (title, message) => {
  const [promise, setPromise] = useState(null);

  const confirm = () =>
    new Promise((resolve, reject) => {
      setPromise({ resolve });
    });

  const handleClose = () => {
    setPromise(null);
  };

  const handleConfirm = () => {
    promise?.resolve(true);
    handleClose();
  };

  const handleCancel = () => {
    promise?.resolve(false);
    handleClose();
  };

  const ConfirmationDialog = () => (
    <Dialog open={promise !== null} onOpenChange={handleClose}>
      <DialogContent disableClose className="min-w-[560px]">
        <DialogHeader>
          <DialogTitle className="text-lg font-semibold text-text-primary">
            {title}
          </DialogTitle>
          <DialogDescription className="sr-only">{title}</DialogDescription>
          <div className="w-full space-y-5">
            <p
              className="text-sm text-text-tertiary"
              dangerouslySetInnerHTML={{ __html: message }}
            ></p>
            <div className="flex items-center justify-end gap-xl">
              <Button
                variant="btnOutlinedGrey"
                className="w-fit"
                onClick={handleCancel}
              >
                Batal
              </Button>
              <Button
                variant="btnError"
                className="w-fit"
                onClick={handleConfirm}
              >
                Hapus
              </Button>
            </div>
          </div>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );

  return [ConfirmationDialog, confirm];
};
