/* eslint-disable no-unused-expressions */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
} from "@material-tailwind/react";
import { MdOutlineDone } from "react-icons/md";
import Input from "../../../../../atoms/Input";
import api from "../../../../../api/configApi";
import { Button } from "../../../../../atoms/Button";
import AlertNotification from "../../../../../atoms/AlertNotification";
import ConfirmDialog from "../../../../../atoms/ConfirmDialog";
import { FaTrash, FaPlus } from "react-icons/fa";
import { Stepper, Step } from "@material-tailwind/react";
import DropdownStockSearch from "../../../../../atoms/DropdownStockSearch";
import { FaCheckCircle } from "react-icons/fa";
import * as Sentry from "@sentry/react";
import dayjs from "dayjs";
import { ReactComponent as ChevronLeft } from "../../../../../assets/icons/chevron-left.svg";

const AddRecordingHarian = ({ onReloadData }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [isAddSuccess, setAddSuccess] = useState(false);
  const [isAddError, setIsAddError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isAddDialogOpen, setAddDialogOpen] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [isLastStep, setIsLastStep] = useState(false);
  const [isFirstStep, setIsFirstStep] = useState(false);
  const [listProducts, setListProducts] = useState([]);
  const [searchProduct, setSearchProduct] = useState([""]);
  const [selectedInDate, setSelectedInDate] = useState(null);
  const [dayAge, setDayAge] = useState(null);
  const [lastProdukTypeSelected, setLastProdukTypeSelected] = useState("");
  const [step, setStep] = useState(1);
  const [standardPerformance, setStandardPerformance] = useState([]);
  const [productFeed, setProductFeed] = useState([]);
  const [productOvk, setProductOvk] = useState([""]);
  const [jumlahFeed, setJumlahFeed] = useState([]);
  const [jumlahOvk, setJumlahOvk] = useState([""]);

  const [culling, setCulling] = useState("");
  const [mortalitas, setMortalitas] = useState("");
  const [bobotTimbang, setBobotTimbang] = useState("");
  const [populasi, setPopulasi] = useState("");

  const [validationJumlahPemberianError, setValidationJumlahPemberianError] =
    useState([]);
  const [
    validationJumlahPemberianOverStok,
    setValidationJumlahPemberianOverStock,
  ] = useState([""]);
  const [validationPopulationError, setValidationPopulationError] =
    useState("");
  const [validationBobotTimbangError, setValidationBobotTimbangError] =
    useState("");
  const [validationCullingError, setValidationCullingError] = useState("");
  const [validationMortalityError, setValidationMortalityError] = useState("");

  const [indexActive, setIndexActive] = useState(0);

  const [productIdExclude, setProductIdExclude] = useState([]);
  const [productLabel, setProductLabel] = useState([]);
  const [productStock, setProductStock] = useState([]);
  const [jumlahProduk, setJumlahProduk] = useState([""]);
  const [productType, setProductType] = useState([""]);


  const productIdExcludeString = productIdExclude.join(",");

  const [step1Data, setStep1Data] = useState({
    produkList: [
      {
        usedFeeds: [],
        usedOvks: [],
      },
    ],
  });


  const isSubmitStep1Disabled =
  productFeed?.length === 0 || productFeed?.every((feed) => feed === "") ||
  !(
    validationJumlahPemberianError?.length === 0 ||
    validationJumlahPemberianError?.every((error) => error === "")
  ) ||
  (jumlahProduk?.some((jmlProduk) => jmlProduk === ""));

  const handleOpenAddDialog = () => {
    setAddDialogOpen(true);
  };

  const handleCloseAddDialog = () => {
    setAddDialogOpen(false);
  };

  useEffect(() => {
    const fetchNextRecord = async () => {
      try {
        const params = {
          periodId: id,
        };
        const response = await api.get("/v1/dailyRecords/nextRecord", {
          params,
        });
        if (response?.data?.day > 0) {
          setSelectedInDate(response?.data?.date);
        }

        setDayAge(response?.data?.day);
      } catch (error) {
        Sentry.captureException(error);
      }
    };

    const fetchProducts = async () => {
      try {
        const params = {
          search: searchProduct[indexActive],
          periodId: id,
          exclude: productIdExcludeString,
        };
        const response = await api.get("/v1/sapronak/stocks", { params });
        setListProducts(response?.data);
      } catch (error) {
        Sentry.captureException(error);
      }
    };

    fetchNextRecord();
    fetchProducts();
  }, [searchProduct, indexActive, id, productIdExcludeString]);

  useEffect(() => {
    const fetchPerformanceStandard = async () => {
      try {
        const params = {
          age: dayAge,
        };
        const response = await api.get("/v1/performanceStandard/daily", {
          params,
        });
        setStandardPerformance(response?.data?.contents);
      } catch (error) {
        Sentry.captureException(error);
      }
    };

    fetchPerformanceStandard();
  }, [dayAge]);

  const handleSelectFeed = (selectedFeed, index) => {
    setIndexActive(index);

    // Tambahkan id produk feed yang dipilih ke dalam string
    const selectedFeedId = selectedFeed.id.toString();

    const selectedLabel = selectedFeed.labels;

    const selectedStock = selectedFeed.stock / 50;

    setProductIdExclude((prevIds) => {
      const newIds = [...prevIds];
      newIds[index] = selectedFeedId;
      return newIds;
    });

    setProductLabel((prevLabels) => {
      const newLabels = [...prevLabels];
      newLabels[index] = selectedLabel;
      return newLabels;
    });

    setProductStock((prevStocks) => {
      const newStocks = [...prevStocks];
      newStocks[index] = selectedStock;
      return newStocks;
    });

    setProductLabel((prevLabels) => {
      const newLabels = [...prevLabels];
      newLabels[index] = selectedLabel;
      return newLabels;
    });

    setProductFeed((prevFeeds) => {
      const newFeeds = [...prevFeeds];
      newFeeds[index] = selectedLabel;
      return newFeeds;
    });

    setLastProdukTypeSelected(selectedFeed.type);

    setProductType((prevTypes) => {
      const newTypes = [...prevTypes];
      newTypes[index] = selectedFeed.type;
      return newTypes;
    });

    // Update state step1Data saat feed dipilih
    setStep1Data((prevStep1Data) => {
      const updatedProdukList = [...prevStep1Data.produkList];

      if (updatedProdukList[index]) {
        // Jika jenis produkList di indeks tersebut sudah ada, update
        if (updatedProdukList[index].usedFeeds) {
          updatedProdukList[index] = {
            ...updatedProdukList[index],
            usedFeeds: [
              {
                feed: {
                  id: selectedFeed.id,
                  label: selectedFeed.labels,
                  type: selectedFeed.type,
                  stock: selectedFeed.stock,
                },
              },
            ],
          };
        } else {
          // Jika jenis produkList di indeks tersebut belum ada, tambahkan
          updatedProdukList[index] = {
            usedFeeds: [
              {
                feed: { id: selectedFeed.id, label: selectedFeed.labels },
              },
            ],
          };
        }
      } else {
        // Jika jenis produkList di indeks tersebut belum ada, tambahkan
        updatedProdukList[index] = {
          usedFeeds: [
            {
              feed: {
                id: selectedFeed.id,
                label: selectedFeed.labels,
                type: selectedFeed.type,
                stock: selectedFeed.stock,
              },
            },
          ],
        };
      }

      // Kosongkan usedOvks jika sudah ada data sebelumnya
      if (updatedProdukList[index].usedOvks) {
        updatedProdukList[index].usedOvks = [];

        setJumlahProduk((prevJumlahProduks) => {
          const newJumlahProduks = [...prevJumlahProduks];
          newJumlahProduks[index] = "";
          return newJumlahProduks;
        });
      }

      return {
        ...prevStep1Data,
        produkList: updatedProdukList,
      };
    });
  };

  const handleSelectOvk = (selectedOvk, index) => {
    const selectedOvkId = selectedOvk.id.toString();
    const selectedLabel = selectedOvk.labels;
    const selectedStock = selectedOvk.stock;

    setProductIdExclude((prevIds) => {
      const newIds = [...prevIds];
      newIds[index] = selectedOvkId;
      return newIds;
    });

    setProductLabel((prevLabels) => {
      const newLabels = [...prevLabels];
      newLabels[index] = selectedLabel;
      return newLabels;
    });

    setProductStock((prevStocks) => {
      const newStocks = [...prevStocks];
      newStocks[index] = selectedStock;
      return newStocks;
    });

    setLastProdukTypeSelected(selectedOvk.type);

    setProductType((prevTypes) => {
      const newTypes = [...prevTypes];
      newTypes[index] = selectedOvk.type;
      return newTypes;
    });

    setProductOvk((prevOvks) => {
      const newOvks = [...prevOvks];
      newOvks[index] = selectedLabel;
      return newOvks;
    });

    setProductFeed((prevFeeds) => {
      const newFeeds = [...prevFeeds];
      newFeeds[index] = "";
      return newFeeds;
    });

    // Update state step1Data saat ovk dipilih
    setStep1Data((prevStep1Data) => {
      const updatedProdukList = [...prevStep1Data.produkList];

      if (updatedProdukList[index]) {
        // Jika jenis produkList di indeks tersebut sudah ada, update
        if (updatedProdukList[index].usedOvks) {
          updatedProdukList[index] = {
            ...updatedProdukList[index],
            usedOvks: [
              {
                ovk: {
                  id: selectedOvk.id,
                  label: selectedOvk.labels,
                  type: selectedOvk.type,
                  stock: selectedOvk.stock,
                },
              },
            ],
          };
        } else {
          // Jika jenis produkList di indeks tersebut belum ada, tambahkan
          updatedProdukList[index] = {
            usedOvks: [
              {
                ovk: {
                  id: selectedOvk.id,
                  label: selectedOvk.labels,
                  type: selectedOvk.type,
                  stock: selectedOvk.stock,
                },
              },
            ],
          };
        }
      } else {
        // Jika jenis produkList di indeks tersebut belum ada, tambahkan
        updatedProdukList[index] = {
          usedOvks: [
            {
              ovk: {
                id: selectedOvk.id,
                label: selectedOvk.labels,
                type: selectedOvk.type,
                stock: selectedOvk.stock,
              },
            },
          ],
        };
      }

      // Kosongkan usedFeeds jika sudah ada data sebelumnya
      if (updatedProdukList[index].usedFeeds) {
        updatedProdukList[index].usedFeeds = [];

        setJumlahProduk((prevJumlahProduks) => {
          const newJumlahProduks = [...prevJumlahProduks];
          newJumlahProduks[index] = "";
          return newJumlahProduks;
        });

        setJumlahFeed((prevJumlahFeed) => {
          const updatedJumlahFeed = [...prevJumlahFeed];
          updatedJumlahFeed.splice(index, 1);
          return updatedJumlahFeed;
        });
      }

      return {
        ...prevStep1Data,
        produkList: updatedProdukList,
      };
    });
  };

  const saveDailyRecords = async () => {
    setIsSubmitting(true);
    const { produkList } = step1Data;

    const requestBody = {
      rearingPeriod: { id: id },
      samplingWeight: bobotTimbang,
      samplingPopulation: populasi,
      usedFeeds: [],
      usedOvks: [],
    };

    if (mortalitas) {
      requestBody.mortality = mortalitas;
    }

    if (culling) {
      requestBody.culling = culling;
    }

    produkList.forEach((produk, index) => {
      if (produk.usedFeeds.length > 0) {
        requestBody.usedFeeds.push({
          feed: { id: produk.usedFeeds[0].feed.id },
          qtyUsed: parseInt(jumlahProduk[index], 10) * 50,
        });
      }

      if (produk.usedOvks.length > 0) {
        requestBody.usedOvks.push({
          ovk: { id: produk.usedOvks[0].ovk.id },
          qtyUsed: parseInt(jumlahProduk[index], 10),
        });
      }
    });

    try {
      const response = await api.post("/v1/dailyRecords", requestBody);
      // console.log("Data berhasil disimpan:", response);

      setAddSuccess(true);
      navigate(`/periode-budidaya/periode-berjalan/${id}/dataharian`);
      localStorage.setItem("responseAddSuccess", JSON.stringify(!isAddSuccess));
      localStorage.setItem("settingType", "data");

      if (onReloadData) {
        onReloadData();
      }

      handleCloseAddDialog();
      return "response";
    } catch (error) {
      handleCloseAddDialog();
      setIsSubmitting(false);
      console.error("Gagal menambahkan data harian", error.response);
      setIsAddError(true);
      setErrorMessage(
        error.response.data.error?.name || error.response.data.error
      );
      setTimeout(() => setIsAddError(false), 2000);

      setIsAddError(true);
      setErrorMessage(
        error.response?.data?.error?.name || error.response?.data?.error
      );
      setTimeout(() => setIsAddError(false), 2000);
    }
  };

  const handleBack = () => {
    navigate(`/periode-budidaya/periode-berjalan/${id}/dataharian`);
    localStorage.setItem("settingType", "data");
  };

  const calculateTotalPakan = () => {
    let totalPakan = 0;

    step1Data.produkList.forEach((produk, index) => {
      produk.usedFeeds.forEach((feed) => {
        totalPakan += parseInt(jumlahProduk[index], 10);
      });
    });

    return totalPakan;
  };

  const calculateTotalOvk = () => {
    let totalOvk = 0;

    step1Data.produkList.forEach((produk, index) => {
      produk.usedOvks.forEach((ovk) => {
        totalOvk += parseInt(jumlahProduk[index], 10);
      });
    });

    return totalOvk;
  };

  // const handleJumlahChange = (e, index) => {
  //   const jumlahPemberian = e.target.value;
  //   console.log("jumlah: ", jumlahPemberian);
  //   console.log("produk stok:", productStock[index]);

  //   if (
  //     (!isNaN(jumlahPemberian) && /^[0-9]+$/.test(jumlahPemberian)) ||
  //     jumlahPemberian === ""
  //   ) {
  //     setJumlahProduk((prevJumlahProduks) => {
  //       const newJumlahProduks = [...prevJumlahProduks];
  //       newJumlahProduks[index] = jumlahPemberian;
  //       return newJumlahProduks;
  //     });

  //     if (productType[index] === "feed") {
  //       setJumlahFeed((prevJumlahFeeds) => {
  //         const newJumlahFeeds = [...prevJumlahFeeds];
  //         newJumlahFeeds[index] = jumlahPemberian;
  //         return newJumlahFeeds;
  //       });
  //     }

  //     if (productStock[index]) {
  //       if (jumlahPemberian <= productStock[index]) {

  //         setValidationJumlahPemberianError((prevValidationJumlahPemberianErrors) => {
  //           const updatedValidationJumlahPemberianError = [...prevValidationJumlahPemberianErrors];
  //           updatedValidationJumlahPemberianError.splice(index, 1);
  //           return updatedValidationJumlahPemberianError;
  //         });

  //       } else {
  //         setValidationJumlahPemberianError((prevValidationJumlahErrors) => {
  //           const newValidationErrors = [...prevValidationJumlahErrors];
  //           newValidationErrors[index] = "Jumlah pemberian pakan melebihi sisa stok";
  //           return newValidationErrors;
  //         });
  //       }
  //     }

  //   } else {
  //     setValidationJumlahPemberianError((prevValidationJumlahErrors) => {
  //       const newValidationErrors = [...prevValidationJumlahErrors];
  //       newValidationErrors[index] = "Masukkan jumlah pemberian yang sesuai";
  //       return newValidationErrors;
  //     });
  //   }
  // };

  const handleJumlahChange = (e, index) => {
    const jumlahPemberian = e.target.value;

    if (
      (!isNaN(jumlahPemberian) && /^[0-9]+$/.test(jumlahPemberian)) ||
      jumlahPemberian === ""
    ) {
      setValidationJumlahPemberianError((prevValidationJumlahErrors) => {
        const newValidationErrors = [...prevValidationJumlahErrors];
        newValidationErrors[index] = "";
        return newValidationErrors;
      });

      setValidationJumlahPemberianError((prevValidationJumlahErrors) => {
        const newValidationErrors = [...prevValidationJumlahErrors];

        // Jika validasi berhasil, hapus elemen error di index tersebut
        if (newValidationErrors[index]) {
          newValidationErrors.splice(index, 1);
        }
        return newValidationErrors;
      });

      setJumlahProduk((prevJumlahProduks) => {
        const newJumlahProduks = [...prevJumlahProduks];

        if (jumlahPemberian === "") {
          // newJumlahProduks.splice(index, 1);
          newJumlahProduks[index] = "";
        } else {
          newJumlahProduks[index] = jumlahPemberian;
        }

        return newJumlahProduks;
      });

      if (productType[index] === "feed") {
        setJumlahFeed((prevJumlahFeeds) => {
          const newJumlahFeeds = [...prevJumlahFeeds];

          if (jumlahPemberian === "") {
            // newJumlahFeeds.splice(index, 1);
            newJumlahFeeds[index] = "";
          } else {
            newJumlahFeeds[index] = jumlahPemberian;
          }

          return newJumlahFeeds;
        });
      }

      if (productType[index] === "ovk") {
        setJumlahOvk((prevJumlahOvks) => {
          const newJumlahOvks = [...prevJumlahOvks];

          if (jumlahPemberian === "") {
            // newJumlahOvks.splice(index, 1);
            newJumlahOvks[index] = "";
          } else {
            newJumlahOvks[index] = jumlahPemberian;
          }

          return newJumlahOvks;
        });
      }

      if (productStock[index]) {
        if (jumlahPemberian <= productStock[index]) {
          setValidationJumlahPemberianError((prevValidationJumlahErrors) => {
            const newValidationErrors = [...prevValidationJumlahErrors];
            newValidationErrors[index] = "";
            return newValidationErrors;
          });
        } else {
          setValidationJumlahPemberianError((prevValidationJumlahErrors) => {
            const newValidationErrors = [...prevValidationJumlahErrors];
            newValidationErrors[index] =
              "Jumlah pemberian pakan melebihi sisa stok";
            return newValidationErrors;
          });
        }
      }
    } else {
      setValidationJumlahPemberianError((prevValidationJumlahErrors) => {
        const newValidationErrors = [...prevValidationJumlahErrors];
        newValidationErrors[index] = "Masukkan jumlah pemberian yang sesuai";
        return newValidationErrors;
      });
    }
  };

  const handleCullingChange = (e) => {
    const inputValue = e.target.value;

    // Check if the input is a non-negative number
    if (
      (!isNaN(inputValue) && /^[0-9]+$/.test(inputValue)) ||
      inputValue === ""
    ) {
      setCulling(inputValue);
      setValidationCullingError("");
    } else {
      setValidationCullingError("Masukkan pemisahan yang sesuai");
    }
  };

  const handleMortalitasChange = (e) => {
    const inputValue = e.target.value;

    // Check if the input is a non-negative number
    if (
      (!isNaN(inputValue) && /^[0-9]+$/.test(inputValue)) ||
      inputValue === ""
    ) {
      setMortalitas(inputValue);
      setValidationMortalityError("");
    } else {
      setValidationMortalityError("Masukkan kematian yang sesuai");
    }
  };

  const handlePopulasiChange = (e) => {
    const inputValue = e.target.value;

    // Check if the input is a non-negative number
    if (
      (!isNaN(inputValue) && /^[0-9]+$/.test(inputValue)) ||
      inputValue === ""
    ) {
      setPopulasi(inputValue);
      setValidationPopulationError("");
    } else {
      setValidationPopulationError("Masukkan jumlah sampling yang sesuai");
    }
  };

  const handleBobotTimbangChange = (e) => {
    const inputValue = e.target.value;
    // Check if the input is a non-negative number
    if (
      (!isNaN(inputValue) && /^[0-9]+$/.test(inputValue)) ||
      inputValue === ""
    ) {
      setBobotTimbang(inputValue);
      setValidationBobotTimbangError("");
    } else {
      setValidationBobotTimbangError("Masukkan total bobot ayam yang sesuai");
    }
  };

  const handleDeleteProduct = (index) => {
    const updatedList = [...step1Data.produkList];
    updatedList.splice(index, 1);
    setStep1Data({
      ...step1Data,
      produkList: updatedList,
    });

    // Menghapus nilai productIdExclude pada indeks yang sesuai
    const updatedIdExclude = [...productIdExclude];
    updatedIdExclude.splice(index, 1);
    setProductIdExclude(updatedIdExclude);

    setSearchProduct((prevSearchProduct) => {
      const updatedSearchProduct = [...prevSearchProduct];
      updatedSearchProduct.splice(index, 1);
      return updatedSearchProduct;
    });

    setJumlahProduk((prevJumlahProduk) => {
      const updatedJumlahProduk = [...prevJumlahProduk];
      updatedJumlahProduk.splice(index, 1);
      return updatedJumlahProduk;
    });

    setProductLabel((prevProductLabel) => {
      const updatedProductLabel = [...prevProductLabel];
      updatedProductLabel.splice(index, 1);
      return updatedProductLabel;
    });

    setProductStock((prevProductStock) => {
      const updatedProductStock = [...prevProductStock];
      updatedProductStock.splice(index, 1);
      return updatedProductStock;
    });

    setProductFeed((prevProductFeed) => {
      const updatedProductFeed = [...prevProductFeed];
      updatedProductFeed.splice(index, 1);
      return updatedProductFeed;
    });

    setProductOvk((prevProductOvk) => {
      const updatedProductOvk = [...prevProductOvk];
      updatedProductOvk.splice(index, 1);
      return updatedProductOvk;
    });

    setJumlahFeed((prevJumlahFeed) => {
      const updatedJumlahFeed = [...prevJumlahFeed];
      updatedJumlahFeed.splice(index, 1);
      return updatedJumlahFeed;
    });

    setJumlahOvk((prevJumlahOvk) => {
      const updatedJumlahOvk = [...prevJumlahOvk];
      updatedJumlahOvk.splice(index, 1);
      return updatedJumlahOvk;
    });

    setProductType((prevProductType) => {
      const updatedProductType = [...prevProductType];
      updatedProductType.splice(index, 1);

      return updatedProductType;
    });
  };

  const handleSetActiveIndex = (index) => {
    setIndexActive(index);
  };

  const handleSetSearchProduct = (value, index) => {
    setSearchProduct((prevSearchProduct) => {
      const newSearchProduct = [...prevSearchProduct];
      newSearchProduct[index] = value;
      return newSearchProduct;
    });
    setIndexActive(index);
  };

  const handleNext = () => !isLastStep && setActiveStep((cur) => cur + 1);
  const handlePrev = () => !isFirstStep && setActiveStep((cur) => cur - 1);

  const nextStep = () => {
    setStep(step + 1);
    handleNext();
  };

  const prevStep = () => {
    setStep(step - 1);
    handlePrev();
  };

  return (
    <div className="flex justify-center p-4">
      <Card className="w-[800px] border">
        <CardHeader floated={false} shadow={false} className="rounded-b-none">
          <Typography className="text-xl font-semibold flex items-center text-text-primary">
            <div className="cursor-pointer pr-2" onClick={handleBack}>
              <ChevronLeft />
            </div>
            Tambah data harian
          </Typography>
          <Typography className="text-sm text-text-tertiary py-1 pl-8 font-normal">
            {dayjs(selectedInDate).locale("id").format("DD MMMM YYYY")} | Hari
            ke-{dayAge}
          </Typography>
        </CardHeader>
        <CardBody className="flex justify-center border-t-2">
          <div className=" w-[500px] py-9 px-8">
            <Stepper
              activeStep={activeStep}
              isLastStep={(value) => setIsLastStep(value)}
              isFirstStep={(value) => setIsFirstStep(value)}
              lineClassName=""
              activeLineClassName="bg-blue-600"
            >
              <Step
                className={`h-7 w-7 !bg-neutral-100 border-2 text-neutral-300 cursor-pointer`}
                activeClassName="ring ring-blue-200 !bg-blue-600 text-white"
                completedClassName="!bg-blue-600 text-white"
              >
                {activeStep === 1 || activeStep === 2 ? (
                  <MdOutlineDone className="h-5 w-5" />
                ) : (
                  1
                )}
                <div className="absolute pt-4 -bottom-[1.5rem] w-max text-center">
                  <Typography
                    className={
                      activeStep === 0
                        ? "text-sm font-semibold text-text-secondary"
                        : "text-sm font-semibold text-text-quarterary"
                    }
                  >
                    Pemberian pakan & OVK
                  </Typography>
                </div>
              </Step>
              <Step
                className="h-7 w-7 !bg-neutral-100 border-2 text-neutral-300 cursor-pointer"
                activeClassName="ring ring-blue-200 !bg-blue-600 text-white"
                completedClassName="!bg-blue-600 text-white"
              >
                {activeStep === 2 ? <MdOutlineDone className="h-5 w-5" /> : 2}
                <div className="absolute pt-4 -bottom-[1.5rem] w-max text-center">
                  <Typography
                    className={
                      activeStep === 1
                        ? "text-sm font-semibold text-text-secondary"
                        : "text-sm font-semibold text-text-quarterary"
                    }
                  >
                    Deplesi & bobot
                  </Typography>
                </div>
              </Step>
              <Step
                className="h-7 w-7 !bg-neutral-100 border-2 text-neutral-300 cursor-pointer"
                activeClassName="ring ring-blue-200 !bg-blue-600 text-white"
                completedClassName="!bg-blue-600 text-white"
              >
                {activeStep === 3 ? <MdOutlineDone className="h-5 w-5" /> : 3}
                <div className="absolute pt-4 -bottom-[1.5rem] w-max text-center">
                  <Typography
                    className={
                      activeStep === 2
                        ? "text-sm font-semibold text-text-secondary"
                        : "text-sm font-semibold text-text-quarterary"
                    }
                  >
                    Ringkasan
                  </Typography>
                </div>
              </Step>
            </Stepper>
          </div>
        </CardBody>
        {/* Konten Form */}
        {step === 1 && (
          <div>
            <Card className="border-none shadow-none">
              <CardBody className="p-5">
                <div className="mb-4">
                  <div></div>
                </div>
                <div className="w-[752px] h-px flex-col justify-center items-center inline-flex">
                  <div className="w-[752px] h-px relative bg-gray-200" />
                </div>
                <Typography className="text-xl font-semibold my-2 text-text-primary">
                  Pakan dan OVK
                </Typography>

                <Card
                  style={{ marginBottom: "20px" }}
                  className="border border-neutral-300 bg-bg-secondary shadow-none p-0"
                >
                  {step1Data.produkList.map((produk, index) => (
                    <CardBody
                      key={index}
                      className="border-b border-bg-quarternary p-2xl"
                    >
                      <div>
                        <div className="grid grid-cols-2">
                          <div className="col-span-1 py-md">
                            <label className="block text-sm font-medium text-text-primary">
                              Produk
                              <span className="text-text-errorPrimary text-sm font-normal leading-tight">
                                *
                              </span>
                            </label>
                          </div>
                          <div className="col-span-1 text-right">
                            {index !== 0 && (
                              <Button
                                type={`btnLinkRed`}
                                label="Hapus"
                                size="small"
                                onClick={() => handleDeleteProduct(index)}
                                componentLeft={<FaTrash className="ml-1" />}
                              />
                            )}
                          </div>
                        </div>
                        <DropdownStockSearch
                          id={`produkList-` + index}
                          placeholder="Pilih Produk"
                          options={listProducts}
                          onSelectFeed={(selectedFeed) =>
                            handleSelectFeed(selectedFeed, index)
                          }
                          onSelectOvk={(selectedOvk) =>
                            handleSelectOvk(selectedOvk, index)
                          }
                          label={productLabel[index]}
                          selectedStock={productStock[index]}
                          setSearch={(value) =>
                            handleSetSearchProduct(value, index)
                          }
                          indexProduct={index}
                          handleSetActiveIndex={handleSetActiveIndex}
                          search={searchProduct[index] || ""} // Mengambil nilai pencarian pada indeks tertentu
                          size="medium"
                          type="text"
                        />
                      </div>

                      <div className="mt-3xl">
                        <label className="block text-sm font-medium text-text-primary mb-md">
                          Jumlah pemberian pakan atau ovk{" "}
                          <span className="text-red-600 text-sm font-normal leading-tight">
                            *
                          </span>
                        </label>
                        <Input
                          type="text"
                          id={`jumlahPemberian-${index}`}
                          placeholder="Masukkan Jumlah Pemberian"
                          size="medium"
                          value={jumlahProduk[index]}
                          onChange={(e) => handleJumlahChange(e, index)}
                          componentRight={
                            productType[index] === "feed"
                              ? "zak"
                              : productType[index] === "ovk"
                              ? "item"
                              : ""
                          }
                        />
                        {validationJumlahPemberianError[index] && (
                          <p className="text-red-500 text-sm mt-2">
                            {validationJumlahPemberianError[index]}
                          </p>
                        )}

                        {validationJumlahPemberianOverStok[index] && (
                          <p className="text-red-500 text-sm mt-2">
                            {validationJumlahPemberianOverStok[index]}
                          </p>
                        )}
                      </div>
                    </CardBody>
                  ))}
                  <CardFooter>
                    <Button
                      size="small"
                      type={listProducts.totalItems === 0 ? "btnLinkDisabled" : "btnLinkBlue"}
                      onClick={() => {
                        setIndexActive(indexActive + 1);
                        setStep1Data({
                          ...step1Data,
                          produkList: [
                            ...step1Data.produkList,
                            {
                              usedFeeds: [],
                              usedOvks: [],
                            },
                          ],
                        });
                        setSearchProduct((prevSearchProduct) => {
                          const updatedSearchProduct = [...prevSearchProduct];
                          updatedSearchProduct.splice(indexActive + 1, 0, "");
                          return updatedSearchProduct;
                        });
                        setJumlahProduk((prevJumlahProduk) => {
                          const updatedJumlahProduk = [...prevJumlahProduk];
                          updatedJumlahProduk.splice(indexActive + 1, 0, "");
                          return updatedJumlahProduk;
                        });
                      }}
                      label="Tambah produk"
                      componentLeft={<FaPlus className="w-4 h-4 mt-1"></FaPlus>}
                      disabled={listProducts.totalItems === 0 ? true : false}
                    />
                  </CardFooter>
                </Card>
              </CardBody>
              {/* Save Button */}
              <CardFooter className="flex justify-end border-t border-blue-gray-50 p-lg">
                <Button
                  label="Batal"
                  size="medium"
                  type="btnSecondary"
                  className="mr-md"
                  onClick={handleBack}
                />
                <Button
                  label="Selanjutnya"
                  disabled={isSubmitStep1Disabled || isSubmitting}
                  size="medium"
                  type={`${
                    isSubmitStep1Disabled || isSubmitting
                      ? "btnPrimaryDisabled"
                      : "btnPrimary"
                  }`}
                  onClick={() => nextStep()}
                />
              </CardFooter>
            </Card>
          </div>
        )}

        {step === 2 && (
          <div>
            <Card className="border-none shadow-none">
              <CardBody className="p-5">
                <Typography className="text-xl font-semibold my-2 text-text-primary">
                  Deplesi
                </Typography>
                <Card
                  style={{ marginBottom: "20px" }}
                  className="border border-border-primary bg-bg-secondary shadow-none"
                >
                  <CardBody className="grid grid-cols-2 gap-4">
                    <div className="col-span-1">
                      <label className="block text-sm font-medium text-text-primary mb-2">
                        Kematian{" "}
                      </label>
                      <Input
                        type="text"
                        id="mortality"
                        placeholder="Kematian"
                        className="max-w-sm"
                        value={mortalitas}
                        size="medium"
                        onChange={handleMortalitasChange}
                        componentRight={"ekor"}
                      />
                      {validationMortalityError && (
                        <p className="text-red-500 text-sm mt-2">
                          {validationMortalityError}
                        </p>
                      )}
                    </div>
                    <div className="col-span-1">
                      <label className="block text-sm font-medium text-text-primary mb-2">
                        Pemisahan{" "}
                      </label>
                      <Input
                        type="text"
                        id="culling"
                        placeholder="Pemisahan"
                        className="max-w-sm"
                        value={culling}
                        size="medium"
                        onChange={handleCullingChange}
                        componentRight={"ekor"}
                      />
                      {validationCullingError && (
                        <p className="text-red-600 text-sm mt-2">
                          {validationCullingError}
                        </p>
                      )}
                    </div>
                  </CardBody>
                </Card>

                <Typography className="text-xl font-semibold my-2">
                  Sampling
                </Typography>
                <Card
                  style={{ marginBottom: "20px" }}
                  className="border border-border-primary bg-bg-secondary shadow-none"
                >
                  <CardBody className="grid grid-cols-2 gap-4">
                    <div className="col-span-1">
                      <label className="block text-sm font-medium text-text-primary mb-2">
                        Total Bobot Ayam
                      </label>
                      <Input
                        type="text"
                        id="bobotTimbang"
                        placeholder="Masukkan total bobot ayam"
                        className="max-w-sm"
                        size="medium"
                        value={bobotTimbang}
                        onChange={handleBobotTimbangChange}
                        componentRight={"g"}
                      />
                      {validationBobotTimbangError && (
                        <p className="text-red-500 text-sm mt-2">
                          {validationBobotTimbangError}
                        </p>
                      )}
                    </div>
                    <div className="col-span-1">
                      <label className="block text-sm font-medium text-text-primary mb-2">
                        Jumlah Sampling
                      </label>
                      <Input
                        type="text"
                        id="population"
                        placeholder="Masukkan populasi"
                        className="max-w-sm"
                        size="medium"
                        value={populasi}
                        onChange={handlePopulasiChange}
                        componentRight={"ekor"}
                      />
                      {validationPopulationError && (
                        <p className="text-red-500 text-sm mt-2">
                          {validationPopulationError}
                        </p>
                      )}
                    </div>
                  </CardBody>
                </Card>
                <div className="my-4"></div>

                <CardFooter className="flex justify-end border-t border-blue-gray-50 p-lg">
                  <Button
                    label="Kembali"
                    size="medium"
                    type="btnSecondary"
                    className="mr-md"
                    onClick={() => prevStep()}
                  />
                  <Button
                    label="Selanjutnya"
                    disabled={isSubmitting}
                    size="medium"
                    type={`${
                      isSubmitting ? "btnPrimaryDisabled" : "btnPrimary"
                    }`}
                    onClick={() => nextStep()}
                  />
                </CardFooter>
              </CardBody>
            </Card>
          </div>
        )}

        {step === 3 && (
          <div>
            <Card className="border-none shadow-none">
              <CardBody className="p-5">
                <Typography className="text-lg font-semibold my-2 text-text-secondary">
                  Pakan
                </Typography>

                <Card className="p-3 border my-2 shadow-none border-neutral-200">
                  <CardHeader
                    floated={false}
                    shadow={false}
                    className="grid grid-cols-2 gap-4 rounded-none p-0 m-0"
                  >
                    <div className="col-span-1">
                      <Typography className="text-md font-semibold my-2 text-text-secondary">
                        Merk Pakan
                      </Typography>
                    </div>
                    <div className="col-span-1">
                      <Typography className="text-md font-semibold text-right my-2 text-text-secondary">
                        Berat
                      </Typography>
                    </div>
                  </CardHeader>
                  <CardBody className="border-t-2 rounded-none p-0 m-0 py-2">
                    {step1Data.produkList.every(
                      (produk) => !produk.usedFeeds[0]
                    ) ? (
                      <div className="my-2 text-text-secondary">
                        Tidak ada data
                      </div>
                    ) : (
                      step1Data.produkList.map((produk, index) =>
                        produk.usedFeeds[0] ? (
                          <div key={index} className="grid grid-cols-2 gap-4">
                            <div className="col-span-1">
                              <Typography className="text-sm font-normal my-2 text-text-secondary">
                                {produk.usedFeeds[0]?.feed?.label}
                              </Typography>
                            </div>
                            <div className="col-span-1">
                              <Typography className="text-sm font-normal text-right my-2 text-text-secondary">
                                {jumlahProduk[index]} zak
                              </Typography>
                            </div>
                          </div>
                        ) : null
                      )
                    )}
                  </CardBody>
                  <CardFooter className="grid grid-cols-2 gap-4 border-t-2 border-dotted border-neutral-300 rounded-none p-0 m-0">
                    <div className="col-span-1 mt-3">
                      <Typography className="text-left text-md font-semibold text-text-primary">
                        Total
                      </Typography>
                    </div>
                    <div className="col-span-1 mt-3">
                      <Typography className="text-md font-semibold text-right text-text-primary">
                        {calculateTotalPakan()} zak
                      </Typography>
                    </div>
                  </CardFooter>
                </Card>

                <Typography className="text-lg font-semibold my-2 mt-6 text-text-secondary">
                  OVK
                </Typography>

                <Card className="p-3 border my-2 shadow-none border-neutral-200">
                  <CardHeader
                    floated={false}
                    shadow={false}
                    className="grid grid-cols-2 gap-4 rounded-none p-0 m-0"
                  >
                    <div className="col-span-1">
                      <Typography className="text-md font-semibold my-2 text-text-secondary">
                        Merk OVK
                      </Typography>
                    </div>
                    <div className="col-span-1">
                      <Typography className="text-md font-semibold text-right my-2 text-text-secondary">
                        Qty
                      </Typography>
                    </div>
                  </CardHeader>
                  <CardBody className="border-t border-neutral-300 rounded-none m-0 p-0 py-2">
                    {step1Data.produkList.every(
                      (produk) => !produk.usedOvks[0]
                    ) ? (
                      <div className="m-0 text-text-secondary my-2">
                        Tidak ada data
                      </div>
                    ) : (
                      step1Data.produkList.map((produk, index) =>
                        produk.usedOvks[0] ? (
                          <div key={index} className="grid grid-cols-2 gap-4">
                            <div className="col-span-1">
                              <Typography className="text-sm font-normal my-2">
                                {produk.usedOvks[0]?.ovk?.label}
                              </Typography>
                            </div>
                            <div className="col-span-1">
                              <Typography className="text-sm font-normal text-right my-2 text-text-secondary">
                                {jumlahProduk[index]} item
                              </Typography>
                            </div>
                          </div>
                        ) : null
                      )
                    )}
                  </CardBody>
                  <CardFooter className="m-0 p-0 grid grid-cols-2 gap-4 border-t-2 border-dotted rounded-none border-neutral-200">
                    <div className="col-span-1 mt-3">
                      <Typography className="text-left text-md font-semibold text-text-primary">
                        Total
                      </Typography>
                    </div>
                    <div className="col-span-1 mt-3">
                      <Typography className="text-md font-semibold text-right text-text-primary">
                        {calculateTotalOvk()} item
                      </Typography>
                    </div>
                  </CardFooter>
                </Card>

                <Typography className="text-lg font-semibold my-2 mt-6 text-text-secondary">
                  Deplesi
                </Typography>

                <Card className="p-3 border my-2 shadow-none border-neutral-200">
                  <CardHeader
                    floated={false}
                    shadow={false}
                    className="grid grid-cols-2 gap-2 rounded-none p-0 m-0"
                  >
                    <div className="col-span-1">
                      <Typography className="text-sm font-normal my-2 text-text-secondary">
                        Kematian
                      </Typography>
                    </div>
                    <div className="col-span-1">
                      <Typography className="text-sm font-normal text-right my-2 text-text-secondary">
                        {mortalitas || "0"} ekor
                      </Typography>
                    </div>
                    <div className="col-span-1">
                      <Typography className="text-sm font-normal my-2 text-text-secondary">
                        Pemisahan
                      </Typography>
                    </div>
                    <div className="col-span-1">
                      <Typography className="text-sm font-normal text-right my-2 text-text-secondary">
                        {culling || "0"} ekor
                      </Typography>
                    </div>
                  </CardHeader>
                  <CardBody className="grid grid-cols-2 gap-4 border-t-2 border-dotted border-neutral-300 rounded-none p-0 m-0">
                    <div className="col-span-1 my-3">
                      <Typography className="text-md font-semibold text-text-primary">
                        Total
                      </Typography>
                    </div>
                    <div className="col-span-1 my-3">
                      <Typography className="text-md font-semibold text-right text-red-500">
                        {parseInt(culling || 0, 10) +
                          parseInt(mortalitas || 0, 10)}{" "}
                        ekor
                      </Typography>
                    </div>
                  </CardBody>
                  <CardFooter className="grid grid-cols-2 gap-4 border-t-2 rounded-none text-text-secondary m-0 p-0">
                    <div className="col-span-1 my-3">
                      <Typography className="text-sm font-normal">
                        Std
                      </Typography>
                    </div>
                    <div className="col-span-1 my-3">
                      <Typography className="text-sm font-normal text-right text-text-secondary">
                        {standardPerformance[0].depletion.toLocaleString(
                          "id-ID"
                        )}{" "}
                        ekor
                      </Typography>
                    </div>
                  </CardFooter>
                </Card>

                <Typography className="text-lg font-semibold my-2 mt-6 text-text-primary">
                  BW
                </Typography>

                <Card className="p-3 border my-2 shadow-none border-neutral-200">
                  <CardBody className="grid grid-cols-2 gap-4 rounded-none p-0 m-0">
                    <div className="col-span-1">
                      <Typography className="text-sm font-normal text-text-secondary">
                        {populasi} ekor
                      </Typography>
                    </div>
                    <div className="col-span-1">
                      <Typography className="text-sm font-normal text-right text-text-secondary">
                        {bobotTimbang.toLocaleString("id-ID")} g
                      </Typography>
                    </div>
                  </CardBody>
                  <CardFooter className="grid grid-cols-2 gap-4 border-none rounded-none m-0 p-0 mt-4">
                    <div className="col-span-1">
                      <Typography className="text-md font-semibold text-text-primary">
                        Rata-rata bobot timbang
                      </Typography>
                    </div>
                    <div className="col-span-1">
                      <Typography className="text-md font-semibold text-text-primary text-right">
                        {bobotTimbang?.length > 0 && populasi?.length > 0 && populasi > 0
                          ? (bobotTimbang / populasi).toLocaleString("id-ID")
                          : "0"}{" "}
                        g
                      </Typography>
                    </div>
                  </CardFooter>
                </Card>

                <CardFooter className="flex justify-end border-t border-blue-gray-50 p-lg">
                  <Button
                    label="Kembali"
                    size="medium"
                    type="btnSecondary"
                    className="mr-md"
                    onClick={() => prevStep()}
                  />
                  <Button
                    label="Simpan"
                    size="medium"
                    type="btnPrimary"
                    disabled={isSubmitStep1Disabled || isSubmitting}
                    onClick={handleOpenAddDialog}
                  />
                </CardFooter>
              </CardBody>
            </Card>
          </div>
        )}
      </Card>
      {/* Komponen dialog konfirmasi edit*/}
      <ConfirmDialog
        isOpen={isAddDialogOpen}
        onClose={handleCloseAddDialog}
        onConfirm={saveDailyRecords}
        title="Konfirmasi simpan data"
        content="Pastikan data yang anda masukkan sudah benar, sebelum melanjutkan tindakan ini."
        btnLabel="Ya, lanjutkan"
        btnType={`${
          isSubmitting
            ? "btnPrimaryDisabled"
            : "btnPrimary"
        }`}
        disabled={isSubmitting}
        componentLeftBtn={
          isSubmitting && (
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              className="animate-spin fill-neutral-400"
            >
              <path
                d="M10.72,19.9a8,8,0,0,1-6.5-9.79A7.77,7.77,0,0,1,10.4,4.16a8,8,0,0,1,9.49,6.52A1.54,1.54,0,0,0,21.38,12h.13a1.37,1.37,0,0,0,1.38-1.54,11,11,0,1,0-12.7,12.39A1.54,1.54,0,0,0,12,21.34h0A1.47,1.47,0,0,0,10.72,19.9Z"
                className="spinner_6kVp"
              />
            </svg>
          )
        }
      />
      {/* Alert untuk notifikasi error */}
      {isAddError && (
        <AlertNotification
          icon={<FaCheckCircle />}
          color="red"
          message={errorMessage}
        />
      )}
    </div>
  );
};

export default AddRecordingHarian;
