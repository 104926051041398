/* eslint-disable no-unused-vars */
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Chip,
  Typography,
} from "@material-tailwind/react";
import { Button } from "../../../../atoms/Button";
import {
  ChevronUpDownIcon,
  MagnifyingGlassIcon,
  PlusIcon,
} from "@heroicons/react/24/outline";
import Input from "../../../../atoms/Input";
import { useEffect, useState } from "react";
import api from "../../../../api/configApi";
import LoadingAnimation from "../../../../atoms/LoadingAnimation";
import { ReactComponent as EmptyIcon } from "../../../../assets/icons/emptyIconn.svg";
import Cookies from "js-cookie";
import Pagination from "../../../../atoms/Pagination";
import DropdownFilterStatus from "../../../../atoms/DropdownFilterStatus";
import { useNavigate } from "react-router-dom";
import AlertNotification from "../../../../atoms/AlertNotification";
import { FaCheckCircle } from "react-icons/fa";
import { ButtonIcon } from "../../../../atoms/ButtonIcon";

const MasterRegion = () => {
  const navigate = useNavigate();
  const alertAdd = JSON.parse(localStorage.getItem("responseAddSuccess"));
  const alertEdit = JSON.parse(localStorage.getItem("responseEditSuccess"));
  const currentPageCookies = parseInt(Cookies.get("current-page-masterRegion"));
  const sortableColumns = [
    "",
    "name",
    "headOfUnit.fullName",
    "city.province.name",
    "city.name",
    "address",
    "",
    "",
    "isActive",
  ];

  const TABLE_HEAD = [
    "No",
    "ID region",
    "Nama region",
    "Kepala region",
    "Status",
    "Aksi",
  ];

  const [sortBy, setSortBy] = useState("");
  const [descending, setDescending] = useState(true);
  const [response, setResponse] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(
    currentPageCookies ? currentPageCookies : 1
  );
  const [totalPages, setTotalPages] = useState(1);
  const [isSearchProcess, setIsSearchProcess] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState();
  const [labelStatus, setLabelStatus] = useState(null);
  const [notifAdd, setNotifAdd] = useState(alertAdd);
  const [notifEdit, setNotifEdit] = useState(alertEdit);

  useEffect(() => {
    setTimeout(() => {
      setNotifEdit(false);
      localStorage.clear("responseEditSuccess");
    }, 4000);
  }, [notifEdit]);

  useEffect(() => {
    setTimeout(() => {
      setNotifAdd(false);
      localStorage.clear("responseAddSuccess");
    }, 4000);
  }, [notifAdd]);

  useEffect(() => {
    setIsSearchProcess(true);
    const fetchData = async () => {
      setLoading(true);

      const params = {
        itemPerPage: 10,
        search: search,
        descending: descending,
        currentPage: currentPage - 1,
      };

      if (selectedStatus !== null) {
        params.isActive = selectedStatus;
      }

      try {
        const responseRegion = await api.get("/v1/regions", { params });
        setResponse(responseRegion?.data?.contents);
        setTotalPages(responseRegion.data.totalPages);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [descending, search, currentPage, selectedStatus]);

  const handleShort = (column) => {
    if (sortableColumns.includes(column)) {
      if (sortBy === column) {
        setDescending(!descending);
      } else {
        setSortBy(column);
        setDescending(false);
      }
    }
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const statusUnits = [
    {
      id: 1,
      IsActive: true,
      labels: ["Aktif"],
      labelsPlaceholder: "Aktif",
    },
    {
      id: 2,
      IsActive: false,
      labels: ["Tidak aktif"],
      labelsPlaceholder: "Tidak aktif",
    },
  ];

  const handleResetFilterStatus = () => {
    setSelectedStatus("");
    setLabelStatus("");
  };

  const handleFilterStatus = (val) => {
    handlePageChange(1);
    setSelectedStatus(val);
    if (val === true) {
      setLabelStatus("Aktif");
    } else if (val === false) {
      setLabelStatus("Tidak aktif");
    } else if (val === null) {
      setLabelStatus("Semua Status");
    }
  };

  const handleClickDetail = (val) => {
    navigate(`/master/region/edit-region/${val}`);
  };

  return (
    <>
      <div className="flex h-auto overflow-hidden">
        <div className="flex flex-col flex-1 overflow-hidden">
          <div className="p-4">
            <Card className="w-fulll h-auto border border-border-secondary shadow-none p-0 m-0">
              <CardHeader
                floated={false}
                shadow={false}
                className="p-2 m-0 border-none w-full"
              >
                <Typography className="text-xl p-4 font-bold text-text-primary">
                  Region
                </Typography>

                <div className="grid grid-cols-4 px-4 gap-4 mb-4">
                  <div className="text-left">
                    <Input
                      type="text"
                      id="search"
                      placeholder="Pencarian..."
                      className="max-w-[404px]"
                      size="medium"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                      componentLeft={
                        <MagnifyingGlassIcon className="h-5 w-5" />
                      }
                      componentRight={
                        !isSearchProcess && (
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                            className="animate-spin fill-neutral-400 max-w-sm"
                          >
                            <path
                              d="M10.72,19.9a8,8,0,0,1-6.5-9.79A7.77,7.77,0,0,1,10.4,4.16a8,8,0,0,1,9.49,6.52A1.54,1.54,0,0,0,21.38,12h.13a1.37,1.37,0,0,0,1.38-1.54,11,11,0,1,0-12.7,12.39A1.54,1.54,0,0,0,12,21.34h0A1.47,1.47,0,0,0,10.72,19.9Z"
                              className="spinner_6kVp"
                            />
                          </svg>
                        )
                      }
                    />
                  </div>
                  <div className="relative w-[208px]">
                    <DropdownFilterStatus
                      data={statusUnits}
                      popoverContentPosition={"top-[240px] left-[760px]"}
                      onReset={handleResetFilterStatus}
                      handleSelect={handleFilterStatus}
                      selectedStatus={selectedStatus}
                      label={labelStatus}
                    />
                  </div>
                  <div></div>
                  <div className="text-center flex justify-end">
                    <Button
                      label="Tambah Region"
                      size="medium"
                      type="btnPrimary"
                      componentRight={<PlusIcon className="h-5 w-5" />}
                      onClick={() => navigate("/master/region/tambah-region")}
                    />
                  </div>
                </div>
              </CardHeader>

              {loading ? (
                <div className="flex justify-center items-center h-screen">
                  <LoadingAnimation />
                </div>
              ) : (
                <>
                  {response.length === 0 ? (
                    <div className=" flex justify-center items-center h-screen">
                      <div>
                        <div className="flex justify-center">
                          <EmptyIcon className="ml-[30px]" />
                        </div>

                        <Typography
                          variant="h2"
                          className="text-xl font-semibold p-4 text-center text-text-secondary"
                        >
                          Data tidak ditemukan
                        </Typography>

                        <Typography
                          variant="medium"
                          className="flex justify-center font-normal leading-none w-full text-text-secondary min-h-full bg-gray-950"
                        >
                          Data yang Anda cari tidak ditemukan. Coba kata kunci
                          lain
                        </Typography>
                      </div>
                    </div>
                  ) : (
                    <>
                      <CardBody className="p-0 overflow-x-scroll">
                        <table className="w-full min-w-max table-auto text-left">
                          <thead>
                            <tr>
                              {TABLE_HEAD.map((head, index) => (
                                <th
                                  key={head}
                                  className={`border-y border-border-secondary bg-neutral-50 py-2 px-lg ${
                                    index === 10
                                      ? "sticky right-0 bg-neutral-50 z-10"
                                      : ""
                                  }`}
                                >
                                  <Typography className="flex text-sm justify-between font-semibold text-text-quarterary leading-tight">
                                    {head}
                                    {""}
                                    {index !== 5 &&
                                      index !== TABLE_HEAD.length - 1 && (
                                        <button
                                          onClick={() =>
                                            handleShort(sortableColumns[index])
                                          }
                                          className="focus:outline-none ml-1"
                                        >
                                          <ChevronUpDownIcon
                                            strokeWidth={2}
                                            className={`h-4 w-4 ${
                                              descending
                                                ? "transform rotate-180"
                                                : ""
                                            }`}
                                          />
                                        </button>
                                      )}
                                  </Typography>
                                </th>
                              ))}
                            </tr>
                          </thead>

                          <tbody>
                            {response.map((region, index) => {
                              const isLast = index === response.length - 1;
                              const classes = isLast
                                ? "p-lg"
                                : "p-lg border-b border-border-secondary";

                              return (
                                <tr key={region?.id}>
                                  <td className={classes}>
                                    <div className="flex items-center">
                                      <Typography className="text-text-primary text-sm font-medium leading-tight">
                                        {index + 1}
                                      </Typography>
                                    </div>
                                  </td>

                                  <td className={classes}>
                                    <div className="flex items-center">
                                      <Typography className="text-text-primary text-sm font-medium leading-tight">
                                        {region?.code}
                                      </Typography>
                                    </div>
                                  </td>

                                  <td className={classes}>
                                    <div className="flex items-center">
                                      <Typography className="text-text-primary text-sm font-medium leading-tight">
                                        {region?.name}
                                      </Typography>
                                    </div>
                                  </td>

                                  <td className={classes}>
                                    <div className="flex items-center">
                                      <Typography className="text-text-primary text-sm font-medium leading-tight">
                                        {region?.headOfRegion?.fullName}
                                      </Typography>
                                    </div>
                                  </td>

                                  <td className={classes}>
                                    <div className="w-max">
                                      <Chip
                                        size="sm"
                                        variant="ghost"
                                        value={
                                          region.isActive
                                            ? "Aktif"
                                            : "Tidak aktif"
                                        }
                                        className={`text-sm font-inter font-normal leading-[18px] border normal-case ${
                                          region.isActive
                                            ? "text-green-600 border-green-200 bg-green-25"
                                            : "text-neutral-800 border-neutral-200 bg-neutral-50"
                                        }`}
                                        icon={
                                          region.isActive ? (
                                            <span className="mx-auto mt-1 block h-2 w-2 rounded-full bg-green-500 content-['']" />
                                          ) : (
                                            <span className="mx-auto mt-1 block h-2 w-2 rounded-full bg-gray-500 content-['']" />
                                          )
                                        }
                                      />
                                    </div>
                                  </td>

                                  <td
                                    className={`border-b border-border-secondary px-lg bg-white sticky right-0 shadow-[rgba(0,0,10,0.2)_-3px_1px_12px_-5px]`}
                                  >
                                    <ButtonIcon
                                      className="z-25"
                                      onClick={() =>
                                        handleClickDetail(region?.id)
                                      }
                                      icon="edit"
                                      type="outlineWarning"
                                      size="small"
                                    />
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </CardBody>
                      <CardFooter className="p-sm items-center border-t border-border-secondary">
                        <Pagination
                          currentPage={currentPage}
                          totalPages={totalPages}
                          onPageChange={handlePageChange}
                        />
                      </CardFooter>
                    </>
                  )}
                </>
              )}
            </Card>
            {notifAdd && (
              <AlertNotification
                icon={<FaCheckCircle />}
                color="green"
                message="Data region berhasil ditambah."
              />
            )}

            {notifEdit && (
              <AlertNotification
                icon={<FaCheckCircle />}
                color="green"
                message="Data region berhasil diubah."
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default MasterRegion;
