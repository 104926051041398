/* eslint-disable no-sparse-arrays */
/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  IconButton,
  Popover,
  PopoverContent,
  PopoverHandler,
  Typography,
} from "@material-tailwind/react";
import Input from "../../../../atoms/Input";
import {
  ChevronUpDownIcon,
  MagnifyingGlassIcon,
  DocumentTextIcon,
} from "@heroicons/react/24/outline";
import { FiFilter } from "react-icons/fi";
import { Button } from "../../../../atoms/Button";
import { useState } from "react";
import api from "../../../../api/configApi";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { ReactComponent as BurgerDots } from "../../../../assets/icons/burgers-dot.svg";
import Pagination from "../../../../atoms/Pagination";
import AlertNotification from "../../../../atoms/AlertNotification";
import { FaCheckCircle } from "react-icons/fa";
import { ReactComponent as EmptyIcon } from "../../../../assets/icons/emptyIconn.svg";
import LoadingAnimation from "../../../../atoms/LoadingAnimation";
import DropdownWithSearchInput from "../../../../atoms/DropdownWithSearchInput";
import { AiOutlineUnorderedList } from "react-icons/ai";
import InputNumber from "../../../../atoms/NumberFormat";
import Cookies from "js-cookie";
import { useSelector } from "react-redux";
import { ReactComponent as BurgerDotIcon } from "../../../../assets/icons/burgers-dot.svg";

const ListPeriodeBerjalan = () => {
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  let currentPageCookiesSelesaiPeriod = parseInt(
    Cookies.get("current-page-periodeSelesai")
  );
  const [responseList, setResponseList] = useState(null);
  const [sortBy, setSortBy] = useState("");
  const [descending, setDescending] = useState(true);
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const sortableColumns = [
    "docInDate",
    "house.name",
    "house.owner.fullName",
    "",
    "house.city.province",
    "house.city",
  ];
  const [openSetting, setOpenSetting] = useState(false);
  const [getId, setGetId] = useState("");
  const [currentPage, setCurrentPage] = useState(
    currentPageCookiesSelesaiPeriod ? currentPageCookiesSelesaiPeriod : 1
  );
  const [totalPage, setTotalPage] = useState(1);
  const [selectUnit, setSelectedUnit] = useState([0]);
  const [search, setSearch] = useState("");
  const [reload, setReload] = useState(false);
  const alertStop = JSON.parse(
    localStorage.getItem("responseSuccessStopPeriod")
  );
  const [notifStop, setNotifStop] = useState(alertStop);

  const alert = JSON.parse(localStorage.getItem("response success"));
  const [notif, setNotif] = useState(alert);
  const [startFetch, setStartFetch] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [initialCapacity, setInitialCapacity] = useState(null);
  const [finalCapacity, setFinalCapacity] = useState(null);
  const [searchProvince, setSearchProvince] = useState("");
  const [searchCity, setSearchCity] = useState("");
  const [checkedRegions, setCheckedRegions] = useState([]);
  const [checkedCities, setCheckedCities] = useState([]);
  const [checkedRegionsString, setCheckedRegionsString] = useState("");
  const [checkedCitiesString, setCheckedCitiesString] = useState("");
  const [lastLabelProvince, setLastLabelProvince] = useState("");
  const [lastLabelCities, setLastLabelCities] = useState("");

  const [regions, setRegions] = useState([]);
  const [cities, setCities] = useState([]);
  const [implementFilter, setImplementFilter] = useState(false);
  const [reloadFilter, setReloadFilter] = useState(false);
  const [selectedRegion, setSelectedRegion] = useState([0]);
  const [countFilter, setCountFilter] = useState(0);
  const [validationError, setValidationError] = useState("");
  const [isFetchError, setIsFetchError] = useState(false);
  const [debouncedSearch, setDebouncedSearch] = useState(search);
  const [searchRegions, setSearchRegions] = useState([])
  const [regionsList, setRegionList] = useState([])
  const [searchMasterUnit, setSearchMasterUnit] = useState("");
  const [listMasterUnits, setListMasterUnits] = useState([])
  const [checkNewRegions, setCheckNewRegions] = useState("")
  const [lastLabelUnits, setLastLabelUnits] = useState("");
  const [checkRegions, setChecksRegions] = useState([])
  const [checkedUnitsString, setCheckedUnitsString] = useState("");
  const [checkedUnits, setCheckedUnits] = useState([]);
  const [lastLabelUnit, setLastLabelUnit] = useState("");
  const [activePopup, setActivePopup] = useState(null)
  

  Cookies.set("current-page-periodeSelesai", currentPage);

  const TABLE_HEAD = [
    "Tanggal DOC in",
    "Nama Kandang",
    "Pemilik Kandang",
    "Umur",
    "Provinsi",
    "Kabupaten/Kota",
    <div class="p-0 m-0 grid grid-cols-8 w-[900px] max-h-24">
      <div class="col-span-2 text-center min-w-[800px] py-2 border-t-0">
        Performance
      </div>
      <div class="col-start-1 col-end-1 p-0 m-0">
        <Typography className="text-text-quarterary border-t text-sm font-semibold leading-tight border-l border-border-secondary text-center py-2 bg-neutral-100">
          IP
        </Typography>
      </div>
      <div class="col-start-2 col-end-2 p-0 m-0">
        <Typography className="text-text-quarterary border-t text-sm font-semibold leading-tight border-border-secondary text-center py-2 bg-neutral-100">
          FCR std
        </Typography>
      </div>
      <div class="col-start-3 col-end-3 p-0 m-0">
        <Typography className="text-text-quarterary border-t text-sm font-semibold leading-tight border-border-secondary text-center py-2 bg-neutral-100">
          FCR act
        </Typography>
      </div>
      <div class="col-start-4 col-end-4 p-0 m-0">
        <Typography className="text-text-quarterary border-t text-sm font-semibold leading-tight border-border-secondary text-center py-2 bg-neutral-100">
          Diff FCR
        </Typography>
      </div>
      <div class="col-start-5 col-end-5 p-0 m-0">
        <Typography className="text-text-quarterary border-t text-sm font-semibold leading-tight border-border-secondary text-center py-2 bg-neutral-100">
          ADG
        </Typography>
      </div>
      <div class="col-start-6 col-end-6 p-0 m-0">
        <Typography className="text-text-quarterary border-t text-sm font-semibold leading-tight border-border-secondary text-center py-2 bg-neutral-100">
          Deplesi
        </Typography>
      </div>
      <div class="col-start-7 col-end-7 p-0 m-0">
        <Typography className="text-text-quarterary border-t text-sm font-semibold leading-tight border-border-secondary text-center py-2 bg-neutral-100">
          Bobot total (kg)
        </Typography>
      </div>

      <div class="col-start-8 col-end-8 min-w-[130px] p-0 m-0">
        <Typography className="text-text-quarterary border-t text-sm font-semibold leading-tight border-r border-border-secondary text-center py-2 bg-neutral-100">
          Bobot rerata (kg)
        </Typography>
      </div>
    </div>,
    "",
  ];

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearch(search);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [search]);

  useEffect(() => {
    const fetchingList = async () => {
      try {
        const params = {
          currentPage: debouncedSearch.length > 0 ? null : currentPage - 1,
          sortBy: sortBy,
          descending: descending,
          itemPerPage: debouncedSearch.length > 0 ? null : 10,
          search: debouncedSearch,
          end: 1,
        };
        if (implementFilter === true || reloadFilter === true) {
          params.province = checkedRegionsString ? checkedRegionsString : "";
          params.city = checkedCitiesString ? checkedCitiesString : "";
          params.unit = checkedUnits ? checkedUnits : ""
          params.region = checkNewRegions ? checkNewRegions : ""
          params.initialCapacity =
            initialCapacity !== null
              ? parseInt(initialCapacity.replace(".", ""))
              : null;
          params.finalCapacity =
            finalCapacity !== null
              ? parseInt(finalCapacity.replace(".", ""))
              : null;
        }
        const responseList = await api.get("/v1/rearingPeriods", { params });
        setIsLoading(true);
        setReload(true);
        setResponseList(responseList?.data?.contents);
        setTotalPage(responseList?.data?.totalPages);
        setIsFetchError(false);

        if (!responseList) {
          setIsOpenFilter(false);
        }
      } catch (error) {
        console.log(error);
        setIsFetchError(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchingList();
  }, [descending, currentPage, debouncedSearch, sortBy, implementFilter, reloadFilter]);

  useEffect(() => {
    setReloadFilter(false);
  }, [reloadFilter]);

  useEffect(() => {
    const fetchFilterProvince = async () => {
      try {
        const responseRegionDropdown = await api.get(
          "/v1/regions/id/province/dropdown",
          {
            params: {
              search: searchProvince,
            },
          }
        );
        setRegions(responseRegionDropdown?.data?.contents);
      } catch (error) {
        console.log(error);
      }
    };

    fetchFilterProvince();
  }, [searchProvince]);


  useEffect(() => {
    const fetchRegion = async () => {
      try {
        const response = await api.get("/v1/regions/dropdown", {
          params: {
            search: searchRegions
          }
        })
        setRegionList(response?.data?.contents)
      } catch (error) {
        console.error("Error fetching roles:", error);
      }
    }
    
    fetchRegion();
  }, [searchRegions]);

  useEffect(() => {
    const fetchMasterUnit = async () => {
      try {
        const params = {
          search: searchMasterUnit,
          descending: false
        };
        const responseList = await api.get("/v1/units/dropdown", { params });
        setListMasterUnits(responseList?.data?.contents);
      } catch (error) {
        console.log(error.message);
      }
    };
    fetchMasterUnit();
  }, [searchMasterUnit]);

  useEffect(() => {
    setTimeout(() => {
      setNotifStop(false);
      localStorage.clear("responseSuccessStopPeriod");
    }, 3000);
  }, [notifStop]);

  const handleChangePage = (target) => {
    setCurrentPage(target);
  };

  const handleSort = (column) => {
    if (sortableColumns.includes(column)) {
      if (sortBy === column) {
        setDescending(!descending);
      } else {
        setSortBy(column);
        setDescending(false);
      }
    }
  };

  const handleOpenPeriod = (periodId) => {
    if (activePopup === periodId) {
      setActivePopup(null)
    } else {
      setActivePopup(periodId)
    }

    setGetId(periodId);

    setOpenSetting((prevSettingId) => ({
      ...prevSettingId,
      [periodId]: !prevSettingId[periodId] || false,
    }));
  };

  useEffect(() => {
    if (startFetch) {
      const fetchData = async () => {
        const data = await api.get(`/v1/regions/id/cities/dropdown`, {
          params: {
            search: searchCity,
            province: checkedRegionsString,
          },
        });
        setCities(data?.data);
      };
      fetchData();
    }
  }, [startFetch, checkedRegionsString, searchCity]);

  useEffect(() => {
    setTimeout(() => {
      setNotif(false);
      localStorage.clear("response success");
    }, 4000);
  }, [notif]);

  const dataSetting = [
    {
      id: 2,
      icon: <DocumentTextIcon strokeWidth={2} className={`h-5 w-5`} />,
      label: "Download Laporan",
    },
    {
      id: 1,
      icon: <AiOutlineUnorderedList strokeWidth={2} className={`h-5 w-5`} />,
      label: "Detail",
    },
  ];

  const handleClickConditionalDetailAndExport = async (valSetting, val) => {
    if (valSetting === 1) {
      navigate(`/periode-budidaya/periode-selesai/${getId}`);
    } else if (valSetting === 2) {
      try {
        const response = await api.get(`/v1/rearingPeriods/${val}/toExcel`, {
          responseType: "blob", 
        });

        const contentDisposition = response.headers["content-disposition"];

        let fileName = "rearing_periods.xlsx";
  
        if (contentDisposition) {
          const fileNameMatch = contentDisposition.match(/filename="?([^"]+)"?/);
          if (fileNameMatch && fileNameMatch[1]) {
            fileName = fileNameMatch[1];
          }
        }
  
        const url = window.URL.createObjectURL(new Blob([response.data]));

        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);

        document.body.appendChild(link);

        link.click();

        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleFilterUnit = (val) => {
    setSelectedUnit(val);
  };

  const handleFilterRegion = (val) => {
    setSelectedRegion(val);
    setStartFetch(true);
  };

  const handleTerapkanFilter = () => {
    setIsOpenFilter(false);
    setCountFilter(0);
    let count = 0;

    if (implementFilter === true) {
      setReloadFilter(true);
    }

    if (checkedRegionsString !== "") {
      count = count + 1;
    }

    // if (check)
    if (checkedUnits !== "") {
      count = count + 1
    }

    if (checkedCitiesString !== "") {
      count = count + 1;
    }

    if (checkNewRegions !== "") {
      count = count + 1
    }


    if (initialCapacity?.length > 0 || finalCapacity?.length > 0) {
      count = count + 1;
    }

    setCountFilter(count);
    setImplementFilter(true);
  };

  useEffect(() => {
    setReloadFilter(false);
  }, [reloadFilter]);

  const handleResetFilter = () => {
    setImplementFilter(false);
    setIsOpenFilter(false);
    setSelectedRegion("");
    setSelectedUnit("");
    setInitialCapacity(null);
    setFinalCapacity(null);
    setCheckedCities([]);
    setCheckedCitiesString("");
    setCheckedRegions([]);
    setCheckedRegionsString("");
    setCountFilter(0);
    setCheckNewRegions("")
    setCheckedUnits("")
  };

  const handleFilterMasterUnit = (val) => {};

  return (
    <>
      <div className="flex h-auto xl:overflow-hidden overflow-auto z-0">
        <div className="flex flex-col flex-1 overflow-hidden">
          <div className="p-4">
            <Card className="w-full h-auto border border-border-secondary shadow-none p-0 m-0">
              <CardHeader
                floated={false}
                shadow={false}
                className="p-2 m-0 border-none w-full"
              >
                <Typography
                  variant="h2"
                  className="text-xl font-bold p-4 text-neutral-800"
                >
                  Periode Selesai
                </Typography>

                <div className="grid grid-cols-2">
                  {/* search input */}
                  <div className="flex justify-start mb-4 gap-2">
                    <div className="text-left pl-4">
                      <Input
                        type="text"
                        id="search"
                        placeholder="Cari pemilik kandang, nama kandang"
                        className="min-w-[350px] border border-neutral-300"
                        size="medium"
                        componentLeft={
                          <MagnifyingGlassIcon className="h-5 w-5" />
                        }
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                      />
                    </div>
                    <div className="ml-2">
                      <Popover
                        placement="bottom"
                        open={isOpenFilter}
                        handler={() => setIsOpenFilter(!isOpenFilter)}
                      >
                        <PopoverHandler className="cursor-pointer">
                          <div>
                            <button
                              className={`${
                                countFilter > 0
                                  ? "border-button-infoBorder"
                                  : "border-neutral-300"
                              } p-5 h-11 px-xl py-2.5 justify-center items-center gap-md inline-flex text-base font-medium leading-normal rounded-md bg-white hover:bg-neutral-50 border text-neutral-700 focus:shadow-sm focus:shadow-gray-300 hover:border-border-secondary`}
                            >
                              <div
                                className={`justify-center min-w-6 w-full items-center gap-2.5 flex cursor-pointer ${
                                  countFilter > 0
                                    ? "text-fg-infoPrimary"
                                    : "text-neutral-700"
                                }`}
                              >
                                <div className="w-auto font-medium">Filter</div>
                              </div>
                              <div className="w-6 h-6 relative flex items-center">
                                <FiFilter
                                  className={`h-5 w-5 ${
                                    countFilter > 0 && "text-fg-infoPrimary"
                                  }`}
                                />
                              </div>
                              {countFilter > 0 && (
                                <div className="w-6 h-6 px-3 py-1 bg-fg-infoPrimary rounded-full justify-center items-center gap-1 inline-flex">
                                  <div className="text-white text-xs font-medium leading-[18px]">
                                    {countFilter}
                                  </div>
                                </div>
                              )}
                            </button>
                          </div>
                        </PopoverHandler>

                        <PopoverContent className="top-[205px] left-[500px] z-50 w-[576px]">
                          {user?.level === "MITRA" && (
                            <div>
                              <Typography className="flex justify-between font-medium text-xs text-text-primary leading-[18px] w-full min-h-full mt-6">
                               Region
                              </Typography>

                              <div className="mt-2">
                                <DropdownWithSearchInput 
                                  placeholder="Pilih region"
                                  placeholderInputSearch="Cari region"
                                  options={regions}
                                  onSelect={handleFilterRegion}
                                  search={searchRegions}
                                  setSearch={setSearchRegions}
                                  onSelectString={setCheckNewRegions}
                                  lastLabel={lastLabelUnit}
                                  setLastLabel={setLastLabelUnit}
                                  positionClassName="top-[335px] left-[520px]"
                                  selectCheckBox={checkRegions}
                                  setSelectCheckbox={setChecksRegions}
                                />
                              </div>
                             
                              <Typography className="flex justify-between font-medium text-xs text-text-primary leading-[18px] w-full min-h-full mt-6">
                                Unit
                              </Typography>

                              <div className="mt-2">
                              <DropdownWithSearchInput 
                                 placeholder="Semua unit"
                                 placeholderInputSearch="Cari unit"
                                 options={listMasterUnits}
                                 onSelect={handleFilterMasterUnit}
                                 search={searchMasterUnit}
                                 setSearch={setSearchMasterUnit}
                                 onSelectString={setCheckedUnitsString}
                                 lastLabel={lastLabelUnits}
                                 setLastLabel={setLastLabelUnits}
                                 positionClassName="top-[515px] left-[520px]"
                                 selectCheckBox={checkedUnits}
                                 setSelectCheckbox={setCheckedUnits}
                               />
                              </div>
                            </div>
                          )}

                          {user?.level === "REGION" && (
                            <div>
                              <Typography className="flex justify-between font-medium text-xs text-text-primary leading-[18px] w-full min-h-full mt-6">
                                Unit
                              </Typography>

                              <div className="mt-2">
                                <DropdownWithSearchInput 
                                  placeholder="Semua unit"
                                  placeholderInputSearch="Cari unit"
                                  options={listMasterUnits}
                                  onSelect={handleFilterMasterUnit}
                                  search={searchMasterUnit}
                                  setSearch={setSearchMasterUnit}
                                  onSelectString={setCheckedUnitsString}
                                  lastLabel={lastLabelUnits}
                                  setLastLabel={setLastLabelUnits}
                                  positionClassName="top-[515px] left-[520px]"
                                  selectCheckBox={checkedUnits}
                                  setSelectCheckbox={setCheckedUnits}
                                />
                              </div>
                           </div>
                          )}

                          {user?.role === "Super Admin" && (
                            <>
                              <Typography
                                variant="small"
                                className="flex justify-between font-normal text-xs leading-none text-neutral-500 w-full min-h-full"
                              >
                                Lokasi
                              </Typography>
                              <Typography
                                variant="small"
                                className="flex justify-between font-medium text-neutral-900 leading-none w-full min-h-full mt-6"
                              >
                                Provinsi
                              </Typography>

                              <div className="mt-4">
                                <DropdownWithSearchInput
                                  placeholder="Semua provinsi"
                                  placeholderInputSearch="Cari Provinsi"
                                  options={regions}
                                  onSelect={handleFilterRegion}
                                  search={searchProvince}
                                  setSearch={setSearchProvince}
                                  onSelectString={setCheckedRegionsString}
                                  lastLabel={lastLabelProvince}
                                  setLastLabel={setLastLabelProvince}
                                  positionClassName="top-[340px] left-[520px]"
                                  selectCheckBox={checkedRegions}
                                  setSelectCheckbox={setCheckedRegions}
                                />
                              </div>

                              <Typography
                                variant="medium"
                                className="flex justify-between font-normal leading-none opacity-70 w-full min-h-full text-text-primary mt-4"
                              >
                                Kabupaten/Kota
                              </Typography>

                              <div className="mt-4">
                                <DropdownWithSearchInput
                                  placeholder="Semua kab/kota"
                                  placeholderInputSearch="Cari kab/kota"
                                  options={cities?.contents}
                                  onSelect={handleFilterUnit}
                                  search={searchCity}
                                  setSearch={setSearchCity}
                                  onSelectString={setCheckedCitiesString}
                                  lastLabel={lastLabelCities}
                                  setLastLabel={setLastLabelCities}
                                  positionClassName="top-[430px] left-[520px]"
                                  selectCheckBox={checkedCities}
                                  setSelectCheckbox={setCheckedCities}
                                />
                              </div>
                            </>
                          )}
                          <Typography
                            variant="small"
                            className="flex justify-between font-medium leading-none text-xs text-neutral-500 w-full min-h-full mt-6"
                          >
                            Kapasitas kandang
                          </Typography>

                          <div className="mt-2 flex justify-between items-center">
                            <div className="relative w-full">
                              <InputNumber
                                type="text"
                                id="capasity"
                                placeholder="Kapasitas minimal"
                                className="max-w-full w-[200px]"
                                value={initialCapacity}
                                size="medium"
                                onChange={(e) =>
                                  setInitialCapacity(e.target.value)
                                }
                                placeholderSize={"medium"}
                                componentRight={
                                  <Typography
                                    variant="medium"
                                    className="font-medium text-neutral-500"
                                  >
                                    ekor
                                  </Typography>
                                }
                              />
                              {validationError && (
                                <p className="text-red-500 text-sm mt-2">
                                  {validationError}
                                </p>
                              )}
                            </div>

                            <hr className="mx-4 px-2 border-2 rounded-md border-neutral-500" />
                            <div className="relative w-full">
                              <InputNumber
                                type="text"
                                id="capasity"
                                placeholder="Kapasitas maksimal"
                                className="max-w-full"
                                value={finalCapacity}
                                size="medium"
                                onChange={(e) =>
                                  setFinalCapacity(e.target.value)
                                }
                                placeholderSize={"medium"}
                                componentRight={
                                  <Typography
                                    variant="medium"
                                    className="font-medium text-neutral-500"
                                  >
                                    ekor
                                  </Typography>
                                }
                              />
                              {validationError && (
                                <p className="text-red-500 text-sm mt-2">
                                  {validationError}
                                </p>
                              )}
                            </div>
                          </div>

                          <div className="border-t border-border-secondary my-4" />

                          <div className="divide-y flex w-full gap-8 justify-between mt-4">
                            <div onClick={handleResetFilter}>
                              <Typography className="flex justify-between items-center text-sm text-text-primary font-medium leading-tight w-full min-h-full cursor-pointer">
                                Atur Ulang
                              </Typography>
                            </div>

                            <Button
                              size={"small"}
                              label="Terapkan"
                              onClick={handleTerapkanFilter}
                              className="cursor-pointer"
                            />
                          </div>
                        </PopoverContent>
                      </Popover>
                    </div>
                  </div>
                  <div className="text-center flex justify-end mr-[30px]">
                    {/* <Button
                      label="Export Laporan"
                      size="medium"
                      type="btnPrimary"
                    /> */}
                  </div>
                </div>
              </CardHeader>

              {responseList?.length === 0 || isFetchError ? (
                <div className=" flex justify-center items-center h-screen">
                  <div>
                    <div className="flex justify-center">
                      <EmptyIcon className="ml-[30px]" />
                    </div>
                    <Typography className="text-xl font-semibold p-4 text-center text-neutral-500">
                      Data tidak ditemukan
                    </Typography>

                    <Typography className="flex justify-between text-md font-normal leading-none text-neutral-500 w-full min-h-full">
                      Data yang Anda cari tidak ditemukan. Coba kata kunci lain
                    </Typography>
                  </div>
                </div>
              ) : (
                <>
                  <CardBody className="p-0 overflow-scroll max-w-full max-h-[960px] z-10 scrollbar-hide">
                    {isLoading ? (
                      <div className="flex justify-center items-center h-screen">
                        <LoadingAnimation />
                      </div>
                    ) : (
                      <>
                        <table className="w-full min-w-max table-auto text-left">
                          <thead className="border-y border-border-secondary bg-neutral-50">
                            <tr>
                              {/* {console.log(TABLE_HEAD > 5 ? null : console.log("muncul"))} */}
                              {TABLE_HEAD?.map((head, index) => {
                                return (
                                  <th
                                    key={index}
                                    className={`border-y border-border-secondary bg-neutral-50 p-0 h-xl ${
                                      index === TABLE_HEAD.length - 1
                                        ? "max-w-[70px] sticky right-0 ml-4 z-10 p-0 shadow-[rgba(0,0,10,0.2)_-3px_0_12px_-5px] border-l"
                                        : index === 14
                                        ? "max-w-[70px] sticky left-0 ml-4 z-10 p-0 shadow-[rgba(0,0,10,0.2)_-3px_0_12px_-5px] border-r"
                                        : ""
                                    }`}
                                  >
                                    <Typography className="flex justify-start px-4 font-semibold leading-normal text-text-quarterary text-sm">
                                      {head}{" "}
                                      {index !== 3 &&
                                        index !== 4 &&
                                        index !== 5 &&
                                        index !== 6 &&
                                        index !== 7 &&
                                        index !== 8 &&
                                        index !== 9 &&
                                        index !== 10 &&
                                        index !== 11 &&
                                        index !== 12 &&
                                        index !== 13 &&
                                        index !== 14 &&
                                        TABLE_HEAD.length - 1 && (
                                          <button
                                            className="focus:outline-none ml-1"
                                            onClick={() =>
                                              handleSort(sortableColumns[index])
                                            }
                                          >
                                            <ChevronUpDownIcon
                                              strokeWidth={2}
                                              className={`h-4 w-4 ${
                                                descending
                                                  ? "transform rotate-180"
                                                  : ""
                                              }`}
                                            />
                                          </button>
                                        )}
                                    </Typography>
                                  </th>
                                );
                              })}
                            </tr>
                          </thead>

                          <tbody>
                            {responseList?.map((val, index) => {
                              const isList = index === responseList.length - 1;
                              const classes = isList
                                ? "p-lg"
                                : "p-lg border-b border-border-secondary";

                              return (
                                <tr key={val.id}>
                                  <td className={classes}>
                                    <Typography className="text-text-primary text-sm font-medium leading-tight">
                                      {moment(val.docInDate).format(
                                        "D MMMM YYYY"
                                      )}
                                    </Typography>
                                  </td>

                                  <td className={classes + " max-w-[200px]"}>
                                    <Typography className="text-text-primary font-medium text-sm leading-tight">
                                      {/* <div
                                        onClick={() =>
                                          handleIdCage(val?.house?.id)
                                        }
                                      > */}
                                      {val?.house?.labels}
                                      {/* </div> */}
                                    </Typography>
                                  </td>

                                  <td className={classes + " max-w-[200px]"}>
                                    <Typography className="text-text-primary text-sm font-medium leading-tight">
                                      {val?.house?.owner?.fullName}
                                    </Typography>
                                  </td>

                                  <td className={classes}>
                                    <Typography className="text-text-primary text-sm font-medium leading-tight">
                                      {val?.dayOfPeriod}
                                    </Typography>
                                  </td>

                                  <td className={classes}>
                                    <Typography className="text-text-primary text-sm font-medium leading-tight">
                                      {val?.house?.province?.labels}
                                    </Typography>
                                  </td>

                                  <td className={classes}>
                                    <Typography className="text-text-primary text-sm font-medium leading-tight">
                                      {val?.house?.city?.labels}
                                    </Typography>
                                  </td>

                                  <td
                                    className={`${classes} className="text-text-primary text-sm font-medium leading-tight text-center`}
                                  >
                                    <div className="grid grid-cols-8">
                                      <div className="col-span-1">
                                        {val?.ip}
                                      </div>

                                      <div className="col-span-1">
                                        {val?.actualFcr}
                                      </div>

                                      <div className="col-span-1">
                                        {val?.standardFcr}
                                      </div>

                                      <div className="col-span-1">
                                        {val?.diffFcr}
                                      </div>

                                      <div className="col-span-1">
                                        {val?.adg}
                                      </div>

                                      <div className="col-span-1">
                                        {val?.totalDepletion}
                                      </div>

                                      <div className="col-span-1">
                                        {val?.totalWeight / 1000}
                                      </div>

                                      <div className="col-span-1">
                                        {val?.averageWeight / 1000}
                                      </div>
                                    </div>
                                  </td>
                                  <td className={` sticky right-0 border-l border-b border-border-secondary hover:bg-bg-secondary bg-white px-lg shadow-[rgba(0,0,10,0.2)_-3px_1px_12px_-5px]`}>
                                   

                                    <div className="flex justify-between gap-2 m-0 relative">
                                      <IconButton
                                        variant="text"
                                        onClick={
                                          () => handleOpenPeriod(val.id)
                                        }
                                      >
                                        <BurgerDotIcon />
                                      </IconButton>
                                    </div>

                                    {activePopup === val?.id && (
                                      <div className="absolute top-[-8px] right-[75px] mt-2 z-5">
                                        <Card className="w-[180px] h-[80px]">
                                          <CardBody>
                                            <div className="space-y">
                                              {dataSetting?.map((valSetting, index) => (
                                                <div
                                                  className="flex items-center gap-3 mt-[-2px] cursor-pointer"
                                                  key={valSetting.id}
                                                  onClick={() =>
                                                    handleClickConditionalDetailAndExport(valSetting.id, val.id)
                                                  }
                                                >
                                                  <div>
                                                    <Typography className="text-text-primary text-sm font-medium leading-tight space-y-0.5 mt-2">
                                                      {valSetting.label}
                                                    </Typography>
                                                  </div>
                                                </div>
                                              ))}
                                            </div>
                                          </CardBody>
                                        </Card>
                                      </div>

                                    )}

                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </>
                    )}
                  </CardBody>
                  <CardFooter className="items-center border-t border-border-secondary p-sm">
                    <Pagination
                      currentPage={currentPage}
                      totalPages={totalPage}
                      onPageChange={handleChangePage}
                    />
                  </CardFooter>
                </>
              )}
            </Card>
          </div>
        </div>
      </div>

      {notif && (
        <AlertNotification
          icon={<FaCheckCircle />}
          color="green"
          message="Periode Berhasil Dibuat"
        />
      )}

      {notifStop && (
        <AlertNotification
          icon={<FaCheckCircle />}
          color="green"
          message="Periode budidaya berhasil diakhiri."
        />
      )}

      {/* <div className="p-4">
        <AddPeriod
          isOpen={openAddPeriod}
          setReload={reload}
          onClose={() => setAddOpenPeriod(!openAddPeriod)}
        />
      </div> */}
    </>
  );
};

export default ListPeriodeBerjalan;
