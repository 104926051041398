/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Switch,
  Typography,
} from "@material-tailwind/react";
import Input from "../../../../atoms/Input";
import api from "../../../../api/configApi";
import { Button } from "../../../../atoms/Button";
import AlertNotification from "../../../../atoms/AlertNotification";
import FormDropdownSearch from "../../../../atoms/FormDropdownSearch";
import TextArea from "../../../../atoms/TextArea";
import { FaCheckCircle } from "react-icons/fa";
import ConfirmDialog from "../../../../atoms/ConfirmDialog";
import { ReactComponent as ChevronLeft } from "../../../../assets/icons/chevron-left.svg";
import * as Sentry from "@sentry/react";

const EditUnit = ({ onReloadData }) => {
  const { id } = useParams();
  const unitId = id;

  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");

  const [regions, setRegions] = useState([]);
  const [cities, setCities] = useState([]);

  const [listUser, setListUsers] = useState([]);
  const [codeUnit, setCodeUnit] = useState("")

  const [selectedHeadOfUnit, setSelectedHeadOfUnit] = useState("");
  const [selectedRegion, setSelectedRegion] = useState("");
  const [selectedCities, setSelectedCities] = useState("");

  const [selectedLabelHeadOfUnit, setSelectedLabelHeadOfUnit] = useState("");
  const [selectedLabelRegion, setSelectedLabelRegion] = useState("");
  const [selectedLabelCity, setSelectedLabelCity] = useState("");

  const [isEditSuccess, setEditSuccess] = useState(false);
  const [isEditError, setIsEditError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [searchHeadOfUnit, setSearchHeadOfUnit] = useState("");
  const [searchCity, setSearchCity] = useState("");
  const [searchProvince, setSearchProvince] = useState("");

  const [isEditDialogOpen, setEditDialogOpen] = useState(false);
  const [isActive, setActive] = useState(false);
  const [responseRegions, setResponseRegions] = useState("")
  const [listRegions, setListRegions] = useState([])
  const [searchRegions, setSearchRegions] = useState("")
  const [selectRegions, setSelectRegions] = useState("")
  const [isValid, setIsValid] = useState(false)
  const [showErrorMessage, setErrorShowMessage] = useState(false)

  // const isSubmitDisabled =
  //   !name ||
  //   !address ||
  //   !selectedHeadOfUnit ||
  //   !selectedRegion ||
  //   !selectRegions ||
  //   !selectedCities;

  const handleSwitchChange = () => {
    setActive(!isActive);
  };

  const handleOpenEditDialog = () => {
    setEditDialogOpen(true);
  };

  const handleCloseEditDialog = () => {
    setEditDialogOpen(false);
  };

  const handleSelectHeadOfUnit = (select) => {
    setSelectedHeadOfUnit(select.id);
  };

  const handleSelectRegions = (select) => {
    setSelectRegions(select.id)
  }

  const handleSelectRegion = (select) => {
    setSelectedRegion(select.id);
  };

  const handleSelectCities = (select) => {
    setSelectedCities(select.id);
  };

  const handleNameChange = (e) => {
    let newName = e.target.value;

    if (newName.length < 100) {
      setName(newName);
    }
  };

  const handleChangeCode = (e) => {
    let code = e.target.value;
    const regex = /^(?=.*[a-zA-Z]{3})(?=.*\d{1})[a-zA-Z\d]{4}$/;

    if (code.length <= 4) {
      setCodeUnit(code)
    }

    if (regex.test(code)) {
      setIsValid(true)
    } else {
      setIsValid(false)
    }
  }

  useEffect(() => {
    const fetchUnitData = async () => {
      try {
        const response = await api.get(`/v1/units/${unitId}`);

        const unitData = response.data;
        setCodeUnit(unitData.code)
        setName(unitData.name);
        setSelectedHeadOfUnit(unitData.headOfUnit?.id);
        setSelectedCities(unitData.city?.id);
        setSelectedRegion(unitData.province?.id);
        setAddress(unitData.address);
        setActive(unitData.isActive);

        setSelectedLabelHeadOfUnit(unitData.headOfUnit?.fullName);
        setSelectedLabelCity(unitData.city?.labels);
        setSelectedLabelRegion(unitData.province?.labels);
        setResponseRegions(unitData?.region?.labels)
      } catch (error) {
        Sentry.captureException(error);
      }
    };

    fetchUnitData();
  }, [unitId]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const params = {
          search: searchHeadOfUnit,
          role: "Kepala Unit"
        };
        const response = await api.get("/v1/users/dropdown", { params });
        setListUsers(response?.data?.contents);
      } catch (error) {
        Sentry.captureException(error);
      }
    };

    const fetchRegions = async () => {
      try {
        const params = {
          search: searchRegions
        }
        const responseRegions = await api.get('/v1/regions/dropdown', { params })
        setListRegions(responseRegions?.data?.contents)
      } catch (error) {
        console.log(error)
      }
    }

    fetchRegions()
    fetchUsers();
  }, [searchHeadOfUnit, searchRegions]);


  useEffect(() => {
    const dataFetchDropdownRegion = async () => {
      try {
        const responseRegionDropdown = await api.get(
          "/v1/regions/id/province/dropdown", {
            params: {
              search: searchProvince,
            },
          }
        );
        setRegions(responseRegionDropdown.data);
      } catch (error) {
        Sentry.captureException(error);
      }
    };

    dataFetchDropdownRegion();
  }, [searchProvince]);


  useEffect(() => {
    const fetchCities = async () => {
      const data = await api.get(`/v1/regions/id/cities/dropdown`, {
        params: {
          search: searchCity,
          province: selectedRegion,
        },
      });
      setCities(data?.data);
    };

    fetchCities();
  }, [ searchCity, selectedRegion]);

  const handleSave = async () => {
    setIsSubmitting(true);
    const newUnit = {
      name: name,
      code: codeUnit,
      address: address,
      headOfUnit: { id: selectedHeadOfUnit },
      city: { id: selectedCities },
      region: { id: selectRegions },
      province: { id: selectedRegion },
      isActive: isActive,
    };
    try {
      await api.put(`/v1/units/${unitId}`, newUnit);

      setEditSuccess(true);

      navigate("/master/unit");
      localStorage.setItem(
        "responseSuccessEdit",
        JSON.stringify(!isEditSuccess)
      );

      if (onReloadData) {
        onReloadData();
      }
    } catch (error) {
      setErrorShowMessage(true)
      handleCloseEditDialog();
      setIsSubmitting(false);;
      setIsEditError(true);
      setErrorMessage(
        error.response.data.error?.name || error.response.data.error
      );
      setTimeout(() => setIsEditError(false), 2000);
    }
  };

  const handleBack = () => {
    navigate("/master/unit");
  };

  return (
    <div className="p-4 flex justify-center">
      <div>
        <Card className="border w-[800px] shadow-none border-border-secondary">
          <CardHeader floated={false} shadow={false} className="rounded-b-none">
            <Typography className="text-xl font-bold text-text-primary flex items-center leading-normal">
              <div className="cursor-pointer pr-2" onClick={handleBack}>
                <ChevronLeft />
              </div>
              Ubah unit
            </Typography>
          </CardHeader>
          <div className="flex justify-center w-full">
            <CardBody className="w-[440px]">
              <div className="mb-3xl">
                <div>
                  <label className="block text-sm font-semibold text-text-primary leading-tight mb-md">
                    Region{" "}
                    <span className="text-red-500 text-sm font-normal leading-tight">
                      *
                    </span>
                  </label>

                  <FormDropdownSearch 
                    onSelect={handleSelectRegions}
                    size="medium"
                    placeholder={responseRegions}
                    defaultValue={true}
                    options={listRegions}
                    setSearch={setSearchRegions}
                    search={searchRegions}
                  />
                </div>

                <div className="mt-5">
                  <label className="block text-sm font-semibold text-text-primary leading-tight mb-md">
                    Nama Unit{" "}
                    <span className="text-red-500 text-sm font-normal leading-tight">
                      *
                    </span>
                  </label>
                  <Input
                    type="text"
                    id="name"
                    placeholder="Masukkan nama unit"
                    className="max-w-md"
                    value={name}
                    size="medium"
                    onChange={handleNameChange}
                  />
                </div>
              </div>

              <div className="mb-3xl">
                <Typography className="text-sm font-semibold text-text-primary leading-tight">
                  Kode Unit <span className="text-red-500">*</span>
                </Typography>

                  <Input
                    type="text"
                    id="name"
                    placeholder="Masukkan nama unit"
                    className="max-w-md"
                    value={codeUnit?.toLocaleUpperCase("id-ID")}
                    size="medium"
                    onChange={handleChangeCode} 
                  />
              </div>

              {showErrorMessage && (
                <p className="text-red-500 text-sm mt-1">{errorMessage}</p>
              )}

              {codeUnit === "" && (
                <p className="text-red-500 text-sm mt-1">
                  Kolom ini tidak boleh kosong
                </p>
              )}

              <Typography className="text-sm font-semibold text-text-primary leading-tight">
                Kepala Unit <span className="text-red-500">*</span>
              </Typography>

              <div className="mt-md mb-3xl">
                <FormDropdownSearch
                  onSelect={handleSelectHeadOfUnit}
                  placeholder={selectedLabelHeadOfUnit}
                  options={listUser}
                  defaultValue={true}
                  setSearch={setSearchHeadOfUnit}
                  search={searchHeadOfUnit}
                  size="medium"
                />
              </div>

              <Typography className="text-sm font-semibold text-text-primary leading-tight">
                Provinsi <span className="text-red-500">*</span>
              </Typography>

              <div className="mt-md mb-3xl">
                <FormDropdownSearch
                  onSelect={handleSelectRegion}
                  placeholder={selectedLabelRegion}
                  defaultValue={true}
                  options={regions?.contents}
                  setSearch={setSearchProvince}
                  search={searchProvince}
                  size="medium"
                />
              </div>

              <Typography className="text-sm font-semibold text-text-primary leading-tight">
                Kabupaten / kota <span className="text-red-500">*</span>
              </Typography>

              <div className="mt-md mb-3xl">
                <FormDropdownSearch
                  onSelect={handleSelectCities}
                  placeholder={selectedLabelCity}
                  defaultValue={true}
                  options={cities?.contents}
                  setSearch={setSearchCity}
                  search={searchCity}
                  size="medium"
                />
              </div>

              <Typography className="text-sm font-semibold text-text-primary leading-tight">
                Alamat Unit <span className="text-red-500">*</span>
              </Typography>

              <div className="mt-md mb-3xl">
                <TextArea
                  placeholder="Masukkan alamat unit"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  className={"placeholder:text-text-placeholder"}
                />
              </div>
              <div className="items-center gap-4">
                <div className="mb-md">
                  <label className="block font-semibold text-text-primary leading-tight text-sm">
                    Status unit
                  </label>
                </div>
                <div>
                  <Switch
                    className="checked:bg-bg-brandSolid"
                    onChange={handleSwitchChange}
                    checked={isActive}
                  />
                  <span className="m-2 text-sm">
                    {isActive ? "Aktif" : "Tidak Aktif"}
                  </span>
                </div>
              </div>

              <div className="mt-9 items-center gap-4">
                <div className="my-3"></div>
                <div></div>
              </div>
            </CardBody>
          </div>

          <CardFooter className="flex justify-end border-t border-border-secondary p-lg">
            <Button
              label="Batal"
              size="medium"
              type="btnSecondary"
              className="mr-md"
              onClick={handleBack}
            />
            <Button
              label="Simpan"
              size="medium"
              // type={`${
              //   isSubmitDisabled || isSubmitting 
              //     ? "btnPrimaryDisabled"
              //     : "btnPrimary"
              // }`}
              onClick={handleOpenEditDialog}
              // disabled={isSubmitDisabled || isSubmitting}
              componentLeft={
                isSubmitting && (
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    className="animate-spin fill-neutral-400"
                  >
                    <path
                      d="M10.72,19.9a8,8,0,0,1-6.5-9.79A7.77,7.77,0,0,1,10.4,4.16a8,8,0,0,1,9.49,6.52A1.54,1.54,0,0,0,21.38,12h.13a1.37,1.37,0,0,0,1.38-1.54,11,11,0,1,0-12.7,12.39A1.54,1.54,0,0,0,12,21.34h0A1.47,1.47,0,0,0,10.72,19.9Z"
                      className="spinner_6kVp"
                    />
                  </svg>
                )
              }
            />
          </CardFooter>
        </Card>

        <ConfirmDialog
          isOpen={isEditDialogOpen}
          onClose={handleCloseEditDialog}
          onConfirm={handleSave}
          title="Konfirmasi ubah unit"
          content="Anda yakin ingin melanjutkan tindakan ini?"
          btnLabel="Lanjutkan"
          btnType="btnPrimary"
        />

        {isEditError && (
          <AlertNotification
            icon={<FaCheckCircle />}
            color="red"
            message={errorMessage}
          />
        )}
      </div>
    </div>
  );
};

export default EditUnit;
