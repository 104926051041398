import { Card, Typography } from "@material-tailwind/react";
import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import { Button } from "../../../atoms/Button";
import waIcon from "../../../img/Social.png";
import emailIcon from "../../../img/mail-01.png";
import { FaChevronRight } from "react-icons/fa";
import api from "../../../api/configApi";
import { useState } from "react";
import LoadingAnimation from "../../../atoms/LoadingAnimation";

const AskOtp = ({ onClose, response, onNext, loadingProcces }) => {
  const [loading, setLoading] = useState(false); // Set initial loading state

  const dataInquiry = JSON.parse(localStorage.getItem("newUsernamePassword"));
  const { username } = dataInquiry;

  const dataSentConfirmationOtp = [
    {
      id: 1,
      destination: "WHATSAPP",
      username: response?.data?.phoneNumber,
      icon: waIcon,
    },
    {
      id: 2,
      destination: "EMAIL",
      username: response?.data?.email,
      icon: emailIcon,
    },
  ];

  const handleClickSendOTP = async (val) => {
    localStorage.setItem("dataConfirmationOTP", JSON.stringify(val));
    setLoading(true); // Set loading to true before request

    try {
      const reqBody = {
        username: username,
        destination: val.destination,
      };

      const responseSendOtp = await api.post("/v1/auth/reqForgotPass", reqBody);

      localStorage.setItem(
        "responseOTPSession",
        JSON.stringify(responseSendOtp?.data?.otpSession)
      );
      localStorage.setItem("userNameFromButtom", JSON.stringify(username));
      localStorage.setItem("desctination", JSON.stringify(responseSendOtp))

      onNext();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false); // Set loading back to false after request
    }
  };

  return (
    <>
      {loading ? (
        <div className="bg-transparent">
          <LoadingAnimation className="bg-transparent" />
        </div>
      ) : (
        <div className="fixed top-0 w-full h-full flex items-center justify-center bg-white bg-opacity-0">
          <Card className="w-[484px] bg-white rounded-xl relative p-3xl">
            <div className="items-start cursor-pointer" onClick={onClose}>
              <ArrowLeftIcon strokeWidth={2} className="h-4 w-4" />
            </div>

            <Typography
              type="h2"
              className="text-xl text-center font-semibold text-slate-900 "
            >
              Pilih Metode Verifikasi
            </Typography>
            <Typography
              type="small"
              className="text-sm text-center text-slate-800 "
            >
              Silahkan pilih metode pengiriman OTP untuk melanjutkan proses masuk
            </Typography>

            <div className="space-y-4"> 
              {dataSentConfirmationOtp?.map((val) => (
                <Button
                  onClick={() => handleClickSendOTP(val)}
                  className="flex items-center justify-between w-full mt-2xl p-4 bg-white shadow-md rounded-lg hover:shadow-lg" // Styling for buttons
                  size="large"
                  type="btnSecondary"
                  disabled={loading} // Disable button while loading
                  componentLeft={<img src={val.icon} alt={`${val.destination} icon`} className="h-6 w-6 mr-4" />} // Icon styling
                  componentRight={
                    <FaChevronRight className="h-5 w-5 text-slate-600 hover:text-neutral-800" />
                  }
                  label={
                    <div className="flex flex-col items-start pl-[60px] w-[440px]">
                      <Typography
                        type="small"
                        className="text-slate-700 text-base font-semibold"
                      >
                        Kirim lewat {val.destination}
                      </Typography>
                      <Typography
                        type="small"
                        className="text-neutral-500 text-sm font-medium"
                      >
                        {val.username}
                      </Typography>
                    </div>
                  }
                />
              ))}
            </div>
          </Card>
        </div>
      )}
    </>
  );
};

export default AskOtp;
