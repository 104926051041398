import { useQuery } from "@tanstack/react-query";

import api from "../../api/configApi";

const useGetUserDropdownList = ({ search = "", role = "", unit = "" }) => {
  const query = useQuery({
    queryKey: ["get-user-dropdown-list", { search, role }],
    queryFn: async () => {
      const response = await api.get("/v1/users/dropdown", {
        params: {
          search,
          role,
          unit,
        },
      });
      return response;
    },
    select: ({ data }) => data?.contents,
  });

  return query;
};

export default useGetUserDropdownList;
