/* eslint-disable no-unused-vars */
import { useRef, useState } from "react";
import { ReactComponent as ChevronDown } from "../assets/icons/chevron_down.svg";
import { Card, CardBody, CardHeader } from "@material-tailwind/react";

const sizes = {
  xsmall: { input: "h-7 rounded-sm", icon: "h-5 w-5" },
  small: { input: "h-8 rounded-sm", icon: "h-5 w-5" },
  medium: { input: "h-11 rounded-md", icon: "h-5 w-5" },
  large: { input: "h-14 rounded-md", icon: "h-6 w-6" },
};

const DropdownSapronak = ({
  size,
  type,
  placeholder,
  className,
  search,
  disabled,
  color,
  options,
  onSelect,
  setSearch,
  defaultProduct,
  isLoading,
  isStock,
  isType
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState("");
  const [selectProduct, setSelectProduct] = useState(null);
  const [currentPakan, setCurrentPakan] = useState(0)
  const inputRef = useRef();
  const ZAK_WEIGHT = 50;

  const handleSearchChange = (e) => {
    setSearch(e.target.value || setSelected(""));
  };

  const handleSelect = (select) => {
    onSelect(select);
    setSelectProduct(select);
    setIsOpen(false);
    setSelected(select.labels || search);
    setSearch("");
    setCurrentPakan(select.stock)
  };

  const handleClickOutside = (event) => {
    if (inputRef.current && !inputRef.current.contains(event.target)) {
      setIsOpen(false);
      setSearch("");
    }
  };

  document.addEventListener("mousedown", handleClickOutside);

  const height = sizes[size]["input"];

  const countZak = (value) => {
    const zakCount = value / ZAK_WEIGHT;
    const roundedZakCount = Math.floor(zakCount);

    
    return roundedZakCount
  }


  return (
    <div className="relative">
      <input
        type={type}
        placeholder={placeholder}
        className={`${height} w-full border border-${color}-500 ${className} text-neutral-800 px-sm py-xs justify-start items-center gap-xxs inline-flex focus:outline-none focus:border-primary-400 focus:ring focus:ring-primary-200 disabled:bg-white pl-lg placeholder:text-text-placeholder`}
        value={search || selected}
        onChange={handleSearchChange}
        onClick={() => setIsOpen(!isOpen)}
        disabled={disabled}
      />

      {isStock && (
       <>
        {currentPakan === 0 ? null : (
          <div className="relative w-[90%]">
            <div className="absolute bottom-3 right-0 flex items-center text-[#F79009] text-sm whitespace-nowrap">
              Sisa pakan : {Math.floor(currentPakan / 50)} Zak
            </div>
          </div>
        )}
       </>
      )}

      <ChevronDown
        className={`absolute right-4 top-3 ${
          isOpen ? "transform rotate-180" : ""
        } text-gray-500`}
      />

      {isOpen && (
        <Card className="p-0 mt-2">
          {options?.lenght < 0 ? null : (
            <CardHeader
              floated={false}
              shadow={false}
              className="border-b rounded-none pb-1"
            >
             Cari
            </CardHeader>
          )}

          <CardBody className="p-0">
            <ul className="p-0 relative z-30 bg-white w-full mt-1 overflow-y-auto max-h-60">
              {isLoading ? (
                <li className="p-2 pl-4 hover:bg-gray-100 cursor-pointer text-neutral-500">
                  Loading...
                </li>
              ) : options?.length > 0 ? (
                options?.map((item, index) => (
                  <li
                    key={index}
                    className="rounded-lg px-2 py-1 hover:bg-gray-100 cursor-pointer grid grid-cols-5"
                    onClick={() => handleSelect(item)}
                  >
                    <div className="col-span-2">
                      <div className="flex justify-start">
                        <img
                          src={item.image || defaultProduct}
                          alt="default"
                          className="h-12 w-12 rounded-md"
                        />

                        <div className="grid grid-rows-2 px-2">
                          <div className="row-span-1">{item?.labels}</div>
                          {isType && (
                            <div>
                              {item?.type?.labels}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    {isStock && (
                      <div className="relative w-[90%] col-span-3 flex justify-end items-center">
                        <span className="absolute text-sm font-medium text-[#F79009]">
                          {/* {item.stock} zak */}
                          Sisa Pakan: {countZak(item.stock)} Zak
                        </span>
                      </div>
                    )}
                  </li>
                ))
              ) : (
                <li className="m-2">Tidak ada data</li>
              )}
            </ul>
          </CardBody>
        </Card>
      )}
    </div>
  );
};

export default DropdownSapronak;
