import React, { useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  Typography,
} from "@material-tailwind/react";
import { format } from 'date-fns';
import idLocale from 'date-fns/locale/id';
import moment from "moment";
import "moment/locale/id";
import LoadingAnimation from "../../../../../atoms/LoadingAnimation";
import { ReactComponent as ChevronDown } from "../../../../../assets/icons/chevron_down.svg";
moment.locale("id");

const RincianTransaksi = ({ ResponseDetail, loading }) => {
  const [isOpenDocInReport, setIsOpenDocInReport] = useState(true);
  const [isOpenFeedInReport, setIsOpenFeedInReport] = useState(true);
  const [isOpenOvkInReport, setIsOpenOvkInReport] = useState(true);
  const [isOpenHarvestReport, setIsOpenHarvestReport] = useState(true);
  const handleToggleDocInReport = () => setIsOpenDocInReport(!isOpenDocInReport);
  const handleToggleFeedInReport = () => setIsOpenFeedInReport(!isOpenFeedInReport);
  const handleToggleOvkInReport = () => setIsOpenOvkInReport(!isOpenOvkInReport);
  const handleToggleHarvestReport = () => setIsOpenHarvestReport(!isOpenHarvestReport);

  const docInReport = [
    {
      id: 1,
      label: "Tanggal",
      value: format(new Date(ResponseDetail?.docInReport?.date ), 'dd MMMM yyyy', { locale: idLocale }),
    },
    {
      id: 2,
      label: "Produk",
      value: ResponseDetail?.docInReport?.product,
    },
    {
      id: 3,
      label: "QTY",
      value: ResponseDetail?.docInReport?.qty?.toLocaleString("id-ID"),
    },
    {
      id: 4,
      label: "Satuan",
      value: "ekor",
    },
    {
      id: 5,
      label: "Harga",
      value:  "Rp" + ResponseDetail?.docInReport?.price?.toLocaleString("id-ID"),
    },
    {
      id: 6,
      label: "Jumlah",
      value: "Rp" + ResponseDetail?.docInReport?.total?.toLocaleString("id-ID"),
    },
  ];

  const TABLE_HEAD_PAKAN = ["Tanggal", "Produk", "QTY", "Satuan", "Harga", "Jumlah"];
  const TABLE_HEAD_OVK = ["Tanggal", "Produk", "QTY", "Satuan", "Harga", "Jumlah"];
  const TABLE_HEAD_PANEN = ["Tanggal", "No. DO", "Ekor", "Umur", "Satuan", "Harga", "Jumlah"];

  const calculateTotalPakan = () => {
    let totalPakan = 0;
    ResponseDetail?.feedInReport?.forEach((produk) => {
        totalPakan += parseInt(produk.total, 10);
    });
    return "Rp" + totalPakan.toLocaleString("id-ID");
  };

  const calculateTotalQtyPakan = () => {
    let totalQtyPakan = 0;
    ResponseDetail?.feedInReport?.forEach((produk) => {
        totalQtyPakan += parseInt(produk.qty, 10);
    });
    return totalQtyPakan.toLocaleString("id-ID");
  };

  const calculateTotalOvk = () => {
    let totalOvk = 0;
    ResponseDetail?.ovkInReport?.forEach((produk) => {
        totalOvk += parseInt(produk.total, 10);
    });
    return "Rp" + totalOvk.toLocaleString("id-ID");
  };

  const calculateTotalQtyOvk= () => {
    let totalQtyOvk = 0;
    ResponseDetail?.ovkInReport?.forEach((produk) => {
        totalQtyOvk += parseInt(produk.qty, 10);
    });
    return totalQtyOvk.toLocaleString("id-ID");
  };

  const calculateTotalHarvest = () => {
    let totalPrice = 0;
    ResponseDetail?.harvestReport?.forEach((produk) => {
        totalPrice += parseInt(produk.totalPrice, 10);
    });
    return "Rp" + totalPrice.toLocaleString("id-ID");
  };

  const calculateTotalQtyHarvest = () => {
    let totalAyam = 0;
    ResponseDetail?.harvestReport?.forEach((produk) => {
        totalAyam += parseInt(produk.numberOfChicken, 10);
    });
    return totalAyam.toLocaleString("id-ID");;
  };

  return (
    <div className="felx h-auto xl:overflow-hidden overflow-hidden w-full">
      <div className="flex flex-col flex-1 overflow-hidden">
        {loading ? (
          <>
            <LoadingAnimation />
          </>
        ) : (
          <div className="m-8 grid sm:grid-cols-1">
            <div className="grid grid-cols-1">
              <div className="w-full">
                <Card className="p-0 w-full border shadow-none">
                  <CardHeader
                    floated={false}
                    shadow={false}
                    className={`p-0 m-0 ${
                      isOpenDocInReport && "rounded-none rounded-tr-xl rounded-tl-xl"
                    } border-none bg-neutral-900 cursor-pointer`}
                    onClick={handleToggleDocInReport}
                  >
                    <div className="flex justify-between p-4">
                      <Typography
                        variant="h7"
                        className="font-semibold text-base items-center text-white leading-normal"
                      >
                        Day old chick
                      </Typography>
                      <div className="flex items-center text-center">
                        <ChevronDown
                          className={`relative ${
                            isOpenDocInReport ? "transform rotate-180" : ""
                          } text-gray-100 font-bold`}
                          onClick={handleToggleDocInReport}
                        />
                      </div>
                    </div>
                  </CardHeader>
                  {isOpenDocInReport && (
                    <>
                      <CardBody className="p-0 overflow-scroll max-w-full max-h-[960px] z-10 border-t">
                        <div className="grid grid-cols-6">
                          {docInReport?.map((val) => (
                            <>
                              <div key={val.id} className="">
                                <div className="w-full bg-gray-100 p-4">
                                  <Typography
                                    className="text-left text-sm font-semibold text-text-quarterary leading-tight"
                                  >
                                    {val.label}
                                  </Typography>
                                </div>

                                <div className="mt-1 p-4">
                                  <Typography
                                    className="text-left text-sm font-medium text-text-primary leading-tight"
                                  >
                                    {val?.value}
                                  </Typography>
                                </div>
                              </div>
                            </>
                          ))}
                        </div>
                      </CardBody>
                      <CardFooter className="p-0 overflow-scroll max-w-full max-h-[960px] z-10 border-t">
                        <div className="grid grid-cols-6">
                          <>
                            <div>
                              <div className="w-full bg-gray-100 p-4 rounded-bl-xl">
                                <Typography
                                  className="text-left text-sm font-bold text-text-primary leading-tight"
                                >
                                  TOTAL
                                </Typography>
                              </div>
                            </div>
                            <div className="w-full bg-gray-100 p-4">{""}</div>
                            <div>
                              <div className="w-full bg-gray-100 p-4">
                                <Typography
                                  className="text-left text-sm font-bold text-text-primary leading-tight"
                                >
                                  {ResponseDetail?.docInReport?.qty?.toLocaleString("id-ID")}
                                </Typography>
                              </div>
                            </div>
                            <div className="w-full bg-gray-100 p-4">{""}</div>
                            <div className="w-full bg-gray-100 p-4">{""}</div>
                            <div>
                              <div className="w-full bg-gray-100 p-4 rounded-br-xl">
                                <Typography
                                  className="text-left text-sm font-bold text-text-primary leading-tight"
                                >
                                 Rp{ResponseDetail?.docInReport.total.toLocaleString("id-ID")}
                                </Typography>
                              </div>
                            </div>
                          </>
                        </div>
                      </CardFooter>
                    </>
                  )}
                </Card>
              </div>

              <div className="w-full mt-4">
                <Card className="p-0 w-full border shadow-none">
                  <CardHeader
                    floated={false}
                    shadow={false}
                    className={`p-0 m-0 ${
                      isOpenFeedInReport && "rounded-none rounded-tr-xl rounded-tl-xl"
                    } border-none bg-neutral-900 cursor-pointer`}
                    onClick={handleToggleFeedInReport}
                  >
                    <div className="flex justify-between p-4">
                      <Typography
                        variant="h7"
                        className="font-semibold text-base items-center text-white leading-normal"
                      >
                        Pakan
                      </Typography>
                      <div className="flex items-center text-center">
                        <ChevronDown
                          className={`relative ${
                            isOpenFeedInReport ? "transform rotate-180" : ""
                          } text-gray-100 font-bold`}
                          onClick={handleToggleFeedInReport}
                        />
                      </div>
                    </div>
                  </CardHeader>
                  {isOpenFeedInReport && (
                    <>
                      <CardBody className="p-0 overflow-scroll max-w-full max-h-[960px] z-10 border-t">
                        <div className="grid grid-cols-7">
                          {TABLE_HEAD_PAKAN?.map((head) => (
                              <div key={head} className={`border-b ${head === 'Produk' && 'col-span-2'}`}>
                                <div className="w-full bg-gray-100 p-4">
                                  <Typography
                                    className="text-left text-sm font-semibold text-text-quarterary leading-tight"
                                  >
                                    {head}
                                  </Typography>
                                </div>
                              </div>
                          ))}
                        </div>
                        <div className="grid grid-cols-7">
                          {ResponseDetail?.feedInReport ? ResponseDetail?.feedInReport?.map((val, index) => (
                            <>
                              <div className="border-b">
                                <div className="w-full p-4">
                                  <Typography
                                    className="text-left text-sm font-medium text-text-primary leading-tight"
                                  >
                                    {format(new Date(val.date ), 'dd MMMM yyyy', { locale: idLocale })}
                                  </Typography>
                                </div>
                              </div>
                              <div className="border-b col-span-2">
                                <div className="w-full p-4 ">
                                  <Typography
                                    className="text-left text-sm font-medium text-text-primary leading-tight"
                                  >
                                    {val.product}
                                  </Typography>
                                </div>
                              </div>
                              <div className="border-b">
                                <div className="w-full p-4">
                                  <Typography
                                    className="text-left text-sm font-medium text-text-primary leading-tight"
                                  >
                                    {val?.qty.toLocaleString("id-ID")}
                                  </Typography>
                                </div>
                              </div>
                              <div className="border-b">
                                <div className="w-full p-4">
                                  <Typography
                                    className="text-left text-sm font-medium text-text-primary leading-tight"
                                  >
                                    kg
                                  </Typography>
                                </div>
                              </div>
                              <div className="border-b">
                                <div className="w-full p-4">
                                  <Typography
                                    className="text-left text-sm font-medium text-text-primary leading-tight"
                                  >
                                    Rp {val?.price.toLocaleString("id-ID")}
                                  </Typography>
                                </div>
                              </div>
                              <div className="border-b">
                                <div className="w-full p-4">
                                  <Typography
                                    className="text-left text-sm font-medium text-text-primary leading-tight"
                                  >
                                    Rp{val?.total.toLocaleString("id-ID")}
                                  </Typography>
                                </div>
                              </div>
                            </>
                          )) : ( 
                            <div className="col-span-7 p-4">
                               <Typography
                                    className="text-center text-sm font-medium text-text-primary leading-tight"
                                  >
                                    Tidak ada data
                                  </Typography>
                            </div>
                          )}
                        </div>
                      </CardBody>
                      <CardFooter className="p-0 overflow-scroll max-w-full max-h-[960px] z-10">
                        <div className="grid grid-cols-7">
                          <>
                            <div>
                              <div className="w-full bg-gray-100 p-4 rounded-bl-xl">
                                <Typography

                                  className="text-left text-sm font-bold text-text-primary leading-tight"
                                >
                                  TOTAL
                                </Typography>
                              </div>
                            </div>
                            <div className="w-full col-span-2 bg-gray-100 p-4">{""}</div>
                            <div>
                              <div className="w-full bg-gray-100 p-4">
                                <Typography

                                  className="text-left text-sm font-bold text-text-primary leading-tight"
                                >
                                  {calculateTotalQtyPakan()}
                                </Typography>
                              </div>
                            </div>
                            <div className="w-full bg-gray-100 p-4">{""}</div>
                            <div className="w-full bg-gray-100 p-4">{""}</div>
                            <div>
                              <div className="w-full bg-gray-100 p-4 rounded-br-xl">
                                <Typography

                                  className="text-left text-sm font-bold text-text-primary leading-tight"
                                >
                                  {calculateTotalPakan()}
                                </Typography>
                              </div>
                            </div>
                          </>
                        </div>
                      </CardFooter>
                    </>
                  )}
                </Card>
              </div>


              <div className="w-full mt-4">
                <Card className="p-0 w-full border shadow-none">
                  <CardHeader
                    floated={false}
                    shadow={false}
                    className={`p-0 m-0 ${
                      isOpenOvkInReport && "rounded-none rounded-tr-xl rounded-tl-xl"
                    } border-none bg-neutral-900 cursor-pointer`}
                    onClick={handleToggleOvkInReport}
                  >
                    <div className="flex justify-between p-4">
                      <Typography
                        variant="h7"
                        className="font-semibold text-base items-center text-white leading-normal"
                      >
                        Obat, vaksin, & kimia
                      </Typography>
                      <div className="flex items-center text-center">
                        <ChevronDown
                          className={`relative ${
                            isOpenOvkInReport ? "transform rotate-180" : ""
                          } text-gray-100 font-bold`}
                          onClick={handleToggleOvkInReport}
                        />
                      </div>
                    </div>
                  </CardHeader>
                  {isOpenOvkInReport && (
                    <>
                      <CardBody className="p-0 overflow-scroll max-w-full max-h-[960px] z-10 border-t">
                        <div className="grid grid-cols-7">
                          {TABLE_HEAD_OVK?.map((head) => (
                            <>
                              <div key={head} className={`border-b ${head === 'Produk' && 'col-span-2'}`}>
                                <div className="w-full bg-gray-100 p-4">
                                  <Typography
                                    className="text-left text-sm font-semibold text-text-quarterary leading-tight"
                                  >
                                    {head}
                                  </Typography>
                                </div>
                              </div>
                            </>
                          ))}
                        </div>
                        <div className="grid grid-cols-7">
                          {ResponseDetail?.ovkInReport ? ResponseDetail?.ovkInReport?.map((val) => (
                            <>
                              <div className="border-b">
                                <div className="w-full p-4">
                                  <Typography
                                    className="text-left text-sm font-medium text-text-primary leading-tight"
                                  >
                                    {format(new Date(val.date ), 'dd MMMM yyyy', { locale: idLocale })}
                                  </Typography>
                                </div>
                              </div>
                              <div className="border-b col-span-2">
                                <div className="w-full p-4">
                                  <Typography
                                    className="text-left text-sm font-medium text-text-primary leading-tight"
                                  >
                                    {val.product}
                                  </Typography>
                                </div>
                              </div>
                              <div className="border-b">
                                <div className="w-full p-4">
                                  <Typography
                                    className="text-left text-sm font-medium text-text-primary leading-tight"
                                  >
                                    {val?.qty.toLocaleString("id-ID")}
                                  </Typography>
                                </div>
                              </div>
                              <div className="border-b">
                                <div className="w-full p-4">
                                  <Typography
                                    className="text-left text-sm font-medium text-text-primary leading-tight"
                                  >
                                    kg
                                  </Typography>
                                </div>
                              </div>
                              <div className="border-b">
                                <div className="w-full p-4">
                                  <Typography
                                    className="text-left text-sm font-medium text-text-primary leading-tight"
                                  >
                                    Rp{val?.price.toLocaleString("id-ID")}
                                  </Typography>
                                </div>
                              </div>
                              <div className="border-b">
                                <div className="w-full p-4">
                                  <Typography
                                    className="text-left text-sm font-medium text-text-primary leading-tight"
                                  >
                                    Rp{val?.total.toLocaleString("id-ID")}
                                  </Typography>
                                </div>
                              </div>
                            </>
                          )) : ( 
                            <div className="col-span-7 p-4">
                               <Typography
                                    className="text-center text-sm font-medium text-text-primary leading-tight"
                                  >
                                    Tidak ada data
                                  </Typography>
                            </div>
                          )}
                        </div>
                      </CardBody>
                      <CardFooter className="p-0 overflow-scroll max-w-full max-h-[960px] z-10">
                        <div className="grid grid-cols-7">
                          <>
                            <div>
                              <div className="w-full bg-gray-100 p-4 rounded-bl-xl">
                                <Typography
                                  className="text-left text-sm font-bold text-text-primary leading-tight"
                                >
                                  TOTAL
                                </Typography>
                              </div>
                            </div>
                            <div className="w-full col-span-2 bg-gray-100 p-4">{""}</div>
                            <div>
                              <div className="w-full bg-gray-100 p-4">
                                <Typography
                                  className="text-left text-sm font-bold text-text-primary leading-tight"
                                >
                                  {calculateTotalQtyOvk()}
                                </Typography>
                              </div>
                            </div>
                            <div className="w-full bg-gray-100 p-4">{""}</div>
                            <div className="w-full bg-gray-100 p-4">{""}</div>
                            <div>
                              <div className="w-full bg-gray-100 p-4 rounded-br-xl">
                                <Typography
                                  className="text-left text-sm font-bold text-text-primary leading-tight"
                                >
                                  {calculateTotalOvk()}
                                </Typography>
                              </div>
                            </div>
                          </>
                        </div>
                      </CardFooter>
                    </>
                  )}
                </Card>
              </div>


              <div className="w-full mt-4">
                <Card className="p-0 w-full border shadow-none">
                  <CardHeader
                    floated={false}
                    shadow={false}
                    className={`p-0 m-0 ${
                      isOpenHarvestReport && "rounded-none rounded-tr-xl rounded-tl-xl"
                    } border-none bg-neutral-900 cursor-pointer`}
                    onClick={handleToggleHarvestReport}
                  >
                    <div className="flex justify-between p-4">
                      <Typography
                        variant="h7"
                        className="font-semibold text-base items-center text-white leading-normal"
                      >
                        Panen
                      </Typography>
                      <div className="flex items-center text-center">
                        <ChevronDown
                          className={`relative ${
                            isOpenHarvestReport ? "transform rotate-180" : ""
                          } text-gray-100 font-bold`}
                          onClick={handleToggleHarvestReport}
                        />
                      </div>
                    </div>
                  </CardHeader>
                  {isOpenHarvestReport && (
                    <>
                      <CardBody className="p-0 overflow-scroll max-w-full max-h-[960px] z-10 border-t">
                        <div className="grid grid-cols-7">
                          {TABLE_HEAD_PANEN?.map((head) => (
                            <>
                              <div key={head} className="border-b min-w-[150px]">
                                <div className="w-full bg-gray-100 p-4">
                                  <Typography
                                    className="text-left text-sm font-semibold text-text-quarterary leading-tight"
                                  >
                                    {head}
                                  </Typography>
                                </div>
                              </div>
                            </>
                          ))}
                        </div>
                        <div className="grid grid-cols-7">
                          {ResponseDetail?.harvestReport?.map((val) => (
                            <>
                              <div key={""} className="border-b">
                                <div className="w-full p-4 min-w-[150px]">
                                  <Typography
                                    className="text-left text-sm font-medium text-text-primary leading-tight"
                                  >
                                    {format(new Date(val.date ), 'dd MMMM yyyy', { locale: idLocale })}
                                  </Typography>
                                </div>
                              </div>
                              <div key={""} className="border-b">
                                <div className="w-full p-4">
                                  <Typography
                                    className="text-left text-sm font-medium text-text-primary leading-tight"
                                  >
                                    {val.doNumber}
                                  </Typography>
                                </div>
                              </div>
                              <div key={""} className="border-b">
                                <div className="w-full p-4">
                                  <Typography
                                    className="text-left text-sm font-medium text-text-primary leading-tight"
                                  >
                                    {val?.numberOfChicken.toLocaleString("id-ID")}
                                  </Typography>
                                </div>
                              </div>
                              <div key={""} className="border-b">
                                <div className="w-full p-4">
                                  <Typography
                                    className="text-left text-sm font-medium text-text-primary leading-tight"
                                  >
                                    {val.age }
                                  </Typography>
                                </div>
                              </div>
                              <div key={""} className="border-b">
                                <div className="w-full p-4">
                                  <Typography
                                    className="text-left text-sm font-medium text-text-primary leading-tight"
                                  >
                                    kg
                                  </Typography>
                                </div>
                              </div>
                              <div key={""} className="border-b">
                                <div className="w-full p-4">
                                  <Typography
                                    className="text-left text-sm font-medium text-text-primary leading-tight"
                                  >
                                    Rp{val?.price.toLocaleString("id-ID")}
                                  </Typography>
                                </div>
                              </div>
                              <div key={""} className="border-b">
                                <div className="w-full p-4">
                                  <Typography
                                    className="text-left text-sm font-medium text-text-primary leading-tight"
                                  >
                                    Rp{val?.totalPrice.toLocaleString("id-ID")}
                                  </Typography>
                                </div>
                              </div>
                            </>
                          ))}
                        </div>
                      </CardBody>
                      <CardFooter className="p-0 overflow-scroll max-w-full max-h-[960px] z-10">
                        <div className="grid grid-cols-7">
                          <>
                            <div key="1" className="">
                              <div className="w-full bg-gray-100 p-4 rounded-bl-xl">
                                <Typography
                                  className="text-left text-sm font-bold text-text-primary leading-tight"
                                >
                                  TOTAL
                                </Typography>
                              </div>
                            </div>
                            <div className="w-full bg-gray-100 p-4">{""}</div>
                            <div>
                              <div className="w-full bg-gray-100 p-4">
                                <Typography
                                  className="text-left text-sm font-bold text-text-primary leading-tight"
                                >
                                  {calculateTotalQtyHarvest()}
                                </Typography>
                              </div>
                            </div>
                            <div className="w-full bg-gray-100 p-4">{""}</div>
                            <div className="w-full bg-gray-100 p-4">{""}</div>
                            <div className="w-full bg-gray-100 p-4">{""}</div>
                            <div>
                              <div className="w-full bg-gray-100 p-4 rounded-br-xl min-w-[150px]">
                                <Typography
                                  className="text-left text-sm font-bold text-text-primary leading-tight"
                                >
                                  {calculateTotalHarvest()}
                                </Typography>
                              </div>
                            </div>
                          </>
                        </div>
                      </CardFooter>
                    </>
                  )}
                </Card>
              </div>

            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default RincianTransaksi;
