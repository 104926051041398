/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Card,
  CardHeader,
  Typography,
  CardBody,
  CardFooter,
  Chip,
} from "@material-tailwind/react";
import {
  PlusIcon,
  ChevronUpDownIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";

import { Button } from "../../../../atoms/Button";
import Input from "../../../../atoms/Input";
import Pagination from "../../../../atoms/Pagination";
import { FaCheckCircle } from "react-icons/fa";
import api from "../../../../api/configApi";
import DropdownFilterStatus from "../../../../atoms/DropdownFilterStatus";
import { ReactComponent as EmptyIcon } from "../../../../assets/icons/emptyIconn.svg";
import LoadingAnimation from "../../../../atoms/LoadingAnimation";
import AlertNotification from "../../../../atoms/AlertNotification";
import * as Sentry from "@sentry/react";
import Cookies from 'js-cookie'
import { ButtonIcon } from "../../../../atoms/ButtonIcon";

const MasterUnit = () => {
  const currentPageCookies = parseInt(Cookies.get("current-page-masterUnit"))
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [isSearchProcess, setIsSearchProcess] = useState(false);

  const [units, setUnits] = useState([]);
  const [regions, setRegions] = useState([]);
  const [cities, setCities] = useState([]);
  const [currentPage, setCurrentPage] = useState(
    currentPageCookies ? currentPageCookies : 1
  );
  const [totalPages, setTotalPages] = useState(1);
  const [isFetchError, setIsFetchError] = useState(false);
  Cookies.set("current-page-masterUnit", currentPage)

  const sortableColumns = [
    "",
    "name",
    "headOfUnit.fullName",
    "code",
    "city.province.name",
    "city.name",
    "address",
    "",
    "",
    "isActive",
  ];
  const [sortBy, setSortBy] = useState("");
  const [descending, setDescending] = useState(true);

  const [selectedStatus, setSelectedStatus] = useState();

  const [checkedRegions, setCheckedRegions] = useState([]);
  const [checkedCities, setCheckedCities] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const alertAdd = JSON.parse(localStorage.getItem("responseAddSuccess"));
  const alertEdit = JSON.parse(localStorage.getItem("responseSuccessEdit"));

  const [notifAdd, setNotfAdd] = useState(alertAdd);
  const [notifEdit, setNotfEdit] = useState(alertEdit);

  const [labelStatus, setLabelStatus] = useState(null);

  const TABLE_HEAD = [
    "No",
    "Nama unit",
    "Kode unit",
    "Region",
    "Kepala unit",
    "Provinsi",
    "Kabupaten/Kota",
    "Alamat",
    "Jumlah plasma",
    "Jumlah kandang",
    "Status",
    "Aksi",
  ];

  const handleOpenEditModal = (unitId) => {
    navigate(`/master/unit/edit-unit/${unitId}`);
  };

  const handleAddClick = () => {
    navigate("/master/unit/tambah-unit");
  };

  useEffect(() => {
    setTimeout(() => {
      setNotfAdd(false);
      localStorage.clear("responseAddSuccess");
    }, 4000);
  }, [notifAdd]);

  useEffect(() => {
    setTimeout(() => {
      setNotfEdit(false);
      localStorage.clear("responseSuccessEdit");
    }, 4000);
  }, [notifEdit]);

  useEffect(() => {
    setIsSearchProcess(true);
    const fetchData = async () => {
      setIsLoading(true);
      try {
        let stringProvinceList =
          checkedRegions.length > 0 ? checkedRegions.join(",") : null;

        let stringCitiesList =
          checkedCities.length > 0 ? checkedCities.join(",") : null;

        const params = {
          itemPerPage: 10,
          search: searchTerm,
          currentPage: currentPage - 1,
          sortBy: sortBy,
          descending: descending,
        };

        if (stringProvinceList !== null) {
          params.province = stringProvinceList;
        }

        if (stringCitiesList !== null) {
          params.city = stringCitiesList;
        }

        if (selectedStatus !== null) {
          params.isActive = selectedStatus;
        }
        const response = await api.get("/v1/units", { params });

        setUnits(response.data.contents);
        setTotalPages(response.data.totalPages);
        setIsFetchError(false);
      } catch (error) {
        Sentry.captureException(error);
        setIsFetchError(true);
      } finally {
        setIsLoading(false);
      }
    };

    const dataFetchDropdownRegion = async () => {
      try {
        const responseRegionDropdown = await api.get(
          "/v1/regions/id/province/dropdown"
        );
        setRegions(responseRegionDropdown.data);
      } catch (error) {
        Sentry.captureException(error);
      }
    };

    const fetchCities = async () => {
      const data = await api.get(`/v1/regions/id/cities/dropdown`);
      setCities(data?.data);
    };

    fetchCities();

    dataFetchDropdownRegion();
    fetchData();
  }, [
    checkedRegions,
    checkedCities,
    currentPage,
    searchTerm,
    sortBy,
    descending,
    selectedStatus,
  ]);

  const handleSort = (column) => {
    if (sortableColumns.includes(column)) {
      if (sortBy === column) {
        setDescending(!descending);
      } else {
        setSortBy(column);
        setDescending(false);
      }
    }
  };

  const handleSearchChange = (e) => {
    setIsSearchProcess(!isSearchProcess);
    const keywordSearch = e.target.value;
    setSearchTerm(keywordSearch);
    setIsSearchProcess(false);
    handlePageChange(1);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleFilterStatus = (val) => {
    handlePageChange(1);
    setSelectedStatus(val);
    if (val === true) {
      setLabelStatus("Aktif");
    } else if (val === false) {
      setLabelStatus("Tidak aktif");
    } else if (val === null) {
      setLabelStatus("Semua Status");
    }
  };

  const statusUnits = [
    {
      id: 1,
      IsActive: true,
      labels: ["Aktif"],
      labelsPlaceholder: "Aktif",
    },
    {
      id: 2,
      IsActive: false,
      labels: ["Tidak aktif"],
      labelsPlaceholder: "Tidak aktif",
    },
  ];

  const handleResetFilterStatus = () => {
    setSelectedStatus("");
    setLabelStatus("");
  };

  return (
    <div className={`flex h-auto overflow-hidden`}>
      <div className="flex flex-col flex-1 overflow-hidden">
        <div className="p-4">
          <Card className="w-full h-auto border border-border-secondary shadow-none p-0 m-0">
            <CardHeader
              floated={false}
              shadow={false}
              className="p-2 m-0 border-none w-full"
            >
              <div className="flex justify-between px-4 pt-4 mb-1">
                <Typography className="text-xl font-bold text-text-primary">
                  Unit
                </Typography>

              
              </div>

              <div className="grid grid-cols-4 px-4 gap-4 mb-4">
                <div className="text-left">
                  <Input
                    type="text"
                    id="search"
                    placeholder="Pencarian.."
                    className="max-w-[404px]"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    size="medium"
                    componentLeft={<MagnifyingGlassIcon className="h-5 w-5" />}
                    componentRight={
                      !isSearchProcess && (
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                          className="animate-spin fill-neutral-400 max-w-sm"
                        >
                          <path
                            d="M10.72,19.9a8,8,0,0,1-6.5-9.79A7.77,7.77,0,0,1,10.4,4.16a8,8,0,0,1,9.49,6.52A1.54,1.54,0,0,0,21.38,12h.13a1.37,1.37,0,0,0,1.38-1.54,11,11,0,1,0-12.7,12.39A1.54,1.54,0,0,0,12,21.34h0A1.47,1.47,0,0,0,10.72,19.9Z"
                            className="spinner_6kVp"
                          />
                        </svg>
                      )
                    }
                  />
                </div>
                <div className="relative w-[208px]">
                  <DropdownFilterStatus
                    data={statusUnits}
                    handleSelect={handleFilterStatus}
                    popoverContentPosition={"top-[240px] left-[760px]"}
                    selectedStatus={selectedStatus}
                    label={labelStatus}
                    onReset={handleResetFilterStatus}
                  />
                </div>
                <div></div>
                <div className="text-center flex justify-end">
                  <Button
                    label="Tambah Unit"
                    size="medium"
                    type="btnPrimary"
                    onClick={handleAddClick}
                    componentRight={<PlusIcon className="h-5 w-5" />}
                  />
                </div>
              </div>
            </CardHeader>

            {isLoading ? (
              <div className="flex justify-center items-center h-screen">
                <LoadingAnimation />
              </div>
            ) : (
              <>
                {units?.length === 0 || isFetchError ? (
                  <div className=" flex justify-center items-center h-screen">
                    <div>
                      <div className="flex justify-center">
                        <EmptyIcon className="ml-[30px]" />
                      </div>

                      <Typography
                        variant="h2"
                        className="text-xl font-semibold p-4 text-center text-text-secondary"
                      >
                        Data tidak ditemukan
                      </Typography>

                      <Typography
                        variant="medium"
                        className="flex justify-center font-normal leading-none w-full text-text-secondary min-h-full bg-gray-950"
                      >
                        Data yang Anda cari tidak ditemukan. Coba kata kunci
                        lain
                      </Typography>
                    </div>
                  </div>
                ) : (
                  <>
                    <CardBody className="p-0 overflow-x-scroll">

                      <table className="w-full min-w-max table-auto text-left">
                        <thead>
                          <tr>
                            {TABLE_HEAD.map((head, index) => (
                              <th
                                key={head}
                                className={`border-y border-border-secondary bg-neutral-50 py-2 px-lg ${index === 11 ? 'sticky right-0 bg-neutral-50 z-10' : ''}`}
                              >
                                <Typography className="flex justify-between text-sm font-semibold text-text-quarterary leading-tight">
                                  {head}{" "}
                                  {index !== 0 &&
                                    index !== TABLE_HEAD.length - 1 && (
                                      <button
                                        onClick={() =>
                                          handleSort(sortableColumns[index])
                                        }
                                        className="focus:outline-none ml-1"
                                      >
                                        <ChevronUpDownIcon
                                          strokeWidth={2}
                                          className={`h-4 w-4 ${
                                            descending
                                              ? "transform rotate-180"
                                              : ""
                                          }`}
                                        />
                                      </button>
                                    )}
                                </Typography>
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {units.map((unit, index) => {
                            const isLast = index === units.length - 1;
                            const classes = isLast
                              ? "p-lg"
                              : "p-lg border-b border-border-secondary";

                            return (
                              <tr key={unit.id}>
                                <td className={classes}>
                                  <div className="flex items-center">
                                    <Typography className="text-text-primary text-sm font-medium leading-tight">
                                      {(currentPage - 1) * 10 + index + 1}.
                                    </Typography>
                                  </div>
                                </td>
                                <td className={classes + " max-w-[350px]"}>
                                  <Typography className="text-text-primary text-sm font-medium leading-tight">
                                    {unit.name}
                                  </Typography>
                                </td>
                                <td className={classes}>
                                  <Typography className="text-text-primary text-sm font-medium leading-tight">
                                    {unit.code || "-"}
                                  </Typography>
                                </td>
                                {/* <td className={classes}>
                                  <Typography className="text-text-primary text-sm font-medium leading-tight">
                                    {unit?.region?.labels || "-"}
                                  </Typography>
                                </td> */}

                                <td className={classes}>
                                  <div className="w-max">
                                    <Chip
                                      size="sm"
                                      variant="ghost"
                                      value={
                                        unit?.region?.labels || "-"
                                      }
                                      className={`text-sm font-inter font-normal leading-[18px] border normal-case ${
                                        unit?.region?.labels === "Jawa Timur"
                                          ? "text-green-600 border-green-200 bg-green-25"
                                          : "text-blue-800 border-blue-200 bg-blue-50"
                                      }`}
                                  
                                    />
                                  </div>
                                </td>
                                <td className={classes}>
                                  <Typography className="text-text-primary text-sm font-medium leading-tight">
                                    {unit.headOfUnit?.fullName || "-"}
                                  </Typography>
                                </td>
                                <td className={classes}>
                                  <Typography className="text-text-primary text-sm font-medium leading-tight">
                                    {unit?.province?.labels || "-"}
                                  </Typography>
                                </td>
                                <td className={classes}>
                                  <Typography className="text-text-primary text-sm font-medium leading-tight">
                                    {unit.city?.labels || "-"}
                                  </Typography>
                                </td>
                                <td className={classes + " max-w-[350px]"}>
                                  <Typography className="text-text-primary text-sm font-medium leading-tight">
                                    {unit.address || "-"}
                                  </Typography>
                                </td>
                                <td className={classes}>
                                  <Typography className="text-text-primary text-sm font-medium leading-tight">
                                    {unit.totalPlasma || "0"}
                                  </Typography>
                                </td>
                                <td className={classes}>
                                  <Typography className="text-text-primary text-sm font-medium leading-tight">
                                    {unit.totalHouse || "0"}
                                  </Typography>
                                </td>
                                <td className={classes}>
                                  <div className="w-max">
                                    <Chip
                                      size="sm"
                                      variant="ghost"
                                      value={
                                        unit.isActive ? "Aktif" : "Tidak aktif"
                                      }
                                      className={`text-sm font-inter font-normal leading-[18px] border normal-case ${
                                        unit.isActive
                                          ? "text-green-600 border-green-200 bg-green-25"
                                          : "text-neutral-800 border-neutral-200 bg-neutral-50"
                                      }`}
                                      icon={
                                        unit.isActive ? (
                                          <span className="mx-auto mt-1 block h-2 w-2 rounded-full bg-green-500 content-['']" />
                                        ) : (
                                          <span className="mx-auto mt-1 block h-2 w-2 rounded-full bg-gray-500 content-['']" />
                                        )
                                      }
                                    />
                                  </div>
                                </td>
                                <td className={`border-l border-b border-border-secondary bg-white sticky right-0 px-lg shadow-[rgba(0,0,10,0.2)_-3px_1px_12px_-5px]`}>
                                  <ButtonIcon
                                      className="z-25"
                                      onClick={() =>
                                        handleOpenEditModal(unit.id)
                                      }
                                      icon="edit"
                                      type="outlineWarning"
                                      size="small"
                                    />
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </CardBody>
                    <CardFooter className="p-sm items-center border-t border-border-secondary">
                      <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={handlePageChange}
                      />
                    </CardFooter>
                  </>
                )}
              </>
            )}
          </Card>
        </div>
        {notifAdd && (
          <AlertNotification
            icon={<FaCheckCircle />}
            color="green"
            message="Data unit berhasil ditambah."
          />
        )}

        {notifEdit && (
          <AlertNotification
            icon={<FaCheckCircle />}
            color="green"
            message="Data unit berhasil diubah."
          />
        )}
      </div>
    </div>
  );
};

export default MasterUnit;
