import { Card, CardBody, CardHeader, Chip } from "@material-tailwind/react";
import { IoClose } from "react-icons/io5";
import { FaChevronUp } from "react-icons/fa6";
import React, { useState } from "react";
import moment from "moment";

const CardDetailMonGrow = ({
  detailData,
  handleCloseDetail,
  feedInData,
  feedOutData,
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const handleToggle = () => setIsOpen(!isOpen);
  
  return (
    <Card
      className={`h-auto border border-border-secondary shadow-none p-0 m-0 w-[300px] transition-all duration-300 transform ${
        detailData ? "translate-x-0 opacity-100" : "translate-x-full opacity-0"
      }`}
    >
      <CardHeader
        className="p-0 m-sm h-[120px] border-b border-border-secondary rounded-none"
        floated={false}
        shadow={false}
      >
        <div className="flex justify-between items-center text-sm">
          <div className="text-left font-bold text-text-primary">
            {detailData.house?.owner?.fullName}
          </div>
          <button
            className="text-red-500 font-bold"
            onClick={handleCloseDetail}
          >
            <IoClose className="h-7 w-7 bg-white text-text-quarterary hover:text-text-primary" />
          </button>
        </div>
        <div className="flex justify-between items-center text-xs">
          <div className="text-left font-normal text-text-quarterary">
            {detailData.analyticAccount}
          </div>
        </div>
        <div className="flex justify-between text-sm my-md">
          <Chip
            size="sm"
            variant="ghost"
            value={
              "PPL: " +
              (detailData.house?.ppl ? detailData.house?.ppl?.fullName : "-")
            }
            className={`font-normal bg-neutral-50 leading-[18px] normal-case`}
          />
        </div>
      </CardHeader>
      <CardBody className="p-lg overflow-scroll">
        <div className="flex justify-between items-center text-sm">
          <div className="text-left font-bold text-text-primary">DOC</div>
        </div>
        <div className="flex justify-between items-center text-xs my-md">
          <div className="text-left text-text-secondary">Tgl DOC In:</div>
          <div className="text-left text-text-secondary">
            {moment(detailData.docInDate).format("DD MMMM YYYY")}
          </div>
        </div>
        <div className="flex justify-between items-center text-xs my-md">
          <div className="text-left text-text-secondary">Merk DOC In:</div>
          <div className="text-left text-text-secondary">CP (Vaksin)</div>
        </div>
        <div className="flex justify-between items-center text-xs my-md">
          <div className="text-left text-text-secondary">BW:</div>
          <div className="text-left text-text-secondary">
            <Chip
              size="sm"
              variant="ghost"
              value={detailData.recap?.averageWeight + " gr" || "-"}
              className={`font-medium text-xs bg-warning-25 border border-warning-200 text-warning-500 leading-[18px] normal-case`}
            />
          </div>
        </div>
        <div className="flex justify-between items-center text-xs my-md">
          <div className="text-left text-text-secondary">Populasi:</div>
          <div className="text-left text-text-secondary">
            <Chip
              size="sm"
              variant="ghost"
              value={"5000 ekor"}
              className={`font-medium text-xs bg-success-25 border border-success-200 text-success-600 leading-[18px] normal-case`}
            />
          </div>
        </div>
        <div className="flex justify-between items-center text-xs my-md">
          <div className="text-left text-text-secondary">RGR:</div>
          <div className="text-left text-text-secondary flex justify-start">
            <Chip
              size="sm"
              variant="ghost"
              value={detailData.rgr || "0.0"}
              className={`font-medium text-xs bg-purple-25 border border-purple-200 text-purple-600 leading-[18px] normal-case`}
            />
          </div>
        </div>

        <hr className="border-t border-border-secondary my-lg" />
        <div className="flex justify-between items-center text-sm">
          <div className="text-left font-bold text-text-primary">Pakan</div>
        </div>
        <div className="grid grid-cols-2 items-center text-xs my-md gap-6">
          <div className="text-left text-text-secondary col-span-1">
            Total pakan terkirim:
          </div>
          <div className="text-left font-bold text-text-primary col-span-1">
            {detailData.feedRecap?.totalQtyIn / 50} sak
          </div>
        </div>
        <div className="grid grid-cols-2 items-center text-xs my-md gap-6">
          <div className="text-left text-text-secondary col-span-1">
            Total pakan terpakai:
          </div>
          <div className="text-left font-bold text-text-primary col-span-1">
            {detailData.feedRecap?.totalQtyUsed / 50} sak
          </div>
        </div>
        <div className="grid grid-cols-2 items-center text-xs my-md gap-6">
          <div className="text-left text-text-secondary col-span-1">
            Total pakan tersisa:
          </div>
          <div className="text-left font-bold text-text-primary col-span-1">
            {detailData.feedRecap?.totalRemainder /50} sak
          </div>
        </div>

        <div className="flex justify-between items-center text-sm mt-2xl">
          <div className="text-left font-bold text-text-primary">
            List Pakan
          </div>
          <div
            className="text-left font-bold text-text-primary"
            onClick={handleToggle}
          >
            <FaChevronUp className={`${!isOpen && "transform rotate-180"}`} />
          </div>
        </div>
        {isOpen && (
          <>
            <div className="flex justify-between items-center text-xs leading-[18px] mt-lg">
              <div className="text-left font-medium text-text-tertiary">
                Nama produk
              </div>
              <div className="text-left font-medium text-text-tertiary">
                QTY
              </div>
            </div>
            <hr className="border-t border-border-secondary my-md" />

            {feedInData &&
              feedInData.map((feedIn, index) => (
                <div>
                  <div
                    key={index}
                    className="flex justify-between items-center text-xs mt-md"
                  >
                    <div className="text-left text-text-successSecondary">
                      (+) {feedIn.feed?.labels}
                    </div>
                    <div className="text-left text-text-successSecondary">
                      <Chip
                        size="sm"
                        variant="ghost"
                        value={feedIn.qtyIn / 50 + " sak"}
                        className={` font-normal bg-transparent border border-border-secondary leading-[18px] normal-case`}
                        icon={
                          <span className="mx-auto mt-1 block h-2 w-2 rounded-full bg-gray-500 content-['']" />
                        }
                      />
                    </div>
                  </div>
                  <hr className="border-t border-border-secondary my-lg" />
                </div>
              ))}

            {feedOutData &&
              feedOutData.map((feedOut, index) => (
                <div>
                  <div
                    key={index}
                    className="flex justify-between items-center text-xs mt-md"
                  >
                    <div className="text-left text-text-errorPrimary">
                      (-) {feedOut.feed?.labels}
                    </div>
                    <div className="text-left text-text-errorPrimary">
                      <Chip
                        size="sm"
                        variant="ghost"
                        value={feedOut.qtyOut / 50 + " sak"}
                        className={` font-normal bg-transparent border border-border-secondary leading-[18px] normal-case`}
                        icon={
                          <span className="mx-auto mt-1 block h-2 w-2 rounded-full bg-gray-500 content-['']" />
                        }
                      />
                    </div>
                  </div>
                  <hr className="border-t border-border-secondary my-lg" />
                </div>
              ))}
          </>
        )}
      </CardBody>
    </Card>
  );
};

export default CardDetailMonGrow;
