// Maximum number of pinnable columns
export const MAX_PINNED_COLUMNS = 4;

export const validatePinningConfig = (pinningConfigs) => {
  // Filter out undefined or null configs
  const validConfigs = pinningConfigs.filter(
    (config) => config.column && config.direction
  );

  // Return only the first 4 valid configurations
  return validConfigs.slice(0, MAX_PINNED_COLUMNS);
};

export const getColumnPinningState = (pinningConfigs) => {
  const validConfigs = validatePinningConfig(pinningConfigs);

  return {
    left: validConfigs
      .filter((config) => config.direction === "left")
      .map((config) => config.column),
    right: validConfigs
      .filter((config) => config.direction === "right")
      .map((config) => config.column),
  };
};

export const calculatePinnedPosition = (cell, allCells) => {
  const isPinned = cell.column.getIsPinned();
  if (!isPinned) return null;

  if (isPinned === "right") return { right: 0 };

  // For left-pinned columns, calculate cumulative width
  if (isPinned === "left") {
    let position = 0;
    for (const currentCell of allCells) {
      if (currentCell.id === cell.id) break;
      if (currentCell.column.getIsPinned() === "left") {
        // Use getBoundingClientRect() for actual rendered width
        const width = currentCell.column.getSize();
        position += width;
      }
    }
    return { left: `${position}px` };
  }

  return null;
};

export const isLastPinnedColumn = (cell, allCells) => {
  const direction = cell.column.getIsPinned();
  if (!direction) return false;

  const pinnedCells = allCells.filter(
    (c) => c.column.getIsPinned() === direction
  );
  const lastPinnedCell = pinnedCells[pinnedCells.length - 1];

  return cell.id === lastPinnedCell.id;
};
