import { ChevronUpDownIcon } from "@heroicons/react/24/outline";
import {
  Avatar,
  Card,
  CardBody,
  CardFooter,
  Popover,
  PopoverContent,
  PopoverHandler,
  Typography,
} from "@material-tailwind/react";
import moment from "moment";
import "moment/locale/id";
import { ReactComponent as BurgerDots } from "../../assets/icons/burgers-dot.svg";
import { ReactComponent as EditIcon } from "../../assets/icons/edit_icon.svg";
import { ReactComponent as DeleteIcon } from "../../assets/icons/deleted.svg";
import { ReactComponent as IconRightblue } from "../../assets/icons/chevron-right-blue.svg"
import { ReactComponent as IconSapronak } from "../../assets/icons/ovk-icon-sapronak.svg"
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Pagination from "../../atoms/Pagination";
import { ReactComponent as EmptyIcon } from "../../assets/icons/emptyIconn.svg";
import DefaultImageOvk from "../../img/OVK.png";
import ConfirmDialog from "../../atoms/ConfirmDialog";
import api from "../../api/configApi";
import AlertNotification from "../../atoms/AlertNotification";
import { FaCheckCircle } from "react-icons/fa";
import { Button } from "../../atoms/Button";
import DrawerSapronak from "../../atoms/DrawerSapronak";
import TagLabel from "../../atoms/TagLabel";

const TABLE_HEAD = [
  "Produk",
  "Tanggal masuk",
  "Jumlah",
  "Harga Satuan",
  "Total harga",
  "",
];

const TableOVK = ({
  dataTable,
  totalPages,
  currentPage,
  setCurrentPage,
  sortableColumns,
  handleSort,
  descending,
  periodId
}) => {
  const dataSetting = [
    { id: 1, label: "Ubah", icon: <EditIcon /> },
    { id: 2, label: "Hapus", icon: <DeleteIcon /> },
  ];

  const [openDialog, setOpenDialog] = useState(false);
  const [getId, setGetId] = useState(null);
  const [errorNotifDelete, setErrorNotifDelete] = useState(false);
  const [errorDeleteMessage, setErrorDeleteMessage] = useState(false);
  const [showAll, setShowAll] = useState(false)
  const [responseStockOvk, setResponseStockOvk] = useState(null)
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate();

  const handlePage = (target) => {
    setCurrentPage(target);
  };

  const handleShowall = () => {
    setShowAll(!showAll)
  }

  const handleConditionalRoute = (target) => {
    if (target === 1) {
      navigate(`/ovk/update-ovk/${getId}`);
    } else if (target === 2) {
      setOpenDialog(!openDialog);
    }
  };

  useEffect(() => {
    setLoading(true)

    const OVKStock = async () => {
      try {
        const responseStock = await api.get('/v1/ovks/stock/list', {
          params: {
            periodId: periodId
          }
        })

        setResponseStockOvk(responseStock?.data?.contents)
      } catch (error) {
        console.log("error", error)
      } finally {
        setLoading(false)
      }
    }

    OVKStock()
  }, [periodId])


  const handleConfirmationDelete = async () => {
    try {
      await api.delete(`/v1/ovks/in/${getId}`);
      setOpenDialog(false);
      window.location.reload();
      localStorage.setItem("settingType", "sapronak");
      localStorage.setItem("settingTypeSapronak", "ovk");
      localStorage.setItem(
        "successNotifDeleteOvk",
        JSON.stringify(true)
      );
    } catch (error) {
      setOpenDialog(false);
      setErrorNotifDelete(!errorNotifDelete);
      setErrorDeleteMessage(error.response.data.error);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setErrorNotifDelete(false);
      setErrorDeleteMessage("");
    }, 4000);
  }, [errorNotifDelete]);

  console.log("ovk stock", responseStockOvk)

  return (
    <>
      <Card className="h-full w-full shadow-none">
        <Typography className="text-md font-semibold p-6 text-text-primary">
          Jumlah OVK ({responseStockOvk?.length})
        </Typography>
        
        <div className="flex flex-wrap p-[20px] gap-4">
        {loading
          ? Array(3) 
        .fill(0)
        .map((_, index) => (
          <Card
            key={index}
            className="flex-shrink-0 w-full sm:w-[40%] lg:w-[26%] h-auto shadow-none border border-border-secondary rounded-[8px]"
          >
            <CardBody className="relative overflow-hidden border-border-secondary rounded p-3">
              <div className="flex justify-between items-start">
                <div>
                  <div className="h-6 bg-gray-300 animate-pulse rounded w-2/3 mb-2"></div>
                  <div className="h-4 bg-gray-300 animate-pulse rounded w-1/3"></div>
                </div>
                <div className="w-[40px] h-[40px] sm:w-[50px] sm:h-[50px] bg-gray-300 animate-pulse rounded"></div>
              </div>

              <div className="flex justify-between mt-[10px]">
                <div className="h-4 bg-gray-300 animate-pulse rounded w-1/2"></div>
                <div className="h-4 bg-gray-300 animate-pulse rounded w-1/4"></div>
              </div>

              <div className="flex justify-between mt-[10px]">
                <div className="h-4 bg-gray-300 animate-pulse rounded w-1/2"></div>
                <div className="h-4 bg-gray-300 animate-pulse rounded w-1/4"></div>
              </div>
            </CardBody>
          </Card>
        ))
        : responseStockOvk?.slice(0, 3).map((val) => (
            <Card
              key={val.id} 
              className="flex-shrink-0 w-full sm:w-[40%] lg:w-[26%] h-auto shadow-none border border-border-secondary rounded-[8px]"
            >
              <CardBody className="relative overflow-hidden border-border-secondary rounded p-3">
                <div className="flex justify-between items-start">
                  <div>
                    <Typography className="text-lg sm:text-xl text-text-primary font-bold leading-tight">
                      {val.labels}
                    </Typography>
                    <div className="text-start">
                      <TagLabel size="xsmall" type="info" children={val?.unit?.labels} />
                    </div>
                  </div>
                  <IconSapronak className="w-[40px] h-[40px] sm:w-[50px] sm:h-[50px]" />
                </div>

                <div className="flex justify-between mt-[10px]">
                  <Typography className="text-text-quarterary text-sm sm:text-sm font-normal leading-tight">
                    Stok tersedia (Sachet)
                  </Typography>
                  <Typography className="text-sm sm:text-sm text-text-primary font-bold leading-tight">
                    {val.stock} Sachet
                  </Typography>
                </div>

                {/* <div className="flex justify-between mt-[10px]">
                  <Typography className="text-text-quarterary text-sm sm:text-md font-normal leading-tight">
                    Stok tersedia (kg)
                  </Typography>
                  <Typography className="text-sm sm:text-md text-text-primary font-bold leading-tight">
                    {typeof val.stock === "number"
                      ? val.stock.toLocaleString("id-ID")
                      : JSON.stringify(val.stock)}{" "}
                    kg
                  </Typography>
                </div> */}
              </CardBody>
            </Card>
          ))}

          {responseStockOvk?.length > 3 && (
             <div className="mt-[50px]">
              <Button
                label="Lihat lainya"
                type="btnLinkBlue"
                onClick={handleShowall}
                componentRight={<IconRightblue />}
              />
            </div>
          )}
        </div>


        {dataTable?.contents?.length === 0 ? (
          <div className=" flex justify-center items-center h-screen">
            <div>
              <div className="flex justify-center">
                <EmptyIcon />
              </div>

              <Typography
                variant="h2"
                color="blue-gray"
                className="text-xl font-semibold p-4 text-center"
              >
                Data tidak ditemukan
              </Typography>

              <Typography
                variant="medium"
                color="blue-gray"
                className="flex justify-between font-normal leading-none opacity-70 w-full min-h-full bg-gray-950"
              >
                Data yang Anda cari tidak ditemukan. Coba kata kunci lain
              </Typography>
            </div>
          </div>
        ) : (
          <>
            <CardBody className="overflow-scroll p-0">
              <div className="flex justify-start p-[20px] mt-[14px]">
                <Typography className="text-md text-text-primary font-bold leading-tight">
                  Riwayat penambahan
                </Typography>
              </div>

              <table className="mt-lg w-full min-x-max table-auto text-left">
                <thead>
                  <tr>
                    {TABLE_HEAD?.map((head, index) => (
                      <th
                        key={head}
                        className={`border-y border-border-secondary bg-neutral-25 p-lg`}
                      >
                        <div
                          className={`flex ${
                            index === 0 || index === 1
                              ? "justify-between"
                              : "justify-end"
                          } `}
                        >
                          <Typography
                            className={`flex items-center leading-none h-[21px] font-bold text-xs text-text-tertiary ${
                              index === 0 || index === 1
                                ? "justify-start"
                                : "justify-end"
                            }`}
                          >
                            {head}
                          </Typography>
                          {index !== 5 && TABLE_HEAD.length - 1 && (
                            <button
                              onClick={() => handleSort(sortableColumns[index])}
                              className="focus:outline-none ml-1"
                            >
                              <ChevronUpDownIcon
                                strokeWidth={2}
                                onClick={handleShowall}
                                className={` h-4 w-4 ${
                                  descending ? "transform rotate-180" : ""
                                }`}
                              />
                            </button>
                          )}
                        </div>
                      </th>
                    ))}
                  </tr>
                </thead>

                <tbody>
                  {dataTable?.contents?.map((val, index) => {
                    const isLast = index === dataTable.length - 1;
                    const clases = isLast
                      ? "p-lg"
                      : "p-lg border-t border-border-secondary";

                    return (
                      <tr key={val.id}>
                        <td className={clases + " pl-0"}>
                          <div className="flex items-center gap-3">
                            <div className="flex justify-around gap-2">
                              <div className="pl-3xl">
                                {val?.image === undefined ? (
                                  <Avatar
                                    src={DefaultImageOvk}
                                    alt="product image"
                                    variant="rounded"
                                  />
                                ) : (
                                  val?.image
                                )}
                              </div>

                              <div className="text-left grid grid-rows-2 items-center">
                                <Typography className="text-text-primary text-sm font-normal leading-tight">
                                  {val?.ovk?.labels}
                                </Typography>

                                <Typography
                                  variant="small"
                                  color="blue-gray"
                                  className="text-text-quarterary text-xs"
                                >
                                  {val?.ovk?.shape?.labels}
                                </Typography>
                              </div>
                            </div>
                          </div>
                        </td>

                        <td className={clases}>
                          <Typography className="text-text-primary text-sm font-normal leading-tight">
                            {moment(val?.dateIn)
                              .locale("id")
                              .format("D MMMM YYYY")}
                          </Typography>
                        </td>

                        <td className={clases + " text-right"}>
                          <Typography className="text-text-primary text-sm font-normal leading-tight">
                            {val?.qtyIn.toLocaleString("id-ID")}
                          </Typography>
                        </td>

                        <td className={clases + " text-right"}>
                          <Typography className="text-text-primary text-sm font-normal leading-tight">
                            Rp{val?.price.toLocaleString("id-ID")}
                          </Typography>
                        </td>

                        <td className={clases + " text-right"}>
                          <Typography className="text-text-primary text-sm font-normal leading-tight">
                            Rp
                            {(
                              parseFloat(val.qtyIn) *
                              parseFloat(val.price).toFixed()
                            ).toLocaleString("id-ID")}
                          </Typography>
                        </td>

                        <td className={clases}>
                          <Popover placement="left">
                            <PopoverHandler onClick={() => setGetId(val.id)}>
                              <button className="flex items-center">
                                <BurgerDots />
                              </button>
                            </PopoverHandler>

                            <PopoverContent className="w-[8%]">
                              {dataSetting.map((val) => (
                                <div
                                  onClick={() => handleConditionalRoute(val.id)}
                                  className="flex gap-4 cursor-pointer mt-2 hover:opacity-50"
                                >
                                  <div>{val.icon}</div>
                                  <div>{val.label}</div>
                                </div>
                              ))}
                            </PopoverContent>
                          </Popover>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </CardBody>

            <CardFooter className="items-center border-t border-border-secondary p-0">
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePage}
              />
            </CardFooter>
          </>
        )}
      </Card>

      <div>
        <ConfirmDialog
          isOpen={openDialog}
          onClose={() => setOpenDialog(!openDialog)}
          onConfirm={handleConfirmationDelete}
          title="Hapus OVK Masuk"
          content="Anda yakin ingin melanjutkan tindakan ini?"
          btnLabel="Hapus"
          btnType="btnError"
        />
      </div>
      {(errorNotifDelete) && (
        <AlertNotification
          icon={<FaCheckCircle />}
          color="red"
          message={errorDeleteMessage}
        />
      )}

     <div className="mt-[20px]">
        <DrawerSapronak 
          isOpen={showAll}
          onClose={() => setShowAll(!showAll)}
          response={responseStockOvk}
          // response={dataDummy}
          OVK
        />
     </div>
    </>
  );
};

export default TableOVK;
