import {
  Card,
  CardBody,
  Drawer,
  IconButton,
  Typography,
} from "@material-tailwind/react";
import { ReactComponent as IconSapronak } from "../assets/icons/sapronak-icon.svg";
import { ReactComponent as IconOVK } from "../assets/icons/ovk-icon-sapronak.svg";
import TagLabel from "./TagLabel";

const DrawerSapronak = ({ isOpen, onClose, className = "", response, OVK }) => {
  return (
    <>
      {isOpen && (
        <div
          className="fixed inset-0 w-full h-full bg-transparent bg-opacity-60 backdrop-blur-sm z-[9998]"
          onClick={onClose}
        ></div>
      )}

      <Drawer
        open={isOpen}
        placement="right"
        onClose={onClose}
        className={`fixed top-0 right-0 z-[9999] shadow-2xl backdrop-blur-none p-4 bg-white ${
          isOpen ? "translate-x-0" : "translate-x-full"
        } ${className}`}
        style={{
          width: "954px",
          height: "100vh",
          transition: "transform 0.3s ease-in-out",
          paddingTop: "-40px",
        }}
      >
        <div className="flex items-center justify-between mb-6">
          <Typography className="text-md font-semibold">
            Jenis pakan ({response?.length})
          </Typography>
          <IconButton variant="text" color="blue-gray" onClick={onClose}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke="currentColor"
              className="h-5 w-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </IconButton>
        </div>

        {/* Scrollable Content Section */}
        <div
          className="flex-grow overflow-y-auto pr-4 max-h-[calc(100vh-100px)]"
          style={{
            scrollBehavior: "smooth",
            paddingRight: "16px",
          }}
        >
          {response?.map((val, index) => (
            <Card
              key={index}
              className="max-h-full max-w-full w-[280px] h-[145px] mt-[10px] shadow-none border border-border-secondary"
            >
              <CardBody className="relative overflow-scroll p-3">
                <div className="flex justify-between">
                  <div>
                    <Typography className="text-sm text-text-primary font-bold leading-tight">
                      {val?.labels}
                    </Typography>
                    {OVK ? (
                      <TagLabel size="xsmall" type="info" children={val?.unit?.labels} />
                    ) : (
                      <TagLabel
                        size="xsmall"
                        type="info"
                        children={val?.type?.labels}
                      />
                    )}
                  </div>
                  {OVK ? (
                    <IconOVK className="w-[50px] h-[50px]" />
                  ) : (
                    <IconSapronak className="w-[50px] h-[50px]" />
                  )}
                </div>

                {OVK ? (
                  <div className="flex justify-between mt-[10px]">
                    <Typography className="text-text-quarterary text-sm font-normal leading-tight">
                      Stok tersedia (Sachet)
                    </Typography>
                    <Typography className="text-sm text-text-primary font-bold leading-tight">
                      {val.stock / 50} Sachet
                    </Typography>
                  </div>
                ) : (
                  <>
                    <div className="flex justify-between mt-[10px]">
                      <Typography className="text-text-quarterary text-sm font-normal leading-tight">
                        Stok tersedia (Sak)
                      </Typography>
                      <Typography className="text-sm text-text-primary font-bold leading-tight">
                        {val.stock / 50} Sak
                      </Typography>
                    </div>

                    <div className="flex justify-between mt-[10px]">
                      <Typography className="text-text-quarterary text-sm font-normal leading-tight">
                        Stok tersedia (kg)
                      </Typography>
                      <Typography className="text-sm text-text-primary font-bold leading-tight">
                        {typeof val.stock === "number"
                          ? val.stock.toLocaleString("id-ID")
                          : JSON.stringify(val.stock)}{" "}
                        kg
                      </Typography>
                    </div>
                  </>
                )}
              </CardBody>
            </Card>
          ))}
        </div>
      </Drawer>
    </>
  );
};

export default DrawerSapronak;
