import React from "react";

const KYC = () => {
  return (
    <div className="p-4">
      <h2 className="text-xl font-semibold mb-4">this is KYC</h2>
    </div>
  );
};

export default KYC;
