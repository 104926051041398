import { Card, Typography } from "@material-tailwind/react"

const TableDetailPanen = ({
  dataTableHarvest
}) => {
  const TABLE_HEAD = [
    "No",
    "Jumlah timbang",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Tonase"
  ];


  return (
    <div className="p-2">
      <Card className="h-full w-full overflow-scroll">
        <div className="w-full">
          <table className="w-full h-auto min-w-max table-auto text-left">
              <thead>
              <tr>
                {TABLE_HEAD.map((head) => (
                  <th
                    key={head}
                    className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4"
                  >
                    <Typography
                      variant="medium"
                      color="blue-gray"
                      className="flex justify-between font-bold leading-none opacity-70 text-[#101828]"
                    >
                      {head}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            
            <tbody>
             {dataTableHarvest?.map((val, index) => {
                const isLast = index === dataTableHarvest.length - 1;
                const classes = isLast
                ? "p-4"
                : "p-4 border-b border-blue-gray-50";

                return (
                  <tr key={index}>
                    <td className={classes}>
                      <div className="flex items-center gap-3">
                        <Typography className="text-neutral-900 text-sm font-medium leading-tight">
                          {index + 1}.
                        </Typography>
                      </div>
                    </td>

                    <td className={classes}>
                      <Typography className="text-neutral-900 text-sm font-medium leading-tight">
                        {val?.numberOfChicken?.toLocaleString('id-ID')} ekor
                      </Typography>
                    </td>
                    <td className={classes}/>
                    <td className={classes}/>
                    <td className={classes}/>
                    <td className={classes}/>
                    <td className={classes}/>
                    <td className={classes}/>
                    <td className={classes}/>
                    <td className={classes}/>
                    <td className={classes}>
                      <Typography className="text-neutral-900 text-sm font-medium leading-tight">
                        {val?.tonnage?.toLocaleString('id-ID')} kg
                      </Typography>
                    </td>
                  </tr>
                )
             })}
            </tbody>
          </table>
        </div>
      </Card>
    </div>
  )
}

export default TableDetailPanen