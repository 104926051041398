/* eslint-disable no-unused-vars */
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Typography,
} from "@material-tailwind/react";
import { FaCheckCircle } from "react-icons/fa";
import { ReactComponent as ChevronLeft } from "../../assets/icons/chevron-left.svg";
import CustomDatePicker from "../../atoms/DatepickerForm";
import DropdownSapronak from "../../atoms/DropdownSapronak";
// import Input from "../../atoms/Input";
import { Button } from "../../atoms/Button";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import api from "../../api/configApi";
import ConfirmDialog from "../../atoms/ConfirmDialog";
import moment from "moment";
import "moment/locale/id";
import InputNumber from "../../atoms/NumberFormat";
import AlertNotification from "../../atoms/AlertNotification";

const UpdateOVK = () => {
  const { id } = useParams();
  const [dateIn, setDateIn] = useState("");
  const [productName, setNameProduct] = useState("");
  const [productId, setProductId] = useState("");
  const [qtyIn, setQtyIn] = useState("");
  const [price, setPrice] = useState("");
  const [rearingPeriod, setRearingPeriod] = useState("");
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [options, setOptons] = useState(null);
  const [notifSuccess, setNotifSuccess] = useState(false);
  const [search, setSearch] = useState("");
  const [isEditError, setIsEditError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    const fetchCage = async () => {
      try {
        const response = await api.get(`/v1/ovks/in/${id}`);
        // console.log("response", response.data);

        setDateIn(response.data.dateIn);
        setNameProduct(response?.data?.ovk?.labels);
        setProductId(response?.data?.ovk?.id);
        setQtyIn(response?.data?.qtyIn);
        setPrice(response?.data?.price);
        setRearingPeriod(response?.data?.rearingPeriod?.id);
      } catch (error) {
        console.log(error);
      }
    };

    const fetchingDropdown = async () => {
      try {
        const responseFetchingDropdown = await api.get("/v1/ovks/dropdown", {
          params: {
            search: search,
          },
        });
        setOptons(responseFetchingDropdown.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchingDropdown();
    fetchCage();
  }, [id, search]);

  const handleAddEditOvk = async () => {
    const parseQtyIn = +qtyIn?.toString()?.replace(/\./g, "");
    const parsePrice = +price?.toString()?.replace(/\./g, "");

    let reqBody = {
      ovk: { id: productId },
      rearingPeriod: { id: rearingPeriod },
      dateIn: moment(dateIn).format("YYYY-MM-DD"),
      qtyIn: parseQtyIn,
      price: parsePrice,
    };

    try {
      const editFormOvk = await api.put(`/v1/ovks/in/${id}`, reqBody);
      setNotifSuccess(true);
      navigate(`/periode-budidaya/periode-berjalan/${rearingPeriod}`);
      localStorage.setItem(
        "successNotifUpdateOVK",
        JSON.stringify(!notifSuccess)
      );
      // console.log(editFormOvk);
      localStorage.setItem("settingType", "sapronak");
      localStorage.setItem("settingTypeSapronak", "ovk");
    } catch (error) {
      setOpenConfirmation(false);
      console.log(error);
      setIsEditError(true);
      setErrorMessage(
        error.response?.data?.error?.name || error.response?.data?.error
      );
      setTimeout(() => setIsEditError(false), 3000);
    }
  };

  const handleSelect = (event) => {
    setNameProduct(event);
  };

  const handleBack = () => {
    navigate(`/periode-budidaya/periode-berjalan/${rearingPeriod}/sapronak`);
    localStorage.setItem("settingType", "sapronak");
    localStorage.setItem("settingTypeSapronak", "ovk");
  };

  return (
    <div className="flex h-auto xl:overflow-hidden overflow-hidden">
      <div className="flex flex-col flex-1 overflow-hidden">
        <div className="p-4">
          <Card className="w-max-md flex justify-center item-centers border">
            <CardHeader
              floated={false}
              shadow={false}
              className="p-md border-none"
            >
              <div className="flex justify-start">
                <div className="mt-2 justify-start" onClick={handleBack}>
                  <ChevronLeft />
                </div>

                <Typography
                  variant="h2"
                  color="blue-gray"
                  className="text-xl font-semibold p-2"
                >
                  Ubah OVK
                </Typography>

                {/* <CustomDatePicker/> */}
              </div>
            </CardHeader>

            <CardBody className="p-4">
              <div className="p-5">
                <Typography
                  type="small"
                  className="text-gray-900 text-sm font-medium leading-tight"
                >
                  Tanggal Masuk
                </Typography>

                <CustomDatePicker
                  // value={dateIn}
                  placeholder={moment(dateIn).format("DD MMMM YYYY")}
                  onChange={(date) => setDateIn(date?.$d)}
                  format={"DD MMMM YYYY"}
                  className="w-full text-neutral-800 px-sm py-xs justify-start items-center gap-xxs inline-flex focus:outline-none focus:border-primary-400 focus:ring focus:ring-primary-200 mt-3"
                />
              </div>
              <hr className="border-t border-gray-300 w-full my-4" />

              <div className="mt-5 p-5">
                <div className="mx-auto w-full bg-[#EAECF0] rounded-sm">
                  <div className="p-4">
                    <div className="ml-4">
                      <Typography
                        type="small"
                        className="text-gray-900 text-sm font-medium leading-tight"
                      >
                        Produk
                      </Typography>

                      <div className="mt-2">
                        <DropdownSapronak
                          size="small"
                          onSelect={handleSelect}
                          placeholder={productName}
                          options={options?.contents}
                          setSearch={setSearch}
                          search={search}
                          disabled={true}
                        />

                        <div className="flex justify-between w-full mt-5 p-1 gap-4">
                          <div className="mt-3 w-[100%]">
                            <Typography
                              type="small"
                              className="text-gray-900 text-sm font-medium leading-tight"
                            >
                              Kuantitas
                            </Typography>

                            <InputNumber
                              // placeholder={qtyIn}
                              value={qtyIn}
                              size="small"
                              className="mt-3 w-[120px]"
                              onChange={(e) => setQtyIn(e.target.value)}
                              componentRight={
                                <Typography
                                  type="small"
                                  className="text-gray-500 mt-3 text-md font-medium leading-tight"
                                >
                                  Item
                                </Typography>
                              }
                            />
                          </div>

                          <div className="mt-3 w-[100%]">
                            <Typography
                              type="small"
                              className="text-gray-900 text-sm font-medium leading-tight"
                            >
                              Harga satuan
                            </Typography>

                            <InputNumber
                              size="small"
                              className="mt-3 w-[120px]"
                              value={price}
                              onChange={(e) => setPrice(e.target.value)}
                              componentLeft={
                                <Typography
                                  type="small"
                                  className="text-gray-500 mt-3 text-md font-medium leading-tight"
                                >
                                  Rp
                                </Typography>
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </CardBody>
            <hr className="border-t border-gray-300 w-full my-4" />

            <CardFooter className="flex justify-end border-t border-gray-50 gap-4 p-3 mr-4">
              <Button
                label="Batal"
                type="btnSecondary"
                size="medium"
                onClick={handleBack}
              />

              <Button
                label="Simpan"
                size="medium"
                onClick={() => setOpenConfirmation(!openConfirmation)}
              />
            </CardFooter>
          </Card>

          <ConfirmDialog
            title="Konfirmasi perubahan data"
            content="Apakah Anda yakin ingin mengubah data stok OVK?"
            isOpen={openConfirmation}
            btnLabel="Ya, Lanjutkan"
            onClose={() => setOpenConfirmation(!openConfirmation)}
            onConfirm={() => handleAddEditOvk()}
          />

          {/* Alert untuk notifikasi error */}
          {isEditError && (
            <AlertNotification
              icon={<FaCheckCircle />}
              color="red"
              message={errorMessage}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default UpdateOVK;
