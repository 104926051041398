/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState, useEffect } from "react";
import { FaChevronDown } from "react-icons/fa6";
import {
  Popover,
  PopoverContent,
  PopoverHandler,
} from "@material-tailwind/react";
import { MdOutlineDone } from "react-icons/md";

const DropdownFilterStatusMultiple = ({
  data,
  handleSelect,
  selectedStatus,
  popoverContentPosition,
  label,
  onReset,
  isOpenFilter,
  setIsOpenFilter
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const inputRef = useRef();

  const handleClickOutside = (event) => {
    if (inputRef.current && !inputRef.current.contains(event.target)) {
      setIsOpen(false);
      setIsOpenFilter && setIsOpenFilter(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);

  const handleItemSelect = (isActive) => {
    handleSelect(isActive);
    setIsOpen(false);
    setIsOpenFilter && setIsOpenFilter(false);
  };

  return (
    <Popover
      placement="bottom-start"
      open={isOpenFilter ? isOpenFilter : isOpen}
      handler={() => setIsOpenFilter ? setIsOpenFilter(!isOpenFilter) : setIsOpen(!isOpen)}
    >
      <PopoverHandler className="cursor-pointer min-w-[208px] w-full">
        <div>
          <button
            className={`${label ? "border-blue-600": "border-neutral-300"}  min-w-[160px] w-full p-5 h-11 px-xl py-2.5 justify-center items-center gap-md inline-flex text-base font-medium leading-normal rounded-md bg-white hover:bg-neutral-50 border text-neutral-700 focus:shadow-sm focus:shadow-gray-300 hover:border-neutral-300`}
          >
            <div
              className={`justify-center min-w-6 w-full items-center flex cursor-pointer ${
                label ? "text-blue-600" : "text-neutral-700"
              }`}
            >
              <div className="w-auto text-text-quarterary">
                {label ? "Status: "+label : "Semua status"}
              </div>
            </div>
            <div className="w-6 h-6 relative">
              <FaChevronDown
                className={`h-4 w-4 mt-[4px] ${
                  isOpen ? "transform rotate-180" : ""
                } ${ label ? "text-blue-600" : " text-gray-500"}`}
              />
            </div>
          </button>
        </div>
      </PopoverHandler>

      <PopoverContent className={`${popoverContentPosition} z-50 p-0`}>
        <div className="flex items-start w-full min-w-[170px] gap-y-4 cursor-pointer">
          <div className="py-0">
            {data?.map((options, index) => (
              <div
                key={index}
                className="flex cursor-pointer hover:bg-gray-100 w-[210px] my-1"
                onClick={() => handleItemSelect(options.value)}
              >
                <div
                  className="flex relative z-30 items-center px-4 py-2 text-base text-neutral-700 w-full justify-between"
                >
                  {options.labels[0]}
                </div>
                <div className="items-center flex justify-end p-2">
                  {selectedStatus === options.value && (
                    <MdOutlineDone className="w-5 h-5 text-green-500" />
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="flex w-full justify-start border-t px-4 py-3">
          <button
            className={`w-full p-0 h-6 justify-start items-center inline-flex text-base font-medium leading-normal rounded-md bg-white border-none text-neutral-700 shadow-none`}
          >
            <div
              className={`justify-start min-w-6 w-full text-sm items-center flex cursor-pointer text-blue-600 hover:text-blue-700`}
              onClick={onReset}
            >
              Atur ulang
            </div>
          </button>
        </div>
      </PopoverContent>
    </Popover>
  );
};

export default DropdownFilterStatusMultiple;
