const getStrengthBarColor = (strength) => {
  switch (strength) {
    case 1:
      return [
        "bg-red-500",
        "bg-red-500",
        "bg-gray-300",
        "bg-gray-300",
        "bg-gray-300",
        "bg-gray-300",
      ];
    case 2:
      return [
        "bg-amber-500",
        "bg-amber-500",
        "bg-amber-500",
        "bg-amber-500",
        "bg-gray-300",
        "bg-gray-300",
      ];
    case 3:
      return [
        "bg-green-500",
        "bg-green-500",
        "bg-green-500",
        "bg-green-500",
        "bg-green-500",
        "bg-green-500",
      ];
    default:
      return [
        "bg-gray-300",
        "bg-gray-300",
        "bg-gray-300",
        "bg-gray-300",
        "bg-gray-300",
        "bg-gray-300",
      ];
  }
};

const PasswordStrengthBar = ({ passwordStrength, width }) => {
  return (
    <div className="grid grid-cols-6 gap-2">
      {getStrengthBarColor(passwordStrength).map((colorClass, index) => (
        <div key={index} className={`${width} h-1 rounded ${colorClass}`} />
      ))}
    </div>
  );
};

export default PasswordStrengthBar;
