import React, { useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Typography,
  Radio,
} from "@material-tailwind/react";
import Input from "../../../atoms/Input";
import {
  ChevronUpDownIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import { Button } from "../../../atoms/Button";
import { useState } from "react";
import api from "../../../api/configApi";
import { Link } from "react-router-dom";
import AlertNotification from "../../../atoms/AlertNotification";
import { FaCheckCircle } from "react-icons/fa";
import { ReactComponent as EmptyIcon } from "../../../assets/icons/emptyIconn.svg";
import LoadingAnimation from "../../../atoms/LoadingAnimation";
import * as Sentry from "@sentry/react";
import { ReactComponent as Download } from "../../../assets/icons/download_icon_black.svg";
import { useDebounce } from "use-debounce";
import { CheckboxCust } from "../../../atoms/Checkbox";
import ConfirmDialog from "../../../atoms/ConfirmDialog";
import LoadingAnimationRounded from "../../../atoms/LoadingAnimationRounded";
import Loading from "../../../atoms/Loading";
import TableCellItem from "../../../atoms/Table/TableCellItem";
import TableHeaderItem from "../../../atoms/Table/TableHeaderItem";
import Table from "../../../atoms/Table/Table";
import TableFooterItem from "../../../atoms/Table/TableFooterItem";

const LiveStockManager = () => {
  const [sortBy, setSortBy] = useState("unitName");
  const [descending, setDescending] = useState(true);
  const [search, setSearch] = useState("");
  const [debouncedSearch] = useDebounce(search, 500);
  const [isLoading, setIsLoading] = useState(true);
  const [isFetchError, setIsFetchError] = useState(false);
  const [isLoadingDownload, setIsLoadingDownload] = useState(false);
  const [isDownloadSuccess, setIsDownloadSuccess] = useState(false);
  const [isDownloadError, setIsDownloadError] = useState(false);
  const [livestocks, setLivestocks] = useState(null);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [modalConfirmation, setModalconfirmation] = useState(false);
  const [downloadType, setDownloadType] = useState("all_data");
  const [totalLivestockByCategory, setTotalLivestockByCategory] =
    useState(null);

  const handleCheckAllChange = () => {
    setSelectAll(!selectAll);
    setSelectedRows(selectAll ? [] : livestocks.map((row) => row.id));
  };

  useEffect(() => {
    if (selectedRows.length === 0) {
      setDownloadType("all_data");
    } else {
      setDownloadType("selected_data");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalConfirmation]);

  useEffect(() => {
    const fetchingList = async () => {
      setIsLoading(true);
      try {
        const params = {
          sortBy: sortBy,
          descending: descending,
          search: debouncedSearch,
        };

        const response = await api.get(
          "/v1/livestock/withTotalWeightByCategory",
          { params }
        );

        setLivestocks(response?.data?.liveStocks);
        setTotalLivestockByCategory(response?.data?.totalWeightCategory);
        setIsFetchError(false);
      } catch (error) {
        Sentry.captureException(error);
        setIsFetchError(true);
      } finally {
        setIsLoading(false);
      }
    };

    if (debouncedSearch || !debouncedSearch) {
      fetchingList();
    }
  }, [debouncedSearch, sortBy, descending]);

  const handleSort = (column) => {
    if (sortBy === column) {
      setDescending(!descending);
    } else {
      setSortBy(column);
      setDescending(false);
    }
  };

  const reqDownload = async (params) => {
    setIsLoadingDownload(true);
    try {
      // Check if ids are provided in the params, and format the query string
      let ids = params.ids ? "id=" + params.ids : "";

      const response = await api.get(`/v1/livestock/toXLSX?` + ids, {
        responseType: "blob",
      });

      const contentDisposition = response.headers["content-disposition"];
      let fileName = "livebird_region.pdf";

      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename="?([^"]+)"?/);
        if (fileNameMatch && fileNameMatch[1]) {
          fileName = fileNameMatch[1];
        }
      }

      const url = window.URL.createObjectURL(new Blob([response.data]));

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(url);

      setIsDownloadSuccess(true);
      setTimeout(() => setIsDownloadSuccess(false), 3000);
    } catch (error) {
      setModalconfirmation(false);
      setIsDownloadError(true);
      setTimeout(() => setIsDownloadError(false), 3000);
      // setMessageError("Tidak ada data yang bisa di unduh");
      console.log(error);
    } finally {
      setIsLoadingDownload(false);
    }
  };

  const handleDownloadReport = async () => {
    try {
      const params = {};

      if (modalConfirmation) {
        if (downloadType === "selected_data") {
          params.ids = selectedRows.map((row) => row);
          reqDownload(params);
        } else {
          reqDownload(params);
        }
      }

      setSelectedRows([]);
      setSelectAll(false);
      setModalconfirmation(false);
    } catch (error) {
      setModalconfirmation(false);
      console.log(error);
    }
  };

  const handleOpenModalConfirmationDownload = () => {
    setModalconfirmation(true);
  };

  const handleCheckboxChange = (id) => {
    setSelectedRows((prevSelectedRows) => {
      if (prevSelectedRows.includes(id)) {
        return prevSelectedRows.filter((rowID) => rowID !== id);
      } else {
        return [...prevSelectedRows, id];
      }
    });

    // Ensure selectAll is toggled off when a single checkbox changes
    setSelectAll(false);
  };

  const handleDownloadTypeChange = (event) => {
    setDownloadType(event.target.value);
  };

  const columns = [
    {
      accessorKey: "unitName",
      header: ({ column }) => {
        return (
          <div className="flex items-center w-full gap-2">
            <CheckboxCust
              className="checked:bg-primary-500"
              onChange={handleCheckAllChange}
              checked={selectAll}
            />
            <div className="flex w-full justify-between gap-4">
              <Typography className="flex justify-start font-semibold leading-tight text-text-primary text-sm">
                Unit
              </Typography>
              <button
                className="focus:outline-none "
                onClick={() => handleSort("unitName")}
              >
                <ChevronUpDownIcon
                  strokeWidth={2}
                  className={`h-4 w-4 ${
                    descending ? "transform rotate-180" : ""
                  }`}
                />
              </button>
            </div>
          </div>
        );
      },
      cell: ({ row }) => {
        const unitName = row.original.unitName;
        const id = row.original.id;
        return (
          <div className="flex items-center gap-2 ">
            <CheckboxCust
              className="checked:bg-primary-500"
              onChange={() => handleCheckboxChange(id)}
              checked={selectedRows?.includes(id)}
            />
            <Link to={`/stok/livebird/${id}`} className="w-[200px]">
              <Typography className="text-text-infoPrimary text-sm font-medium leading-tight">
                {unitName}
              </Typography>
            </Link>
          </div>
        );
      },
      footer: () => {
        return (
          <TableCellItem
            value="Total"
            isNumber={false}
            customClass="!font-bold ml-[52px]"
          />
        );
      },
    },
    {
      accessorKey: "a",
      header: ({ column }) => {
        const value = column.id;
        return (
          <TableHeaderItem
            title="< 0,8"
            value={value}
            handleSort={handleSort}
            isDescending={descending}
          />
        );
      },
      cell: ({ row }) => {
        const value = row.original.a;
        return <TableCellItem value={value} />;
      },
      footer: () => {
        const value = totalLivestockByCategory?.totalA || 0;

        return <TableFooterItem value={value} />;
      },
    },
    {
      accessorKey: "b",
      header: ({ column }) => {
        const value = column.id;
        return (
          <TableHeaderItem
            title="0,81-1,00"
            value={value}
            handleSort={handleSort}
            isDescending={descending}
          />
        );
      },
      cell: ({ row }) => {
        const value = row.original.b;
        return <TableCellItem value={value} />;
      },
      footer: () => {
        const value = totalLivestockByCategory?.totalB;

        return <TableFooterItem value={value} />;
      },
    },
    {
      accessorKey: "c",
      header: ({ column }) => {
        const value = column.id;
        return (
          <TableHeaderItem
            title="1,01-1,20"
            value={value}
            handleSort={handleSort}
            isDescending={descending}
          />
        );
      },
      cell: ({ row }) => {
        const value = row.original.c;
        return <TableCellItem value={value} />;
      },
      footer: () => {
        const value = totalLivestockByCategory?.totalC;

        return <TableFooterItem value={value} />;
      },
    },
    {
      accessorKey: "d",
      header: ({ column }) => {
        const value = column.id;
        return (
          <TableHeaderItem
            title="1,21-1,40"
            value={value}
            handleSort={handleSort}
            isDescending={descending}
          />
        );
      },
      cell: ({ row }) => {
        const value = row.original.d;
        return <TableCellItem value={value} />;
      },
      footer: () => {
        const value = totalLivestockByCategory?.totalD;

        return <TableFooterItem value={value} />;
      },
    },
    {
      accessorKey: "e",
      header: ({ column }) => {
        const value = column.id;
        return (
          <TableHeaderItem
            title="1,41-1,60"
            value={value}
            handleSort={handleSort}
            isDescending={descending}
          />
        );
      },
      cell: ({ row }) => {
        const value = row.original.e;
        return <TableCellItem value={value} />;
      },
      footer: () => {
        const value = totalLivestockByCategory?.totalE;

        return <TableFooterItem value={value} />;
      },
    },
    {
      accessorKey: "f",
      header: ({ column }) => {
        const value = column.id;
        return (
          <TableHeaderItem
            title="1,61-1,80"
            value={value}
            handleSort={handleSort}
            isDescending={descending}
          />
        );
      },
      cell: ({ row }) => {
        const value = row.original.f;
        return <TableCellItem value={value} />;
      },
      footer: () => {
        const value = totalLivestockByCategory?.totalF;

        return <TableFooterItem value={value} />;
      },
    },
    {
      accessorKey: "g",
      header: ({ column }) => {
        const value = column.id;
        return (
          <TableHeaderItem
            title="1,81-2,00"
            value={value}
            handleSort={handleSort}
            isDescending={descending}
          />
        );
      },
      cell: ({ row }) => {
        const value = row.original.g;
        return <TableCellItem value={value} />;
      },
      footer: () => {
        const value = totalLivestockByCategory?.totalG;

        return <TableFooterItem value={value} />;
      },
    },
    {
      accessorKey: "h",
      header: ({ column }) => {
        const value = column.id;
        return (
          <TableHeaderItem
            title="2,01-2,30"
            value={value}
            handleSort={handleSort}
            isDescending={descending}
          />
        );
      },
      cell: ({ row }) => {
        const value = row.original.h;
        return <TableCellItem value={value} />;
      },
      footer: () => {
        const value = totalLivestockByCategory?.totalH;

        return <TableFooterItem value={value} />;
      },
    },
    {
      accessorKey: "i",
      header: ({ column }) => {
        const value = column.id;
        return (
          <TableHeaderItem
            title="2,31-2,50"
            value={value}
            handleSort={handleSort}
            isDescending={descending}
          />
        );
      },
      cell: ({ row }) => {
        const value = row.original.i;
        return <TableCellItem value={value} />;
      },
      footer: () => {
        const value = totalLivestockByCategory?.totalI;

        return <TableFooterItem value={value} />;
      },
    },
    {
      accessorKey: "j",
      header: ({ column }) => {
        const value = column.id;
        return (
          <TableHeaderItem
            title="> 2,50"
            value={value}
            handleSort={handleSort}
            isDescending={descending}
          />
        );
      },
      cell: ({ row }) => {
        const value = row.original.j;
        return <TableCellItem value={value} />;
      },
      footer: () => {
        const value = totalLivestockByCategory?.totalJ;

        return <TableFooterItem value={value} />;
      },
    },
    {
      accessorKey: "totalWeightRange",
      header: ({ column }) => {
        const value = column.id;
        return (
          <TableHeaderItem
            title="Total"
            value={value}
            handleSort={handleSort}
            isDescending={descending}
          />
        );
      },
      cell: ({ row }) => {
        const value = row.original.totalWeightRange;
        return <TableCellItem value={value} customClass="font-bold" />;
      },
      footer: () => {
        const value = totalLivestockByCategory?.totalTotalWeightRange;

        return <TableFooterItem value={value} />;
      },
    },
  ];

  const pinningConfigs = [{ column: "unitName", direction: "left" }];

  return (
    <>
      <div className={`flex w-full h-auto overflow-hidden z-0 `}>
        <div className="flex flex-col flex-1 overflow-hidden">
          <div className="p-4">
            <Card className="w-full h-auto border border-border-secondary shadow-none p-0 m-0">
              <CardHeader
                floated={false}
                shadow={false}
                className="p-2 m-0 border-none w-full"
              >
                <Typography
                  variant="h2"
                  className="text-xl font-bold p-xl text-text-primary"
                >
                  Stok Livebird
                </Typography>

                <div className="grid grid-cols-2">
                  <div className="flex justify-start mb-4 gap-2">
                    <div className="text-left pl-4">
                      <Input
                        type="text"
                        id="search"
                        placeholder="Cari nama unit"
                        className="min-w-[350px] border border-border-primary"
                        size="medium"
                        componentLeft={
                          <MagnifyingGlassIcon className="h-5 w-5" />
                        }
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="text-center flex justify-end mr-[30px] gap-4">
                    <Button
                      label="Unduh"
                      size="medium"
                      type={
                        isLoadingDownload
                          ? "btnPrimaryDisabled"
                          : "btnSecondary"
                      }
                      componentLeft={
                        isLoadingDownload ? (
                          <LoadingAnimationRounded />
                        ) : (
                          <Download />
                        )
                      }
                      onClick={handleOpenModalConfirmationDownload}
                      disabled={isLoadingDownload}
                    />
                  </div>
                </div>
              </CardHeader>

              {livestocks?.length === 0 || isFetchError ? (
                <div className=" flex justify-center items-center h-screen">
                  <div>
                    <div className="flex justify-center">
                      <EmptyIcon className="ml-[30px]" />
                    </div>
                    <Typography
                      variant="h2"
                      className="text-xl font-semibold p-4 text-center text-neutral-500"
                    >
                      Data tidak ditemukan
                    </Typography>

                    <Typography
                      variant="medium"
                      className="flex justify-between font-normal leading-none text-neutral-500 w-full min-h-full"
                    >
                      Data yang Anda cari tidak ditemukan. Coba kata kunci lain
                    </Typography>
                  </div>
                </div>
              ) : (
                <>
                  <CardBody className="p-0 overflow-scroll max-w-full max-h-[960px] z-10 scrollbar-hide">
                    {isLoading ? (
                      <div className="flex justify-center items-center h-screen">
                        <LoadingAnimation />
                      </div>
                    ) : (
                      <>
                        <Table
                          data={livestocks}
                          columns={columns}
                          pinningConfigs={pinningConfigs}
                        />
                      </>
                    )}
                  </CardBody>
                </>
              )}
            </Card>
          </div>
        </div>
      </div>
      <ConfirmDialog
        isOpen={modalConfirmation}
        onClose={() => setModalconfirmation(!modalConfirmation)}
        classNameHeader={"px-3xl"}
        content={
          <div>
            <Typography className="px-md text-text-tertiary">
              Pilih kategori data yang ingin di unduh
            </Typography>
            <div className="grid grid-rows-2 mt-xl">
              <Radio
                name="downloadType"
                className="p-0"
                label={
                  <Typography className="hover:text-text-primary text-text-secondary font-medium transition-colors text-sm">
                    Semua data di halaman ini ({livestocks?.length})
                  </Typography>
                }
                color="amber"
                value="all_data"
                checked={downloadType === "all_data"}
                onChange={handleDownloadTypeChange}
              />
              <Radio
                name="downloadType"
                disabled={selectedRows.length === 0 ? true : false}
                label={
                  <Typography className="hover:text-text-primary text-text-secondary font-medium transition-colors text-sm">
                    Data yang telah dipilih ({selectedRows?.length})
                  </Typography>
                }
                color="amber"
                value="selected_data"
                checked={downloadType === "selected_data"}
                onChange={handleDownloadTypeChange}
              />
            </div>
          </div>
        }
        title={"Unduh data file ke XLS"}
        btnLabel="Lanjutkan"
        onConfirm={handleDownloadReport}
        classNameContent={"text-md"}
      />

      {isLoadingDownload && (
        <AlertNotification
          icon={<Loading />}
          color="black"
          message="Sedang mengunduh data stok"
        />
      )}

      {isDownloadSuccess && (
        <AlertNotification
          icon={<FaCheckCircle />}
          color="green"
          message="Berhasil unduh data stok"
        />
      )}

      {isDownloadError && (
        <AlertNotification
          icon={<FaCheckCircle />}
          color="red"
          message="Tidak bisa download data stok"
        />
      )}
    </>
  );
};

export default LiveStockManager;
