/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect} from "react";
import { Card, Typography } from "@material-tailwind/react";
import { Button } from "../../../atoms/Button";
import { useTimer } from "react-timer-hook";
import api from "../../../api/configApiAuth";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { VerifyOtp, reset } from "../../../molecules/features/authSlice";
import { ReactComponent as OTPIcon } from '../../../assets/icons/otp-icon.svg'
import { ArrowLeftIcon } from "@heroicons/react/24/outline";

function FormVerifOTP({ type, otpSession, receiverOTP, onBack, isNumber, isNext }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [otp, setOTP] = useState("");
  const [isVerificationError, setIsVerificationError] = useState(false);
  const inputRefs = [];
  const [isResendDisabled, setIsResendDisabled] = useState(true);
  const expiryTimestamp = new Date();
  expiryTimestamp.setSeconds(expiryTimestamp.getSeconds() + 25)
  const [sensorEmailValue, setSensorEmailValue] = useState("");
  const [showNotifNotSend, setShowNotifNotSend] = useState(false)
  const [showtimer, setShowTimer] = useState(true)
  const [disableAfterTimes, setDisabledAfterTimes] = useState(0)
  const dataUsername = JSON.parse(localStorage.getItem("userNameFromButtom")) || {};
  const responseSendOtp = JSON.parse(localStorage.getItem("responseOTPSession")) || {};
  const dataResponseReqOtp = JSON.parse(localStorage.getItem("dataConfirmationOTP")) || {}
  const valueVerifOtp = JSON.parse(localStorage.getItem("dataConfirmationOTP")) || {}


  const { user, isVerifyOtpSuccess } = useSelector(
    (state) => state.auth
  );

  // Fungsi untuk menyensor karakter di tengah
  const sensorMiddleCharacters = (text, start, end) => {
    const chars = text.split("");
    for (let i = start; i < chars.length - end; i++) {
      chars[i] = "*";
    }
    return chars.join("");
  };
  useEffect(() => {
    if (type === "Email") {
      const sensorEmail = (receiverOTP) => {
        const emailParts = receiverOTP.split("@");
        const username = emailParts[0];
        const domain = emailParts[1];

        // Sensor huruf di awal dan di tengah
        const sensorUsername = sensorMiddleCharacters(username, 2, 3);

        // Sensor huruf di akhir
        const sensorDomain = sensorMiddleCharacters(domain, 0, 7);

        return `${sensorUsername}@${sensorDomain}`;
      };

      sensorEmail(receiverOTP);
      setSensorEmailValue(sensorEmail(receiverOTP));
    }
  }, [type, receiverOTP]);

  const { seconds, minutes, isRunning, restart } = useTimer({
    expiryTimestamp,
    onExpire: () => {
      setShowTimer(false)
      setIsResendDisabled(true);
      setShowNotifNotSend(true)
    },
  });

  const handleChange = (e, index) => {
    const value = e.target.value;

    // Handle manual input (allow only one character)
    if (!isNaN(value) && value.length <= 1) {
      const newOtp = otp.slice(0, index) + value + otp.slice(index + 1);
      setOTP(newOtp);
      setIsVerificationError(false);
  
      // Move focus to the next input if value is entered
      if (value !== "" && index < 5) {
        inputRefs[index + 1].focus();
      }
  
      // Move focus to the previous input column if the value is deleted
      if (value === "" && index > 0) {
        inputRefs[index - 1].focus();
      }
    } else if (!isNaN(value) && value.length === 6) {
      // Handle copy-paste of the full OTP
      setOTP(value.slice(0, 6)); // Set the OTP string directly
      setIsVerificationError(false);
    }
  };

  useEffect(() => {
    const dataFetchOtpResponse = async () => {
      const reqBody = {
        username: dataUsername,
        otpSession: responseSendOtp,
        otp,
      };

      setDisabledAfterTimes((value) => value + 1)
  
      try {
        const response = await api.post('/v1/auth/verifyOtp', reqBody);
        localStorage.setItem("verifyOTP", JSON.stringify(response))
        isNext()
      } catch (error) {
        console.log("error", error);
        setIsVerificationError(true)

        if (disableAfterTimes > 3) {
          // setShowFreezeOtp(true)
          setShowNotifNotSend(false)
          setShowTimer(false)
          setIsVerificationError(false)
        }
      }
    };

  
    if (otp.length === 6) {
      dataFetchOtpResponse();
    }
  }, [otp, dataUsername, responseSendOtp]);

  // const handleResend = () => {
  //   if (type === "Email") {
  //     const fetchData = async () => {
  //       try {
  //         await api.post("v1/auth/sendOtp", {
  //           username: receiverOTP,
  //           otpSession: otpSession,
  //           destination: "EMAIL",
  //         });
  //         setIsResendDisabled(true);
  //         restart(expiryTimestamp);
  //         console.log("Resend OTP berhasil");
  //       } catch (error) {
  //         setIsResendDisabled(true);
  //         restart(expiryTimestamp);
  //         console.log(`Resend OTP gagal. Error: ${error.message}`);
  //       }
  //     };
  //     fetchData();
  //   }
  // };

 

  useEffect(() => {
    const handleVerificationOTP = () => {
      const isOTPComplete = otp.length === 6; // Check if OTP is complete (6 digits)
      const otpString = otp;  // Directly use the OTP string
  
      if (isOTPComplete) {
        dispatch(VerifyOtp({ receiverOTP, otpSession, otpString }));
  
        if (user || isVerifyOtpSuccess) {
          navigate("/dashboard");
        }
      }
      dispatch(reset());
    };
  
    handleVerificationOTP();
  }, [
    dispatch,
    navigate,
    otp,
    receiverOTP,
    otpSession,
    user,
    isVerifyOtpSuccess,
  ]);

  const handelResendOtp = async () => {
    const reqBody = {
      username: dataUsername ,
      otpSession: responseSendOtp,
      destination: valueVerifOtp.destination
    };

    try {

      const response = await api.post('/v1/auth/sendOtp', reqBody);
      const newExpiryTimeStamp = new Date()
      newExpiryTimeStamp.setSeconds(newExpiryTimeStamp.getSeconds() + 25)
      restart(newExpiryTimeStamp)
      setDisabledAfterTimes((value) => value + 3)

      setShowTimer(true);
      setShowNotifNotSend(false);
      setIsResendDisabled(true);
      return response
    } catch (error) {
      console.log("error", error);

      if (disableAfterTimes > 3) {
        // setShowFreezeOtp(true)
        setShowNotifNotSend(false)
        setShowTimer(false)
        setIsVerificationError(false)
      }
    }

    setShowTimer(true)
    setShowNotifNotSend(false)
  }



  useEffect(() => {
    if (!isRunning) {
      setIsResendDisabled(true);
    }

  }, [isRunning]);

  // useEffect(() => {
  //   const handleVerificationOTP = () => {
  //     const isOTPComplete = otp.every((digit) => digit !== "");
  //     const otpString = otp.join("");
  //     if (isOTPComplete) {
  //       setIsOtpVerify(true);
  //       dispatch(VerifyOtp({ receiverOTP, otpSession, otpString }));
  //     }
  //     dispatch(reset());
  //   };

  //   handleVerificationOTP();
  // }, [dispatch, otp, receiverOTP, otpSession]);

  // useEffect(() => {
  //   console.log("isLoading: " + isLoading);
  //   if (isLoading === false) {
  //     if (isOtpVerify) {
  //       console.log("dalam: " + isVerifyOtpSuccess);
  //       if (isVerifyOtpSuccess) {
  //         console.log("Verifikasi OTP berhasil");
  //         navigate("/dashboard");
  //       } else {
  //         setIsVerificationError(true);
  //         setIsOtpVerify(false);
  //       }
  //     }
  //   }
  // }, [navigate, isLoading, isVerifyOtpSuccess, isOtpVerify]);
  // console.log("luar: "+ isVerifyOtpSuccess);

  return (
    <div className="flex justify-center items-center min-h-[700px]">
      <Card className="w-[484px] h-[460px] max-w-[484px] md:min-w-sm shadow-none md:shadow-md md:border-t-2 md:border-gray-100 rounded-xl md:p-4xl p-xs opacity-100">
        <div
          className="items-start cursor-pointer"
          onClick={onBack}
        >
          <ArrowLeftIcon strokeWidth={2} className="h-4 w-4" />
        </div>
        <div className="w-[420px] h-[72px] md:min-w-sm flex-col justify-center items-center inline-flex mt-[60px]">
          <div
            className="items-start cursor-pointer"
          >
            <OTPIcon />
          </div>

          <Typography
            type="h2"
            className="text-lg font-bold text-center text-neutral-900"
          >
            Masukkan kode verifikasi
          </Typography>
          <div className="w-[80%] text-center">
            <Typography
              type="small"
              className="font-normal text-sm text-neutral-500 text-center"
            >
              Kode verifikasi telah dikirim melalui{" "}
              <span className="text-neutral-900 font-bold">
                {dataResponseReqOtp.destination === "WHATSAPP" ? "Whatsapp" : "Email"}
              </span>{" "}
              <span className="text-sm text-neutral-500 font-semibold">
                {type}
              </span>{" "}
              ke{" "}
              <span className="text-sm text-neutral-500 font-semibold">
                {type === "Email"
                  ? sensorEmailValue
                  : "*******" + receiverOTP.slice(-4)}
              </span>
            </Typography>
          </div>
        </div>

        <div className="w-[420px] h-[114px] md:min-w-sm flex-col justify-start items-center gap-5 inline-flex mt-[90px]">
          <div className="flex justify-center items-center space-x-2">
            {[...Array(6)].map((_, index) => (
              <input
                key={index}
                type="text"
                className={`w-12 h-12 text-center rounded-md border ${
                  isVerificationError ? "border-red-600" : "border-gray-300"
                } focus:outline-none focus:border-yellow-400 focus:ring-yellow-400 focus:shadow-sm focus:shadow-yellow-400`}
                value={otp[index] || ""} // Ensure the value is always a string, default to empty string
                onChange={(e) => handleChange(e, index)}
                ref={(input) => (inputRefs[index] = input)}
              />
            ))}
          </div>

          <div className="flex flex-col">
            {/* error message */}
            {isVerificationError && (
              <p className="text-red-500 text-sm text-center">
                Masukkan kode yang benar
              </p>
            )}
           
            {showtimer && (
              <div className="flex items-center justify-center text-center text-xs font-medium space-x-1 text-gray-500">
                <p>Mohon tunggu dalam</p>
                <div className="text-center text-slate-600 text-xs font-bold font-['Inter'] leading-[18px]">
                  {minutes}:{seconds < 10 ? "0" + seconds : seconds}
                  {/* {seconds} */}
                </div>
                <p>untuk kirim ulang</p>
              </div>
            )}

            {/* {showFreezeOtp && (
              <div className="flex items-center justify-center text-center text-xs font-medium space-x-1 text-gray-500">
                <p>Tunggu beberapa saat kemudian coba lagi </p>
              </div>
            )} */}

            { isVerificationError && (
              <>
                <div className="mt-3">
                  <Typography
                    type="small"
                    className="font-normal text-sm text-neutral-500 text-center"
                  >
                    Belum menerima kode?
                  </Typography>
                </div>

                <div className="flex items-center justify-center text-center text-sm font-medium space-x-1">
                  <Button
                    type={isResendDisabled ? "btnLinkDisabled" : "btnLinkBlue"}
                    // onClick={handleResend}
                    onClick={handelResendOtp}
                    disabled={isResendDisabled}
                    label="Kirim Ulang"
                    size="xsmall"
                  />

                  <p>atau</p>

                  <Button
                    type={isResendDisabled ? "btnLinkDisabled" : "btnLinkBlue"}
                    onClick={onBack}
                    disabled={isResendDisabled}
                    label="Gunakan metode lain"
                    size="xsmall"
                  />
                </div>
              </>
            )}

          </div>
        </div>
      </Card>
    </div>
  );
}

export default FormVerifOTP;
