import React, { createContext, useContext, useEffect, useState } from "react";

import { zodResolver } from "@hookform/resolvers/zod";
import { format } from "date-fns";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { ROUTES_PATH } from "../../../../../shared/routes";
import { useGetHousesList } from "../../../../../useCases/Houses";
import { useMutateMasterPeriod } from "../../../../../useCases/MasterPeriod";
import { useGetSequence } from "../../../../../useCases/RearingPeriod";
import { schema } from "../config";

const MasterPeriodeTambah = createContext(undefined);

export const MasterPeriodeTambahStore = ({ children }) => {
  const navigate = useNavigate();

  const { user } = useSelector((state) => state.auth);

  const [selectedPPL, setSelectedPPL] = useState(null);
  const [selectedOwner, setSelectedOwner] = useState(null);
  const [isCreateAnother, setIsCreateAnother] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const form = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      kandang: "",
      periode: 1,
      ppl: "",
      differentFcrHistory1: 0,
      differentFcrHistory2: 0,
      differentFcrHistory3: 0,
      performanceIndexHistory1: 0,
      performanceIndexHistory2: 0,
      performanceIndexHistory3: 0,
    },
  });

  const queryHouses = useGetHousesList({
    unit: user?.unit,
  });
  const querySequence = useGetSequence(selectedOwner);
  const mutateAdd = useMutateMasterPeriod();

  const sequenceValue = form.watch("sequence");

  useEffect(() => {
    if (querySequence.data && sequenceValue) {
      const sequence = querySequence.data.registeredSequences;
      const code = querySequence.data.code;

      form.setValue("idPeriode", `${code}${sequenceValue}`);

      if (sequence.includes(sequenceValue)) {
        form.setError("sequence", {
          type: "manual",
          message: "Periode sudah terdaftar",
        });
      } else {
        form.clearErrors("sequence");
      }
    }
  }, [querySequence.data, sequenceValue, form]);

  useEffect(() => {
    if (querySequence.data?.nextSequence) {
      const lastSequence = querySequence.data.nextSequence;
      form.setValue("sequence", lastSequence);
    }
  }, [querySequence.data, form]);

  const handleOnSubmit = () => {
    setIsDialogOpen(true);
  };

  const handleMutate = () => {
    const values = form.getValues();
    const payload = {
      house: {
        id: values.house,
      },
      ppl: {
        id: selectedPPL || "",
      },
      docInDate: format(values.docInDate, "yyyy-MM-dd"),
      sequence: values.sequence,
      docInPopulation: values.docInPopulation,
      guaranteeType: values.guaranteeType,
      guaranteeAmount: values.guaranteeAmount,
      differentFcrHistory1: values.differentFcrHistory1,
      differentFcrHistory2: values.differentFcrHistory2,
      differentFcrHistory3: values.differentFcrHistory3,
      performanceIndexHistory1: values.performanceIndexHistory1,
      performanceIndexHistory2: values.performanceIndexHistory2,
      performanceIndexHistory3: values.performanceIndexHistory3,
    };

    mutateAdd.mutate(payload, {
      onSuccess: () => {
        if (isCreateAnother) {
          form.reset();
          setIsDialogOpen(false);
          setSelectedPPL(null);
          setSelectedOwner(null);
          form.setValue("sequence", "");
          form.setValue("idPeriode", "");
        } else {
          navigate(ROUTES_PATH.masterPeriode);
        }
      },
    });
  };

  return (
    <MasterPeriodeTambah.Provider
      value={{
        form,
        mutateAdd,
        queryHouses,
        querySequence,
        handleOnSubmit,
        handleMutate,
        selectedPPL,
        setSelectedPPL,
        selectedOwner,
        setSelectedOwner,
        isCreateAnother,
        setIsCreateAnother,
        isDialogOpen,
        setIsDialogOpen,
      }}
    >
      {children}
    </MasterPeriodeTambah.Provider>
  );
};

export const useMasterPeriodeTambah = () => {
  const context = useContext(MasterPeriodeTambah);

  if (context === undefined) {
    throw new Error(
      "useMasterPeriodeTambah must be used within a MasterPeriodeTambahStore",
    );
  }

  return context;
};
