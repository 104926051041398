/* eslint-disable no-unused-vars */

import React, { useState, useEffect, useRef } from "react";
import {
  Dialog,
  IconButton,
  Typography,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Stepper,
  Step,
  Radio,
  DialogBody,
} from "@material-tailwind/react";
import { Button } from "../../atoms/Button";
import Input from "../../atoms/Input";
import InputNumber from "../../atoms/NumberFormat";
import useFetchImage from "../tablePanen/HookFetchingPanen";
import api from "../../api/configApi";
import ConfirmDialog from "../../atoms/ConfirmDialog";

import { FiZoomIn } from "react-icons/fi";
import { FiZoomOut } from "react-icons/fi";
import { FiDownload } from "react-icons/fi";
import { FiRotateCw } from "react-icons/fi";

import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

const ModalInputPanen = ({
  open,
  setOpen,
  onReloadData,
  harvestId,
  detailId,
  url,
  setUrl,
}) => {
  const [activeStep, setActiveStep] = useState(1);
  const [isLastStep, setIsLastStep] = useState(false);
  const [isFirstStep, setIsFirstStep] = useState(false);
  const [panenType, setPanenType] = useState("");
  const [chickenCondition, setChickenCondition] = useState("");
  const { data: imgNota } = useFetchImage(url);
  const [step, setStep] = useState(2);
  const [harvestQty, setHarvestQty] = useState(null);
  const [tonase, setTonase] = useState(0);
  const [bw, setBw] = useState(0);
  const [marketPrice, setMarketPrice] = useState(0);
  const [contractPrice, setContractPrice] = useState(0);
  const [nomorDo, setNomorDo] = useState("");
  const [platMobil, setPlatMobil] = useState("");
  const [isNomorDoEmpty, setIsNomorDoEmpty] = useState(false);
  const [isPlatMobilEmpty, setIsPlatMobilEmpty] = useState(false);
  const [isPanenTypeEmpty, setIsPanenTypeEmpty] = useState(false);
  const [isChickenConditionEmpty, setIsChickenConditionEmpty] = useState(false);
  const [isHarvestQtyError, setIsHarvestQtyError] = useState(false);
  const [harvestQtyMessageError, setHarvestQtyMessageError] = useState(false);
  const [isTonaseEmpty, setIsTonaseEmpty] = useState(false);
  const [isMarketPriceEmpty, setIsMarketPriceEmpty] = useState(false);
  const [isContractPriceEmpty, setIsContractPriceEmpty] = useState(false);
  const [modalConfirmation, setModalconfirmation] = useState(false);
  const [rotation, setRotation] = useState(0);
  const [rotationNegative, setRotationNegative] = useState("");

  const [zoomLevel, setZoomLevel] = useState(1);

  const handleChangePlatMobil = (e) => {
    const valuePlatMobil = e.target.value;

    if (valuePlatMobil) {
      setIsPlatMobilEmpty(false);
    }

    setPlatMobil(valuePlatMobil);
  };

  const handleChangeNomorDo = (e) => {
    const value = e.target.value;

    if (value) {
      setIsNomorDoEmpty(false);
    }

    setNomorDo(value);
  };

  const handleChangeMarketPrice = (e) => {
    const value = e.target.value;

    if (value) {
      setIsMarketPriceEmpty(false);
    }

    setMarketPrice(value);
  };

  const handleChangeContractPrice = (e) => {
    const value = e.target.value;

    if (value) {
      setIsContractPriceEmpty(false);
    }

    setContractPrice(value);
  };

  useEffect(() => {
    const fetchHarvestDetail = async () => {
      try {
        const response = await api.get(`/v2/harvestDetails/${detailId}`);
        setNomorDo(response.data.doNumber);
        setPlatMobil(response.data.licenseNumber);
        setPanenType(response.data.harvestType?.id);
        setChickenCondition(response.data.chickenType?.id);
        setBw(response.data.averageBw);
        setHarvestQty(response.data.harvestQty);
        setTonase(response.data.tonnageQty);
        setMarketPrice(response.data.marketPrice);
        setContractPrice(response.data.contractPrice);
      } catch (error) {
        console.error("Error fetching docs:", error);
      }
    };

    if (open && detailId) {
      fetchHarvestDetail();
    }
  }, [open, detailId]);

  const handlePanenTypeChange = (event) => {
    if (event.target.value) {
      setIsPanenTypeEmpty(false);
    }

    setPanenType(event.target.value);
  };

  const handleChickenConditionChange = (event) => {
    if (event.target.value) {
      setIsChickenConditionEmpty(false);
    }
    setChickenCondition(event.target.value);
  };

  const handleNext = () => !isLastStep && setActiveStep((cur) => cur + 1);
  const handlePrev = () => !isFirstStep && setActiveStep((cur) => cur - 1);

  const nextStep = () => {
    if (!nomorDo || !platMobil || !panenType || !chickenCondition) {
      if (!nomorDo) {
        setIsNomorDoEmpty(true);
      }
      if (!platMobil) {
        setIsPlatMobilEmpty(true);
      }
      if (!panenType) {
        setIsPanenTypeEmpty(true);
      }

      if (!chickenCondition) {
        setIsChickenConditionEmpty(true);
      }
    } else {
      setStep(step + 1);
      handleNext();
    }
  };

  const prevStep = () => {
    setStep(step - 1);
    handlePrev();
  };

  const handleClose = () => {
    setOpen(false);
    setModalconfirmation(false);
    setUrl("");
    setIsNomorDoEmpty(false);
    setIsPlatMobilEmpty(false);
    setIsPanenTypeEmpty(false);
    setIsChickenConditionEmpty(false);
    setNomorDo("");
    setPlatMobil("");
    setPanenType("");
    setChickenCondition("");
    setHarvestQty(0);
    setTonase(0);
    setBw(0);
    setMarketPrice(0);
    setContractPrice(0);
    setStep(2);
    setActiveStep(1);
  };

  const handleSave = async () => {
    if (!harvestQty || !tonase || !marketPrice || !contractPrice) {
      if (!harvestQty) {
        setIsHarvestQtyError(true);
        setHarvestQtyMessageError("Jumlah panen tidak boleh kosong");
      }
      if (!tonase) {
        setIsTonaseEmpty(true);
      }
      if (!marketPrice) {
        setIsMarketPriceEmpty(true);
      }
      if (!contractPrice) {
        setIsContractPriceEmpty(true);
      }
    } else {
      const intHarvestQty =
        typeof harvestQty === "string" && harvestQty?.includes(".")
          ? parseInt(harvestQty?.replace(/\./g, ""))
          : harvestQty;

      let parseTonase;
      if (typeof tonase === "string") {
        if (tonase?.includes(".")) {
          parseTonase = tonase?.replace(/\./g, "");
        } else {
          parseTonase = tonase;
        }
      }

      const floatTonase = parseFloat(
        parseTonase?.includes(",")
          ? parseTonase?.replace(",", ".")
          : parseTonase
      );

      const intMarketPrice =
        typeof marketPrice === "string" && marketPrice?.includes(".")
          ? parseInt(marketPrice?.replace(/\./g, ""))
          : marketPrice;

      const intContractPrice =
        typeof contractPrice === "string" && contractPrice?.includes(".")
          ? parseInt(contractPrice?.replace(/\./g, ""))
          : contractPrice;

      const newDetail = {
        harvest: { id: harvestId },
        doNumber: nomorDo,
        licenseNumber: platMobil,
        harvestType: panenType,
        chickenType: chickenCondition,
        harvestQty: intHarvestQty,
        tonnageQty: floatTonase,
        marketPrice: intMarketPrice,
        contractPrice: intContractPrice,
      };

      try {
        const response = await api.put(
          `/v2/harvestDetails/${detailId}`,
          newDetail
        );

        if (onReloadData) {
          onReloadData();
        }

        handleClose();
      } catch (error) {
        console.error(
          "Gagal melengkapi detail panen:",
          error.response.data.error
        );

        if (
          error.response.data.error ===
          "Jumlah panen melebihi kapasitas populasi saat ini"
        ) {
          setHarvestQty("");
          setIsHarvestQtyError(true);
          setHarvestQtyMessageError("Jumlah panen melebihi populasi");
        }
      }
    }
  };

  const handleOpenModalConfirmation = () => {
    setModalconfirmation(true);
  };

  const handleChangeHarvestQty = (e) => {
    const value = e.target.value;
    if (value) {
      setIsHarvestQtyError(false);
    }

    const intValue = parseInt(value?.replace(/\./g, "") || 0);

    let parseTonase;
    if (typeof tonase === "string") {
      if (tonase?.includes(".")) {
        parseTonase = tonase?.replace(/\./g, "");
      } else {
        parseTonase = tonase;
      }
    }

    const floatTonase = parseFloat(
      parseTonase?.includes(",") ? parseTonase?.replace(",", ".") : parseTonase
    );

    if (intValue && (tonase !== 0 || tonase !== null)) {
      const BW = floatTonase / intValue || 0;
      const roundedBW = Number(BW?.toFixed(4));
      setBw(roundedBW);
    } else {
      setBw(0);
    }

    setHarvestQty(value);
  };

  const handleChangeTonase = (e) => {
    const value = e.target.value;

    if (value) {
      setIsTonaseEmpty(false);
    }
    let parseValue;
    if (typeof value === "string") {
      if (value?.includes(".")) {
        parseValue = value?.replace(/\./g, "");
      } else {
        parseValue = value;
      }
    }

    const intValue = parseFloat(parseValue?.replace(",", "."));
    const intHarvestQTY =
      typeof harvestQty === "string" && harvestQty?.includes(".")
        ? parseInt(harvestQty?.replace(/\./g, ""))
        : harvestQty;

    if (intValue && (harvestQty !== null || intHarvestQTY !== 0)) {
      const BW = intValue / intHarvestQTY;
      const roundedBW = Number(BW?.toFixed(4));

      setBw(roundedBW);
    } else {
      setBw(0);
    }

    setTonase(value);
  };

  const handleZoomChange = (zoomIn) => {
    setZoomLevel(zoomIn);
  };

  const handleRotate = () => {
    setRotation((prevRotation) => (prevRotation + 90) % 360); // Tambah 90 derajat
  };

  return (
    <Dialog
      open={open}
      handler={setOpen}
      className="relative flex justify-center z-9999 w-full bg-transparent"
    >
      <DialogBody className="flex justify-center items-center p-0 m-0 w-full bg-transparent 2xl:max-h-[718px] xl:max-h-[674px]">
        <Card className="min-w-[539px] p-0 m-0 rounded-md border-none shadow-none">
          <TransformWrapper
            initialScale={1}
            minScale={1}
            maxScale={4}
            wheel={{ disabled: true }}
            zoomIn={{
              step: 0.1,
            }}
            zoomOut={{
              step: 0.1,
            }}
            onZoomChange={({ scale }) => handleZoomChange(scale)}
          >
            {({ zoomIn, zoomOut, setTransform }) => (
              <>
                <div className="relative flex items-center justify-center h-full w-full bg-black rounded-l-md">
                  <TransformComponent>
                    {imgNota?.url ? (
                      <img
                        className={`object-cover object-center transform transition-transform duration-500 ease-in-out w-screen rounded-l-md h-full 2xl:max-h-[718px] xl:max-h-[674px] 2xl:min-h-[718px] xl:min-h-[674px]`}
                        src={imgNota?.url}
                        alt="nota"
                        style={{
                          transform: `rotate(${rotation}deg) scale(${zoomLevel})`,
                          cursor: zoomLevel > 1 ? "move" : "default",
                        }}
                      />
                    ) : (
                      <div className="w-full h-full 2xl:max-h-[718px] xl:max-h-[674px] 2xl:min-h-[718px] xl:min-h-[674px] flex justify-center items-center ">
                        <Typography className="text-4xl text-white">
                          Loading...
                        </Typography>
                      </div>
                    )}
                  </TransformComponent>

                  <div className="flex justify-between left-8 bottom-[2%] absolute items-center bg-black bg-opacity-40 rounded-lg gap-2 z-50">
                    <IconButton
                      onClick={() => {
                        zoomOut();
                        setZoomLevel((prev) => Math.max(prev - 0.7, 1));
                      }}
                      className="relative bg-transparent"
                    >
                      <FiZoomOut className="h-7 w-7 text-white hover:text-neutral-300" />
                    </IconButton>
                    <input
                      type="range"
                      min="1"
                      max="3"
                      step="0.1"
                      value={zoomLevel}
                      onChange={(e) => {
                        const newScale = parseFloat(e.target.value);
                        setTransform(0, 0, newScale);
                        setZoomLevel(newScale);
                      }}
                      className="relative bg-gray-200 cursor-pointer"
                      style={{
                        width: "150px",
                        appearance: "none",
                        height: "2px",
                        background: "#ddd",
                        outline: "none",
                        opacity: "0.9",
                        transition: "opacity 0.2s",
                        borderRadius: "5px",
                        accentColor: "white",
                      }}
                    />
                    <IconButton
                      onClick={() => {
                        zoomIn();
                        setZoomLevel((prev) => Math.min(prev + 0.7, 3));
                      }}
                      className="relative bg-transparent"
                    >
                      <FiZoomIn className="h-7 w-7 text-white hover:text-neutral-300" />
                    </IconButton>
                    <IconButton
                      onClick={handleRotate}
                      className="relative bg-transparent"
                    >
                      <FiRotateCw className="h-7 w-7 text-white hover:text-neutral-300" />
                    </IconButton>
                  </div>

                  <div className="right-6 bottom-2 absolute">
                    <a
                      href={imgNota?.url}
                      download="nota.jpg"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <IconButton className="relative bg-black bg-opacity-40">
                        <FiDownload className="h-7 w-7 text-white hover:text-neutral-300" />
                      </IconButton>
                    </a>
                  </div>
                </div>
              </>
            )}
          </TransformWrapper>
        </Card>
        <Card className="min-w-[560px] 2xl:max-h-[718px] xl:max-h-[674px] 2xl:min-h-[718px] xl:min-h-[674px] rounded-none rounded-r-md">
          <CardHeader
            floated={false}
            shadow={false}
            className="p-0 m-0 min-h-[180px] rounded-b-none"
          >
            <div className="flex justify-between border-b items-center p-lg">
              <Typography className="text-text-primary text-xl font-bold">
                Data Timbang
              </Typography>

              <IconButton
                size="sm"
                variant="text"
                onClick={handleOpenModalConfirmation}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                  className="h-5 w-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </IconButton>
            </div>
            <div className="flex justify-center items-center m-4xl">
              <Stepper
                activeStep={activeStep}
                isLastStep={(value) => setIsLastStep(value)}
                isFirstStep={(value) => setIsFirstStep(value)}
                lineClassName="border-2"
                activeLineClassName="border-2 border-blue-600"
                className="w-[300px]"
              >
                <Step
                  className={`h-7 w-7 border-8 border-neutral-200 !bg-white cursor-pointer`}
                  activeClassName="border-8 border-blue-600 !bg-white"
                  completedClassName="border-8 border-blue-600 !bg-white"
                >
                  <div className="absolute pt-4 -bottom-[2.3rem] w-max text-center">
                    <Typography
                      className={
                        activeStep === 1
                          ? "text-sm font-semibold text-text-secondary"
                          : "text-sm font-semibold text-text-quarterary"
                      }
                    >
                      Informasi Pakan
                    </Typography>
                  </div>
                </Step>
                <Step className="h-0 w-0" />
                <Step
                  className="h-7 w-7 border-8 border-neutral-200 !bg-white cursor-pointer"
                  activeClassName="border-8 border-blue-600 !bg-white"
                  completedClassName="border-8 border-blue-600 !bg-white"
                >
                  <div className="absolute pt-4 -bottom-[2.3rem] w-max text-center">
                    <Typography
                      className={
                        activeStep === 2
                          ? "text-sm font-semibold text-text-secondary"
                          : "text-sm font-semibold text-text-quarterary"
                      }
                    >
                      Data Timbang
                    </Typography>
                  </div>
                </Step>
              </Stepper>
            </div>
          </CardHeader>

          {step === 2 && (
            <>
              <CardBody className="p-lg m-0 overflow-auto">
                <div className="max-h-[399px] p-xs">
                  <div>
                    <Typography className="text-sm font-medium text-text-secondary leading-tight mb-md">
                      No. DO
                      <span className="text-red-500 text-sm font-normal leading-tight">
                        {" "}
                        *
                      </span>
                    </Typography>

                    <Input
                      size="medium"
                      value={nomorDo}
                      onChange={handleChangeNomorDo}
                      className="max-w-full"
                      type="text"
                      placeholder="BRX/Kodeunit/XXXX"
                    />
                    {isNomorDoEmpty && (
                      <p className="text-red-500 text-sm mt-1">
                        Nomor D.O tidak boleh kosong
                      </p>
                    )}
                  </div>
                  <div className="mt-5xl">
                    <Typography className="text-sm font-medium text-text-secondary leading-tight mb-md">
                      Plat mobil
                      <span className="text-red-500 text-sm font-normal leading-tight">
                        {" "}
                        *
                      </span>
                    </Typography>

                    <Input
                      size="medium"
                      value={platMobil}
                      onChange={handleChangePlatMobil}
                      placeholder={"Masukkan nomor plat mobil"}
                    />
                    {isPlatMobilEmpty && (
                      <p className="text-red-500 text-sm mt-1">
                        Nomor plat mobil tidak boleh kosong
                      </p>
                    )}
                  </div>
                  <div className="my-5xl">
                    <Typography className="text-sm font-medium text-text-secondary leading-tight mb-md">
                      Jenis panen
                      <span className="text-red-500 text-sm font-normal leading-tight">
                        {" "}
                        *
                      </span>
                    </Typography>
                    <div className="flex gap-6">
                      <Radio
                        name="panenType"
                        label={
                          <Typography className="hover:text-text-primary text-text-secondary font-medium transition-colors text-base">
                            Panen raya
                          </Typography>
                        }
                        color="amber"
                        value="PANEN_RAYA"
                        checked={panenType === "PANEN_RAYA"}
                        onChange={handlePanenTypeChange}
                      />
                      <Radio
                        name="panenType"
                        label={
                          <Typography className="hover:text-text-primary text-text-secondary font-medium transition-colors text-base">
                            Penjarangan
                          </Typography>
                        }
                        color="amber"
                        value="PENJARANGAN"
                        checked={panenType === "PENJARANGAN"}
                        onChange={handlePanenTypeChange}
                      />
                      <Radio
                        name="panenType"
                        label={
                          <Typography className="hover:text-text-primary text-text-secondary font-medium transition-colors text-base">
                            Kosong kandang
                          </Typography>
                        }
                        color="amber"
                        value="KOSONG_KANDANG"
                        checked={panenType === "KOSONG_KANDANG"}
                        onChange={handlePanenTypeChange}
                      />
                    </div>
                    {isPanenTypeEmpty && (
                      <p className="text-red-500 text-sm mt-1">
                        Jenis panen tidak boleh kosong
                      </p>
                    )}
                  </div>

                  <div>
                    <Typography className="text-sm font-medium text-text-secondary leading-tight mb-md">
                      Kondisi ayam
                      <span className="text-red-500 text-sm font-normal leading-tight">
                        {" "}
                        *
                      </span>
                    </Typography>
                    <div className="flex gap-14">
                      <Radio
                        name="chickenCondition"
                        label={
                          <Typography className="hover:text-text-primary text-text-secondary font-medium transition-colors text-base">
                            Normal
                          </Typography>
                        }
                        color="amber"
                        value="NORMAL"
                        checked={chickenCondition === "NORMAL"}
                        onChange={handleChickenConditionChange}
                      />
                      <Radio
                        name="chickenCondition "
                        label={
                          <Typography className="hover:text-text-primary text-text-secondary font-medium transition-colors text-base">
                            Afkir
                          </Typography>
                        }
                        color="amber"
                        value="AFKIR"
                        checked={chickenCondition === "AFKIR"}
                        onChange={handleChickenConditionChange}
                      />
                      <Radio
                        name="chickenCondition "
                        label={
                          <Typography className="hover:text-text-primary text-text-secondary font-medium transition-colors text-base">
                            Sakit
                          </Typography>
                        }
                        color="amber"
                        value="SAKIT"
                        checked={chickenCondition === "SAKIT"}
                        onChange={handleChickenConditionChange}
                      />
                    </div>
                    {isChickenConditionEmpty && (
                      <p className="text-red-500 text-sm mt-1">
                        Kondisi ayam tidak boleh kosong
                      </p>
                    )}
                  </div>
                </div>
              </CardBody>
              <CardFooter className="flex justify-end border-t border-blue-gray-50 py-lg px-4xl">
                <Button
                  label="Selanjutnya"
                  size="medium"
                  type="btnPrimary"
                  onClick={() => nextStep()}
                  className={"w-[209px]"}
                />
              </CardFooter>
            </>
          )}

          {step === 3 && (
            <>
              <CardBody className="p-lg m-0 overflow-auto">
                <div className="max-h-[399px]">
                  <div className="p-xs">
                    <Typography className="text-sm font-medium text-text-secondary leading-tight mb-md">
                      Jumlah panen
                      <span className="text-red-500 text-sm font-normal leading-tight">
                        {" "}
                        *
                      </span>
                    </Typography>

                    <InputNumber
                      size="medium"
                      className="max-w-full"
                      value={harvestQty}
                      onChange={handleChangeHarvestQty}
                      componentRight={"Ekor"}
                      placeholder={"Masukkan jumlah panen"}
                    />
                    {isHarvestQtyError && (
                      <p className="text-red-500 text-sm mt-1">
                        {harvestQtyMessageError}
                      </p>
                    )}
                  </div>
                  <div className="mt-5xl p-xs">
                    <Typography className="text-sm font-medium text-text-secondary leading-tight mb-md">
                      Tonase
                      <span className="text-red-500 text-sm font-normal leading-tight">
                        {" "}
                        *
                      </span>
                    </Typography>
                    <InputNumber
                      size="medium"
                      className="max-w-full"
                      value={tonase}
                      onChange={handleChangeTonase}
                      componentRight={"kg"}
                      placeholder={"Masukkan jumlah tonase"}
                      decimalScale={2}
                    />
                    {isTonaseEmpty && (
                      <p className="text-red-500 text-sm mt-1">
                        Tonase tidak boleh kosong
                      </p>
                    )}
                  </div>
                  <div className="mt-5xl p-xs">
                    <Typography className="text-sm font-medium text-text-secondary leading-tight mb-md">
                      Rata-rata BW
                      <span className="text-red-500 text-sm font-normal leading-tight">
                        {" "}
                        *
                      </span>
                    </Typography>
                    <InputNumber
                      size="medium"
                      className="max-w-full"
                      value={bw}
                      onChange={(e) => setBw(e.target.value)}
                      componentRight={"kg"}
                      disabled
                      decimalScale={4}
                    />
                  </div>

                  <div className="mt-5xl p-xs">
                    <Typography className="text-sm font-medium text-text-secondary leading-tight mb-md">
                      Harga pasar
                      <span className="text-red-500 text-sm font-normal leading-tight">
                        {" "}
                        *
                      </span>
                    </Typography>

                    <InputNumber
                      size="medium"
                      className="max-w-full"
                      value={marketPrice}
                      onChange={handleChangeMarketPrice}
                      componentLeft={"Rp"}
                    />
                    {isMarketPriceEmpty && (
                      <p className="text-red-500 text-sm mt-1">
                        Harga pasar tidak boleh kosong
                      </p>
                    )}
                  </div>
                  <div className="mt-5xl p-xs">
                    <Typography className="text-sm font-medium text-text-secondary leading-tight mb-md">
                      Harga kontrak
                      <span className="text-red-500 text-sm font-normal leading-tight">
                        {" "}
                        *
                      </span>
                    </Typography>

                    <InputNumber
                      size="medium"
                      className="max-w-full"
                      value={contractPrice}
                      onChange={handleChangeContractPrice}
                      componentLeft={"Rp"}
                    />
                    {isContractPriceEmpty && (
                      <p className="text-red-500 text-sm mt-1">
                        Harga kontrak tidak boleh kosong
                      </p>
                    )}
                  </div>
                </div>
              </CardBody>
              <CardFooter className="flex justify-between border-t border-border-primary py-lg px-4xl">
                <Button
                  label="Sebelumnya"
                  size="medium"
                  type="btnSecondary"
                  className="w-[209px]"
                  onClick={() => prevStep()}
                />
                <Button
                  label="Simpan"
                  size="medium"
                  type="btnPrimary"
                  className="w-[209px]"
                  onClick={handleSave}
                />
              </CardFooter>
            </>
          )}
        </Card>
      </DialogBody>
      <ConfirmDialog
        isOpen={modalConfirmation}
        onClose={() => setModalconfirmation(!modalConfirmation)}
        onCancel={handleClose}
        content="Data yang sudah Anda masukkan tidak akan disimpan jika Anda keluar sekarang. Apakah Anda yakin ingin melanjutkan?"
        title="Keluar tanpa menyimpan?"
        btnLabel="Batal keluar"
        btnLabelCancel="Tinggalkan form"
        onConfirm={() => setModalconfirmation(!modalConfirmation)}
        classNameContent={"text-md"}
      />
    </Dialog>
  );
};

export default ModalInputPanen;
