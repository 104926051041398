import React from "react";
import {
    Checkbox,
  } from "@material-tailwind/react";

export const CheckboxCust = ({checked, onChange}) => {
  return (
    <Checkbox
    className="checked:bg-primary-500 rounded-sm checked:border-none hover:before:opacity-0 hover:shadow-[0px_0px_3px_3px] hover:shadow-neutral-300 hover:checked:shadow-primary-200"
    ripple="false"
    checked={checked}
    onChange={onChange}
  />
  );
};
