/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/alt-text */
import { Card, CardBody, CardFooter, CardHeader, Typography } from "@material-tailwind/react"
import CustomDatePicker from "../../../atoms/DatepickerForm"
import Dropdown from "../../../atoms/DropdownWithReset"
import DropdownSapronak from "../../../atoms/DropdownSapronak"
import Input from "../../../atoms/Input"
import TextArea from "../../../atoms/TextArea"
import { useEffect, useState } from "react"
import { ReactComponent as UploadImage } from '../../../assets/icons/upload_image.svg'
import imageCompression from 'browser-image-compression';
import { Button } from "../../../atoms/Button"
import { useNavigate } from "react-router-dom"
import ConfirmDialog from "../../../atoms/ConfirmDialog"
import { ReactComponent as TrashMutation } from '../../../assets/icons/trash-mutation.svg'
import api from "../../../api/configApi";
import DefaultImagePakan from "../../../img/Pakan_image.png";
import moment from "moment"
import AlertNotification from "../../../atoms/AlertNotification"
import { FaCheckCircle } from "react-icons/fa";
import { FiAlertTriangle } from "react-icons/fi";
import LoadingAnimationRounded from "../../../atoms/LoadingAnimationRounded"
import ImageDialog from "../../../atoms/ModalDialogImage"
import { ReactComponent as ChevronLeft } from "../../../assets/icons/chevron-left.svg";

const AddMutasi = () => {
  const navigate = useNavigate()
  const [images, setImages] = useState([])
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [originCage, setOriginCage] = useState([])
  const [selectCageOrigin, setSelectCageOrigin] = useState(null)
  const [destyniCage, setDestinyCage] = useState([])
  const [selectDestiny, setSelectDestiny] = useState(null)
  const [dateMutation, setDateMutation] = useState(null)
  const [optionsFeeds, setOptionsFeed] = useState([])
  const [registerNumber, setRegisterNumber] = useState("")
  const [searchFeed, setSearchFeed] = useState("");
  const [selectStock, setSelectStock] = useState(0)
  const [range, setRange] = useState("")
  const [desc, setDesc] = useState("")
  const [selectFeed, setSelectFeed] = useState(null)
  const [showError, setShowError] = useState(false)
  const [showErrorMessage, setShowErrorMessage] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [isSuccsess, setIssuccess] = useState(false);
  const [borderError, setBorderError] = useState(false)
  const [openDialogImage, setOpenDialogimage] = useState(false)
  const [errorEmptydate, setErrorEmptydate] = useState(false)
  const [cageOriginError, setCageOriginError] = useState(false)
  const [cageDestinyError, setCageDestinyError] = useState(false)
  const [typeOvkError, setTypeOvkError] = useState(false)
  const [quantityError, setQuantityError] = useState(false)
  const [registryNumberError, setRegistryNumberError] = useState(false)
  const [imageMore, setImageMore] = useState(false)
  const [showNotifNoEmpty, setShowNotifNoEmpty] = useState(false)
  const [showToastComplete, setShowToastcomplete] = useState(false)
  const [disableAfterCreate, setDisableAfterCreate] = useState(false)
  const [errorEmptImage, setErrorEmptyImage] = useState(false)

  const ZAK_WEIGHT = 50;


  useEffect(() => {
    const originMutation = async () => {
      try {
        const responseOriginMutation = await api.get('/v1/rearingPeriods/dropdown/mutation')
        setOriginCage(responseOriginMutation?.data)
      } catch (error) {
        console.log(error)
      }
    }
    originMutation()
  }, [])

  useEffect(() => {
    const destinyMutation = async () => {
      try {
        const destinyOrigin = await api.get('/v1/rearingPeriods/dropdown/mutation', {
          params: {
            validZak: 1,
            exclude: selectCageOrigin?.id
          }
        })
        setDestinyCage(destinyOrigin?.data)
      } catch (error) {
        console.log("error", error)
      }
    }

    destinyMutation()
  }, [selectCageOrigin])

  useEffect(() => {
    const fethcingPakan = async () => {
      try {
        const fetchingOVKDropdown = await api.get("v1/feeds/stock", {
          params: {
            periodId:  selectCageOrigin?.id
          }
        })

        setOptionsFeed(fetchingOVKDropdown?.data?.contents)
      } catch (error) {
        console.log(error)
      }
    }

    fethcingPakan()
  }, [selectCageOrigin?.id])

  const handleFileChange = async (event) => {
    const files = Array.from(event.target.files);
    const compressedImages = [];
  
    for (let file of files) {
      try {
        const options = {
          maxSizeMB: 2, // Limit the file size to 2MB
          useWebWorker: true, // Enable web worker for better performance
        };
  
        // Compress the image while retaining original dimensions
        const compressedFile = await imageCompression(file, options);
        const imageUrl = URL.createObjectURL(compressedFile);
        compressedImages.push(imageUrl);
      } catch (error) {
        console.error("Error compressing the image:", error);
      }
    }
  
    // Limit the total number of images to 6
    if (images.length + compressedImages.length > 6) {
      setImageMore(true);
      return;
    }
  
    setImages((prevImages) => [...prevImages, ...compressedImages]);
  };
  

  useEffect(() => {
    if (imageMore) {
      const timer = setTimeout(() => setImageMore(false), 3000);
      return () => clearTimeout(timer); 
    }

    if (showToastComplete) {
      const timer = setTimeout(() => setShowToastcomplete(false), 3000)
      return () => clearTimeout(timer)
    };
    
  }, [imageMore, showToastComplete]);
  
  
  const handleDeleteImage = (index) => {
    setImages((prevImages) => prevImages.filter((_, i) => i !== index));
  };
  

  const handleSelectOriginCage = (cage) => {
    setSelectCageOrigin(cage)
  }

  const handleSelectDestinyCage = (cage) => {
    setSelectDestiny(cage)
  }

  const handleDateMutation = (date) => {
    setDateMutation(date)
  }

  const handleSelectFeeds = (feed) => {
    console.log(feed, "pakan")

    const zakCount = feed.stock / ZAK_WEIGHT;
    const roundedZakCount = Math.floor(zakCount);

    setSelectFeed(feed);
    setSelectStock(roundedZakCount);

  }

  const handleCreate = async () => {
    setIsLoading(true)
    setDisableAfterCreate(true)

    // conditional empty
    if (dateMutation === null) {
      setErrorEmptydate(true)
    }

    if (selectCageOrigin === null) {
      setCageOriginError(true)
    }
     
    if (selectDestiny === null) {
      setCageDestinyError(true)
    }

    if (selectFeed === null) {
      setTypeOvkError(true)
    }

    if (selectStock === 0) {
      setQuantityError(true)
    }

    if (registerNumber === "") {
      setRegistryNumberError(true)
    }

    if (images.length === 0) {
      setErrorEmptyImage(true)
    }

    const formData = new FormData()
    // formData.append("files", images)

    const convertBlobUrlToFile = async (blobUrl, filename) => {
      const response = await fetch(blobUrl);
      const blob = await response.blob();
      return new File([blob], filename, { type: blob.type });
    };

    if (Array.isArray(images)) {
      
      await Promise.all(
        images.map(async (blobUrl, index) => {
          const file = await convertBlobUrlToFile(blobUrl, `image_${index + 1}.jpg`);
          formData.append("files", file);
        })
      );
    } else if (typeof images === "string") {
      const file = await convertBlobUrlToFile(images, "image.jpg");
      formData.append("files", file);
    }
  

    const mutationData = {
      dateOut:  moment(dateMutation)?.format("YYYY-MM-DD"),
      rearingPeriod: {id: selectCageOrigin?.id },
      destination: { id: selectDestiny?.id  },
      feed: { id: selectFeed?.id },
      qtyOut: Math.floor(selectStock * 50),
      licenseNumber: registerNumber,
      destinationEstimation: range,
      creatorNotes: desc
    }

    formData.append("data", JSON.stringify(mutationData))

   
    try {
      const responseCreate = await api.post(`/v1/feeds/out/withDoc`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })

      localStorage.setItem("response add success", JSON.stringify(isSuccsess))
      navigate(`/mutasi/${responseCreate?.data?.content?.id}`)
     
      // /mutasi/detailMutasi/:id
    } catch (error) {
      console.log(error)
      setShowConfirmation(false)
      setShowError(true)
      setShowErrorMessage(error?.message)
      setBorderError(true)
      setTimeout(() => setShowError(false), 2000)
    } finally {
      setIsLoading(false)
    }
  }

  const handleClickBack = () => {
    const hasIncompleteData = !dateMutation || !originCage || !destyniCage || !optionsFeeds || !selectStock || !registerNumber;

    if (hasIncompleteData) {
        setShowNotifNoEmpty(true);
    } else {
        navigate("/mutasi");
    }

    // console.log("click")
  };

  const handleClickCreate = () => {
    // Identify incomplete data
    const hasIncompleteData = 
      !dateMutation ||       
      !originCage ||         
      !destyniCage ||        
      !optionsFeeds ||       
      !selectStock ||        
      !registerNumber ||     
      images.length === 0; // Add images check
  
    if (hasIncompleteData) {
      // Set error states for specific fields
      setErrorEmptydate(!dateMutation);
      setCageOriginError(!selectCageOrigin);
      setCageDestinyError(!selectDestiny);
      setTypeOvkError(!selectFeed);
      setQuantityError(!selectStock);
      setRegistryNumberError(!registerNumber);
      setErrorEmptyImage(images.length === 0);
  
      // Show confirmation and toaster
      setShowConfirmation(false);
      setShowToastcomplete(true);
  
      // Clear errors after a timeout
      setTimeout(() => {
        // setErrorEmptydate(false);
        // setCageOriginError(false);
        // setCageDestinyError(false);
        // setTypeOvkError(false);
        // setQuantityError(false);
        // setRegistryNumberError(false);
        // setErrorEmptyImage(false);
        setShowToastcomplete(false);
      }, 3000); // 3-second timeout
    } else {
      setShowConfirmation(true);
    }
  };
  

  return (
    <>
      <div className="bg-[#F8F8F8] w-full h-full">
        <div className="flex justify-center items-center">
          <div className="flex justify-center w-[75%] bg-[#ffffff] items-center rounded-sm mt-[40px]">
            <Card className="w-full h-auto border shadow-none">
              <CardHeader
                floated={false}
                shadow={false}
                className="border-none text-left rounded-t-lg rounded-b-none m-0 p-2"
              >
                <div className="h-[40px] p-2 w-full relative bg-[#ffff] pl-4 flex justify-start">
                  <div className="mt-2 justify-start cursor-pointer" onClick={() => navigate("/mutasi")}>
                    <ChevronLeft />
                  </div>
                  <Typography className="text-md font-bold text-text-primary mt-[7px]">
                    Tambah Data Mutasi
                  </Typography>
                </div>
              </CardHeader>

              <CardBody className="p-4 mt-[2px]">
                <div className="bg-[#F8F8F8] p-3 rounded-md">  
                  <Typography className="text-text-primary text-sm font-medium leading-tight">
                    Tanggal Mutasi <span className="text-red-600">*</span>

                    <CustomDatePicker 
                      placeholder="Pilih Tanggal"
                      className={`w-full text-text-primary px-sm py-xs justify-start items-center gap-xxs inline-flex focus:outline-none focus:border-primary-400 focus:ring focus:ring-primary-200 mt-md h-[42px]
                        ${errorEmptydate ?"border-red-500" : "border-none" }  
                      `}
                      //  onChange={handleChangeEndDate}
                      value={dateMutation}
                      onChange={handleDateMutation}
                      format={"DD MMMM YYYY"}
                      size="md"
                      defaultValue={true}
                      disableDate={(current) => current && current > moment().endOf("day")}
                    />

                    {/* Error message display */}
                    {errorEmptydate && (
                      <div className="text-red-600 text-xs mt-1">
                        Pilih Tanggal terlebih dahulu
                      </div>
                    )}
                  </Typography>

                  <div className="grid grid-cols-1 gap-3xl mt-[20px]">
                    <Typography className="text-text-primary text-sm font-medium leading-tight">
                      Kandang Asal <span className="text-red-600">*</span>
                      
                      <div className="mt-2">
                        <Dropdown 
                          placeholder="Kandang Asal"
                          options={originCage?.contents}
                          onSelect={handleSelectOriginCage}
                          selectedOption={selectCageOrigin}
                          className={`w-full text-text-primary px-sm py-xs justify-start items-center gap-xxs inline-flex focus:outline-none focus:border-primary-400 focus:ring focus:ring-primary-200 mt-md h-[42px]
                            ${cageOriginError ?"border-red-500" : "border-none" }  
                          `}
                          isError={cageOriginError}
                        />

                        {cageOriginError && (
                          <div className="text-red-600 text-xs mt-1">
                            Pilih Kandang Asal Terlebih dahulu
                          </div>
                        )}
                      </div>
                    </Typography>
                  </div>

                  <div className="grid grid-cols-1 gap-3xl mt-[20px]">
                    <Typography className="text-text-primary text-sm font-medium leading-tight">
                      Kandang Tujuan <span className="text-red-600">*</span>
                      
                      <div className="mt-2">
                        <Dropdown 
                          placeholder="Kandang Tujuan"
                          options={destyniCage?.contents}
                          onSelect={handleSelectDestinyCage}
                          selectedOption={selectDestiny}
                          isError={cageDestinyError}
                          // onSelect={handleSelectOriginCage}
                          // selectedOption={selectCageOrigin}
                        />

                        {cageDestinyError && (
                          <div className="text-red-600 text-xs mt-1">
                            Pilih Kandang Tujuan Terlebih dahulu
                          </div>
                        )}
                      </div>
                    </Typography>
                  </div>

                  <div className="grid grid-cols-1 gap-3xl mt-[20px]">
                    <Typography className="text-text-primary text-sm font-medium leading-tight">
                      Jenis Pakan <span className="text-red-600">*</span>
                      
                      <div className="mt-2">
                        <DropdownSapronak 
                          size="medium"
                          placeholder="Pilih Jenis Pakan"
                          options={optionsFeeds}
                          onSelect={handleSelectFeeds}
                          setSearch={setSearchFeed}
                          defaultProduct={DefaultImagePakan}
                          search={searchFeed}
                          className={`placeholder-gray-900 ${
                            typeOvkError ? "border-red-500" : "border-none"}`}
                          isStock
                          isType
                        />

                        {typeOvkError && (
                          <div className="text-red-600 text-xs mt-1">
                            Pilih Jenis Pakan Terlebih dahulu
                          </div>
                        )}
                      </div>
                    </Typography>
                  </div>

                  <div className="grid grid-cols-1 gap-3xl mt-[20px]">
                    <Typography className="text-text-primary text-sm font-medium leading-tight">
                      Kuantitas <span className="text-red-600">*</span>
                      
                      <div className="mt-2">
                        <Input
                          type="text"
                          className={`h-[40px] w-full rounded-md pr-5xl placeholder:text-text-placeholder text-text-secondary px-sm py-xs justify-start items-center gap-xxs inline-flex focus:outline-none ${
                            borderError  ? "border-red-500" : "border-none"
                          }`}
                          placeholder="0"
                          componentRight={
                            <Typography className="text-[#727478] font-normal ">
                              Zak
                            </Typography>
                          }
                          value={selectStock}
                          onChange={(e) => setSelectStock(e.target.value) * 50}
                          isError={quantityError}
                        />

                        {quantityError && (
                          <div className="pt-2">
                            <Typography className="text-sm text-text-errorPrimary w-full h-[30px] text-start font-normal leading-tight">
                              Inputkan kuantitas terlebih dahulu
                            </Typography>
                        </div>
                        )}

                        {borderError && (
                          <div className="pt-2">
                            <Typography className="text-sm text-text-errorPrimary w-full h-[30px] text-start font-normal leading-tight">
                              Jumlah kuantitas melebihi sisa pakan
                            </Typography>
                          </div>
                        )}
                      </div>
                    </Typography>
                  </div>

                  <div className="grid grid-cols-1 gap-3xl mt-[20px]">
                    <Typography className="text-text-primary text-sm font-medium leading-tight">
                      Plat nomor <span className="text-red-600">*</span>
                      <div className="mt-2">
                        <Input
                          type="text"
                          className={`h-[40px] w-full rounded-md pr-5xl placeholder:text-text-placeholder text-text-secondary px-sm py-xs justify-start items-center gap-xxs inline-flex focus:outline-none 
                          ${registryNumberError ? "border-red-500" : "border-neutral-300"}`}
                          placeholder="cth : B34XXXX"
                          value={registerNumber.toUpperCase()}
                          onChange={(e) => setRegisterNumber(e.target.value)}
                          isError={registryNumberError}
                        />

                        {registryNumberError && (
                          <div className="pt-2">
                            <Typography className="text-sm text-text-errorPrimary w-full h-[30px] text-start font-normal leading-tight">
                              Inputkan nomor kendaraan terlebih dahulu
                            </Typography>
                          </div>
                        )}
                      </div>
                    </Typography>
                  </div>

                  <div className="grid grid-cols-1 gap-3xl mt-[20px]">
                    <Typography className="text-text-primary text-sm font-medium leading-tight">
                      Inputkan estimasi jarak (km) (Opsional)
                      
                      <div className="mt-2">
                        <Input
                          type="text"
                          className={`h-[40px] w-full border-none rounded-md pr-5xl placeholder:text-text-placeholder text-text-secondary px-sm py-xs justify-start items-center gap-xxs inline-flex focus:outline-none `}
                          placeholder='0'
                          value={range}
                          onChange={(e) => setRange(e.target.value)}
                          // value={search}
                          // onChange={(e) => setSearch(e.target.value)}
                        />
                      </div>
                    </Typography>
                  </div>

                  <div className="grid grid-cols-1 gap-3xl mt-[20px]">
                    <Typography className="text-text-primary text-sm font-medium leading-tight">
                      Keterangan (opsional)
                      
                      <div className="mt-2">
                        <TextArea
                          type="text"
                          className={`h-[120px] w-full border-none rounded-md pr-5xl placeholder:text-text-placeholder placeholder:p-2 text-text-secondary px-sm py-xs justify-start items-center gap-xxs inline-flex focus:outline-none `}
                          placeholder='Tidak ada catatan'
                          value={desc}
                          onChange={(e) => setDesc(e.target.value)}
                          // value={search}
                          // onChange={(e) => setSearch(e.target.value)}
                        />
                      </div>
                    </Typography>
                  </div>
                </div>

                <div className="bg-[#F8F8F8] p-3 rounded-md mt-[34px]"> 
                  <div className="p-4">
                    <Typography className="text-text-primary text-sm font-medium leading-tight">
                      Unggah Foto <span className="text-red-600">*</span>
                    </Typography>
                    <Typography className="text-[#727478] text-[12px] ">
                      Unggah maksimal 6 foto 
                    </Typography>

                    {errorEmptImage && (
                      <Typography className="text-[#F04438] text-sm font-medium leading-tight mt-[2px]">
                        Anda belum mengunggah foto dokumentasi
                      </Typography>
                    )}
                  </div>

                  <div className="mt-4 grid grid-cols-6 gap-4 bg-[#F8F8F8]">
                    {images.map((image, index) => (
                      <>
                        <Card key={index} className="relative bg-white shadow-md rounded-md h-[108px] w-[108px] p-1">
                          <CardBody className="flex flex-col items-center justify-center gap-1">
                            <div
                              onClick={() => handleDeleteImage(index)}
                              className="absolute top-1 right-1 bg-black/70 rounded-md w-[20px] h-[20px] flex justify-center items-center cursor-pointer z-50"
                              style={{ pointerEvents: "auto" }}
                            >
                              <TrashMutation className="w-[10px] h-[10px]" />
                            </div>
                            <div
                              onClick={() => setOpenDialogimage(!openDialogImage)}
                              className="w-[89px] h-[97px] bg-white rounded-md flex justify-center items-center cursor-pointer overflow-hidden mt-[-23px]"
                            >
                              <img
                                src={image}
                                alt={`upload ${index + 1}`}
                                className="object-cover rounded-md"
                              />
                            </div>
                          </CardBody>
                        </Card>

                        <ImageDialog 
                          open={openDialogImage}
                          handlerClose={() => setOpenDialogimage(!openDialogImage)}
                          img={image}
                          withoutFetching
                        />
                      </>

                    ))}
                  </div>

                  <div className="border-2 border-dashed rounded-md border-[#D5D5D7] p-4 mt-[10px]">
                    <div className="flex justify-center h-[80px] items-center">
                      <div className="flex items-center justify-center w-full h-24">
                        <label className="flex flex-col items-center justify-center h-full w-full cursor-pointer">
                          <div className="flex gap-2">
                            <UploadImage />
                            <span className="text-[#2D61D6] text-sm">Pilih file</span>
                          </div>

                          <Typography className="text-text-primary text-sm font-bold leading-tight mt-[2px]">
                            Atau tarik untuk unggah gambar
                          </Typography>

                          <Typography className="text-[#727478] text-sm font-medium leading-tight mt-[2px]">
                            Unggah foto dalam format <span className="font-bold">JPG</span>, <span className="font-bold">JPEG</span>, atau <span className="font-bold">PNG</span> dengan ukuran maksimal <span className="font-bold">2 MB</span>
                          </Typography>

                          

                          <input
                            type="file"
                            accept="image/*"
                            className="hidden"
                            onChange={handleFileChange}
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </CardBody>

              <CardFooter className="border-t p-0 m-0 border-b-none shadow-none">
                <div className="flex justify-end p-4 gap-4">
                  <Button 
                    label="Keluar"
                    size="medium"
                    // onClick={() => navigate("/mutasi")}
                    onClick={handleClickBack}
                    type="btnSecondary"
                  />

                  <Button 
                    label="Simpan"
                    size="medium"
                    onClick={handleClickCreate}
                    // onClick={() => setShowConfirmation(!showConfirmation)}
                    type="btnPrimary"
                  />    
                </div>
              </CardFooter>
            </Card>
          </div>
        </div>
      </div>

      <ConfirmDialog 
        title={"Simpan data?"}
        content={"Data yang telah disimpan tidak dapat diubah. Apakah Anda yakin ingin melanjutkan penyimpanan data?"}
        isOpen={showConfirmation}
        onClose={() => setShowConfirmation(!showConfirmation)}
        btnLabel="Lanjutkan"
        onConfirm={handleCreate}
        disabled={disableAfterCreate}
        btnType={disableAfterCreate ?  "btnPrimaryDisabled" : "btnPrimary"}
        componentLeftBtn={
          isLoading ? (
            <LoadingAnimationRounded />
          ) : null
        }
      />

      {showError && (
        <AlertNotification 
          color="red"
          icon={<FaCheckCircle />}
          message={'Data gagal disimpan, silakan lengkapi formulir'}
        />
      )}

      {imageMore  && (
        <AlertNotification 
          icon={<FiAlertTriangle />}
          color={'orange'}
          message={'Jumlah foto telah mencapai batas maksimum.'}
        />
      )}

      <ConfirmDialog 
        isOpen={showNotifNoEmpty}
        onClose={() => setShowNotifNoEmpty(!showNotifNoEmpty)}
        btnLabel="Tetap dihalaman ini"
        btnLabelCancel="Tinggalkan form"
        title={"Anda ingin keluar dari formulir ini?"}
        content="Jika Anda meninggalkan formulir ini, semua data yang telah dimasukkan akan hilang. yakin ingin melanjutkan?"
        onCancel={() => navigate('/mutasi')}
        onConfirm={() => setShowNotifNoEmpty(!showNotifNoEmpty)}
      />

      {showToastComplete && (
        <AlertNotification 
         color="red"
         icon={<FaCheckCircle />}
         message="Data gagal disimpan, silakan lengkapi formulir."
       />
      )}

      {/* {showErrorStatus && (
        <AlertNotification 
          color="red"
          icon={<FaCheckCircle />}
          message="Data gagal disimpan, silakan lengkapi formulir."
        />
      )} */}
    </>
  )
}

export default AddMutasi