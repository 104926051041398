import { Card, CardBody, CardFooter, CardHeader } from '@material-tailwind/react'
import { ReactComponent as IconBroiler } from '../../assets/icons/LogoBroiler.svg'
import { ReactComponent as DeletedAccountIcon } from '../../assets/icons/deleteAccount.svg'
import { Typography } from 'antd'
import Input from '../../atoms/Input'
import { Button } from '../../atoms/Button'
import ConfirmDialogDeleteAccount from '../../atoms/ConfirmationDeleteAccount'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import TextArea from '../../atoms/TextArea'
import api from "../../api/configApi";
import { FaEye, FaEyeSlash } from 'react-icons/fa'

const dataDummyDeletedAccount = [
  {
    label: 'Setelah akun dihapus, Anda akan kehilangan akses dan informasi sebagai berikut:'
  }
]

const dataListDeleteAccount = [
  {
    index: 1,
    label: "Data Harian Budidaya",
    desc: "Riwayat periode budidaya, data sapronak, data deplesi,dst."
  },

  {
    index: 2,
    label: "Akun dan data pribadi",
    desc: "Info personal pada profil akun BroilerX"
  }, 

  {
    index: 3,
    label: "Data Kandang",
    desc: "Data kandang yang terhubung dengan akun Anda akan terputus baik mitra maupun non mitra."
  }
]

const DeletedAccount = () => {
  const [open, setOpen] = useState(false) 
  const [password, setPassword] = useState("")
  const [showPassword, setShowPassword] = useState(false)
  const [reason, setReasom] = useState("")
  const [responseDeleteAccount, setResponseDeleteAccouunt] = useState(null)
  const navigate = useNavigate()

  const handleSendDelete = async () => {

    try {
      const reqBody = {
        password: password,
        reason: reason
      }
      const ResponseDelete = await api.post('/v1/users/me/terminateAccount', reqBody)
      // console.log(ResponseDelete)
      setResponseDeleteAccouunt(ResponseDelete)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (responseDeleteAccount?.status === 200) {
      navigate('/')
      // setShowMessage(true)
    }
  },[navigate, responseDeleteAccount?.status])

  // setTimeout(() => setShowMessage(false), 2000)

  return (
    <div className='relative msx-h-[400px]'>
      <div className="items-start p-5">
        <IconBroiler className='w-[120px] h-[120px]'/>
      </div>

      <div className='flex justify-center items-center h-screen mt-[-90px]'>
        <div className='p-4'>
          <Card className="w-full h-auto border">
            <CardHeader
              floated={false}
              shadow={false}
              className='p-md border-none w-full flex justify-center items-center'
            >
              <DeletedAccountIcon className='w-[100px] h-[100px]'/>
            </CardHeader>

            <CardBody className='relative max-w-[800px] lex justify-center items-center'>
              {dataDummyDeletedAccount?.map((val) => (
                <Typography
                  variant="h5"
                  color="blue-gray"
                  className='p-4 text-start font-bold text-sm'
                >
                  {val.label}
                </Typography>
              ))}

              <div className='p-4 mt-[-12px] w-full'>
                {dataListDeleteAccount.map((val) => (
                  <>
                    <Typography
                      variant="h5"
                      className='w-[420px] mt-[15px] text-[#000000] font-bold text-sm leading-tight cursor-pointer'
                    >
                      <div className='flex justify-between'>
                        {val.index}. {val.label}
                      </div>
                    </Typography>

                    <div className='mt-'>
                      <Typography
                        variant="small"
                        className='text-[#475467]'
                      >
                        {val.desc}    
                      </Typography>               
                    </div>
                  </>
                ))}
              </div>
              <div className="border-t border-gray-300 my-4 w-full"/>
              <div>
                <Typography
                  variant="h5"
                  className='text-[#000000] font-bold text-sm leading-tight'
                >
                  Alasan ingin menghapus akun
                </Typography>

                <TextArea
                  className={"mt-4"}
                  placeholder="Berikan alasan untuk menghapus akun"
                  value={reason}
                  onChange={(e) => setReasom(e.target.value)}
                />
              </div>

              <div >
                <Typography
                  variant="h5"
                  className='text-[#000000] font-bold text-sm leading-tight'
                >
                  Konfirmasi kata sandi
                </Typography>

                <Input
                  size="medium"
                  className="mt-[10px]"
                  type={!showPassword ? "password" : "text"}
                  placeholder="Masukan Kata Sandi"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  componentRight={
                    <button className='mt-4' onClick={() => setShowPassword(!showPassword)}>
                      {showPassword ? (
                        <FaEye className="h-5 w-5 text-neutral-400" />
                      ) : (
                        <FaEyeSlash className="h-5 w-5 text-neutral-400" />
                      )}
                    </button>
                  }
                />
              </div>
            </CardBody>

            <div className="mt-[4px] border-t border-gray-300 my-4 w-full"/>
            <CardFooter className='flex justify-end border-t border-gray-50 p-lg ml-[30px] mt-[-20px]'>
              <Button
                label="Batalkan"
                size="medium"
                type="btnSecondary"
                className="mr-md"
                onClick={() => navigate('/dashboard')}
              />

              <Button
                label="Lanjutkan"
                size="medium"
                type={password.length < 3 ? 'btnSecondary': null}
                disabled={password?.length < 3}
                onClick={() => setOpen(!open)}
              />
            </CardFooter>
          </Card>
        </div>
      </div>
      
      <ConfirmDialogDeleteAccount
        isOpen={open}
        onConfirm={handleSendDelete}
        onClose={() => setOpen(!open)}
        btnType="btnError"
      />
    </div>
  )
}

export default DeletedAccount