/* eslint-disable jsx-a11y/img-redundant-alt */
import { Dialog, DialogBody, DialogHeader, IconButton } from "@material-tailwind/react"

const ImageDialog = ({
  open,
  handlerOpen,
  imageSrc
}) => {
  return (
    <Dialog size="md"  open={open} handler={handlerOpen}>
      <DialogHeader className="justify-end">
        <div className="flex items-center gap-2">
          <IconButton
             color="blue-gray"
             size="sm"
             variant="text"
             onClick={handlerOpen}
          >
             <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
              className="h-5 w-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
          </svg>
          </IconButton>
        </div>
      </DialogHeader>

      <DialogBody className="mt-[-30px]">
        <img
          alt="image detail harvest"
          className="h-[638px] w-[728px] rounded-lg object-cover object-center"
          src={imageSrc}
        />
      </DialogBody>
    </Dialog>
  )
}

export default ImageDialog