/* eslint-disable no-sequences */

import Cookies from "js-cookie";

export default function deleteIrrelevantCookies(currentMenu, currentSubmenu, activeMenu, activeSubmenu) {
  const cookiesMap = {
    "master": {
      "master-bakul": ["current-page-bakul", "current-page-masterUnit"],
      "master-unit": ["current-page-masterUnit", "current-page-bakul"],
    },
    "periode-budidaya": {
      "periode-berjalan": ["current-page-periodebudidaya", "current-page-periodeSelesai"],
      "periode-selesai": ["current-page-periodeSelesai", "current-page-periodebudidaya"],
    },
    "kandang": {
      "default": ["current-page-kandang"],
    },
    "panen": {
      "default": ["current-page-panen"],
    },
    "mutasi": {
      "default": ["current-page-mutasi"],
    },
    "stok": {
      "livebird": ["current-page-livebird-unit"],
    },
  };

  // Jika menu berbeda, hapus semua cookies terkait menu tersebut
  if (currentMenu !== activeMenu) {
    Object.keys(cookiesMap).forEach((menuKey) => {
      if (cookiesMap[menuKey]) {
        Object.values(cookiesMap[menuKey]).flat().forEach((cookie) => {
          Cookies.remove(cookie);
        });
      }
    });
  } else if (cookiesMap[currentMenu] && currentSubmenu !== activeSubmenu) {
    const submenuCookies = cookiesMap[currentMenu][currentSubmenu] || [];
    submenuCookies.forEach((cookie) => {
      Cookies.remove(cookie);
    });
  }
}