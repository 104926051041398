import { useMutation, useQueryClient } from "@tanstack/react-query";

import api from "../../api/configApi";
import { toast } from "../../atoms/V2/useToast";

const useDeleteMasterPeriod = (id) => {
  const queryClient = useQueryClient();
  const mutate = useMutation({
    mutationFn: async () => {
      const response = await api.delete(`/v1/masterPeriods/${id}`);
      return response;
    },
    onSuccess: () => {
      toast({
        title: "Berhasil hapus periode",
        variant: "success",
      });
      queryClient.invalidateQueries({
        queryKey: ["get-master-period"],
      });
      queryClient.invalidateQueries({
        queryKey: ["get-houses-sequences"],
      });
    },
    onError: (error) => {
      console.log(error);
      toast({
        title: "Gagal hapus periode",
        description: error.response.data.error,
        variant: "error",
      });
    },
  });

  return mutate;
};

export default useDeleteMasterPeriod;
