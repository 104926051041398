import { flexRender } from "@tanstack/react-table";

import { cn } from "../../shared/utils";
import { calculatePinnedPosition, isLastPinnedColumn } from "./config";

const TableColumn = ({ headerGroups }) => {
  return (
    <thead className="sticky top-0 z-10">
      {headerGroups.map((headerGroup) => (
        <tr key={headerGroup.id}>
          {headerGroup.headers.map((header) => {
            const isPinned = header.column.getIsPinned();
            const pinnedPosition = calculatePinnedPosition(
              header,
              headerGroup.headers,
            );
            const isLastPinned = isLastPinnedColumn(
              header,
              headerGroup.headers,
            );

            const columnRelativeDepth = header.depth - header.column.depth;
            if (columnRelativeDepth > 1) {
              return null;
            }

            let rowSpan = 1;
            if (header.isPlaceholder) {
              const leafs = header.getLeafHeaders();
              rowSpan = leafs[leafs.length - 1].depth - header.depth;
            }

            const isLastCell =
              header.id === headerGroup.headers.slice(-1)[0].id;

            return (
              <th
                key={header.id}
                colSpan={header.colSpan}
                rowSpan={rowSpan}
                className={cn(
                  "h-xl border-b border-border-secondary bg-neutral-50 px-lg py-2 font-sans text-text-quarterary",
                  isPinned && "sticky z-20",
                  isPinned === "left" && "left-0",
                  isPinned === "right" && "right-0",
                  isPinned === "right" && isLastPinned && "w-fit border-l",
                  isLastCell ? "" : "border-r",
                )}
                style={{
                  minWidth:
                    isPinned && isPinned === "left"
                      ? header.getSize()
                      : undefined,
                  ...pinnedPosition,
                }}
              >
                {flexRender(
                  header.column.columnDef.header,
                  header.getContext(),
                )}
              </th>
            );
          })}
        </tr>
      ))}
    </thead>
  );
};

export default TableColumn;
