import React from "react";
import classNames from "classnames";
import { Tooltip } from "@material-tailwind/react";
import { ReactComponent as EditIcon } from "../assets/icons/Button/edit-icon.svg";
import { ReactComponent as DetailIcon } from "../assets/icons/Button/detail-icon.svg";
import { ReactComponent as DeleteIcon } from "../assets/icons/Button/delete-icon.svg";
import { ReactComponent as DeleteDisabledIcon } from "../assets/icons/Button/delete-disabled-icon.svg";
import { ReactComponent as EditDisabledIcon } from "../assets/icons/Button/edit-disabled-icon.svg";
import { ReactComponent as DownloadIcon } from '../assets/icons/download-icon.svg'
import { ReactComponent as DetailIconBlue } from '../assets/icons/detail-icon-blue.svg'
import { ReactComponent as DownloadDisabled } from '../assets/icons/download-icon-disabled.svg'
import { ReactComponent as BurgerDotIcon } from '../assets/icons/burgers-dot.svg'

const btnIcons = {
  edit: <EditIcon />,
  detail: <DetailIcon />,
  delete: <DeleteIcon />,
  download: <DownloadIcon />,
  detailIconBlue: <DetailIconBlue />,
  deleteDisabled: <DeleteDisabledIcon />,
  editDisabled: <EditDisabledIcon />,
  downloadDisabled: <DownloadDisabled />,
  burgerDot: <BurgerDotIcon />
};
const btnTypes = {
  outlineWarning: `bg-bg-warningPrimary border border-button-warningBorder`,
  outlineInfo: `bg-bg-infoPrimary border border-button-infoBorder`,
  outlineError: `bg-bg-errorSecondary border border-button-errorBorder`,
  disabled: `bg-bg-disabled border-border-disableSubtle`,
  outlineBlue: `bg-bg-infoPrimary border border-border-info`,
  outlineSucced: `bg-bg-successPrimary border border-border-successSolid`,
  transparent: `bg-bg-primary`
};

const sizes = {
  xsmall: "h-8 p-1.5 rounded-md justify-center items-center gap-2 inline-flex",
  small: "h-8 p-1.5 rounded-md justify-center items-center gap-2 inline-flex",
  medium: "h-11 p-2.5 rounded-lg justify-center items-center gap-2 inline-flex",
  large: "h-14 p-4 rounded-lg justify-center items-center gap-2 inline-flex",
};

export const ButtonIcon = ({
  icon,
  disabled,
  onClick,
  type,
  className,
  size,
}) => {
  const buttonClasses = classNames(
    btnTypes[type] || btnTypes.outlineInfo,
    sizes[size] || sizes.medium
  );
  return (
    <Tooltip
      content={
        icon === "edit" || icon === "editDisabled"
          ? "Ubah"
          : icon === "detail"
          ? "Detail"
          : icon === "delete" || icon === "deleteDisabled"
          ? "Hapus" 
          : icon === "download" || icon === "downloadDisabled"
          ? "Download"
          : icon === "detailIconBlue" || icon === "detailIconDisabled"
          ? "Detail"
          : icon === "burgerDotIcon" 
          ? "" 
          : ""
      }
      placement="bottom"
    >
      <button
        className={`${buttonClasses} ${className} h-8 p-sm rounded-md justify-center items-center gap-2 inline-flex`}
        disabled={disabled}
        onClick={onClick}
      >
        <div
          className={
            size === "medium" || size === "large" ? "w-6 h-6" : "w-5 h5"
          }
        >
          {btnIcons[icon]}
        </div>
      </button>
    </Tooltip>
  );
};
