import React from "react";
import NumberFormat from 'react-number-format'

const sizes = {
  xsmall: { input: "h-7 rounded-sm", icon: "h-5 w-5" },
  small: { input: "h-8 rounded-sm", icon: "h-5 w-5" },
  medium: { input: "h-11 rounded-md", icon: "h-5 w-5" },
  large: { input: "h-14 rounded-md", icon: "h-6 w-6" },
};

const InputNumber = ({
  placeholder,
  value,
  onChange,
  color,
  size,
  type,
  className,
  componentLeft,
  componentRight,
  disabled,
  props,
  placeholderSize,
  decimalScale,
  onValueChange
}) => {
  const height = sizes[size]["input"]
  const inputRef = React.createRef()

  return (
    <div className="relative">
      <div className="absolute inset-y-0 left-0 flex items-center pl-lg focus:outline-none">
        {componentLeft}
      </div>

      <NumberFormat
        getInputRef={inputRef}
        thousandSeparator="."
        decimalSeparator=","
        allowNegative={false}
        fixedDecimalScale={true}
        pattern="[0-9]*"
        type="text"
        onValueChange={onValueChange}
        decimalScale={decimalScale}
        disabled={disabled}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        className={`${height}  w-full border border-${color}-500 ${componentLeft && type === "tel" ? 'pl-[108px]' : componentLeft && type !== "tel" ? 'pl-5xl' : 'pl-lg'}  ${componentRight ? 'pr-5xl' : 'pr-lg'} ${className} text-text-primary px-sm py-xs justify-start items-center gap-xxs inline-flex focus:outline-none focus:border-primary-400 focus:ring focus:ring-primary-200 placeholder:text-text-placeholder ${placeholderSize === 'medium' && 'placeholder:text-md'} `}
      />

      <div className="absolute inset-y-0 right-0 flex items-center pr-lg focus:outline-none">
        {componentRight}
      </div>
    </div>
  )
}

export default InputNumber