/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  Typography,
  CardBody,
  CardFooter,
  Popover,
  PopoverContent,
  PopoverHandler,
  Chip,
} from "@material-tailwind/react";
import {
  PlusIcon,
  ChevronUpDownIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";

import { Button } from "../../atoms/Button";
import Input from "../../atoms/Input";
import Pagination from "../../atoms/Pagination";
import AddUserModal from "../../molecules/user/AddUserModal";
import EditUserModal from "../../molecules/user/EditUserModal";
import DropdownWithSearchInput from "../../atoms/DropdownWithSearchInput";
import { FaCheckCircle } from "react-icons/fa";
import { FiFilter } from "react-icons/fi";

import api from "../../api/configApi";
import ConfirmDialog from "../../atoms/ConfirmDialog";
import AlertNotification from "../../atoms/AlertNotification";
import { ReactComponent as EmptyIcon } from "../../assets/icons/emptyIconn.svg";
import LoadingAnimation from "../../atoms/LoadingAnimation";
import * as Sentry from "@sentry/react";
import { ReactComponent as ArrowUp } from "../../assets/icons/arrowupblue.svg";
import { ReactComponent as ArrowDown } from "../../assets/icons/arrowdownblue.svg";
import { countryData } from "../../data/Country";
import moment from "moment";
import { ButtonIcon } from "../../atoms/ButtonIcon";

const TABLE_HEAD = [
  "No",
  "Nama Lengkap",
  "Nomor Handphone",
  "Role",
  "Region",
  "Unit",
  "Aktivitas Terakhir",
  "Aksi",
];

const User = () => {
  const [selectedCountry, setSelectedCountry] = useState("ID");

  const [searchTerm, setSearchTerm] = useState("");
  const [searchDisplay, setSearchDisplay] = useState("");
  const [isSearchProcess, setIsSearchProcess] = useState(false);

  const [users, setUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isAddModalOpen, setAddModalOpen] = useState(false);
  const [isEditModalOpen, setEditModalOpen] = useState(false);

  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const [selectedDeleteUserId, setSelectedDeleteUserId] = useState(null);
  const [selectedEditUserId, setSelectedEditUserId] = useState(null);
  const [isDeleteSuccess, setDeleteSuccess] = useState(false);
  const [isDeleteError, setDeleteError] = useState(false);

  const sortableColumns = ["", "fullName", "phoneNumber", "role.name", "region.name", "unit.name", "lastSeen"];
  const [sortBy, setSortBy] = useState("fullName");
  const [descending, setDescending] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isFetchError, setIsFetchError] = useState(false);

  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [countFilter, setCountFilter] = useState(0);
  const [units, setUnits] = useState([]);
  const [selectedUnit, setSelectedUnit] = useState(null);
  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState(null);
  const [searchRole, setSearchRole] = useState("");
  const [searchUnit, setSearchUnit] = useState("");
  const [checkedRolesString, setCheckedRolesString] = useState("");
  const [checkedUnitsString, setCheckedUnitsString] = useState("");
  const [lastLabelRole, setLastLabelRole] = useState("");
  const [lastLabelUnit, setLastLabelUnit] = useState("");
  const [lastLabelRegion, setLastLabelRegion] = useState("");
  const [checkedRoles, setCheckedRoles] = useState([]);
  const [checkedUnits, setCheckedUnits] = useState([]);
  const [implementFilter, setImplementFilter] = useState(false);
  const [reloadFilter, setReloadFilter] = useState(false);

  const [regions, setRegions] = useState([]);
  const [searchRegions, setSearchRegions] = useState([]);
  const [checkedRegions, setCheckedRegions] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [checkedRegionString, setCheckedRegionsString] = useState("");
  const [profile, setProfile] = useState(null);

  const [showAll, setShowAll] = useState(Array(users.length).fill(false));

  const handleResetFilter = () => {
    setImplementFilter(false);
    setIsOpenFilter(false);
    setSelectedRole("");
    setSelectedUnit("");
    setCheckedRoles([]);
    setCheckedRolesString("");
    setCheckedRegions([]);
    setCheckedRegionsString("");
    setCheckedUnits([]);
    setCheckedUnitsString("");
    setCountFilter(0);
  };

  const handleTerapkanFilter = () => {
    setCountFilter(0);
    let count = 0;

    if (implementFilter === true) {
      setReloadFilter(true);
    }

    if (checkedRolesString !== "") {
      count = count + 1;
    }

    if (checkedUnitsString !== "") {
      count = count + 1;
    }

    if (checkedRegionString !== "") {
      count = count + 1;
    }

    setCountFilter(count);
    setImplementFilter(true);
    setCurrentPage(1);
  };

  useEffect(() => {
    setReloadFilter(false);
  }, [reloadFilter]);

  const handleFilterUnit = (val) => {
    setSelectedUnit(val.id);
  };

  const handleFilterRole = (val) => {
    setSelectedRole(val.id);
  };

  const handleFilterRegion = (val) => {
    setSelectedRegion(val.id);
  };

  const handleOpenDeleteDialog = (userId) => {
    setSelectedDeleteUserId(userId);
    setDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setSelectedDeleteUserId(null);
    setDeleteDialogOpen(false);
    reloadData();
  };

  const reloadData = async () => {
    try {
      const params = {
        itemPerPage: 10,
        search: searchTerm,
        currentPage: currentPage - 1,
        sortBy: sortBy,
        descending: descending,
      };

      if (implementFilter === true || reloadFilter === true) {
        params.role = checkedRolesString ? checkedRolesString : "";
        params.unit = checkedUnitsString ? checkedUnitsString : "";
        params.region = checkedRegionString ? checkedRegionString : "";
      }
      const response = await api.get("/v1/users", { params });
      setUsers(response.data.contents);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const handleConfirmDelete = async () => {
    try {
      await api.delete(`/v1/users/${selectedDeleteUserId}`);
      handleCloseDeleteDialog();

      if (reloadData()) {
        reloadData();
      }
      setDeleteSuccess(true);
      setTimeout(() => setDeleteSuccess(false), 2000);
    } catch (error) {
      Sentry.captureException(error);
      setDeleteError(true);
      setTimeout(() => setDeleteError(false), 2000);
    }
  };

  const handleOpenEditModal = (userId) => {
    setSelectedEditUserId(userId);
    setEditModalOpen(true);
  };

  const handleCloseEditModal = () => {
    setSelectedEditUserId(null);
    setEditModalOpen(false);
  };

  const handleAddClick = () => {
    setAddModalOpen(true);
  };

  const handleAddModalClose = () => {
    setAddModalOpen(false);
  };

  useEffect(() => {
    setIsSearchProcess(true);
    const fetchData = async () => {
      try {
        setLoading(true);
        const params = {
          itemPerPage: 10,
          search: searchTerm,
          currentPage: currentPage - 1,
          sortBy: sortBy,
          descending: descending,
        };

        if (implementFilter === true || reloadFilter === true) {
          params.role = checkedRolesString ? checkedRolesString : "";
          params.unit = checkedUnitsString ? checkedUnitsString : "";
          params.region = checkedRegionString ? checkedRegionString : "";
        }
        const response = await api.get("/v1/users", { params });
        setUsers(response.data.contents);
        setTotalPages(response.data.totalPages);
        setIsFetchError(false);
      } catch (error) {
        Sentry.captureException(error);
        setIsFetchError(true);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [
    currentPage,
    searchTerm,
    sortBy,
    descending,
    implementFilter,
    reloadFilter,
  ]);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await api.get("/v1/users/me");
        setProfile(response?.data);
      } catch (error) {
        console.log("error", error);
      }
    };

    fetchUser();
  }, [isOpenFilter]);

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const response = await api.get("/v1/roles/dropdown", {
          params: {
            search: searchRole,
          },
        });
        setRoles(response.data.contents);
      } catch (error) {
        console.error("Error fetching roles:", error);
      }
    };
    fetchRoles();
  }, [isOpenFilter, searchRole]);


  useEffect(() => {
    const fetchRegion = async () => {
      try {
        const response = await api.get("/v1/regions/dropdown", {
          params: {
            search: searchRegions,
          },
        });
        setRegions(response?.data?.contents);
      } catch (error) {
        console.error("Error fetching roles:", error);
      }
    };

    fetchRegion();
  }, [isOpenFilter, searchRegions]);

  useEffect(() => {
    const fetchUnits = async () => {
      try {
        const response = await api.get("/v1/units/dropdown", {
          params: {
            search: searchUnit,
            descending: false,
          },
        });
        setUnits(response.data.contents);
      } catch (error) {
        console.error("Error fetching roles:", error);
      }
    };

    fetchUnits();
  }, [isOpenFilter, searchUnit]);

  const handleSort = (column) => {
    if (sortableColumns.includes(column)) {
      if (sortBy === column) {
        // Toggle descending if the same column is clicked
        setDescending(!descending);
      } else {
        // Change sorting column and set default descending to false
        setSortBy(column);
        setDescending(false);
      }
    }
  };

  const handleSearchChange = (e) => {
    setIsSearchProcess(!isSearchProcess);
    const keywordSearch = e.target.value;
    if (
      typeof keywordSearch === "string" &&
      keywordSearch.trim() !== "" &&
      keywordSearch.startsWith("08")
    ) {
      let formattedPhone = keywordSearch;

      formattedPhone = formattedPhone.replace(/^0+/, "");

      // Jika phone tidak mengandung kode negara, tambahkan countryCode
      if (!formattedPhone.startsWith(countryData[selectedCountry].code)) {
        formattedPhone = countryData[selectedCountry].code + formattedPhone;
      }
      setSearchTerm(formattedPhone);
    } else {
      setSearchTerm(keywordSearch);
    }

    setSearchDisplay(keywordSearch);

    setIsSearchProcess(false);
    handlePageChange(1);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleShowAll = (index) => {
    const updateShowAll = [...showAll];
    updateShowAll[index] = !updateShowAll[index];
    setShowAll(updateShowAll);
  };

  return (
    <div className={`flex h-auto overflow-hidden`}>
      {/* Main Content */}
      <div className="flex flex-col flex-1 overflow-hidden">
        {/* Main Content Container */}
        <div className="p-4">
          <Card className="h-full w-full border border-border-secondary shadow-none">
            <CardHeader
              floated={false}
              shadow={false}
              className="p-md border-none"
            >
              <Typography className="text-xl font-bold text-text-primary mb-4">
                Pengguna
              </Typography>
              <div className="grid grid-cols-4 gap-8">
                <div className="text-left">
                  <Input
                    type="text"
                    id="search"
                    placeholder="Pencarian.."
                    className="max-w-[340px]"
                    value={searchDisplay}
                    onChange={handleSearchChange}
                    size="medium"
                    componentLeft={<MagnifyingGlassIcon className="h-5 w-5" />}
                    componentRight={
                      !isSearchProcess && (
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                          className="animate-spin fill-neutral-400 max-w-sm"
                        >
                          <path
                            d="M10.72,19.9a8,8,0,0,1-6.5-9.79A7.77,7.77,0,0,1,10.4,4.16a8,8,0,0,1,9.49,6.52A1.54,1.54,0,0,0,21.38,12h.13a1.37,1.37,0,0,0,1.38-1.54,11,11,0,1,0-12.7,12.39A1.54,1.54,0,0,0,12,21.34h0A1.47,1.47,0,0,0,10.72,19.9Z"
                            className="spinner_6kVp"
                          />
                        </svg>
                      )
                    }
                  />
                </div>
                <div>
                  <Popover
                    placement="bottom"
                    open={isOpenFilter}
                    handler={() => setIsOpenFilter(!isOpenFilter)}
                  >
                    <PopoverHandler className="cursor-pointer">
                      <div>
                        <button
                          className={`${
                            countFilter > 0
                              ? "border-button-infoBorder"
                              : "border-neutral-300"
                          } p-5 h-11 px-xl py-2.5 justify-center items-center gap-md inline-flex text-base font-medium leading-normal rounded-md bg-white hover:bg-neutral-50 border text-text-secondary focus:shadow-sm focus:shadow-gray-300 hover:border-border-secondary`}
                        >
                          <div
                            className={`justify-center min-w-6 w-full items-center gap-2.5 flex cursor-pointer ${
                              countFilter > 0
                                ? "text-fg-infoPrimary"
                                : "text-text-secondary"
                            }`}
                          >
                            <div className={`w-auto font-medium`}>Filter</div>
                          </div>
                          <div className="w-6 h-6 relative flex items-center">
                            <FiFilter
                              className={`h-5 w-5 ${
                                countFilter > 0 && "text-fg-infoPrimary"
                              }`}
                            />
                          </div>
                          {countFilter > 0 && (
                            <div className="w-6 h-6 px-3 py-1 bg-fg-infoPrimary rounded-full justify-center items-center gap-1 inline-flex">
                              <div className="text-white text-xs font-medium leading-[18px]">
                                {countFilter}
                              </div>
                            </div>
                          )}
                        </button>
                      </div>
                    </PopoverHandler>

                    <PopoverContent className="top-[210px] left-[500px] z-50 w-[576px]">
                      <Typography className="flex justify-between font-normal text-[11px] text-text-disabled leading-none text-quarterary w-full min-h-full">
                        Filter berdasarkan
                      </Typography>
                      <Typography className="flex justify-between font-medium text-xs text-text-primary leading-[18px] w-full min-h-full mt-6">
                        Role
                      </Typography>

                      <div className="mt-2">
                        <DropdownWithSearchInput
                          placeholder="Pilih role"
                          placeholderInputSearch="Cari Role"
                          options={roles}
                          onSelect={handleFilterRole}
                          search={searchRole}
                          setSearch={setSearchRole}
                          onSelectString={setCheckedRolesString}
                          lastLabel={lastLabelRole}
                          setLastLabel={setLastLabelRole}
                          positionClassName="top-[335px] left-[520px]"
                          selectCheckBox={checkedRoles}
                          setSelectCheckbox={setCheckedRoles}
                        />
                      </div>

                      {profile?.level === "REGION" ? (
                        <div />
                      ) : (
                        <>
                          <Typography className="flex justify-between font-medium text-xs text-text-primary leading-[18px] w-full min-h-full mt-6">
                            Region
                          </Typography>
                          <div className="mt-2">
                            <DropdownWithSearchInput
                              placeholder="Pilih region"
                              placeholderInputSearch="Cari region"
                              options={regions}
                              onSelect={handleFilterRegion}
                              search={searchRegions}
                              setSearch={setSearchRegions}
                              onSelectString={setCheckedRegionsString}
                              lastLabel={lastLabelRegion}
                              setLastLabel={setLastLabelRegion}
                              positionClassName="top-[335px] left-[520px]"
                              selectCheckBox={checkedRegions}
                              setSelectCheckbox={setCheckedRegions}
                            />
                          </div>
                        </>
                      )}

                      <Typography className="flex justify-between font-medium text-xs text-text-primary leading-[18px] w-full min-h-full mt-6">
                        Unit
                      </Typography>
                      <div className="mt-2">
                        <DropdownWithSearchInput
                          placeholder="Pilih unit"
                          placeholderInputSearch="Cari Unit"
                          options={units}
                          onSelect={handleFilterUnit}
                          search={searchUnit}
                          setSearch={setSearchUnit}
                          onSelectString={setCheckedUnitsString}
                          lastLabel={lastLabelUnit}
                          setLastLabel={setLastLabelUnit}
                          positionClassName="top-[335px] left-[520px]"
                          selectCheckBox={checkedUnits}
                          setSelectCheckbox={setCheckedUnits}
                        />
                      </div>

                      <div className="border-t border-gray-300 my-4" />

                      <div className="divide-y flex w-full gap-8 justify-between mt-4">
                        <div onClick={handleResetFilter}>
                          <Typography className="flex justify-between items-center text-sm text-text-primary font-medium leading-tight w-full min-h-full cursor-pointer">
                            Atur Ulang
                          </Typography>
                        </div>

                        <Button
                          size={"small"}
                          label="Terapkan"
                          onClick={handleTerapkanFilter}
                          className="cursor-pointer"
                        />
                      </div>
                    </PopoverContent>
                  </Popover>
                </div>
                <div></div>
                <div className="text-center flex justify-end">
                  <Button
                    label="Tambah pengguna"
                    size="medium"
                    type="btnPrimary"
                    onClick={handleAddClick}
                    componentRight={<PlusIcon className="h-5 w-5" />}
                  />
                </div>
              </div>
            </CardHeader>
            {loading ? (
              <div className="flex justify-center items-center h-screen">
                <LoadingAnimation />
              </div>
            ) : (
              <>
                {users?.length === 0 || isFetchError ? (
                  <div className=" flex justify-center items-center h-screen mr-3">
                    <div>
                      <div className="flex justify-center">
                        <EmptyIcon className="ml-[30px]" />
                      </div>
                      <Typography className="text-xl text-text-secondary font-semibold p-4 text-center">
                        Data tidak ditemukan
                      </Typography>

                      <Typography className="flex justify-between text-text-quarterary font-normal leading-none opacity-70 w-full min-h-full">
                        Data yang Anda cari tidak ditemukan. Coba kata kunci
                        lain
                      </Typography>
                    </div>
                  </div>
                ) : (
                  <>
                    <CardBody className="p-0 overflow-x-scroll scrollbar-hide">
                      <table className="w-full min-w-max table-auto text-left">
                        <thead>
                          <tr>
                            {TABLE_HEAD.map((head, index) => (
                              <th
                                key={head}
                                className={`border-y border-border-secondary bg-neutral-50 py-2 px-lg  ${
                                  index === 7
                                    ? "sticky right-0 ml-4 z-10 p-0 shadow-[rgba(0,0,10,0.2)_-3px_0_12px_-5px] border-l"
                                    : ""
                                }`}
                              >
                                <Typography
                                  variant="small"
                                  className="flex justify-between font-semibold text-text-quarterary leading-none"
                                >
                                  {/* {head}{" "} */}
                                  {profile?.level === "REGION" && index === 4
                                    ? null
                                    : head}
                                  {profile?.level === "REGION" &&
                                  index === 4 ? null : (
                                    <>
                                      {index !== 0 &&
                                        index !== TABLE_HEAD.length - 1 && (
                                          <button
                                            onClick={() =>
                                              handleSort(sortableColumns[index])
                                            }
                                            className="focus:outline-none ml-1"
                                          >
                                            <ChevronUpDownIcon
                                              strokeWidth={2}
                                              className={`h-4 w-4 ${
                                                descending
                                                  ? "transform rotate-180"
                                                  : ""
                                              }`}
                                            />
                                          </button>
                                        )}
                                    </>
                                  )}
                                </Typography>
                              </th>
                            ))}
                          </tr>
                        </thead>

                        <tbody>
                          {users.map((user, index) => {
                            const isLast = index === users.length - 1;
                            const classes = isLast
                              ? "p-lg"
                              : "p-lg border-b border-border-secondary";

                            return (
                              <tr key={user.id}>
                                <td className={classes}>
                                  <div className="flex items-center gap-3">
                                    <Typography className="text-text-primary text-sm font-medium leading-tight">
                                      {(currentPage - 1) * 10 + index + 1}
                                    </Typography>
                                  </div>
                                </td>
                                <td className={`${classes} max-w-[250px]`}>
                                  <Typography className="text-text-primary text-sm font-medium leading-tight">
                                    {user.fullName}
                                  </Typography>
                                </td>
                                <td className={classes}>
                                  <Typography className="text-text-primary text-sm font-medium leading-tight">
                                    {user.phoneNumber || "-"}
                                  </Typography>
                                </td>
                                <td className={classes}>
                                  <Typography className="text-text-primary text-sm font-medium leading-tight">
                                    {user.role?.labels || "-"}
                                  </Typography>
                                </td>
                                <td className={classes}>
                                  {profile?.level === "REGION" ? (
                                    <div />
                                  ) : (
                                    <div className="w-max">
                                      <Chip
                                        size="sm"
                                        variant="ghost"
                                        value={user?.region?.labels || "-"}
                                        className={`text-sm font-inter font-normal leading-[18px] border normal-case ${
                                          user?.region?.labels === "Jawa Timur"
                                            ? "text-green-600 border-green-200 bg-green-25"
                                            : "text-blue-800 border-blue-200 bg-blue-50"
                                        }`}
                                      />
                                    </div>
                                  )}
                                </td>
                                <td className={classes}>
                                  <Typography className="text-text-primary text-sm font-medium leading-tight">
                                    {user?.units?.length !== undefined ? (
                                      <>
                                        {showAll[index]
                                          ? user?.units?.map((item, index) => (
                                              <div key={index}>
                                                {item.labels}
                                              </div>
                                            ))
                                          : user?.units
                                              ?.slice(0, 2)
                                              .map((item, index) => (
                                                <div key={index}>
                                                  {item.labels}
                                                </div>
                                              ))}

                                        {user?.units?.length > 2 ? (
                                          <>
                                            <div
                                              className="text-text-infoPrimary cursor-pointer flex"
                                              onClick={() =>
                                                handleShowAll(index)
                                              }
                                            >
                                              {showAll[index] ? (
                                                <>
                                                  Lebih Sedikit{" "}
                                                  <span>
                                                    <ArrowUp />
                                                  </span>
                                                </>
                                              ) : (
                                                <>
                                                  Lihat {user?.units?.length - 2} Lainya{" "}
                                                  <span>
                                                    <ArrowDown />
                                                  </span>
                                                </>
                                              )}
                                            </div>
                                          </>
                                        ) : null}
                                      </>
                                    ) : (
                                      <Typography className="text-text-primary text-sm font-medium leading-tight">
                                        -
                                      </Typography>
                                    )}
                                  </Typography>
                                </td>
                                <td className={classes}>
                                  <Typography className="text-text-primary text-sm font-medium leading-tight">
                                    {user?.lastSeen
                                      ? moment(user?.lastSeen).format(
                                          "DD-MM-YYYY HH:mm:ss"
                                        )
                                      : "-"}
                                  </Typography>
                                </td>
                                <td
                                  className={`border-l border-b border-border-secondary bg-white sticky right-0 p-4 shadow-[rgba(0,0,10,0.2)_-3px_1px_12px_-5px]`}
                                >
                                  <div className="flex gap-2 w-max">
                                    <ButtonIcon
                                      className="z-25"
                                      onClick={() =>
                                        handleOpenEditModal(user.id)
                                      }
                                      icon="edit"
                                      type="outlineWarning"
                                      size="small"
                                    />
                                    <ButtonIcon
                                      className="z-25"
                                      onClick={() =>
                                        handleOpenDeleteDialog(user.id)
                                      }
                                      icon="delete"
                                      type="outlineError"
                                      size="small"
                                    />
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </CardBody>
                    <CardFooter className="p-sm items-center border-t border-blue-gray-50">
                      <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={handlePageChange}
                      />
                    </CardFooter>
                  </>
                )}
              </>
            )}
          </Card>
        </div>
      </div>
      <AddUserModal
        isOpen={isAddModalOpen}
        onClose={handleAddModalClose}
        onReloadData={reloadData}
      />
      <EditUserModal
        isOpen={isEditModalOpen}
        onClose={handleCloseEditModal}
        userId={selectedEditUserId}
        onReloadData={reloadData}
        setSelectedEditUserId={setSelectedEditUserId}
      />

      <ConfirmDialog
        isOpen={isDeleteDialogOpen}
        onClose={handleCloseDeleteDialog}
        onConfirm={handleConfirmDelete}
        title="Hapus pengguna"
        content="Anda yakin ingin melanjutkan tindakan ini?"
        btnLabel="Hapus"
        btnType="btnError"
      />

      {isDeleteSuccess && (
        <AlertNotification
          icon={<FaCheckCircle />}
          color="green"
          message="Data pengguna berhasil di hapus."
        />
      )}

      {isDeleteError && (
        <AlertNotification
          icon={<FaCheckCircle />}
          color="red"
          message="Terjadi kesalahan saat menghapus pengguna."
        />
      )}
    </div>
  );
};

export default User;
