/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Card,
  CardHeader,
  Typography,
  CardBody,
  CardFooter,
  Chip,
} from "@material-tailwind/react";
import {
  PlusIcon,
  ChevronUpDownIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";

import { Button } from "../../../../atoms/Button";
import Input from "../../../../atoms/Input";
import Pagination from "../../../../atoms/Pagination";
import { FaCheckCircle } from "react-icons/fa";
import api from "../../../../api/configApi";
import DropdownFilterStatus from "../../../../atoms/DropdownFilterStatus";
import { ReactComponent as EmptyIcon } from "../../../../assets/icons/emptyIconn.svg";
import LoadingAnimation from "../../../../atoms/LoadingAnimation";
import AlertNotification from "../../../../atoms/AlertNotification";
import * as Sentry from "@sentry/react";
import { ReactComponent as EditIconMaster } from "../../../../assets/icons/edit_icon_master.svg";
import Cookies from "js-cookie";

const MasterBakul = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [isSearchProcess, setIsSearchProcess] = useState(false);

  const [bakuls, setBakuls] = useState([]);
  const [regions, setRegions] = useState([]);
  const [cities, setCities] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [isFetchError, setIsFetchError] = useState(false);
  const [isOpenFilterStatus, setIsOpenFilterStatus] = useState(false);

  const sortableColumns = [
    "",
    "name",
    "phone",
    "bakulType",
    "city.province.name",
    "city.name",
    "address",
    "isActive",
  ];
  const [sortBy, setSortBy] = useState("");
  const [descending, setDescending] = useState(true);

  const [selectedStatus, setSelectedStatus] = useState();

  const [checkedRegions, setCheckedRegions] = useState([]);
  const [checkedCities, setCheckedCities] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const alertAdd = JSON.parse(localStorage.getItem("responseAddSuccess"));
  const alertEdit = JSON.parse(localStorage.getItem("responseSuccessEdit"));

  const [notifAdd, setNotfAdd] = useState(alertAdd);
  const [notifEdit, setNotfEdit] = useState(alertEdit);
  const [labelStatus, setLabelStatus] = useState(null);
  
  let currentPageCookies = parseInt(Cookies.get('current-page-bakul'));
  const [currentPage, setCurrentPage] = useState(currentPageCookies ? currentPageCookies : 1);
  Cookies.set("current-page-bakul", currentPage);

  const TABLE_HEAD = [
    "No",
    "Bakul",
    "Nomor Telepon",
    "Tipe Bakul",
    "Provinsi",
    "Kabupaten/Kota",
    "Alamat",
    "Status",
    "Aksi",
  ];

  const handleOpenEditModal = (bakulId) => {
    navigate(`/master/bakul/edit-bakul/${bakulId}`);
  };

  const handleAddClick = () => {
    navigate("/master/bakul/tambah-bakul");
  };

  useEffect(() => {
    setTimeout(() => {
      setNotfAdd(false);
      localStorage.clear("responseAddSuccess");
    }, 4000);
  }, [notifAdd]);

  useEffect(() => {
    setTimeout(() => {
      setNotfEdit(false);
      localStorage.clear("responseSuccessEdit");
    }, 4000);
  }, [notifEdit]);

  useEffect(() => {
    setIsSearchProcess(true);
    const fetchData = async () => {
      setIsLoading(true);
      try {
        let stringProvinceList =
          checkedRegions.length > 0 ? checkedRegions.join(",") : null;

        let stringCitiesList =
          checkedCities.length > 0 ? checkedCities.join(",") : null;

        const params = {
          itemPerPage: 10,
          search: searchTerm,
          currentPage: currentPage - 1,
          sortBy: sortBy,
          descending: descending,
        };

        // Menambahkan bakul ke params hanya jika stringBakulList tidak bernilai null
        if (stringProvinceList !== null) {
          params.province = stringProvinceList;
        }

        if (stringCitiesList !== null) {
          params.city = stringCitiesList;
        }

        if (selectedStatus !== null) {
          params.isActive = selectedStatus;
        }
        const response = await api.get("/v1/bakuls", { params });

        setBakuls(response.data.contents);
        setTotalPages(response.data.totalPages);
        setIsFetchError(false);
      } catch (error) {
        Sentry.captureException(error);
        setIsFetchError(true);
      } finally {
        setIsLoading(false);
      }
    };

    const dataFetchDropdownRegion = async () => {
      try {
        const responseRegionDropdown = await api.get(
          "/v1/regions/id/province/dropdown"
        );
        setRegions(responseRegionDropdown.data);
      } catch (error) {
        console.log(error);
      }
    };

    const fetchCities = async () => {
      const data = await api.get(`/v1/regions/id/cities/dropdown`);
      setCities(data?.data);
    };

    fetchCities();

    dataFetchDropdownRegion();
    fetchData();
  }, [
    checkedRegions,
    checkedCities,
    currentPage,
    searchTerm,
    sortBy,
    descending,
    selectedStatus,
  ]);

  const handleSort = (column) => {
    if (sortableColumns.includes(column)) {
      if (sortBy === column) {
        // Toggle descending if the same column is clicked
        setDescending(!descending);
      } else {
        // Change sorting column and set default descending to false
        setSortBy(column);
        setDescending(false);
      }
    }
  };

  const handleSearchChange = (e) => {
    setIsSearchProcess(!isSearchProcess);
    const keywordSearch = e.target.value;
    setSearchTerm(keywordSearch);
    setIsSearchProcess(false);
    handlePageChange(1);
  };

  const handlePageChange = (newPage) => {
    Cookies.set('current-page-bakul', newPage);
    setCurrentPage(parseInt(Cookies.get('current-page-bakul')));
  };

  const handleFilterStatus = (val) => {
    setSelectedStatus(val);
    setIsOpenFilterStatus(false);
    handlePageChange(1);
    if (val === true) {
      setLabelStatus("Aktif");
    } else if (val === false) {
      setLabelStatus("Tidak aktif");
    } else if (val === null) {
      setLabelStatus("Semua Status");
    }
  };

  const statusBakuls = [
    {
      id: 1,
      IsActive: true,
      labels: ["Aktif"],
      labelsPlaceholder: "Aktif",
    },
    {
      id: 2,
      IsActive: false,
      labels: ["Tidak aktif"],
      labelsPlaceholder: "Tidak altif",
    },
  ];

  const handleResetFilterStatus = () => {
    setSelectedStatus("");
    setLabelStatus("");
    setIsOpenFilterStatus(false);
  };

  return (
    <div className={`flex h-auto overflow-hidden`}>
      <div className="flex flex-col flex-1 overflow-hidden">
        <div className="p-4">
          <Card className="w-full h-auto border border-border-secondary shadow-none p-0 m-0">
            <CardHeader
              floated={false}
              shadow={false}
              className="p-2 m-0 border-none w-full"
            >
              <div className="flex justify-between px-4 pt-4 mb-1">
                <Typography className="text-xl font-bold text-text-primary">
                  Bakul
                </Typography>

              
              </div>

              <div className="grid grid-cols-4 px-4 gap-4 mb-4">
                <div className="text-left">
                  <Input
                    type="text"
                    id="search"
                    placeholder="Pencarian.."
                    className="max-w-[300px]"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    size="medium"
                    componentLeft={<MagnifyingGlassIcon className="h-5 w-5" />}
                    componentRight={
                      !isSearchProcess && (
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                          className="animate-spin fill-neutral-400 max-w-sm"
                        >
                          <path
                            d="M10.72,19.9a8,8,0,0,1-6.5-9.79A7.77,7.77,0,0,1,10.4,4.16a8,8,0,0,1,9.49,6.52A1.54,1.54,0,0,0,21.38,12h.13a1.37,1.37,0,0,0,1.38-1.54,11,11,0,1,0-12.7,12.39A1.54,1.54,0,0,0,12,21.34h0A1.47,1.47,0,0,0,10.72,19.9Z"
                            className="spinner_6kVp"
                          />
                        </svg>
                      )
                    }
                  />
                </div>
                <div className="relative w-[208px]">
                  <DropdownFilterStatus
                    data={statusBakuls}
                    handleSelect={handleFilterStatus}
                    popoverContentPosition={"top-[240px] left-[760px]"}
                    label={labelStatus}
                    selectedStatus={selectedStatus}
                    onReset={handleResetFilterStatus}
                    isOpenFilter={isOpenFilterStatus}
                    setIsOpenFilter={setIsOpenFilterStatus}
                  />
                </div>
                <div></div>
                <div className="text-center flex justify-end">
                  <Button
                    label="Tambah bakul"
                    size="medium"
                    type="btnPrimary"
                    onClick={handleAddClick}
                    componentRight={<PlusIcon className="h-5 w-5" />}
                  />
                </div>
              </div>
            </CardHeader>

            {isLoading ? (
              <div className="flex justify-center items-center h-screen">
                <LoadingAnimation />
              </div>
            ) : (
              <>
                {bakuls?.length === 0 || isFetchError ? (
                  <div className=" flex justify-center items-center h-screen">
                    <div>
                      <div className="flex justify-center">
                        <EmptyIcon className="ml-[30px]" />
                      </div>

                      <Typography
                        variant="h2"
                        className="text-xl font-semibold p-4 text-center text-text-secondary"
                      >
                        Data tidak ditemukan
                      </Typography>

                      <Typography
                        variant="medium"
                        className="flex justify-center font-normal leading-none w-full text-text-secondary min-h-full bg-gray-950"
                      >
                        Data yang Anda cari tidak ditemukan. Coba kata kunci
                        lain
                      </Typography>
                    </div>
                  </div>
                ) : (
                  <>
                    <CardBody className="p-0 overflow-x-scroll">
                      <table className="w-full min-w-max table-auto text-left">
                        <thead>
                          <tr>
                            {TABLE_HEAD.map((head, index) => (
                              <th
                                key={head}
                                className={`border-y border-border-secondary bg-neutral-50 py-2 px-lg ${index === 8 ? 'sticky right-0 bg-neutral-50 z-10' : ''}`}
                              >
                                <Typography className="flex justify-between text-sm font-semibold text-text-quarterary leading-tight">
                                  {head}{" "}
                                  {index !== 0 &&
                                    index !== TABLE_HEAD.length - 1 && (
                                      <button
                                        onClick={() =>
                                          handleSort(sortableColumns[index])
                                        }
                                        className="focus:outline-none ml-1"
                                      >
                                        <ChevronUpDownIcon
                                          strokeWidth={2}
                                          className={`h-4 w-4 ${
                                            descending
                                              ? "transform rotate-180"
                                              : ""
                                          }`}
                                        />
                                      </button>
                                    )}
                                </Typography>
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {bakuls.map((bakul, index) => {
                            const isLast = index === bakuls.length - 1;
                            const classes = isLast
                              ? "p-lg"
                              : "p-lg border-b border-border-secondary";

                            return (
                              <tr key={bakul.id}>
                                <td className={classes}>
                                  <div className="flex items-center gap-3">
                                    <Typography className="text-text-primary text-sm font-medium leading-tight">
                                      {(currentPage - 1) * 10 + index + 1}.
                                    </Typography>
                                  </div>
                                </td>
                                <td className={classes + " max-w-[350px]"}>
                                  <Typography className="text-text-primary text-sm font-medium leading-tight">
                                    {bakul.name}
                                  </Typography>
                                </td>
                                <td className={classes}>
                                  <Typography className="text-text-primary text-sm font-medium leading-tight">
                                    {bakul.phone}
                                  </Typography>
                                </td>
                                <td className={classes}>
                                  <Typography className="text-text-primary text-sm font-medium leading-tight">
                                    {bakul.bakulType?.labels || "-"}
                                  </Typography>
                                </td>
                                <td className={classes}>
                                  <Typography className="text-text-primary text-sm font-medium leading-tight">
                                    {bakul?.province?.labels || "-"}
                                  </Typography>
                                </td>
                                <td className={classes}>
                                  <Typography className="text-text-primary text-sm font-medium leading-tight">
                                    {bakul.city?.labels || "-"}
                                  </Typography>
                                </td>
                                <td className={classes + " max-w-[350px]"}>
                                  <Typography className="text-text-primary text-sm font-medium leading-tight">
                                    {bakul.address || "-"}
                                  </Typography>
                                </td>
                                <td className={classes}>
                                  <div className="w-max">
                                    <Chip
                                      size="sm"
                                      variant="ghost"
                                      value={
                                        bakul.isActive ? "Aktif" : "Tidak Aktif"
                                      }
                                      className={`text-text-primary text-xs leading-[18px] border ${
                                        bakul.isActive
                                          ? "text-green-600 border-green-200 bg-green-50"
                                          : "text-neutral-500 border-border-secondary bg-neutral-50"
                                      }`}
                                      icon={
                                        bakul.isActive ? (
                                          <span className="mx-auto mt-1 block h-2 w-2 rounded-full bg-green-500" />
                                        ) : (
                                          <span className="mx-auto mt-1 block h-2 w-2 rounded-full bg-gray-500" />
                                        )
                                      }
                                    />
                                  </div>
                                </td>
                                <td className={`${classes} sticky right-0 bg-white`}>
                                  <div className="flex gap-6 w-max">
                                    <button
                                      className="flex items-center"
                                      onClick={() =>
                                        handleOpenEditModal(bakul.id)
                                      }
                                    >
                                      <EditIconMaster className="w-4 h-4" />
                                      <span className="text-text-secondary text-sm font-medium leading-tight">
                                        Ubah
                                      </span>
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </CardBody>
                    <CardFooter className="p-sm items-center border-t border-border-secondary">
                      <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={handlePageChange}
                      />
                    </CardFooter>
                  </>
                )}
              </>
            )}
          </Card>
        </div>
        {notifAdd && (
          <AlertNotification
            icon={<FaCheckCircle />}
            color="green"
            message="Data bakul berhasil ditambah."
          />
        )}

        {notifEdit && (
          <AlertNotification
            icon={<FaCheckCircle />}
            color="green"
            message="Data bakul berhasil diubah."
          />
        )}
      </div>
    </div>
  );
};

export default MasterBakul;
