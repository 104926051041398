import { useQuery } from "@tanstack/react-query";

import api from "../../api/configApi";

const useGetSequence = (houseId) => {
  const query = useQuery({
    queryKey: ["get-rearing-period-sequences", { houseId }],
    queryFn: async () => {
      const response = await api.get("/v1/rearingPeriods/sequences", {
        params: {
          houseId,
        },
      });
      return response.data;
    },
    enabled: !!houseId,
  });

  return query;
};

export default useGetSequence;
