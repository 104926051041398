import { ID, SG, US } from "country-flag-icons/react/1x1";

export const countryData = {
  ID: {
    name: "Indonesia",
    labelCode: "+62",
    code: "62",
    flag: <ID title="Indonesia" className="h-5 w-5 object-cover rounded-full" />,
  },

  SG: {
    name: "Singapore",
    labelCode: "+65",
    code: "65",
    flag: <SG title="Singapore" className="h-5 w-5 object-cover rounded-full" />,
  },

  US: {
    name: "United States",
    labelCode: "+1",
    code: "1",
    flag: <US title="United States" className="h-5 w-5 object-cover rounded-full" />,
  },
};