import React from "react";

import { CalendarIcon } from "@heroicons/react/24/outline";
import { format } from "date-fns";
import { id } from "date-fns/locale";
import { FiFilter } from "react-icons/fi";

import { Button } from "../../../../../atoms/V2/Button";
import { Calendar } from "../../../../../atoms/V2/DatePicker";
import { DropdownSingleWithSearch } from "../../../../../atoms/V2/DropdownSingleWithSearch";
import { Input } from "../../../../../atoms/V2/Input";
import { Label } from "../../../../../atoms/V2/Label";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../../../../atoms/V2/Popover";
import { Separator } from "../../../../../atoms/V2/Separator";
import { cn } from "../../../../../shared/utils";
import { useMasterPeriode } from "../hook";

const MasterPeriodeFilter = () => {
  const {
    query,
    selectedPPL,
    setSelectedPPL,
    initialCapacity,
    setInitialCapacity,
    finalCapacity,
    setFinalCapacity,
    docInDate,
    setDocInDate,
    handleResetFilter,
    handleApplyFilter,
    queryPPL,
    filteredItems,
  } = useMasterPeriode();

  const countFilteredItems = (items) => {
    const { docInInitial, docInFinal, ...rest } = items;
    let count = 0;

    if (docInInitial || docInFinal) {
      count += 1;
    }
    count += Object.values(rest).filter((value) => value).length;

    return count;
  };

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant="btnOutlinedGrey"
          disabled={queryPPL.isLoading || query.isLoading}
        >
          <FiFilter className="mr-1 size-5 text-[#484B4F]" />
          Filter
          {filteredItems && countFilteredItems(filteredItems) > 0 && (
            <span className="ml-[6px] flex h-[18px] w-[18px] items-center justify-center rounded-full bg-[#5A5C60] text-xs text-white">
              {countFilteredItems(filteredItems)}
            </span>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[544px]">
        <div className="flex w-full flex-col items-center gap-3">
          <div className="flex w-full flex-col gap-2">
            <Label>Tanggal DOC-in</Label>
            <Popover>
              <PopoverTrigger asChild>
                <Button
                  variant={"btnOutlinedGrey"}
                  className={cn("w-full justify-between text-left font-normal")}
                >
                  {docInDate?.from ? (
                    docInDate?.to ? (
                      <>
                        {format(docInDate.from, "dd/MM/yyyy", {
                          locale: id,
                        })}{" "}
                        -{" "}
                        {format(docInDate.to, "dd/MM/yyyy", {
                          locale: id,
                        })}
                      </>
                    ) : (
                      format(docInDate.from, "dd/MM/yyyy", {
                        locale: id,
                      })
                    )
                  ) : (
                    <span>Pilih tanggal DOC-in</span>
                  )}

                  <CalendarIcon className="mr-2 h-4 w-4" />
                </Button>
              </PopoverTrigger>
              <PopoverContent className="w-auto bg-white p-0" align="start">
                <Calendar
                  mode="range"
                  defaultMonth={docInDate?.from}
                  selected={docInDate}
                  onSelect={setDocInDate}
                  numberOfMonths={2}
                  autoFocus
                />
              </PopoverContent>
            </Popover>
          </div>
          <div className="flex w-full flex-col gap-2">
            <Label>PPL</Label>
            <DropdownSingleWithSearch
              options={queryPPL.data || []}
              disabled={queryPPL.isLoading}
              placeholder="Pilih nama PPL"
              placeholderSearch="Cari nama PPL"
              selectedOption={
                queryPPL.data?.find((item) => item.id === selectedPPL) || null
              }
              onSelect={(value) => {
                setSelectedPPL(value?.id || null);
              }}
              content={(option) => (
                <div className="flex max-w-80 flex-col py-[10px]">
                  <p className="text-sm text-text-secondary">
                    {option?.labels}
                  </p>
                </div>
              )}
            />
          </div>
          <div className="flex w-full flex-col gap-2">
            <Label>Populasi</Label>
            <div className="flex items-center justify-between gap-2">
              <div>
                <Input
                  placeholder="Kapasitas minimal"
                  className="w-full flex-shrink-0"
                  value={initialCapacity}
                  onChange={(e) => setInitialCapacity(e.target.value)}
                  rightComponent={
                    <span className="text-sm text-text-placeholder">Ekor</span>
                  }
                />
              </div>
              <Separator className="w-6 bg-border-primary" />
              <div>
                <Input
                  placeholder="Kapasitas maksimal"
                  className="w-full flex-shrink-0"
                  value={finalCapacity}
                  onChange={(e) => setFinalCapacity(e.target.value)}
                  rightComponent={
                    <span className="text-sm text-text-placeholder">Ekor</span>
                  }
                />
              </div>
            </div>
          </div>
          <div className="flex w-full items-center justify-between border-t-2 border-border-primary pt-3">
            <Button
              size="small"
              variant="ghost"
              className="w-fit"
              onClick={handleResetFilter}
            >
              Atur Ulang
            </Button>
            <Button size="small" className="w-fit" onClick={handleApplyFilter}>
              Terapkan
            </Button>
          </div>
        </div>
      </PopoverContent>
    </Popover>
  );
};

export default MasterPeriodeFilter;
