import React from "react";

import MasterPeriodeEditFeature from "./feature";
import { MasterPeriodeEditStore } from "./hook";

const MasterPeriodeEdit = () => {
  return (
    <MasterPeriodeEditStore>
      <MasterPeriodeEditFeature />
    </MasterPeriodeEditStore>
  );
};

export default MasterPeriodeEdit;
