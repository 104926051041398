/* eslint-disable no-unused-vars */
import React, { useRef, useState } from "react";
import { ReactComponent as ChevronDown } from "../assets/icons/chevron_down.svg";
import { CheckboxCust } from "./Checkbox";
import { Button } from "./Button";
import {
  Popover,
  PopoverContent,
  PopoverHandler,
  Typography,
} from "@material-tailwind/react";

const DropdownFilterCheckbox = ({
  options,
  onSelect,
  placeholder,
  setCheckedItems,
  popoverContentClassName,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedCheckBox, setSelectedCheckBox] = useState("");
  const [selectedAll, setSelectedAll] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedLabel, setSelectedLabel] = useState("");
  const inputRef = useRef();

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };
  const handleSelect = (selectedOption) => {
    setSelectedOption(selectedOption?.id);
    setSelectedLabel(
      selectedOption?.labelsPlaceholder
        ? selectedOption?.labelsPlaceholder
        : selectedOption?.labels
    );

    if (selectedOption && selectedOption === "selectAll") {
      setSelectedAll(!selectedAll);
      setSelectedCheckBox(
        selectedAll ? [] : options?.map((item) => item.labels)
      );
    } else {
      let updateCheckBox;
      if (selectedCheckBox && selectedCheckBox.includes(selectedOption?.id)) {
        updateCheckBox = selectedCheckBox.filter(
          (check) => check !== selectedOption?.id
        );
      } else {
        updateCheckBox = [...selectedCheckBox, selectedOption?.id];
      }

      setSelectedCheckBox(updateCheckBox);
      setSelectedAll(updateCheckBox?.length === selectedOption.length);
      onSelect(
        updateCheckBox && updateCheckBox?.length === selectedOption.length
      );
    }
  };

  setCheckedItems(selectedCheckBox);

  const handleClickOutside = (event) => {
    if (inputRef.current && !inputRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  document.addEventListener("mousedown", handleClickOutside);

  return (
    <Popover
      placement="bottom"
      open={isOpen}
      handler={() => setIsOpen(!isOpen)}
    >
      <PopoverHandler className="cursor-pointer p-0 m-0 px-xl border border-border-secondary min-w-[160px] w-auto h-11 justify-center items-center gap-md inline-flex rounded-md bg-white hover:bg-neutral-50 hover:border-neutral-300">
        <button>
          <div className="flex justify-between min-w-6 w-full items-center cursor-pointer font-medium text-text-text-tertiary">
            <Typography className="text-base text-text-primary leading-normal font-normal">
              {selectedCheckBox.length === 0
                ? placeholder
                : selectedCheckBox?.length === options.length
                ? placeholder
                : `${selectedLabel.substring(0, 20) + "..."}`}
            </Typography>
            <div className="w-6 h-6 relative flex items-center">
              <ChevronDown
                className={`absolute ${
                  isOpen ? "transform rotate-180" : ""
                } text-gray-500`}
                onClick={handleToggle}
              />
            </div>
          </div>
        </button>
      </PopoverHandler>

      <PopoverContent
        className={`${
          popoverContentClassName
            ? popoverContentClassName
            : `top-[225px] left-[570px]`
        } w-full overflow-y-auto max-h-[250px] max-w-[350px] gap-4 z-50`}
      >
        <ul>
          {options?.map((item, index) => (
            <li
              key={index}
              className="rounded-lg px-2 py-1 hover:bg-gray-100 cursor-pointer"
            >
              <div
                className="flex justify-between"
                onClick={() => handleSelect(item)}
              >
                <label className="flex items-center cursor-pointer text-neutral-700 text-md">
                  {item.labels}
                </label>
                <CheckboxCust
                  onChange={() => handleSelect(item)}
                  checked={selectedCheckBox?.includes(item?.id)}
                />{" "}
              </div>
            </li>
          ))}
        </ul>
      </PopoverContent>
    </Popover>
  );
};

export default DropdownFilterCheckbox;
