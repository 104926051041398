/* eslint-disable no-unused-expressions */
/* eslint-disable no-unused-vars */

import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
} from "@material-tailwind/react";
import Input from "../../../../../atoms/Input";
import api from "../../../../../api/configApi";
import { Button } from "../../../../../atoms/Button";
import AlertNotification from "../../../../../atoms/AlertNotification";
import ConfirmDialog from "../../../../../atoms/ConfirmDialog";
import { FaTrash, FaPlus } from "react-icons/fa";
import { Stepper, Step } from "@material-tailwind/react";
import DropdownStockSearch from "../../../../../atoms/DropdownStockSearch";
import { FaCheckCircle } from "react-icons/fa";
import dayjs from "dayjs";
import { ReactComponent as ChevronLeft } from "../../../../../assets/icons/chevron-left.svg";

const EditRecordingHarian = ({ onReloadData }) => {
  const navigate = useNavigate();
  const {  periodId, id } = useParams();
  const [isEditSuccess, setEditSuccess] = useState(false);
  const [isEditError, setIsEditError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isEditDialogOpen, setEditDialogOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [isLastStep, setIsLastStep] = useState(false);
  const [isFirstStep, setIsFirstStep] = useState(false);
  const [listProducts, setListProducts] = useState([]);
  const [searchProduct, setSearchProduct] = useState("");
  const [selectedInDate, setSelectedInDate] = useState(null);
  const [dayAge, setDayAge] = useState(null);
  const [step, setStep] = useState(1);
  const [standardPerformance, setStandardPerformance] = useState([]);
  const [culling, setCulling] = useState("");
  const [mortalitas, setMortalitas] = useState("");
  const [bobotTimbang, setBobotTimbang] = useState("");
  const [populasi, setPopulasi] = useState("");
  const [jumlahPanen, setJumlahPanen] = useState("");
  const [flaggingFetchDailyRecord, setFlaggingFetchDailyRecord] =
    useState(false);

  const isSubmitStep2Disabled = bobotTimbang === "" || populasi === "";

  const [validationJumlahPemberianError, setValidationJumlahPemberianError] =
    useState([""]);
  const [validationPopulationError, setValidationPopulationError] =
    useState("");
  const [validationBobotTimbangError, setValidationBobotTimbangError] =
    useState("");
  const [validationCullingError, setValidationCullingError] = useState("");
  const [validationMortalityError, setValidationMortalityError] = useState("");
  const [productStock, setProductStock] = useState([]);
  const [productIdExclude, setProductIdExclude] = useState([]);
  const [productFeed, setProductFeed] = useState([]);
  const [jumlahProduk, setJumlahProduk] = useState([""]);

  const productIdExcludeString = productIdExclude.join(",");

  const [step1Data, setStep1Data] = useState({
    produkList: [],
  });

  const isSubmitStep1Disabled =
  productFeed?.length === 0 || productFeed?.every((feed) => feed === "") ||
  !(
    validationJumlahPemberianError?.length === 0 ||
    validationJumlahPemberianError?.every((error) => error === "")
  ) ||
  (jumlahProduk?.some((jmlProduk) => jmlProduk === ""));

  const handleOpenEditDialog = () => {
    setEditDialogOpen(true);
  };

  const handleCloseEditDialog = () => {
    setEditDialogOpen(false);
  };

  useEffect(() => {
    const fetchDailyRecord = async () => {
      try {
        const response = await api.get(`/v1/dailyRecords/${id}`);

        setCulling(response.data.culling);
        setMortalitas(response.data.mortality);
        setBobotTimbang(response.data.samplingWeight);
        setPopulasi(response.data.samplingPopulation);
        setJumlahPanen(response.data.harvest);
        setSelectedInDate(response?.data?.recordDate);
        setDayAge(response?.data?.day);

        const initialUsedFeeds = response.data.usedFeeds.map((usedFeed) => ({
          usedFeeds: [
            {
              feed: {
                id: usedFeed.id,
                label: usedFeed.labels,
                type: "feed",
                stock: (usedFeed.stock + usedFeed.usedFeed.qtyUsed),
              },
              jumlah: usedFeed.usedFeed.qtyUsed / 50,
            },
          ],
          usedOvks: [],
        }));

        const initialUsedOvks = response.data.usedOvks.map((usedOvk) => ({
          usedFeeds: [],
          usedOvks: [
            {
              ovk: {
                id: usedOvk.id,
                label: usedOvk.labels,
                type: "ovk",
                stock: usedOvk.stock + usedOvk.usedOvk.qtyUsed,
              },
              jumlah: usedOvk.usedOvk.qtyUsed,
            },
          ],
        }));
        

        // Combine usedFeeds and usedOvks stocks into a single array
        const allStocks = [
          ...response.data.usedFeeds.map((usedFeed) => (usedFeed.stock + usedFeed.usedFeed.qtyUsed)),
          ...response.data.usedOvks.map((usedOvk) => usedOvk.stock + usedOvk.usedOvk.qtyUsed),
        ];

        const allIdExclude = [
          ...response.data.usedFeeds.map((usedFeed) => usedFeed.id),
          ...response.data.usedOvks.map((usedOvk) => usedOvk.id),
        ];

        const allProductUsed = [
          ...response.data.usedFeeds.map((usedFeed) => (usedFeed.usedFeed.qtyUsed / 50).toString()),
          ...response.data.usedOvks.map((usedOvk) => usedOvk.usedOvk.qtyUsed.toString()),
        ];

        const allProductFeed = [
          ...response.data.usedFeeds.map((usedFeed) => usedFeed.labels),
        ];

        const allDefaultValidationError= [
          ...response.data.usedFeeds.map(() => ""),
          ...response.data.usedOvks.map(() => ""),
        ];


        setValidationJumlahPemberianError(allDefaultValidationError);
        setProductFeed(allProductFeed)
        setJumlahProduk(allProductUsed);
        setProductIdExclude(allIdExclude);
        setProductStock(allStocks);

        const updatedStep1Data = (prevStep1Data) => ({
          ...prevStep1Data,
          produkList: [...initialUsedFeeds, ...initialUsedOvks],
        });

        setStep1Data(updatedStep1Data);
        setFlaggingFetchDailyRecord();
      } catch (error) {
        console.log(error);
      }
    };

    fetchDailyRecord();
  }, [id]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const params = {
          search: searchProduct,
          periodId: periodId,
          exclude: productIdExcludeString,
        };
        const response = await api.get("/v1/sapronak/stocks", { params });
        setListProducts(response?.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchProducts();
  }, [searchProduct, periodId, productIdExcludeString]);

  useEffect(() => {
    const fetchPerformanceStandard = async () => {
      try {
        const params = {
          age: dayAge,
        };
        const response = await api.get("/v1/performanceStandard/daily", {
          params,
        });
        setStandardPerformance(response?.data?.contents);
      } catch (error) {
        console.log(error);
      }
    };

    fetchPerformanceStandard();
  }, [dayAge]);

  const handleSelectFeed = (selectedFeed, index) => {

    setProductFeed((prevProductFeed) => {
      const newProductFeed = [...prevProductFeed];
      newProductFeed[index] = selectedFeed.labels;
      return newProductFeed;
    });

    setProductIdExclude((prevProductIdExclude) => {
      const newProductIdExclude = [...prevProductIdExclude];
      newProductIdExclude[index] = selectedFeed.id;
      return newProductIdExclude;
    });

    // Update productStock sesuai dengan index
    setProductStock((prevProductStock) => {
      const newProductStock = [...prevProductStock];
      newProductStock[index] = selectedFeed.stock;
      return newProductStock;
    });

    // Update state step1Data saat feed dipilih
    setStep1Data((prevStep1Data) => {
      const updatedProdukList = [...prevStep1Data.produkList];

      if (updatedProdukList[index]) {
        // Jika jenis produkList di indeks tersebut sudah ada, update
        if (updatedProdukList[index].usedFeeds) {
          updatedProdukList[index] = {
            ...updatedProdukList[index],
            usedFeeds: [
              {
                feed: {
                  id: selectedFeed.id,
                  label: selectedFeed.labels,
                  type: selectedFeed.type,
                  stock: selectedFeed.stock,
                },
                jumlah: "",
              },
            ],
          };
        } else {
          // Jika jenis produkList di indeks tersebut belum ada, tambahkan
          updatedProdukList[index] = {
            usedFeeds: [
              {
                feed: { id: selectedFeed.id, label: selectedFeed.labels },
                jumlah: "",
              },
            ],
          };
        }
      } else {
        // Jika jenis produkList di indeks tersebut belum ada, tambahkan
        updatedProdukList[index] = {
          usedFeeds: [
            {
              feed: {
                id: selectedFeed.id,
                label: selectedFeed.labels,
                type: selectedFeed.type,
                stock: selectedFeed.stock,
              },
              jumlah: "",
            },
          ],
        };
      }

      // Kosongkan usedOvks jika sudah ada data sebelumnya
      if (updatedProdukList[index].usedOvks) {
        updatedProdukList[index].usedOvks = [];

        setJumlahProduk((prevJumlahProduks) => {
          const newJumlahProduks = [...prevJumlahProduks];
          newJumlahProduks[index] = "";
          return newJumlahProduks;
        });
      }

      return {
        ...prevStep1Data,
        produkList: updatedProdukList,
      };
    });
  };

  const handleSelectOvk = (selectedOvk, index) => {

    setProductIdExclude((prevProductIdExclude) => {
      const newProductIdExclude = [...prevProductIdExclude];
      newProductIdExclude[index] = selectedOvk.id;
      return newProductIdExclude;
    });

    // Update productStock sesuai dengan index
    setProductStock((prevProductStock) => {
      const newProductStock = [...prevProductStock];
      newProductStock[index] = selectedOvk.stock;
      return newProductStock;
    });

    setProductFeed((prevFeeds) => {
      const newFeeds = [...prevFeeds];
      newFeeds[index] = "";
      return newFeeds;
    });

    // Update state step1Data saat ovk dipilih
    setStep1Data((prevStep1Data) => {
      const updatedProdukList = [...prevStep1Data.produkList];

      if (updatedProdukList[index]) {
        // Jika jenis produkList di indeks tersebut sudah ada, update
        if (updatedProdukList[index].usedOvks) {
          updatedProdukList[index] = {
            ...updatedProdukList[index],
            usedOvks: [
              {
                ovk: {
                  id: selectedOvk.id,
                  label: selectedOvk.labels,
                  type: selectedOvk.type,
                  stock: selectedOvk.stock,
                },
                jumlah: "",
              },
            ],
          };
        } else {
          // Jika jenis produkList di indeks tersebut belum ada, tambahkan
          updatedProdukList[index] = {
            usedOvks: [
              {
                ovk: {
                  id: selectedOvk.id,
                  label: selectedOvk.labels,
                  type: selectedOvk.type,
                  stock: selectedOvk.stock,
                },
                jumlah: "",
              },
            ],
          };
        }
      } else {
        // Jika jenis produkList di indeks tersebut belum ada, tambahkan
        updatedProdukList[index] = {
          usedOvks: [
            {
              ovk: {
                id: selectedOvk.id,
                label: selectedOvk.labels,
                type: selectedOvk.type,
                stock: selectedOvk.stock,
              },
              jumlah: "",
            },
          ],
        };
      }

      // Kosongkan usedFeeds jika sudah ada data sebelumnya
      if (updatedProdukList[index].usedFeeds) {
        updatedProdukList[index].usedFeeds = [];
  
        setJumlahProduk((prevJumlahProduks) => {
          const newJumlahProduks = [...prevJumlahProduks];
          newJumlahProduks[index] = "";
          return newJumlahProduks;
        });

      }


      return {
        ...prevStep1Data,
        produkList: updatedProdukList,
      };
    });
  };

  const handleNext = () => !isLastStep && setActiveStep((cur) => cur + 1);
  const handlePrev = () => !isFirstStep && setActiveStep((cur) => cur - 1);

  const nextStep = () => {
    setStep(step + 1);
    handleNext();
  };

  const prevStep = () => {
    setStep(step - 1);
    handlePrev();
  };

  const saveDailyRecords = async () => {
    setIsSubmitting(true);

    const { produkList } = step1Data;

    const requestBody = {
      rearingPeriod: { id: periodId },
      mortality: mortalitas,
      culling: culling,
      samplingWeight: bobotTimbang,
      samplingPopulation: populasi,
      harvest: jumlahPanen,
      usedFeeds: [],
      usedOvks: [],
    };

    produkList.forEach((produk) => {
      if (produk.usedFeeds[0]) {
        requestBody.usedFeeds.push({
          feed: { id: produk.usedFeeds[0].feed.id },
          qtyUsed: parseInt(produk.usedFeeds[0].jumlah * 50, 10),
        });
      }

      if (produk.usedOvks[0]) {
        requestBody.usedOvks.push({
          ovk: { id: produk.usedOvks[0].ovk.id },
          qtyUsed: parseInt(produk.usedOvks[0].jumlah, 10),
        });
      }
    });

    try {
      const response = await api.put(`/v1/dailyRecords/${id}`, requestBody);
      console.log("Data berhasil disimpan:", response);
      setEditSuccess(true);

      handleCloseEditDialog();
      navigate(`/periode-budidaya/periode-berjalan/${periodId}/dataharian`);
      localStorage.setItem(
        "responseSuccessEdit",
        JSON.stringify(!isEditSuccess)
      );
      localStorage.setItem("settingType", "data");

      if (onReloadData) {
        onReloadData();
      }

      return response;
    } catch (error) {
      handleCloseEditDialog();
      setIsSubmitting(false);
      console.error("Gagal mengubah data harian", error.response);

      setIsEditError(true);
      setErrorMessage(
        error.response?.data?.error?.name || error.response?.data?.error
      );
      setTimeout(() => setIsEditError(false), 3000);
    }
  };

  const handleBack = () => {
    navigate(`/periode-budidaya/periode-berjalan/${periodId}/dataharian/${id}`);
    localStorage.setItem("settingType", "data");
  };

  const calculateTotalPakan = () => {
    let totalPakan = 0;

    step1Data.produkList.forEach((produk) => {
      produk.usedFeeds.forEach((feed) => {
        // Menambahkan validasi jika jumlah tidak kosong
        if (feed.jumlah) {
          totalPakan += parseInt(feed.jumlah, 10);
        }
      });
    });

    return totalPakan;
  };

  const calculateTotalOvk = () => {
    let totalOvk = 0;

    step1Data.produkList.forEach((produk) => {
      produk.usedOvks.forEach((ovk) => {
        // Menambahkan validasi jika jumlah tidak kosong
        if (ovk.jumlah) {
          totalOvk += parseInt(ovk.jumlah, 10);
        }
      });
    });

    return totalOvk;
  };

  // Define the handleJumlahChange function outside of the JSX
  const handleJumlahChange = (e, index) => {
    const updatedList = [...step1Data.produkList];
    const selectedProduk = updatedList[index];

    const jumlahPemberian = e.target.value;

    if (
      (!isNaN(jumlahPemberian) && /^[0-9]+$/.test(jumlahPemberian)) ||
      jumlahPemberian === ""
    ) {
      if (
        selectedProduk.usedFeeds[0] &&
        selectedProduk.usedFeeds[0]?.feed?.type === "feed"
      ) {
        selectedProduk.usedFeeds[0].jumlah = jumlahPemberian;
        if (productStock[index]) {
          if (jumlahPemberian * 50 <= productStock[index]) {
            setValidationJumlahPemberianError((prevValidationJumlahErrors) => {
              const newValidationErrors = [...prevValidationJumlahErrors];
              newValidationErrors[index] = "";
              return newValidationErrors;
            });
          } else {
            setValidationJumlahPemberianError((prevValidationJumlahErrors) => {
              const newValidationErrors = [...prevValidationJumlahErrors];
              newValidationErrors[index] =
                "Jumlah pemberian pakan melebihi sisa stok";
              return newValidationErrors;
            });
          }
        }
      } else if (
        selectedProduk.usedOvks[0] &&
        selectedProduk.usedOvks[0]?.ovk?.type === "ovk"
      ) {
        selectedProduk.usedOvks[0].jumlah = jumlahPemberian;

        if (productStock[index]) {
          if (jumlahPemberian <= productStock[index]) {
            setValidationJumlahPemberianError((prevValidationJumlahErrors) => {
              const newValidationErrors = [...prevValidationJumlahErrors];
              newValidationErrors[index] = "";
              return newValidationErrors;
            });
          } else {
            setValidationJumlahPemberianError((prevValidationJumlahErrors) => {
              const newValidationErrors = [...prevValidationJumlahErrors];
              newValidationErrors[index] =
                "Jumlah pemberian pakan melebihi sisa stok";
              return newValidationErrors;
            });
          }
        }
      }
      setStep1Data({
        ...step1Data,
        produkList: updatedList,
      });
      // setIsSubmitStep1Disabled(false);

      // setValidationJumlahPemberianError((prevValidationJumlahErrors) => {
      //   const newValidationErrors = [...prevValidationJumlahErrors];
      //   newValidationErrors[index] = "";
      //   return newValidationErrors;
      // });

      setJumlahProduk((prevJumlahProduks) => {
        const newJumlahProduks = [...prevJumlahProduks];

        if (jumlahPemberian === "") {
          // newJumlahProduks.splice(index, 1);
          newJumlahProduks[index] = "";
        } else {
          newJumlahProduks[index] = jumlahPemberian;
        }

        return newJumlahProduks;
      });
    } else {
      setValidationJumlahPemberianError((prevValidationJumlahErrors) => {
        const newValidationErrors = [...prevValidationJumlahErrors];
        newValidationErrors[index] = "Masukkan jumlah pemberian yang sesuai.";
        return newValidationErrors;
      });
    }
  };

  const handleCullingChange = (e) => {
    const inputValue = e.target.value;

    // Check if the input is a non-negative number
    if (
      (!isNaN(inputValue) && /^[0-9]+$/.test(inputValue)) ||
      inputValue === ""
    ) {
      setCulling(inputValue);
      setValidationCullingError("");
    } else {
      setValidationCullingError("Masukkan culling yang sesuai.");
    }
  };

  const handleMortalitasChange = (e) => {
    const inputValue = e.target.value;

    // Check if the input is a non-negative number
    if (
      (!isNaN(inputValue) && /^[0-9]+$/.test(inputValue)) ||
      inputValue === ""
    ) {
      setMortalitas(inputValue);
      setValidationMortalityError("");
    } else {
      setValidationMortalityError("Masukkan mortalitas yang sesuai.");
    }
  };

  const handlePopulasiChange = (e) => {
    const inputValue = e.target.value;

    // Check if the input is a non-negative number
    if (
      (!isNaN(inputValue) && /^[0-9]+$/.test(inputValue)) ||
      inputValue === ""
    ) {
      setPopulasi(inputValue);
      setValidationPopulationError("");
    } else {
      setValidationPopulationError("Masukkan populasi yang sesuai.");
    }
  };

  const handleBobotTimbangChange = (e) => {
    const inputValue = e.target.value;
    // Check if the input is a non-negative number
    if (
      (!isNaN(inputValue) && /^[0-9]+$/.test(inputValue)) ||
      inputValue === ""
    ) {
      setBobotTimbang(inputValue);
      setValidationBobotTimbangError("");
    } else {
      setValidationBobotTimbangError("Masukkan harga yang sesuai.");
    }
  };

  const handleDeleteProduct = (index) => {
    // Hapus produk pada produkList dan perbarui state step1Data
    setStep1Data((prevStep1Data) => {
      const updatedList = [...prevStep1Data.produkList];
      updatedList.splice(index, 1);
  
      return {
        ...prevStep1Data,
        produkList: updatedList,
      };
    });

    setProductFeed((prevProductFeed) => {
      const updatedProductFeed = [...prevProductFeed];
      updatedProductFeed.splice(index, 1);
      return updatedProductFeed.length === 0 ? [''] : updatedProductFeed;
    });

    setJumlahProduk((prevJumlahProduk) => {
      const updatedJumlahProduk = [...prevJumlahProduk];
      updatedJumlahProduk.splice(index, 1);
      return updatedJumlahProduk;
    });

    setProductIdExclude((prevProductIdExclude) => {
      const updatedProductIdExclude = [...prevProductIdExclude];
      updatedProductIdExclude.splice(index, 1);
      return updatedProductIdExclude;
    });
  
    setProductStock((prevProductStock) => {
      const updatedProductStock = [...prevProductStock];
      updatedProductStock.splice(index, 1);
      return updatedProductStock;
    });
  };
  
  return (
    <div className="flex justify-center p-4">
      <Card className="w-[800px] border">
        <CardHeader floated={false} shadow={false} className="rounded-b-none">
          <Typography className="text-xl font-semibold flex items-center text-text-primary">
            <div className="cursor-pointer pr-2" onClick={handleBack}>
              <ChevronLeft />
            </div>
            Ubah data harian
          </Typography>
          <Typography className="text-sm text-text-tertiary py-1 font-normal pl-8">
            {dayjs(selectedInDate).locale("id").format("DD MMMM YYYY")} | Hari
            ke-{dayAge}
          </Typography>
        </CardHeader>
        <CardBody className="flex justify-center border-t-2">
          <div className=" w-[500px] py-9 px-8">
            <Stepper
              activeStep={activeStep}
              isLastStep={(value) => setIsLastStep(value)}
              isFirstStep={(value) => setIsFirstStep(value)}
              lineClassName=""
              activeLineClassName="bg-blue-600"
            >
              <Step
                className="h-7 w-7 !bg-neutral-100 border-2 text-neutral-300 cursor-pointer"
                activeClassName="ring ring-blue-200 !bg-blue-600 text-white"
                completedClassName="!bg-blue-600 text-white"
              >
                1
                <div className="absolute pt-4 -bottom-[1.5rem] w-max text-center">
                  <Typography
                    color={activeStep === 0 ? "blue-gray" : "gray"}
                    className={
                      activeStep === 0
                        ? "text-sm font-semibold text-text-secondary"
                        : "text-sm font-semibold text-text-quarterary"
                    }
                  >
                    Pemberian pakan & OVK
                  </Typography>
                </div>
              </Step>
              <Step
                className="h-7 w-7 !bg-neutral-100 border-2 text-neutral-300 cursor-pointer"
                activeClassName="ring ring-blue-200 !bg-blue-600 text-white"
                completedClassName="!bg-blue-600 text-white"
              >
                2
                <div className="absolute pt-4 -bottom-[1.5rem] w-max text-center">
                  <Typography
                    color={activeStep === 1 ? "blue-gray" : "gray"}
                    className={
                      activeStep === 1
                        ? "text-sm font-semibold text-text-secondary"
                        : "text-sm font-semibold text-text-quarterary"
                    }
                  >
                    Deplesi & bobot
                  </Typography>
                </div>
              </Step>
              <Step
                className="h-7 w-7 !bg-neutral-100 border-2 text-neutral-300 cursor-pointer"
                activeClassName="ring ring-blue-200 !bg-blue-600 text-white"
                completedClassName="!bg-blue-600 text-white"
              >
                3
                <div className="absolute pt-4 -bottom-[1.5rem] w-max text-center">
                  <Typography
                    color={activeStep === 2 ? "blue-gray" : "gray"}
                    className={
                      activeStep === 2
                        ? "text-sm font-semibold text-text-secondary"
                        : "text-sm font-semibold text-text-quarterary"
                    }
                  >
                    Ringkasan
                  </Typography>
                </div>
              </Step>
            </Stepper>
          </div>
        </CardBody>
        {/* Konten Form */}
        {step === 1 && (
          <div>
            <Card className="border-none shadow-none">
              <CardBody className="p-5">
                <div className="mb-4">
                  <div></div>
                </div>
                <div className="w-[752px] h-px flex-col justify-center items-center inline-flex">
                  <div className="w-[752px] h-px relative bg-gray-200" />
                </div>
                <Typography className="text-xl font-semibold my-2 text-text-primary">
                  Pakan dan OVK
                </Typography>

                <Card
                  style={{ marginBottom: "20px" }}
                  className="border border-neutral-300 bg-neutral-100 shadow-none"
                >
                  {step1Data.produkList.map((produk, index) => (
                    <CardBody key={index}>
                      <div className="mt-4">
                        <div className="grid grid-cols-2">
                          <div className="col-span-1">
                            <label className="block text-sm font-medium text-text-secondary">
                              Produk{" "}
                              <span className="text-red-500 text-sm font-normal leading-tight">
                                *
                              </span>
                            </label>
                          </div>
                          <div className="col-span-1 text-right">
                            <Button
                              type="btnLinkRed"
                              label="Hapus"
                              size="small"
                              // onClick={() => {
                              //   const updatedList = [...step1Data.produkList];
                              //   updatedList.splice(index, 1);
                              //   setStep1Data({
                              //     ...step1Data,
                              //     produkList: updatedList,
                              //   });
                              // }}
                              onClick={() => handleDeleteProduct(index)}
                              componentLeft={<FaTrash className="m-1" />}
                            />
                          </div>
                        </div>
                        <DropdownStockSearch
                          id="name"
                          placeholder="Pilih Produk"
                          options={listProducts}
                          onSelectFeed={(selectedFeed) =>
                            handleSelectFeed(selectedFeed, index)
                          }
                          onSelectOvk={(selectedOvk) =>
                            handleSelectOvk(selectedOvk, index)
                          }
                          setSearch={(value) => setSearchProduct(value)}
                          search={
                            searchProduct === "" && produk.usedFeeds?.length > 0
                              ? produk.usedFeeds[0].feed.label
                              : searchProduct === "" &&
                                produk.usedOvks?.length > 0
                              ? produk.usedOvks[0].ovk.label
                              : searchProduct
                          }
                          size="medium"
                          type="text"
                          initialValue={
                            produk.usedFeeds?.length > 0
                              ? produk?.usedFeeds[0]
                              : produk.usedOvks?.length > 0
                              ? produk?.usedOvks[0]
                              : null
                          }
                        />
                      </div>
                      <div className="mb-4">
                        <div className="mt-6">
                          <label className="block text-sm font-medium text-text-secondary mb-2">
                            Jumlah pemberian pakan atau ovk{" "}
                            <span className="text-red-500 text-sm font-normal leading-tight">
                              *
                            </span>
                          </label>
                          <Input
                            type="text"
                            id={`jumlahPemberian-${index}`}
                            placeholder="Masukkan Jumlah Pemberian"
                            size="medium"
                            value={
                              produk.usedFeeds?.length > 0 &&
                              produk.usedFeeds[0].jumlah !== ""
                                ? produk.usedFeeds[0].jumlah
                                : produk.usedOvks?.length > 0 &&
                                  produk.usedOvks[0].jumlah !== ""
                                ? produk.usedOvks[0].jumlah
                                : ""
                            }
                            onChange={(e) => handleJumlahChange(e, index)}
                            componentRight={
                              produk.usedFeeds?.length > 0
                                ? "zak"
                                : produk.usedOvks?.length > 0
                                ? "item"
                                : ""
                            }
                          />

                          {validationJumlahPemberianError[index] && (
                            <p className="text-red-500 text-sm mt-2">
                              {validationJumlahPemberianError[index]}
                            </p>
                          )}
                        </div>
                      </div>
                    </CardBody>
                  ))}
                  <CardFooter className="border-t-2">
                    <Button
                      size="small"
                      type={listProducts.totalItems === 0 ? "btnLinkDisabled" : "btnLinkBlue"}
                      onClick={() => {
                        setStep1Data({
                          ...step1Data,
                          produkList: [
                            ...step1Data.produkList,
                            {
                              usedFeeds: [],
                              usedOvks: [],
                            },
                          ],
                        });
                      }}
                      label="Tambah produk"
                      componentLeft={<FaPlus className="w-4 h-4 mt-1"></FaPlus>}
                      disabled={listProducts.totalItems === 0 ? true : false}
                    />
                  </CardFooter>
                </Card>
              </CardBody>
              {/* Save Button */}
              <CardFooter className="flex justify-end border-t border-blue-gray-50 p-lg">
                <Button
                  label="Batal"
                  size="medium"
                  type="btnSecondary"
                  className="mr-md"
                  onClick={handleBack}
                />
                <Button
                  label="Selanjutnya"
                  disabled={isSubmitStep1Disabled || isSubmitting}
                  size="medium"
                  type={`${
                    isSubmitStep1Disabled || isSubmitting
                      ? "btnPrimaryDisabled"
                      : "btnPrimary"
                  }`}
                  onClick={() => nextStep()}
                />
              </CardFooter>
            </Card>
          </div>
        )}

        {step === 2 && (
          <div>
            <Card className="border-none shadow-none">
              <CardBody className="p-5">
                <Typography className="text-xl font-semibold my-2">
                  Deplesi
                </Typography>
                <Card
                  style={{ marginBottom: "20px" }}
                  className="border border-neutral-300 bg-neutral-100 shadow-none"
                >
                  <CardBody className="grid grid-cols-2 gap-4">
                    <div className="col-span-1">
                      <label className="block text-sm font-medium text-text-secondary mb-2">
                        Kematian
                      </label>
                      <Input
                        type="number"
                        placeholder="Kematian"
                        className="max-w-sm"
                        value={mortalitas}
                        size="medium"
                        onChange={handleMortalitasChange}
                      />
                      {validationMortalityError && (
                        <p className="text-red-500 text-sm mt-2">
                          {validationMortalityError}
                        </p>
                      )}
                    </div>
                    <div className="col-span-1">
                      <label className="block text-sm font-medium text-text-secondary mb-2">
                        Pemisahan
                      </label>
                      <Input
                        type="number"
                        placeholder="Pemisahan"
                        className="max-w-sm"
                        value={culling}
                        size="medium"
                        onChange={handleCullingChange}
                      />
                      {validationCullingError && (
                        <p className="text-red-500 text-sm mt-2">
                          {validationCullingError}
                        </p>
                      )}
                    </div>
                  </CardBody>
                </Card>

                <Typography className="text-xl font-semibold my-2">
                  Sampling
                </Typography>
                <Card
                  style={{ marginBottom: "20px" }}
                  className="border border-neutral-300 bg-neutral-100 shadow-none"
                >
                  <CardBody className="grid grid-cols-2 gap-4">
                    <div className="col-span-1">
                      <label className="block text-sm font-medium text-text-secondary mb-2">
                        Bobot Timbang{" "}
                        <span className="text-red-500 text-sm font-normal leading-tight">
                          *
                        </span>
                      </label>
                      <Input
                        type="number"
                        placeholder="Masukkan bobot timnbang"
                        className="max-w-sm"
                        size="medium"
                        value={bobotTimbang}
                        onChange={handleBobotTimbangChange}
                      />
                      {validationBobotTimbangError && (
                        <p className="text-red-500 text-sm mt-2">
                          {validationBobotTimbangError}
                        </p>
                      )}
                    </div>
                    <div className="col-span-1">
                      <label className="block text-sm font-medium text-text-secondary mb-2">
                        Populasi{" "}
                        <span className="text-red-500 text-sm font-normal leading-tight">
                          *
                        </span>
                      </label>
                      <Input
                        type="number"
                        placeholder="Masukkan populasi"
                        className="max-w-sm"
                        size="medium"
                        value={populasi}
                        onChange={handlePopulasiChange}
                      />
                      {validationPopulationError && (
                        <p className="text-red-500 text-sm mt-2">
                          {validationPopulationError}
                        </p>
                      )}
                    </div>
                  </CardBody>
                </Card>

                {/* Save Button */}
                <CardFooter className="flex justify-end border-t border-blue-gray-50 p-lg">
                  <Button
                    label="Kembali"
                    size="medium"
                    type="btnSecondary"
                    className="mr-md"
                    onClick={() => prevStep()}
                  />
                  <Button
                    label="Selanjutnya"
                    disabled={isSubmitStep2Disabled || isSubmitting}
                    size="medium"
                    type={`${
                      isSubmitStep2Disabled || isSubmitting
                        ? "btnPrimaryDisabled"
                        : "btnPrimary"
                    }`}
                    onClick={() => nextStep()}
                  />
                </CardFooter>
              </CardBody>
            </Card>
          </div>
        )}

        {step === 3 && (
          <div>
            <Card className="border-none shadow-none">
              <CardBody className="p-5">
                <Typography className="text-lg font-semibold my-2 text-text-primary">
                  Pakan
                </Typography>

                <Card className="p-3 border my-2 shadow-none border-neutral-200">
                  <CardHeader
                    floated={false}
                    shadow={false}
                    className="grid grid-cols-2 gap-4 rounded-none p-0 m-0"
                  >
                    <div className="col-span-1">
                      <Typography className="text-md font-semibold my-2 text-text-secondary">
                        Merk Pakan
                      </Typography>
                    </div>
                    <div className="col-span-1">
                      <Typography className="text-md font-semibold text-right my-2 text-text-secondary">
                        Berat
                      </Typography>
                    </div>
                  </CardHeader>
                  <CardBody className="border-t-2 rounded-none p-0 m-0 py-2">
                    {step1Data.produkList.every(
                      (produk) => !produk.usedFeeds[0]
                    ) ? (
                      <div className="my-2 text-text-secondary">
                        Tidak ada data
                      </div>
                    ) : (
                      step1Data.produkList.map(
                        (produk, index) =>
                          produk.usedFeeds[0] && (
                            <div
                              key={index}
                              className="grid grid-cols-2 gap-4 "
                            >
                              <div className="col-span-1">
                                <Typography className="text-sm font-normal my-2 text-text-secondary">
                                  {produk.usedFeeds[0]?.feed?.label}
                                </Typography>
                              </div>
                              <div className="col-span-1">
                                <Typography className="text-sm font-normal text-right my-2 text-text-secondary">
                                  {produk.usedFeeds[0]?.jumlah} zak
                                </Typography>
                              </div>
                            </div>
                          )
                      )
                    )}
                  </CardBody>
                  <CardFooter className="grid grid-cols-2 gap-4 border-t-2 border-dotted border-neutral-300 rounded-none p-0 m-0">
                    <div className="col-span-1 mt-3">
                      <Typography className="text-left text-md font-semibold text-text-primary">
                        Total
                      </Typography>
                    </div>
                    <div className="col-span-1 mt-3">
                      <Typography className="text-md font-semibold text-right text-text-primary">
                        {calculateTotalPakan()} zak
                      </Typography>
                    </div>
                  </CardFooter>
                </Card>

                <Typography className="text-lg font-semibold my-2 mt-6 text-text-primary">
                  OVK
                </Typography>

                <Card className="p-3 border my-2 shadow-none border-neutral-200">
                  <CardHeader
                    floated={false}
                    shadow={false}
                    className="grid grid-cols-2 gap-4 rounded-none p-0 m-0"
                  >
                    <div className="col-span-1">
                      <Typography className="text-md font-semibold my-2 text-text-secondary">
                        Merk OVK
                      </Typography>
                    </div>
                    <div className="col-span-1">
                      <Typography className="text-md font-semibold text-right my-2 text-text-secondary">
                        Qty
                      </Typography>
                    </div>
                  </CardHeader>
                  <CardBody className="border-t border-neutral-300 rounded-none m-0 p-0 py-2">
                    {step1Data.produkList.every(
                      (produk) => !produk.usedOvks[0]
                    ) ? (
                      <div className="m-0 text-neutral-700 my-2">
                        Tidak ada data
                      </div>
                    ) : (
                      step1Data.produkList.map(
                        (produk, index) =>
                          produk.usedOvks[0] && (
                            <div
                              key={index}
                              className="grid grid-cols-2 gap-4 "
                            >
                              <div className="col-span-1">
                                <Typography className="text-sm font-normal my-2">
                                  {produk.usedOvks[0]?.ovk?.label}
                                </Typography>
                              </div>
                              <div className="col-span-1">
                                <Typography className="text-sm font-normal text-right my-2 text-text-secondary">
                                  {produk.usedOvks[0]?.jumlah} item
                                </Typography>
                              </div>
                            </div>
                          )
                      )
                    )}
                  </CardBody>
                  <CardFooter className="m-0 p-0 grid grid-cols-2 gap-4 border-t-2 border-dotted rounded-none border-neutral-200">
                    <div className="col-span-1 mt-3">
                      <Typography className="text-left text-md font-semibold text-text-primary">
                        Total
                      </Typography>
                    </div>
                    <div className="col-span-1 mt-3">
                      <Typography className="text-md font-semibold text-right text-text-secondary">
                        {calculateTotalOvk()} item
                      </Typography>
                    </div>
                  </CardFooter>
                </Card>

                <Typography className="text-lg font-semibold my-2 mt-6 text-text-primary">
                  Deplesi
                </Typography>

                <Card className="p-3 border my-2 shadow-none border-neutral-200">
                  <CardHeader
                    floated={false}
                    shadow={false}
                    className="grid grid-cols-2 gap-2 rounded-none p-0 m-0"
                  >
                    <div className="col-span-1">
                      <Typography className="text-sm font-normal my-2 text-text-secondary">
                        Kematian
                      </Typography>
                    </div>
                    <div className="col-span-1">
                      <Typography className="text-sm font-normal text-right my-2 text-text-secondary">
                        {mortalitas || "0"} ekor
                      </Typography>
                    </div>
                    <div className="col-span-1">
                      <Typography className="text-sm font-normal my-2 text-text-secondary">
                        Pemisahan
                      </Typography>
                    </div>
                    <div className="col-span-1">
                      <Typography className="text-sm font-normal text-right my-2 text-text-secondary">
                        {culling || "0"} ekor
                      </Typography>
                    </div>
                  </CardHeader>
                  <CardBody className="grid grid-cols-2 gap-4 border-t-2 border-dotted border-neutral-300 rounded-none p-0 m-0">
                    <div className="col-span-1 my-3">
                      <Typography className="text-md font-semibold text-text-primary">
                        Total
                      </Typography>
                    </div>
                    <div className="col-span-1 my-3">
                      <Typography className="text-md font-semibold text-right text-red-500">
                        {parseInt(culling || 0, 10) +
                          parseInt(mortalitas || 0, 10)}{" "}
                        ekor
                      </Typography>
                    </div>
                  </CardBody>
                  <CardFooter className="grid grid-cols-2 gap-4 border-t-2 rounded-none text-text-secondary m-0 p-0">
                    <div className="col-span-1 my-3">
                      <Typography className="text-sm font-normal">
                        Std
                      </Typography>
                    </div>
                    <div className="col-span-1 my-3">
                      <Typography className="text-sm font-normal text-right text-text-secondary">
                        {standardPerformance[0].depletion} ekor
                      </Typography>
                    </div>
                  </CardFooter>
                </Card>

                <Typography className="text-lg font-semibold my-2 mt-6 text-text-primary">
                  BW
                </Typography>

                <Card className="p-3 border my-2 shadow-none border-neutral-200">
                  <CardBody className="grid grid-cols-2 gap-4 rounded-none p-0 m-0">
                    <div className="col-span-1">
                      <Typography className="text-sm font-normal text-text-secondary">
                        {populasi} ekor
                      </Typography>
                    </div>
                    <div className="col-span-1">
                      <Typography className="text-sm font-normal text-right text-text-secondary">
                        {bobotTimbang} g
                      </Typography>
                    </div>
                  </CardBody>
                  <CardFooter className="grid grid-cols-2 gap-4 border-none rounded-none m-0 p-0 mt-4">
                    <div className="col-span-1">
                      <Typography className="text-md font-semibold text-text-primary">
                        Rata-rata bobot timbang
                      </Typography>
                    </div>
                    <div className="col-span-1">
                      <Typography className="text-md font-semibold text-text-primary text-right">
                      {bobotTimbang?.length > 0 && populasi?.length > 0 && populasi > 0
                          ? (bobotTimbang / populasi).toLocaleString("id-ID")
                          : "0"}{" "} g
                      </Typography>
                    </div>
                  </CardFooter>
                </Card>

                <CardFooter className="flex justify-end border-t border-blue-gray-50 p-lg">
                  <Button
                    label="Kembali"
                    size="medium"
                    type="btnSecondary"
                    className="mr-md"
                    onClick={() => prevStep()}
                  />
                  <Button
                    label="Simpan"
                    size="medium"
                    type="btnPrimary"
                    onClick={handleOpenEditDialog}
                  />
                </CardFooter>
              </CardBody>
            </Card>
          </div>
        )}
      </Card>
      {/* Komponen dialog konfirmasi edit*/}
      <ConfirmDialog
        isOpen={isEditDialogOpen}
        onClose={handleCloseEditDialog}
        onConfirm={saveDailyRecords}
        title="Konfirmasi perubahan data"
        content="Pastikan data yang anda masukkan sudah benar, sebelum melanjutkan tindakan ini."
        btnLabel="Ya, lanjutkan"
        btnType={`${
          isSubmitting
            ? "btnPrimaryDisabled"
            : "btnPrimary"
        }`}
        disabled={isSubmitting}
        componentLeftBtn={
          isSubmitting && (
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              className="animate-spin fill-neutral-400"
            >
              <path
                d="M10.72,19.9a8,8,0,0,1-6.5-9.79A7.77,7.77,0,0,1,10.4,4.16a8,8,0,0,1,9.49,6.52A1.54,1.54,0,0,0,21.38,12h.13a1.37,1.37,0,0,0,1.38-1.54,11,11,0,1,0-12.7,12.39A1.54,1.54,0,0,0,12,21.34h0A1.47,1.47,0,0,0,10.72,19.9Z"
                className="spinner_6kVp"
              />
            </svg>
          )
        }
      />
      {/* Alert untuk notifikasi error */}
      {isEditError && (
        <AlertNotification
          icon={<FaCheckCircle />}
          color="red"
          message={errorMessage}
        />
      )}
    </div>
  );
};

export default EditRecordingHarian;
