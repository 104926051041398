/* eslint-disable no-sparse-arrays */
/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo } from "react";
import { useState } from "react";

import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Popover,
  PopoverContent,
  PopoverHandler,
  Radio,
  Tab,
  Tabs,
  TabsHeader,
  Typography,
} from "@material-tailwind/react";
import * as Sentry from "@sentry/react";
import Cookies from "js-cookie";
import { FaCheckCircle } from "react-icons/fa";
import { FiFilter } from "react-icons/fi";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useDebounce } from "use-debounce";

import api from "../../../api/configApi";
import { ReactComponent as Download } from "../../../assets/icons/download_icon_black.svg";
import { ReactComponent as EmptyIcon } from "../../../assets/icons/emptyIconn.svg";
import AlertNotification from "../../../atoms/AlertNotification";
import { Button } from "../../../atoms/Button";
import ConfirmDialog from "../../../atoms/ConfirmDialog";
import DropdownWithSearchInput from "../../../atoms/DropdownWithSearchInput";
import Input from "../../../atoms/Input";
import LoadingAnimation from "../../../atoms/LoadingAnimation";
import Pagination from "../../../atoms/Pagination";
import RangeDatepicker from "../../../atoms/RangeDatepicker";
import CardDetail from "../../../molecules/monGrow/CardDetailMonGrow";
import TableMonGrow from "../../../molecules/monGrow/Table";

const MonGrow = () => {
  const location = useLocation();
  const pathSegments = location.pathname.split("/");
  const lastSegment = pathSegments[pathSegments.length - 1];
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  let currentPageCookies = parseInt(
    Cookies.get("current-page-periodebudidaya"),
  );
  const [currentPage, setCurrentPage] = useState(
    currentPageCookies ? currentPageCookies : 1,
  );

  const [isOpenFilter, setIsOpenFilter] = useState(false);

  const [totalPage, setTotalPage] = useState(1);
  const [search, setSearch] = useState("");
  const alertStop = JSON.parse(
    localStorage.getItem("responseSuccessStopPeriod"),
  );
  const [notifStop, setNotifStop] = useState(alertStop);

  const alert = JSON.parse(localStorage.getItem("response success"));
  const [notif, setNotif] = useState(alert);
  const [isLoading, setIsLoading] = useState(true);

  const [isFetchError, setIsFetchError] = useState(false);
  const [debouncedSearch, setDebouncedSearch] = useState(search, 400);
  const [isLoadingDownload, setIsLoadingDownload] = useState(false);
  const [isDownloadSuccess, setIsDownloadSuccess] = useState(false);
  const [isDownloadError, setIsDownloadError] = useState(false);
  const [feedInList, setFeedInList] = useState([]);
  const [feedOutList, setFeedOutList] = useState([]);

  const [monGrowList, setMonGrowList] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [monGrowType, setMonGrowType] = useState("berjalan");
  const [end, setEnd] = useState(0);
  const [modalConfirmation, setModalconfirmation] = useState(false);
  const [countMonGrows, setCountMonGrows] = useState(0);
  const [countMonGrowsFilter, setCountMonGrowsFilter] = useState(0);
  const [downloadType, setDownloadType] = useState("all_data");
  const [selectAll, setSelectAll] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [checkedRegions, setCheckedRegions] = useState([]);
  const [checkedRegionsString, setCheckedRegionsString] = useState("");
  const [lastLabelRegion, setLastLabelRegion] = useState("");
  const [checkedUnits, setCheckedUnits] = useState([]);
  const [checkedUnitsString, setCheckedUnitsString] = useState("");
  const [lastLabelUnit, setLastLabelUnit] = useState("");
  const [checkedPpls, setCheckedPpls] = useState([]);
  const [checkedPplsString, setCheckedPplsString] = useState("");
  const [lastLabelPpl, setLastLabelPpl] = useState("");
  const [totalItems, setTotalItems] = useState(0);
  const [countFilter, setCountFilter] = useState(0);
  const [implementFilter, setImplementFilter] = useState(false);
  const [reloadFilter, setReloadFilter] = useState(false);
  const [pplList, setPplList] = useState(null);
  const [searchPpl, setSearchPpl] = useState("");
  const [debouncedSearchPpl] = useDebounce(searchPpl, 500);
  const [regionList, setRegionList] = useState([]);
  const [searchRegion, setSearchRegion] = useState("");
  const [debouncedSearchRegion] = useDebounce(searchRegion, 500);
  const [unitList, setUnitList] = useState([]);
  const [searchUnit, setSearchUnit] = useState("");
  const [debouncedSearchUnit] = useDebounce(searchUnit, 500);
  const [docList, setDocList] = useState([]);
  const [searchDoc, setSearchDoc] = useState("");
  const [debouncedSearchDoc] = useDebounce(searchDoc, 500);
  const [lastLabelDoc, setLastLabelDoc] = useState("");
  const [checkedDocsString, setCheckedDocsString] = useState("");
  const [checkedDocs, setCheckedDocs] = useState([]);
  const [docInDateInitial, setDocInDateInitial] = useState("");
  const [docInDateFinal, setDocInDateFinal] = useState("");
  const [selectedDayRange, setSelectedDayRange] = useState({
    from: null,
    to: null,
  });

  const handleCloseDetail = () => {
    setSelectedItem(null);
  };

  const tabData = useMemo(() => [
    {
      label: "Berjalan",
      value: "berjalan",
    },
    {
      label: "Closing",
      value: "closing",
    },
  ]);

  const handleTabClick = (value) => {
    setMonGrowType(value);
    if (value === "berjalan") {
      setEnd(0);
    } else {
      setEnd(1);
    }
    navigate(`/periode-budidaya/monitoring-growing/${value}`);
  };

  Cookies.set("current-page-periodebudidaya", currentPage);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearch(search);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [search]);

  useEffect(() => {
    const fetchingList = async () => {
      setIsLoading(true);
      try {
        const params = {
          currentPage: debouncedSearch?.length > 0 ? null : currentPage - 1,
          itemPerPage: debouncedSearch?.length > 0 ? null : 10,
          end: end,
          search: debouncedSearch,
        };

        if (implementFilter === true || reloadFilter === true) {
          params.ppl = checkedPplsString ? checkedPplsString : "";
          params.region = checkedRegionsString ? checkedRegionsString : "";
          params.unit = checkedUnitsString ? checkedUnitsString : "";
          params.doc = checkedDocsString ? checkedDocsString : "";
          params.docInInitial = docInDateInitial ? docInDateInitial : "";
          params.docInFinal = docInDateFinal ? docInDateFinal : "";
        }

        const response = await api.get("/v1/mongrow", { params });

        setMonGrowList(response?.data?.contents);
        setTotalPage(response?.data?.totalPages);
        setTotalItems(response?.data.totalItems);
        if (!response) {
          setIsOpenFilter(false);
        }
        setIsFetchError(false);
      } catch (error) {
        Sentry.captureException(error);
        setIsFetchError(true);
      } finally {
        setIsLoading(false);
      }
    };

    if (debouncedSearch || !debouncedSearch) {
      fetchingList();
    }
  }, [debouncedSearch, currentPage, implementFilter, reloadFilter, end]);

  useEffect(() => {
    if (
      checkedPpls?.length > 0 ||
      checkedUnits?.length > 0 ||
      checkedDocs?.length > 0 ||
      checkedRegions?.length > 0 ||
      docInDateInitial?.length > 0 ||
      docInDateFinal?.length > 0 ||
      search?.length > 0
    ) {
      setCountMonGrowsFilter(totalItems);
    } else {
      setCountMonGrowsFilter(0);
      setCountMonGrows(totalItems);
    }
  }, [totalItems, checkedPpls, checkedUnits, checkedDocs, checkedRegions]);

  useEffect(() => {
    const fetchingPplList = async () => {
      try {
        const params = {
          role: "PPL",
          search: debouncedSearchPpl,
          unit: checkedUnitsString?.length > 0 ? checkedUnitsString : "",
        };

        const response = await api.get("/v1/users/dropdown", { params });

        setPplList(response?.data?.contents);
        setIsFetchError(false);
      } catch (error) {
        Sentry.captureException(error);
        setIsFetchError(true);
      }
    };

    if (debouncedSearchPpl || !debouncedSearchPpl) {
      fetchingPplList();
      setCheckedPpls([]);
      setCheckedPplsString("");
      setLastLabelPpl("");
    }
  }, [debouncedSearchPpl, checkedUnitsString]);

  useEffect(() => {
    const fetchMasterRegion = async () => {
      try {
        const params = {
          search: debouncedSearchRegion,
          descending: false,
        };
        const responseList = await api.get("/v1/regions/dropdown", { params });
        setRegionList(responseList?.data?.contents);
      } catch (error) {
        console.log(error.message);
      }
    };

    fetchMasterRegion();
  }, [debouncedSearchRegion]);

  useEffect(() => {
    const fetchMasterUnit = async () => {
      try {
        const params = {
          search: debouncedSearchUnit,
          descending: false,
        };

        if (checkedRegionsString?.length > 0) {
          params.region = checkedRegionsString ? checkedRegionsString : "";
        }

        const responseList = await api.get("/v1/units/dropdown", { params });
        setUnitList(responseList?.data?.contents);
      } catch (error) {
        console.log(error.message);
      }
    };
    setCheckedUnits([]);
    setCheckedUnitsString("");
    setLastLabelUnit("");

    fetchMasterUnit();
  }, [debouncedSearchUnit, checkedRegionsString]);

  useEffect(() => {
    const fetchMasterDoc = async () => {
      try {
        const params = {
          search: debouncedSearchDoc,
          descending: false,
        };
        const responseList = await api.get("/v1/docs/dropdown", { params });
        setDocList(responseList?.data?.contents);
      } catch (error) {
        console.log(error.message);
      }
    };

    fetchMasterDoc();
  }, [debouncedSearchDoc]);

  useEffect(() => {
    const fetchFeedIn = async () => {
      try {
        const params = {
          periodId: selectedItem?.id,
        };
        const responseList = await api.get("/v1/feeds/in", { params });
        setFeedInList(responseList?.data?.contents);
      } catch (error) {
        console.log(error.message);
      }
    };
    if (selectedItem) {
      fetchFeedIn();
    }
  }, [selectedItem]);

  useEffect(() => {
    const fetchFeedOut = async () => {
      try {
        const params = {
          periodId: selectedItem?.id,
        };
        const responseList = await api.get("/v1/feeds/out", { params });
        setFeedOutList(responseList?.data?.contents);
      } catch (error) {
        console.log(error.message);
      }
    };
    if (selectedItem) {
      fetchFeedOut();
    }
  }, [selectedItem]);

  useEffect(() => {
    setTimeout(() => {
      setNotifStop(false);
      localStorage.clear("responseSuccessStopPeriod");
    }, 3000);
  }, [notifStop]);

  const handleChangePage = (target) => {
    setCurrentPage(target);
  };

  const handleTerapkanFilter = () => {
    setIsOpenFilter(false);
    setCountFilter(0);
    let count = 0;

    if (implementFilter === true) {
      setReloadFilter(true);
    }

    if (checkedRegionsString !== "") {
      count = count + 1;
    }

    if (checkedUnitsString !== "") {
      count = count + 1;
    }

    if (checkedPplsString !== "") {
      count = count + 1;
    }

    if (checkedDocsString !== "") {
      count = count + 1;
    }

    setCountFilter(count);
    setImplementFilter(true);
    setCurrentPage(1);
  };

  const handleResetFilter = () => {
    setImplementFilter(false);
    setIsOpenFilter(false);
    setSelectedRows([]);
    setSelectAll(false);
    setCheckedPpls([]);
    setCheckedPplsString("");
    setLastLabelPpl("");
    setCheckedDocs([]);
    setCheckedDocsString("");
    setLastLabelDoc("");
    setCheckedRegions([]);
    setCheckedRegionsString("");
    setLastLabelRegion("");
    setCheckedUnits([]);
    setCheckedUnitsString("");
    setLastLabelUnit("");
    setCountFilter(0);
    setDocInDateInitial("");
    setDocInDateFinal("");
    setSelectedDayRange({
      from: null,
      to: null,
    });
  };

  useEffect(() => {
    setTimeout(() => {
      setNotif(false);
      localStorage.clear("response success");
    }, 4000);
  }, [notif]);

  useEffect(() => {
    setReloadFilter(false);
  }, [reloadFilter]);

  const handleFilterPpl = (val) => {};
  const handleFilterRegion = (val) => {};
  const handleFilterUnit = (val) => {};
  const handleFilterDoc = (val) => {};

  const handleOpenModalConfirmationDownload = () => {
    setModalconfirmation(true);
  };

  const handleDownloadTypeChange = (event) => {
    setDownloadType(event.target.value);
  };

  const reqDownload = async (params) => {
    setIsLoadingDownload(true);
    try {
      let ids = params.ids ? "&id=" + params.ids : "";

      let searchVal = search?.length > 0 ? "search=" + search : "";

      let pplParams = params.ppls ? "&ppl=" + params.ppls : "";
      let regionParams = params.region ? "&region=" + params.region : "";
      let unitParams = params.unit ? "&unit=" + params.unit : "";
      let docParams = params.doc ? "&doc=" + params.doc : "";

      let searchParams = searchVal?.length > 0 ? "&" + searchVal : "";

      const multipleParams =
        searchParams + pplParams + docParams + regionParams + unitParams;

      const response = await api.get(
        `/v1/mongrow/toXLSX?end=` + end + ids + multipleParams,
        {
          responseType: "blob",
        },
      );

      const contentDisposition = response.headers["content-disposition"];
      let fileName = "monitoring_growing.xlsx";

      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename="?([^"]+)"?/);
        if (fileNameMatch && fileNameMatch[1]) {
          fileName = fileNameMatch[1];
        }
      }

      const url = window.URL.createObjectURL(new Blob([response.data]));

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(url);

      setIsDownloadSuccess(true);
      setTimeout(() => setIsDownloadSuccess(false), 3000);
    } catch (error) {
      setModalconfirmation(false);
      setIsDownloadError(true);
      setTimeout(() => setIsDownloadError(false), 3000);
      // setMessageError("Tidak ada data yang bisa di unduh");
      console.log(error);
    } finally {
      setIsLoadingDownload(false);
    }
  };

  const handleDownloadReport = async () => {
    try {
      const params = {};

      if (modalConfirmation) {
        if (downloadType === "selected_data") {
          params.ids = selectedRows.map((row) => row);
          reqDownload(params);
        } else if (downloadType === "filter_data") {
          params.ppl = checkedPplsString;
          params.unit = checkedUnitsString;
          reqDownload(params);
        } else {
          reqDownload(params);
        }
      }

      setSelectedRows([]);
      setSelectAll(false);
      setModalconfirmation(false);
    } catch (error) {
      setModalconfirmation(false);
      console.log(error);
    }
  };

  useEffect(() => {
    if (selectedRows.length > 0) {
      setDownloadType("selected_data");
    } else if (countMonGrowsFilter > 0) {
      setDownloadType("filter_data");
    } else {
      setDownloadType("all_data");
    }
  }, [modalConfirmation]);

  return (
    <>
      <div className={`z-0 flex h-auto w-full overflow-hidden`}>
        <div className="flex flex-1 flex-col overflow-hidden">
          <div className="flex max-h-[1260px] gap-4 p-4">
            <Card
              className={`m-0 h-auto border border-border-secondary p-0 shadow-none ${
                selectedItem ? "w-[calc(100%-316px)]" : "w-full"
              } transition-all duration-300`}
            >
              <CardHeader
                floated={false}
                shadow={false}
                className="m-0 w-full border-none p-3xl"
              >
                <Typography
                  variant="h2"
                  className="text-xl font-bold text-text-primary"
                >
                  Monitoring Growing
                </Typography>
                <div className="mt-xl max-w-[150px]">
                  <Tabs value={monGrowType} className="m-0 p-0">
                    <TabsHeader
                      className="z-0 m-0 rounded-none bg-transparent p-0"
                      indicatorProps={
                        location.pathname.endsWith(monGrowType)
                          ? {
                              className:
                                "bg-transparent border-b-[3px] border-primary-300 shadow-none rounded-none gap-x-6 p-0 m-0",
                            }
                          : null
                      }
                    >
                      {tabData.map(({ label, value }) => (
                        <Tab
                          key={value}
                          value={value}
                          onClick={() => handleTabClick(value)}
                          className={`m-0 p-0 text-sm font-semibold ${
                            monGrowType === value
                              ? "h-9 bg-white text-text-secondary"
                              : "h-9 bg-white text-text-quarterary"
                          }`}
                        >
                          <Typography variant="h6" className="gap-y-5">
                            {label}
                          </Typography>
                        </Tab>
                      ))}
                    </TabsHeader>
                  </Tabs>
                </div>
                <hr className="mb-xl" />

                <div className="grid grid-cols-2">
                  <div className="flex justify-start gap-2">
                    <div className="pl-4 text-left">
                      <Input
                        type="text"
                        id="search"
                        placeholder="Cari"
                        className="min-w-[350px] border border-border-primary"
                        size="medium"
                        componentLeft={
                          <MagnifyingGlassIcon className="h-5 w-5" />
                        }
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                      />
                    </div>
                    <div className="ml-2">
                      <Popover
                        placement="bottom"
                        open={isOpenFilter}
                        handler={() => setIsOpenFilter(!isOpenFilter)}
                      >
                        <PopoverHandler className="cursor-pointer">
                          <div>
                            <button
                              className={`${
                                countFilter > 0
                                  ? "border-button-infoBorder"
                                  : "border-neutral-300"
                              } inline-flex h-11 items-center justify-center gap-md rounded-md border bg-white p-5 px-xl py-2.5 text-base font-medium leading-normal text-text-secondary hover:border-border-secondary hover:bg-neutral-50 focus:shadow-sm focus:shadow-gray-300`}
                            >
                              <div
                                className={`flex w-full min-w-6 cursor-pointer items-center justify-center gap-2.5 ${
                                  countFilter > 0
                                    ? "text-fg-infoPrimary"
                                    : "text-text-secondary"
                                }`}
                              >
                                <div className={`w-auto font-medium`}>
                                  Filter
                                </div>
                              </div>
                              <div className="relative flex h-6 w-6 items-center">
                                <FiFilter
                                  className={`h-5 w-5 ${
                                    countFilter > 0 && "text-fg-infoPrimary"
                                  }`}
                                />
                              </div>
                              {countFilter > 0 && (
                                <div className="inline-flex h-6 w-6 items-center justify-center gap-1 rounded-full bg-fg-infoPrimary px-3 py-1">
                                  <div className="text-xs font-medium leading-[18px] text-white">
                                    {countFilter}
                                  </div>
                                </div>
                              )}
                            </button>
                          </div>
                        </PopoverHandler>

                        <PopoverContent className="left-[500px] top-[205px] z-50 w-[576px]">
                          <>
                            {(user?.level === "ALL_DATA" ||
                              user?.level === "MITRA") && (
                              <>
                                <Typography className="mb-sm flex min-h-full w-full justify-between text-xs font-medium leading-[18px] text-text-primary">
                                  Region
                                </Typography>

                                <div className="mt-2 mb-6">
                                  <DropdownWithSearchInput
                                    placeholder="Semua Region"
                                    options={regionList}
                                    onSelect={handleFilterRegion}
                                    onSelectString={setCheckedRegionsString}
                                    lastLabel={lastLabelRegion}
                                    setLastLabel={setLastLabelRegion}
                                    positionClassName="top-[335px] left-[520px]"
                                    selectCheckBox={checkedRegions}
                                    setSelectCheckbox={setCheckedRegions}
                                    search={searchRegion}
                                    setSearch={setSearchRegion}
                                    placeholderInputSearch="Cari Region"
                                  />
                                </div>
                              </>
                            )}
                            {(user?.level === "ALL_DATA" ||
                              user?.level === "MITRA" || user?.level === "REGION") && (
                              <>
                                <Typography className="mb-sm flex min-h-full w-full justify-between text-xs font-medium leading-[18px] text-text-primary">
                                  Unit
                                </Typography>

                                <div className="mt-2">
                                  <DropdownWithSearchInput
                                    placeholder="Semua Unit"
                                    options={unitList}
                                    onSelect={handleFilterUnit}
                                    onSelectString={setCheckedUnitsString}
                                    lastLabel={lastLabelUnit}
                                    setLastLabel={setLastLabelUnit}
                                    positionClassName="top-[335px] left-[520px]"
                                    selectCheckBox={checkedUnits}
                                    setSelectCheckbox={setCheckedUnits}
                                    search={searchUnit}
                                    setSearch={setSearchUnit}
                                    placeholderInputSearch="Cari Unit"
                                  />
                                </div>
                              </>
                            )}
                            <Typography className="mt-6 flex min-h-full w-full justify-between text-xs font-medium leading-[18px] text-text-secondary">
                              PPL
                            </Typography>
                            <DropdownWithSearchInput
                              placeholder="Semua PPL"
                              options={pplList}
                              onSelect={handleFilterPpl}
                              onSelectString={setCheckedPplsString}
                              lastLabel={lastLabelPpl}
                              setLastLabel={setLastLabelPpl}
                              positionClassName="top-[335px] left-[520px]"
                              selectCheckBox={checkedPpls}
                              setSelectCheckbox={setCheckedPpls}
                              search={searchPpl}
                              setSearch={setSearchPpl}
                              placeholderInputSearch="Cari PPL"
                            />
                            <Typography className="mt-6 flex min-h-full w-full justify-between text-xs font-medium leading-[18px] text-text-primary">
                              Merk DOC
                            </Typography>

                            <div className="mt-2">
                              <DropdownWithSearchInput
                                placeholder="Semua merk"
                                options={docList}
                                onSelect={handleFilterDoc}
                                onSelectString={setCheckedDocsString}
                                lastLabel={lastLabelDoc}
                                setLastLabel={setLastLabelDoc}
                                positionClassName="top-[335px] left-[520px]"
                                selectCheckBox={checkedDocs}
                                setSelectCheckbox={setCheckedDocs}
                                search={searchDoc}
                                setSearch={setSearchDoc}
                                placeholderInputSearch="Cari DOC"
                              />
                            </div>

                            <Typography className="mt-6 flex min-h-full w-full justify-between text-xs font-medium leading-[18px] text-text-primary">
                              Tanggal DOC
                            </Typography>
                            <div className="mt-md">
                              <RangeDatepicker
                                setStartDate={setDocInDateInitial}
                                setFinalDate={setDocInDateFinal}
                                selectedDayRange={selectedDayRange}
                                setSelectedDayRange={setSelectedDayRange}
                              />
                            </div>
                          </>

                          <div className="my-4 border-t border-gray-300" />

                          <div className="mt-4 flex w-full justify-between gap-8 divide-y">
                            <div onClick={handleResetFilter}>
                              <Typography className="flex min-h-full w-full cursor-pointer items-center justify-between text-sm font-medium leading-tight text-text-primary">
                                Atur Ulang
                              </Typography>
                            </div>

                            <Button
                              size={"small"}
                              label="Terapkan"
                              className="cursor-pointer"
                              onClick={handleTerapkanFilter}
                            />
                          </div>
                        </PopoverContent>
                      </Popover>
                    </div>
                  </div>
                  <div className="mr-[30px] flex justify-end gap-4 text-center">
                    <Button
                      label="Unduh laporan"
                      size="medium"
                      type={
                        isLoadingDownload
                          ? "btnPrimaryDisabled"
                          : user?.level === "UNIT" || user?.level === "ALL_DATA"
                            ? "btnSecondary"
                            : "btnPrimary"
                      }
                      componentLeft={
                        isLoadingDownload ? (
                          <svg
                            width="22"
                            height="22"
                            viewBox="0 0 22 22"
                            xmlns="http://www.w3.org/2000/svg"
                            className="animate-spin fill-neutral-400"
                          >
                            <path
                              d="M10.72,19.9a8,8,0,0,1-6.5-9.79A7.77,7.77,0,0,1,10.4,4.16a8,8,0,0,1,9.49,6.52A1.54,1.54,0,0,0,21.38,12h.13a1.37,1.37,0,0,0,1.38-1.54,11,11,0,1,0-12.7,12.39A1.54,1.54,0,0,0,12,21.34h0A1.47,1.47,0,0,0,10.72,19.9Z"
                              className="spinner_6kVp"
                            />
                          </svg>
                        ) : (
                          <Download />
                        )
                      }
                      onClick={handleOpenModalConfirmationDownload}
                      disabled={isLoadingDownload}
                    />
                  </div>
                </div>
              </CardHeader>

              {monGrowList?.length === 0 || isFetchError ? (
                <div className="flex h-screen items-center justify-center">
                  <div>
                    <div className="flex justify-center">
                      <EmptyIcon className="ml-[30px]" />
                    </div>
                    <Typography
                      variant="h2"
                      className="p-4 text-center text-xl font-semibold text-neutral-500"
                    >
                      Data tidak ditemukan
                    </Typography>

                    <Typography
                      variant="medium"
                      className="flex min-h-full w-full justify-between font-normal leading-none text-neutral-500"
                    >
                      Data yang Anda cari tidak ditemukan. Coba kata kunci lain
                    </Typography>
                  </div>
                </div>
              ) : (
                <>
                  <CardBody className="z-10 max-h-[960px] max-w-full overflow-scroll p-0 scrollbar-hide">
                    {isLoading ? (
                      <div className="flex h-screen items-center justify-center">
                        <LoadingAnimation />
                      </div>
                    ) : (
                      <>
                        <TableMonGrow
                          data={monGrowList}
                          setSelectedItem={setSelectedItem}
                          monGrowType={monGrowType}
                          selectAll={selectAll}
                          setSelectAll={setSelectAll}
                          selectedRows={selectedRows}
                          setSelectedRows={setSelectedRows}
                        />
                      </>
                    )}
                  </CardBody>
                  <CardFooter className="items-center border-t border-border-secondary p-sm">
                    <Pagination
                      currentPage={currentPage}
                      totalPages={totalPage}
                      onPageChange={handleChangePage}
                    />
                  </CardFooter>
                </>
              )}
            </Card>

            {/* Card Detail */}
            {selectedItem && (
              <CardDetail
                detailData={selectedItem}
                handleCloseDetail={handleCloseDetail}
                feedInData={feedInList}
                feedOutData={feedOutList}
              />
            )}
          </div>
        </div>
      </div>

      <ConfirmDialog
        isOpen={modalConfirmation}
        onClose={() => setModalconfirmation(!modalConfirmation)}
        classNameHeader={"px-3xl"}
        content={
          <div>
            <Typography className="px-md">
              Pilih kategori data yang ingin di unduh
            </Typography>
            <div className="mt-xl grid grid-rows-2">
              <Radio
                name="downloadType"
                className="p-0"
                disabled={
                  countMonGrowsFilter !== 0 || selectedRows?.length !== 0
                    ? true
                    : false
                }
                label={
                  <Typography className="text-sm font-medium text-text-secondary transition-colors hover:text-text-primary">
                    Semua data di seluruh halaman ({countMonGrows})
                  </Typography>
                }
                color="amber"
                value="all_data"
                checked={downloadType === "all_data"}
                onChange={handleDownloadTypeChange}
              />
              <Radio
                name="downloadType"
                disabled={selectedRows?.length === 0 ? true : false}
                label={
                  <Typography className="text-sm font-medium text-text-secondary transition-colors hover:text-text-primary">
                    Data yang telah dipilih ({selectedRows?.length})
                  </Typography>
                }
                color="amber"
                value="selected_data"
                checked={downloadType === "selected_data"}
                onChange={handleDownloadTypeChange}
              />
              <Radio
                name="downloadType"
                className="p-0"
                disabled={countMonGrowsFilter === 0 ? true : false}
                label={
                  <Typography className="text-sm font-medium text-text-secondary transition-colors hover:text-text-primary">
                    Semua data yang telah di filter ({countMonGrowsFilter})
                  </Typography>
                }
                color="amber"
                value="filter_data"
                checked={downloadType === "filter_data"}
                onChange={handleDownloadTypeChange}
              />
            </div>
          </div>
        }
        title={"Unduh data file ke .XLS"}
        btnLabel="Lanjutkan"
        onConfirm={handleDownloadReport}
        classNameContent={"text-md"}
      />

      {isDownloadSuccess && (
        <AlertNotification
          icon={<FaCheckCircle />}
          color="green"
          message="Berhasil unduh data"
        />
      )}

      {isDownloadError && (
        <AlertNotification
          icon={<FaCheckCircle />}
          color="red"
          message="Tidak bisa download data monitoring growing"
        />
      )}

      {notif && (
        <AlertNotification
          icon={<FaCheckCircle />}
          color="green"
          message="Periode Berhasil Dibuat"
        />
      )}

      {notifStop && (
        <AlertNotification
          icon={<FaCheckCircle />}
          color="green"
          message="Periode budidaya berhasil diakhiri."
        />
      )}
    </>
  );
};

export default MonGrow;
