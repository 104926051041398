import React from "react";
import { Card, Typography } from "@material-tailwind/react";
import { FaCheckCircle } from "react-icons/fa";

const PasswordStrengthList = ({
  isLengthValid,
  isUpperCaseValid,
  isLowerCaseValid,
  isNumberValid,
  className
}) => {
  return (
    <Card className={`${className} p-lg bg-gray-50 rounded-lg border border-gray-200 flex-col justify-center items-start gap-2 inline-flex shadow-none mb-2xl`}>
      <div className="text-neutral-500 text-sm font-normal leading-tight">
        <Typography type="small" className="text-sm ml-lg">
          Kata sandi minimal terdiri dari:
        </Typography>
        <div className="grid grid-cols-2 gap-6 text-neutral-500 p-md">
          <div className="flex justify-start text-sm items-center">
            {isLengthValid ? (
              <FaCheckCircle className="h-4.5 w-4.5 text-green-600 mr-sm" />
            ) : (
              "•"
            )}{" "}
            8 Karakter
          </div>
          <div className="flex justify-start text-sm items-center">
            {isUpperCaseValid ? (
              <FaCheckCircle className="h-4.5 w-4.5 text-green-600 mr-sm" />
            ) : (
              "•"
            )}{" "}
            Huruf Kapital
          </div>
        </div>
        <div className="grid grid-cols-2 gap-6 text-neutral-500 p-md">
          <div className="flex justify-start text-sm items-center">
            {isLowerCaseValid ? (
              <FaCheckCircle className="h-4.5 w-4.5 text-green-600 mr-sm" />
            ) : (
              "•"
            )}{" "}
            Huruf Kecil
          </div>
          <div className="flex justify-start text-sm items-center">
            {isNumberValid ? (
              <FaCheckCircle className="h-4.5 w-4.5 text-green-600 mr-sm" />
            ) : (
              "•"
            )}{" "}
            Angka
          </div>
        </div>
      </div>
    </Card>
  );
};

export default PasswordStrengthList;
