import { useQuery } from "@tanstack/react-query";

import api from "../../api/configApi";

const useGetMasterPeriodRegionList = ({
  sortBy = "createdAt",
  descending = true,
  search = "",
}) => {
  const query = useQuery({
    queryKey: [
      "get-master-period-region-list",
      {
        sortBy,
        descending,
        search,
      },
    ],
    queryFn: async () => {
      const response = await api.get("/v1/masterPeriodRegion/list", {
        params: {
          sortBy,
          descending,
          search,
        },
      });
      return response;
    },
    select: ({ data }) => data,
  });

  return query;
};

export default useGetMasterPeriodRegionList;
