import React, { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
} from "@material-tailwind/react";
import { FaCheckCircle } from "react-icons/fa";
import Input from "../../atoms/Input";
import { Button } from "../../atoms/Button";
import api from "../../api/configApi";

import AlertNotification from "../../atoms/AlertNotification";
import ConfirmDialog from "../../atoms/ConfirmDialog";
import PhoneInput from "../../molecules/login/PhoneInput";
import Dropdown from "../../atoms/DropdownWithReset";

import { useSelector } from "react-redux";

// Component definition
const EditUserModal = ({
  isOpen,
  onClose,
  onUpdate,
  userId,
  setSelectedEditUserId,
  rolesApiEndpoint = "/v1/roles/dropdown",
  onReloadData,
}) => {
  const [nama, setNama] = useState("");
  const [email, setEmail] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(true);
  // const [isEmailEmpty, setIsEmailEmpty] = useState(false);
  const [role, setRole] = useState(null);
  const [password, setPassword] = useState("");
  const [isActive, setIsActive] = useState();
  const [unit, setUnit] = useState();

  // const [user, setUser] = useState(null);
  const [roles, setRoles] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [selectedOption, setSelectedOption] = useState(null);

  const [isEditDialogOpen, setEditDialogOpen] = useState(false);
  const [isEditSuccess, setEditSuccess] = useState(false);

  const [phone, setPhone] = useState("");
  const [isPhoneValid, setIsPhoneValid] = useState(true);
  const [countryCode, setCountryCode] = useState("62");

  const [isEditError, setEditError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [units, setUnits] = useState([]);
  const [selectedUnit, setSelectedUnit] = useState(null);

  const [selectedLabel, setSelectedLabel] = useState("");
  const [phoneVerification, setPhoneVerfication] = useState(null);
  const [phoneBefore, setPhoneBefore] = useState("");

  const rolePerimissions =
    selectedLabel &&
    (selectedLabel === "Admin Unit" ||
      selectedLabel === "PPL" ||
      selectedLabel === "Admin Sales");

  const isSubmitDisabled =
    !role ||
    !nama ||
    !phone ||
    (email && !isEmailValid) ||
    !isPhoneValid ||
    (phoneVerification === false && phoneBefore !== phone) ||
    (!selectedUnit && rolePerimissions);

  const { user } = useSelector((state) => state.auth);

  const handleOpenEditDialog = () => {
    setEditDialogOpen(true);
  };

  const handleCloseEditDialog = () => {
    setSelectedEditUserId(null);
    setEditDialogOpen(false);
  };

  const handleCloseModal = () => {
    setNama("");
    setPhone("");
    setEmail("");
    setRole("");
    setPassword("");
    setUnit("");
    setSelectedUnit("");
    setSelectedLabel("");
    setIsEmailValid(true);
    setIsPhoneValid(true);
    setPhoneVerfication(null);
    setPhoneBefore("");
    onClose();
  };

  // Fetch user and roles data
  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await api.get(`/v1/users/${userId}`);

        setNama(response.data.fullName);
        setPhoneBefore(response.data.phoneNumber);
        setPhone(response.data.phoneNumber);
        setEmail(response.data.email);
        setSelectedOption(response.data.role);
        setRole(response.data.role.id);
        setSelectedLabel(response.data.role?.labels);
        setPassword(response.data.password);
        setIsActive(response.data.isActive);
        setUnit(response.data.unit?.id);
        setSelectedUnit(response?.data?.units[0]);
      } catch (error) {
        console.error("Error fetching user:", error);
      }
    };

    if (isOpen && userId) {
      fetchUser();
    }
  }, [isOpen, userId]);

  useEffect(() => {
    const fetchUnits = async () => {
      try {
        const response = await api.get("/v1/units/dropdown");
        setUnits(response.data.contents);
      } catch (error) {
        console.error("Error fetching roles:", error);
      }
    };

    const fetchRoles = async () => {
      try {
        const response = await api.get(rolesApiEndpoint);
        setRoles(response.data.contents);
      } catch (error) {
        console.error("Error fetching roles:", error);
      }
    };

    fetchUnits();
    fetchRoles();
  }, [isOpen, rolesApiEndpoint]);

  // const handleEmailBlur = () => {
  //   // Validasi saat fokus keluar dari field email
  //   if (email.trim() === "") {
  //     setIsEmailEmpty(true);
  //   } else {
  //     setIsEmailEmpty(false);
  //   }
  // };

  // const validateEmail = (inputEmail) => {
  //   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  //   if (!emailRegex.test(inputEmail)) {
  //     setIsEmailValid(false);
  //   } else {
  //     setIsEmailValid(true);
  //   }
  // };

  // const handleEmailChange = (e) => {
  //   const newEmail = e.target.value;
  //   setEmail(newEmail);
  //   validateEmail(newEmail);
  // };

  // Handle user update
  const handleUpdate = async () => {
    // console.log("email: ",email);
    let requestEditData = {
      fullName: nama,
      password: password,
      role: { id: role },
      isActive: isActive,
      // units: selectedUnit
    };
    // Tambahkan pengecekan apakah nomorTelepon adalah string dan tidak kosong
    if (typeof phone === "string" && phone.trim() !== "") {
      let formattedPhone = phone;

      // Hilangkan angka 0 di depan jika ada
      formattedPhone = formattedPhone.replace(/^0+/, "");

      if (formattedPhone !== "") {
        // Jika phone tidak mengandung kode negara, tambahkan countryCode
        if (!formattedPhone.startsWith(countryCode)) {
          formattedPhone = countryCode + formattedPhone;
        }
        requestEditData.phoneNumber = formattedPhone;
      }
    }

    if (unit) {
      requestEditData.unit = { id: unit };
    }

    // if (email) {
    //   requestEditData.email = email;
    // }

    handleCloseEditDialog();
    try {
      setIsSubmitting(true);
      const response = await api.put(`/v1/users/${userId}`, requestEditData);
      console.log("User data updated:", response.data);
      setIsSubmitting(false);
      if (onUpdate) {
        onUpdate(response.data);
      }

      if (onReloadData) {
        onReloadData();
      }

      setEditSuccess(true);
      setTimeout(() => setEditSuccess(false), 2000);

      setPhoneVerfication(null);
      handleCloseModal();
    } catch (error) {
      // console.log(requestEditData);
      // if (error.response.data.error?.fullName) {
      //   setNama("");
      //   setPhoneVerfication(null);
      //   setPhoneBefore("");
      // } else if (error.response.data.error?.phoneNumber) {
      //   setPhone("");
      //   setPhoneVerfication(null);
      //   setPhoneBefore("");
      // } else {
      //   setNama("");
      //   setPhone("");
      //   setEmail("");
      //   setRole("");
      //   setPhoneVerfication(null);
      //   setPhoneBefore("");
      // }

      setErrorMessage(
        error.response.data.error?.fullName ||
          error.response.data.error?.phoneNumber ||
          error.response.data.error
      );
      console.error("Error updating user data:", error);
      setEditError(true);
      setTimeout(() => setEditError(false), 4000);
      setIsSubmitting(false);
    }
  };

  const handleChangeUnit = (select) => {
    setUnit(select.id);
    setSelectedUnit(select.id);
  };

  const handleChangeRole = (select) => {
    // console.log("select role", select);

    const selectedRole = select;
    setRole(selectedRole.id);
    // console.log(selectedRole.id);
    setSelectedOption(selectedRole);
  };

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }

    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [isOpen]);

  return (
    <div>
      {isOpen && <div className="fixed inset-0 bg-gray-800 opacity-50 z-[50]"></div>}
      <Card
        className={`fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-[60] ${
          isOpen ? "block" : "hidden"
        } rounded-xl flex-col justify-start items-start`}
        style={{ width: "800px" }}
      >
        {/* CardHeader */}
        <CardHeader
          floated={false}
          shadow={false}
          className="border-b border-border-secondary rounded-b-none m-0 p-0"
        >
          {/* Close button */}
          <button
            className="absolute top-6 right-4 text-gray-500"
            onClick={handleCloseModal}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
          {/* Modal Title */}
          <Typography
            type="h2"
            className="text-lg text-text-primary font-semibold p-4"
          >
            Ubah pengguna
          </Typography>
        </CardHeader>
        {/* CardBody */}
        <CardBody className="p-5">
          {/* Nama Pengguna */}
          <div className="grid grid-cols-1">
            <div className="mb-md">
              <Typography className="text-text-primary text-sm font-semibold leading-tight">
                Nama Lengkap:{" "}
                <span className="text-red-500 text-sm font-normal leading-tight">
                  *
                </span>
              </Typography>
            </div>
            <Input
              type="text"
              id="name"
              placeholder="Masukkan Nama Lengkap"
              value={nama}
              size="medium"
              onChange={(e) => setNama(e.target.value)}
            />
          </div>

          {/* Nomor Telepon dan Email */}
          <div className="grid grid-cols-1 gap-4 my-5">
            <div>
              <Typography className="text-text-primary text-sm font-semibold leading-tight mb-2">
                Nomor telepon:{" "}
                <span className="text-red-500 text-sm font-normal leading-tight">
                  *
                </span>
              </Typography>
              <PhoneInput
                phone={phone}
                setPhone={setPhone}
                setCountryCode={setCountryCode}
                phoneIsNull={true}
                isLabelsActive={false}
                phoneValid={setIsPhoneValid}
                verification={phoneVerification}
                setVerification={setPhoneVerfication}
                phoneBefore={phoneBefore}
                isEdit={true}
              />
            </div>

            {/* <div className="">
                <Typography
                  className="text-text-primary text-sm font-semibold leading-tight"
                >
                  Email:
                </Typography>
              </div>
              <Input
                type="email"
                id="email"
                placeholder="Masukkan Email"
                className={`max-w-sm mt-md`}
                value={email}
                size="medium"
                onChange={handleEmailChange}
              /> */}
            {/* {email === "" && isEmailEmpty && (
                <p className="text-red-500 text-sm mt-1">
                  Email tidak boleh kosong.
                </p>
              )} */}
            {/* {!isEmailValid && email && (
                <p className="text-red-500 text-sm mt-1">
                  Masukkan alamat email yang valid.
                </p>
              )} */}
          </div>

          {/* Role */}
          <div className="grid grid-cols-1 my-5">
            <Typography className="text-text-primary text-sm font-semibold leading-tight mb-md">
              Role:{" "}
              <span className="text-red-500 text-sm font-normal leading-tight">
                *
              </span>
            </Typography>
            <Dropdown
              onSelect={handleChangeRole}
              placeholder="Pilih role akses"
              options={roles}
              selectedOption={selectedOption}
              setSelectedLabel={setSelectedLabel}
            ></Dropdown>
          </div>
          {user?.role === "Super Admin" && rolePerimissions && (
            <div className="grid grid-cols-1 my-5">
              <Typography className="text-text-primary text-sm font-semibold leading-tight mb-md">
                Unit:{" "}
                <span className="text-red-500 text-sm font-normal leading-tight">
                  *
                </span>
              </Typography>
              <Dropdown
                onSelect={handleChangeUnit}
                placeholder="Pilih unit"
                options={units}
                selectedOption={selectedUnit}
              />
            </div>
          )}

          {/* <div className="mt-5 items-center">
            <div className="my-3 w-full">
              <Typography
                className="text-text-primary text-sm font-semibold leading-tight mb-md"
              >
                Status pengguna
              </Typography>
            </div>
            <div className="w-full">
              <Switch
                className="checked:bg-bg-brandSolid"
                onChange={() => setIsActive(!isActive)}
                checked={isActive}
              />
              <span className="m-2 text-sm">
                {isActive ? "Aktif" : "Tidak Aktif"}
              </span>
            </div>
          </div> */}
        </CardBody>

        {/* CardFooter */}
        <CardFooter className="flex justify-end border-t border-border-secondary p-lg">
          {/* Cancel button */}
          <Button
            label="Keluar"
            size="medium"
            type="btnSecondary"
            className="mr-md"
            onClick={handleCloseModal}
          />
          {/* Save button */}
          <Button
            label="Simpan"
            size="medium"
            type={`${
              isSubmitDisabled || isSubmitting
                ? "btnPrimaryDisabled"
                : "btnPrimary"
            }`}
            onClick={handleOpenEditDialog}
            disabled={isSubmitDisabled || isSubmitting}
            componentLeft={
              isSubmitting && (
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  className="animate-spin fill-neutral-400"
                >
                  <path
                    d="M10.72,19.9a8,8,0,0,1-6.5-9.79A7.77,7.77,0,0,1,10.4,4.16a8,8,0,0,1,9.49,6.52A1.54,1.54,0,0,0,21.38,12h.13a1.37,1.37,0,0,0,1.38-1.54,11,11,0,1,0-12.7,12.39A1.54,1.54,0,0,0,12,21.34h0A1.47,1.47,0,0,0,10.72,19.9Z"
                    className="spinner_6kVp"
                  />
                </svg>
              )
            }
          />
        </CardFooter>
      </Card>
      {/* Komponen dialog konfirmasi edit*/}
      <ConfirmDialog
        isOpen={isEditDialogOpen}
        onClose={handleCloseEditDialog}
        onConfirm={handleUpdate}
        title="Ubah pengguna"
        content="Anda yakin ingin melanjutkan tindakan ini?"
        btnLabel="Lanjutkan"
        btnType="btnPrimary"
      />
      {isEditSuccess && (
        <AlertNotification
          icon={<FaCheckCircle />}
          color="green"
          message="Data pengguna berhasil di ubah."
        />
      )}

      {/* Alert untuk notifikasi error */}
      {isEditError && (
        <AlertNotification
          icon={<FaCheckCircle />}
          color="red"
          message={errorMessage}
        />
      )}
    </div>
  );
};

export default EditUserModal;
