import classNames from "classnames";
import { flexRender } from "@tanstack/react-table";

import { calculatePinnedPosition, isLastPinnedColumn } from "./config";

const TableFooter = ({ footerGroups }) => {
  return (
    <tfoot className="">
      {footerGroups.map((footerGroup) => (
        <tr key={footerGroup.id}>
          {footerGroup.headers.map((header) => {
            const isPinned = header.column.getIsPinned();
            const pinnedPosition = calculatePinnedPosition(
              header,
              footerGroup.headers
            );
            const isLastPinned = isLastPinnedColumn(
              header,
              footerGroup.headers
            );

            return (
              <th
                key={header.id}
                rowSpan={header.rowSpan}
                className={classNames("p-lg text-left bg-bg-infoPrimary ", {
                  "sticky z-10 bg-bg-infoPrimary": isPinned,
                  "border-r shadow-[rgba(0,0,10,0.2)_3px_0_10px_-5px]":
                    isPinned === "left" && isLastPinned,
                  "border-l shadow-[rgba(0,0,10,0.2)_-3px_1px_12px_-5px]":
                    isPinned === "right" && isLastPinned,
                })}
                style={{
                  ...pinnedPosition,
                }}
              >
                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.footer,
                      header.getContext()
                    )}
              </th>
            );
          })}
        </tr>
      ))}
    </tfoot>
  );
};

export default TableFooter;
