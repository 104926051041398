import React from "react";

import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/24/outline";
import {
  Button,
  ButtonGroup,
  IconButton,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
} from "@material-tailwind/react";
import { IoChevronDown } from "react-icons/io5";

import { cn } from "../../shared/utils";

const Pagination = ({
  currentPage,
  totalPages,
  itemPerPage = 10,
  setItemPerPage,
  onPageChange,
}) => {
  const handlePaginationChange = (newPage) => {
    onPageChange(newPage);
  };

  const [isClicked, setIsClicked] = React.useState(false);

  const itemsPerPageOptions = [10, 15, 20, 30, 40, 50];

  const renderPaginationButtons = () => {
    const buttons = [];

    const renderButton = (pageNumber) => (
      <IconButton
        key={pageNumber}
        variant="text"
        size="sm"
        className={`flex max-h-[36px] items-center justify-center rounded-none border-x-neutral-200 border-y-neutral-300 focus:ring-0 ${
          pageNumber === currentPage ? "bg-primary-50 text-primary-600" : ""
        }`}
        onClick={() => handlePaginationChange(pageNumber)}
      >
        {pageNumber}
      </IconButton>
    );

    if (totalPages <= 8) {
      if (totalPages === 0) {
        totalPages = 1;
      }
      for (let i = 1; i <= totalPages; i++) {
        buttons.push(renderButton(i));
      }
    } else {
      if (currentPage < 4) {
        for (let start = 1; start <= 4; start++) {
          buttons.push(renderButton(start));
        }
        buttons.push(
          <IconButton
            key="ellipsis-start"
            variant="text"
            size="sm"
            className="flex max-h-[36px] items-center justify-center rounded-none border-x-neutral-300 border-y-neutral-400"
            disabled
          >
            ...
          </IconButton>,
        );
        for (let end = totalPages - 2; end <= totalPages; end++) {
          buttons.push(renderButton(end));
        }
      } else if (currentPage >= totalPages - 2) {
        // Display the last 3 pages
        buttons.push(renderButton(1));
        buttons.push(
          <IconButton
            key="ellipsis-end"
            variant="text"
            size="sm"
            className="flex max-h-[36px] items-center justify-center border-x-neutral-300 border-y-neutral-400"
            disabled
          >
            ...
          </IconButton>,
        );
        for (let i = totalPages - 3; i <= totalPages; i++) {
          buttons.push(renderButton(i));
        }
      } else {
        // Display current page and 3 pages on each side
        buttons.push(renderButton(1));
        buttons.push(
          <IconButton
            key="ellipsis-start"
            variant="text"
            size="sm"
            className="flex max-h-[36px] items-center justify-center border-x-neutral-300 border-y-neutral-400"
            disabled
          >
            ...
          </IconButton>,
        );
        for (let i = currentPage - 1; i <= currentPage + 1; i++) {
          buttons.push(renderButton(i));
        }
        buttons.push(
          <IconButton
            key="ellipsis-end"
            variant="text"
            size="sm"
            className="flex max-h-[36px] items-center justify-center border-x-neutral-300 border-y-neutral-400"
            disabled
          >
            ...
          </IconButton>,
        );
        buttons.push(renderButton(totalPages));
      }
    }

    return buttons;
  };

  return (
    <div className="mx-xl flex items-center justify-between pb-sm">
      <div className="mt-4 flex items-center justify-start space-x-2 text-sm text-text-tertiary">
        Halaman {currentPage} dari {totalPages === 0 ? 1 : totalPages}
      </div>

      <div className="mt-4 flex items-center justify-end space-x-2">
        <div className="flex items-center gap-[10px] text-sm text-text-tertiary">
          Baris per halaman
          <Menu open={isClicked} handler={(open) => setIsClicked(open)}>
            <MenuHandler>
              <Button
                size="sm"
                className="flex max-h-9 min-h-9 items-center gap-1 border-neutral-200 px-[10px] py-[6px] text-base font-normal text-text-placeholder"
                variant="outlined"
              >
                {itemPerPage}
                <IoChevronDown
                  className={cn(
                    "size-4",
                    isClicked
                      ? "rotate-180 transform transition-transform ease-in-out"
                      : "rotate-0",
                  )}
                />
              </Button>
            </MenuHandler>
            <MenuList className="min-w-none p-2">
              {itemsPerPageOptions.map((option) => (
                <MenuItem
                  key={option}
                  onClick={() => {
                    setItemPerPage(option);
                    setIsClicked(false);
                  }}
                >
                  {option}
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
        </div>
        <ButtonGroup variant="outlined">
          {currentPage > 1 ? (
            <IconButton
              className="flex max-h-[36px] min-w-[150px] border-neutral-300 border-r-neutral-200 normal-case"
              onClick={() => handlePaginationChange(currentPage - 1)}
            >
              <div className="flex gap-2">
                <ArrowLeftIcon strokeWidth={2} className="h-4 w-4" />
                Sebelumnya
              </div>
            </IconButton>
          ) : (
            <IconButton
              className="max-h-[36px] min-w-[150px] border-neutral-300 border-r-neutral-200 normal-case"
              disabled
            >
              <div className="flex gap-2">
                <ArrowLeftIcon strokeWidth={2} className="h-4 w-4" />
                Sebelumnya
              </div>
            </IconButton>
          )}
          {renderPaginationButtons()}
          {currentPage < totalPages ? (
            <IconButton
              className="max-h-[36px] min-w-[150px] border-neutral-300 border-l-neutral-200 normal-case"
              onClick={() => handlePaginationChange(currentPage + 1)}
            >
              <div className="flex gap-2">
                Selanjutnya
                <ArrowRightIcon strokeWidth={2} className="h-4 w-4" />
              </div>
            </IconButton>
          ) : (
            <IconButton
              className="flex max-h-[36px] min-w-[150px] border-neutral-300 border-l-neutral-200 normal-case"
              disabled
            >
              <div className="flex gap-2">
                Selanjutnya
                <ArrowRightIcon strokeWidth={2} className="h-4 w-4" />
              </div>
            </IconButton>
          )}
        </ButtonGroup>
      </div>
    </div>
  );
};

export default Pagination;
