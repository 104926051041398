import React from "react";
import classNames from "classnames";
import { Primary, Secondary, Error } from "../static/colors/ButtonsColor";

const btnTypes = {
  btnPrimary: `${Primary["bg"]} ${Primary["bg_hover"]} border ${Primary["border"]} ${Primary["border_hover"]} text-button-primaryFg focus:shadow-sm focus:shadow-primary-500 `,
  btnSecondary: `${Secondary["bg"]} ${Secondary["bg_hover"]} border ${Secondary["border"]} ${Secondary["border_hover"]} text-text-secondary focus:shadow-sm focus:shadow-gray-300`,
  btnLinkBlack: `bg-none border-0 text-slate-600 hover:text-neutral-800`,
  btnLinkPrimary: `bg-none border-0 text-primary-500 hover:text-primary-600`,
  btnLinkBlue: `bg-none border-0 text-blue-600 hover:text-blue-800`,
  btnLinkRed: `bg-none border-0 text-red-600 hover:text-red-800`,
  btnError: `${Error["bg"]} ${Error["bg_hover"]} border ${Error["border"]} ${Error["border_hover"]} text-white`,
  btnPrimaryDisabled: "bg-gray-100 border border-gray-200 text-gray-400",
  btnLinkDisabled: "bg-none border-0 text-gray-400",
  btnInfoOutlined: "bg-none border-2 border-blue-500 text-blue-600 hover:text-blue-800 hover:border-blue-800",
  btnOutlinedGrey: "bg-none border border-neutral-300  text-text-primary hover:bg-neutral-100",
  btnErrorOutlined: `bg-none hover:text-red-700 hover:border-red-700 border-2 ${Error["border"]} ${Error["border_hover"]} text-red-600`,
};

const sizes = {
  xsmall:
    "h-6 px-1.5 py-lg justify-center items-center gap-xs inline-flex text-sm font-semibold leading-tight rounded-sm",
  small:
    "h-8 px-3.5 py-lg justify-center items-center gap-xs inline-flex text-sm font-medium leading-normal rounded-sm",
  medium:
    "h-11 px-xl py-2.5 justify-center items-center gap-md inline-flex text-base font-medium leading-normal rounded-md",
  large:
    "h-14 px-xl py-xl justify-center items-center gap-2.5 inline-flex text-base font-medium leading-normal rounded-md",
};

export const Button = ({
  label,
  type,
  size,
  disabled,
  onClick,
  className,
  componentLeft,
  componentRight,
  classNameLabelBtn
}) => {
  const buttonClasses = classNames(
    btnTypes[type] || btnTypes.btnPrimary,
    sizes[size] || sizes.medium
  );

  return (
    <button
      className={`${buttonClasses} ${className}`}
      disabled={disabled}
      onClick={onClick}
    >
      {componentLeft && (
        <div className="w-6 h-6 relative flex items-center"> {componentLeft} </div>
      )}
      <div className={`${classNameLabelBtn ? classNameLabelBtn : `justify-center`} min-w-6 w-full items-center gap-2.5 flex`}>
        <div className="w-auto">{label}</div>
      </div>
      {componentRight && (
        <div className="w-6 h-6 relative flex items-center"> {componentRight} </div>
      )}
    </button>
  );
};
