import React, { useState, useEffect } from "react";
import { Card, Typography } from "@material-tailwind/react";
import { Button } from "../../atoms/Button";
import { FaChevronLeft } from "react-icons/fa";
import { useTimer } from "react-timer-hook";
import api from "../../api/configApiAuth";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { VerifyOtp, reset } from "../../molecules/features/authSlice";

function OTPVerificationForm({ type, otpSession, receiverOTP, onBack }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [otp, setOTP] = useState(["", "", "", "", "", ""]);
  const [isVerificationError, setIsVerificationError] = useState(false);
  // const [isOtpVerify, setIsOtpVerify] = useState(false);
  const inputRefs = [];
  const [isResendDisabled, setIsResendDisabled] = useState(true);
  const expiryTimestamp = new Date();
  expiryTimestamp.setSeconds(expiryTimestamp.getSeconds() + 120); // 180 detik = 3 menit
  const [sensorEmailValue, setSensorEmailValue] = useState("");

  const { seconds, minutes, isRunning, restart } = useTimer({
    expiryTimestamp,
    onExpire: () => {
      setIsResendDisabled(false);
    },
  });

  const { user, isVerifyOtpSuccess } = useSelector(
    (state) => state.auth
  );

  // Fungsi untuk menyensor karakter di tengah
  const sensorMiddleCharacters = (text, start, end) => {
    const chars = text.split("");
    for (let i = start; i < chars.length - end; i++) {
      chars[i] = "*";
    }
    return chars.join("");
  };
  useEffect(() => {
    if (type === "Email") {
      const sensorEmail = (receiverOTP) => {
        const emailParts = receiverOTP.split("@");
        const username = emailParts[0];
        const domain = emailParts[1];

        // Sensor huruf di awal dan di tengah
        const sensorUsername = sensorMiddleCharacters(username, 2, 3);

        // Sensor huruf di akhir
        const sensorDomain = sensorMiddleCharacters(domain, 0, 7);

        return `${sensorUsername}@${sensorDomain}`;
      };

      sensorEmail(receiverOTP);
      setSensorEmailValue(sensorEmail(receiverOTP));
    }
  }, [type, receiverOTP]);

  const handleChange = (e, index) => {
    const value = e.target.value;

    // Handle manual input
    if (!isNaN(value) && value.length <= 1) {
      otp[index] = value;
      setOTP([...otp]);
      setIsVerificationError(false);
      // if (value !== "" && index < 5) {
      //   inputRefs[index + 1].focus();
      // }

      // Move focus to the previous input column if the value is deleted
      if (value === "" && index > 0) {
        inputRefs[index - 1].focus();
      } else if (value !== "" && index < 5) {
        inputRefs[index + 1].focus();
      }
    } else if (!isNaN(value) && value.length === 6) {
      // Handle copy-paste of the full OTP
      const otpArray = value.split("").slice(0, 6);
      otpArray.forEach((digit, i) => {
        otp[index + i] = digit;
      });
      setOTP([...otp]);
      setIsVerificationError(false);
    }
  };

  const handleResend = () => {
    if (type === "Email") {
      const fetchData = async () => {
        try {
          await api.post("v1/auth/sendOtp", {
            username: receiverOTP,
            otpSession: otpSession,
            destination: "EMAIL",
          });
          setIsResendDisabled(true);
          restart(expiryTimestamp);
          console.log("Resend OTP berhasil");
        } catch (error) {
          setIsResendDisabled(true);
          restart(expiryTimestamp);
          console.log(`Resend OTP gagal. Error: ${error.message}`);
        }
      };
      fetchData();
    }
  };

  useEffect(() => {
    if (!isRunning) {
      setIsResendDisabled(false);
    }
  }, [isRunning]);

  useEffect(() => {
    const handleVerificationOTP = () => {
      const isOTPComplete = otp.every((digit) => digit !== "");
      const otpString = otp.join("");
      if (isOTPComplete) {
        dispatch(VerifyOtp({ receiverOTP, otpSession, otpString }));

        if (user || isVerifyOtpSuccess) {
          navigate("/dashboard");
        }
      }
      dispatch(reset());
    };

    handleVerificationOTP();
  }, [
    dispatch,
    navigate,
    otp,
    receiverOTP,
    otpSession,
    user,
    isVerifyOtpSuccess,
  ]);

  // useEffect(() => {
  //   const handleVerificationOTP = () => {
  //     const isOTPComplete = otp.every((digit) => digit !== "");
  //     const otpString = otp.join("");
  //     if (isOTPComplete) {
  //       setIsOtpVerify(true);
  //       dispatch(VerifyOtp({ receiverOTP, otpSession, otpString }));
  //     }
  //     dispatch(reset());
  //   };

  //   handleVerificationOTP();
  // }, [dispatch, otp, receiverOTP, otpSession]);

  // useEffect(() => {
  //   console.log("isLoading: " + isLoading);
  //   if (isLoading === false) {
  //     if (isOtpVerify) {
  //       console.log("dalam: " + isVerifyOtpSuccess);
  //       if (isVerifyOtpSuccess) {
  //         console.log("Verifikasi OTP berhasil");
  //         navigate("/dashboard");
  //       } else {
  //         setIsVerificationError(true);
  //         setIsOtpVerify(false);
  //       }
  //     }
  //   }
  // }, [navigate, isLoading, isVerifyOtpSuccess, isOtpVerify]);
  // console.log("luar: "+ isVerifyOtpSuccess);

  return (
    <div className="flex justify-center items-center min-h-[700px]">
      <Card className="w-[484px] h-[326px] max-w-[484px] md:min-w-sm shadow-none md:shadow-md md:border-t-2 md:border-gray-100 rounded-xl md:p-4xl p-xs opacity-100">
        <div className="w-[420px] h-[72px] md:min-w-sm flex-col justify-center items-center inline-flex">
          
          <Typography
            type="h2"
            className="text-lg font-bold text-center text-neutral-900"
          >
            Masukkan kode verifikasi
          </Typography>
          <div className="w-[340px] text-center">
            <Typography
              type="small"
              className="font-normal text-sm text-neutral-500 text-center"
            >
              Masukkan 6-digit kode OTP yang telah dikirim melalui{" "}
              <span className="text-sm text-neutral-500 font-semibold">
                {type}
              </span>{" "}
              ke{" "}
              <span className="text-sm text-neutral-500 font-semibold">
                {type === "Email"
                  ? sensorEmailValue
                  : "*******" + receiverOTP.slice(-4)}
              </span>
            </Typography>
          </div>
        </div>

        <div className="w-[420px] h-[114px] md:min-w-sm flex-col justify-start items-center gap-5 inline-flex mt-2xl">
          <div className="flex justify-center items-center space-x-2">
            {otp.map((digit, index) => (
              <input
                key={index}
                type="text"
                className={`w-12 h-12 text-center rounded-md border ${
                  isVerificationError ? "border-red-600" : "border-gray-300"
                } focus:outline-none focus:border-yellow-400 focus:ring-yellow-400 focus:shadow-sm focus:shadow-yellow-400`}
                value={digit}
                onChange={(e) => handleChange(e, index)}
                ref={(input) => (inputRefs[index] = input)}
              />
            ))}
          </div>

          <div className="flex flex-col">
            {/* error message */}
            {isVerificationError && (
              <p className="text-red-500 text-sm text-center">
                Masukkan kode yang benar
              </p>
            )}
            <div className="flex flex-row items-center justify-center text-center text-xs font-medium space-x-1 text-gray-500">
              <p>Belum menerima kode?</p>
              <div className="text-center text-slate-600 text-xs font-bold leading-[18px]">
                {minutes}:{seconds < 10 ? "0" + seconds : seconds}
                {/* {seconds} */}
              </div>
            </div>
            <div className="flex flex-row items-center justify-center text-center text-sm font-medium space-x-1">
              <Button
                type={isResendDisabled ? "btnLinkDisabled" : "btnLinkPrimary"}
                onClick={handleResend}
                disabled={isResendDisabled}
                label="Kirim Ulang"
                size="xsmall"
              />
            </div>
            <Button
              size="small"
              type="btnLinkBlack"
              className="mt-4xl"
              componentLeft={
                <FaChevronLeft className="h-3 w-3 text-slate-600 hover:text-neutral-800 mt-[7px]" />
              }
              label="Kembali ke halaman masuk"
              onClick={onBack}
            />
          </div>
        </div>
      </Card>
    </div>
  );
}

export default OTPVerificationForm;
