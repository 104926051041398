/* eslint-disable no-unused-expressions */
/* eslint-disable no-unused-vars */
import React, { useRef, useState } from "react";
import { ReactComponent as ChevronDown } from "../assets/icons/chevron_down.svg";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Typography,
} from "@material-tailwind/react";
import DefaultOvk from '../img/OVK.png'
import DefaultPakan from '../img/Pakan_image.png'

const sizes = {
  xsmall: { input: "h-7 rounded-sm", icon: "h-5 w-5" },
  small: { input: "h-8 rounded-sm", icon: "h-5 w-5" },
  medium: { input: "h-11 rounded-md", icon: "h-5 w-5" },
  large: { input: "h-14 rounded-md", icon: "h-6 w-6" },
};

const DropdownStockSearch = ({
  options,
  onSelectFeed,
  onSelectOvk,
  placeholder,
  size,
  color,
  className,
  type,
  disabled,
  search,
  setSearch,
  initialValue,
  label,
  indexProduct,
  selectedStock,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState("");
  const inputRef = useRef();
  const [selectedFeed, setSelectedFeed] = useState(null);
  const [selectedOvk, setSelectedOvk] = useState(null);
  const [selectedStockString, setSelectedStockString] = useState();

  const handleSearchChange = (e, index) => {
    setSearch(e.target.value || setSelected(""));
  };
  const handleToggle = () => setIsOpen(!isOpen);

  // useEffect(() => {
  //   if (initialValue) {
  //     if (initialValue.feed.type === "feed") {
  //       onSelectFeed(initialValue);
  //       setSelectedFeed(initialValue);
  //       setSelectedStock("Sisa pakan: " + initialValue.feed.stock + " zak");
  //     } else if (initialValue.feed.type === "ovk") {
  //       onSelectOvk(initialValue);
  //       setSelectedOvk(initialValue);
  //       setSelectedStock("Sisa OVK: " + initialValue.ovk.stock + " item");
  //     }

  //   }
  // }, [initialValue, onSelectFeed, onSelectOvk]);

  const handleSelect = (selectedOption, index) => {
    if (selectedOption.type === "feed") {
      onSelectFeed(selectedOption);
      setSelectedFeed(selectedOption);
      setSelectedStockString("Sisa pakan: " + selectedOption.stock / 50 + " zak");
    } else if (selectedOption.type === "ovk") {
      onSelectOvk(selectedOption);
      setSelectedOvk(selectedOption);
      setSelectedStockString("Sisa OVK: " + selectedOption.stock + " item");
    }

    setIsOpen(false);
    setSelected(selectedOption.labels);
    setSearch("");
  };

  const handleClickOutside = (event) => {
    if (inputRef.current && !inputRef.current.contains(event.target)) {
      setIsOpen(false);
      setSearch("");
    }
  };

  document.addEventListener("mousedown", handleClickOutside);

  const height = sizes[size]["input"];
  return (
    <div className="relative">
      <input
        type={type}
        placeholder={label ? label : placeholder}
        className={`${height} w-full border border-${color}-500 ${className} ${
          label ? `text-text-primary` : `placeholder:text-text-placeholder`
        } px-lg py-xs justify-start items-center gap-xxs inline-flex focus:outline-none focus:border-primary-400 focus:ring focus:ring-primary-200 `}
        value={search ? search : label ? label : ""}
        onChange={(e) => handleSearchChange(e, indexProduct)}
        onClick={handleToggle}
        disabled={disabled}
      />
      <div className={`absolute right-12 top-3 text-sm text-amber-600`}>
        {selectedStock
          ? selectedStockString
          : initialValue?.feed?.type === "feed"
          ? "Sisa pakan: " + initialValue?.feed?.stock / 50 + " zak"
          : initialValue?.ovk?.type === "ovk"
          ? "Sisa OVK: " + initialValue?.ovk?.stock + " item"
          : ""}
      </div>

      <ChevronDown
        className={`absolute right-4 top-3 w-6 h-6 ${
          isOpen ? "transform rotate-180" : ""
        } text-gray-500`}
        onClick={handleToggle}
      />
      {isOpen && (
        <Card className="p-0 mt-2">
          <CardHeader
            floated={false}
            shadow={false}
            className="border-b rounded-none pb-1"
          >
            Cari
          </CardHeader>
          <CardBody className="p-0">
            <ul className="p-0 relative z-30 bg-white w-full mt-1 overflow-y-auto max-h-60">
              <Typography className="p-3 font-semibold">Pakan</Typography>
              {options?.feedStocks?.length > 0 ? (
                options?.feedStocks?.map(
                  (item, index) =>
                    item?.stock !== 0 && (
                      <li
                        key={index}
                        className="rounded-lg px-2 py-1 hover:bg-gray-100 cursor-pointer grid grid-cols-6"
                        onClick={() =>
                          handleSelect({ ...item, type: "feed" }, index)
                        }
                      >
                        <div className="col-span-4">
                          <div className="flex justify-start">
                            <img
                              src={item?.image || DefaultPakan}
                              alt="Default"
                              className="h-12 w-12 rounded-md"
                            />
                            <div className="grid grid-rows-2 px-2">
                              <div className="row-span-1">{item?.labels}</div>
                              <div className="row-span-1 text-sm">
                                {item?.type?.labels}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-span-2 text-right text-sm p-2 text-amber-600">
                          Sisa pakan: {item?.stock / 50} zak
                        </div>
                      </li>
                    )
                )
              ) : (
                <li className="pl-4 flex justify-left items-center">
                  Tidak ada data
                </li>
              )}
              <Typography className="p-3 font-semibold mt-2">OVK</Typography>
              {options?.ovkStocks?.length > 0 ? (
                options?.ovkStocks?.map(
                  (item, index) =>
                    item?.stock !== 0 && (
                      <li
                        key={index}
                        className="rounded-lg px-2 py-1 hover:bg-gray-100 cursor-pointer grid grid-cols-5"
                        onClick={() =>
                          handleSelect({ ...item, type: "ovk" }, index)
                        }
                      >
                        <div className="col-span-2">
                          <div className="flex justify-start">
                            <img
                              src={item?.image || DefaultOvk}
                              alt="Default"
                              className="h-12 w-12 rounded-md"
                            />
                            <div className="grid grid-rows-2 px-2">
                              <div className="row-span-1">{item?.labels}</div>
                              <div className="row-span-1 text-sm">
                                {item.shape?.labels}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-span-1"></div>
                        <div className="col-span-2 text-right text-sm p-2 text-amber-600">
                          Sisa vaksin: {item?.stock} item
                        </div>
                      </li>
                    )
                )
              ) : (
                <li className="pl-4 flex justify-left items-center">
                  Tidak ada data
                </li>
              )}
            </ul>
          </CardBody>
          <CardFooter></CardFooter>
        </Card>
      )}
    </div>
  );
};

export default DropdownStockSearch;
